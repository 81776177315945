import React, { useEffect, useState } from 'react';
import { useGetUserStatisticsQuery } from '@ugo/graphql-operations';
import { useGetList } from 'react-admin';
import { ChartComponentProps } from '@ugo/models';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const AveragePurchasePerUser = (props: ChartComponentProps) => {
  const [sum, setSum] = useState<number>(0);
  // TODO: Here we should fetch range bound users by created_at
  const { data: useGetUserStatistics, loading: useGetUserStatisticsLoading } =
    useGetUserStatisticsQuery();

  const { data, loading } = useGetList(
    'payment',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'ASC' },

    { 'created_at@_gte': props.startDate, 'created_at@_lte': props.endDate }
  );

  useEffect(() => {
    const payments = data as any;
    const _payments = Object.keys(payments).map((k) => payments[k]);

    const sum = Object.keys(payments)
      .map((pid) => payments[pid])
      .filter(
        (p) =>
          p.reservation.metadata.service_city !== 'PRODUCTION_TEST_CITY' &&
          !!p.reservation.metadata.service
      )
      .reduce((c, v) => c + Number(v.metadata.amount), 0);

    const distinctUsers = [
      //@ts-ignore
      ...new Set(_payments.map((p) => p.reservation.reservor_id)),
    ];

    setSum(sum / distinctUsers.length || 0);
  }, [data, useGetUserStatistics]);

  return (
    <Card>
      <Box p={2}>
        {loading || useGetUserStatisticsLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Typography align="center" variant="h6">
              Average income per user
            </Typography>
            <Typography align="center" color="primary" variant="h5">
              <b>{sum.toFixed(2)} €</b>
            </Typography>
          </>
        )}
      </Box>
    </Card>
  );
};

export default AveragePurchasePerUser;
