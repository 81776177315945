import { useForm } from 'react-final-form';
import { UgoMapsAutocomplete } from './UgoMapsAutocomplete';
import { TextInput } from 'react-admin';
import React from 'react';
import Box from '@material-ui/core/Box';

export const LocationInput = ({
  addressField,
  latField,
  lngField,
  sourceObject = 'root',
}: {
  addressField: string;
  latField: string;
  lngField: string;
  sourceObject?: 'root' | string;
}) => {
  const form = useForm();

  const onLocationSelect = ({
    address,
    lat,
    lng,
  }: {
    address: string;
    lat: string;
    lng: string;
  }) => {
    // props.source is used to
    const formValues = form.getState().values;
    const newFormValues = {
      ...formValues,
    };

    // Root is used on manual_reservations
    if (!sourceObject || sourceObject === 'root') {
      newFormValues[addressField] = address;
      newFormValues[latField] = lat.toString();
      newFormValues[lngField] = lng.toString();
    } else {
      newFormValues[sourceObject][addressField] = address;
      newFormValues[sourceObject][latField] = lat;
      newFormValues[sourceObject][lngField] = lng;
    }

    form.reset({ ...newFormValues });
  };

  return (
    <>
      <Box>
        <UgoMapsAutocomplete onLocationSelect={onLocationSelect} />
      </Box>
      <Box display="flex">
        <Box flex={1} py="1em">
          <TextInput
            source={
              sourceObject === 'root'
                ? addressField
                : `${sourceObject}.${addressField}`
            }
            multiline
            disabled
            fullWidth
            type="text"
          />
        </Box>
        <Box flex={1} py="1em">
          <TextInput
            source={
              sourceObject === 'root' ? latField : `${sourceObject}.${latField}`
            }
            disabled
            type="text"
            fullWidth
          />
          <TextInput
            source={
              sourceObject === 'root' ? lngField : `${sourceObject}.${lngField}`
            }
            disabled
            fullWidth
            type="text"
          />
        </Box>
      </Box>
    </>
  );
};
