import React from 'react';
import { ResourceComponentProps } from 'ra-core';
import { Datagrid, List, TextField, TextInput, Filter } from 'react-admin';
import { NumberField } from 'ra-ui-materialui';

const CreditCardExpirationFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="email@_ilike" label="Email" alwaysOn />
    </Filter>
  );
};

const CreditCardExpirationDates = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      sort={{ field: 'expiry_date', order: 'ASC' }}
      filters={<CreditCardExpirationFilter />}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField source="last4" />
        <NumberField source="expiry_date" />
      </Datagrid>
    </List>
  );
};

export default CreditCardExpirationDates;
