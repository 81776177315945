import {
  getDistanceCost,
  getHalfHourIntervalTimeCost,
  getHourlyIntervalTimeCost,
  CITY_MIN_DISTANCE,
  CITY_MIN_DISTANCE_DEFAULT,
  Service,
  CITY_RADIUS_LIMIT_IN_KMS,
} from './utils';

export class QuoteCalculator {
  city: string;
  service: Service;
  duration: number;
  isTwoWay: boolean;
  distanceBetween0AndA: number;
  distanceBetween0AndB: number;
  // Calculated depending on isTwoWay
  travelDistance: number;

  // Calculated
  driverDistance: number;

  constructor(
    service: Service,
    duration: number,
    isTwoWay: boolean,
    city: string,
    distanceBetween0AndA: number,
    distanceBetween0AndB: number,
    distanceBetweenAAndB: number
  ) {
    this.service = service;
    this.duration = duration;
    this.isTwoWay = isTwoWay;
    this.city = city;
    this.distanceBetween0AndA = distanceBetween0AndA;
    this.distanceBetween0AndB = distanceBetween0AndB;
    this.travelDistance = isTwoWay
      ? distanceBetweenAAndB * 2
      : distanceBetweenAAndB;

    this.driverDistance = this.getDriverDistance();
  }

  private getDriverDistance(): number {
    const isDistance0APayable =
      this.distanceBetween0AndA > CITY_RADIUS_LIMIT_IN_KMS;
    const isDistance0BPayable =
      this.distanceBetween0AndB > CITY_RADIUS_LIMIT_IN_KMS;

    let driverDistance = 0;

    if (this.isTwoWay) {
      /*
       * CC ➝ A ➝ B ➝ A ➝ CC
       * Driver always returns to A (because is_two_way = true) and back to CC
       */

      // Adding 0A if 0A > 15km
      // Multiplying by 2 because is_two_way always returns on 0A
      if (isDistance0APayable) {
        driverDistance += this.distanceBetween0AndA * 2;
      }
      console.log(`Distance driver considered: ${driverDistance} (A0*2)`);
    } else {
      /**
       * CC ➝ A ➝ B ➝ CC
       * Driver returns on 0B because is_two_way = true
       */
      if (isDistance0APayable) {
        driverDistance += this.distanceBetween0AndA;
      }

      if (isDistance0BPayable) {
        driverDistance += this.distanceBetween0AndB;
      }

      console.log(`Distance driver considered: ${driverDistance} (A0+B0)`);
    }

    return driverDistance;
  }

  public calculate(): number {
    let cost = 0;
    switch (this.service) {
      case 'CAREGIVING_WITH_UGOS_CAR':
        cost = this.calculateCaregivingWithUgosCarCost();
        break;
      case 'CAREGIVING_WITH_USERS_CAR':
        cost = this.calculateCaregivingWithUsersCarCost();
        break;
      case 'CAREGIVING_WITHOUT_CAR':
        cost = this.calculateCaregivingWithoutCarCost();
        break;
      case 'CAREGIVING_VIA_TELEMEDICINE':
        cost = this.calculateCaregivingViaTelemedicineCost();
        break;
      case 'ERRANDS':
        cost = this.calculateErrandsCost();
        break;
      case 'PHONE_ASSISTANCE':
        cost = this.calculatePhoneAssistanceCost();
        break;
    }

    return cost;
  }

  private calculateCaregivingWithUgosCarCost() {
    const CAREGIVING_WITH_UGOS_CAR_PRICE_PER_HOUR = 15;

    const timeCost = getHourlyIntervalTimeCost(
      this.duration,
      CAREGIVING_WITH_UGOS_CAR_PRICE_PER_HOUR
    );

    console.log(`Distance travel considered: ${this.travelDistance}`);
    console.log(`Caregiving duration considered: ${this.duration}h`);

    const minDistancePrice =
      CITY_MIN_DISTANCE[this.city] || CITY_MIN_DISTANCE_DEFAULT;

    const distanceCost = getDistanceCost(
      this.travelDistance + this.driverDistance,
      minDistancePrice
    );
    console.log(`Total distance: ${this.travelDistance + this.driverDistance}`);

    console.log(
      `Distance cost (driver + travel) - not rounded: ${distanceCost}`
    );
    console.log(`Caregiving duration cost: ${timeCost}`);

    return timeCost + distanceCost;
  }

  /**
   * @description Calculates cost for caregiving/driving from point A to point B
   * with the user's car. This option works only for long distances.
   * Short distances should be prevented via UI (form validation).
   *
   * timeCost - +15 €/hour; every added hours cost (hour - 1; - 1€)
   * kaskoCost - Kasko insurance cost is calculated On the distance of the
   * first route (2,5 €/50 km)
   * wayBackCost - Expenses refund is calculated for
   * the way back (0,50 €/km. After 200 km, every additional km costs 0,25 €/km)
   * distanceCost - kaskoCost + wayBackCost;
   *
   * @param {number} hours
   * @param {number} distance - km
   * @param {number} driverDistance - km
   */
  private calculateCaregivingWithUsersCarCost() {
    const CAREGIVING_WITH_USERS_CAR_PRICE_PER_HOUR = 15;
    const timeCost = getHourlyIntervalTimeCost(
      this.duration,
      CAREGIVING_WITH_USERS_CAR_PRICE_PER_HOUR
    );

    // Kasko
    const CAREGIVING_WITH_USERS_CAR_KASKO_PER_50_KM = 2.5;
    const fiftyKmChunks = Math.ceil(this.travelDistance / 50);
    const kaskoCost = fiftyKmChunks * CAREGIVING_WITH_USERS_CAR_KASKO_PER_50_KM;

    console.log(
      `Kasko cost: ${kaskoCost} ((${this.travelDistance}/50)*${CAREGIVING_WITH_USERS_CAR_KASKO_PER_50_KM}`
    );

    // Wayback
    const wayBackDistance = Math.ceil(this.travelDistance); // km
    const wayBackCost = getDistanceCost(wayBackDistance + this.travelDistance);

    console.log(`Wayback distance considered: ${wayBackDistance}`);
    console.log(`Wayback cost: ${wayBackCost}`);

    const distanceCost = kaskoCost + wayBackCost;

    return timeCost + distanceCost;
  }

  private calculateCaregivingWithoutCarCost() {
    const CAREGIVING_WITHOUT_CAR_PRICE_PER_HOUR = 15;

    const hourlyCost = getHourlyIntervalTimeCost(
      this.duration,
      CAREGIVING_WITHOUT_CAR_PRICE_PER_HOUR
    );

    const distanceCost = getDistanceCost(this.driverDistance, 0);

    console.log(`Distance driver considered: ${this.driverDistance}`);
    console.log(`Caregiving duration considered: ${this.duration}h`);

    console.log(`Distance driver cost: ${distanceCost}`);
    console.log(`Caregiving duration cost: ${hourlyCost}`);

    return hourlyCost + distanceCost;
  }

  /**
   * @description +15 €/hour; every added hours cost (hour - 1; - 1€)
   */
  private calculateCaregivingViaTelemedicineCost() {
    const TELEMEDICINE_PRICE_PER_HOUR = 15;

    const hourlyCost = getHourlyIntervalTimeCost(
      this.duration,
      TELEMEDICINE_PRICE_PER_HOUR
    );

    const distanceCost = getDistanceCost(this.driverDistance, 0);

    console.log(`Distance driver considered: ${this.driverDistance}`);
    console.log(`Caregiving duration considered: ${this.duration}h`);

    console.log(`Distance driver cost: ${distanceCost}`);
    console.log(`Caregiving duration cost: ${hourlyCost}`);

    return hourlyCost + distanceCost;
  }

  private calculateErrandsCost() {
    const ERRANDS_FIRST_HOUR_COST = 12.5;
    const ERRANDS_SUBSEQUENT_PRICE_PER_HALF_HOUR = 6;

    const duration = (this.duration > 1 ? this.duration - 1 : 0) * 2;

    const subsequentHalfHoursCost = getHalfHourIntervalTimeCost(
      duration,
      ERRANDS_SUBSEQUENT_PRICE_PER_HALF_HOUR
    );

    // Don't calculate distance cost if it's lower than 3 kms in one way
    // Distance is calculated 2 ways (from A to B and from B to A)
    const distanceCost =
      this.travelDistance <= 3
        ? 0
        : getDistanceCost(this.travelDistance * 2 + this.driverDistance);

    console.log(`Distance driver considered: ${this.driverDistance}`);
    console.log(`Caregiving duration considered: ${this.duration}h`);

    console.log(`Distance cost: ${distanceCost}`);
    console.log(
      `Caregiving duration cost: ${
        ERRANDS_FIRST_HOUR_COST + subsequentHalfHoursCost
      }`
    );

    return ERRANDS_FIRST_HOUR_COST + subsequentHalfHoursCost + distanceCost;
  }

  private calculatePhoneAssistanceCost() {
    const PHONE_ASSISTANCE_PRICE_PER_HALF_HOUR = 5;
    const PHONE_ASSISTANCE_MIN_HOURS = 0.5;

    const duration =
      PHONE_ASSISTANCE_MIN_HOURS > this.duration
        ? PHONE_ASSISTANCE_MIN_HOURS
        : this.duration;

    return getHalfHourIntervalTimeCost(
      duration,
      PHONE_ASSISTANCE_PRICE_PER_HALF_HOUR
    );
  }
}
