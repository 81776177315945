import React, { useState } from 'react';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
	// @ts-ignore
} from 'react-places-autocomplete';

export const UgoMapsAutocomplete = ({ onLocationSelect }: any) => {
	const [address, setAddress] = useState('');

	const handleChange = (_address: any) => {
		setAddress(_address);
	};

	const handleSelect = async (_address: any) => {
		try {
			const latLng = await geocodeByAddress(_address).then((results: any) =>
				getLatLng(results[0]),
			);

			onLocationSelect({
				address: _address,
				...latLng,
			});
		} catch (err) {
			onLocationSelect({
				address: '',
				lat: '',
				lng: '',
			});

			console.error(err);
			throw err;
		}
	};

	return (
		<PlacesAutocomplete
			// @ts-ignore
			value={address}
			debounce={1000}
			onChange={handleChange}
			onSelect={handleSelect}
		>
			{({
				getInputProps,
				suggestions,
				getSuggestionItemProps,
				loading,
			}: any) => (
				<div>
					<input
						{...getInputProps({
							placeholder: 'Search Places ...',
							className: 'location-search-input',
						})}
					/>
					<div className="autocomplete-dropdown-container">
						{loading && <div>Loading...</div>}
						{suggestions.map((suggestion: any, idx: number) => {
							const className = suggestion.active
								? 'suggestion-item--active'
								: 'suggestion-item';
							// inline style for demonstration purpose
							const style = suggestion.active
								? { backgroundColor: '#fafafa', cursor: 'pointer' }
								: { backgroundColor: '#ffffff', cursor: 'pointer' };
							return (
								<div
									key={idx}
									{...getSuggestionItemProps(suggestion, {
										className,
										style,
									})}
								>
									<span>{suggestion.description}</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</PlacesAutocomplete>
	);
};
