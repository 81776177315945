import React, { useEffect } from 'react';
import { Edit } from 'react-admin';
import validator from 'validator';
import { ManualDriverForm } from './ManualDriverForm';
import { useUtilities } from '@ugo/utils';

// TODO: Move shared (form) logic to a separate component
export const ManualDriverEdit = (props: any) => {
  const { removeDeleteButtonFromDom } = useUtilities();

  useEffect(() => {
    removeDeleteButtonFromDom();
  });

  const validateManualDriverEdit = (values: any) => {
    const errors = {
      ...(['email', 'first_name', 'last_name', 'phone_number']
        .filter((v) => !values[v])
        .reduce((accumulator: any, value: any) => {
          accumulator[value] = `${value} is required`;
          return accumulator;
        }, {}) || {}),
    };

    if (!validator.isEmail(values.email || '')) {
      errors.email = `${values.email} is not a valid email`;
    }

    return errors;
  };

  return (
    <>
      <Edit {...props}>
        <ManualDriverForm {...props} validate={validateManualDriverEdit} />
      </Edit>
    </>
  );
};
