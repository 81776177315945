import * as React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { MANUAL_STATUSES } from '@ugo/data';
import { PREFERRED_DRIVER_ACTIONS } from '@ugo/data';
import { MANUAL_SERVICES } from '@ugo/data';
import { MANUAL_CITIES } from '@ugo/data';
import { B2B_PROJECT_NAMES } from '@ugo/data';
import { manualReservationExported } from '../../helpers/manual-reservation-exporter';
import { GLOBAL_EXPORTER_SETTINGS } from '@ugo/data';
import { CloneManualReservationLink } from '../manual-reservations/ManualReservationList';
import { Link } from 'react-router-dom';
import { Button } from 'ra-ui-materialui';
import { useDataProvider } from 'ra-core';
import { useEffect } from 'react';

const ManualReservationListFilter = (props: any) => {
  const dataProvider = useDataProvider();
  const [projectNames, setProjectNames] = React.useState<
    Array<{ id: string; name: string }>
  >([]);

  useEffect(() => {
    dataProvider
      .getList('project', {
        pagination: { page: 1, perPage: 9999999 },
        sort: { field: 'name', order: 'ASC' },
        filter: {},
      })
      .then(({ data }) => {
        setProjectNames(
          data.map((project: any) => ({ id: project.name, name: project.name }))
        );
      });
  }, []);

  return (
    <Filter {...props}>
      <TextInput source="id@_eq" label="Reservation Id" alwaysOn />
      <TextInput
        source="manual_driver_email@_ilike"
        label="Driver email"
        alwaysOn
      />
      <TextInput
        source="manual_patient_email@_ilike"
        label="Patient email"
        alwaysOn
      />
      <NumberInput source="reservor_id" label="Reservor Id" alwaysOn />
      <SelectInput source="status" choices={MANUAL_STATUSES} alwaysOn />
      <SelectInput
        source="preferred_driver_action"
        choices={PREFERRED_DRIVER_ACTIONS}
        alwaysOn
      />
      <SelectInput alwaysOn source="project_name" choices={projectNames} />
      <TextInput
        source="manual_driver_first_name@_ilike"
        label="Driver first name"
      />
      <TextInput
        source="manual_driver_last_name@_ilike"
        label="Driver last name"
      />
      <TextInput
        source="manual_patient_first_name@_ilike"
        label="Patient first name"
      />
      <TextInput
        source="manual_patient_last_name@_ilike"
        label="Patient last name"
      />

      <DateInput source="starts_at@_gte" label="Starts from" />
      <DateInput source="starts_at@_lte" label="Starts to" />
      <DateInput source="ends_at@_gte" label="Ends from" />
      <DateInput source="ends_at@_lte" label="Ends to" />
    </Filter>
  );
};

export const ManualReservationWithPreferredDriverList = (props: any) => (
  <List
    exporter={manualReservationExported}
    {...props}
    filters={<ManualReservationListFilter />}
    filterDefaultValues={{ has_preferred_driver: true }}
    sort={{ field: 'created_at', order: 'DESC' }}
    actions={<ManualReservationWithPreferredDriverListActions />}
  >
    <Datagrid>
      <TextField source="id" />
      <SelectField source="service" choices={MANUAL_SERVICES} />
      <SelectField source="service_city" choices={MANUAL_CITIES} />
      <TextField source="status" />
      <ReferenceField source="driver_id" reference="manual_driver">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="driver_preferred_id" reference="manual_driver">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="preferred_driver_action" />
      <ReferenceField
        source="reservor_id"
        reference="manual_patient"
        label="Patient (Reservor)"
      >
        <FunctionField
          render={(record) => {
            return `${record?.first_name} ${record?.last_name} (${record?.id})`;
          }}
        />
      </ReferenceField>
      <DateField source="starts_at" showTime />
      <TextField source="duration_estimate" />
      <TextField source="departure_address" />
      <TextField source="destination_address" />
      <BooleanField source="is_two_way" />
      <TextField source="price_estimate" />
      <TextField source="created_by" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <CustomEditButton basePath="/manual_reservation_with_preferred_driver" />
      <CloneManualReservationLink basePath="/manual_reservation_with_preferred_driver" />
    </Datagrid>
  </List>
);

const CustomEditButton = ({ basePath, customLink, record }: any) => {
  return (
    <Button
      label="View"
      component={Link}
      to={{
        pathname: `${basePath}/${record.id}`,
      }}
    />
  );
};

const ManualReservationWithPreferredDriverListActions = (props: any) => {
  const { className, basePath, total, resource, currentSort, filters } = props;

  const { showFilter, displayedFilters, filterValues } = useListContext();

  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={'/manual_reservation_with_preferred_driver'} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={manualReservationExported}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};
