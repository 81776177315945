import React, { useEffect } from 'react';
import { Button, Edit, TopToolbar, useNotify, useRefresh } from 'react-admin';
import { ManualReservationForm } from './ManualReservationForm';
import { useSendNewManualReservationEmailToAllDriversMutation } from '@ugo/graphql-operations';
import { useUtilities } from '@ugo/utils';

export const validateManualReservationEdit = (values: any) => {
  const errors = {
    ...([
      'service',
      'service_city',
      'status',
      'reservor_id',
      'starts_at',
      'duration_estimate',
      'departure_address',
      'destination_address',
      'price_estimate',
      'distance_billable',
      'project_name',
    ]
      .filter((v) => !values[v])
      .reduce((accumulator: any, value: any) => {
        accumulator[value] = `${value} is required`;
        return accumulator;
      }, {}) || {}),
  };

  return errors;
};

export const ManualReservationEdit = (props: any) => {
  const { removeDeleteButtonFromDom } = useUtilities();

  useEffect(() => {
    removeDeleteButtonFromDom();
  });

  return (
    <Edit
      {...props}
      actions={<ManualReservationEditActions />}
      mutationMode="pessimistic"
    >
      <ManualReservationForm
        {...props}
        validate={validateManualReservationEdit}
        submitOnEnter={false}
        isEdit={true}
      />
    </Edit>
  );
};

const ManualReservationEditActions = (props: any) => {
  const [
    sendNewManualReservationEmailToAllDrivers,
    { loading: isSendingEmailToAllDrivers },
  ] = useSendNewManualReservationEmailToAllDriversMutation();
  const notify = useNotify();
  const refresh = useRefresh();

  /**
   * @description Sends and email to relevant drivers
   *  - relevant driver === driver that matches service and service_city
   */
  const sendEmailToRelevantDrivers = async () => {
    try {
      await sendNewManualReservationEmailToAllDrivers({
        variables: {
          reservation_id: Number(props.data.id),
        },
      });

      notify('Email to all relevant drivers sent', 'info');
      refresh();
    } catch (err) {
      console.log(err);

      notify('Could not send email to relevant drivers', 'error');
      notify(JSON.stringify(err), 'error');

      throw err;
    }
  };

  return (
    <TopToolbar>
      {props?.data?.status === 'DRAFT' && (
        <>
          {isSendingEmailToAllDrivers ? (
            'Sending email...'
          ) : (
            <Button
              label="Send email to relevant drivers"
              color="primary"
              onClick={sendEmailToRelevantDrivers}
            />
          )}
        </>
      )}
    </TopToolbar>
  );
};
