import { getDistanceCost, getHourlyIntervalTimeCost } from '../utils';
import { CalculationData, CalculationStrategy } from './calculation-strategy';

export class CaregivingWithoutCarStrategy implements CalculationStrategy {
  calculate(data: CalculationData) {
    const CAREGIVING_WITHOUT_CAR_PRICE_PER_HOUR = 15;
    const caregivingDuration = data.caregivingDuration;

    if (!data?.travel) {
      throw new Error('Travel data is required');
    }

    if (!caregivingDuration && typeof caregivingDuration !== 'number') {
      throw new Error('Caregiving duration is required');
    }

    const driverDistance = data.travel.getDriverDistance();

    const timeCost = getHourlyIntervalTimeCost(
      caregivingDuration,
      CAREGIVING_WITHOUT_CAR_PRICE_PER_HOUR
    );

    const distanceCost = getDistanceCost(driverDistance, 0);

    return {
      timeCost,
      distanceCost,
      driverDistance,
      caregivingDuration,
      totalCost: timeCost + distanceCost,
    };
  }
}
