import React from 'react';
import { Chip } from '@material-ui/core';
import Box from '@material-ui/core/Box';

export const ChipArrayField = ({ record, source }: any) => {
	return <>
		{record[source].map((item: any) => (
			<Box
				key={item}
				m={0.5}
				display="inline-block"
			>
				<Chip label={item} />
			</Box>
		))}
	</>;
};
