import { useUtilities } from '@ugo/utils';
import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { format } from 'date-fns';
import { Box, Card } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { ChartComponentProps } from '@ugo/models';

export const TotalVsManualServicesLineChart = (props: ChartComponentProps) => {
  const { groupServiceCountByDay } = useUtilities();
  const [graphData, setGraphData] = useState<Array<number[]>>([]);
  const [days, setDays] = useState<string[]>([]);

  const { data } = useGetList(
    'reservation',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'ASC' },
    {
      'created_at@_gte': props.startDate,
      'created_at@_lte': props.endDate,
    }
  );

  const {
    days: _days,
    reservationsByDays,
    manualReservationsByDays,
  } = groupServiceCountByDay(data, [], props.startDate, props.endDate);

  // @ts-ignore
  useEffect(async () => {
    setDays(_days.map((d) => format(d, 'yyyy-MM-dd')));
    setGraphData([reservationsByDays, manualReservationsByDays]);
  }, [data]);

  return (
    <Card>
      <Box height="350px" p={2}>
        <Line
          data={{
            labels: reservationsByDays.map(
              (numberOfTotalDayReservations, idx) => {
                return (
                  (
                    (manualReservationsByDays[idx] /
                      numberOfTotalDayReservations || 0) * 100
                  ).toFixed(2) + `% - ${days[idx]}`
                );
              }
            ),
            datasets: [
              {
                label: 'All Reservations',
                data: graphData[0],
                lineTension: 0,
                pointRadius: 5,
                borderColor: colorScheme[0],
                backgroundColor: 'transparent',
              },
              {
                label: 'Manual Reservations',
                data: graphData[1],
                lineTension: 0,
                pointRadius: 5,
                borderColor: colorScheme[1],
                backgroundColor: 'transparent',
              },
            ],
          }}
          options={{
            title: {
              display: true,
              text: 'All / Manual services per day ratio',
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    precision: 0,
                    beginAtZero: true,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </Box>
    </Card>
  );
};
