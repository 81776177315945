import { CircularProgress } from '@material-ui/core';
import React from 'react';

/**
 * @description Shows a loading overlay page
 *  - Not fully implemented ATM, because of the missing tailwind implementation
 */
const UgoLoadingOverlayPage = () => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				height: '95vh',
				alignItems: 'center',
			}}
		>
			<CircularProgress
				style={{
					color: 'rgba(249, 178, 51)',
				}}
			/>
		</div>
		
		// TODO: Uncomment once tailwind is implemented
		// <div tw="fixed z-50 left-0 top-0 flex items-center justify-center w-full h-full bg-white">
		// 	<div tw="flex flex-col items-center">
		// 		<img src="/logo_transparent.png" tw="mb-3 h-10 object-contain" />
		// 		<CircularProgress size={32} thickness={5} />
		// 	</div>
		// </div>
	);
};

export default UgoLoadingOverlayPage;
