import React, { useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@material-ui/core';
import { useMutation, useNotify, useRefresh } from 'ra-core';
import { Carvoila_Driver_Status_Enum } from '@ugo/graphql-operations';
import { DOFRejectDriver } from '../../components/shared/DOFRejectDriver';

const CarvoilaCarvoilaDriverOnboardingFlowData = ({ driver }: any) => {
  const notify = useNotify();
  const [updateMutation] = useMutation();
  const refresh = useRefresh();
  const [rejectModalOpen, setRejectModalOpen] = useState(false);

  const updateApprovals = async (event: any, field: string) => {
    const value = event.target.checked;

    let timestampField;

    switch (field) {
      case 'is_typeform_documents_approved': {
        timestampField = 'typeform_documents_approved_at';
        break;
      }
      case 'is_video_verification_approved': {
        timestampField = 'video_verification_approved_at';
        break;
      }
    }

    const data = { [field]: value };

    if (timestampField) {
      data[timestampField] = value ? new Date() : null;
    }

    try {
      await updateMutation({
        type: 'update',
        resource: 'carvoila_driver',
        payload: {
          id: driver?.id,
          data,
        },
      });

      setTimeout(() => {
        refresh();
      }, 500);

      notify('Successfully updated approval', 'success');
    } catch (e) {
      console.log(e);
      notify('Error updating approval', 'error');
    }
  };

  const resendVideoaskEmail = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'carvoila_driver',
        payload: {
          id: driver?.id,
          data: { is_typeform_general_info_submitted: false },
        },
      },
      { returnPromise: true }
    );

    await updateMutation(
      {
        type: 'update',
        resource: 'carvoila_driver',
        payload: {
          id: driver?.id,
          data: { is_typeform_general_info_submitted: true },
        },
      },
      { returnPromise: true }
    );

    notify('Videoask email sent.', 'success');
  };

  const resendDocumentsTypeformEmail = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'carvoila_driver',
        payload: {
          id: driver?.id,
          data: { is_gforms_academy_completed: false },
        },
      },
      { returnPromise: true }
    );

    await updateMutation(
      {
        type: 'update',
        resource: 'carvoila_driver',
        payload: {
          id: driver?.id,
          data: {
            is_gforms_academy_completed: true,
            is_typeform_documents_submitted: false,
          },
        },
      },
      { returnPromise: true }
    );

    notify('Documents typeform email sent.', 'success');
  };

  const resendAcademyEmail = async () => {
    const previousDocumentVerificationStatus =
      driver?.is_typeform_documents_submitted;

    await updateMutation(
      {
        type: 'update',
        resource: 'carvoila_driver',
        payload: {
          id: driver?.id,
          data: { is_video_verification_submitted: false },
        },
      },
      { returnPromise: true }
    );
    await updateMutation(
      {
        type: 'update',
        resource: 'carvoila_driver',
        payload: {
          id: driver?.id,
          data: {
            is_video_verification_submitted: previousDocumentVerificationStatus,
          },
        },
      },
      { returnPromise: true }
    );
    notify('Academy email sent.', 'success');
  };

  const approveDriver = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'carvoila_driver',
        payload: {
          id: driver?.id,
          data: {
            status: Carvoila_Driver_Status_Enum.Approved,
            dof_approved_at: new Date(),
          },
        },
      },
      { returnPromise: true }
    );

    notify(
      'Driver successfully approved. Please modify their cities and services.',
      'success'
    );
  };

  const handleRejectDriver = async ({ rejectedReason }: any) => {
    setRejectModalOpen(false);
    try {
      await updateMutation(
        {
          type: 'update',
          resource: 'carvoila_driver',
          payload: {
            id: driver?.id,
            data: {
              status: Carvoila_Driver_Status_Enum.DofRejected,
              dof_rejected_reason: rejectedReason,
              dof_rejected_at: new Date(),
            },
          },
        },
        { returnPromise: true }
      );
      notify(
        'Driver successfully rejected. They will be notified via email.',
        'success'
      );
    } catch (e) {
      notify('Error rejecting user. Try again later.', 'error');
    }
  };

  return (
    <Box position="relative">
      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>1. General Info (Typeform)</span>
          &nbsp;&nbsp;
          {driver?.is_typeform_general_info_submitted && (
            <CheckIcon style={{ color: 'green' }} />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box mb={4}>
              General info submitted:&nbsp;&nbsp;
              <CheckIcon style={{ color: 'green' }} />
            </Box>
            {driver?.is_typeform_general_info_submitted && (
              <Box>
                {Object.keys(driver?.typeform_general_info_payload).map((k) => {
                  return (
                    <Box key={k} mb={3}>
                      <Box>
                        <span style={{ fontWeight: 'bold' }}>
                          {k.replace('*', '')}
                        </span>
                      </Box>
                      <Box>
                        {typeof driver?.typeform_general_info_payload[k] !==
                        'boolean'
                          ? driver?.typeform_general_info_payload[k]
                          : driver?.typeform_general_info_payload[k]
                          ? 'Si'
                          : 'No'}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>
            2. Video verification (Videoask)
          </span>
          {driver?.is_video_verification_submitted &&
            driver?.is_video_verification_approved && (
              <CheckIcon style={{ color: 'green' }} />
            )}

          {driver?.is_video_verification_submitted &&
            !driver?.is_video_verification_approved && (
              <>
                <span>&nbsp;|&nbsp; Submitted: </span>
                <CheckIcon
                  style={{ color: 'green' }}
                /> &nbsp;|&nbsp;Approved: <ClearIcon style={{ color: 'red' }} />
              </>
            )}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box>
              <Box mb={4}>
                Video response approved:{' '}
                {/*<ReferenceInput reference="driver_profile" source="account_id">*/}
                <input
                  type="checkbox"
                  defaultChecked={driver?.is_video_verification_approved}
                  onChange={(e) =>
                    updateApprovals(e, 'is_video_verification_approved')
                  }
                />
                <div>
                  <small>
                    (once HR reviews the video response, they should approve by
                    ticking the checkbox)
                  </small>
                </div>
                {/*</ReferenceInput>*/}
              </Box>
              <a
                href={driver?.video_verification_uri}
                target="_blank"
                rel="noreferrer"
              >
                Videoask Response
              </a>
              {driver?.is_typeform_general_info_submitted && (
                <Box mt={4}>
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    onClick={resendVideoaskEmail}
                  >
                    Resend Videoask email
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>
            3. Academy (Google Form){' '}
            {driver?.is_gforms_academy_completed && (
              <CheckIcon style={{ color: 'green' }} />
            )}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            Academy completed:{' '}
            {driver?.is_gforms_academy_completed && (
              <CheckIcon style={{ color: 'green' }} />
            )}
            <Box>
              {driver?.gforms_academy_payload
                ? Object.keys(driver?.gforms_academy_payload).map((k) => {
                    return (
                      <Box key={k} mb={3}>
                        <Box>
                          <span style={{ fontWeight: 'bold' }}>
                            {k.replace('*', '')}
                          </span>
                        </Box>
                        <Box>
                          {typeof driver?.gforms_academy_payload[k] !==
                          'boolean'
                            ? driver?.gforms_academy_payload[k]
                            : driver?.gforms_academy_payload[k]
                            ? 'Si'
                            : 'No'}
                        </Box>
                      </Box>
                    );
                  })
                : 'No data yet'}
            </Box>
            {driver?.is_video_verification_submitted && (
              <Box mt={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={resendAcademyEmail}
                >
                  Resend Academy Email
                </Button>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>
            4. Document verification (Typeform)
          </span>
          <Box>
            {driver?.is_typeform_documents_submitted &&
              driver?.is_typeform_documents_approved && (
                <CheckIcon style={{ color: 'green' }} />
              )}

            {driver?.is_typeform_documents_submitted &&
              !driver?.is_typeform_documents_approved && (
                <>
                  <span>&nbsp;|&nbsp; Submitted: </span>
                  <CheckIcon style={{ color: 'green' }} />{' '}
                  &nbsp;|&nbsp;Approved: <ClearIcon style={{ color: 'red' }} />
                </>
              )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box mb={4}>
              Document verification approved:{' '}
              {/*<ReferenceInput reference="driver_profile" source="account_id">*/}
              <input
                type="checkbox"
                defaultChecked={driver?.is_typeform_documents_approved}
                onChange={(e) =>
                  updateApprovals(e, 'is_typeform_documents_approved')
                }
              />
              <div>
                <small>
                  (once HR reviews the documents, they should approve by ticking
                  the checkbox)
                </small>
              </div>
              {driver?.is_gforms_academy_completed && (
                <Box mt={4}>
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    onClick={resendDocumentsTypeformEmail}
                  >
                    Resend documents typeform email
                  </Button>
                </Box>
              )}
            </Box>
            <Box>
              {driver?.is_typeform_documents_submitted && (
                <Box>
                  {Object.keys(driver?.typeform_documents_payload).map((k) => {
                    return (
                      <Box key={k} mb={3}>
                        <Box>
                          <span style={{ fontWeight: 'bold' }}>
                            {k.replace('*', '')}
                          </span>
                        </Box>
                        <Box>
                          {typeof driver?.typeform_documents_payload[k] !==
                          'boolean'
                            ? driver?.typeform_documents_payload[k]
                            : driver?.typeform_documents_payload[k]
                            ? 'Si'
                            : 'No'}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
            {driver?.typeform_documents_payload && (
              <Box display="flex" flexWrap="wrap">
                <Box width={1 / 2}>
                  <Box p={1}>
                    <h6>
                      Green pass{' '}
                      <small>
                        <a
                          href={`${
                            driver?.typeform_documents_payload[
                              'covid_passport'
                            ] ||
                            driver?.typeform_documents_payload[
                              '*Allega il tuo Green Pass*'
                            ]
                          }`}
                          download
                        >
                          Download
                        </a>
                      </small>
                    </h6>
                    <img
                      width="100%"
                      src={`${
                        driver?.typeform_documents_payload['covid_passport'] ||
                        driver?.typeform_documents_payload[
                          '*Allega il tuo Green Pass*'
                        ]
                      }`}
                    />
                  </Box>
                </Box>
                <Box width={1 / 2}>
                  <Box p={1}>
                    <h6>
                      Drivers license (front){' '}
                      <small>
                        <a
                          href={`${
                            driver?.typeform_documents_payload[
                              'driver_license_front'
                            ] ||
                            driver?.typeform_documents_payload[
                              '*Allega qui la tua Patente (fronte)*'
                            ]
                          }`}
                          download
                        >
                          Download
                        </a>
                      </small>
                    </h6>
                    <img
                      width="100%"
                      src={`${
                        driver?.typeform_documents_payload[
                          'driver_license_front'
                        ] ||
                        driver?.typeform_documents_payload[
                          '*Allega qui la tua Patente (fronte)*'
                        ]
                      }`}
                    />
                  </Box>
                </Box>
                <Box width={1 / 2}>
                  <Box p={1}>
                    <h6>
                      Drivers license (back){' '}
                      <small>
                        <a
                          href={`${
                            driver?.typeform_documents_payload[
                              'driver_license_back'
                            ] ||
                            driver?.typeform_documents_payload[
                              '*Allega qui la tua Patente (retro)*'
                            ]
                          }`}
                          download
                        >
                          Download
                        </a>
                      </small>
                    </h6>
                    <img
                      width="100%"
                      src={`${
                        driver?.typeform_documents_payload[
                          'driver_license_back'
                        ] ||
                        driver?.typeform_documents_payload[
                          '*Allega qui la tua Patente (retro)*'
                        ]
                      }`}
                    />
                  </Box>
                </Box>
                <Box width={1 / 2}>
                  <Box p={1}>
                    <h6>
                      Image{' '}
                      <small>
                        <a
                          href={`${
                            driver?.typeform_documents_payload['selfie'] ||
                            driver?.typeform_documents_payload[
                              '*Allega qui una tua foto - mezzo busto*'
                            ]
                          }`}
                          download
                        >
                          Download
                        </a>
                      </small>
                    </h6>
                    <img
                      width="100%"
                      src={`${
                        driver?.typeform_documents_payload['selfie'] ||
                        driver?.typeform_documents_payload[
                          '*Allega qui una tua foto - mezzo busto*'
                        ]
                      }`}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box
        py={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          Driver approval status: <b>{driver?.status}</b>
          <br />
          {(!driver?.is_video_verification_approved ||
            !driver?.is_typeform_documents_approved ||
            !driver?.is_gforms_academy_completed) && (
            <small>
              In order to approve driver, please approve{' '}
              <b>
                Video, Document (id and driver license) verification and
                <br /> Academy completion.
              </b>
            </small>
          )}
        </Box>
        {driver?.status !== Carvoila_Driver_Status_Enum.Approved && (
          <Box>
            <Button
              disabled={
                !driver.is_video_verification_approved ||
                !driver.is_typeform_documents_approved ||
                !driver.is_gforms_academy_completed
              }
              type="button"
              variant="outlined"
              color="primary"
              onClick={approveDriver}
            >
              Approve driver
            </Button>
          </Box>
        )}
      </Box>
      {driver?.status === Carvoila_Driver_Status_Enum.Pending && (
        <Box display="flex" justifyContent="between">
          <Box pr={4}>
            Driver can be rejected at any time during the application process
            before approval.
          </Box>
          <Box flexShrink={0}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => setRejectModalOpen(true)}
            >
              Reject driver
            </Button>
          </Box>
          <DOFRejectDriver
            isOpen={rejectModalOpen}
            onResolve={handleRejectDriver}
            onReject={() => setRejectModalOpen(false)}
          />
        </Box>
      )}
    </Box>
  );
};

export default CarvoilaCarvoilaDriverOnboardingFlowData;
