import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Box, Button, Dialog } from '@material-ui/core';
import { DateTimeInput, NumberInput, TextInput, useNotify } from 'react-admin';

export const AddMissingOverdueReservationDataModal = ({
  isOpen,
  onResolve,
  onReject,
  form,
  ...rest
}: any) => {
  const notify = useNotify();

  return (
    <Dialog
      open={isOpen}
      onClose={(e) => onReject(e)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Fill missing data</DialogTitle>
      <DialogContent>
        <Box display="flex" flexWrap="wrap">
          <Box width={1}>
            <DateTimeInput
              fullWidth
              source="started_at"
              onChange={form.mutators.updateReservationDurationBillable}
            />
          </Box>
          <Box width={1}>
            <DateTimeInput
              fullWidth
              source="ended_at"
              onChange={form.mutators.updateReservationDurationBillable}
            />
          </Box>
          <Box width={1}>
            <NumberInput fullWidth source="price_billable" />
          </Box>
          <Box width={1}>
            <NumberInput
              fullWidth
              source="duration_billable"
              onChange={form.mutators.updateReservationPriceBillable}
            />
          </Box>
          <Box width={1}>
            <NumberInput
              fullWidth
              source="extra_costs_value"
              onChange={form.mutators.updateReservationPriceBillable}
            />
          </Box>
          <Box width={1}>
            <TextInput fullWidth source="extra_costs_text" />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          variant={'outlined'}
          onClick={() => onReject()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => {
            const { ended_at } = form.getState().values;
            if (!ended_at) {
              notify('Please fill out the finish form', 'error');
            } else {
              onResolve();
            }
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
