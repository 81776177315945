import React, { useEffect, useState } from 'react';
import { colorScheme } from '@ugo/data';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { useUtilities } from '@ugo/utils';

export const ConversionsPerDayLineChartWithVariantId = ({
  reservations,
  startDate,
  endDate,
}: {
  reservations: any[];
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { groupServiceCountByDay } = useUtilities();
  const [graphData, setGraphData] = useState<Array<number[]>>([]);
  const [days, setDays] = useState<string[]>([]);

  useEffect(() => {
    const variant0reservations = reservations.filter(
      (r) => r.reservation_metadata.variant_id === '0'
    );

    const variant1reservations = reservations.filter(
      (r) => r.reservation_metadata.variant_id === '1'
    );

    const variant2reservations = reservations.filter(
      (r) => r.reservation_metadata.variant_id === '2'
    );

    const { days, reservationsByDays } = groupServiceCountByDay(
      reservations,
      [],
      startDate,
      endDate
    );

    const { reservationsByDays: servicesByDaysByVariant0 } =
      groupServiceCountByDay(variant0reservations, [], startDate, endDate);

    const { reservationsByDays: servicesByDaysByVariant1 } =
      groupServiceCountByDay(variant1reservations, [], startDate, endDate);

    const { reservationsByDays: servicesByDaysByVariant2 } =
      groupServiceCountByDay(variant2reservations, [], startDate, endDate);

    setDays(days.map((d) => format(d, 'yyyy-MM-dd')));
    setGraphData([
      reservationsByDays,
      servicesByDaysByVariant0,
      servicesByDaysByVariant1,
      servicesByDaysByVariant2,
    ]);
  }, [reservations, startDate, endDate]);

  return (
    <Line
      data={{
        labels: days,
        datasets: [
          {
            label: 'Total first reservations',
            data: graphData[0],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[0],
            backgroundColor: 'transparent',
          },
          {
            label: 'Variant 0',
            data: graphData[1],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[1],
            backgroundColor: 'transparent',
          },
          {
            label: 'Variant 1',
            data: graphData[2],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[2],
            backgroundColor: 'transparent',
          },
          {
            label: 'Variant 2',
            data: graphData[3],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[3],
            backgroundColor: 'transparent',
          },
        ],
      }}
      options={{
        title: {
          display: true,
          text: 'Conversions per day',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
      }}
    />
  );
};
