import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Dialog } from '@material-ui/core';
import { Button } from '@material-ui/core';

export const DOFRejectDriver = ({ isOpen, onResolve, onReject }: any) => {
  const [rejectedReason, setRejectedReason] = React.useState('');

  return (
    <Dialog
      open={isOpen}
      onClose={(e) => onReject(e)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Reject driver</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Driver will be rejected and email will be sent out to the driver.
          <br />
          <br />
          Reason:
          <br />
          <textarea
            style={{
              width: '100%',
            }}
            value={rejectedReason}
            onChange={(e) => setRejectedReason(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          variant={'outlined'}
          onClick={() => onReject()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => onResolve({ rejectedReason })}
        >
          Reject driver
        </Button>
      </DialogActions>
    </Dialog>
  );
};
