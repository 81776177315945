import {
  AutocompleteInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import React from 'react';

export function VoucherCreate(props) {
  return (
    <Create {...props}>
      {/*<FormWithRedirect {...props}></FormWithRedirect>*/}
      <SimpleForm {...props}>
        <TextInput label="Code" source="code" />
        <BooleanInput label="Enabled" source="enabled" defaultChecked={true} />
        <BooleanInput
          label="First service only"
          source="first_reservation_only"
          defaultChecked={false}
        />
        <NumberInput label="Percent discount" source="percent_value" />
        <ReferenceInput
          label="Referrer"
          source="referrer_id"
          reference="account"
          filterToQuery={(searchText) => ({
            email: searchText,
          })}
        >
          <AutocompleteInput optionText="email" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
