import { getDistanceCost, getHalfHourIntervalTimeCost } from '../utils';
import { CalculationData, CalculationStrategy } from './calculation-strategy';

export class CaregivingErrandsStrategy implements CalculationStrategy {
  calculate(data: CalculationData) {
    const ERRANDS_FIRST_HOUR_COST = 12.5;
    const ERRANDS_SUBSEQUENT_PRICE_PER_HALF_HOUR = 6;

    if (!data?.travel) {
      throw new Error('Travel data is required');
    }

    if (
      !data?.caregivingDuration &&
      typeof data.caregivingDuration !== 'number'
    ) {
      throw new Error('Caregiving duration is required');
    }

    const travelDistance = data.travel.getTravelDistance();
    const driverDistance = data.travel.getDriverDistance();

    const duration =
      (data.caregivingDuration > 1 ? data.caregivingDuration - 1 : 0) * 2;

    const subsequentHalfHoursCost = getHalfHourIntervalTimeCost(
      duration,
      ERRANDS_SUBSEQUENT_PRICE_PER_HALF_HOUR
    );

    // Don't calculate distance cost if it's lower than 3 kms in one way
    // Distance is calculated 2 ways (from A to B and from B to A)
    const distanceCost =
      travelDistance <= 3
        ? 0
        : getDistanceCost(travelDistance * 2 + driverDistance);

    const timeCost = ERRANDS_FIRST_HOUR_COST + subsequentHalfHoursCost;
    const total =
      ERRANDS_FIRST_HOUR_COST + subsequentHalfHoursCost + distanceCost;

    return {
      timeCost,
      distanceCost,
      totalCost: total,
      driverDistance,
      travelDistance,
    };
  }
}
