import * as React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { ResourceComponentProps } from 'ra-core';

export const DistrictList = (props: ResourceComponentProps) => (
  <List
    bulkActionButtons={false}
    {...props}
    sort={{
      field: 'id',
      order: 'ASC',
    }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" emptyText="n/a" label="District" />
    </Datagrid>
  </List>
);
