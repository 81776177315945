import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import { TextInput } from 'ra-ui-materialui';

export const DistrictCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm {...props} submitOnEnter={false}>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};
