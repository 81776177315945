import {
  downloadCSV,
  ExportButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import * as React from 'react';
import { BooleanInput, Datagrid } from 'ra-ui-materialui';
import { Carvoila_Driver, Driver_Profile } from '@ugo/graphql-operations';
import { unparse as convertToCSV } from 'papaparse';
import { flattenObjectWithParentKeys, useUtilities } from '@ugo/utils';
import { GLOBAL_EXPORTER_SETTINGS } from '@ugo/data';

export const driverProfileExporter = async (
  driverProfiles: Driver_Profile[]
) => {
  const _driverProfiles = driverProfiles.map((driverProfile) => {
    const covid_passport_payload = flattenObjectWithParentKeys(
      driverProfile.covid_passport_payload,
      'covid_passport_payload'
    );

    const general_info_payload = flattenObjectWithParentKeys(
      driverProfile.general_info_payload,
      'general_info_payload'
    );

    const object: any = {
      account_id: driverProfile.account_id,
      // @ts-ignore
      iban_connected: driverProfile?.iban_connected || '',
      // @ts-ignore
      email: driverProfile?.email || '',
      ...covid_passport_payload,
      ...general_info_payload,
    };

    const formatted_object: { [key: string]: any } = {};
    Object.keys(object).forEach((key) => {
      formatted_object[key.replace(/(,|\s|\*|\.)/g, '_')] = object[key];
    });

    return formatted_object;
  });

  const objectWithMaxKeys = _driverProfiles.reduce((a, b) =>
    Object.keys(a).length > Object.keys(b).length ? a : b
  );

  // Hacking
  const csv = convertToCSV(
    { data: _driverProfiles, fields: Object.keys(objectWithMaxKeys) },
    { delimiter: ';' }
  );

  downloadCSV(csv, 'driver_profiles_' + Date.now());
};

const DriverProfileListActions = (props: any) => {
  const {
    className,
    total,
    resource,
    currentSort,
    filters,
    showFilter,
    displayedFilters,
    filterValues,
  } = props;
  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};

export const DriverProfilesList = (props: any) => {
  return (
    <List
      exporter={driverProfileExporter}
      actions={<DriverProfileListActions />}
      resource="driver_profile"
      basePath={props?.match?.url}
      filters={
        <Filter {...props}>
          <BooleanInput
            alwaysOn
            source={'is_typeform_documents_submitted'}
            label="Has Typeform documents data"
          />
          <BooleanInput
            alwaysOn
            source={'is_covid_passport_submitted'}
            label="Has Covid passport data"
          />
        </Filter>
      }
      {...props}
      bulkActionButtons={false}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField source="account_id" reference="account">
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
