import React, { useState } from 'react';
import { Box, Card, Paper, Tab, Tabs } from '@material-ui/core';
import { Bar, Line } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { identity, pipe } from 'rxjs';
import { countBy, map, merge, values } from 'ramda';
import { TabPanel } from '../shared/TabPanel';
import { useGetReservationFrequencyByPatientAccountsQuery } from '@ugo/graphql-operations';

export const ReservationFrequencySinceFirstReservation = () => {
  const [value, setValue] = useState(0);
  const [reservationFrequency, setReservationFrequency] = useState([]);

  useGetReservationFrequencyByPatientAccountsQuery({
    onCompleted: (data) => {
      setReservationFrequency(
        data?.vw_stats_reservation_frequency_by_patient_accounts || []
      );
    },
  });

  const handleTabChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const datasetKeyProvider = () => {
    return btoa(Math.random()).substring(0, 12);
  };

  const numOfDisplayedDays = 90;
  const numOfDisplayedDaysArray = Array.from({
    length: numOfDisplayedDays,
  }).map((_) => 0);

  return (
    <Box>
      <Box mt={3}>
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            color="white"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab label={`Reservation frequency w/ reservation count per day`} />
            <Tab label={`Reservation frequency per day`} />
          </Tabs>
        </Paper>
      </Box>
      <TabPanel value={value} index={0}>
        <Paper>
          <Card>
            <Box p={2}>
              <Bar
                datasetKeyProvider={datasetKeyProvider}
                data={{
                  labels: Array.from({ length: 90 }).map((_, idx) => idx),
                  datasets: reservationFrequency.map((rf, idx) => ({
                    label: `${rf.email} (${rf.reservation_count})`,
                    data: pipe(
                      countBy(identity),
                      merge(numOfDisplayedDaysArray),
                      values
                    )(rf.days_since_first_reservation),
                    backgroundColor: colorScheme[idx],
                    hidden: idx >= 3,
                  })),
                }}
                options={{
                  legend: {
                    position: 'bottom',
                  },
                  title: {
                    display: true,
                    text: 'Reservation frequency w/ reservation count per day per converted patient since the first reservation - first 90 days period',
                  },
                  scales: {
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: 'Reservation count',
                        },
                        ticks: {
                          precision: 0,
                          beginAtZero: true,
                          min: 0,
                        },
                      },
                    ],
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString:
                            'Days since the first reservation (conversion)',
                        },
                      },
                    ],
                  },
                }}
              />
            </Box>
          </Card>
        </Paper>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Card pt={2}>
          <Box p={2}>
            <Line
              datasetKeyProvider={datasetKeyProvider}
              data={{
                labels: Array.from({ length: 90 }).map((_, idx) => idx),
                datasets: reservationFrequency.map((rf, idx) => ({
                  label: `${rf.email} (${rf.reservation_count})`,
                  data: pipe(
                    countBy(identity),
                    merge(numOfDisplayedDaysArray),
                    values,
                    map((item) => (item ? idx + 1 : null))
                  )(rf.days_since_first_reservation),
                  pointBackgroundColor: colorScheme[idx],
                  pointRadius: 8,
                  backgroundColor: colorScheme[idx],
                  fill: false,
                  showLine: false,
                  hidden: idx >= 3,
                })),
              }}
              options={{
                legend: {
                  position: 'bottom',
                },
                title: {
                  display: true,
                  text: 'Reservation frequency per day per converted patient since the first reservation - first 90 days period',
                },
                scales: {
                  yAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: 'Patient accounts',
                      },
                      ticks: {
                        precision: 0,
                        beginAtZero: true,
                        min: 0,
                        callback: (value, index, values) => {
                          return reservationFrequency[value - 1]?.email;
                        },
                      },
                    },
                  ],
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString:
                          'Days since the first reservation (conversion)',
                      },
                    },
                  ],
                },
              }}
            />
          </Box>
        </Card>
      </TabPanel>
    </Box>
  );
};
