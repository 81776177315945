import * as React from 'react';
import {
  ArrayInput,
  Datagrid,
  DateTimeInput,
  Edit,
  FormWithRedirect,
  FunctionField,
  ImageField,
  List,
  ResourceComponentProps,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TextField,
  TextInput,
} from 'react-admin';
import { Box, Toolbar } from '@material-ui/core';
import { TRAFFIC_SOURCES } from '@ugo/data';
import { DateInput } from 'ra-ui-materialui';

export const ReviewEdit = (props: ResourceComponentProps) => {
  return (
    <>
      <Edit {...props}>
        <FormWithRedirect
          {...props}
          render={({ record, form, ...formProps }) => (
            <form>
              <Box p="1em">
                <Box>
                  <Box>
                    <TextInput
                      label="Review Source"
                      source="review_source"
                      fullWidth
                      disabled
                    />
                  </Box>
                  <Box>
                    <TextInput
                      label="Source ID"
                      source="source_review_id"
                      fullWidth
                      disabled
                    />
                  </Box>
                  <Box>
                    <TextInput
                      label="Rating"
                      source="rating"
                      disabled
                      fullWidth
                    />
                  </Box>
                  <Box>
                    {/*<TextInput label="Tags" source="tags" fullWidth />*/}

                    <ArrayInput source="tags">
                      <SimpleFormIterator>
                        <TextInput />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Box>
                  <Box>
                    <ImageField source="reviewer_photo_url"></ImageField>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 16,
                  }}
                >
                  <Box>
                    <DateTimeInput
                      source="source_created_at"
                      disabled
                      fullWidth
                    ></DateTimeInput>
                  </Box>
                  <Box>
                    <DateTimeInput
                      source="source_updated_at"
                      disabled
                      fullWidth
                    ></DateTimeInput>
                  </Box>

                  <Box>
                    <TextInput
                      label="Reviewer Name"
                      source="reviewer_name"
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TextInput
                      label="Source Reviewer Name"
                      source="source_reviewer_name"
                      fullWidth
                      disabled
                    />
                  </Box>

                  <Box>
                    <TextInput
                      label="Comment (ITA)"
                      source="comment_ita"
                      multiline
                      rows="6"
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TextInput
                      label="Source Comment (ITA)"
                      source="source_comment_ita"
                      multiline
                      rows="6"
                      fullWidth
                      disabled
                    />
                  </Box>

                  <Box>
                    <TextInput
                      label="Reply (ITA)"
                      source="reply_ita"
                      multiline
                      rows="6"
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TextInput
                      label="Source Reply (ITA)"
                      source="source_reply_ita"
                      multiline
                      rows="6"
                      fullWidth
                      disabled
                    />
                  </Box>

                  <Box>
                    <TextInput
                      label="Comment (ENG)"
                      source="comment_eng"
                      multiline
                      rows="6"
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TextInput
                      label="Source Comment (ENG)"
                      source="source_comment_eng"
                      multiline
                      rows="6"
                      fullWidth
                      disabled
                    />
                  </Box>
                </Box>
              </Box>
              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <SaveButton
                    redirect={false}
                    disabled={formProps.pristine}
                    saving={formProps.saving}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />
                </Box>
              </Toolbar>
            </form>
          )}
        />
      </Edit>
    </>
  );
};
