import * as React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Button,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import {
  B2B_PROJECT_NAMES,
  GLOBAL_EXPORTER_SETTINGS,
  MANUAL_CITIES,
  MANUAL_SERVICES,
  MANUAL_STATUSES,
  ManualStatusEnum,
} from '@ugo/data';
import { manualReservationExported } from '../../helpers/manual-reservation-exporter';
import { Link } from 'react-router-dom';
import { useDataProvider } from 'ra-core';
import { useEffect } from 'react';

const disabledProjectIds = [
  12, 17, 25, 36, 40, 41, 42, 4, 9, 11, 14, 16, 20, 21, 23, 27, 28, 29, 30, 32,
  33, 35, 37, 38, 39, 43, 44, 45, 46, 47, 49, 50, 52, 53, 68, 2, 3, 5, 6, 7, 8,
  10, 13, 15, 18, 19, 22, 24, 26, 31, 34, 48, 51,
];

const ManualReservationListFilter = (props: any) => {
  const dataProvider = useDataProvider();
  const [projectNames, setProjectNames] = React.useState<
    Array<{ id: string; name: string }>
  >([]);

  useEffect(() => {
    dataProvider
      .getList('project', {
        pagination: { page: 1, perPage: 9999999 },
        sort: { field: 'name', order: 'ASC' },
        filter: {},
      })
      .then(({ data }) => {
        setProjectNames(
          data
            .filter(
              (project) => !disabledProjectIds.includes(Number(project.id))
            )
            .map((project: any) => ({ id: project.name, name: project.name }))
        );
      });
  }, []);
  return (
    <Filter {...props}>
      <TextInput source="id@_eq" label="Reservation Id" alwaysOn />
      <TextInput
        source="manual_driver_first_name@_ilike"
        label="Driver first name"
      />
      <TextInput
        source="manual_driver_last_name@_ilike"
        label="Driver last name"
      />
      <TextInput
        source="manual_patient_first_name@_ilike"
        label="Patient first name"
      />
      <TextInput
        source="manual_patient_last_name@_ilike"
        label="Patient last name"
      />
      <TextInput
        source="manual_driver_email@_ilike"
        label="Driver email"
        alwaysOn
      />
      <TextInput
        source="manual_patient_email@_ilike"
        label="Patient email"
        alwaysOn
      />

      <NumberInput source="reservor_id" label="Reservor Id" alwaysOn />
      <SelectInput source="status" choices={MANUAL_STATUSES} alwaysOn />
      <SelectInput alwaysOn source="project_name" choices={projectNames} />
      <DateInput source="starts_at@_gte" label="Starts from" />
      <DateInput source="starts_at@_lte" label="Starts to" />
      <DateInput source="ends_at@_gte" label="Ends from" />
      <DateInput source="ends_at@_lte" label="Ends to" />
    </Filter>
  );
};

export const CloneManualReservationLink = ({ record, basePath }: any) => {
  const duplicateRecord = {
    ...record,
    status: ManualStatusEnum.Draft,
    duplicate: true,
  };

  delete duplicateRecord.id;
  delete duplicateRecord.driver_id;
  delete duplicateRecord.preferred_driver_action;
  delete duplicateRecord.has_preferred_driver;
  delete duplicateRecord.started_at;
  delete duplicateRecord.ended_at;
  delete duplicateRecord.starts_at;
  delete duplicateRecord.ends_at;
  delete duplicateRecord.created_at;
  delete duplicateRecord.updated_at;
  delete duplicateRecord.id;
  delete duplicateRecord.activity_log;
  delete duplicateRecord.created_by;
  delete duplicateRecord.manual_patient_first_name;
  delete duplicateRecord.manual_patient_last_name;
  delete duplicateRecord.manual_driver_first_name;
  delete duplicateRecord.manual_driver_last_name;
  delete duplicateRecord.manual_patient_email;
  delete duplicateRecord.manual_driver_email;

  return (
    <Button
      label="Duplicate"
      component={Link}
      to={{
        pathname: `${basePath}/create`,
        state: {
          record: duplicateRecord,
        },
      }}
    />
  );
};

const CustomEditButton = ({ basePath, customLink, record }: any) => {
  return (
    <Button
      label="View"
      component={Link}
      to={{
        pathname: `/manual_reservation/${record.id}`,
      }}
    />
  );
};

export const ManualReservationList = (props: any) => (
  <List
    exporter={manualReservationExported}
    {...props}
    filterDefaultValues={{ has_preferred_driver: false }}
    filters={<ManualReservationListFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    actions={<ManualReservationListActions />}
  >
    <Datagrid>
      <TextField source="id" />
      <SelectField source="service" choices={MANUAL_SERVICES} />
      <SelectField source="service_city" choices={MANUAL_CITIES} />
      <TextField source="status" />
      <ReferenceField source="driver_id" reference="manual_driver">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        source="reservor_id"
        reference="manual_patient"
        label="Patient (Reservor)"
      >
        <FunctionField
          render={(record) => {
            return `${record?.first_name} ${record?.last_name} (${record?.id})`;
          }}
        />
      </ReferenceField>
      <DateField source="starts_at" showTime />
      <TextField source="duration_estimate" />
      <TextField source="departure_address" />
      <TextField source="destination_address" />
      <BooleanField source="is_two_way" />
      <TextField source="price_estimate" />
      <TextField source="created_by" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <CustomEditButton />
      <CloneManualReservationLink basePath="/manual_reservation" />
    </Datagrid>
  </List>
);

const ManualReservationListActions = (props: any) => {
  const { className, basePath, total, resource, currentSort, filters } = props;

  const { showFilter, displayedFilters, filterValues } = useListContext();

  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={'/manual_reservation'} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={manualReservationExported}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};
