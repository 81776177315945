import React, { useEffect, useState } from 'react';
import {
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { MANUAL_CITIES } from '@ugo/data';
import { MANUAL_SERVICES } from '@ugo/data';
import Box from '@material-ui/core/Box';
import { Link } from '@material-ui/core';

export const ManualDriverForm = (props: any) => {
  const finishExistingReservationTypeformId =
    process.env.REACT_APP_TYPEFORM_FINISH_MANUAL_RESERVATION_FORM_ID;
  const addNewReservationTypeformId =
    process.env.REACT_APP_TYPEFORM_ADD_NEW_MANUAL_RESERVATION_FORM_ID;
  const [driverId, setDriverId] = useState('');
  const [driverEmail, setDriverEmail] = useState('');

  useEffect(() => {
    setDriverId(props.record.id);
    setDriverEmail(props.record.email);
  }, []);

  return (
    <SimpleForm {...props} submitOnEnter={false}>
      <TextField source="id" />

      <Box display="flex">
        <Box flex={1} p="1em">
          <b>Finish existing reservation Typeform link:</b>
          <br />
          <Link
            href={`https://hellougo.typeform.com/to/${finishExistingReservationTypeformId}#erdem=${driverEmail}`}
          >
            {`https://hellougo.typeform.com/to/${finishExistingReservationTypeformId}#erdem=${driverEmail}`}
          </Link>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} p="1em">
          <b>Add new manual reservation Typeform link:</b>
          <br />
          <Link
            href={`https://hellougo.typeform.com/to/${addNewReservationTypeformId}#nrdid=${driverId}`}
          >
            {`https://hellougo.typeform.com/to/${addNewReservationTypeformId}#nrdid=${driverId}`}
          </Link>
        </Box>
      </Box>

      <TextInput label="Email Address" source="email" type="email" />
      <TextInput label="First name" source="first_name" type="text" />
      <TextInput label="Last name" source="last_name" type="text" />
      <TextInput label="Phone number" source="phone_number" type="text" />
      <SelectArrayInput source="cities" choices={MANUAL_CITIES} fullWidth />
      <SelectArrayInput source="services" choices={MANUAL_SERVICES} fullWidth />
    </SimpleForm>
  );
};
