import * as React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ResourceComponentProps,
  TextField,
} from 'react-admin';
import { Link } from 'react-router-dom';

export const ReviewList = (props: ResourceComponentProps) => (
  <List
    bulkActionButtons={false}
    {...props}
    // filters={<ReviewFilter />}
    // actions={<ReviewActions />}
    sort={{
      field: 'created_at',
      order: 'DESC',
    }}
  >
    <Datagrid>
      <DateField source="source_created_at" label="Created At" showTime />
      <TextField source="reviewer_name" label="Reviewer Name" />
      <TextField source="rating" label="Rating" />
      <TextField source="tags" label="Tags" />
      <TextField source="review_source" label="Review Source" />
      <FunctionField
        render={(record: any) => <Link to={`/review/${record.id}`}>VIEW</Link>}
      />
    </Datagrid>
  </List>
);
