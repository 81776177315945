import { getHalfHourIntervalTimeCost } from '../utils';
import { CalculationData, CalculationStrategy } from './calculation-strategy';

export class CaregivingPhoneAssistanceStrategy implements CalculationStrategy {
  calculate(data: CalculationData) {
    const PHONE_ASSISTANCE_PRICE_PER_HALF_HOUR = 5;
    const PHONE_ASSISTANCE_MIN_HOURS = 0.5;

    if (
      !data.caregivingDuration &&
      typeof data.caregivingDuration !== 'number'
    ) {
      throw new Error('Caregiving duration is required');
    }

    const caregivingDuration =
      (PHONE_ASSISTANCE_MIN_HOURS > data.caregivingDuration
        ? PHONE_ASSISTANCE_MIN_HOURS
        : data.caregivingDuration) * 2;

    const timeCost = getHalfHourIntervalTimeCost(
      caregivingDuration,
      PHONE_ASSISTANCE_PRICE_PER_HALF_HOUR
    );

    return {
      timeCost,
      caregivingDuration,
      totalCost: timeCost,
    };
  }
}
