import { AuthProvider } from 'ra-core';
import { authGQLClient } from './ApolloClient';
import { FirebaseApp } from './vendor/firebase';

export const XHasuraAdminSecret = 'X-Hasura-Admin-Secret';
export const Authorization = 'Authorization';
export const XHasuraRole = 'X-Hasura-Role';

const checkAuth = (): Promise<void> => {
  return FirebaseApp().auth().currentUser
    ? Promise.resolve()
    : Promise.reject();
};

export const authProvider: AuthProvider = {
  checkAuth,
  login: async ({ username, password }) => {
    const res = await FirebaseApp()
      .auth()
      .signInWithEmailAndPassword(username, password);

    if (!res?.user) {
      await FirebaseApp().auth().signOut();
      throw new Error("User doesn't exist");
    }
    location.reload();
    return res.user;
  },
  logout: () =>
    authGQLClient.resetStore().then(() => FirebaseApp().auth().signOut()),
  async checkError({ graphQLErrors, networkError }) {
    // await authGQLClient.resetStore()
    //   .then(() => FirebaseApp().auth().signOut());
    if (networkError) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.log(`[Network error]: ${networkError}`);
    }

    if (
      graphQLErrors &&
      graphQLErrors[0].extensions.code === 'validation-failed'
    ) {
      // check unauthorized error
      return checkAuth();
    }

    return Promise.resolve();
  },
  getPermissions: () => Promise.reject('Unknown method'),
};
