import * as React from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  downloadCSV,
  EditButton,
  ExportButton,
  Filter,
  List,
  ResourceComponentProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import { ChipArrayField } from '../shared/ChipArrayField';
import jsonExport from 'jsonexport/dist';
import { MANUAL_CITIES } from '@ugo/data';
import { GLOBAL_EXPORTER_SETTINGS } from '@ugo/data';

const ManualDriverListFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="id" label="ID" alwaysOn />
      <TextInput source="first_name@_ilike" label="First name" alwaysOn />
      <TextInput source="last_name@_ilike" label="Last name" alwaysOn />
      <TextInput source="email@_ilike" label="Email" alwaysOn />
      <TextInput source="phone_number@_ilike" label="Phone" alwaysOn />
      <SelectInput source="cities@_has_key" choices={MANUAL_CITIES} alwaysOn />
    </Filter>
  );
};

export const ManualDriverList = (props: ResourceComponentProps) => (
  <List
    {...props}
    filters={<ManualDriverListFilter />}
    actions={<ManualDriverListActions />}
  >
    <Datagrid>
      <TextField source="email" />
      <TextField source="first_name" emptyText="n/a" label="First name" />
      <TextField source="last_name" emptyText="n/a" label="Last name" />
      <TextField source="phone_number" emptyText="n/a" label="Phone number" />
      <ChipArrayField
        {...props}
        source="services"
        label="Services"
        emptyText="n/a"
      />
      <ChipArrayField
        {...props}
        source="cities"
        label="Cities"
        emptyText="n/a"
      />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton label="View" />
    </Datagrid>
  </List>
);

/**
 * @description This add custom fields to the csv export
 * @docs https://marmelab.com/react-admin/List.html#exporter
 */
const manualDriverExporter = (drivers: any[]) => {
  const driversForExport = drivers.map((driver: any) => {
    driver.typeform_finish_reservation_link = `https://hellougo.typeform.com/to/${process.env.REACT_APP_TYPEFORM_FINISH_MANUAL_RESERVATION_FORM_ID}#erdem=${driver.email}`;
    driver.typeform_add_new_reservation_link = `https://hellougo.typeform.com/to/${process.env.REACT_APP_TYPEFORM_ADD_NEW_MANUAL_RESERVATION_FORM_ID}#nrdid=${driver.id}`;
    return driver;
  });
  jsonExport(
    driversForExport,
    {
      headers: [
        'typeform_finish_reservation_link',
        'typeform_add_new_reservation_link',
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'manual_driver');
    }
  );
};

const ManualDriverListActions = (props: any) => {
  const { className, basePath, total, resource, currentSort } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={manualDriverExporter}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};
