import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import { ManualReservationWithPreferredDriverCreate } from './components/manual-reservations-with-preferred-driver/ManualReservationWithPreferredDriverCreate';
import { ManualReservationWithPreferredDriverEdit } from './components/manual-reservations-with-preferred-driver/ManualReservationWithPreferredDriverEdit';
import Conversion from './components/other/Conversion';
import CarnetDuplication from './components/other/CarnetDuplication';
import RegistrationToFirstReservation from './components/other/RegistrationToFirstReservation';
import { PmfLists } from './components/other/PmfLists';
import { DataSyncs } from './components/developers/DataSyncs';
import { ParetoDistributionForAccountsVsReservations } from './components/other/ParetoDistributionForAccountsVsReservations';
import { ReservationFrequencySinceFirstReservation } from './components/other/ReservationFrequencySinceFirstReservation';
import { DriverList } from './components/accounts/DriverList';
import { DriverOnboarding } from './components/accounts/DriverOnboarding';
import { ReservationsWithFailedVoucherRedemptions } from './components/reservations/ReservationsWithFailedVoucherRedemptions';
import { CarvoilaDriverOnboarding } from './carvoila/components/DriverOnboarding';
import { DistrictList } from './carvoila/components/DistrictList';
import { CarvoilaDriverList } from './carvoila/components/DriverList';
import { ReportBug } from './components/other/ReportBug';
import { CalculateTransactionFees } from './components/other/CalculateTransactionFees';
import DOFAnalytics from './components/dashboard/charts/DOFAnalytics';
import CreditCardExpirationDates from './components/other/CreditCardExpirationDates';
import { FailedPaymentsReservations } from './components/other/FailedPaymentsReservations';
import { OverdueReservations } from './components/other/OverdueReservations';
import { AdminDashboard } from './components/developers/AdminDashboard';
import { ShelvedReservations } from './components/other/ShelvedReservations';
import { ManualReservationList } from './components/manual-reservations/ManualReservationList';

const otherRoutes = [
  <Route
    key="other/report-bug"
    exact
    path="/other/report-bug"
    component={ReportBug}
  />,
  <Route
    key="failed_payments_reservations"
    exact
    path="/failed_payments_reservations"
    render={(routeProps) => (
      <FailedPaymentsReservations
        resource="reservation"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="other/dashboard"
    exact
    path="/other/dashboard"
    component={Dashboard}
  />,
  <Route
    key="overdue_reservations"
    exact
    path="/overdue_reservations"
    render={(routeProps) => (
      <OverdueReservations
        resource="reservation"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="shelved-reservations"
    exact
    path="/shelved-reservations"
    render={(routeProps) => (
      <ShelvedReservations
        resource="reservation"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="other/conversion"
    exact
    path="/other/conversion"
    component={Conversion}
  />,
  <Route
    key="other/pareto-distribution"
    exact
    path="/other/pareto-distribution"
    component={ParetoDistributionForAccountsVsReservations}
  />,
  <Route
    key="other/reservation-frequency"
    exact
    path="/other/reservation-frequency"
    component={ReservationFrequencySinceFirstReservation}
  />,
  <Route
    key="other/pmf-lists"
    exact
    path="/other/pmf-lists"
    component={PmfLists}
  />,
  <Route
    key="other/carnet"
    exact
    path="/other/carnet"
    component={CarnetDuplication}
  />,
  <Route
    key="other/registration-to-first-reservation"
    exact
    path="/other/registration-to-first-reservation"
    component={RegistrationToFirstReservation}
  />,
  <Route key="admin" exact path="/admin" component={AdminDashboard} />,
  <Route
    key="developers/data-syncs"
    exact
    path="/developers/data-syncs"
    component={DataSyncs}
  />,
  <Route
    key="other/calculate-transaction-fees"
    exact
    path="/other/calculate-transaction-fees"
    component={CalculateTransactionFees}
  />,
  <Route
    key="other/credit-card-expiration-dates"
    exact
    path="/other/credit-card-expiration-dates"
    render={(routeProps) => (
      <CreditCardExpirationDates
        resource="vw_admin_default_account_credit_cards"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="other/dof-analytics"
    exact
    path="/other/dof-analytics"
    component={DOFAnalytics}
  />,
];

const carvoilaRoutes = [
  <Route
    key="carvoila_district"
    exact
    path="/carvoila_district"
    render={(routeProps) => (
      <DistrictList
        resource="carvoila_district"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="carvoila_driver"
    exact
    path="/carvoila_driver"
    render={(routeProps) => (
      <CarvoilaDriverList
        resource="carvoila_driver"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="driver-onboarding"
    exact
    path="/carvoila/driver-onboarding"
    render={(routeProps) => (
      <CarvoilaDriverOnboarding
        resource="carvoila_driver"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
];

const manualReservationWithPreferredDriverRoutes = [
  <Route
    key="drivers"
    exact
    path="/drivers"
    render={(routeProps) => (
      <DriverList
        resource="vw_admin_account"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="vw_manual_reservation"
    exact
    path="/vw_manual_reservation"
    render={(routeProps) => (
      <ManualReservationList
        resource="vw_admin_manual_reservation"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="driver-onboarding"
    exact
    path="/driver-onboarding"
    render={(routeProps) => (
      <DriverOnboarding
        resource="vw_admin_account"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="manual_reservation_with_preferred_driver/create"
    exact
    path="/manual_reservation_with_preferred_driver/create"
    render={(routeProps) => (
      <ManualReservationWithPreferredDriverCreate
        resource="manual_reservation"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="manual_reservation_with_preferred_driver/:id"
    exact
    path="/manual_reservation_with_preferred_driver/:id"
    render={(routeProps) => (
      <ManualReservationWithPreferredDriverEdit
        resource="manual_reservation"
        basePath={routeProps.match.url}
        id={decodeURIComponent(routeProps.match.params.id)}
        {...routeProps}
      />
    )}
  />,
  <Route
    key="voucher-redemptions"
    exact
    path="/voucher-redemptions"
    render={(routeProps) => (
      <ReservationsWithFailedVoucherRedemptions
        resource="vw_admin_reservation"
        basePath={routeProps.match.url}
        {...routeProps}
      />
    )}
  />,
];

const routes = [
  ...manualReservationWithPreferredDriverRoutes,
  ...otherRoutes,
  ...carvoilaRoutes,
];

export default routes;
