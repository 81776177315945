import {
  BooleanField,
  Datagrid,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import * as React from 'react';

export const VoucherList = (props: any) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={
        <Filter {...props}>
          <TextInput source="code@_ilike" label="Code" alwaysOn />
        </Filter>
      }
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="code" />
        <TextField source="created_at" />
        <ReferenceField source="referrer_id" reference="account">
          <TextField source="email" />
        </ReferenceField>
        <BooleanField source="enabled" />
        <FunctionField
          label="View"
          render={(r: any) => <Link to={`/voucher/${r.code}`}>VIEW</Link>}
        />
      </Datagrid>
    </List>
  );
};
