import React from 'react';

export const ReportBug = () => {
  return (
    <>
      <iframe
        className="clickup-embed clickup-dynamic-height"
        src="https://forms.clickup.com/2662099/f/2h7pk-701/3DC4XVBRZBRO7L0VX0"
        onWheel=""
        width="100%"
        height="100%"
        style={{ background: 'transparent', border: '1px solid #ccc' }}
      ></iframe>
      );
      <script
        async
        src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
      ></script>
    </>
  );
};
