import { Box, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DateRangePreset, getDatePresetValue } from '@ugo/utils';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import TextField from '@material-ui/core/TextField';
import {
  DateRange,
  DateRangeDelimiter,
  DateRangePicker,
} from '@material-ui/pickers';
import LocalizationProvider from '@material-ui/pickers/LocalizationProvider';
import itLocale from 'date-fns/locale/it';
import { useAtom } from 'jotai';
import { datePresetValueAtom } from '../../shared/globalState';

const DateFilter = (props: {
  rangeChange: (date: DateRange<Date>) => void;
  defaultPreset: DateRangePreset;
}) => {
  const [selectedPreset, setSelectedPreset] = useState<DateRangePreset | null>(
    props.defaultPreset
  );

  const [, setDatePresetValue] = useAtom(datePresetValueAtom);

  const [rangeValue, setRangeValue] = React.useState<DateRange<Date>>(
    getDatePresetValue(props.defaultPreset)
  );

  const handlePresetChange = (val: any) => {
    const presetValue = val.target.value;

    if (presetValue) {
      setRangeValue(getDatePresetValue(presetValue));
    }
    setSelectedPreset(presetValue);
    setDatePresetValue(presetValue);
  };

  const handleRangeChange = (newValue: any) => {
    setSelectedPreset(0);
    setRangeValue(newValue);
  };

  useEffect(() => props.rangeChange(rangeValue), [rangeValue]);

  return (
    <Box display="flex" justifyContent="space-between">
      <Box minWidth="120px" mr={2}>
        <FormControl>
          <InputLabel id="preset-label">
            <Box m={1}>Preset</Box>
          </InputLabel>
          <Select
            labelId="preset-label"
            margin="dense"
            variant="filled"
            onChange={handlePresetChange}
            inputProps={{ vairant: 'filled', size: 'large' }}
            placeholder="Preset"
            value={selectedPreset}
          >
            <MenuItem value={0}>Custom</MenuItem>
            <MenuItem value={DateRangePreset.ThisWeek}>This week</MenuItem>
            <MenuItem value={DateRangePreset.ThisMonth}>This month</MenuItem>
            <MenuItem value={DateRangePreset.ThisYear}>This year</MenuItem>
            <MenuItem value={DateRangePreset.AllTime}>All time</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <LocalizationProvider dateAdapter={DateFnsAdapter} locale={itLocale}>
          <DateRangePicker
            disabled={!!selectedPreset}
            startText="Start"
            endText="End"
            clearable={true}
            value={rangeValue}
            onChange={handleRangeChange}
            renderInput={(startProps: any, endProps: any) => (
              <React.Fragment>
                <TextField
                  {...startProps}
                  helperText={null}
                  variant="filled"
                  size="small"
                />
                <DateRangeDelimiter> to </DateRangeDelimiter>
                <TextField
                  size="small"
                  {...endProps}
                  variant="filled"
                  helperText={null}
                />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DateFilter;
