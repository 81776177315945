import * as React from 'react';
import { createElement, useEffect, useState } from 'react';
//@ts-ignore
import { useSelector } from 'react-redux';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { getResources, MenuItemLink } from 'react-admin';
import { withRouter } from 'react-router-dom';
import SpeedIcon from '@material-ui/icons/Speed';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CarIcon from '@material-ui/icons/DirectionsCar';
import { groupBy, pipe } from 'ramda';
import {
  BugReport,
  CreditCard,
  LocalOffer,
  Settings,
} from '@material-ui/icons';
import { FirebaseApp } from '../../shared/vendor/firebase';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      margin: theme.spacing(2),
    },
    subTitle: {
      whiteSpace: 'normal',
    },
  })
);

const Menu = ({ onMenuClick }: any) => {
  const classes = useStyles();
  // @ts-ignore
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const [showAdminTabs, setShowAdminTabs] = useState(false);
  const superAdminPartialEmails = [
    'f.vidali',
    'v.repar',
    'j.gradisar',
    'gradisar.joze8',
  ];

  useEffect(() => {
    return FirebaseApp()
      .auth()
      .onAuthStateChanged((user) => {
        superAdminPartialEmails.forEach((e) => {
          // @ts-ignore
          if (user?.email?.indexOf(e) > -1) {
            setShowAdminTabs(true);
          }
        });
      });
  }, []);

  const { b2bResources, b2cResources } = pipe(
    groupBy((r: any) =>
      r.name.includes('manual') || r.name === 'partner' || r.name === 'project'
        ? 'b2bResources'
        : 'b2cResources'
    )
  )(resources);

  return (
    <Box pt={'6px'}>
      <Typography variant="h6" className={classes.title}>
        B2C
      </Typography>
      {b2cResources
        ?.filter((r: any) => r.hasList)
        .map((resource: any, i) => (
          <GenericMenuItemLink
            key={resource.name + i}
            resource={resource}
            onMenuClick={onMenuClick}
            isSidebarOpened={open}
          />
        ))}
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/failed_payments_reservations"
        primaryText="Failed Payments Reservations"
        leftIcon={<ListIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/overdue_reservations"
        primaryText="Overdue Reservations"
        leftIcon={<ListIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/drivers"
        primaryText="Drivers"
        leftIcon={<CarIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/driver-onboarding"
        primaryText="DOF"
        leftIcon={<CarIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/shelved-reservations"
        primaryText="DAF - Shelved Reservations"
        leftIcon={<CarIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      <Typography variant="h6" className={classes.title}>
        B2B
      </Typography>
      {b2bResources
        ?.filter((r: any) => r.hasList)
        .map((resource: any) => (
          <GenericMenuItemLink
            key={resource.name}
            resource={resource}
            onMenuClick={onMenuClick}
            isSidebarOpened={open}
          />
        ))}
      <Typography variant="h6" className={classes.title}>
        Other
      </Typography>
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/report-bug"
        primaryText="Report a Bug"
        leftIcon={<BugReport />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/dashboard"
        primaryText="Dashboard"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/conversion"
        primaryText="Conversion"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/pareto-distribution"
        primaryText="Pareto Distribution (Accounts ~ Reservations)"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/reservation-frequency"
        primaryText="Reservation Frequency"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/pmf-lists"
        primaryText="PMF Lists"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/carnet"
        primaryText="Carnet (duplication)"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/registration-to-first-reservation"
        primaryText="Registration To First Reservation"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/calculate-transaction-fees"
        primaryText="Calculate Transaction Fees"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/credit-card-expiration-dates"
        primaryText="Credit card expiration dates"
        leftIcon={<CreditCard />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/other/dof-analytics"
        primaryText="DOF Analytics"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <Typography variant="h6" className={classes.title}>
        Carvoila
      </Typography>
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/carvoila_driver"
        primaryText="Drivers"
        leftIcon={<CarIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/carvoila/driver-onboarding"
        primaryText="DOF"
        leftIcon={<CarIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/carvoila_driver"
        primaryText="Drivers"
        leftIcon={<CarIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/carvoila_district"
        primaryText="Districts"
        leftIcon={<LocationCityIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <Typography variant="h6" className={classes.title}>
        Developers
      </Typography>
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/developers/data-syncs"
        primaryText="Data Syncs"
        leftIcon={<SpeedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        to="/voucher-redemptions"
        primaryText="Voucher Redemptions"
        leftIcon={<LocalOffer />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {showAdminTabs && (
        <MenuItemLink
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
          to="/admin"
          primaryText="Admin"
          leftIcon={<Settings />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}
    </Box>
  );
};

export default withRouter(Menu);

const GenericMenuItemLink = ({
  resource,
  onMenuClick,
  isSidebarOpened,
}: {
  resource: any;
  onMenuClick: any;
  isSidebarOpened: boolean;
}) => {
  return (
    <MenuItemLink
      style={{ paddingTop: '10px', paddingBottom: '10px' }}
      to={`/${resource.name}`}
      primaryText={
        (resource.options && resource.options.label) || resource.name
      }
      leftIcon={createElement(resource.icon)}
      onClick={onMenuClick}
      sidebarIsOpen={isSidebarOpened}
    />
  );
};
