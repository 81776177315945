import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Line } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { Box, Card, Typography } from '@material-ui/core';
import { endOfDay, format, isBefore, subWeeks } from 'date-fns';
import { getDaysArray } from '@ugo/utils';
import { ChartComponentProps } from '@ugo/models';

/**
 * Returns distinct count of drivers that were active on a day or at least 4 weeks before
 */
const groupDistinctActiveUsersByDay = (
  services: any,
  startDate: Date | null,
  endDate: Date | null
) => {
  const rangeActiveUsersByDays: number[] = [];
  let days: Date[] = [];

  if (!startDate || !endDate) {
    return { days, rangeActiveUsersByDays };
  }

  const reservationsArray = Object.keys(services).map((sid) => services[sid]);

  days = getDaysArray(startDate, endDate, true);

  days.forEach(() => rangeActiveUsersByDays.push(0));

  days.forEach((d, i) => {
    const reservationsInRangeForDayArray = reservationsArray.filter(
      (r) => !!r.reservor_id && isBefore(new Date(r.created_at), endOfDay(d))
    );
    const distinctUsers = [
      // @ts-ignore
      ...new Set(reservationsInRangeForDayArray.map((r) => r.reservor_id)),
    ];
    rangeActiveUsersByDays[i] = distinctUsers.length;
  });

  return { days, rangeActiveUsersByDays };
};

const NumberOfActiveUsersLineChart = (props: ChartComponentProps) => {
  const [graphData, setGraphData] = useState<number[]>([]);
  const [days, setDays] = useState<string[]>([]);

  const { data } = useGetList(
    'reservation',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'ASC' },
    {
      'created_at@_gte': subWeeks(props.startDate as Date, 4),
      'created_at@_lte': props.endDate,
    }
  );

  useEffect(() => {
    const { days, rangeActiveUsersByDays } = groupDistinctActiveUsersByDay(
      data,
      props.startDate,
      props.endDate
    );

    setDays(days.map((d) => format(d, 'yyyy-MM-dd')));
    setGraphData(rangeActiveUsersByDays);
  }, [data]);

  return (
    <Card>
      <Box px={2} pt={2}>
        <Typography color="textSecondary">
          <small>
            Created reservation on the day or at least 4 weeks prior
          </small>
        </Typography>
      </Box>
      <Box height="350px" px={2} pb={2}>
        <Line
          data={{
            labels: days,
            datasets: [
              {
                label: 'Active users',
                data: graphData,
                lineTension: 0,
                pointRadius: 2,
                borderColor: colorScheme[0],
                backgroundColor: 'transparent',
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    precision: 0,
                    beginAtZero: true,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </Box>
    </Card>
  );
};
export default NumberOfActiveUsersLineChart;
