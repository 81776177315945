export type Service =
  | 'CAREGIVING_WITH_UGOS_CAR'
  | 'CAREGIVING_WITH_USERS_CAR'
  | 'CAREGIVING_WITHOUT_CAR'
  | 'CAREGIVING_VIA_TELEMEDICINE'
  | 'ERRANDS'
  | 'PHONE_ASSISTANCE';

export const CITY_RADIUS_LIMIT_IN_KMS = 15; // km
export const CITY_MIN_DISTANCE: Record<string, number> = {
  roma: 10,
};
export const CITY_MIN_DISTANCE_DEFAULT = 5;

export function getHalfHourIntervalTimeCost(
  hours: number,
  pricePerHalfHour: number
) {
  return hours * pricePerHalfHour;
}

/*
 *  Hours need to be rounded to the nearest 0,5h
 *  https://app.clickup.com/t/1uq9v7n
 *
 *  From :00 to :15 we round to ,00 before
 *  From :16 to :45 we round to ,30 before
 *  From :46 to :00 we round to ,00 after
 */
export function getHourlyIntervalTimeCost(hours: number, pricePerHour: number) {
  const _hours = hours < 1 ? 1 : Math.round(hours * 2) / 2;
  const fullHours = Math.floor(_hours);
  const fractionalHour = _hours - fullHours;

  /**
   * Create an array of hour multipliers e.g. [1, 1, 0.5]
   * e.g.
   * hours = 2.55
   * hours gets rounded to 2.5
   * multiplier array = [1, 1, 0.5]
   */
  const hourMultiplierArray = Array.from({ length: fullHours }).map(
    (_, idx) => 1
  );

  if (fractionalHour) {
    hourMultiplierArray.push(fractionalHour);
  }

  /**
   * Multiply the hourly prices with the multiplier array,
   * so we correctly calculate the fractional hour
   *
   * multiplier array = [1, 1, 0.5]
   * hourly array = [15*1, 14*1, 13*0.5]
   */
  const hourlyArray = hourMultiplierArray.map(
    (multiplier, idx) => (idx + 1 >= 8 ? 8 : pricePerHour - idx) * multiplier
  );

  // Return the reduced value
  return hourlyArray.reduce((a, b) => a + b, 0);
}

export function getDistanceCost(distance: number, minPrice = 5) {
  const PRICE_PER_KM_BELOW_200_KM = 0.5;
  /**
   * @description Sets a price to be applied above 200km
   *  - It has been a different price prior to 15. 4. 2022
   *  - We're keeping the same logic as before but apply
   *  the same price as `below 200 km`; We're keeping this if we need to set
   *  a different price above 200km in the future again
   */
  const PRICE_PER_KM_ABOVE_200_KM = 0.5;

  const numberOfAbove200Km = distance - 200 <= 0 ? 0 : distance - 200;
  const numberOfBelow200Km = numberOfAbove200Km > 0 ? 200 : distance;

  const above200KmCost = numberOfAbove200Km * PRICE_PER_KM_ABOVE_200_KM;
  const below200KmCost = numberOfBelow200Km * PRICE_PER_KM_BELOW_200_KM;

  const cost = below200KmCost + above200KmCost;

  if (cost < minPrice) {
    return minPrice;
  }

  return cost;
}
