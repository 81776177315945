import { useState } from 'react';
import {
  Reservation_Bool_Exp,
  Scalars,
  useGetAuthProviderDataQuery,
  useGetDailyConversionsByAuthProviderQuery,
  useGetPatientConversionReservationQuery,
  useGetPatientsByConversionStatusQuery,
} from '@ugo/graphql-operations';
import { isNil, reject } from 'ramda';

/**
 * @description A collection of complex API calls
 */
export const useQueries = () => {
  const { refetch: getPatientsByConversionStatus } =
    useGetPatientsByConversionStatusQuery({
      fetchPolicy: 'network-only',
      skip: true,
    });

  const { refetch: getPatientConversionReservation } =
    useGetPatientConversionReservationQuery({
      fetchPolicy: 'network-only',
      skip: true,
    });

  const { refetch: getAuthProviderData } = useGetAuthProviderDataQuery({
    fetchPolicy: 'network-only',
    skip: true,
  });

  const { refetch: getDailyConversionsByAuthProvider } =
    useGetDailyConversionsByAuthProviderQuery({
      fetchPolicy: 'network-only',
      skip: true,
    });

  const refetchPatientsByConversionStatus = async ({
    dateRange,
    city,
    trafficSource,
  }: {
    dateRange: any[];
    city?: string;
    trafficSource?: string;
  }) => {
    return await getPatientsByConversionStatus({
      where: reject(isNil, {
        reservor_id: { _is_null: false },
        status: { _neq: 'CANCELED' },
        created_at: {
          _gte: dateRange[0] as Scalars['timestamptz'],
          _lte: dateRange[1] as Scalars['timestamptz'],
        },
        reservor:
          trafficSource && trafficSource !== '_'
            ? { account_feedback: { traffic_source: { _eq: trafficSource } } }
            : null,
        metadata:
          city && city !== '_' ? { _contains: { service_city: city } } : null,
      }) as Reservation_Bool_Exp,
    });
  };

  const refetchPatientConversionReservations = async ({
    dateRange,
    service_city,
    traffic_source,
    shouldIncludeManualConversionReservations,
  }: {
    dateRange: any[];
    service_city?: string;
    traffic_source?: string;
    shouldIncludeManualConversionReservations: boolean;
  }) => {
    return (
      (
        await getPatientConversionReservation({
          where: reject(isNil, {
            service_city: (service_city && { _eq: service_city }) || null,
            traffic_source: (traffic_source && { _eq: traffic_source }) || null,
            created_at: {
              _gte: dateRange[0] as Scalars['timestamptz'],
              _lte: dateRange[1] as Scalars['timestamptz'],
            },
            is_manual: { _eq: shouldIncludeManualConversionReservations },
          }),
        })
      )?.data?.vw_stats_patient_conversion_reservation || []
    );
  };

  const refetchGetAuthProviderData = async ({
    dateRange,
  }: {
    dateRange: any[];
  }) => {
    return (
      (
        await getAuthProviderData({
          where: reject(isNil, {
            day: {
              _gte: dateRange[0] as Scalars['timestamptz'],
              _lte: dateRange[1] as Scalars['timestamptz'],
            },
          }),
        })
      )?.data?.vw_stats_auth_provider_data || []
    );
  };

  const refetchDailyConversionsByAuthProvider = async ({
    dateRange,
  }: {
    dateRange: any[];
  }) => {
    return (
      (
        await getDailyConversionsByAuthProvider({
          where: reject(isNil, {
            converted_at: {
              _gte: dateRange[0] as Scalars['timestamptz'],
              _lte: dateRange[1] as Scalars['timestamptz'],
            },
          }),
        })
      )?.data?.vw_stats_daily_conversions_by_auth_provider || []
    );
  };

  const [queriesState] = useState({
    refetchPatientsByConversionStatus,
    refetchPatientConversionReservations,
    refetchGetAuthProviderData,
    refetchDailyConversionsByAuthProvider,
  });

  return queriesState;
};
