import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Login } from 'react-admin';

const useStyles = makeStyles(() => ({
  main: {
    background:
      'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
  },
}));

const LoginPage = (props: any) => {
  const classes = useStyles();
  return <Login classes={classes} backgroundImage="" {...props} />;
};
export default LoginPage;
