import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

export const ugoTheme = merge({}, defaultTheme, {
	palette: {
		primary: {
			main: '#f9b233',
		},
		secondary: {
			main: '#373636',
		},
	},
	// overrides: {
	// 	MuiButton: {
	// 		// override the styles of all instances of this component
	// 		root: {
	// 			// Name of the rule
	// 			color: 'white', // Some CSS
	// 		},
	// 	},
	// },
});
