import { Box, Button, Paper } from '@material-ui/core';
import {
  useGetSyncsSubSubscription,
  useSyncFirebaseAuthenticationUsersWithDbManuallyMutation,
} from '@ugo/graphql-operations';
import { groupBy, pipe, prop } from 'ramda';
import React, { useState } from 'react';

export const DataSyncs = () => {
  const [syncs, setSyncs] = useState({});

  const [syncFirebaseAuthenticationUsersWithDbManuallyMutation] =
    useSyncFirebaseAuthenticationUsersWithDbManuallyMutation({});

  useGetSyncsSubSubscription({
    onSubscriptionData: (payload) => {
      const _syncs = pipe(groupBy(prop('id')))(
        payload?.subscriptionData?.data?.sync || []
      );
      setSyncs(_syncs);
    },
  });

  async function onTriggerFirebaseAuthenticationUsersSyncClick() {
    await syncFirebaseAuthenticationUsersWithDbManuallyMutation();
  }

  return (
    <Box pt={3}>
      <Paper>
        <Box p={3}>
          <pre>
            {JSON.stringify(
              (syncs?.firebase_authentication_users &&
                syncs?.firebase_authentication_users[0]) ||
                {},
              null,
              2
            )}
          </pre>
          <Button
            disabled={
              (syncs?.firebase_authentication_users &&
                syncs?.firebase_authentication_users[0].is_sync_in_progress) ||
              false
            }
            onClick={onTriggerFirebaseAuthenticationUsersSyncClick}
            color="primary"
            variant="contained"
          >
            Trigger Firebase Authentication Users Sync
          </Button>
          <br />
        </Box>
      </Paper>
    </Box>
  );
};
