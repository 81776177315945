import {
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  TextField,
} from 'react-admin';
import React from 'react';
import {
  ReservationListFilter,
  ReservationStatusField,
} from '../reservations/ReservationList';
import { Reservation_Status_Enum } from '@ugo/graphql-operations';
import { differenceInMinutes } from 'date-fns';
import { fromTimestamptz } from '@ugo/utils';

export function ShelvedReservations(props: any) {
  return (
    <List
      {...props}
      sort={{ field: 'shelved_at', order: 'DESC' }}
      filterDefaultValues={{
        status: Reservation_Status_Enum.Shelved,
      }}
      filters={<ReservationListFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="starts_at" showTime />
        <DateField source="shelved_at" showTime />
        <FunctionField
          label="Minutes since shelved"
          render={(r: any) => {
            const minutes = differenceInMinutes(
              new Date(),
              fromTimestamptz(r.shelved_at)
            );

            return minutes <= 17 ? (
              <span style={{ color: 'green' }}>{minutes}</span>
            ) : minutes > 17 && minutes <= 25 ? (
              <span style={{ color: 'orange' }}>{minutes}</span>
            ) : (
              <span style={{ color: 'red' }}>{minutes}</span>
            );
          }}
        />
        <TextField source="metadata.service" />
        <TextField
          style={{ textTransform: 'capitalize' }}
          source="metadata.service_city"
          label="City"
          emptyText="n/a"
        />
        <ReservationStatusField source="status" emptyText="n/a" />
        <FunctionField
          label="View"
          render={(r: any) => <Link to={`/reservation/${r.id}`}>VIEW</Link>}
        />
      </Datagrid>
    </List>
  );
}
