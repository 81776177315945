import React from 'react';
import { Create } from 'react-admin';
import validator from 'validator';
import { ManualDriverForm } from './ManualDriverForm';

export const ManualDriverCreate = (props: any) => {
  const validateManualDriverCreation = (values: any) => {
    const errors = {
      ...(['email', 'first_name', 'last_name', 'phone_number']
        .filter((v) => !values[v])
        .reduce((accumulator: any, value: any) => {
          accumulator[value] = `${value} is required`;
          return accumulator;
        }, {}) || {}),
    };

    if (!validator.isEmail(values.email || '')) {
      errors.email = `${values.email} is not a valid email`;
    }

    return errors;
  };

  return (
    <Create {...props}>
      <ManualDriverForm {...props} validate={validateManualDriverCreation} />
    </Create>
  );
};
