/**
 * @description Preferred driver actions related to manual_* (b2b) flows
 *
 * 1. NO_ACTION - Reservation is created, email is not sent yet
 * 2. AWAITING_RESPONSE - Email is sent, system is waiting for the preferred driver's response
 * 3. ACCEPTED - Preferred driver has accepted the reservation
 * 4. REJECTED - Preferred driver has rejected the reservation, the system sends
 * email to all available drivers afterwards
 */
export const PREFERRED_DRIVER_ACTIONS = [
	{ id: 'NO_ACTION', name: 'No action' },
	{ id: 'AWAITING_RESPONSE', name: 'Awaiting Response' },
	{ id: 'ACCEPTED', name: 'Accepted' },
	{ id: 'REJECTED', name: 'Rejected' },
];
