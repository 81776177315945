import { Box } from '@material-ui/core';
import React from 'react';

const renderAnswer = (answer: Blob) => {
  return <img src={URL.createObjectURL(answer)} width="100%" />;
};

const TypeformPayloadRender = ({
  payload,
}: {
  payload: { [key: string]: Blob };
}) => {
  return (
    <Box>
      <h4>Photos</h4>
      {Object.keys(payload).map((k) => {
        return (
          <Box key={k} mb={3}>
            <Box>
              <span style={{ fontWeight: 'bold' }}>{k.replace('*', '')}</span>
            </Box>
            <Box>{renderAnswer(payload[k])}</Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default TypeformPayloadRender;
