import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { ChartComponentProps } from '@ugo/models';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Reservation_Status_Enum } from '@ugo/graphql-operations';

const TotalServicesFinished = (props: ChartComponentProps) => {
  const [sum, setSum] = useState<number>(0);

  const { data, loading } = useGetList(
    'reservation',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'DESC' },
    {
      'created_at@_gte': props.startDate,
      'created_at@_lte': props.endDate,
      'is_manual@_eq': props.shouldIncludeManualB2cReservations,
    }
  );

  useEffect(() => {
    if (data) {
      const services = Object.keys(data as any).map((sid) => data[sid]);
      setSum(
        services.filter((s) => s.status === Reservation_Status_Enum.Finished)
          .length
      );
    }
  }, [data]);
  return (
    <Card>
      <Box p={2}>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Typography align="center" variant="h6">
              Services finished
            </Typography>
            <Typography align="center" color="primary" variant="h5">
              <b>{sum}</b>
            </Typography>
          </>
        )}
      </Box>
    </Card>
  );
};

export default TotalServicesFinished;
