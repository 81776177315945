import {
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  TextField,
} from 'react-admin';
import React from 'react';
import {
  renderBooleanIcons,
  ReservationListFilter,
  ReservationStatusField,
} from '../reservations/ReservationList';
import { Reservation_Status_Enum } from '@ugo/graphql-operations';

export function OverdueReservations(props: any) {
  return (
    <List
      {...props}
      sort={{ field: 'created_at', order: 'DESC' }}
      filterDefaultValues={{
        status: Reservation_Status_Enum.FinishReservationOverdue,
      }}
      filters={<ReservationListFilter />}
    >
      <Datagrid>
        <TextField source="short_id" label="Short ID" />
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="started_at" showTime />
        <TextField source="metadata.service" />
        <FunctionField
          label="Submitted details"
          render={(r: any) => renderBooleanIcons(r.driver_suggested_started_at)}
        />
        <TextField
          style={{ textTransform: 'capitalize' }}
          source="metadata.service_city"
          label="City"
          emptyText="n/a"
        />
        <ReservationStatusField source="status" emptyText="n/a" />
        <FunctionField
          label="View"
          render={(r: any) => <Link to={`/reservation/${r.id}`}>VIEW</Link>}
        />
      </Datagrid>
    </List>
  );
}
