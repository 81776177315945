/**
 * @description A list of supported cities (they can be treated as city ids)
 * where we provide services.
 *  - They are lowercase due to easier/saner fuse.js search implementation
 *  - Use function `getSupportedCitites` if you want to use the city list
 *  in a form. The function will append a production test city for admins.
 */
export const SUPPORTED_CITIES = [
  { name: 'bergamo', lng: 9.668711, lat: 45.6941984, code: 'BG' },
  { name: 'bologna', lng: 11.3426, lat: 44.4932318, code: 'BO' },
  { name: 'brescia', lng: 10.2156823, lat: 45.5403137, code: 'BS' },
  { name: 'firenze', lng: 11.2463453, lat: 43.7751269, code: 'FI' },
  { name: 'genova', lng: 8.9097227, lat: 44.4083582, code: 'GE' },
  { name: 'milano', lng: 9.18854, lat: 45.464664, code: 'MI' },
  { name: 'monza', lng: 9.2735078, lat: 45.583547, code: 'MB' },
  { name: 'padova', lng: 11.8713873, lat: 45.4089257, code: 'PD' },
  { name: 'roma', lng: 12.4841008, lat: 41.8962146, code: 'RM' },
  { name: 'torino', lng: 7.6778283, lat: 45.0712738, code: 'TO' },
  { name: 'catania', lng: 15.0850115, lat: 37.5023308, code: 'CT' },
  { name: 'PRODUCTION_TEST_CITY', lng: 9.18854, lat: 45.464664, code: 'PTS' },
];

/**
 * @description returns a PRODUCTION_TEST_CITY if the user is an admin
 */
export const getSupportedCities = (isAdmin: boolean) => {
  const cities = SUPPORTED_CITIES.map((c) => c.name);

  if (!isAdmin) {
    cities.splice(cities.indexOf('PRODUCTION_TEST_CITY'), 1);
  }

  return cities;
};
