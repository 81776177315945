import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { colorScheme } from '@ugo/data';
import { useUtilities } from '@ugo/utils';

export const ConversionsPerMonthLineChartWithVariantId = ({
  reservations,
  startDate,
  endDate,
}: {
  reservations: any[];
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { groupServiceCountByMonth } = useUtilities();
  const [graphData, setGraphData] = useState<Array<number[]>>([]);
  const [month, setMonths] = useState<string[]>([]);

  useEffect(() => {
    const variant0reservations = reservations.filter(
      (r) => r.reservation_metadata.variant_id === '0'
    );

    const variant1reservations = reservations.filter(
      (r) => r.reservation_metadata.variant_id === '1'
    );

    const variant2reservations = reservations.filter(
      (r) => r.reservation_metadata.variant_id === '2'
    );

    const { months, servicesByMonths } = groupServiceCountByMonth(
      reservations,
      [],
      startDate,
      endDate
    );

    const { servicesByMonths: servicesByMonthsByVariant0 } =
      groupServiceCountByMonth(variant0reservations, [], startDate, endDate);

    const { servicesByMonths: servicesByMonthsByVariant1 } =
      groupServiceCountByMonth(variant1reservations, [], startDate, endDate);

    const { servicesByMonths: servicesByMonthsByVariant2 } =
      groupServiceCountByMonth(variant2reservations, [], startDate, endDate);

    // @ts-ignore
    setMonths(months);
    setGraphData([
      // @ts-ignore
      servicesByMonths,
      servicesByMonthsByVariant0,
      servicesByMonthsByVariant1,
      servicesByMonthsByVariant2,
    ]);
  }, [reservations, startDate, endDate]);

  return (
    <Line
      data={{
        labels: month.map((m) => {
          const date = new Date(m);
          const monthStart = startOfMonth(date);
          const monthEnd = endOfMonth(date);

          return `${format(monthStart, 'dd/MM')} - ${format(
            monthEnd,
            'dd/MM'
          )}`;
        }),
        datasets: [
          {
            label: 'Total first reservations',
            data: graphData[0],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[0],
            backgroundColor: 'transparent',
          },
          {
            label: 'Variant 0',
            data: graphData[1],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[1],
            backgroundColor: 'transparent',
          },
          {
            label: 'Variant 1',
            data: graphData[2],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[2],
            backgroundColor: 'transparent',
          },
          {
            label: 'Variant 2',
            data: graphData[3],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[3],
            backgroundColor: 'transparent',
          },
        ],
      }}
      options={{
        title: {
          display: true,
          text: 'Conversions per month',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
      }}
    />
  );
};
