import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Config } from './config';
import { setContext } from '@apollo/client/link/context';
import { FirebaseApp } from './vendor/firebase';
import { Authorization, XHasuraRole } from './AuthProvider';

export const XHasuraClientName = 'hasura-client-name';

export const AuthorizationHeader = 'Authorization';
export const AuthBearer = 'Bearer';

// @ts-ignore
const getIdToken = (): Promise<string> =>
  // @ts-ignore
  FirebaseApp().auth().currentUser
    ? // @ts-ignore
      FirebaseApp().auth().currentUser.getIdToken()
    : Promise.resolve(null);
// const getBearerToken = (token: string): string =>
// @ts-ignore
const getBearerToken = (token: string): string =>
  // @ts-ignore
  token ? `${AuthBearer} ${token}` : null;

const authLink = setContext((_, { headers }) =>
  getIdToken()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .then(async (token) => {
      if (!token) {
        await FirebaseApp().auth().signOut();
      }

      const firstHeader = {
        [AuthorizationHeader]: getBearerToken(token),
        'x-hasura-role': 'administrator',
        ...headers,
      };

      const secondHeader = {
        ...headers,
      };

      return {
        headers: token ? firstHeader : secondHeader,
      };
    })
);

const splitLink = (http: ApolloLink, ws: WebSocketLink): ApolloLink =>
  split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(
        query
      ) as OperationDefinitionNode;

      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    ws,
    http
  );

const httpLink = from([
  authLink,
  // errorLink,
  new HttpLink({
    uri: Config.httpDataHost,
    headers: {
      [XHasuraClientName]: Config.hasuraClientName,
    },
  }),
]);

const wsLink = new WebSocketLink({
  uri: Config.wsDataHost,
  options: {
    reconnect: true,
    connectionParams: () =>
      getIdToken().then((token) => {
        console.log();

        return {
          headers: {
            [Authorization]: getBearerToken(token),
            [XHasuraRole]: 'administrator',
            [XHasuraClientName]: Config.hasuraClientName,
          },
        };
      }),
    lazy: true,
    connectionCallback: (error) => {
      console.error('connection error: ', error);
    },
  },
});

const commonApolloOptions = {
  version: Config.version,
};

export const authGQLClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink(httpLink, wsLink),
  ...commonApolloOptions,
});

export const gqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: Config.httpDataHost,
  }),
  ...commonApolloOptions,
});
