enum LogLevel {
  Debug = 'DEBUG',
  Error = 'ERROR',
}

export class Logger {
  #log = (level: LogLevel, message: string) => {
    console.log(`[${level}]: ${message}`);
  };

  debug(message: string): void {
    if (process.env.NODE_ENV !== 'production') {
      this.#log(LogLevel.Debug, message);
    }
  }
}

const logger = new Logger();

export { logger };
