import * as React from 'react';
import {
  Datagrid,
  DateField,
  downloadCSV,
  ExportButton,
  Filter,
  FunctionField,
  Link,
  List,
  ResourceComponentProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { GLOBAL_EXPORTER_SETTINGS, TRAFFIC_SOURCES } from '@ugo/data';
import jsonExport from 'jsonexport/dist';
import { groupBy, map, pick, pipe, prop } from 'ramda';
import { Roles } from '../../shared/types';
import { DataProvider, useDataProvider } from 'ra-core';

const AFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="email@_ilike" label="Email" alwaysOn />
      <SelectInput
        label="Traffic Source"
        source="traffic_source"
        choices={TRAFFIC_SOURCES}
        alwaysOn
      />
    </Filter>
  );
};

export const VwAdminAccountFeedbackList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<AFilter />}
      actions={<VwAdminAccountFeedbackListActions />}
    >
      <Datagrid>
        <FunctionField
          label="Account"
          render={(r: any) => <Link to={`/account/${r.id}`}>VIEW</Link>}
        />
        <FunctionField
          label="Account Feedback"
          render={(r: any) => (
            <Link to={`/account_feedback/${r.account_feedback_id}`}>VIEW</Link>
          )}
        />
        <TextField source="email" />
        <DateField label="Registered At" source="created_at" showTime />
        <TextField label="Traffic Source" source="traffic_source" />
        <TextField
          label="Traffic Source Conversion Text"
          source="traffic_source_other_text"
        />
        <TextField
          label="Traffic Source Affiliate Text"
          source="traffic_source_affiliate_text"
        />
        <FunctionField
          label="Role (determined by `stripe_connected_account_id` existence)"
          render={(r: any) => getRole(r)}
        />
      </Datagrid>
    </List>
  );
};

/**
 * @description This add custom fields to the csv export
 * @docs https://marmelab.com/react-admin/List.html#exporter
 */
const vwAdminAccountFeedbackListActionsExporter = async (
  vwAdminAccountFeedbacks: any[],
  dataProvider: DataProvider
) => {
  const account_feedback_ids = vwAdminAccountFeedbacks
    .map((f) => f.account_feedback_id)
    .filter(Boolean);

  const results = await dataProvider.getMany('account_feedback', {ids: account_feedback_ids});

  const providerDatas = results.data
    .filter((item) => !!item.firebase_authentication_user)
    .map((item) => {
      const id = item.firebase_authentication_user.uid;
      const providerIds = item.firebase_authentication_user.providerData.map((pd: any) => pd.providerId);
      const preferredProviderId = !providerIds.length
        ? 'driver_link'
        : providerIds.includes('google.com')
        ? 'google.com'
        : providerIds.includes('facebook.com')
        ? 'facebook.com'
        : 'password';

      return ({
        id,
        providerIds: JSON.stringify(providerIds),
        preferredProviderId
      });
    });
  const groupedProviderDatasById = groupBy(prop('id'), providerDatas);
  const enrichedVwAdminAccountFeedbacks = vwAdminAccountFeedbacks.map((f) => ({
    ...f,
    ...(groupedProviderDatasById[f.id] && groupedProviderDatasById[f.id][0] || {}),
  }));

  const vwAdminAccountFeedbacksForExport = pipe(
    map((f: any) => ({
      ...f,
      role: getRole(f),
      registered_at: f.created_at,
    })),
    map((f) =>
      pick(
        [
          'id',
          'email',
          'traffic_source',
          'traffic_source_other_text',
          'traffic_source_affiliate_text',
          'role',
          'registered_at',
          'provider',
          'providerIds',
          'preferredProviderId',
        ],
        f
      )
    )
  )(enrichedVwAdminAccountFeedbacks);

  jsonExport(
    vwAdminAccountFeedbacksForExport,
    {
      headers: ['id', 'email'], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'vw_account_feedback');
    }
  );
};

const VwAdminAccountFeedbackListActions = (props: any) => {
  const { className, total, resource, currentSort, filters } = props;

  const { showFilter, displayedFilters, filterValues, ids } = useListContext();
  const dataProvider = useDataProvider();

  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{}}
        exporter={(data) => vwAdminAccountFeedbackListActionsExporter(data, dataProvider)}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};

/**
 * Get user role based on the existence of stripe_connected_account_id
 *  - User can technically have a `driver` role
 *  before having `stripe_connected_account_id` set, but in practice he/she cannot
 *  use the app as a driver (because he/she cannot receive payments without
 *  `stripe_connected_account_id`)
 *  - TODO: Store `allowed roles` as a jsonb array in the database
 */
function getRole(record: any) {
  return record?.payment_metadata?.stripe_connected_account_id
    ? Roles.Driver
    : Roles.Patient;
}
