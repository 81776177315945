import {
  ReservationFor,
  ServicesEnum,
  TimePriority,
  TypeOfTravel,
} from '@ugo/models';
import { isNil, map, pick, pipe } from 'ramda';
import { SUPPORTED_CITIES, SUPPORTED_CITIES_B2B } from '@ugo/data';
import * as Yup from 'yup';
import {
  CalculateDriverAmountValuesInterface,
  CalculateExtraCostValuesInterface,
  CalculateTransactionAmountValuesInterface,
  CalculateTransactionFeesArgsInterface,
  CalculateTransactionFeesInterface,
  CalculateUgoFeeValuesInterface,
} from '../type/calculateTransactionFees.interface';
import { GetDistanceAndDuration_25052022Query } from '@ugo/graphql-operations';
import { logger } from '@ugo/utils';
import { DiscountAmount, DiscountPercent, DiscountUnit } from '@voucherify/sdk';
import { SupportedCurrenciesEnum } from '../../../../apps/ugo-app/src/Public/Quote/SupportedCurrenciesEnum';

/**
 * @description Prepares/returns values for `calculateCostByService` function
 */
function getServiceCostCalculationParams({
  service,
  payload,
}: {
  service: string;
  payload: any;
}) {
  let params: any = {};
  switch (true) {
    case ServicesEnum.CaregivingWithUgosCar === service:
      params = {
        hours: payload?.caregiving_duration,
        distance: payload?.travel_distance,
        driverDistance: getDriverDistance(payload),
        isTwoWay: payload?.type_of_travel === TypeOfTravel.TwoWay,
        specialCase:
          payload?.service_city === 'roma'
            ? GetMinDistancePriceSpecialCasesEnum.Rome
            : GetMinDistancePriceSpecialCasesEnum.Default,
      };

      break;
    case ServicesEnum.CaregivingWithUsersCar === service:
      params = {
        hours: payload?.travel_duration,
        distance: payload?.travel_distance,
        driverDistance: getDriverDistance(payload),
      };
      break;
    case ServicesEnum.CaregivingWithoutCar === service:
      params = {
        hours: payload?.caregiving_duration,
        driverDistance: getDriverDistance(payload),
      };
      break;
    case ServicesEnum.Errands === service:
      params = {
        hours: payload?.caregiving_duration,
        distance: payload?.travel_distance,
        driverDistance: getDriverDistance(payload),
      };
      break;
    case ServicesEnum.CaregivingViaTelemedicine === service:
      params = {
        hours: payload?.caregiving_duration,
        driverDistance: getDriverDistance(payload),
      };
      break;
    case ServicesEnum.PhoneAssistance === service:
      params = {
        hours: payload?.caregiving_duration,
      };
      break;
    default:
      console.log('No condition is met!');
  }

  return params;
}

/**
 * @description Returns cost (price_*) by service in EURs
 */
function calculateCostByService({
  service,
  hours,
  distance,
  ...rest
}: {
  service: ServicesEnum;
  hours: number;
  distance: number;
  driverDistance: number;
  isTwoWay?: boolean;
  specialCase?: GetMinDistancePriceSpecialCasesEnum;
}) {
  let cost = 0;

  switch (true) {
    case ServicesEnum.CaregivingWithUgosCar === service:
      cost = calculateCaregivingWithUgosCar({
        hours,
        distance,
        isTwoWay: rest.isTwoWay,
        specialCase: rest.specialCase,
        driverDistance: rest.driverDistance,
      });
      break;
    case ServicesEnum.CaregivingWithUsersCar === service:
      cost = calculateCaregivingWithUsersCarCost({
        hours,
        distance,
        driverDistance: rest.driverDistance,
      });
      break;
    case ServicesEnum.CaregivingWithoutCar === service:
      cost = calculateCaregivingWithoutCarCost({
        hours,
        driverDistance: rest.driverDistance,
      });
      break;
    case ServicesEnum.Errands === service:
      cost = calculateErrandsCost({
        hours,
        distance,
        driverDistance: rest.driverDistance,
      });
      break;
    case ServicesEnum.CaregivingViaTelemedicine === service:
      cost = calculateTelemedicineCost({
        hours,
        driverDistance: rest.driverDistance,
      });
      break;
    case ServicesEnum.PhoneAssistance === service:
      cost = calculatePhoneAssistanceCost({ hours });
      break;
    default:
      console.log('No condition is met!');

      break;
  }

  return parseFloat(roundToNearestHalf(cost).toFixed(2));
}

function roundToNearestHalf(value: number) {
  return Math.round(value * 2) / 2;
}

/**
 * @description Calculates costs for caregiving with Ugo's car
 * The user can ask for a single way (for example because he is going
 * to be operated) or for a way out and back. The total km is
 * in the second case, calculated as the single distance
 * between starting and ending point, multiplied by 2.
 *
 * - isTwoWay option should be passed via form (validation)
 * - If distance is more than 10km consider it two-way
 */
function calculateCaregivingWithUgosCar({
  hours,
  distance, // km
  driverDistance = 0, // km
  isTwoWay,
  specialCase = GetMinDistancePriceSpecialCasesEnum.Default,
}: {
  hours: number;
  distance: number;
  isTwoWay?: boolean;
  specialCase?: GetMinDistancePriceSpecialCasesEnum;
  driverDistance: number; // km
}) {
  const CAREGIVING_WITH_UGOS_CAR_PRICE_PER_HOUR = 15;

  const timeCost = calculateTimeCostWithHourlyIntervals({
    hours,
    pricePerHour: CAREGIVING_WITH_UGOS_CAR_PRICE_PER_HOUR,
  });

  const _distance = isTwoWay ? distance * 2 : distance;

  logger.debug(`Distance travel considered: ${_distance}`);
  logger.debug(`Caregiving duration considered: ${hours}h`);

  const distanceCost = calculateDistanceCost({
    distance: _distance + driverDistance,
    minPrice: getMinDistancePrice({ specialCase }),
  });

  logger.debug(
    `Distance cost (driver + travel) - not rounded: ${distanceCost}`
  );
  logger.debug(`Caregiving duration cost: ${timeCost}`);

  return timeCost + distanceCost;
}

/**
 * @description Calculates cost for caregiving/driving from point A to point B
 * with the user's car. This option works only for long distances.
 * Short distances should be prevented via UI (form validation).
 *
 * timeCost - +15 €/hour; every added hours cost (hour - 1; - 1€)
 * kaskoCost - Kasko insurance cost is calculated On the distance of the
 * first route (2,5 €/50 km)
 * wayBackCost - Expenses refund is calculated for
 * the way back (0,50 €/km. After 200 km, every additional km costs 0,25 €/km)
 * distanceCost - kaskoCost + wayBackCost;
 *
 * User story: User can go in the mountains for a month and
 * want's to keep her car there/with her (80 yo can't drive such an
 * "intensive" and "dangerous" distance). Ugo's driver goes back to
 * Milano after the ride to the mountains.
 *
 * @param {number} hours
 * @param {number} distance - km
 * @param {number} driverDistance - km
 */
function calculateCaregivingWithUsersCarCost({
  hours = 0,
  distance = 0,
  driverDistance = 0,
}: {
  hours: number;
  distance: number; // km
  driverDistance: number; // km
}) {
  const CAREGIVING_WITH_USERS_CAR_PRICE_PER_HOUR = 15;
  const timeCost = calculateTimeCostWithHourlyIntervals({
    hours,
    pricePerHour: CAREGIVING_WITH_USERS_CAR_PRICE_PER_HOUR,
  });

  // Kasko
  const CAREGIVING_WITH_USERS_CAR_KASKO_PER_50_KM = 2.5;
  const fiftyKmChunks = Math.ceil(distance / 50);
  const kaskoCost = fiftyKmChunks * CAREGIVING_WITH_USERS_CAR_KASKO_PER_50_KM;

  logger.debug(
    `Kasko cost: ${kaskoCost} ((${distance}/50)*${CAREGIVING_WITH_USERS_CAR_KASKO_PER_50_KM}`
  );

  // Wayback
  const wayBackDistance = Math.ceil(distance); // km
  const wayBackCost = calculateDistanceCost({
    distance: wayBackDistance + driverDistance,
  });

  logger.debug(`Wayback distance considered: ${wayBackDistance}`);
  logger.debug(`Wayback cost: ${wayBackCost}`);

  const distanceCost = kaskoCost + wayBackCost;

  return timeCost + distanceCost;
}

function calculateCaregivingWithoutCarCost({
  hours = 0,
  driverDistance = 0,
}: {
  hours: number;
  driverDistance: number; // km
}) {
  const CAREGIVING_WITHOUT_CAR_PRICE_PER_HOUR = 15;

  const hourlyCost = calculateTimeCostWithHourlyIntervals({
    hours,
    pricePerHour: CAREGIVING_WITHOUT_CAR_PRICE_PER_HOUR,
  });

  const distanceCost = calculateDistanceCost({
    distance: driverDistance,
    minPrice: 0,
  });

  logger.debug(`Distance driver considered: ${driverDistance}`);
  logger.debug(`Caregiving duration considered: ${hours}h`);

  logger.debug(`Distance driver cost: ${distanceCost}`);
  logger.debug(`Caregiving duration cost: ${hourlyCost}`);

  return hourlyCost + distanceCost;
}

/**
 * @description +15 €/hour; every added hours cost (hour - 1; - 1€)
 */
function calculateTelemedicineCost({
  hours = 0,
  driverDistance = 0, // km
}: {
  hours: number;
  driverDistance: number;
}) {
  const TELEMEDICINE_PRICE_PER_HOUR = 15;

  const hourlyCost = calculateTimeCostWithHourlyIntervals({
    hours,
    pricePerHour: TELEMEDICINE_PRICE_PER_HOUR,
  });

  const distanceCost = calculateDistanceCost({
    distance: driverDistance,
    minPrice: 0,
  });

  logger.debug(`Distance driver considered: ${driverDistance}`);
  logger.debug(`Caregiving duration considered: ${hours}h`);

  logger.debug(`Distance driver cost: ${distanceCost}`);
  logger.debug(`Caregiving duration cost: ${hourlyCost}`);

  return hourlyCost + distanceCost;
}

/**
 * @description Calculates errands cost
 *  - every 1st hour costs 12.5 eur
 *  - every subsequent half hour costs 6 eur
 *  - distance is calculated 2 ways (from A to B and from B to A)
 *  - min distance to include distance calculation is 3km (in one way)
 *
 *  Most common case: Go (Ugo's Driver) to the hospital to get something
 *  for the elderly and along the way get some groceries also.
 *
 * @docs https://miro.com/app/board/o9J_laxXQew=/?moveToWidget=3074457352770061270&cot=14
 */
function calculateErrandsCost({
  hours = 0,
  distance = 0,
  driverDistance = 0,
}: {
  hours: number;
  distance: number; // km
  driverDistance: number; // km
}) {
  const ERRANDS_FIRST_HOUR_COST = 12.5;
  const ERRANDS_SUBSEQUENT_PRICE_PER_HALF_HOUR = 6;

  const halfHours = hours > 1 ? divideHoursInHalf(hours - 1) : 0;

  const subsequentHalfHoursCost = calculateTimeCostWithHalfHourIntervals({
    halfHours,
    pricePerHalfHour: ERRANDS_SUBSEQUENT_PRICE_PER_HALF_HOUR,
  });

  // Don't calculate distance cost if it's lower than 3 kms in one way
  // Distance is calculated 2 ways (from A to B and from B to A)
  const distanceCost =
    distance <= 3
      ? 0
      : calculateDistanceCost({ distance: distance * 2 + driverDistance });

  logger.debug(`Distance driver considered: ${driverDistance}`);
  logger.debug(`Caregiving duration considered: ${hours}h`);

  logger.debug(`Distance cost: ${distanceCost}`);
  logger.debug(
    `Caregiving duration cost: ${
      ERRANDS_FIRST_HOUR_COST + subsequentHalfHoursCost
    }`
  );

  return ERRANDS_FIRST_HOUR_COST + subsequentHalfHoursCost + distanceCost;
}

function calculatePhoneAssistanceCost({ hours = 0 }: { hours: number }) {
  const PHONE_ASSISTANCE_PRICE_PER_HALF_HOUR = 5;
  const PHONE_ASSISTANCE_MIN_HOURS = 0.5;

  const halfHours = divideHoursInHalf(
    PHONE_ASSISTANCE_MIN_HOURS > hours ? PHONE_ASSISTANCE_MIN_HOURS : hours
  );
  return calculateTimeCostWithHalfHourIntervals({
    halfHours,
    pricePerHalfHour: PHONE_ASSISTANCE_PRICE_PER_HALF_HOUR,
  });
}

function calculateQuotePrice(quote: any) {
  const SELECTED_SERVICE = quote.service;
  logger.debug(`--- Quote calculation ---`);
  logger.debug(`Service: ${SELECTED_SERVICE}`);
  logger.debug(`Distance driver (0A): ${quote.distance_between_0_and_a}`);
  logger.debug(`Distance driver (0B): ${quote.distance_between_0_and_b}`);
  logger.debug(`Distance travel (AB): ${quote.travel_distance}`);

  const { hours, distance, driverDistance, ...rest } =
    getServiceCostCalculationParams({
      service: SELECTED_SERVICE,
      payload: {
        ...quote,
      },
    });

  const _cost = calculateCostByService({
    service: SELECTED_SERVICE,
    hours,
    distance,
    driverDistance,
    ...rest,
  });

  return {
    duration_estimate: hours,
    price_estimate: _cost,
    currency: 'EUR',
  };
}

/**
 * ----------- CONSTANTS -----------
 */

enum GetMinDistancePriceSpecialCasesEnum {
  Rome = 'ROME',
  Default = 'DEFAULT',
}

/**
 * ------------ HELPERS ------------
 */

function divideHoursInHalf(hours: number) {
  return hours * 2;
}

function getDriverDistance({
  is_provided_in_city,
  shortest_distance_from_city_center,
  distance_between_0_and_a,
  distance_between_0_and_b,
  type_of_travel,
}: {
  is_provided_in_city: any;
  shortest_distance_from_city_center: any;
  distance_between_0_and_a: number;
  distance_between_0_and_b: number;
  type_of_travel: TypeOfTravel;
}) {
  const isTwoWay = type_of_travel === TypeOfTravel.TwoWay;

  const isDistance0APayable =
    distance_between_0_and_a > CITY_RADIUS_LIMIT_IN_KMS;
  const isDistance0BPayable =
    distance_between_0_and_b > CITY_RADIUS_LIMIT_IN_KMS;

  let driver_distance = 0;

  if (isTwoWay) {
    /*
     * CC ➝ A ➝ B ➝ A ➝ CC
     * Driver always returns to A (because is_two_way = true) and back to CC
     */

    // Adding 0A if 0A > 15km
    // Multiplying by 2 because is_two_way always returns on 0A
    if (isDistance0APayable) {
      driver_distance += distance_between_0_and_a * 2;
    }
    logger.debug(`Distance driver considered: ${driver_distance} (A0*2)`);
  } else {
    /**
     * CC ➝ A ➝ B ➝ CC
     * Driver returns on 0B because is_two_way = true
     */
    if (isDistance0APayable) {
      driver_distance += distance_between_0_and_a;
    }

    if (isDistance0BPayable) {
      driver_distance += distance_between_0_and_b;
    }
    logger.debug(`Distance driver considered: ${driver_distance} (A0+B0)`);
  }

  return driver_distance;
}
/*
 *  Hours need to be rounded to the nearest 0,5h
 *  https://app.clickup.com/t/1uq9v7n
 *
 *  From :00 to :15 we round to ,00 before
 *  From :16 to :45 we round to ,30 before
 *  From :46 to :00 we round to ,00 after
 */
function calculateTimeCostWithHourlyIntervals({
  hours,
  pricePerHour,
}: {
  hours: number;
  pricePerHour: number;
}) {
  const _hours = hours < 1 ? 1 : Math.round(hours * 2) / 2;
  const fullHours = Math.floor(_hours);
  const fractionalHour = _hours - fullHours;

  /**
   * Create an array of hour multipliers e.g. [1, 1, 0.5]
   * e.g.
   * hours = 2.55
   * hours gets rounded to 2.5
   * multiplier array = [1, 1, 0.5]
   */
  const hourMultiplierArray = Array.from({ length: fullHours }).map(
    (_, idx) => 1
  );

  if (fractionalHour) {
    hourMultiplierArray.push(fractionalHour);
  }

  /**
   * Multiply the hourly prices with the multiplier array,
   * so we correctly calculate the fractional hour
   *
   * multiplier array = [1, 1, 0.5]
   * hourly array = [15*1, 14*1, 13*0.5]
   */
  const hourlyArray = hourMultiplierArray.map(
    (multiplier, idx) => (idx + 1 >= 8 ? 8 : pricePerHour - idx) * multiplier
  );

  // Return the reduced value
  return hourlyArray.reduce((a, b) => a + b, 0);
}

function calculateTimeCostWithHalfHourIntervals({
  halfHours,
  pricePerHalfHour,
}: {
  pricePerHalfHour: number;
  halfHours: number;
}) {
  return halfHours * pricePerHalfHour;
}

/**
 * @description Calculates distance costs
 *
 *  - The cost of a single km is equal to 0,50 € till 200 km.
 *  - Every km added after 200km costs 0,25 €.
 */
function calculateDistanceCost({
  distance = 0,
  minPrice,
}: {
  distance: number;
  minPrice?: number;
}) {
  const PRICE_PER_KM_BELOW_200_KM = 0.5;
  /**
   * @description Sets a price to be applied above 200km
   *  - It has been a different price prior to 15. 4. 2022
   *  - We're keeping the same logic as before but apply
   *  the same price as `below 200 km`; We're keeping this if we need to set
   *  a different price above 200km in the future again
   */
  const PRICE_PER_KM_ABOVE_200_KM = 0.5;

  const _distance = distance;

  const numberOfAbove200Km = _distance - 200 <= 0 ? 0 : _distance - 200;
  const numberOfBelow200Km = numberOfAbove200Km > 0 ? 200 : _distance;

  const above200KmCost = numberOfAbove200Km * PRICE_PER_KM_ABOVE_200_KM;
  const below200KmCost = numberOfBelow200Km * PRICE_PER_KM_BELOW_200_KM;

  const _cost = below200KmCost + above200KmCost;
  const _minPrice =
    typeof minPrice !== 'undefined' && !isNaN(minPrice) ? minPrice : 5;

  return _cost < _minPrice ? _minPrice : _cost;
}

function getMinDistancePrice({
  specialCase,
}: {
  specialCase: GetMinDistancePriceSpecialCasesEnum;
}) {
  type GetMinDistancePriceSpecialCasesType = {
    [K in GetMinDistancePriceSpecialCasesEnum]: {
      MIN_PRICE: number;
    };
  };

  const GET_MINIMUM_DISTANCE_PRICE_SPECIAL_CASES: GetMinDistancePriceSpecialCasesType =
    {
      [GetMinDistancePriceSpecialCasesEnum.Rome]: {
        MIN_PRICE: 10,
      },
      [GetMinDistancePriceSpecialCasesEnum.Default]: {
        MIN_PRICE: 5,
      },
    };

  return GET_MINIMUM_DISTANCE_PRICE_SPECIAL_CASES[specialCase] &&
    GET_MINIMUM_DISTANCE_PRICE_SPECIAL_CASES[specialCase].MIN_PRICE
    ? GET_MINIMUM_DISTANCE_PRICE_SPECIAL_CASES[specialCase].MIN_PRICE
    : GET_MINIMUM_DISTANCE_PRICE_SPECIAL_CASES[
        GetMinDistancePriceSpecialCasesEnum.Default
      ].MIN_PRICE;
}

// This is ESTIMATE quote schema validation
const quoteValidationSchema = Yup.object().shape({
  service: Yup.string().required(),
  service_city: Yup.string().when('service', {
    is: !ServicesEnum.PhoneAssistance,
    then: Yup.string().required(),
  }),
  departure_address: Yup.string().when('service', {
    is: (service: string) =>
      !ServicesEnum.PhoneAssistance && !ServicesEnum.CaregivingViaTelemedicine,
    then: Yup.string().required(),
  }),
  departure_lng: Yup.number().when('service', {
    is: (service: string) =>
      !ServicesEnum.PhoneAssistance && !ServicesEnum.CaregivingViaTelemedicine,
    then: Yup.number().required(),
  }),
  departure_lat: Yup.number().when('service', {
    is: (service: string) =>
      !ServicesEnum.PhoneAssistance && !ServicesEnum.CaregivingViaTelemedicine,
    then: Yup.number().required(),
  }),
  destination_address: Yup.string().when('service', {
    is: (service: string) =>
      service !== ServicesEnum.PhoneAssistance &&
      service !== ServicesEnum.CaregivingWithoutCar,
    then: Yup.string().required(),
  }),
  destination_lng: Yup.number().when('service', {
    is: (service: string) =>
      service !== ServicesEnum.PhoneAssistance &&
      service !== ServicesEnum.CaregivingWithoutCar,
    then: Yup.number().required(),
  }),
  destination_lat: Yup.number().when('service', {
    is: (service: string) =>
      service !== ServicesEnum.PhoneAssistance &&
      service !== ServicesEnum.CaregivingWithoutCar,
    then: Yup.number().required(),
  }),
  caregiving_duration: Yup.number().when('service', {
    is: (service: string) => service !== ServicesEnum.CaregivingWithUsersCar,
    then: Yup.number().required(),
  }),
  time_priority: Yup.string(),
  time: Yup.string().when(['service', 'time_priority'], {
    is: (service: string, time_priority: string) =>
      !(
        service === ServicesEnum.Errands &&
        time_priority === TimePriority.NotImportant
      ),
    then: Yup.string().required(),
  }),
  date: Yup.string().required(),
  reservor_metadata: Yup.object().required(),
  reservee_metadata: Yup.object().when('reservation_for', {
    is: ReservationFor.Other,
    then: Yup.object().required(),
  }),
  price_estimate: Yup.number().required(),
});

const validateQuote = (quote: any) => {
  const SELECTED_SERVICE = quote.service;

  switch (SELECTED_SERVICE) {
    case ServicesEnum.CaregivingWithoutCar: {
      return (
        !!quote?.departure_address &&
        !!quote?.departure_lng &&
        !!quote?.departure_lat
      );
    }
    case ServicesEnum.CaregivingViaTelemedicine: {
      return (
        !!quote?.destination_address &&
        !!quote?.destination_lng &&
        !!quote?.destination_lat
      );
    }
    case ServicesEnum.CaregivingWithUgosCar:
    case ServicesEnum.CaregivingWithUsersCar:
    case ServicesEnum.Errands: {
      return (
        !!quote?.departure_address &&
        !!quote?.departure_lng &&
        !!quote?.departure_lat &&
        !!quote?.destination_address &&
        !!quote?.destination_lng &&
        !!quote?.destination_lat &&
        !!quote?.caregiving_duration &&
        !!quote?.date &&
        !!quote?.time
      );
    }
    default: {
      return true;
    }
  }
};

// Possibly incorrect implementation
function validateQuoteInput(quote: any) {
  const SELECTED_SERVICE = quote.service;

  switch (SELECTED_SERVICE) {
    case ServicesEnum.CaregivingWithoutCar: {
      return (
        !!quote?.departure_address &&
        !!quote?.departure_lng &&
        !!quote?.departure_lat
      );
    }
    case ServicesEnum.CaregivingViaTelemedicine: {
      return (
        !!quote?.destination_address &&
        !!quote?.destination_lng &&
        !!quote?.destination_lat
      );
    }
    case ServicesEnum.CaregivingWithUgosCar:
    case ServicesEnum.CaregivingWithUsersCar:
    case ServicesEnum.Errands: {
      return (
        !!quote?.departure_address &&
        !!quote?.departure_lng &&
        !!quote?.departure_lat &&
        !!quote?.destination_address &&
        !!quote?.destination_lng &&
        !!quote?.destination_lat
      );
    }
    default: {
      return false;
    }
  }
}

function validateAddresses(quote: any) {
  if (!quote.service_city || typeof quote.service_city === 'number') {
    return false;
  }
  switch (quote.service) {
    case ServicesEnum.CaregivingWithUgosCar:
    case ServicesEnum.CaregivingWithUsersCar: {
      return (
        !!quote.service &&
        !!quote.departure_address &&
        !!quote.departure_lat &&
        !!quote.departure_lng &&
        !!quote.destination_address &&
        !!quote.destination_lat &&
        !!quote.destination_lng &&
        !!quote.service_city
      );
    }
    case ServicesEnum.CaregivingWithoutCar: {
      return (
        !!quote.service &&
        !!quote.departure_address &&
        !!quote.departure_lat &&
        !!quote.departure_lng &&
        !!quote.service_city
      );
    }
    case ServicesEnum.CaregivingViaTelemedicine: {
      return (
        !!quote.service &&
        !!quote.destination_address &&
        !!quote.destination_lat &&
        !!quote.destination_lng &&
        !!quote.service_city
      );
    }
    case ServicesEnum.Errands: {
      return (
        !!quote.service &&
        !!quote.departure_address &&
        !!quote.departure_lat &&
        !!quote.departure_lng &&
        !!quote.destination_address &&
        !!quote.destination_lat &&
        !!quote.destination_lng &&
        !!quote.service_city
      );
    }
    case ServicesEnum.PhoneAssistance: {
      return false;
    }
    default: {
      return false;
    }
  }
}

/**
 * @description Returns `getDistanceAndDurationInput` by selected service
 *  - The subsequent call `getDistanceAndDuration` could be further optimized on the backend
 *  - This is a quick and dirty solution
 */
function getDistanceAndDurationInputBySelectedService({
  departure,
  destination,
  service,
  serviceCity,
  businessType = 'b2c',
}: {
  departure: { lat: number; lng: number };
  destination: { lat: number; lng: number };
  serviceCity: string;
  service: string;
  businessType?: 'b2b' | 'b2c';
}) {
  const supportedCities =
    businessType === 'b2b' ? SUPPORTED_CITIES_B2B : SUPPORTED_CITIES;

  const city = supportedCities.find((c) => c.name === serviceCity);
  const SELECTED_SERVICE = service;

  return SELECTED_SERVICE === ServicesEnum.CaregivingViaTelemedicine
    ? {
        departure: {
          lng: destination.lng,
          lat: destination.lat,
        },
        destination: {
          lng: destination.lng,
          lat: destination.lat,
        },
        city: city ? (city ? pick(['lat', 'lng'], city) : null) : null,
      }
    : SELECTED_SERVICE === ServicesEnum.CaregivingWithoutCar
    ? {
        departure: {
          lng: departure.lng,
          lat: departure.lat,
        },
        destination: {
          lng: departure.lng,
          lat: departure.lat,
        },
        city: city ? pick(['lat', 'lng'], city) : null,
      }
    : {
        departure: {
          lng: departure.lng,
          lat: departure.lat,
        },
        destination: {
          lng: destination.lng,
          lat: destination.lat,
        },
        city: city ? pick(['lat', 'lng'], city) : null,
      };
}

const CAREGIVING_WITH_UGOS_CAR_CITY_INSIDE_MIN_DISTANCE = 10; // kms

function calculateFinalTravelDistanceAndDuration(
  data: GetDistanceAndDuration_25052022Query,
  quote: any
) {
  const isTwoWay = quote.is_two_way;

  // FROM A to B
  const travel_distance =
    data?.getDistanceAndDuration?.ab?.distance?.kilometers;

  const travel_duration = data?.getDistanceAndDuration?.ab?.duration?.hours;

  // From CC to A
  const distance_between_0_and_a =
    data?.getDistanceAndDuration?.a0?.distance?.kilometers;

  // FROM B to CC
  const distance_between_0_and_b =
    data?.getDistanceAndDuration?.b0?.distance?.kilometers;

  const shortest_distance_from_city_center = [
    distance_between_0_and_a,
    distance_between_0_and_b,
  ].sort((a, b) => a - b)[0];

  const is_provided_in_city = !(
    shortest_distance_from_city_center > CITY_RADIUS_LIMIT_IN_KMS
  );

  const is_shortest_distance_from_city_center_over_city_radius_limit =
    shortest_distance_from_city_center > CITY_RADIUS_LIMIT_IN_KMS;

  return {
    distance_between_0_and_a,
    distance_between_0_and_b,
    travel_distance,
    travel_duration,
    is_provided_in_city,
    shortest_distance_from_city_center,
    is_shortest_distance_from_city_center_over_city_radius_limit,
  };
}

// New calculation utils 24. 5. 2022

const UGO_FEE_PERCENTAGE = 25; // > 0 - 100
const DRIVER_AMOUNT_PERCENTAGE = 100 - UGO_FEE_PERCENTAGE;

function calculateDriverAmountValues({
  _total_amount_stripe_fee,
  amount,
  driver_amount_percentage,
}: {
  _total_amount_stripe_fee: number;
  amount: number;
  driver_amount_percentage: number;
}): CalculateDriverAmountValuesInterface {
  const _driver_amount_stripe_fee = calculateDriversProportionOfStripeFee({
    _total_amount_stripe_fee: _total_amount_stripe_fee,
    driver_amount_percentage,
  });

  const _driver_amount = calculateDriverAmount({
    amount,
    _driver_amount_stripe_fee,
  });

  const _driver_amount_with_stripe_fee = calculateDriverAmountWithStripeFee({
    amount,
  });

  const _driver_amount_stripe_fee_proportion =
    getDriverAmountStripeFeeProportion(driver_amount_percentage);

  return {
    _driver_amount_stripe_fee,
    _driver_amount,
    _driver_amount_with_stripe_fee,
    _driver_amount_stripe_fee_proportion,
  };
}

function calculateUgoFeeValues({
  _total_amount_stripe_fee,
  amount,
  _driver_amount,
  _driver_amount_stripe_fee,
  ugo_fee_percentage,
}: {
  _total_amount_stripe_fee: number;
  amount: number;
  _driver_amount: number;
  _driver_amount_stripe_fee: number;
  ugo_fee_percentage: number;
}): CalculateUgoFeeValuesInterface {
  const _ugo_fee_stripe_fee = calculateUgosProportionOfStripeFee(
    _total_amount_stripe_fee,
    ugo_fee_percentage
  );

  const _ugo_fee = calculateUgoFee({
    amount,
    _ugo_fee_stripe_fee,
    _driver_amount,
    _driver_amount_stripe_fee,
  });

  const _ugo_fee_with_stripe_fee = calculateUgosFeeWithStripeFee({
    _ugo_fee,
    _ugo_fee_stripe_fee,
  });

  return {
    _ugo_fee_stripe_fee,
    _ugo_fee,
    _ugo_fee_with_stripe_fee,
  };
}

function calculateTransactionAmountValues({
  amount,
}: {
  amount: number;
}): CalculateTransactionAmountValuesInterface {
  const transaction_amount = calculateTransactionAmount({
    amount,
  });

  const _transaction_amount_stripe_fee =
    calculateStripeFeeFromTransactionAmount(transaction_amount);

  return {
    transaction_amount,
    _transaction_amount_stripe_fee,
  };
}

function calculateExtraCostValues({
  existing_transaction_amount,
  _driver_amount,
  extra_cost,
  _total_amount_stripe_fee,
}: {
  existing_transaction_amount: number;
  _driver_amount: number;
  extra_cost: number;
  _total_amount_stripe_fee: number;
}): CalculateExtraCostValuesInterface {
  const transaction_amount = addExtraCostToTransactionAmount({
    existing_transaction_amount,
    extra_cost,
  });

  const _transaction_amount_stripe_fee =
    calculateStripeFeeFromTransactionAmount(transaction_amount);

  const _extra_cost_stripe_fee = calculateStripeFeeFromExtraCost({
    _transaction_amount_stripe_fee,
    _total_amount_stripe_fee,
  });

  const _driver_amount_with_extra_cost = calculateDriverAmountWithExtraCost({
    _driver_amount,
    extra_cost,
  });
  return {
    transaction_amount,
    _transaction_amount_stripe_fee,
    _extra_cost_stripe_fee,
    _driver_amount_with_extra_cost,
  };
}

function calculateStripeFeeFromTotalAmount({
  amount,
}: {
  amount: number;
}): number {
  return amount * 0.015 + 0.25;
}

function calculateUgosProportionOfStripeFee(
  _total_amount_stripe_fee: number,
  ugo_fee_percentage: number
) {
  return _total_amount_stripe_fee * (ugo_fee_percentage / 100);
}

function calculateDriversProportionOfStripeFee({
  _total_amount_stripe_fee,
  driver_amount_percentage,
}: {
  _total_amount_stripe_fee: number;
  driver_amount_percentage: number;
}) {
  return _total_amount_stripe_fee * (driver_amount_percentage / 100);
}

function calculateDriverAmount({
  amount,
  _driver_amount_stripe_fee,
}: {
  amount: number;
  _driver_amount_stripe_fee: number;
}) {
  return amount * (DRIVER_AMOUNT_PERCENTAGE / 100) - _driver_amount_stripe_fee;
}

function calculateDriverAmountWithStripeFee({ amount }: { amount: number }) {
  return amount * (DRIVER_AMOUNT_PERCENTAGE / 100);
}

function calculateUgoFee({
  amount,
  _ugo_fee_stripe_fee,
  _driver_amount,
  _driver_amount_stripe_fee,
}: {
  amount: number;
  _ugo_fee_stripe_fee: number;
  _driver_amount: number;
  _driver_amount_stripe_fee: number;
}) {
  return (
    amount - (_driver_amount + _driver_amount_stripe_fee) - _ugo_fee_stripe_fee
  );
}

function calculateUgosFeeWithStripeFee({
  _ugo_fee,
  _ugo_fee_stripe_fee,
}: {
  _ugo_fee: number;
  _ugo_fee_stripe_fee: number;
}) {
  return _ugo_fee + _ugo_fee_stripe_fee;
}

function getDriverAmountStripeFeeProportion(proportionInPercentage: number) {
  return proportionInPercentage;
}

/**
 * @description Calculates the *amount of money that goes to Ugo, plus the
 * Stripe fee* that will be deducted in the final step of Stripe transaction
 *
 * @docs Stripe transaction flow: https://stripe.com/docs/connect/destination-charges#application-fee
 */
function calculateApplicationFee({
  _ugo_fee,
  _ugo_fee_stripe_fee,
  _driver_amount_stripe_fee,
}: {
  _ugo_fee: number;
  _ugo_fee_stripe_fee: number;
  _driver_amount_stripe_fee: number;
}) {
  return _ugo_fee + _ugo_fee_stripe_fee + _driver_amount_stripe_fee;
}

function calculateTransactionAmount({ amount }: { amount: number }) {
  return amount;
}

function calculateDriverAmountWithExtraCost({
  _driver_amount,
  extra_cost,
}: {
  _driver_amount: number;
  extra_cost: number;
}) {
  return _driver_amount + extra_cost;
}

function calculateStripeFeeFromTransactionAmount(transaction_amount: number) {
  return transaction_amount * 0.015 + 0.25;
}

function calculateStripeFeeFromExtraCost({
  _transaction_amount_stripe_fee,
  _total_amount_stripe_fee,
}: {
  _transaction_amount_stripe_fee: number;
  _total_amount_stripe_fee: number;
}) {
  return _transaction_amount_stripe_fee - _total_amount_stripe_fee;
}

function addExtraCostToTransactionAmount({
  existing_transaction_amount,
  extra_cost,
}: {
  existing_transaction_amount: number;
  extra_cost: number;
}) {
  return existing_transaction_amount + extra_cost;
}

/**
 * @description Keeps all keys in place even if the values are `nil` (using `isNil`)
 *  - `nil` values are switched to '-'
 */
function sanitizeFinalPayload(payload: any) {
  const _payload = {
    ...payload,
    _total_amount_discounted:
      payload._total_amount_discounted === 0
        ? null
        : payload._total_amount_discounted,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return pipe(map((prop) => (isNil(prop) ? '-' : prop)))(
    _payload
  ) as CalculateTransactionFeesInterface;
}

function sortObjectKeysAlphabetically(object: { [key: string]: any }) {
  return Object.keys(object)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: object[key],
      }),
      {}
    );
}

/**
 * NOTE: Copied from functions
 * @description Calculates transactions fees related to the Stripe transaction processs
 *  - Docs (detailed): https://app.clickup.com/2662099/v/dc/2h7pk-525/2h7pk-3022
 *  - How does the function logic work (simplified)?
 *  	1. Calculates all possible transaction amount values (_ugo_fee, _ugo_fee_stripe_fee, _driver_amount, _driver_amount_stripe_fee, ...)
 *  	2. Applies discount and re-calculates relevant values
 *  		2.1. Handles edge-case scenarios related to discounts
 *  	3. Applies extra cost and re-calculates relevant values
 */
function calculateTransactionFees({
  total_amount,
  total_amount_discounted,
  extra_cost = 0,
}: CalculateTransactionFeesArgsInterface): CalculateTransactionFeesInterface {
  const __is_extra_cost_applied = !isNil(extra_cost) && extra_cost > 0;
  const __is_discount_applied =
    !isNil(total_amount_discounted) && total_amount_discounted > 0;
  let __is_driver_paying_whole_stripe_fee = false;

  let _total_amount_stripe_fee = calculateStripeFeeFromTotalAmount({
    amount: total_amount,
  });

  let driverAmountValues = calculateDriverAmountValues({
    _total_amount_stripe_fee,
    amount: total_amount,
    driver_amount_percentage: DRIVER_AMOUNT_PERCENTAGE,
  });

  let ugoFeeValues = calculateUgoFeeValues({
    amount: total_amount,
    _total_amount_stripe_fee,
    _driver_amount: driverAmountValues._driver_amount,
    _driver_amount_stripe_fee: driverAmountValues._driver_amount_stripe_fee,
    ugo_fee_percentage: UGO_FEE_PERCENTAGE,
  });

  let application_fee = calculateApplicationFee({
    _ugo_fee: ugoFeeValues._ugo_fee,
    _ugo_fee_stripe_fee: ugoFeeValues._ugo_fee_stripe_fee,
    _driver_amount_stripe_fee: driverAmountValues._driver_amount_stripe_fee,
  });

  let transactionAmountValues = calculateTransactionAmountValues({
    amount: total_amount,
  });

  if (__is_discount_applied) {
    _total_amount_stripe_fee = calculateStripeFeeFromTotalAmount({
      amount: total_amount_discounted,
    });

    driverAmountValues = calculateDriverAmountValues({
      _total_amount_stripe_fee,
      amount: total_amount,
      driver_amount_percentage: DRIVER_AMOUNT_PERCENTAGE,
    });

    ugoFeeValues = calculateUgoFeeValues({
      amount: total_amount_discounted,
      _total_amount_stripe_fee,
      _driver_amount: driverAmountValues._driver_amount,
      _driver_amount_stripe_fee: driverAmountValues._driver_amount_stripe_fee,
      ugo_fee_percentage: UGO_FEE_PERCENTAGE,
    });

    application_fee = calculateApplicationFee({
      _ugo_fee: ugoFeeValues._ugo_fee,
      _ugo_fee_stripe_fee: ugoFeeValues._ugo_fee_stripe_fee,
      _driver_amount_stripe_fee: driverAmountValues._driver_amount_stripe_fee,
    });

    transactionAmountValues = calculateTransactionAmountValues({
      amount: total_amount_discounted,
    });

    __is_driver_paying_whole_stripe_fee =
      ugoFeeValues._ugo_fee_with_stripe_fee < ugoFeeValues._ugo_fee_stripe_fee;

    if (__is_driver_paying_whole_stripe_fee) {
      // Driver pays the whole Stripe fee when Ugo cannot pay it's share of Stripe fee
      // This can happen around 25% discount and more (25% is also the share of Ugo ATM (April 2022))
      driverAmountValues = calculateDriverAmountValues({
        _total_amount_stripe_fee,
        amount: total_amount,
        driver_amount_percentage: 100,
      });

      ugoFeeValues = calculateUgoFeeValues({
        amount: total_amount_discounted,
        _total_amount_stripe_fee,
        _driver_amount: driverAmountValues._driver_amount,
        _driver_amount_stripe_fee: driverAmountValues._driver_amount_stripe_fee,
        ugo_fee_percentage: 0,
      });

      application_fee = calculateApplicationFee({
        _ugo_fee: ugoFeeValues._ugo_fee,
        _ugo_fee_stripe_fee: ugoFeeValues._ugo_fee_stripe_fee,
        _driver_amount_stripe_fee: driverAmountValues._driver_amount_stripe_fee,
      });
    }
  }

  let extraCostValues = {};

  if (__is_extra_cost_applied) {
    extraCostValues = calculateExtraCostValues({
      existing_transaction_amount: transactionAmountValues.transaction_amount,
      _driver_amount: driverAmountValues._driver_amount,
      extra_cost,
      _total_amount_stripe_fee,
    });
  }

  return sanitizeFinalPayload({
    application_fee,
    ...transactionAmountValues,
    ...driverAmountValues,
    ...ugoFeeValues,
    ...extraCostValues,
    //
    _total_amount: total_amount,
    _total_amount_discounted: total_amount_discounted,
    _extra_cost: extra_cost,
    ///
    __is_discount_applied,
    __is_extra_cost_applied,
    __is_driver_paying_whole_stripe_fee,
    ////
    ___docs_uri: 'https://app.clickup.com/2662099/v/dc/2h7pk-525/2h7pk-3022',
    ___docs_version: 'v1.0.0',
  });
}

function getTotalReservationDistance({
  service,
  distance_between_0_and_a,
  distance_between_0_and_b,
  travel_distance,
  type_of_travel,
}: {
  service: ServicesEnum;
  distance_between_0_and_a: number;
  distance_between_0_and_b: number;
  travel_distance: number;
  type_of_travel: TypeOfTravel;
}) {
  const isTwoWay = type_of_travel === TypeOfTravel.TwoWay;
  const driverDistance = getDriverDistance({
    distance_between_0_and_a,
    distance_between_0_and_b,
    type_of_travel,
    is_provided_in_city: null,
    shortest_distance_from_city_center: null,
  });

  switch (service) {
    case ServicesEnum.CaregivingWithUgosCar: {
      return (
        (isTwoWay ? travel_distance * 2 : travel_distance) + driverDistance
      );
    }
    case ServicesEnum.CaregivingWithUsersCar: {
      const wayBackDistance = Math.ceil(travel_distance);
      return wayBackDistance + driverDistance;
    }
    case ServicesEnum.CaregivingWithoutCar: {
      return driverDistance;
    }
    case ServicesEnum.Errands: {
      return travel_distance * 2 + driverDistance;
    }
    case ServicesEnum.CaregivingViaTelemedicine: {
      return driverDistance;
    }
    case ServicesEnum.PhoneAssistance: {
      return 0;
    }
  }
}

const formatDiscountType = (
  discount: DiscountUnit | DiscountAmount | DiscountPercent,
  currency: SupportedCurrenciesEnum
) => {
  switch (discount.type) {
    case 'AMOUNT':
      return `- ${((discount?.amount_off || 0) / 100)
        .toFixed(2)
        .replace('.', ',')}${currency}`;
    case 'UNIT':
      // TODO: Handle
      return '-';
      break;
    case 'PERCENT':
      return `- ${discount.percent_off}%`;
    default:
      // TODO: Handle
      return '-';
  }
};

export {
  formatDiscountType,
  getServiceCostCalculationParams,
  calculateCostByService,
  roundToNearestHalf,
  calculateCaregivingWithUgosCar,
  calculateCaregivingWithUsersCarCost,
  calculateCaregivingWithoutCarCost,
  calculateTelemedicineCost,
  calculateErrandsCost,
  calculatePhoneAssistanceCost,
  calculateQuotePrice,
  divideHoursInHalf,
  getDriverDistance,
  calculateTimeCostWithHourlyIntervals,
  calculateTimeCostWithHalfHourIntervals,
  calculateDistanceCost,
  getMinDistancePrice,
  validateQuoteInput,
  calculateDriverAmountValues,
  calculateUgoFeeValues,
  calculateTransactionAmountValues,
  calculateExtraCostValues,
  calculateStripeFeeFromTotalAmount,
  calculateUgosProportionOfStripeFee,
  calculateDriversProportionOfStripeFee,
  calculateDriverAmount,
  calculateDriverAmountWithStripeFee,
  calculateUgoFee,
  calculateUgosFeeWithStripeFee,
  getDriverAmountStripeFeeProportion,
  calculateApplicationFee,
  calculateTransactionAmount,
  calculateDriverAmountWithExtraCost,
  calculateStripeFeeFromTransactionAmount,
  calculateStripeFeeFromExtraCost,
  addExtraCostToTransactionAmount,
  sanitizeFinalPayload,
  calculateFinalTravelDistanceAndDuration,
  getDistanceAndDurationInputBySelectedService,
  validateAddresses,
  calculateTransactionFees,
  sortObjectKeysAlphabetically,
  getTotalReservationDistance,
  quoteValidationSchema,
  UGO_FEE_PERCENTAGE,
  DRIVER_AMOUNT_PERCENTAGE,
};

export type Service =
  | 'CAREGIVING_WITH_UGOS_CAR'
  | 'CAREGIVING_WITH_USERS_CAR'
  | 'CAREGIVING_WITHOUT_CAR'
  | 'CAREGIVING_VIA_TELEMEDICINE'
  | 'ERRANDS'
  | 'PHONE_ASSISTANCE';

export interface Address {
  lat: number;
  lng: number;
}

export const CITY_RADIUS_LIMIT_IN_KMS = 15; // km
export const CITY_MIN_DISTANCE: Record<string, number> = {
  roma: 10,
};
export const CITY_MIN_DISTANCE_DEFAULT = 5;

export function getHalfHourIntervalTimeCost(
  hours: number,
  pricePerHalfHour: number
) {
  return hours * 2 * pricePerHalfHour;
}

/*
 *  Hours need to be rounded to the nearest 0,5h
 *  https://app.clickup.com/t/1uq9v7n
 *
 *  From :00 to :15 we round to ,00 before
 *  From :16 to :45 we round to ,30 before
 *  From :46 to :00 we round to ,00 after
 */
export function getHourlyIntervalTimeCost(hours: number, pricePerHour: number) {
  const _hours = hours < 1 ? 1 : Math.round(hours * 2) / 2;
  const fullHours = Math.floor(_hours);
  const fractionalHour = _hours - fullHours;

  /**
   * Create an array of hour multipliers e.g. [1, 1, 0.5]
   * e.g.
   * hours = 2.55
   * hours gets rounded to 2.5
   * multiplier array = [1, 1, 0.5]
   */
  const hourMultiplierArray = Array.from({ length: fullHours }).map(
    (_, idx) => 1
  );

  if (fractionalHour) {
    hourMultiplierArray.push(fractionalHour);
  }

  /**
   * Multiply the hourly prices with the multiplier array,
   * so we correctly calculate the fractional hour
   *
   * multiplier array = [1, 1, 0.5]
   * hourly array = [15*1, 14*1, 13*0.5]
   */
  const hourlyArray = hourMultiplierArray.map(
    (multiplier, idx) => (idx + 1 >= 8 ? 8 : pricePerHour - idx) * multiplier
  );

  // Return the reduced value
  return hourlyArray.reduce((a, b) => a + b, 0);
}

export function getDistanceCost(distance: number, minPrice = 5) {
  const PRICE_PER_KM_BELOW_200_KM = 0.5;
  /**
   * @description Sets a price to be applied above 200km
   *  - It has been a different price prior to 15. 4. 2022
   *  - We're keeping the same logic as before but apply
   *  the same price as `below 200 km`; We're keeping this if we need to set
   *  a different price above 200km in the future again
   */
  const PRICE_PER_KM_ABOVE_200_KM = 0.5;

  const numberOfAbove200Km = distance - 200 <= 0 ? 0 : distance - 200;
  const numberOfBelow200Km = numberOfAbove200Km > 0 ? 200 : distance;

  const above200KmCost = numberOfAbove200Km * PRICE_PER_KM_ABOVE_200_KM;
  const below200KmCost = numberOfBelow200Km * PRICE_PER_KM_BELOW_200_KM;

  const cost = below200KmCost + above200KmCost;

  if (cost < minPrice) {
    return minPrice;
  }

  return cost;
}

export const SERVICE = {
  CAREGIVING_WITH_UGOS_CAR: 'CAREGIVING_WITH_UGOS_CAR',
  CAREGIVING_WITH_USERS_CAR: 'CAREGIVING_WITH_USERS_CAR',
  CAREGIVING_WITHOUT_CAR: 'CAREGIVING_WITHOUT_CAR',
  CAREGIVING_VIA_TELEMEDICINE: 'CAREGIVING_VIA_TELEMEDICINE',
  ERRANDS: 'ERRANDS',
  PHONE_ASSISTANCE: 'PHONE_ASSISTANCE',
} as const;
