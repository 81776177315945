import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { B2B_PROJECT_NAMES } from '@ugo/data';
import { Box } from '@material-ui/core';

export const ManualPatientForm = (props: any) => {
  return (
    <SimpleForm {...props} submitOnEnter={false}>
      <TextInput label="Email Address" source="email" type="email" />
      <TextInput label="First name" source="first_name" type="text" />
      <TextInput label="Last name" source="last_name" type="text" />
      <TextInput label="Phone number" source="phone_number" type="text" />
      <TextInput label="Hospital" source="hospital" type="text" />
      <TextInput label="Caregiver Email" source="caregiver_email" type="text" />
      <TextInput label="Partner Email" source="partner_email" type="text" />
      <SelectInput source="project_name" choices={B2B_PROJECT_NAMES} />
      <Box flex={1} p="1rem">
        <label
          style={{
            display: 'block',
            fontSize: '12px',
            fontWeight: 600,
          }}
        >
          Project name
        </label>
        <TextField label="Project name" source="project_name" />
        <p style={{ fontSize: '12px' }}>
          project_name is archived and is being used here for backward
          compatibility. Projects are stored in the database and connected to
          its specific partner if it exists.
        </p>
      </Box>

      <ReferenceInput
        label="Partner"
        source="partner_id"
        reference="partner"
        perPage={20}
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
        fullWidth
      >
        <AutocompleteInput id="project-select" optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  );
};
