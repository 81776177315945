import React, { useEffect, useState } from 'react';
import { useGetAccountReservationsQuery } from '@ugo/graphql-operations';
import {
  ascend,
  descend,
  filter,
  length,
  map,
  pipe,
  prop,
  sortWith,
} from 'ramda';
import { isDate, isWithinInterval, subDays } from 'date-fns';
import { Box, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { Whatshot } from '@material-ui/icons';
import { TabPanel } from '../shared/TabPanel';

export const PmfLists = () => {
  const [value, setValue] = React.useState<string>(0);
  const [reservationsMadeByAccount, setReservationsMadeByAccount] = useState(
    []
  );

  const { refetch: getAccountReservations } = useGetAccountReservationsQuery({
    fetchPolicy: 'network-only',
    skip: true,
  });

  // @ts-ignore
  useEffect(async () => {
    const reservationsMadeByAccount = groupAccountsByNumberOfReservationsMade(
      (await getAccountReservations())?.data?.account
    );

    // @ts-ignore
    setReservationsMadeByAccount(reservationsMadeByAccount);
  }, []);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const groupAccountsByNumberOfReservationsMade = (
    accountReservations: any[]
  ) => {
    return pipe(
      map((a) => ({
        // @ts-ignore
        email: a.email,
        num_of_purchases_overall: pipe(
          // @ts-ignore
          length
          // @ts-ignore
        )(getPurchasedReservations(a.reservor_reservations)),
        '1x_overall':
          pipe(
            // @ts-ignore
            length
            // @ts-ignore
          )(getPurchasedReservations(a.reservor_reservations)) === 1,
        '1x_in_14d':
          pipe(
            filter((r: any) => isWithinPeriod(r.ended_at, new Date(), 14)),
            // @ts-ignore
            length
            // @ts-ignore
          )(getPurchasedReservations(a.reservor_reservations)) === 1,
        '1x_in_31d':
          pipe(
            filter((r: any) => isWithinPeriod(r.ended_at, new Date(), 31)),
            // @ts-ignore
            length
            // @ts-ignore
          )(getPurchasedReservations(a.reservor_reservations)) === 1,
        '1x_in_62d':
          pipe(
            filter((r: any) => isWithinPeriod(r.ended_at, new Date(), 62)),
            // @ts-ignore
            length
            // @ts-ignore
          )(getPurchasedReservations(a.reservor_reservations)) === 1,
        at_least_2x_overall:
          pipe(
            // @ts-ignore
            length
            // @ts-ignore
          )(getPurchasedReservations(a.reservor_reservations)) >= 2,
        // @ts-ignore
        at_least_2x_in_14d:
          pipe(
            filter((r: any) => isWithinPeriod(r.ended_at, new Date(), 14)),
            // @ts-ignore
            length
            // @ts-ignore
          )(getPurchasedReservations(a.reservor_reservations)) >= 2,
        // @ts-ignore
        at_least_2x_in_31d:
          pipe(
            filter((r: any) => isWithinPeriod(r.ended_at, new Date(), 31)),
            // @ts-ignore
            length
            // @ts-ignore
          )(getPurchasedReservations(a.reservor_reservations)) >= 2,
        // @ts-ignore
        at_least_2x_in_62d:
          pipe(
            filter((r: any) => isWithinPeriod(r.ended_at, new Date(), 62)),
            // @ts-ignore
            length
            // @ts-ignore
          )(getPurchasedReservations(a.reservor_reservations)) >= 2,
      })),
      sortWith([
        descend(prop('num_of_purchases_overall')),
        ascend(prop('email')),
      ])
    )(accountReservations);
  };

  const getPurchasedReservations = (reservations: any) =>
    reservations.filter((r: any) => !!r.ended_at);

  // TODO: Move to `useDates` hook
  const isWithinPeriod = (
    dateStart: Date,
    dateEnd: Date,
    periodInDays: number
  ) => {
    // Convert `timestamptz`s from Postgres to dates
    const _dateStart = isDate(dateStart) ? dateStart : new Date(dateStart);
    const _dateEnd = isDate(dateEnd) ? dateEnd : new Date(dateEnd);

    return isWithinInterval(_dateStart, {
      end: _dateEnd,
      start: subDays(_dateEnd, periodInDays),
    });
  };

  return (
    <Box mt={3}>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          color="white"
          textColor="primary"
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label={
              <div>
                <div>
                  <Whatshot fontSize="small" />
                  <Whatshot fontSize="small" />
                  <Whatshot fontSize="small" />
                  <Whatshot fontSize="small" />
                </div>
                <small>at least 2x in 2w</small>
              </div>
            }
          />
          <Tab
            label={
              <div>
                <div>
                  <Whatshot fontSize="small" />
                  <Whatshot fontSize="small" />
                  <Whatshot fontSize="small" />
                </div>
                <small>at least 2x in 2m</small>
              </div>
            }
          />
          <Tab
            label={
              <div>
                <div>
                  <Whatshot fontSize="small" />
                  <Whatshot fontSize="small" />
                </div>
                <small>at least 2x overall</small>
              </div>
            }
          />
          <Tab
            label={
              <div>
                <div>
                  <Whatshot fontSize="small" />
                </div>
                <small>1x overall</small>
              </div>
            }
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Paper>
                <Box p={2}>
                  Patients that made a purchase <b>at least twice</b> in the
                  last <b>14 days</b>
                </Box>
              </Paper>
              <Paper>
                <Box mt={2} p={4}>
                  {reservationsMadeByAccount
                    .filter((account: any) => account.at_least_2x_in_14d)
                    .map((account: any) => (
                      <div key={account.email}>
                        <small>{account.email}</small>
                        <br />
                      </div>
                    ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Paper>
                <Box p={2}>
                  Patients that made a purchase <b>at least twice</b> in the
                  last <b>2 months</b>
                </Box>
              </Paper>
              <Paper>
                <Box mt={2} p={4}>
                  {reservationsMadeByAccount
                    .filter((account: any) => account.at_least_2x_in_62d)
                    .map((account: any) => (
                      <div key={account.email}>
                        <small>{account.email}</small>
                        <br />
                      </div>
                    ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Paper>
                <Box p={2}>
                  Patients that made a purchase <b>at least twice</b> from{' '}
                  <b>the beginning</b>
                </Box>
              </Paper>
              <Paper>
                <Box mt={2} p={4}>
                  {reservationsMadeByAccount
                    .filter((account: any) => account.at_least_2x_overall)
                    .map((account: any) => (
                      <div key={account.email}>
                        <small>{account.email},</small>
                        <br />
                      </div>
                    ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Paper>
                <Box p={2}>
                  Patients that made a purchase at <b>only once</b> from{' '}
                  <b>the beginning</b>
                </Box>
              </Paper>
              <Paper>
                <Box mt={2} p={4}>
                  {reservationsMadeByAccount
                    .filter((account: any) => account['1x_overall'])
                    .map((account: any) => (
                      <div key={account.email}>
                        <small>{account.email},</small>
                        <br />
                      </div>
                    ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    </Box>
  );
};
