import { useState } from 'react';

import {
  calculateCaregivingWithoutCarCost,
  calculateCaregivingWithUgosCar,
  calculateCaregivingWithUsersCarCost,
  calculateCostByService,
  calculateDistanceCost,
  calculateErrandsCost,
  calculateFinalTravelDistanceAndDuration,
  calculatePhoneAssistanceCost,
  calculateTelemedicineCost,
  calculateTimeCostWithHalfHourIntervals,
  calculateTimeCostWithHourlyIntervals,
  getDistanceAndDurationInputBySelectedService,
  getMinDistancePrice,
  getServiceCostCalculationParams,
  validateAddresses,
} from './utils';

/**
 * @description A collection of quote calculations
 *
 *  - Calculates quotes based on service
 *  - Calculations are made in EUR
 *  - All of calculations assume distance measured in kms and
 *  time measured in hours
 *
 * @docs https://miro.com/app/board/o9J_laxXQew=/
 */
export const useQuoteCalculations = () => {
  const [quoteCalculationsState] = useState({
    calculateTimeCostWithHourlyIntervals,
    calculateTimeCostWithHalfHourIntervals,
    calculateDistanceCost,
    getMinDistancePrice,
    calculateCaregivingWithUgosCar,
    calculateCaregivingWithUsersCarCost,
    calculateCaregivingWithoutCarCost,
    calculateErrandsCost,
    calculateTelemedicineCost,
    calculatePhoneAssistanceCost,
    calculateCostByService,
    getServiceCostCalculationParams,
    calculateFinalTravelDistanceAndDuration,
    validateAddresses,
    getDistanceAndDurationInputBySelectedService,
  });

  return quoteCalculationsState;
};
