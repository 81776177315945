import { Datagrid, DateField, List, TextField } from 'react-admin';
import * as React from 'react';

export const AccountErrorList = (props: any) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="account_id" />
        <DateField source="created_at" showTime />
        <DateField source="resolved_at" showTime />
      </Datagrid>
    </List>
  );
};
