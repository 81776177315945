import firebase from 'firebase';
import 'firebase/auth';
import { Config } from '../config';

let firebaseApp: firebase.app.App;

export const FirebaseApp = (): firebase.app.App => {
	if (!firebaseApp) {
		firebaseApp = firebase.initializeApp(Config.firebase);
	}

	return firebaseApp;
};
