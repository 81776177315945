export const ADMIN_SUPPORTED_CITIES = [
  'ancona',
  'avellino',
  'bergamo',
  'bologna',
  'brescia',
  'firenze',
  'foggia',
  'genova',
  'lecce',
  'milano',
  'monza',
  'padova',
  'roma',
  'siena',
  'torino',
  'varese',
  'palermo',
  'catania',
  'siracusa',
  'agrigento',
  'messina',
  'olbia',
  'cosenza',
];
