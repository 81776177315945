import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


export interface AcceptManualReservationOutput {
  __typename?: 'AcceptManualReservationOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
}

export interface ActivateAdministratorOutput {
  __typename?: 'ActivateAdministratorOutput';
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
}

export interface AddPaymentMethodOutput {
  __typename?: 'AddPaymentMethodOutput';
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['jsonb']>;
}

export interface AddUserToSendinblueListOutput {
  __typename?: 'AddUserToSendinblueListOutput';
  success?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface Boolean_Comparison_Exp {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
}

export interface CalculateTransactionFeesInput {
  extra_cost?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
  total_amount_discounted?: Maybe<Scalars['Int']>;
}

export interface CalculateTransactionFeesOutput {
  __typename?: 'CalculateTransactionFeesOutput';
  result?: Maybe<Scalars['jsonb']>;
}

export interface CancelReservationOutput {
  __typename?: 'CancelReservationOutput';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
}

export interface CheckAuthStatusOutput {
  __typename?: 'CheckAuthStatusOutput';
  token: Scalars['String'];
}

export interface ClientSecretOutput {
  __typename?: 'ClientSecretOutput';
  client_secret: Scalars['String'];
}

export interface CreateAccountOutput {
  __typename?: 'CreateAccountOutput';
  account_id?: Maybe<Scalars['String']>;
}

export interface DeactivateDriverOutput {
  __typename?: 'DeactivateDriverOutput';
  success?: Maybe<Scalars['Boolean']>;
}

export interface DeclineManualReservationOutput {
  __typename?: 'DeclineManualReservationOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
}

export interface DriverAcceptReservationChangeOutput {
  __typename?: 'DriverAcceptReservationChangeOutput';
  success?: Maybe<Scalars['Boolean']>;
}

export interface DriverActivationOutput {
  __typename?: 'DriverActivationOutput';
  account_id: Scalars['String'];
  status: Scalars['String'];
  success: Scalars['Boolean'];
}

export interface EmailChangeOutput {
  __typename?: 'EmailChangeOutput';
  data?: Maybe<Scalars['jsonb']>;
}

export interface FinishReservationOutput {
  __typename?: 'FinishReservationOutput';
  success: Scalars['Boolean'];
}

export interface GetDistanceAndDurationOutput {
  __typename?: 'GetDistanceAndDurationOutput';
  a0?: Maybe<Scalars['jsonb']>;
  ab?: Maybe<Scalars['jsonb']>;
  b0?: Maybe<Scalars['jsonb']>;
  original_distance_matrix_response?: Maybe<Scalars['jsonb']>;
}

export interface GetFirebaseUserDetailsOutput {
  __typename?: 'GetFirebaseUserDetailsOutput';
  firebase_user?: Maybe<Scalars['jsonb']>;
}

export interface GetLoginTokenOutput {
  __typename?: 'GetLoginTokenOutput';
  token?: Maybe<Scalars['String']>;
}

export interface GetUserStatisticsOutput {
  __typename?: 'GetUserStatisticsOutput';
  admins?: Maybe<Scalars['Int']>;
  drivers?: Maybe<Scalars['Int']>;
  driversActivated?: Maybe<Scalars['Int']>;
  patients?: Maybe<Scalars['Int']>;
  patientsActivated?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  totalActivated?: Maybe<Scalars['Int']>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface Int_Comparison_Exp {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
}

export interface IsDriverAvailableOutput {
  __typename?: 'IsDriverAvailableOutput';
  is_driver_available: Scalars['Boolean'];
}

export interface IsDriverFullyOnboardedToStripeOutput {
  __typename?: 'IsDriverFullyOnboardedToStripeOutput';
  is_fully_onboarded: Scalars['Boolean'];
}

export interface LatLngInput {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
}

export interface OnboardDriverToStripeOutput {
  __typename?: 'OnboardDriverToStripeOutput';
  account_link: Scalars['String'];
}

export interface PasswordResetOutput {
  __typename?: 'PasswordResetOutput';
  success: Scalars['Boolean'];
}

export interface PatientCancelReservationOutput {
  __typename?: 'PatientCancelReservationOutput';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
}

export interface RemovePaymentMethodOutput {
  __typename?: 'RemovePaymentMethodOutput';
  payment_method_id?: Maybe<Scalars['String']>;
}

export interface RequestLoginTokenOutput {
  __typename?: 'RequestLoginTokenOutput';
  enum?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  token: Scalars['String'];
}

export interface ResendStripeCheckoutSessionPaymentRequestOutput {
  __typename?: 'ResendStripeCheckoutSessionPaymentRequestOutput';
  success?: Maybe<Scalars['Boolean']>;
}

export interface ResendVerificationEmailOutput {
  __typename?: 'ResendVerificationEmailOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
}

export interface SelectDefaultPaymentMethodOutput {
  __typename?: 'SelectDefaultPaymentMethodOutput';
  success: Scalars['Boolean'];
}

export interface SendNewManualReservationEmailToAllDriversOutput {
  __typename?: 'SendNewManualReservationEmailToAllDriversOutput';
  success: Scalars['Boolean'];
}

export interface SendNewManualReservationEmailToPreferredDriverOutput {
  __typename?: 'SendNewManualReservationEmailToPreferredDriverOutput';
  success: Scalars['Boolean'];
}

export interface SignInOutput {
  __typename?: 'SignInOutput';
  success?: Maybe<Scalars['Boolean']>;
}

export interface SignOutOutput {
  __typename?: 'SignOutOutput';
  success?: Maybe<Scalars['Boolean']>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_Comparison_Exp {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
}

export interface SubmitReservationRatingOutput {
  __typename?: 'SubmitReservationRatingOutput';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
}

export interface SyncFirebaseAuthenticationUsersWithDbManuallyOutput {
  __typename?: 'SyncFirebaseAuthenticationUsersWithDbManuallyOutput';
  success: Scalars['Boolean'];
}

export interface TrOutput {
  __typename?: 'TrOutput';
  success: Scalars['Boolean'];
}

export interface VerifyEmailOutput {
  __typename?: 'VerifyEmailOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
}

export interface VoucherOutput {
  __typename?: 'VoucherOutput';
  code?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['jsonb']>;
  total_amount?: Maybe<Scalars['numeric']>;
}

/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export interface Account {
  __typename?: 'account';
  /** An object relationship */
  account_feedback?: Maybe<Account_Feedback>;
  /** Tracks changes for each `account` record */
  activity_log: Scalars['jsonb'];
  /** An object relationship */
  administrator_profile?: Maybe<Administrator_Profile>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  driver_profile?: Maybe<Driver_Profile>;
  /** An array relationship */
  driver_reservations: Array<Reservation>;
  /** An aggregate relationship */
  driver_reservations_aggregate: Reservation_Aggregate;
  email: Scalars['String'];
  /** `user.uid` from Firebase Auth maps to this id. user on FBA equals to `account` */
  id: Scalars['String'];
  is_champion_patient?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** An object relationship */
  manual_driver?: Maybe<Manual_Driver>;
  /** An object relationship */
  patient_profile?: Maybe<Patient_Profile>;
  /** Various payment metadata from providers { stripe_customer_id } */
  payment_metadata?: Maybe<Scalars['jsonb']>;
  /** Payment method objects <{ id, remote_id, provider, snapshot }>[]; `snapshot` - a provider's object; `remote_id` - provider's payment method id */
  payment_methods?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate;
  permissions?: Maybe<Scalars['jsonb']>;
  /** { first_name, last_name, email, phone_number, sex, fiscal_code, birth_date, home_address, license_plate } */
  personal_data?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  reservee_reservations: Array<Reservation>;
  /** An aggregate relationship */
  reservee_reservations_aggregate: Reservation_Aggregate;
  /** An array relationship */
  reservor_reservations: Array<Reservation>;
  /** An aggregate relationship */
  reservor_reservations_aggregate: Reservation_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  voucher: Array<Voucher>;
  /** An aggregate relationship */
  voucher_aggregate: Voucher_Aggregate;
  /** ID of our user on Voucherify. Used for promotions, referrals, etc. */
  voucherify_customer_id?: Maybe<Scalars['String']>;
}


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountDriver_ReservationsArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountDriver_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountPayment_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountPayment_MethodsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountPaymentsArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountPermissionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountPersonal_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountReservee_ReservationsArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountReservee_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountReservor_ReservationsArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountReservor_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountVoucherArgs = {
  distinct_on?: Maybe<Array<Voucher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Order_By>>;
  where?: Maybe<Voucher_Bool_Exp>;
};


/** Account is a top-level entry point to the app. It's tightly connected to Firebase Auth (eg. one FBA user equals one account). Account is created via cloud function. */
export type AccountVoucher_AggregateArgs = {
  distinct_on?: Maybe<Array<Voucher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Order_By>>;
  where?: Maybe<Voucher_Bool_Exp>;
};

/** aggregated selection of "account" */
export interface Account_Aggregate {
  __typename?: 'account_aggregate';
  aggregate?: Maybe<Account_Aggregate_Fields>;
  nodes: Array<Account>;
}

/** aggregate fields of "account" */
export interface Account_Aggregate_Fields {
  __typename?: 'account_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Max_Fields>;
  min?: Maybe<Account_Min_Fields>;
}


/** aggregate fields of "account" */
export type Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Account_Append_Input {
  /** Tracks changes for each `account` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  /** Various payment metadata from providers { stripe_customer_id } */
  payment_metadata?: Maybe<Scalars['jsonb']>;
  /** Payment method objects <{ id, remote_id, provider, snapshot }>[]; `snapshot` - a provider's object; `remote_id` - provider's payment method id */
  payment_methods?: Maybe<Scalars['jsonb']>;
  permissions?: Maybe<Scalars['jsonb']>;
  /** { first_name, last_name, email, phone_number, sex, fiscal_code, birth_date, home_address, license_plate } */
  personal_data?: Maybe<Scalars['jsonb']>;
}

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export interface Account_Bool_Exp {
  _and?: Maybe<Array<Account_Bool_Exp>>;
  _not?: Maybe<Account_Bool_Exp>;
  _or?: Maybe<Array<Account_Bool_Exp>>;
  account_feedback?: Maybe<Account_Feedback_Bool_Exp>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  administrator_profile?: Maybe<Administrator_Profile_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  driver_profile?: Maybe<Driver_Profile_Bool_Exp>;
  driver_reservations?: Maybe<Reservation_Bool_Exp>;
  driver_reservations_aggregate?: Maybe<Reservation_Aggregate_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  is_champion_patient?: Maybe<Boolean_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  manual_driver?: Maybe<Manual_Driver_Bool_Exp>;
  patient_profile?: Maybe<Patient_Profile_Bool_Exp>;
  payment_metadata?: Maybe<Jsonb_Comparison_Exp>;
  payment_methods?: Maybe<Jsonb_Comparison_Exp>;
  payments?: Maybe<Payment_Bool_Exp>;
  payments_aggregate?: Maybe<Payment_Aggregate_Bool_Exp>;
  permissions?: Maybe<Jsonb_Comparison_Exp>;
  personal_data?: Maybe<Jsonb_Comparison_Exp>;
  reservee_reservations?: Maybe<Reservation_Bool_Exp>;
  reservee_reservations_aggregate?: Maybe<Reservation_Aggregate_Bool_Exp>;
  reservor_reservations?: Maybe<Reservation_Bool_Exp>;
  reservor_reservations_aggregate?: Maybe<Reservation_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  voucher?: Maybe<Voucher_Bool_Exp>;
  voucher_aggregate?: Maybe<Voucher_Aggregate_Bool_Exp>;
  voucherify_customer_id?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "account" */
export enum Account_Constraint {
  /** unique or primary key constraint on columns "email" */
  AccountEmailKey = 'account_email_key',
  /** unique or primary key constraint on columns "id" */
  AccountPkey = 'account_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Account_Delete_At_Path_Input {
  /** Tracks changes for each `account` record */
  activity_log?: Maybe<Array<Scalars['String']>>;
  /** Various payment metadata from providers { stripe_customer_id } */
  payment_metadata?: Maybe<Array<Scalars['String']>>;
  /** Payment method objects <{ id, remote_id, provider, snapshot }>[]; `snapshot` - a provider's object; `remote_id` - provider's payment method id */
  payment_methods?: Maybe<Array<Scalars['String']>>;
  permissions?: Maybe<Array<Scalars['String']>>;
  /** { first_name, last_name, email, phone_number, sex, fiscal_code, birth_date, home_address, license_plate } */
  personal_data?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Account_Delete_Elem_Input {
  /** Tracks changes for each `account` record */
  activity_log?: Maybe<Scalars['Int']>;
  /** Various payment metadata from providers { stripe_customer_id } */
  payment_metadata?: Maybe<Scalars['Int']>;
  /** Payment method objects <{ id, remote_id, provider, snapshot }>[]; `snapshot` - a provider's object; `remote_id` - provider's payment method id */
  payment_methods?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Scalars['Int']>;
  /** { first_name, last_name, email, phone_number, sex, fiscal_code, birth_date, home_address, license_plate } */
  personal_data?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Account_Delete_Key_Input {
  /** Tracks changes for each `account` record */
  activity_log?: Maybe<Scalars['String']>;
  /** Various payment metadata from providers { stripe_customer_id } */
  payment_metadata?: Maybe<Scalars['String']>;
  /** Payment method objects <{ id, remote_id, provider, snapshot }>[]; `snapshot` - a provider's object; `remote_id` - provider's payment method id */
  payment_methods?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['String']>;
  /** { first_name, last_name, email, phone_number, sex, fiscal_code, birth_date, home_address, license_plate } */
  personal_data?: Maybe<Scalars['String']>;
}

/** columns and relationships of "account_error" */
export interface Account_Error {
  __typename?: 'account_error';
  account_id: Scalars['String'];
  created_at: Scalars['timestamp'];
  errors: Scalars['jsonb'];
  id: Scalars['uuid'];
  resolved: Scalars['Boolean'];
  resolved_at?: Maybe<Scalars['timestamptz']>;
  type: Account_Error_Type_Enum;
}


/** columns and relationships of "account_error" */
export type Account_ErrorErrorsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "account_error" */
export interface Account_Error_Aggregate {
  __typename?: 'account_error_aggregate';
  aggregate?: Maybe<Account_Error_Aggregate_Fields>;
  nodes: Array<Account_Error>;
}

/** aggregate fields of "account_error" */
export interface Account_Error_Aggregate_Fields {
  __typename?: 'account_error_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Error_Max_Fields>;
  min?: Maybe<Account_Error_Min_Fields>;
}


/** aggregate fields of "account_error" */
export type Account_Error_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Error_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Account_Error_Append_Input {
  errors?: Maybe<Scalars['jsonb']>;
}

/** Boolean expression to filter rows from the table "account_error". All fields are combined with a logical 'AND'. */
export interface Account_Error_Bool_Exp {
  _and?: Maybe<Array<Account_Error_Bool_Exp>>;
  _not?: Maybe<Account_Error_Bool_Exp>;
  _or?: Maybe<Array<Account_Error_Bool_Exp>>;
  account_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  errors?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  resolved?: Maybe<Boolean_Comparison_Exp>;
  resolved_at?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<Account_Error_Type_Enum_Comparison_Exp>;
}

/** unique or primary key constraints on table "account_error" */
export enum Account_Error_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountErrorPkey = 'account_error_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Account_Error_Delete_At_Path_Input {
  errors?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Account_Error_Delete_Elem_Input {
  errors?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Account_Error_Delete_Key_Input {
  errors?: Maybe<Scalars['String']>;
}

/** input type for inserting data into table "account_error" */
export interface Account_Error_Insert_Input {
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  errors?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  resolved?: Maybe<Scalars['Boolean']>;
  resolved_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Account_Error_Type_Enum>;
}

/** aggregate max on columns */
export interface Account_Error_Max_Fields {
  __typename?: 'account_error_max_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  resolved_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Account_Error_Min_Fields {
  __typename?: 'account_error_min_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  resolved_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "account_error" */
export interface Account_Error_Mutation_Response {
  __typename?: 'account_error_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Error>;
}

/** on_conflict condition type for table "account_error" */
export interface Account_Error_On_Conflict {
  constraint: Account_Error_Constraint;
  update_columns?: Array<Account_Error_Update_Column>;
  where?: Maybe<Account_Error_Bool_Exp>;
}

/** Ordering options when selecting data from "account_error". */
export interface Account_Error_Order_By {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  errors?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resolved?: Maybe<Order_By>;
  resolved_at?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
}

/** primary key columns input for table: account_error */
export interface Account_Error_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Account_Error_Prepend_Input {
  errors?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "account_error" */
export enum Account_Error_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id',
  /** column name */
  Resolved = 'resolved',
  /** column name */
  ResolvedAt = 'resolved_at',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "account_error" */
export interface Account_Error_Set_Input {
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  errors?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  resolved?: Maybe<Scalars['Boolean']>;
  resolved_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Account_Error_Type_Enum>;
}

/** Streaming cursor of the table "account_error" */
export interface Account_Error_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Account_Error_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Account_Error_Stream_Cursor_Value_Input {
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  errors?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  resolved?: Maybe<Scalars['Boolean']>;
  resolved_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Account_Error_Type_Enum>;
}

/** columns and relationships of "account_error_type" */
export interface Account_Error_Type {
  __typename?: 'account_error_type';
  value: Scalars['String'];
}

/** aggregated selection of "account_error_type" */
export interface Account_Error_Type_Aggregate {
  __typename?: 'account_error_type_aggregate';
  aggregate?: Maybe<Account_Error_Type_Aggregate_Fields>;
  nodes: Array<Account_Error_Type>;
}

/** aggregate fields of "account_error_type" */
export interface Account_Error_Type_Aggregate_Fields {
  __typename?: 'account_error_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Error_Type_Max_Fields>;
  min?: Maybe<Account_Error_Type_Min_Fields>;
}


/** aggregate fields of "account_error_type" */
export type Account_Error_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Error_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account_error_type". All fields are combined with a logical 'AND'. */
export interface Account_Error_Type_Bool_Exp {
  _and?: Maybe<Array<Account_Error_Type_Bool_Exp>>;
  _not?: Maybe<Account_Error_Type_Bool_Exp>;
  _or?: Maybe<Array<Account_Error_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "account_error_type" */
export enum Account_Error_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccountErrorTypePkey = 'account_error_type_pkey'
}

export enum Account_Error_Type_Enum {
  ChangeEmail = 'CHANGE_EMAIL',
  DeactivateDriver = 'DEACTIVATE_DRIVER'
}

/** Boolean expression to compare columns of type "account_error_type_enum". All fields are combined with logical 'AND'. */
export interface Account_Error_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Account_Error_Type_Enum>;
  _in?: Maybe<Array<Account_Error_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Account_Error_Type_Enum>;
  _nin?: Maybe<Array<Account_Error_Type_Enum>>;
}

/** input type for inserting data into table "account_error_type" */
export interface Account_Error_Type_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Account_Error_Type_Max_Fields {
  __typename?: 'account_error_type_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Account_Error_Type_Min_Fields {
  __typename?: 'account_error_type_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "account_error_type" */
export interface Account_Error_Type_Mutation_Response {
  __typename?: 'account_error_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Error_Type>;
}

/** on_conflict condition type for table "account_error_type" */
export interface Account_Error_Type_On_Conflict {
  constraint: Account_Error_Type_Constraint;
  update_columns?: Array<Account_Error_Type_Update_Column>;
  where?: Maybe<Account_Error_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "account_error_type". */
export interface Account_Error_Type_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: account_error_type */
export interface Account_Error_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "account_error_type" */
export enum Account_Error_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "account_error_type" */
export interface Account_Error_Type_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "account_error_type" */
export interface Account_Error_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Account_Error_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Account_Error_Type_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "account_error_type" */
export enum Account_Error_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Account_Error_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Account_Error_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Error_Type_Bool_Exp;
}

/** update columns of table "account_error" */
export enum Account_Error_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id',
  /** column name */
  Resolved = 'resolved',
  /** column name */
  ResolvedAt = 'resolved_at',
  /** column name */
  Type = 'type'
}

export interface Account_Error_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Account_Error_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Account_Error_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Account_Error_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Account_Error_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Account_Error_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Account_Error_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Error_Bool_Exp;
}

/** A user feedback table, used for storing customer discovery information */
export interface Account_Feedback {
  __typename?: 'account_feedback';
  /** An object relationship */
  account: Account;
  account_id: Scalars['String'];
  /** Firebase Authentication user object. One Firebase Authentication user maps to one `account` - `account.id` field equals Firebase Authentication's `uid` */
  firebase_authentication_user?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  /** Different and detailed traffic sources that we cannot capture with our data layer */
  traffic_source?: Maybe<Scalars['String']>;
  /** Additional information about the affiliate traffic source */
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  /** The creation timestamp that can be used for analytics */
  traffic_source_created_at: Scalars['timestamptz'];
  /** Information about traffic source that we did not anticipate */
  traffic_source_other_text?: Maybe<Scalars['String']>;
}


/** A user feedback table, used for storing customer discovery information */
export type Account_FeedbackFirebase_Authentication_UserArgs = {
  path?: Maybe<Scalars['String']>;
};


/** A user feedback table, used for storing customer discovery information */
export type Account_FeedbackMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "account_feedback" */
export interface Account_Feedback_Aggregate {
  __typename?: 'account_feedback_aggregate';
  aggregate?: Maybe<Account_Feedback_Aggregate_Fields>;
  nodes: Array<Account_Feedback>;
}

/** aggregate fields of "account_feedback" */
export interface Account_Feedback_Aggregate_Fields {
  __typename?: 'account_feedback_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Feedback_Max_Fields>;
  min?: Maybe<Account_Feedback_Min_Fields>;
}


/** aggregate fields of "account_feedback" */
export type Account_Feedback_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Feedback_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Account_Feedback_Append_Input {
  /** Firebase Authentication user object. One Firebase Authentication user maps to one `account` - `account.id` field equals Firebase Authentication's `uid` */
  firebase_authentication_user?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
}

/** Boolean expression to filter rows from the table "account_feedback". All fields are combined with a logical 'AND'. */
export interface Account_Feedback_Bool_Exp {
  _and?: Maybe<Array<Account_Feedback_Bool_Exp>>;
  _not?: Maybe<Account_Feedback_Bool_Exp>;
  _or?: Maybe<Array<Account_Feedback_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<String_Comparison_Exp>;
  firebase_authentication_user?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  traffic_source?: Maybe<String_Comparison_Exp>;
  traffic_source_affiliate_text?: Maybe<String_Comparison_Exp>;
  traffic_source_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  traffic_source_other_text?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "account_feedback" */
export enum Account_Feedback_Constraint {
  /** unique or primary key constraint on columns "account_id" */
  AccountFeedbackAccountIdKey = 'account_feedback_account_id_key',
  /** unique or primary key constraint on columns "id" */
  AccountFeedbackPkey = 'account_feedback_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Account_Feedback_Delete_At_Path_Input {
  /** Firebase Authentication user object. One Firebase Authentication user maps to one `account` - `account.id` field equals Firebase Authentication's `uid` */
  firebase_authentication_user?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Account_Feedback_Delete_Elem_Input {
  /** Firebase Authentication user object. One Firebase Authentication user maps to one `account` - `account.id` field equals Firebase Authentication's `uid` */
  firebase_authentication_user?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Account_Feedback_Delete_Key_Input {
  /** Firebase Authentication user object. One Firebase Authentication user maps to one `account` - `account.id` field equals Firebase Authentication's `uid` */
  firebase_authentication_user?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
}

/** input type for inserting data into table "account_feedback" */
export interface Account_Feedback_Insert_Input {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['String']>;
  /** Firebase Authentication user object. One Firebase Authentication user maps to one `account` - `account.id` field equals Firebase Authentication's `uid` */
  firebase_authentication_user?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** Different and detailed traffic sources that we cannot capture with our data layer */
  traffic_source?: Maybe<Scalars['String']>;
  /** Additional information about the affiliate traffic source */
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  /** The creation timestamp that can be used for analytics */
  traffic_source_created_at?: Maybe<Scalars['timestamptz']>;
  /** Information about traffic source that we did not anticipate */
  traffic_source_other_text?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Account_Feedback_Max_Fields {
  __typename?: 'account_feedback_max_fields';
  account_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** Different and detailed traffic sources that we cannot capture with our data layer */
  traffic_source?: Maybe<Scalars['String']>;
  /** Additional information about the affiliate traffic source */
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  /** The creation timestamp that can be used for analytics */
  traffic_source_created_at?: Maybe<Scalars['timestamptz']>;
  /** Information about traffic source that we did not anticipate */
  traffic_source_other_text?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Account_Feedback_Min_Fields {
  __typename?: 'account_feedback_min_fields';
  account_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** Different and detailed traffic sources that we cannot capture with our data layer */
  traffic_source?: Maybe<Scalars['String']>;
  /** Additional information about the affiliate traffic source */
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  /** The creation timestamp that can be used for analytics */
  traffic_source_created_at?: Maybe<Scalars['timestamptz']>;
  /** Information about traffic source that we did not anticipate */
  traffic_source_other_text?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "account_feedback" */
export interface Account_Feedback_Mutation_Response {
  __typename?: 'account_feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Feedback>;
}

/** input type for inserting object relation for remote table "account_feedback" */
export interface Account_Feedback_Obj_Rel_Insert_Input {
  data: Account_Feedback_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Account_Feedback_On_Conflict>;
}

/** on_conflict condition type for table "account_feedback" */
export interface Account_Feedback_On_Conflict {
  constraint: Account_Feedback_Constraint;
  update_columns?: Array<Account_Feedback_Update_Column>;
  where?: Maybe<Account_Feedback_Bool_Exp>;
}

/** Ordering options when selecting data from "account_feedback". */
export interface Account_Feedback_Order_By {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  firebase_authentication_user?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  traffic_source?: Maybe<Order_By>;
  traffic_source_affiliate_text?: Maybe<Order_By>;
  traffic_source_created_at?: Maybe<Order_By>;
  traffic_source_other_text?: Maybe<Order_By>;
}

/** primary key columns input for table: account_feedback */
export interface Account_Feedback_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Account_Feedback_Prepend_Input {
  /** Firebase Authentication user object. One Firebase Authentication user maps to one `account` - `account.id` field equals Firebase Authentication's `uid` */
  firebase_authentication_user?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "account_feedback" */
export enum Account_Feedback_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  FirebaseAuthenticationUser = 'firebase_authentication_user',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  TrafficSource = 'traffic_source',
  /** column name */
  TrafficSourceAffiliateText = 'traffic_source_affiliate_text',
  /** column name */
  TrafficSourceCreatedAt = 'traffic_source_created_at',
  /** column name */
  TrafficSourceOtherText = 'traffic_source_other_text'
}

/** input type for updating data in table "account_feedback" */
export interface Account_Feedback_Set_Input {
  account_id?: Maybe<Scalars['String']>;
  /** Firebase Authentication user object. One Firebase Authentication user maps to one `account` - `account.id` field equals Firebase Authentication's `uid` */
  firebase_authentication_user?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** Different and detailed traffic sources that we cannot capture with our data layer */
  traffic_source?: Maybe<Scalars['String']>;
  /** Additional information about the affiliate traffic source */
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  /** The creation timestamp that can be used for analytics */
  traffic_source_created_at?: Maybe<Scalars['timestamptz']>;
  /** Information about traffic source that we did not anticipate */
  traffic_source_other_text?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "account_feedback" */
export interface Account_Feedback_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Account_Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Account_Feedback_Stream_Cursor_Value_Input {
  account_id?: Maybe<Scalars['String']>;
  /** Firebase Authentication user object. One Firebase Authentication user maps to one `account` - `account.id` field equals Firebase Authentication's `uid` */
  firebase_authentication_user?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** Different and detailed traffic sources that we cannot capture with our data layer */
  traffic_source?: Maybe<Scalars['String']>;
  /** Additional information about the affiliate traffic source */
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  /** The creation timestamp that can be used for analytics */
  traffic_source_created_at?: Maybe<Scalars['timestamptz']>;
  /** Information about traffic source that we did not anticipate */
  traffic_source_other_text?: Maybe<Scalars['String']>;
}

/** update columns of table "account_feedback" */
export enum Account_Feedback_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  FirebaseAuthenticationUser = 'firebase_authentication_user',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  TrafficSource = 'traffic_source',
  /** column name */
  TrafficSourceAffiliateText = 'traffic_source_affiliate_text',
  /** column name */
  TrafficSourceCreatedAt = 'traffic_source_created_at',
  /** column name */
  TrafficSourceOtherText = 'traffic_source_other_text'
}

export interface Account_Feedback_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Account_Feedback_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Account_Feedback_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Account_Feedback_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Account_Feedback_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Account_Feedback_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Account_Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Feedback_Bool_Exp;
}

/** input type for inserting data into table "account" */
export interface Account_Insert_Input {
  account_feedback?: Maybe<Account_Feedback_Obj_Rel_Insert_Input>;
  /** Tracks changes for each `account` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  administrator_profile?: Maybe<Administrator_Profile_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  driver_profile?: Maybe<Driver_Profile_Obj_Rel_Insert_Input>;
  driver_reservations?: Maybe<Reservation_Arr_Rel_Insert_Input>;
  email?: Maybe<Scalars['String']>;
  /** `user.uid` from Firebase Auth maps to this id. user on FBA equals to `account` */
  id?: Maybe<Scalars['String']>;
  is_champion_patient?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  manual_driver?: Maybe<Manual_Driver_Obj_Rel_Insert_Input>;
  patient_profile?: Maybe<Patient_Profile_Obj_Rel_Insert_Input>;
  /** Various payment metadata from providers { stripe_customer_id } */
  payment_metadata?: Maybe<Scalars['jsonb']>;
  /** Payment method objects <{ id, remote_id, provider, snapshot }>[]; `snapshot` - a provider's object; `remote_id` - provider's payment method id */
  payment_methods?: Maybe<Scalars['jsonb']>;
  payments?: Maybe<Payment_Arr_Rel_Insert_Input>;
  permissions?: Maybe<Scalars['jsonb']>;
  /** { first_name, last_name, email, phone_number, sex, fiscal_code, birth_date, home_address, license_plate } */
  personal_data?: Maybe<Scalars['jsonb']>;
  reservee_reservations?: Maybe<Reservation_Arr_Rel_Insert_Input>;
  reservor_reservations?: Maybe<Reservation_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher?: Maybe<Voucher_Arr_Rel_Insert_Input>;
  /** ID of our user on Voucherify. Used for promotions, referrals, etc. */
  voucherify_customer_id?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Account_Max_Fields {
  __typename?: 'account_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** `user.uid` from Firebase Auth maps to this id. user on FBA equals to `account` */
  id?: Maybe<Scalars['String']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID of our user on Voucherify. Used for promotions, referrals, etc. */
  voucherify_customer_id?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Account_Min_Fields {
  __typename?: 'account_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** `user.uid` from Firebase Auth maps to this id. user on FBA equals to `account` */
  id?: Maybe<Scalars['String']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID of our user on Voucherify. Used for promotions, referrals, etc. */
  voucherify_customer_id?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "account" */
export interface Account_Mutation_Response {
  __typename?: 'account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account>;
}

/** input type for inserting object relation for remote table "account" */
export interface Account_Obj_Rel_Insert_Input {
  data: Account_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Account_On_Conflict>;
}

/** on_conflict condition type for table "account" */
export interface Account_On_Conflict {
  constraint: Account_Constraint;
  update_columns?: Array<Account_Update_Column>;
  where?: Maybe<Account_Bool_Exp>;
}

/** Ordering options when selecting data from "account". */
export interface Account_Order_By {
  account_feedback?: Maybe<Account_Feedback_Order_By>;
  activity_log?: Maybe<Order_By>;
  administrator_profile?: Maybe<Administrator_Profile_Order_By>;
  created_at?: Maybe<Order_By>;
  driver_profile?: Maybe<Driver_Profile_Order_By>;
  driver_reservations_aggregate?: Maybe<Reservation_Aggregate_Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_champion_patient?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  manual_driver?: Maybe<Manual_Driver_Order_By>;
  patient_profile?: Maybe<Patient_Profile_Order_By>;
  payment_metadata?: Maybe<Order_By>;
  payment_methods?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  permissions?: Maybe<Order_By>;
  personal_data?: Maybe<Order_By>;
  reservee_reservations_aggregate?: Maybe<Reservation_Aggregate_Order_By>;
  reservor_reservations_aggregate?: Maybe<Reservation_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  voucher_aggregate?: Maybe<Voucher_Aggregate_Order_By>;
  voucherify_customer_id?: Maybe<Order_By>;
}

/** primary key columns input for table: account */
export interface Account_Pk_Columns_Input {
  /** `user.uid` from Firebase Auth maps to this id. user on FBA equals to `account` */
  id: Scalars['String'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Account_Prepend_Input {
  /** Tracks changes for each `account` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  /** Various payment metadata from providers { stripe_customer_id } */
  payment_metadata?: Maybe<Scalars['jsonb']>;
  /** Payment method objects <{ id, remote_id, provider, snapshot }>[]; `snapshot` - a provider's object; `remote_id` - provider's payment method id */
  payment_methods?: Maybe<Scalars['jsonb']>;
  permissions?: Maybe<Scalars['jsonb']>;
  /** { first_name, last_name, email, phone_number, sex, fiscal_code, birth_date, home_address, license_plate } */
  personal_data?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "account" */
export enum Account_Select_Column {
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsChampionPatient = 'is_champion_patient',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  PaymentMetadata = 'payment_metadata',
  /** column name */
  PaymentMethods = 'payment_methods',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  PersonalData = 'personal_data',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherifyCustomerId = 'voucherify_customer_id'
}

/** input type for updating data in table "account" */
export interface Account_Set_Input {
  /** Tracks changes for each `account` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** `user.uid` from Firebase Auth maps to this id. user on FBA equals to `account` */
  id?: Maybe<Scalars['String']>;
  is_champion_patient?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** Various payment metadata from providers { stripe_customer_id } */
  payment_metadata?: Maybe<Scalars['jsonb']>;
  /** Payment method objects <{ id, remote_id, provider, snapshot }>[]; `snapshot` - a provider's object; `remote_id` - provider's payment method id */
  payment_methods?: Maybe<Scalars['jsonb']>;
  permissions?: Maybe<Scalars['jsonb']>;
  /** { first_name, last_name, email, phone_number, sex, fiscal_code, birth_date, home_address, license_plate } */
  personal_data?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID of our user on Voucherify. Used for promotions, referrals, etc. */
  voucherify_customer_id?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "account" */
export interface Account_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Account_Stream_Cursor_Value_Input {
  /** Tracks changes for each `account` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** `user.uid` from Firebase Auth maps to this id. user on FBA equals to `account` */
  id?: Maybe<Scalars['String']>;
  is_champion_patient?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** Various payment metadata from providers { stripe_customer_id } */
  payment_metadata?: Maybe<Scalars['jsonb']>;
  /** Payment method objects <{ id, remote_id, provider, snapshot }>[]; `snapshot` - a provider's object; `remote_id` - provider's payment method id */
  payment_methods?: Maybe<Scalars['jsonb']>;
  permissions?: Maybe<Scalars['jsonb']>;
  /** { first_name, last_name, email, phone_number, sex, fiscal_code, birth_date, home_address, license_plate } */
  personal_data?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID of our user on Voucherify. Used for promotions, referrals, etc. */
  voucherify_customer_id?: Maybe<Scalars['String']>;
}

/** update columns of table "account" */
export enum Account_Update_Column {
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsChampionPatient = 'is_champion_patient',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  PaymentMetadata = 'payment_metadata',
  /** column name */
  PaymentMethods = 'payment_methods',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  PersonalData = 'personal_data',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherifyCustomerId = 'voucherify_customer_id'
}

export interface Account_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Account_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Account_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Account_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Account_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Account_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Bool_Exp;
}

/** This is administrator related data */
export interface Administrator_Profile {
  __typename?: 'administrator_profile';
  /** An object relationship */
  account: Account;
  account_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
}

/** aggregated selection of "administrator_profile" */
export interface Administrator_Profile_Aggregate {
  __typename?: 'administrator_profile_aggregate';
  aggregate?: Maybe<Administrator_Profile_Aggregate_Fields>;
  nodes: Array<Administrator_Profile>;
}

/** aggregate fields of "administrator_profile" */
export interface Administrator_Profile_Aggregate_Fields {
  __typename?: 'administrator_profile_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Administrator_Profile_Max_Fields>;
  min?: Maybe<Administrator_Profile_Min_Fields>;
}


/** aggregate fields of "administrator_profile" */
export type Administrator_Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Administrator_Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "administrator_profile". All fields are combined with a logical 'AND'. */
export interface Administrator_Profile_Bool_Exp {
  _and?: Maybe<Array<Administrator_Profile_Bool_Exp>>;
  _not?: Maybe<Administrator_Profile_Bool_Exp>;
  _or?: Maybe<Array<Administrator_Profile_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "administrator_profile" */
export enum Administrator_Profile_Constraint {
  /** unique or primary key constraint on columns "id" */
  AdministratorPkey = 'administrator_pkey',
  /** unique or primary key constraint on columns "account_id" */
  AdministratorProfileAccountIdKey = 'administrator_profile_account_id_key'
}

/** input type for inserting data into table "administrator_profile" */
export interface Administrator_Profile_Insert_Input {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Administrator_Profile_Max_Fields {
  __typename?: 'administrator_profile_max_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Administrator_Profile_Min_Fields {
  __typename?: 'administrator_profile_min_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "administrator_profile" */
export interface Administrator_Profile_Mutation_Response {
  __typename?: 'administrator_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Administrator_Profile>;
}

/** input type for inserting object relation for remote table "administrator_profile" */
export interface Administrator_Profile_Obj_Rel_Insert_Input {
  data: Administrator_Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Administrator_Profile_On_Conflict>;
}

/** on_conflict condition type for table "administrator_profile" */
export interface Administrator_Profile_On_Conflict {
  constraint: Administrator_Profile_Constraint;
  update_columns?: Array<Administrator_Profile_Update_Column>;
  where?: Maybe<Administrator_Profile_Bool_Exp>;
}

/** Ordering options when selecting data from "administrator_profile". */
export interface Administrator_Profile_Order_By {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** primary key columns input for table: administrator_profile */
export interface Administrator_Profile_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "administrator_profile" */
export enum Administrator_Profile_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "administrator_profile" */
export interface Administrator_Profile_Set_Input {
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** Streaming cursor of the table "administrator_profile" */
export interface Administrator_Profile_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Administrator_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Administrator_Profile_Stream_Cursor_Value_Input {
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** update columns of table "administrator_profile" */
export enum Administrator_Profile_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Administrator_Profile_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Administrator_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Administrator_Profile_Bool_Exp;
}


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface Bigint_Comparison_Exp {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
}

/** columns and relationships of "carvoila.district" */
export interface Carvoila_District {
  __typename?: 'carvoila_district';
  id: Scalars['Int'];
  name: Scalars['String'];
}

/** aggregated selection of "carvoila.district" */
export interface Carvoila_District_Aggregate {
  __typename?: 'carvoila_district_aggregate';
  aggregate?: Maybe<Carvoila_District_Aggregate_Fields>;
  nodes: Array<Carvoila_District>;
}

/** aggregate fields of "carvoila.district" */
export interface Carvoila_District_Aggregate_Fields {
  __typename?: 'carvoila_district_aggregate_fields';
  avg?: Maybe<Carvoila_District_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Carvoila_District_Max_Fields>;
  min?: Maybe<Carvoila_District_Min_Fields>;
  stddev?: Maybe<Carvoila_District_Stddev_Fields>;
  stddev_pop?: Maybe<Carvoila_District_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Carvoila_District_Stddev_Samp_Fields>;
  sum?: Maybe<Carvoila_District_Sum_Fields>;
  var_pop?: Maybe<Carvoila_District_Var_Pop_Fields>;
  var_samp?: Maybe<Carvoila_District_Var_Samp_Fields>;
  variance?: Maybe<Carvoila_District_Variance_Fields>;
}


/** aggregate fields of "carvoila.district" */
export type Carvoila_District_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Carvoila_District_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Carvoila_District_Avg_Fields {
  __typename?: 'carvoila_district_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "carvoila.district". All fields are combined with a logical 'AND'. */
export interface Carvoila_District_Bool_Exp {
  _and?: Maybe<Array<Carvoila_District_Bool_Exp>>;
  _not?: Maybe<Carvoila_District_Bool_Exp>;
  _or?: Maybe<Array<Carvoila_District_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "carvoila.district" */
export enum Carvoila_District_Constraint {
  /** unique or primary key constraint on columns "id" */
  DistrictPkey = 'district_pkey'
}

/** input type for incrementing numeric columns in table "carvoila.district" */
export interface Carvoila_District_Inc_Input {
  id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "carvoila.district" */
export interface Carvoila_District_Insert_Input {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Carvoila_District_Max_Fields {
  __typename?: 'carvoila_district_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Carvoila_District_Min_Fields {
  __typename?: 'carvoila_district_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "carvoila.district" */
export interface Carvoila_District_Mutation_Response {
  __typename?: 'carvoila_district_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Carvoila_District>;
}

/** on_conflict condition type for table "carvoila.district" */
export interface Carvoila_District_On_Conflict {
  constraint: Carvoila_District_Constraint;
  update_columns?: Array<Carvoila_District_Update_Column>;
  where?: Maybe<Carvoila_District_Bool_Exp>;
}

/** Ordering options when selecting data from "carvoila.district". */
export interface Carvoila_District_Order_By {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
}

/** primary key columns input for table: carvoila.district */
export interface Carvoila_District_Pk_Columns_Input {
  id: Scalars['Int'];
}

/** select columns of table "carvoila.district" */
export enum Carvoila_District_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "carvoila.district" */
export interface Carvoila_District_Set_Input {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface Carvoila_District_Stddev_Fields {
  __typename?: 'carvoila_district_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Carvoila_District_Stddev_Pop_Fields {
  __typename?: 'carvoila_district_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Carvoila_District_Stddev_Samp_Fields {
  __typename?: 'carvoila_district_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "carvoila_district" */
export interface Carvoila_District_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Carvoila_District_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Carvoila_District_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Carvoila_District_Sum_Fields {
  __typename?: 'carvoila_district_sum_fields';
  id?: Maybe<Scalars['Int']>;
}

/** update columns of table "carvoila.district" */
export enum Carvoila_District_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export interface Carvoila_District_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Carvoila_District_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Carvoila_District_Set_Input>;
  /** filter the rows which have to be updated */
  where: Carvoila_District_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Carvoila_District_Var_Pop_Fields {
  __typename?: 'carvoila_district_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Carvoila_District_Var_Samp_Fields {
  __typename?: 'carvoila_district_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Carvoila_District_Variance_Fields {
  __typename?: 'carvoila_district_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "carvoila.driver" */
export interface Carvoila_Driver {
  __typename?: 'carvoila_driver';
  account_holder_name?: Maybe<Scalars['String']>;
  activity_log: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  dev4side_id?: Maybe<Scalars['Int']>;
  districts: Scalars['jsonb'];
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  /** An object relationship */
  driver_status?: Maybe<Carvoila_Driver_Status>;
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  gforms_academy_completed_at?: Maybe<Scalars['timestamptz']>;
  gforms_academy_payload?: Maybe<Scalars['jsonb']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_gforms_academy_completed: Scalars['Boolean'];
  /** Flag if the user was imported from the old Google Sheet */
  is_imported: Scalars['Boolean'];
  is_registered_on_dev4side?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved: Scalars['Boolean'];
  is_typeform_documents_submitted: Scalars['Boolean'];
  is_typeform_general_info_submitted: Scalars['Boolean'];
  is_video_verification_approved: Scalars['Boolean'];
  is_video_verification_submitted: Scalars['Boolean'];
  last_name?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  status?: Maybe<Carvoila_Driver_Status_Enum>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  typeform_general_info_payload?: Maybe<Scalars['jsonb']>;
  typeform_general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  vat_number?: Maybe<Scalars['String']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
}


/** columns and relationships of "carvoila.driver" */
export type Carvoila_DriverActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "carvoila.driver" */
export type Carvoila_DriverDistrictsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "carvoila.driver" */
export type Carvoila_DriverGforms_Academy_PayloadArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "carvoila.driver" */
export type Carvoila_DriverTypeform_Documents_PayloadArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "carvoila.driver" */
export type Carvoila_DriverTypeform_General_Info_PayloadArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "carvoila.driver" */
export interface Carvoila_Driver_Aggregate {
  __typename?: 'carvoila_driver_aggregate';
  aggregate?: Maybe<Carvoila_Driver_Aggregate_Fields>;
  nodes: Array<Carvoila_Driver>;
}

/** aggregate fields of "carvoila.driver" */
export interface Carvoila_Driver_Aggregate_Fields {
  __typename?: 'carvoila_driver_aggregate_fields';
  avg?: Maybe<Carvoila_Driver_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Carvoila_Driver_Max_Fields>;
  min?: Maybe<Carvoila_Driver_Min_Fields>;
  stddev?: Maybe<Carvoila_Driver_Stddev_Fields>;
  stddev_pop?: Maybe<Carvoila_Driver_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Carvoila_Driver_Stddev_Samp_Fields>;
  sum?: Maybe<Carvoila_Driver_Sum_Fields>;
  var_pop?: Maybe<Carvoila_Driver_Var_Pop_Fields>;
  var_samp?: Maybe<Carvoila_Driver_Var_Samp_Fields>;
  variance?: Maybe<Carvoila_Driver_Variance_Fields>;
}


/** aggregate fields of "carvoila.driver" */
export type Carvoila_Driver_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Carvoila_Driver_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Carvoila_Driver_Append_Input {
  activity_log?: Maybe<Scalars['jsonb']>;
  districts?: Maybe<Scalars['jsonb']>;
  gforms_academy_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_general_info_payload?: Maybe<Scalars['jsonb']>;
}

/** aggregate avg on columns */
export interface Carvoila_Driver_Avg_Fields {
  __typename?: 'carvoila_driver_avg_fields';
  dev4side_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "carvoila.driver". All fields are combined with a logical 'AND'. */
export interface Carvoila_Driver_Bool_Exp {
  _and?: Maybe<Array<Carvoila_Driver_Bool_Exp>>;
  _not?: Maybe<Carvoila_Driver_Bool_Exp>;
  _or?: Maybe<Array<Carvoila_Driver_Bool_Exp>>;
  account_holder_name?: Maybe<String_Comparison_Exp>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dev4side_id?: Maybe<Int_Comparison_Exp>;
  districts?: Maybe<Jsonb_Comparison_Exp>;
  dof_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  dof_rejected_at?: Maybe<Timestamptz_Comparison_Exp>;
  dof_rejected_reason?: Maybe<String_Comparison_Exp>;
  driver_status?: Maybe<Carvoila_Driver_Status_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  fiscal_code?: Maybe<String_Comparison_Exp>;
  gforms_academy_completed_at?: Maybe<Timestamptz_Comparison_Exp>;
  gforms_academy_payload?: Maybe<Jsonb_Comparison_Exp>;
  iban?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_gforms_academy_completed?: Maybe<Boolean_Comparison_Exp>;
  is_imported?: Maybe<Boolean_Comparison_Exp>;
  is_registered_on_dev4side?: Maybe<Boolean_Comparison_Exp>;
  is_typeform_documents_approved?: Maybe<Boolean_Comparison_Exp>;
  is_typeform_documents_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_typeform_general_info_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_video_verification_approved?: Maybe<Boolean_Comparison_Exp>;
  is_video_verification_submitted?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Carvoila_Driver_Status_Enum_Comparison_Exp>;
  typeform_documents_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  typeform_documents_payload?: Maybe<Jsonb_Comparison_Exp>;
  typeform_documents_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  typeform_general_info_payload?: Maybe<Jsonb_Comparison_Exp>;
  typeform_general_info_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  vat_number?: Maybe<String_Comparison_Exp>;
  video_verification_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_verification_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_verification_uri?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "carvoila.driver" */
export enum Carvoila_Driver_Constraint {
  /** unique or primary key constraint on columns "id" */
  DriverPkey = 'driver_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Carvoila_Driver_Delete_At_Path_Input {
  activity_log?: Maybe<Array<Scalars['String']>>;
  districts?: Maybe<Array<Scalars['String']>>;
  gforms_academy_payload?: Maybe<Array<Scalars['String']>>;
  typeform_documents_payload?: Maybe<Array<Scalars['String']>>;
  typeform_general_info_payload?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Carvoila_Driver_Delete_Elem_Input {
  activity_log?: Maybe<Scalars['Int']>;
  districts?: Maybe<Scalars['Int']>;
  gforms_academy_payload?: Maybe<Scalars['Int']>;
  typeform_documents_payload?: Maybe<Scalars['Int']>;
  typeform_general_info_payload?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Carvoila_Driver_Delete_Key_Input {
  activity_log?: Maybe<Scalars['String']>;
  districts?: Maybe<Scalars['String']>;
  gforms_academy_payload?: Maybe<Scalars['String']>;
  typeform_documents_payload?: Maybe<Scalars['String']>;
  typeform_general_info_payload?: Maybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "carvoila.driver" */
export interface Carvoila_Driver_Inc_Input {
  dev4side_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
}

/** input type for inserting data into table "carvoila.driver" */
export interface Carvoila_Driver_Insert_Input {
  account_holder_name?: Maybe<Scalars['String']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dev4side_id?: Maybe<Scalars['Int']>;
  districts?: Maybe<Scalars['jsonb']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  driver_status?: Maybe<Carvoila_Driver_Status_Obj_Rel_Insert_Input>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  gforms_academy_completed_at?: Maybe<Scalars['timestamptz']>;
  gforms_academy_payload?: Maybe<Scalars['jsonb']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_gforms_academy_completed?: Maybe<Scalars['Boolean']>;
  /** Flag if the user was imported from the old Google Sheet */
  is_imported?: Maybe<Scalars['Boolean']>;
  is_registered_on_dev4side?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_typeform_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  status?: Maybe<Carvoila_Driver_Status_Enum>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  typeform_general_info_payload?: Maybe<Scalars['jsonb']>;
  typeform_general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  vat_number?: Maybe<Scalars['String']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Carvoila_Driver_Max_Fields {
  __typename?: 'carvoila_driver_max_fields';
  account_holder_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dev4side_id?: Maybe<Scalars['Int']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  gforms_academy_completed_at?: Maybe<Scalars['timestamptz']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  typeform_general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  vat_number?: Maybe<Scalars['String']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Carvoila_Driver_Min_Fields {
  __typename?: 'carvoila_driver_min_fields';
  account_holder_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dev4side_id?: Maybe<Scalars['Int']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  gforms_academy_completed_at?: Maybe<Scalars['timestamptz']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  typeform_general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  vat_number?: Maybe<Scalars['String']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "carvoila.driver" */
export interface Carvoila_Driver_Mutation_Response {
  __typename?: 'carvoila_driver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Carvoila_Driver>;
}

/** on_conflict condition type for table "carvoila.driver" */
export interface Carvoila_Driver_On_Conflict {
  constraint: Carvoila_Driver_Constraint;
  update_columns?: Array<Carvoila_Driver_Update_Column>;
  where?: Maybe<Carvoila_Driver_Bool_Exp>;
}

/** Ordering options when selecting data from "carvoila.driver". */
export interface Carvoila_Driver_Order_By {
  account_holder_name?: Maybe<Order_By>;
  activity_log?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dev4side_id?: Maybe<Order_By>;
  districts?: Maybe<Order_By>;
  dof_approved_at?: Maybe<Order_By>;
  dof_rejected_at?: Maybe<Order_By>;
  dof_rejected_reason?: Maybe<Order_By>;
  driver_status?: Maybe<Carvoila_Driver_Status_Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  fiscal_code?: Maybe<Order_By>;
  gforms_academy_completed_at?: Maybe<Order_By>;
  gforms_academy_payload?: Maybe<Order_By>;
  iban?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_gforms_academy_completed?: Maybe<Order_By>;
  is_imported?: Maybe<Order_By>;
  is_registered_on_dev4side?: Maybe<Order_By>;
  is_typeform_documents_approved?: Maybe<Order_By>;
  is_typeform_documents_submitted?: Maybe<Order_By>;
  is_typeform_general_info_submitted?: Maybe<Order_By>;
  is_video_verification_approved?: Maybe<Order_By>;
  is_video_verification_submitted?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  typeform_documents_approved_at?: Maybe<Order_By>;
  typeform_documents_payload?: Maybe<Order_By>;
  typeform_documents_submitted_at?: Maybe<Order_By>;
  typeform_general_info_payload?: Maybe<Order_By>;
  typeform_general_info_submitted_at?: Maybe<Order_By>;
  vat_number?: Maybe<Order_By>;
  video_verification_approved_at?: Maybe<Order_By>;
  video_verification_submitted_at?: Maybe<Order_By>;
  video_verification_uri?: Maybe<Order_By>;
}

/** primary key columns input for table: carvoila.driver */
export interface Carvoila_Driver_Pk_Columns_Input {
  id: Scalars['Int'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Carvoila_Driver_Prepend_Input {
  activity_log?: Maybe<Scalars['jsonb']>;
  districts?: Maybe<Scalars['jsonb']>;
  gforms_academy_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_general_info_payload?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "carvoila.driver" */
export enum Carvoila_Driver_Select_Column {
  /** column name */
  AccountHolderName = 'account_holder_name',
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dev4sideId = 'dev4side_id',
  /** column name */
  Districts = 'districts',
  /** column name */
  DofApprovedAt = 'dof_approved_at',
  /** column name */
  DofRejectedAt = 'dof_rejected_at',
  /** column name */
  DofRejectedReason = 'dof_rejected_reason',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FiscalCode = 'fiscal_code',
  /** column name */
  GformsAcademyCompletedAt = 'gforms_academy_completed_at',
  /** column name */
  GformsAcademyPayload = 'gforms_academy_payload',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  IsGformsAcademyCompleted = 'is_gforms_academy_completed',
  /** column name */
  IsImported = 'is_imported',
  /** column name */
  IsRegisteredOnDev4side = 'is_registered_on_dev4side',
  /** column name */
  IsTypeformDocumentsApproved = 'is_typeform_documents_approved',
  /** column name */
  IsTypeformDocumentsSubmitted = 'is_typeform_documents_submitted',
  /** column name */
  IsTypeformGeneralInfoSubmitted = 'is_typeform_general_info_submitted',
  /** column name */
  IsVideoVerificationApproved = 'is_video_verification_approved',
  /** column name */
  IsVideoVerificationSubmitted = 'is_video_verification_submitted',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  Note = 'note',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Status = 'status',
  /** column name */
  TypeformDocumentsApprovedAt = 'typeform_documents_approved_at',
  /** column name */
  TypeformDocumentsPayload = 'typeform_documents_payload',
  /** column name */
  TypeformDocumentsSubmittedAt = 'typeform_documents_submitted_at',
  /** column name */
  TypeformGeneralInfoPayload = 'typeform_general_info_payload',
  /** column name */
  TypeformGeneralInfoSubmittedAt = 'typeform_general_info_submitted_at',
  /** column name */
  VatNumber = 'vat_number',
  /** column name */
  VideoVerificationApprovedAt = 'video_verification_approved_at',
  /** column name */
  VideoVerificationSubmittedAt = 'video_verification_submitted_at',
  /** column name */
  VideoVerificationUri = 'video_verification_uri'
}

/** input type for updating data in table "carvoila.driver" */
export interface Carvoila_Driver_Set_Input {
  account_holder_name?: Maybe<Scalars['String']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dev4side_id?: Maybe<Scalars['Int']>;
  districts?: Maybe<Scalars['jsonb']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  gforms_academy_completed_at?: Maybe<Scalars['timestamptz']>;
  gforms_academy_payload?: Maybe<Scalars['jsonb']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_gforms_academy_completed?: Maybe<Scalars['Boolean']>;
  /** Flag if the user was imported from the old Google Sheet */
  is_imported?: Maybe<Scalars['Boolean']>;
  is_registered_on_dev4side?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_typeform_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  status?: Maybe<Carvoila_Driver_Status_Enum>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  typeform_general_info_payload?: Maybe<Scalars['jsonb']>;
  typeform_general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  vat_number?: Maybe<Scalars['String']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
}

/** columns and relationships of "carvoila.driver_status" */
export interface Carvoila_Driver_Status {
  __typename?: 'carvoila_driver_status';
  value: Scalars['String'];
}

/** aggregated selection of "carvoila.driver_status" */
export interface Carvoila_Driver_Status_Aggregate {
  __typename?: 'carvoila_driver_status_aggregate';
  aggregate?: Maybe<Carvoila_Driver_Status_Aggregate_Fields>;
  nodes: Array<Carvoila_Driver_Status>;
}

/** aggregate fields of "carvoila.driver_status" */
export interface Carvoila_Driver_Status_Aggregate_Fields {
  __typename?: 'carvoila_driver_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Carvoila_Driver_Status_Max_Fields>;
  min?: Maybe<Carvoila_Driver_Status_Min_Fields>;
}


/** aggregate fields of "carvoila.driver_status" */
export type Carvoila_Driver_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Carvoila_Driver_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "carvoila.driver_status". All fields are combined with a logical 'AND'. */
export interface Carvoila_Driver_Status_Bool_Exp {
  _and?: Maybe<Array<Carvoila_Driver_Status_Bool_Exp>>;
  _not?: Maybe<Carvoila_Driver_Status_Bool_Exp>;
  _or?: Maybe<Array<Carvoila_Driver_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "carvoila.driver_status" */
export enum Carvoila_Driver_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  DriverStatusPkey = 'driver_status_pkey'
}

export enum Carvoila_Driver_Status_Enum {
  Approved = 'APPROVED',
  Disabled = 'DISABLED',
  DofRejected = 'DOF_REJECTED',
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "carvoila_driver_status_enum". All fields are combined with logical 'AND'. */
export interface Carvoila_Driver_Status_Enum_Comparison_Exp {
  _eq?: Maybe<Carvoila_Driver_Status_Enum>;
  _in?: Maybe<Array<Carvoila_Driver_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Carvoila_Driver_Status_Enum>;
  _nin?: Maybe<Array<Carvoila_Driver_Status_Enum>>;
}

/** input type for inserting data into table "carvoila.driver_status" */
export interface Carvoila_Driver_Status_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Carvoila_Driver_Status_Max_Fields {
  __typename?: 'carvoila_driver_status_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Carvoila_Driver_Status_Min_Fields {
  __typename?: 'carvoila_driver_status_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "carvoila.driver_status" */
export interface Carvoila_Driver_Status_Mutation_Response {
  __typename?: 'carvoila_driver_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Carvoila_Driver_Status>;
}

/** input type for inserting object relation for remote table "carvoila.driver_status" */
export interface Carvoila_Driver_Status_Obj_Rel_Insert_Input {
  data: Carvoila_Driver_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Carvoila_Driver_Status_On_Conflict>;
}

/** on_conflict condition type for table "carvoila.driver_status" */
export interface Carvoila_Driver_Status_On_Conflict {
  constraint: Carvoila_Driver_Status_Constraint;
  update_columns?: Array<Carvoila_Driver_Status_Update_Column>;
  where?: Maybe<Carvoila_Driver_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "carvoila.driver_status". */
export interface Carvoila_Driver_Status_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: carvoila.driver_status */
export interface Carvoila_Driver_Status_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "carvoila.driver_status" */
export enum Carvoila_Driver_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "carvoila.driver_status" */
export interface Carvoila_Driver_Status_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "carvoila_driver_status" */
export interface Carvoila_Driver_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Carvoila_Driver_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Carvoila_Driver_Status_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "carvoila.driver_status" */
export enum Carvoila_Driver_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Carvoila_Driver_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Carvoila_Driver_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Carvoila_Driver_Status_Bool_Exp;
}

/** aggregate stddev on columns */
export interface Carvoila_Driver_Stddev_Fields {
  __typename?: 'carvoila_driver_stddev_fields';
  dev4side_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Carvoila_Driver_Stddev_Pop_Fields {
  __typename?: 'carvoila_driver_stddev_pop_fields';
  dev4side_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Carvoila_Driver_Stddev_Samp_Fields {
  __typename?: 'carvoila_driver_stddev_samp_fields';
  dev4side_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "carvoila_driver" */
export interface Carvoila_Driver_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Carvoila_Driver_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Carvoila_Driver_Stream_Cursor_Value_Input {
  account_holder_name?: Maybe<Scalars['String']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dev4side_id?: Maybe<Scalars['Int']>;
  districts?: Maybe<Scalars['jsonb']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  gforms_academy_completed_at?: Maybe<Scalars['timestamptz']>;
  gforms_academy_payload?: Maybe<Scalars['jsonb']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_gforms_academy_completed?: Maybe<Scalars['Boolean']>;
  /** Flag if the user was imported from the old Google Sheet */
  is_imported?: Maybe<Scalars['Boolean']>;
  is_registered_on_dev4side?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_typeform_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  status?: Maybe<Carvoila_Driver_Status_Enum>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  typeform_general_info_payload?: Maybe<Scalars['jsonb']>;
  typeform_general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  vat_number?: Maybe<Scalars['String']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Carvoila_Driver_Sum_Fields {
  __typename?: 'carvoila_driver_sum_fields';
  dev4side_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
}

/** update columns of table "carvoila.driver" */
export enum Carvoila_Driver_Update_Column {
  /** column name */
  AccountHolderName = 'account_holder_name',
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dev4sideId = 'dev4side_id',
  /** column name */
  Districts = 'districts',
  /** column name */
  DofApprovedAt = 'dof_approved_at',
  /** column name */
  DofRejectedAt = 'dof_rejected_at',
  /** column name */
  DofRejectedReason = 'dof_rejected_reason',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FiscalCode = 'fiscal_code',
  /** column name */
  GformsAcademyCompletedAt = 'gforms_academy_completed_at',
  /** column name */
  GformsAcademyPayload = 'gforms_academy_payload',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  IsGformsAcademyCompleted = 'is_gforms_academy_completed',
  /** column name */
  IsImported = 'is_imported',
  /** column name */
  IsRegisteredOnDev4side = 'is_registered_on_dev4side',
  /** column name */
  IsTypeformDocumentsApproved = 'is_typeform_documents_approved',
  /** column name */
  IsTypeformDocumentsSubmitted = 'is_typeform_documents_submitted',
  /** column name */
  IsTypeformGeneralInfoSubmitted = 'is_typeform_general_info_submitted',
  /** column name */
  IsVideoVerificationApproved = 'is_video_verification_approved',
  /** column name */
  IsVideoVerificationSubmitted = 'is_video_verification_submitted',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  Note = 'note',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Status = 'status',
  /** column name */
  TypeformDocumentsApprovedAt = 'typeform_documents_approved_at',
  /** column name */
  TypeformDocumentsPayload = 'typeform_documents_payload',
  /** column name */
  TypeformDocumentsSubmittedAt = 'typeform_documents_submitted_at',
  /** column name */
  TypeformGeneralInfoPayload = 'typeform_general_info_payload',
  /** column name */
  TypeformGeneralInfoSubmittedAt = 'typeform_general_info_submitted_at',
  /** column name */
  VatNumber = 'vat_number',
  /** column name */
  VideoVerificationApprovedAt = 'video_verification_approved_at',
  /** column name */
  VideoVerificationSubmittedAt = 'video_verification_submitted_at',
  /** column name */
  VideoVerificationUri = 'video_verification_uri'
}

export interface Carvoila_Driver_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Carvoila_Driver_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Carvoila_Driver_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Carvoila_Driver_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Carvoila_Driver_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Carvoila_Driver_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Carvoila_Driver_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Carvoila_Driver_Set_Input>;
  /** filter the rows which have to be updated */
  where: Carvoila_Driver_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Carvoila_Driver_Var_Pop_Fields {
  __typename?: 'carvoila_driver_var_pop_fields';
  dev4side_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Carvoila_Driver_Var_Samp_Fields {
  __typename?: 'carvoila_driver_var_samp_fields';
  dev4side_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Carvoila_Driver_Variance_Fields {
  __typename?: 'carvoila_driver_variance_fields';
  dev4side_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "city" */
export interface City {
  __typename?: 'city';
  active: Scalars['Boolean'];
  id: Scalars['uuid'];
  label_eng?: Maybe<Scalars['String']>;
  label_ita?: Maybe<Scalars['String']>;
  value: Scalars['String'];
}

/** aggregated selection of "city" */
export interface City_Aggregate {
  __typename?: 'city_aggregate';
  aggregate?: Maybe<City_Aggregate_Fields>;
  nodes: Array<City>;
}

/** aggregate fields of "city" */
export interface City_Aggregate_Fields {
  __typename?: 'city_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<City_Max_Fields>;
  min?: Maybe<City_Min_Fields>;
}


/** aggregate fields of "city" */
export type City_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<City_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "city". All fields are combined with a logical 'AND'. */
export interface City_Bool_Exp {
  _and?: Maybe<Array<City_Bool_Exp>>;
  _not?: Maybe<City_Bool_Exp>;
  _or?: Maybe<Array<City_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label_eng?: Maybe<String_Comparison_Exp>;
  label_ita?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "city" */
export enum City_Constraint {
  /** unique or primary key constraint on columns "id" */
  CityPkey = 'city_pkey'
}

/** input type for inserting data into table "city" */
export interface City_Insert_Input {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  label_eng?: Maybe<Scalars['String']>;
  label_ita?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface City_Max_Fields {
  __typename?: 'city_max_fields';
  id?: Maybe<Scalars['uuid']>;
  label_eng?: Maybe<Scalars['String']>;
  label_ita?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface City_Min_Fields {
  __typename?: 'city_min_fields';
  id?: Maybe<Scalars['uuid']>;
  label_eng?: Maybe<Scalars['String']>;
  label_ita?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "city" */
export interface City_Mutation_Response {
  __typename?: 'city_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<City>;
}

/** on_conflict condition type for table "city" */
export interface City_On_Conflict {
  constraint: City_Constraint;
  update_columns?: Array<City_Update_Column>;
  where?: Maybe<City_Bool_Exp>;
}

/** Ordering options when selecting data from "city". */
export interface City_Order_By {
  active?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label_eng?: Maybe<Order_By>;
  label_ita?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: city */
export interface City_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "city" */
export enum City_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  LabelEng = 'label_eng',
  /** column name */
  LabelIta = 'label_ita',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "city" */
export interface City_Set_Input {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  label_eng?: Maybe<Scalars['String']>;
  label_ita?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "city" */
export interface City_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: City_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface City_Stream_Cursor_Value_Input {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  label_eng?: Maybe<Scalars['String']>;
  label_ita?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "city" */
export enum City_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  LabelEng = 'label_eng',
  /** column name */
  LabelIta = 'label_ita',
  /** column name */
  Value = 'value'
}

export interface City_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<City_Set_Input>;
  /** filter the rows which have to be updated */
  where: City_Bool_Exp;
}

export interface CreateQuoteOutput {
  __typename?: 'createQuoteOutput';
  reservation_id?: Maybe<Scalars['String']>;
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface Date_Comparison_Exp {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
}

/** Table consisting a list of timestamped deployments */
export interface Deployment {
  __typename?: 'deployment';
  commit: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  deployment_type?: Maybe<Deployment_Type>;
  id: Scalars['uuid'];
  /** A type of either app or functions or db */
  type: Deployment_Type_Enum;
}

/** aggregated selection of "deployment" */
export interface Deployment_Aggregate {
  __typename?: 'deployment_aggregate';
  aggregate?: Maybe<Deployment_Aggregate_Fields>;
  nodes: Array<Deployment>;
}

/** aggregate fields of "deployment" */
export interface Deployment_Aggregate_Fields {
  __typename?: 'deployment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deployment_Max_Fields>;
  min?: Maybe<Deployment_Min_Fields>;
}


/** aggregate fields of "deployment" */
export type Deployment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deployment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "deployment". All fields are combined with a logical 'AND'. */
export interface Deployment_Bool_Exp {
  _and?: Maybe<Array<Deployment_Bool_Exp>>;
  _not?: Maybe<Deployment_Bool_Exp>;
  _or?: Maybe<Array<Deployment_Bool_Exp>>;
  commit?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deployment_type?: Maybe<Deployment_Type_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<Deployment_Type_Enum_Comparison_Exp>;
}

/** unique or primary key constraints on table "deployment" */
export enum Deployment_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeploymentPkey = 'deployment_pkey'
}

/** input type for inserting data into table "deployment" */
export interface Deployment_Insert_Input {
  commit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deployment_type?: Maybe<Deployment_Type_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  /** A type of either app or functions or db */
  type?: Maybe<Deployment_Type_Enum>;
}

/** aggregate max on columns */
export interface Deployment_Max_Fields {
  __typename?: 'deployment_max_fields';
  commit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
}

/** aggregate min on columns */
export interface Deployment_Min_Fields {
  __typename?: 'deployment_min_fields';
  commit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
}

/** response of any mutation on the table "deployment" */
export interface Deployment_Mutation_Response {
  __typename?: 'deployment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deployment>;
}

/** on_conflict condition type for table "deployment" */
export interface Deployment_On_Conflict {
  constraint: Deployment_Constraint;
  update_columns?: Array<Deployment_Update_Column>;
  where?: Maybe<Deployment_Bool_Exp>;
}

/** Ordering options when selecting data from "deployment". */
export interface Deployment_Order_By {
  commit?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deployment_type?: Maybe<Deployment_Type_Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
}

/** primary key columns input for table: deployment */
export interface Deployment_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "deployment" */
export enum Deployment_Select_Column {
  /** column name */
  Commit = 'commit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "deployment" */
export interface Deployment_Set_Input {
  commit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** A type of either app or functions or db */
  type?: Maybe<Deployment_Type_Enum>;
}

/** Streaming cursor of the table "deployment" */
export interface Deployment_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Deployment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Deployment_Stream_Cursor_Value_Input {
  commit?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** A type of either app or functions or db */
  type?: Maybe<Deployment_Type_Enum>;
}

/** columns and relationships of "deployment_type" */
export interface Deployment_Type {
  __typename?: 'deployment_type';
  value: Scalars['String'];
}

/** aggregated selection of "deployment_type" */
export interface Deployment_Type_Aggregate {
  __typename?: 'deployment_type_aggregate';
  aggregate?: Maybe<Deployment_Type_Aggregate_Fields>;
  nodes: Array<Deployment_Type>;
}

/** aggregate fields of "deployment_type" */
export interface Deployment_Type_Aggregate_Fields {
  __typename?: 'deployment_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deployment_Type_Max_Fields>;
  min?: Maybe<Deployment_Type_Min_Fields>;
}


/** aggregate fields of "deployment_type" */
export type Deployment_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deployment_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "deployment_type". All fields are combined with a logical 'AND'. */
export interface Deployment_Type_Bool_Exp {
  _and?: Maybe<Array<Deployment_Type_Bool_Exp>>;
  _not?: Maybe<Deployment_Type_Bool_Exp>;
  _or?: Maybe<Array<Deployment_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "deployment_type" */
export enum Deployment_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  DeploymentTypePkey = 'deployment_type_pkey'
}

export enum Deployment_Type_Enum {
  App = 'APP',
  Db = 'DB',
  Functions = 'FUNCTIONS'
}

/** Boolean expression to compare columns of type "deployment_type_enum". All fields are combined with logical 'AND'. */
export interface Deployment_Type_Enum_Comparison_Exp {
  _eq?: Maybe<Deployment_Type_Enum>;
  _in?: Maybe<Array<Deployment_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Deployment_Type_Enum>;
  _nin?: Maybe<Array<Deployment_Type_Enum>>;
}

/** input type for inserting data into table "deployment_type" */
export interface Deployment_Type_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Deployment_Type_Max_Fields {
  __typename?: 'deployment_type_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Deployment_Type_Min_Fields {
  __typename?: 'deployment_type_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "deployment_type" */
export interface Deployment_Type_Mutation_Response {
  __typename?: 'deployment_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deployment_Type>;
}

/** input type for inserting object relation for remote table "deployment_type" */
export interface Deployment_Type_Obj_Rel_Insert_Input {
  data: Deployment_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Deployment_Type_On_Conflict>;
}

/** on_conflict condition type for table "deployment_type" */
export interface Deployment_Type_On_Conflict {
  constraint: Deployment_Type_Constraint;
  update_columns?: Array<Deployment_Type_Update_Column>;
  where?: Maybe<Deployment_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "deployment_type". */
export interface Deployment_Type_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: deployment_type */
export interface Deployment_Type_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "deployment_type" */
export enum Deployment_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "deployment_type" */
export interface Deployment_Type_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "deployment_type" */
export interface Deployment_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Deployment_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Deployment_Type_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "deployment_type" */
export enum Deployment_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Deployment_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deployment_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deployment_Type_Bool_Exp;
}

/** update columns of table "deployment" */
export enum Deployment_Update_Column {
  /** column name */
  Commit = 'commit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

export interface Deployment_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deployment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deployment_Bool_Exp;
}

export interface DriverRejectReservationChangeOutput {
  __typename?: 'driverRejectReservationChangeOutput';
  success?: Maybe<Scalars['Boolean']>;
}

/** Driver specific user data */
export interface Driver_Profile {
  __typename?: 'driver_profile';
  /** Date when user was first redirected to academy. Handled by intermission url. */
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  account: Account;
  account_id: Scalars['String'];
  activity_log: Scalars['jsonb'];
  /** example: { milano: true, bergamo: true }; This object form allows us filtering over driver_profiles. `true` is an opaque value; there's no false in practice - city is there or it's not */
  cities?: Maybe<Scalars['jsonb']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_payload?: Maybe<Scalars['jsonb']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  /** This can be toggled to true to manually proceed with the DOF in case Stripe verification fails (e.g. non-plastic driver license card) */
  document_verification_driver_license_force_verified: Scalars['Boolean'];
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  /** This can be toggled to true to manually proceed with the DOF in case Stripe verification fails (e.g. non-plastic ID card) */
  document_verification_id_force_verified: Scalars['Boolean'];
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  driver_status?: Maybe<Driver_Status>;
  general_info_payload?: Maybe<Scalars['jsonb']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  /** Togglabe by HR to send the academy reminder email */
  is_academy_email_reminder_sent?: Maybe<Scalars['Boolean']>;
  is_covid_passport_approved?: Maybe<Scalars['Boolean']>;
  is_covid_passport_submitted?: Maybe<Scalars['Boolean']>;
  is_document_verification_driver_license_approved?: Maybe<Scalars['Boolean']>;
  is_document_verification_id_approved?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** example: { ERRANDS: GENERAL, CAREGIVING_WITH_UGOS_CAR: SPECIALIZED }; This object form allows us filtering over driver_profiles */
  services?: Maybe<Scalars['jsonb']>;
  /** TODO: Add permissions based on status */
  status?: Maybe<Driver_Status_Enum>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}


/** Driver specific user data */
export type Driver_ProfileActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Driver specific user data */
export type Driver_ProfileCitiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Driver specific user data */
export type Driver_ProfileCovid_Passport_PayloadArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Driver specific user data */
export type Driver_ProfileDocument_Verification_Driver_License_SnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Driver specific user data */
export type Driver_ProfileDocument_Verification_Id_SnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Driver specific user data */
export type Driver_ProfileGeneral_Info_PayloadArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Driver specific user data */
export type Driver_ProfileServicesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Driver specific user data */
export type Driver_ProfileTypeform_Documents_PayloadArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "driver_profile" */
export interface Driver_Profile_Aggregate {
  __typename?: 'driver_profile_aggregate';
  aggregate?: Maybe<Driver_Profile_Aggregate_Fields>;
  nodes: Array<Driver_Profile>;
}

export interface Driver_Profile_Aggregate_Bool_Exp {
  bool_and?: Maybe<Driver_Profile_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Driver_Profile_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Driver_Profile_Aggregate_Bool_Exp_Count>;
}

export interface Driver_Profile_Aggregate_Bool_Exp_Bool_And {
  arguments: Driver_Profile_Select_Column_Driver_Profile_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Driver_Profile_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Driver_Profile_Aggregate_Bool_Exp_Bool_Or {
  arguments: Driver_Profile_Select_Column_Driver_Profile_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Driver_Profile_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Driver_Profile_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Driver_Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Driver_Profile_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "driver_profile" */
export interface Driver_Profile_Aggregate_Fields {
  __typename?: 'driver_profile_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Driver_Profile_Max_Fields>;
  min?: Maybe<Driver_Profile_Min_Fields>;
}


/** aggregate fields of "driver_profile" */
export type Driver_Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Driver_Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "driver_profile" */
export interface Driver_Profile_Aggregate_Order_By {
  count?: Maybe<Order_By>;
  max?: Maybe<Driver_Profile_Max_Order_By>;
  min?: Maybe<Driver_Profile_Min_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Driver_Profile_Append_Input {
  activity_log?: Maybe<Scalars['jsonb']>;
  /** example: { milano: true, bergamo: true }; This object form allows us filtering over driver_profiles. `true` is an opaque value; there's no false in practice - city is there or it's not */
  cities?: Maybe<Scalars['jsonb']>;
  covid_passport_payload?: Maybe<Scalars['jsonb']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_id_snapshot?: Maybe<Scalars['jsonb']>;
  general_info_payload?: Maybe<Scalars['jsonb']>;
  /** example: { ERRANDS: GENERAL, CAREGIVING_WITH_UGOS_CAR: SPECIALIZED }; This object form allows us filtering over driver_profiles */
  services?: Maybe<Scalars['jsonb']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
}

/** input type for inserting array relation for remote table "driver_profile" */
export interface Driver_Profile_Arr_Rel_Insert_Input {
  data: Array<Driver_Profile_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Driver_Profile_On_Conflict>;
}

/** Boolean expression to filter rows from the table "driver_profile". All fields are combined with a logical 'AND'. */
export interface Driver_Profile_Bool_Exp {
  _and?: Maybe<Array<Driver_Profile_Bool_Exp>>;
  _not?: Maybe<Driver_Profile_Bool_Exp>;
  _or?: Maybe<Array<Driver_Profile_Bool_Exp>>;
  academy_first_login_at?: Maybe<Timestamptz_Comparison_Exp>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<String_Comparison_Exp>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  cities?: Maybe<Jsonb_Comparison_Exp>;
  covid_passport_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  covid_passport_payload?: Maybe<Jsonb_Comparison_Exp>;
  covid_passport_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_driver_license_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_driver_license_force_verified?: Maybe<Boolean_Comparison_Exp>;
  document_verification_driver_license_force_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_driver_license_session_id?: Maybe<String_Comparison_Exp>;
  document_verification_driver_license_snapshot?: Maybe<Jsonb_Comparison_Exp>;
  document_verification_driver_license_status?: Maybe<String_Comparison_Exp>;
  document_verification_id_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_id_force_verified?: Maybe<Boolean_Comparison_Exp>;
  document_verification_id_force_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_id_session_id?: Maybe<String_Comparison_Exp>;
  document_verification_id_snapshot?: Maybe<Jsonb_Comparison_Exp>;
  document_verification_id_status?: Maybe<String_Comparison_Exp>;
  dof_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  dof_rejected_at?: Maybe<Timestamptz_Comparison_Exp>;
  dof_rejected_reason?: Maybe<String_Comparison_Exp>;
  dof_requested_city?: Maybe<String_Comparison_Exp>;
  driver_license_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  driver_status?: Maybe<Driver_Status_Bool_Exp>;
  general_info_payload?: Maybe<Jsonb_Comparison_Exp>;
  general_info_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  id_card_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_academy_completed?: Maybe<Boolean_Comparison_Exp>;
  is_academy_email_reminder_sent?: Maybe<Boolean_Comparison_Exp>;
  is_covid_passport_approved?: Maybe<Boolean_Comparison_Exp>;
  is_covid_passport_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_document_verification_driver_license_approved?: Maybe<Boolean_Comparison_Exp>;
  is_document_verification_id_approved?: Maybe<Boolean_Comparison_Exp>;
  is_general_info_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_typeform_documents_approved?: Maybe<Boolean_Comparison_Exp>;
  is_typeform_documents_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_video_verification_approved?: Maybe<Boolean_Comparison_Exp>;
  is_video_verification_submitted?: Maybe<Boolean_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Driver_Status_Enum_Comparison_Exp>;
  typeform_documents_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  typeform_documents_payload?: Maybe<Jsonb_Comparison_Exp>;
  typeform_documents_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_verification_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_verification_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_verification_uri?: Maybe<String_Comparison_Exp>;
  videoask_contact_id?: Maybe<String_Comparison_Exp>;
  videoask_form_id?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "driver_profile" */
export enum Driver_Profile_Constraint {
  /** unique or primary key constraint on columns "account_id" */
  DriverAccountIdKey = 'driver_account_id_key',
  /** unique or primary key constraint on columns "id" */
  DriverPkey = 'driver_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Driver_Profile_Delete_At_Path_Input {
  activity_log?: Maybe<Array<Scalars['String']>>;
  /** example: { milano: true, bergamo: true }; This object form allows us filtering over driver_profiles. `true` is an opaque value; there's no false in practice - city is there or it's not */
  cities?: Maybe<Array<Scalars['String']>>;
  covid_passport_payload?: Maybe<Array<Scalars['String']>>;
  document_verification_driver_license_snapshot?: Maybe<Array<Scalars['String']>>;
  document_verification_id_snapshot?: Maybe<Array<Scalars['String']>>;
  general_info_payload?: Maybe<Array<Scalars['String']>>;
  /** example: { ERRANDS: GENERAL, CAREGIVING_WITH_UGOS_CAR: SPECIALIZED }; This object form allows us filtering over driver_profiles */
  services?: Maybe<Array<Scalars['String']>>;
  typeform_documents_payload?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Driver_Profile_Delete_Elem_Input {
  activity_log?: Maybe<Scalars['Int']>;
  /** example: { milano: true, bergamo: true }; This object form allows us filtering over driver_profiles. `true` is an opaque value; there's no false in practice - city is there or it's not */
  cities?: Maybe<Scalars['Int']>;
  covid_passport_payload?: Maybe<Scalars['Int']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['Int']>;
  document_verification_id_snapshot?: Maybe<Scalars['Int']>;
  general_info_payload?: Maybe<Scalars['Int']>;
  /** example: { ERRANDS: GENERAL, CAREGIVING_WITH_UGOS_CAR: SPECIALIZED }; This object form allows us filtering over driver_profiles */
  services?: Maybe<Scalars['Int']>;
  typeform_documents_payload?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Driver_Profile_Delete_Key_Input {
  activity_log?: Maybe<Scalars['String']>;
  /** example: { milano: true, bergamo: true }; This object form allows us filtering over driver_profiles. `true` is an opaque value; there's no false in practice - city is there or it's not */
  cities?: Maybe<Scalars['String']>;
  covid_passport_payload?: Maybe<Scalars['String']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['String']>;
  document_verification_id_snapshot?: Maybe<Scalars['String']>;
  general_info_payload?: Maybe<Scalars['String']>;
  /** example: { ERRANDS: GENERAL, CAREGIVING_WITH_UGOS_CAR: SPECIALIZED }; This object form allows us filtering over driver_profiles */
  services?: Maybe<Scalars['String']>;
  typeform_documents_payload?: Maybe<Scalars['String']>;
}

/** input type for inserting data into table "driver_profile" */
export interface Driver_Profile_Insert_Input {
  /** Date when user was first redirected to academy. Handled by intermission url. */
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['String']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  /** example: { milano: true, bergamo: true }; This object form allows us filtering over driver_profiles. `true` is an opaque value; there's no false in practice - city is there or it's not */
  cities?: Maybe<Scalars['jsonb']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_payload?: Maybe<Scalars['jsonb']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  /** This can be toggled to true to manually proceed with the DOF in case Stripe verification fails (e.g. non-plastic driver license card) */
  document_verification_driver_license_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  /** This can be toggled to true to manually proceed with the DOF in case Stripe verification fails (e.g. non-plastic ID card) */
  document_verification_id_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  driver_status?: Maybe<Driver_Status_Obj_Rel_Insert_Input>;
  general_info_payload?: Maybe<Scalars['jsonb']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  /** Togglabe by HR to send the academy reminder email */
  is_academy_email_reminder_sent?: Maybe<Scalars['Boolean']>;
  is_covid_passport_approved?: Maybe<Scalars['Boolean']>;
  is_covid_passport_submitted?: Maybe<Scalars['Boolean']>;
  is_document_verification_driver_license_approved?: Maybe<Scalars['Boolean']>;
  is_document_verification_id_approved?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** example: { ERRANDS: GENERAL, CAREGIVING_WITH_UGOS_CAR: SPECIALIZED }; This object form allows us filtering over driver_profiles */
  services?: Maybe<Scalars['jsonb']>;
  /** TODO: Add permissions based on status */
  status?: Maybe<Driver_Status_Enum>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Driver_Profile_Max_Fields {
  __typename?: 'driver_profile_max_fields';
  /** Date when user was first redirected to academy. Handled by intermission url. */
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  account_id?: Maybe<Scalars['String']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "driver_profile" */
export interface Driver_Profile_Max_Order_By {
  /** Date when user was first redirected to academy. Handled by intermission url. */
  academy_first_login_at?: Maybe<Order_By>;
  account_id?: Maybe<Order_By>;
  covid_passport_approved_at?: Maybe<Order_By>;
  covid_passport_submitted_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  document_verification_driver_license_approved_at?: Maybe<Order_By>;
  document_verification_driver_license_force_verified_at?: Maybe<Order_By>;
  document_verification_driver_license_session_id?: Maybe<Order_By>;
  document_verification_driver_license_status?: Maybe<Order_By>;
  document_verification_id_approved_at?: Maybe<Order_By>;
  document_verification_id_force_verified_at?: Maybe<Order_By>;
  document_verification_id_session_id?: Maybe<Order_By>;
  document_verification_id_status?: Maybe<Order_By>;
  dof_approved_at?: Maybe<Order_By>;
  dof_rejected_at?: Maybe<Order_By>;
  dof_rejected_reason?: Maybe<Order_By>;
  dof_requested_city?: Maybe<Order_By>;
  driver_license_verified_at?: Maybe<Order_By>;
  general_info_submitted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_card_verified_at?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  typeform_documents_approved_at?: Maybe<Order_By>;
  typeform_documents_submitted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_verification_approved_at?: Maybe<Order_By>;
  video_verification_submitted_at?: Maybe<Order_By>;
  video_verification_uri?: Maybe<Order_By>;
  videoask_contact_id?: Maybe<Order_By>;
  videoask_form_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Driver_Profile_Min_Fields {
  __typename?: 'driver_profile_min_fields';
  /** Date when user was first redirected to academy. Handled by intermission url. */
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  account_id?: Maybe<Scalars['String']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "driver_profile" */
export interface Driver_Profile_Min_Order_By {
  /** Date when user was first redirected to academy. Handled by intermission url. */
  academy_first_login_at?: Maybe<Order_By>;
  account_id?: Maybe<Order_By>;
  covid_passport_approved_at?: Maybe<Order_By>;
  covid_passport_submitted_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  document_verification_driver_license_approved_at?: Maybe<Order_By>;
  document_verification_driver_license_force_verified_at?: Maybe<Order_By>;
  document_verification_driver_license_session_id?: Maybe<Order_By>;
  document_verification_driver_license_status?: Maybe<Order_By>;
  document_verification_id_approved_at?: Maybe<Order_By>;
  document_verification_id_force_verified_at?: Maybe<Order_By>;
  document_verification_id_session_id?: Maybe<Order_By>;
  document_verification_id_status?: Maybe<Order_By>;
  dof_approved_at?: Maybe<Order_By>;
  dof_rejected_at?: Maybe<Order_By>;
  dof_rejected_reason?: Maybe<Order_By>;
  dof_requested_city?: Maybe<Order_By>;
  driver_license_verified_at?: Maybe<Order_By>;
  general_info_submitted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_card_verified_at?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  typeform_documents_approved_at?: Maybe<Order_By>;
  typeform_documents_submitted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_verification_approved_at?: Maybe<Order_By>;
  video_verification_submitted_at?: Maybe<Order_By>;
  video_verification_uri?: Maybe<Order_By>;
  videoask_contact_id?: Maybe<Order_By>;
  videoask_form_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "driver_profile" */
export interface Driver_Profile_Mutation_Response {
  __typename?: 'driver_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Driver_Profile>;
}

/** input type for inserting object relation for remote table "driver_profile" */
export interface Driver_Profile_Obj_Rel_Insert_Input {
  data: Driver_Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Driver_Profile_On_Conflict>;
}

/** on_conflict condition type for table "driver_profile" */
export interface Driver_Profile_On_Conflict {
  constraint: Driver_Profile_Constraint;
  update_columns?: Array<Driver_Profile_Update_Column>;
  where?: Maybe<Driver_Profile_Bool_Exp>;
}

/** Ordering options when selecting data from "driver_profile". */
export interface Driver_Profile_Order_By {
  academy_first_login_at?: Maybe<Order_By>;
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  activity_log?: Maybe<Order_By>;
  cities?: Maybe<Order_By>;
  covid_passport_approved_at?: Maybe<Order_By>;
  covid_passport_payload?: Maybe<Order_By>;
  covid_passport_submitted_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  document_verification_driver_license_approved_at?: Maybe<Order_By>;
  document_verification_driver_license_force_verified?: Maybe<Order_By>;
  document_verification_driver_license_force_verified_at?: Maybe<Order_By>;
  document_verification_driver_license_session_id?: Maybe<Order_By>;
  document_verification_driver_license_snapshot?: Maybe<Order_By>;
  document_verification_driver_license_status?: Maybe<Order_By>;
  document_verification_id_approved_at?: Maybe<Order_By>;
  document_verification_id_force_verified?: Maybe<Order_By>;
  document_verification_id_force_verified_at?: Maybe<Order_By>;
  document_verification_id_session_id?: Maybe<Order_By>;
  document_verification_id_snapshot?: Maybe<Order_By>;
  document_verification_id_status?: Maybe<Order_By>;
  dof_approved_at?: Maybe<Order_By>;
  dof_rejected_at?: Maybe<Order_By>;
  dof_rejected_reason?: Maybe<Order_By>;
  dof_requested_city?: Maybe<Order_By>;
  driver_license_verified_at?: Maybe<Order_By>;
  driver_status?: Maybe<Driver_Status_Order_By>;
  general_info_payload?: Maybe<Order_By>;
  general_info_submitted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_card_verified_at?: Maybe<Order_By>;
  is_academy_completed?: Maybe<Order_By>;
  is_academy_email_reminder_sent?: Maybe<Order_By>;
  is_covid_passport_approved?: Maybe<Order_By>;
  is_covid_passport_submitted?: Maybe<Order_By>;
  is_document_verification_driver_license_approved?: Maybe<Order_By>;
  is_document_verification_id_approved?: Maybe<Order_By>;
  is_general_info_submitted?: Maybe<Order_By>;
  is_typeform_documents_approved?: Maybe<Order_By>;
  is_typeform_documents_submitted?: Maybe<Order_By>;
  is_video_verification_approved?: Maybe<Order_By>;
  is_video_verification_submitted?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  typeform_documents_approved_at?: Maybe<Order_By>;
  typeform_documents_payload?: Maybe<Order_By>;
  typeform_documents_submitted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_verification_approved_at?: Maybe<Order_By>;
  video_verification_submitted_at?: Maybe<Order_By>;
  video_verification_uri?: Maybe<Order_By>;
  videoask_contact_id?: Maybe<Order_By>;
  videoask_form_id?: Maybe<Order_By>;
}

/** primary key columns input for table: driver_profile */
export interface Driver_Profile_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Driver_Profile_Prepend_Input {
  activity_log?: Maybe<Scalars['jsonb']>;
  /** example: { milano: true, bergamo: true }; This object form allows us filtering over driver_profiles. `true` is an opaque value; there's no false in practice - city is there or it's not */
  cities?: Maybe<Scalars['jsonb']>;
  covid_passport_payload?: Maybe<Scalars['jsonb']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_id_snapshot?: Maybe<Scalars['jsonb']>;
  general_info_payload?: Maybe<Scalars['jsonb']>;
  /** example: { ERRANDS: GENERAL, CAREGIVING_WITH_UGOS_CAR: SPECIALIZED }; This object form allows us filtering over driver_profiles */
  services?: Maybe<Scalars['jsonb']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "driver_profile" */
export enum Driver_Profile_Select_Column {
  /** column name */
  AcademyFirstLoginAt = 'academy_first_login_at',
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  Cities = 'cities',
  /** column name */
  CovidPassportApprovedAt = 'covid_passport_approved_at',
  /** column name */
  CovidPassportPayload = 'covid_passport_payload',
  /** column name */
  CovidPassportSubmittedAt = 'covid_passport_submitted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentVerificationDriverLicenseApprovedAt = 'document_verification_driver_license_approved_at',
  /** column name */
  DocumentVerificationDriverLicenseForceVerified = 'document_verification_driver_license_force_verified',
  /** column name */
  DocumentVerificationDriverLicenseForceVerifiedAt = 'document_verification_driver_license_force_verified_at',
  /** column name */
  DocumentVerificationDriverLicenseSessionId = 'document_verification_driver_license_session_id',
  /** column name */
  DocumentVerificationDriverLicenseSnapshot = 'document_verification_driver_license_snapshot',
  /** column name */
  DocumentVerificationDriverLicenseStatus = 'document_verification_driver_license_status',
  /** column name */
  DocumentVerificationIdApprovedAt = 'document_verification_id_approved_at',
  /** column name */
  DocumentVerificationIdForceVerified = 'document_verification_id_force_verified',
  /** column name */
  DocumentVerificationIdForceVerifiedAt = 'document_verification_id_force_verified_at',
  /** column name */
  DocumentVerificationIdSessionId = 'document_verification_id_session_id',
  /** column name */
  DocumentVerificationIdSnapshot = 'document_verification_id_snapshot',
  /** column name */
  DocumentVerificationIdStatus = 'document_verification_id_status',
  /** column name */
  DofApprovedAt = 'dof_approved_at',
  /** column name */
  DofRejectedAt = 'dof_rejected_at',
  /** column name */
  DofRejectedReason = 'dof_rejected_reason',
  /** column name */
  DofRequestedCity = 'dof_requested_city',
  /** column name */
  DriverLicenseVerifiedAt = 'driver_license_verified_at',
  /** column name */
  GeneralInfoPayload = 'general_info_payload',
  /** column name */
  GeneralInfoSubmittedAt = 'general_info_submitted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCardVerifiedAt = 'id_card_verified_at',
  /** column name */
  IsAcademyCompleted = 'is_academy_completed',
  /** column name */
  IsAcademyEmailReminderSent = 'is_academy_email_reminder_sent',
  /** column name */
  IsCovidPassportApproved = 'is_covid_passport_approved',
  /** column name */
  IsCovidPassportSubmitted = 'is_covid_passport_submitted',
  /** column name */
  IsDocumentVerificationDriverLicenseApproved = 'is_document_verification_driver_license_approved',
  /** column name */
  IsDocumentVerificationIdApproved = 'is_document_verification_id_approved',
  /** column name */
  IsGeneralInfoSubmitted = 'is_general_info_submitted',
  /** column name */
  IsTypeformDocumentsApproved = 'is_typeform_documents_approved',
  /** column name */
  IsTypeformDocumentsSubmitted = 'is_typeform_documents_submitted',
  /** column name */
  IsVideoVerificationApproved = 'is_video_verification_approved',
  /** column name */
  IsVideoVerificationSubmitted = 'is_video_verification_submitted',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  TypeformDocumentsApprovedAt = 'typeform_documents_approved_at',
  /** column name */
  TypeformDocumentsPayload = 'typeform_documents_payload',
  /** column name */
  TypeformDocumentsSubmittedAt = 'typeform_documents_submitted_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoVerificationApprovedAt = 'video_verification_approved_at',
  /** column name */
  VideoVerificationSubmittedAt = 'video_verification_submitted_at',
  /** column name */
  VideoVerificationUri = 'video_verification_uri',
  /** column name */
  VideoaskContactId = 'videoask_contact_id',
  /** column name */
  VideoaskFormId = 'videoask_form_id'
}

/** select "driver_profile_aggregate_bool_exp_bool_and_arguments_columns" columns of table "driver_profile" */
export enum Driver_Profile_Select_Column_Driver_Profile_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DocumentVerificationDriverLicenseForceVerified = 'document_verification_driver_license_force_verified',
  /** column name */
  DocumentVerificationIdForceVerified = 'document_verification_id_force_verified',
  /** column name */
  IsAcademyCompleted = 'is_academy_completed',
  /** column name */
  IsAcademyEmailReminderSent = 'is_academy_email_reminder_sent',
  /** column name */
  IsCovidPassportApproved = 'is_covid_passport_approved',
  /** column name */
  IsCovidPassportSubmitted = 'is_covid_passport_submitted',
  /** column name */
  IsDocumentVerificationDriverLicenseApproved = 'is_document_verification_driver_license_approved',
  /** column name */
  IsDocumentVerificationIdApproved = 'is_document_verification_id_approved',
  /** column name */
  IsGeneralInfoSubmitted = 'is_general_info_submitted',
  /** column name */
  IsTypeformDocumentsApproved = 'is_typeform_documents_approved',
  /** column name */
  IsTypeformDocumentsSubmitted = 'is_typeform_documents_submitted',
  /** column name */
  IsVideoVerificationApproved = 'is_video_verification_approved',
  /** column name */
  IsVideoVerificationSubmitted = 'is_video_verification_submitted'
}

/** select "driver_profile_aggregate_bool_exp_bool_or_arguments_columns" columns of table "driver_profile" */
export enum Driver_Profile_Select_Column_Driver_Profile_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DocumentVerificationDriverLicenseForceVerified = 'document_verification_driver_license_force_verified',
  /** column name */
  DocumentVerificationIdForceVerified = 'document_verification_id_force_verified',
  /** column name */
  IsAcademyCompleted = 'is_academy_completed',
  /** column name */
  IsAcademyEmailReminderSent = 'is_academy_email_reminder_sent',
  /** column name */
  IsCovidPassportApproved = 'is_covid_passport_approved',
  /** column name */
  IsCovidPassportSubmitted = 'is_covid_passport_submitted',
  /** column name */
  IsDocumentVerificationDriverLicenseApproved = 'is_document_verification_driver_license_approved',
  /** column name */
  IsDocumentVerificationIdApproved = 'is_document_verification_id_approved',
  /** column name */
  IsGeneralInfoSubmitted = 'is_general_info_submitted',
  /** column name */
  IsTypeformDocumentsApproved = 'is_typeform_documents_approved',
  /** column name */
  IsTypeformDocumentsSubmitted = 'is_typeform_documents_submitted',
  /** column name */
  IsVideoVerificationApproved = 'is_video_verification_approved',
  /** column name */
  IsVideoVerificationSubmitted = 'is_video_verification_submitted'
}

/** input type for updating data in table "driver_profile" */
export interface Driver_Profile_Set_Input {
  /** Date when user was first redirected to academy. Handled by intermission url. */
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  account_id?: Maybe<Scalars['String']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  /** example: { milano: true, bergamo: true }; This object form allows us filtering over driver_profiles. `true` is an opaque value; there's no false in practice - city is there or it's not */
  cities?: Maybe<Scalars['jsonb']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_payload?: Maybe<Scalars['jsonb']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  /** This can be toggled to true to manually proceed with the DOF in case Stripe verification fails (e.g. non-plastic driver license card) */
  document_verification_driver_license_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  /** This can be toggled to true to manually proceed with the DOF in case Stripe verification fails (e.g. non-plastic ID card) */
  document_verification_id_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  general_info_payload?: Maybe<Scalars['jsonb']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  /** Togglabe by HR to send the academy reminder email */
  is_academy_email_reminder_sent?: Maybe<Scalars['Boolean']>;
  is_covid_passport_approved?: Maybe<Scalars['Boolean']>;
  is_covid_passport_submitted?: Maybe<Scalars['Boolean']>;
  is_document_verification_driver_license_approved?: Maybe<Scalars['Boolean']>;
  is_document_verification_id_approved?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** example: { ERRANDS: GENERAL, CAREGIVING_WITH_UGOS_CAR: SPECIALIZED }; This object form allows us filtering over driver_profiles */
  services?: Maybe<Scalars['jsonb']>;
  /** TODO: Add permissions based on status */
  status?: Maybe<Driver_Status_Enum>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "driver_profile" */
export interface Driver_Profile_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Driver_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Driver_Profile_Stream_Cursor_Value_Input {
  /** Date when user was first redirected to academy. Handled by intermission url. */
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  account_id?: Maybe<Scalars['String']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  /** example: { milano: true, bergamo: true }; This object form allows us filtering over driver_profiles. `true` is an opaque value; there's no false in practice - city is there or it's not */
  cities?: Maybe<Scalars['jsonb']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_payload?: Maybe<Scalars['jsonb']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  /** This can be toggled to true to manually proceed with the DOF in case Stripe verification fails (e.g. non-plastic driver license card) */
  document_verification_driver_license_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  /** This can be toggled to true to manually proceed with the DOF in case Stripe verification fails (e.g. non-plastic ID card) */
  document_verification_id_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  general_info_payload?: Maybe<Scalars['jsonb']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  /** Togglabe by HR to send the academy reminder email */
  is_academy_email_reminder_sent?: Maybe<Scalars['Boolean']>;
  is_covid_passport_approved?: Maybe<Scalars['Boolean']>;
  is_covid_passport_submitted?: Maybe<Scalars['Boolean']>;
  is_document_verification_driver_license_approved?: Maybe<Scalars['Boolean']>;
  is_document_verification_id_approved?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** example: { ERRANDS: GENERAL, CAREGIVING_WITH_UGOS_CAR: SPECIALIZED }; This object form allows us filtering over driver_profiles */
  services?: Maybe<Scalars['jsonb']>;
  /** TODO: Add permissions based on status */
  status?: Maybe<Driver_Status_Enum>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}

/** update columns of table "driver_profile" */
export enum Driver_Profile_Update_Column {
  /** column name */
  AcademyFirstLoginAt = 'academy_first_login_at',
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  Cities = 'cities',
  /** column name */
  CovidPassportApprovedAt = 'covid_passport_approved_at',
  /** column name */
  CovidPassportPayload = 'covid_passport_payload',
  /** column name */
  CovidPassportSubmittedAt = 'covid_passport_submitted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentVerificationDriverLicenseApprovedAt = 'document_verification_driver_license_approved_at',
  /** column name */
  DocumentVerificationDriverLicenseForceVerified = 'document_verification_driver_license_force_verified',
  /** column name */
  DocumentVerificationDriverLicenseForceVerifiedAt = 'document_verification_driver_license_force_verified_at',
  /** column name */
  DocumentVerificationDriverLicenseSessionId = 'document_verification_driver_license_session_id',
  /** column name */
  DocumentVerificationDriverLicenseSnapshot = 'document_verification_driver_license_snapshot',
  /** column name */
  DocumentVerificationDriverLicenseStatus = 'document_verification_driver_license_status',
  /** column name */
  DocumentVerificationIdApprovedAt = 'document_verification_id_approved_at',
  /** column name */
  DocumentVerificationIdForceVerified = 'document_verification_id_force_verified',
  /** column name */
  DocumentVerificationIdForceVerifiedAt = 'document_verification_id_force_verified_at',
  /** column name */
  DocumentVerificationIdSessionId = 'document_verification_id_session_id',
  /** column name */
  DocumentVerificationIdSnapshot = 'document_verification_id_snapshot',
  /** column name */
  DocumentVerificationIdStatus = 'document_verification_id_status',
  /** column name */
  DofApprovedAt = 'dof_approved_at',
  /** column name */
  DofRejectedAt = 'dof_rejected_at',
  /** column name */
  DofRejectedReason = 'dof_rejected_reason',
  /** column name */
  DofRequestedCity = 'dof_requested_city',
  /** column name */
  DriverLicenseVerifiedAt = 'driver_license_verified_at',
  /** column name */
  GeneralInfoPayload = 'general_info_payload',
  /** column name */
  GeneralInfoSubmittedAt = 'general_info_submitted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCardVerifiedAt = 'id_card_verified_at',
  /** column name */
  IsAcademyCompleted = 'is_academy_completed',
  /** column name */
  IsAcademyEmailReminderSent = 'is_academy_email_reminder_sent',
  /** column name */
  IsCovidPassportApproved = 'is_covid_passport_approved',
  /** column name */
  IsCovidPassportSubmitted = 'is_covid_passport_submitted',
  /** column name */
  IsDocumentVerificationDriverLicenseApproved = 'is_document_verification_driver_license_approved',
  /** column name */
  IsDocumentVerificationIdApproved = 'is_document_verification_id_approved',
  /** column name */
  IsGeneralInfoSubmitted = 'is_general_info_submitted',
  /** column name */
  IsTypeformDocumentsApproved = 'is_typeform_documents_approved',
  /** column name */
  IsTypeformDocumentsSubmitted = 'is_typeform_documents_submitted',
  /** column name */
  IsVideoVerificationApproved = 'is_video_verification_approved',
  /** column name */
  IsVideoVerificationSubmitted = 'is_video_verification_submitted',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  TypeformDocumentsApprovedAt = 'typeform_documents_approved_at',
  /** column name */
  TypeformDocumentsPayload = 'typeform_documents_payload',
  /** column name */
  TypeformDocumentsSubmittedAt = 'typeform_documents_submitted_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoVerificationApprovedAt = 'video_verification_approved_at',
  /** column name */
  VideoVerificationSubmittedAt = 'video_verification_submitted_at',
  /** column name */
  VideoVerificationUri = 'video_verification_uri',
  /** column name */
  VideoaskContactId = 'videoask_contact_id',
  /** column name */
  VideoaskFormId = 'videoask_form_id'
}

export interface Driver_Profile_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Driver_Profile_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Driver_Profile_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Driver_Profile_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Driver_Profile_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Driver_Profile_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Driver_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Driver_Profile_Bool_Exp;
}

/** columns and relationships of "driver_status" */
export interface Driver_Status {
  __typename?: 'driver_status';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  driver_profiles: Array<Driver_Profile>;
  /** An aggregate relationship */
  driver_profiles_aggregate: Driver_Profile_Aggregate;
  value: Scalars['String'];
}


/** columns and relationships of "driver_status" */
export type Driver_StatusDriver_ProfilesArgs = {
  distinct_on?: Maybe<Array<Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Profile_Order_By>>;
  where?: Maybe<Driver_Profile_Bool_Exp>;
};


/** columns and relationships of "driver_status" */
export type Driver_StatusDriver_Profiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Profile_Order_By>>;
  where?: Maybe<Driver_Profile_Bool_Exp>;
};

/** aggregated selection of "driver_status" */
export interface Driver_Status_Aggregate {
  __typename?: 'driver_status_aggregate';
  aggregate?: Maybe<Driver_Status_Aggregate_Fields>;
  nodes: Array<Driver_Status>;
}

/** aggregate fields of "driver_status" */
export interface Driver_Status_Aggregate_Fields {
  __typename?: 'driver_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Driver_Status_Max_Fields>;
  min?: Maybe<Driver_Status_Min_Fields>;
}


/** aggregate fields of "driver_status" */
export type Driver_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Driver_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "driver_status". All fields are combined with a logical 'AND'. */
export interface Driver_Status_Bool_Exp {
  _and?: Maybe<Array<Driver_Status_Bool_Exp>>;
  _not?: Maybe<Driver_Status_Bool_Exp>;
  _or?: Maybe<Array<Driver_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  driver_profiles?: Maybe<Driver_Profile_Bool_Exp>;
  driver_profiles_aggregate?: Maybe<Driver_Profile_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "driver_status" */
export enum Driver_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  DriverStatusPkey = 'driver_status_pkey'
}

export enum Driver_Status_Enum {
  Approved = 'APPROVED',
  Banned = 'BANNED',
  DofRejected = 'DOF_REJECTED',
  Pending = 'PENDING',
  Requested = 'REQUESTED'
}

/** Boolean expression to compare columns of type "driver_status_enum". All fields are combined with logical 'AND'. */
export interface Driver_Status_Enum_Comparison_Exp {
  _eq?: Maybe<Driver_Status_Enum>;
  _in?: Maybe<Array<Driver_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Driver_Status_Enum>;
  _nin?: Maybe<Array<Driver_Status_Enum>>;
}

/** input type for inserting data into table "driver_status" */
export interface Driver_Status_Insert_Input {
  description?: Maybe<Scalars['String']>;
  driver_profiles?: Maybe<Driver_Profile_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Driver_Status_Max_Fields {
  __typename?: 'driver_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Driver_Status_Min_Fields {
  __typename?: 'driver_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "driver_status" */
export interface Driver_Status_Mutation_Response {
  __typename?: 'driver_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Driver_Status>;
}

/** input type for inserting object relation for remote table "driver_status" */
export interface Driver_Status_Obj_Rel_Insert_Input {
  data: Driver_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Driver_Status_On_Conflict>;
}

/** on_conflict condition type for table "driver_status" */
export interface Driver_Status_On_Conflict {
  constraint: Driver_Status_Constraint;
  update_columns?: Array<Driver_Status_Update_Column>;
  where?: Maybe<Driver_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "driver_status". */
export interface Driver_Status_Order_By {
  description?: Maybe<Order_By>;
  driver_profiles_aggregate?: Maybe<Driver_Profile_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: driver_status */
export interface Driver_Status_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "driver_status" */
export enum Driver_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "driver_status" */
export interface Driver_Status_Set_Input {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "driver_status" */
export interface Driver_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Driver_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Driver_Status_Stream_Cursor_Value_Input {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "driver_status" */
export enum Driver_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export interface Driver_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Driver_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Driver_Status_Bool_Exp;
}


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export interface Float8_Comparison_Exp {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
}

export interface Fn_Stats_Get_General_User_Args {
  _end_date?: Maybe<Scalars['timestamptz']>;
  _start_date?: Maybe<Scalars['timestamptz']>;
}

export interface Fn_Stats_Get_Pareto_Distribution_Account_Reservation_Args {
  min_reservation_count?: Maybe<Scalars['Int']>;
}

export interface Fn_Stats_Get_Voucher_Uses_Args {
  _end_date?: Maybe<Scalars['timestamptz']>;
  _start_date?: Maybe<Scalars['timestamptz']>;
}


export interface Jsonb_Cast_Exp {
  String?: Maybe<String_Comparison_Exp>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface Jsonb_Comparison_Exp {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
}

/** columns and relationships of "manual_driver" */
export interface Manual_Driver {
  __typename?: 'manual_driver';
  /** An object relationship */
  account?: Maybe<Account>;
  account_id?: Maybe<Scalars['String']>;
  cities: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  manual_reservations: Array<Manual_Reservation>;
  /** An aggregate relationship */
  manual_reservations_aggregate: Manual_Reservation_Aggregate;
  /** An array relationship */
  manual_reservations_preferred: Array<Manual_Reservation>;
  /** An aggregate relationship */
  manual_reservations_preferred_aggregate: Manual_Reservation_Aggregate;
  phone_number?: Maybe<Scalars['String']>;
  services: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "manual_driver" */
export type Manual_DriverCitiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "manual_driver" */
export type Manual_DriverManual_ReservationsArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


/** columns and relationships of "manual_driver" */
export type Manual_DriverManual_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


/** columns and relationships of "manual_driver" */
export type Manual_DriverManual_Reservations_PreferredArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


/** columns and relationships of "manual_driver" */
export type Manual_DriverManual_Reservations_Preferred_AggregateArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


/** columns and relationships of "manual_driver" */
export type Manual_DriverServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "manual_driver" */
export interface Manual_Driver_Aggregate {
  __typename?: 'manual_driver_aggregate';
  aggregate?: Maybe<Manual_Driver_Aggregate_Fields>;
  nodes: Array<Manual_Driver>;
}

/** aggregate fields of "manual_driver" */
export interface Manual_Driver_Aggregate_Fields {
  __typename?: 'manual_driver_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Manual_Driver_Max_Fields>;
  min?: Maybe<Manual_Driver_Min_Fields>;
}


/** aggregate fields of "manual_driver" */
export type Manual_Driver_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Manual_Driver_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Manual_Driver_Append_Input {
  cities?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
}

/** Boolean expression to filter rows from the table "manual_driver". All fields are combined with a logical 'AND'. */
export interface Manual_Driver_Bool_Exp {
  _and?: Maybe<Array<Manual_Driver_Bool_Exp>>;
  _not?: Maybe<Manual_Driver_Bool_Exp>;
  _or?: Maybe<Array<Manual_Driver_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<String_Comparison_Exp>;
  cities?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  manual_reservations?: Maybe<Manual_Reservation_Bool_Exp>;
  manual_reservations_aggregate?: Maybe<Manual_Reservation_Aggregate_Bool_Exp>;
  manual_reservations_preferred?: Maybe<Manual_Reservation_Bool_Exp>;
  manual_reservations_preferred_aggregate?: Maybe<Manual_Reservation_Aggregate_Bool_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "manual_driver" */
export enum Manual_Driver_Constraint {
  /** unique or primary key constraint on columns "email" */
  ManualDriverEmailKey = 'manual_driver_email_key',
  /** unique or primary key constraint on columns "id" */
  ManualDriverPkey = 'manual_driver_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Manual_Driver_Delete_At_Path_Input {
  cities?: Maybe<Array<Scalars['String']>>;
  services?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Manual_Driver_Delete_Elem_Input {
  cities?: Maybe<Scalars['Int']>;
  services?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Manual_Driver_Delete_Key_Input {
  cities?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['String']>;
}

/** input type for inserting data into table "manual_driver" */
export interface Manual_Driver_Insert_Input {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['String']>;
  cities?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  manual_reservations?: Maybe<Manual_Reservation_Arr_Rel_Insert_Input>;
  manual_reservations_preferred?: Maybe<Manual_Reservation_Arr_Rel_Insert_Input>;
  phone_number?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Manual_Driver_Max_Fields {
  __typename?: 'manual_driver_max_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Manual_Driver_Min_Fields {
  __typename?: 'manual_driver_min_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "manual_driver" */
export interface Manual_Driver_Mutation_Response {
  __typename?: 'manual_driver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Manual_Driver>;
}

/** input type for inserting object relation for remote table "manual_driver" */
export interface Manual_Driver_Obj_Rel_Insert_Input {
  data: Manual_Driver_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Manual_Driver_On_Conflict>;
}

/** on_conflict condition type for table "manual_driver" */
export interface Manual_Driver_On_Conflict {
  constraint: Manual_Driver_Constraint;
  update_columns?: Array<Manual_Driver_Update_Column>;
  where?: Maybe<Manual_Driver_Bool_Exp>;
}

/** Ordering options when selecting data from "manual_driver". */
export interface Manual_Driver_Order_By {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  cities?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  manual_reservations_aggregate?: Maybe<Manual_Reservation_Aggregate_Order_By>;
  manual_reservations_preferred_aggregate?: Maybe<Manual_Reservation_Aggregate_Order_By>;
  phone_number?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** primary key columns input for table: manual_driver */
export interface Manual_Driver_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Manual_Driver_Prepend_Input {
  cities?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "manual_driver" */
export enum Manual_Driver_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Cities = 'cities',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "manual_driver" */
export interface Manual_Driver_Set_Input {
  account_id?: Maybe<Scalars['String']>;
  cities?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** Streaming cursor of the table "manual_driver" */
export interface Manual_Driver_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Manual_Driver_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Manual_Driver_Stream_Cursor_Value_Input {
  account_id?: Maybe<Scalars['String']>;
  cities?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  services?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** update columns of table "manual_driver" */
export enum Manual_Driver_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Cities = 'cities',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Services = 'services',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Manual_Driver_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Manual_Driver_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Manual_Driver_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Manual_Driver_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Manual_Driver_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Manual_Driver_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Manual_Driver_Set_Input>;
  /** filter the rows which have to be updated */
  where: Manual_Driver_Bool_Exp;
}

/** columns and relationships of "manual_patient" */
export interface Manual_Patient {
  __typename?: 'manual_patient';
  caregiver_email?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  manual_reservations: Array<Manual_Reservation>;
  /** An aggregate relationship */
  manual_reservations_aggregate: Manual_Reservation_Aggregate;
  partner_email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
}


/** columns and relationships of "manual_patient" */
export type Manual_PatientManual_ReservationsArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


/** columns and relationships of "manual_patient" */
export type Manual_PatientManual_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};

/** aggregated selection of "manual_patient" */
export interface Manual_Patient_Aggregate {
  __typename?: 'manual_patient_aggregate';
  aggregate?: Maybe<Manual_Patient_Aggregate_Fields>;
  nodes: Array<Manual_Patient>;
}

/** aggregate fields of "manual_patient" */
export interface Manual_Patient_Aggregate_Fields {
  __typename?: 'manual_patient_aggregate_fields';
  avg?: Maybe<Manual_Patient_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Manual_Patient_Max_Fields>;
  min?: Maybe<Manual_Patient_Min_Fields>;
  stddev?: Maybe<Manual_Patient_Stddev_Fields>;
  stddev_pop?: Maybe<Manual_Patient_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Manual_Patient_Stddev_Samp_Fields>;
  sum?: Maybe<Manual_Patient_Sum_Fields>;
  var_pop?: Maybe<Manual_Patient_Var_Pop_Fields>;
  var_samp?: Maybe<Manual_Patient_Var_Samp_Fields>;
  variance?: Maybe<Manual_Patient_Variance_Fields>;
}


/** aggregate fields of "manual_patient" */
export type Manual_Patient_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Manual_Patient_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Manual_Patient_Avg_Fields {
  __typename?: 'manual_patient_avg_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "manual_patient". All fields are combined with a logical 'AND'. */
export interface Manual_Patient_Bool_Exp {
  _and?: Maybe<Array<Manual_Patient_Bool_Exp>>;
  _not?: Maybe<Manual_Patient_Bool_Exp>;
  _or?: Maybe<Array<Manual_Patient_Bool_Exp>>;
  caregiver_email?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  hospital?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  manual_reservations?: Maybe<Manual_Reservation_Bool_Exp>;
  manual_reservations_aggregate?: Maybe<Manual_Reservation_Aggregate_Bool_Exp>;
  partner_email?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  project_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "manual_patient" */
export enum Manual_Patient_Constraint {
  /** unique or primary key constraint on columns "email" */
  ManualPatientEmailKey = 'manual_patient_email_key',
  /** unique or primary key constraint on columns "id" */
  ManualPatientIdKey = 'manual_patient_id_key',
  /** unique or primary key constraint on columns "id" */
  ManualPatientPkey = 'manual_patient_pkey'
}

/** input type for incrementing numeric columns in table "manual_patient" */
export interface Manual_Patient_Inc_Input {
  id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "manual_patient" */
export interface Manual_Patient_Insert_Input {
  caregiver_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  manual_reservations?: Maybe<Manual_Reservation_Arr_Rel_Insert_Input>;
  partner_email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Manual_Patient_Max_Fields {
  __typename?: 'manual_patient_max_fields';
  caregiver_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  partner_email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Manual_Patient_Min_Fields {
  __typename?: 'manual_patient_min_fields';
  caregiver_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  partner_email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "manual_patient" */
export interface Manual_Patient_Mutation_Response {
  __typename?: 'manual_patient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Manual_Patient>;
}

/** input type for inserting object relation for remote table "manual_patient" */
export interface Manual_Patient_Obj_Rel_Insert_Input {
  data: Manual_Patient_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Manual_Patient_On_Conflict>;
}

/** on_conflict condition type for table "manual_patient" */
export interface Manual_Patient_On_Conflict {
  constraint: Manual_Patient_Constraint;
  update_columns?: Array<Manual_Patient_Update_Column>;
  where?: Maybe<Manual_Patient_Bool_Exp>;
}

/** Ordering options when selecting data from "manual_patient". */
export interface Manual_Patient_Order_By {
  caregiver_email?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  hospital?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  manual_reservations_aggregate?: Maybe<Manual_Reservation_Aggregate_Order_By>;
  partner_email?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** primary key columns input for table: manual_patient */
export interface Manual_Patient_Pk_Columns_Input {
  id: Scalars['bigint'];
}

/** select columns of table "manual_patient" */
export enum Manual_Patient_Select_Column {
  /** column name */
  CaregiverEmail = 'caregiver_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Hospital = 'hospital',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PartnerEmail = 'partner_email',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "manual_patient" */
export interface Manual_Patient_Set_Input {
  caregiver_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  partner_email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface Manual_Patient_Stddev_Fields {
  __typename?: 'manual_patient_stddev_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Manual_Patient_Stddev_Pop_Fields {
  __typename?: 'manual_patient_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Manual_Patient_Stddev_Samp_Fields {
  __typename?: 'manual_patient_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "manual_patient" */
export interface Manual_Patient_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Manual_Patient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Manual_Patient_Stream_Cursor_Value_Input {
  caregiver_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  partner_email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate sum on columns */
export interface Manual_Patient_Sum_Fields {
  __typename?: 'manual_patient_sum_fields';
  id?: Maybe<Scalars['bigint']>;
}

/** update columns of table "manual_patient" */
export enum Manual_Patient_Update_Column {
  /** column name */
  CaregiverEmail = 'caregiver_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Hospital = 'hospital',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PartnerEmail = 'partner_email',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Manual_Patient_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Manual_Patient_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Manual_Patient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Manual_Patient_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Manual_Patient_Var_Pop_Fields {
  __typename?: 'manual_patient_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Manual_Patient_Var_Samp_Fields {
  __typename?: 'manual_patient_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Manual_Patient_Variance_Fields {
  __typename?: 'manual_patient_variance_fields';
  id?: Maybe<Scalars['Float']>;
}

/** Database support for B2B flows. Reference: https://app.clickup.com/t/ez3mmf . Might be temporary - 18. 3. 2021 */
export interface Manual_Reservation {
  __typename?: 'manual_reservation';
  /** Tracks changes for each `manual_reservation` record */
  activity_log: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  /** Id from one of the values in `manual_driver` table. */
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Added by customer care at the end of the service. */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Might be empty in the first iterations. Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['numeric']>;
  /** Used for toggling views on the react-admin frontend. */
  has_preferred_driver?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  is_two_way?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** An object relationship */
  manual_driver?: Maybe<Manual_Driver>;
  /** An object relationship */
  manual_driver_preferred?: Maybe<Manual_Driver>;
  /** An object relationship */
  manual_patient?: Maybe<Manual_Patient>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service?: Maybe<Scalars['String']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service_city?: Maybe<Scalars['String']>;
  /** Added by customer care at the end of the service. */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
}


/** Database support for B2B flows. Reference: https://app.clickup.com/t/ez3mmf . Might be temporary - 18. 3. 2021 */
export type Manual_ReservationActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Database support for B2B flows. Reference: https://app.clickup.com/t/ez3mmf . Might be temporary - 18. 3. 2021 */
export type Manual_ReservationReservee_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "manual_reservation" */
export interface Manual_Reservation_Aggregate {
  __typename?: 'manual_reservation_aggregate';
  aggregate?: Maybe<Manual_Reservation_Aggregate_Fields>;
  nodes: Array<Manual_Reservation>;
}

export interface Manual_Reservation_Aggregate_Bool_Exp {
  bool_and?: Maybe<Manual_Reservation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Manual_Reservation_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Manual_Reservation_Aggregate_Bool_Exp_Count>;
}

export interface Manual_Reservation_Aggregate_Bool_Exp_Bool_And {
  arguments: Manual_Reservation_Select_Column_Manual_Reservation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Manual_Reservation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Manual_Reservation_Aggregate_Bool_Exp_Bool_Or {
  arguments: Manual_Reservation_Select_Column_Manual_Reservation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Manual_Reservation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Manual_Reservation_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Manual_Reservation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Manual_Reservation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "manual_reservation" */
export interface Manual_Reservation_Aggregate_Fields {
  __typename?: 'manual_reservation_aggregate_fields';
  avg?: Maybe<Manual_Reservation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Manual_Reservation_Max_Fields>;
  min?: Maybe<Manual_Reservation_Min_Fields>;
  stddev?: Maybe<Manual_Reservation_Stddev_Fields>;
  stddev_pop?: Maybe<Manual_Reservation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Manual_Reservation_Stddev_Samp_Fields>;
  sum?: Maybe<Manual_Reservation_Sum_Fields>;
  var_pop?: Maybe<Manual_Reservation_Var_Pop_Fields>;
  var_samp?: Maybe<Manual_Reservation_Var_Samp_Fields>;
  variance?: Maybe<Manual_Reservation_Variance_Fields>;
}


/** aggregate fields of "manual_reservation" */
export type Manual_Reservation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Manual_Reservation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "manual_reservation" */
export interface Manual_Reservation_Aggregate_Order_By {
  avg?: Maybe<Manual_Reservation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Manual_Reservation_Max_Order_By>;
  min?: Maybe<Manual_Reservation_Min_Order_By>;
  stddev?: Maybe<Manual_Reservation_Stddev_Order_By>;
  stddev_pop?: Maybe<Manual_Reservation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Manual_Reservation_Stddev_Samp_Order_By>;
  sum?: Maybe<Manual_Reservation_Sum_Order_By>;
  var_pop?: Maybe<Manual_Reservation_Var_Pop_Order_By>;
  var_samp?: Maybe<Manual_Reservation_Var_Samp_Order_By>;
  variance?: Maybe<Manual_Reservation_Variance_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Manual_Reservation_Append_Input {
  /** Tracks changes for each `manual_reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
}

/** input type for inserting array relation for remote table "manual_reservation" */
export interface Manual_Reservation_Arr_Rel_Insert_Input {
  data: Array<Manual_Reservation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Manual_Reservation_On_Conflict>;
}

/** aggregate avg on columns */
export interface Manual_Reservation_Avg_Fields {
  __typename?: 'manual_reservation_avg_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "manual_reservation" */
export interface Manual_Reservation_Avg_Order_By {
  distance_billable?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "manual_reservation". All fields are combined with a logical 'AND'. */
export interface Manual_Reservation_Bool_Exp {
  _and?: Maybe<Array<Manual_Reservation_Bool_Exp>>;
  _not?: Maybe<Manual_Reservation_Bool_Exp>;
  _or?: Maybe<Array<Manual_Reservation_Bool_Exp>>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<String_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  departure_address?: Maybe<String_Comparison_Exp>;
  departure_lat?: Maybe<String_Comparison_Exp>;
  departure_lng?: Maybe<String_Comparison_Exp>;
  destination_address?: Maybe<String_Comparison_Exp>;
  destination_lat?: Maybe<String_Comparison_Exp>;
  destination_lng?: Maybe<String_Comparison_Exp>;
  distance_billable?: Maybe<Numeric_Comparison_Exp>;
  driver_id?: Maybe<Uuid_Comparison_Exp>;
  driver_preferred_id?: Maybe<Uuid_Comparison_Exp>;
  duration_billable?: Maybe<Numeric_Comparison_Exp>;
  duration_estimate?: Maybe<Numeric_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  ends_at?: Maybe<Timestamptz_Comparison_Exp>;
  extra_cost?: Maybe<Numeric_Comparison_Exp>;
  has_preferred_driver?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_two_way?: Maybe<Boolean_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  manual_driver?: Maybe<Manual_Driver_Bool_Exp>;
  manual_driver_preferred?: Maybe<Manual_Driver_Bool_Exp>;
  manual_patient?: Maybe<Manual_Patient_Bool_Exp>;
  market_segment?: Maybe<String_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  preferred_driver_action?: Maybe<String_Comparison_Exp>;
  price_billable?: Maybe<Numeric_Comparison_Exp>;
  price_estimate?: Maybe<Numeric_Comparison_Exp>;
  project_name?: Maybe<String_Comparison_Exp>;
  reservee_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservor_id?: Maybe<Bigint_Comparison_Exp>;
  service?: Maybe<String_Comparison_Exp>;
  service_city?: Maybe<String_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  starts_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "manual_reservation" */
export enum Manual_Reservation_Constraint {
  /** unique or primary key constraint on columns "id" */
  ManualReservationIdKey = 'manual_reservation_id_key',
  /** unique or primary key constraint on columns "id" */
  ManualReservationPkey = 'manual_reservation_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Manual_Reservation_Delete_At_Path_Input {
  /** Tracks changes for each `manual_reservation` record */
  activity_log?: Maybe<Array<Scalars['String']>>;
  reservee_metadata?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Manual_Reservation_Delete_Elem_Input {
  /** Tracks changes for each `manual_reservation` record */
  activity_log?: Maybe<Scalars['Int']>;
  reservee_metadata?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Manual_Reservation_Delete_Key_Input {
  /** Tracks changes for each `manual_reservation` record */
  activity_log?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "manual_reservation" */
export interface Manual_Reservation_Inc_Input {
  distance_billable?: Maybe<Scalars['numeric']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  reservor_id?: Maybe<Scalars['bigint']>;
}

/** input type for inserting data into table "manual_reservation" */
export interface Manual_Reservation_Insert_Input {
  /** Tracks changes for each `manual_reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  /** Id from one of the values in `manual_driver` table. */
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Added by customer care at the end of the service. */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Might be empty in the first iterations. Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['numeric']>;
  /** Used for toggling views on the react-admin frontend. */
  has_preferred_driver?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_two_way?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  manual_driver?: Maybe<Manual_Driver_Obj_Rel_Insert_Input>;
  manual_driver_preferred?: Maybe<Manual_Driver_Obj_Rel_Insert_Input>;
  manual_patient?: Maybe<Manual_Patient_Obj_Rel_Insert_Input>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service?: Maybe<Scalars['String']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service_city?: Maybe<Scalars['String']>;
  /** Added by customer care at the end of the service. */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Manual_Reservation_Max_Fields {
  __typename?: 'manual_reservation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  /** Id from one of the values in `manual_driver` table. */
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Added by customer care at the end of the service. */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Might be empty in the first iterations. Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service?: Maybe<Scalars['String']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service_city?: Maybe<Scalars['String']>;
  /** Added by customer care at the end of the service. */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "manual_reservation" */
export interface Manual_Reservation_Max_Order_By {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  departure_address?: Maybe<Order_By>;
  departure_lat?: Maybe<Order_By>;
  departure_lng?: Maybe<Order_By>;
  destination_address?: Maybe<Order_By>;
  destination_lat?: Maybe<Order_By>;
  destination_lng?: Maybe<Order_By>;
  distance_billable?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  /** Id from one of the values in `manual_driver` table. */
  driver_preferred_id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** Added by customer care at the end of the service. */
  ended_at?: Maybe<Order_By>;
  /** Might be empty in the first iterations. Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  ends_at?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Order_By>;
  market_segment?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  preferred_driver_action?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service?: Maybe<Order_By>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service_city?: Maybe<Order_By>;
  /** Added by customer care at the end of the service. */
  started_at?: Maybe<Order_By>;
  /** Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Manual_Reservation_Min_Fields {
  __typename?: 'manual_reservation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  /** Id from one of the values in `manual_driver` table. */
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Added by customer care at the end of the service. */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Might be empty in the first iterations. Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service?: Maybe<Scalars['String']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service_city?: Maybe<Scalars['String']>;
  /** Added by customer care at the end of the service. */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "manual_reservation" */
export interface Manual_Reservation_Min_Order_By {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  departure_address?: Maybe<Order_By>;
  departure_lat?: Maybe<Order_By>;
  departure_lng?: Maybe<Order_By>;
  destination_address?: Maybe<Order_By>;
  destination_lat?: Maybe<Order_By>;
  destination_lng?: Maybe<Order_By>;
  distance_billable?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  /** Id from one of the values in `manual_driver` table. */
  driver_preferred_id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** Added by customer care at the end of the service. */
  ended_at?: Maybe<Order_By>;
  /** Might be empty in the first iterations. Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  ends_at?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Order_By>;
  market_segment?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  preferred_driver_action?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service?: Maybe<Order_By>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service_city?: Maybe<Order_By>;
  /** Added by customer care at the end of the service. */
  started_at?: Maybe<Order_By>;
  /** Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** response of any mutation on the table "manual_reservation" */
export interface Manual_Reservation_Mutation_Response {
  __typename?: 'manual_reservation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Manual_Reservation>;
}

/** on_conflict condition type for table "manual_reservation" */
export interface Manual_Reservation_On_Conflict {
  constraint: Manual_Reservation_Constraint;
  update_columns?: Array<Manual_Reservation_Update_Column>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
}

/** Ordering options when selecting data from "manual_reservation". */
export interface Manual_Reservation_Order_By {
  activity_log?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  departure_address?: Maybe<Order_By>;
  departure_lat?: Maybe<Order_By>;
  departure_lng?: Maybe<Order_By>;
  destination_address?: Maybe<Order_By>;
  destination_lat?: Maybe<Order_By>;
  destination_lng?: Maybe<Order_By>;
  distance_billable?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  driver_preferred_id?: Maybe<Order_By>;
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  extra_cost?: Maybe<Order_By>;
  has_preferred_driver?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_two_way?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  manual_driver?: Maybe<Manual_Driver_Order_By>;
  manual_driver_preferred?: Maybe<Manual_Driver_Order_By>;
  manual_patient?: Maybe<Manual_Patient_Order_By>;
  market_segment?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  preferred_driver_action?: Maybe<Order_By>;
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  reservee_metadata?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
  service?: Maybe<Order_By>;
  service_city?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** primary key columns input for table: manual_reservation */
export interface Manual_Reservation_Pk_Columns_Input {
  id: Scalars['bigint'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Manual_Reservation_Prepend_Input {
  /** Tracks changes for each `manual_reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "manual_reservation" */
export enum Manual_Reservation_Select_Column {
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  DepartureAddress = 'departure_address',
  /** column name */
  DepartureLat = 'departure_lat',
  /** column name */
  DepartureLng = 'departure_lng',
  /** column name */
  DestinationAddress = 'destination_address',
  /** column name */
  DestinationLat = 'destination_lat',
  /** column name */
  DestinationLng = 'destination_lng',
  /** column name */
  DistanceBillable = 'distance_billable',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverPreferredId = 'driver_preferred_id',
  /** column name */
  DurationBillable = 'duration_billable',
  /** column name */
  DurationEstimate = 'duration_estimate',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraCost = 'extra_cost',
  /** column name */
  HasPreferredDriver = 'has_preferred_driver',
  /** column name */
  Id = 'id',
  /** column name */
  IsTwoWay = 'is_two_way',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  MarketSegment = 'market_segment',
  /** column name */
  Note = 'note',
  /** column name */
  PreferredDriverAction = 'preferred_driver_action',
  /** column name */
  PriceBillable = 'price_billable',
  /** column name */
  PriceEstimate = 'price_estimate',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  ReserveeMetadata = 'reservee_metadata',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  Service = 'service',
  /** column name */
  ServiceCity = 'service_city',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "manual_reservation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "manual_reservation" */
export enum Manual_Reservation_Select_Column_Manual_Reservation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasPreferredDriver = 'has_preferred_driver',
  /** column name */
  IsTwoWay = 'is_two_way'
}

/** select "manual_reservation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "manual_reservation" */
export enum Manual_Reservation_Select_Column_Manual_Reservation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasPreferredDriver = 'has_preferred_driver',
  /** column name */
  IsTwoWay = 'is_two_way'
}

/** input type for updating data in table "manual_reservation" */
export interface Manual_Reservation_Set_Input {
  /** Tracks changes for each `manual_reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  /** Id from one of the values in `manual_driver` table. */
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Added by customer care at the end of the service. */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Might be empty in the first iterations. Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['numeric']>;
  /** Used for toggling views on the react-admin frontend. */
  has_preferred_driver?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_two_way?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service?: Maybe<Scalars['String']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service_city?: Maybe<Scalars['String']>;
  /** Added by customer care at the end of the service. */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface Manual_Reservation_Stddev_Fields {
  __typename?: 'manual_reservation_stddev_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "manual_reservation" */
export interface Manual_Reservation_Stddev_Order_By {
  distance_billable?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Manual_Reservation_Stddev_Pop_Fields {
  __typename?: 'manual_reservation_stddev_pop_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "manual_reservation" */
export interface Manual_Reservation_Stddev_Pop_Order_By {
  distance_billable?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Manual_Reservation_Stddev_Samp_Fields {
  __typename?: 'manual_reservation_stddev_samp_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "manual_reservation" */
export interface Manual_Reservation_Stddev_Samp_Order_By {
  distance_billable?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
}

/** Streaming cursor of the table "manual_reservation" */
export interface Manual_Reservation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Manual_Reservation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Manual_Reservation_Stream_Cursor_Value_Input {
  /** Tracks changes for each `manual_reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  /** Id from one of the values in `manual_driver` table. */
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Added by customer care at the end of the service. */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Might be empty in the first iterations. Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['numeric']>;
  /** Used for toggling views on the react-admin frontend. */
  has_preferred_driver?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_two_way?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service?: Maybe<Scalars['String']>;
  /** Values are passed from the ugo-admin frontend. They might or might not be the same as `service` table. */
  service_city?: Maybe<Scalars['String']>;
  /** Added by customer care at the end of the service. */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Only `starts_at` and `duration` are supplied at the beginning. - 22. 3. 2021 */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate sum on columns */
export interface Manual_Reservation_Sum_Fields {
  __typename?: 'manual_reservation_sum_fields';
  distance_billable?: Maybe<Scalars['numeric']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  reservor_id?: Maybe<Scalars['bigint']>;
}

/** order by sum() on columns of table "manual_reservation" */
export interface Manual_Reservation_Sum_Order_By {
  distance_billable?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
}

/** update columns of table "manual_reservation" */
export enum Manual_Reservation_Update_Column {
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  DepartureAddress = 'departure_address',
  /** column name */
  DepartureLat = 'departure_lat',
  /** column name */
  DepartureLng = 'departure_lng',
  /** column name */
  DestinationAddress = 'destination_address',
  /** column name */
  DestinationLat = 'destination_lat',
  /** column name */
  DestinationLng = 'destination_lng',
  /** column name */
  DistanceBillable = 'distance_billable',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverPreferredId = 'driver_preferred_id',
  /** column name */
  DurationBillable = 'duration_billable',
  /** column name */
  DurationEstimate = 'duration_estimate',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraCost = 'extra_cost',
  /** column name */
  HasPreferredDriver = 'has_preferred_driver',
  /** column name */
  Id = 'id',
  /** column name */
  IsTwoWay = 'is_two_way',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  MarketSegment = 'market_segment',
  /** column name */
  Note = 'note',
  /** column name */
  PreferredDriverAction = 'preferred_driver_action',
  /** column name */
  PriceBillable = 'price_billable',
  /** column name */
  PriceEstimate = 'price_estimate',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  ReserveeMetadata = 'reservee_metadata',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  Service = 'service',
  /** column name */
  ServiceCity = 'service_city',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Manual_Reservation_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Manual_Reservation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Manual_Reservation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Manual_Reservation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Manual_Reservation_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Manual_Reservation_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Manual_Reservation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Manual_Reservation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Manual_Reservation_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Manual_Reservation_Var_Pop_Fields {
  __typename?: 'manual_reservation_var_pop_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "manual_reservation" */
export interface Manual_Reservation_Var_Pop_Order_By {
  distance_billable?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Manual_Reservation_Var_Samp_Fields {
  __typename?: 'manual_reservation_var_samp_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "manual_reservation" */
export interface Manual_Reservation_Var_Samp_Order_By {
  distance_billable?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Manual_Reservation_Variance_Fields {
  __typename?: 'manual_reservation_variance_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "manual_reservation" */
export interface Manual_Reservation_Variance_Order_By {
  distance_billable?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  /** This is a price value. It's provided by the driver. Takes the same currency as price_estimate into account. */
  extra_cost?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Not in use ATM (21. 4. 2021), might be in use in the future. */
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
}

/** mutation root */
export interface Mutation_Root {
  __typename?: 'mutation_root';
  acceptManualReservation?: Maybe<AcceptManualReservationOutput>;
  activateAdministrator?: Maybe<ActivateAdministratorOutput>;
  activateDriver?: Maybe<DriverActivationOutput>;
  addPaymentMethod?: Maybe<AddPaymentMethodOutput>;
  addUserToSendinblueList?: Maybe<AddUserToSendinblueListOutput>;
  calculateTransactionFees?: Maybe<CalculateTransactionFeesOutput>;
  cancelReservation?: Maybe<CancelReservationOutput>;
  /** changeEmail */
  changeEmail?: Maybe<EmailChangeOutput>;
  checkAuthStatus?: Maybe<CheckAuthStatusOutput>;
  createAccount?: Maybe<CreateAccountOutput>;
  createQuote?: Maybe<CreateQuoteOutput>;
  /** deactivateDriver */
  deactivateDriver?: Maybe<DeactivateDriverOutput>;
  declineManualReservation?: Maybe<DeclineManualReservationOutput>;
  /** delete data from the table: "account" */
  delete_account?: Maybe<Account_Mutation_Response>;
  /** delete single row from the table: "account" */
  delete_account_by_pk?: Maybe<Account>;
  /** delete data from the table: "account_error" */
  delete_account_error?: Maybe<Account_Error_Mutation_Response>;
  /** delete single row from the table: "account_error" */
  delete_account_error_by_pk?: Maybe<Account_Error>;
  /** delete data from the table: "account_error_type" */
  delete_account_error_type?: Maybe<Account_Error_Type_Mutation_Response>;
  /** delete single row from the table: "account_error_type" */
  delete_account_error_type_by_pk?: Maybe<Account_Error_Type>;
  /** delete data from the table: "account_feedback" */
  delete_account_feedback?: Maybe<Account_Feedback_Mutation_Response>;
  /** delete single row from the table: "account_feedback" */
  delete_account_feedback_by_pk?: Maybe<Account_Feedback>;
  /** delete data from the table: "administrator_profile" */
  delete_administrator_profile?: Maybe<Administrator_Profile_Mutation_Response>;
  /** delete single row from the table: "administrator_profile" */
  delete_administrator_profile_by_pk?: Maybe<Administrator_Profile>;
  /** delete data from the table: "carvoila.district" */
  delete_carvoila_district?: Maybe<Carvoila_District_Mutation_Response>;
  /** delete single row from the table: "carvoila.district" */
  delete_carvoila_district_by_pk?: Maybe<Carvoila_District>;
  /** delete data from the table: "carvoila.driver" */
  delete_carvoila_driver?: Maybe<Carvoila_Driver_Mutation_Response>;
  /** delete single row from the table: "carvoila.driver" */
  delete_carvoila_driver_by_pk?: Maybe<Carvoila_Driver>;
  /** delete data from the table: "carvoila.driver_status" */
  delete_carvoila_driver_status?: Maybe<Carvoila_Driver_Status_Mutation_Response>;
  /** delete single row from the table: "carvoila.driver_status" */
  delete_carvoila_driver_status_by_pk?: Maybe<Carvoila_Driver_Status>;
  /** delete data from the table: "city" */
  delete_city?: Maybe<City_Mutation_Response>;
  /** delete single row from the table: "city" */
  delete_city_by_pk?: Maybe<City>;
  /** delete data from the table: "deployment" */
  delete_deployment?: Maybe<Deployment_Mutation_Response>;
  /** delete single row from the table: "deployment" */
  delete_deployment_by_pk?: Maybe<Deployment>;
  /** delete data from the table: "deployment_type" */
  delete_deployment_type?: Maybe<Deployment_Type_Mutation_Response>;
  /** delete single row from the table: "deployment_type" */
  delete_deployment_type_by_pk?: Maybe<Deployment_Type>;
  /** delete data from the table: "driver_profile" */
  delete_driver_profile?: Maybe<Driver_Profile_Mutation_Response>;
  /** delete single row from the table: "driver_profile" */
  delete_driver_profile_by_pk?: Maybe<Driver_Profile>;
  /** delete data from the table: "driver_status" */
  delete_driver_status?: Maybe<Driver_Status_Mutation_Response>;
  /** delete single row from the table: "driver_status" */
  delete_driver_status_by_pk?: Maybe<Driver_Status>;
  /** delete data from the table: "manual_driver" */
  delete_manual_driver?: Maybe<Manual_Driver_Mutation_Response>;
  /** delete single row from the table: "manual_driver" */
  delete_manual_driver_by_pk?: Maybe<Manual_Driver>;
  /** delete data from the table: "manual_patient" */
  delete_manual_patient?: Maybe<Manual_Patient_Mutation_Response>;
  /** delete single row from the table: "manual_patient" */
  delete_manual_patient_by_pk?: Maybe<Manual_Patient>;
  /** delete data from the table: "manual_reservation" */
  delete_manual_reservation?: Maybe<Manual_Reservation_Mutation_Response>;
  /** delete single row from the table: "manual_reservation" */
  delete_manual_reservation_by_pk?: Maybe<Manual_Reservation>;
  /** delete data from the table: "patient_profile" */
  delete_patient_profile?: Maybe<Patient_Profile_Mutation_Response>;
  /** delete single row from the table: "patient_profile" */
  delete_patient_profile_by_pk?: Maybe<Patient_Profile>;
  /** delete data from the table: "payment" */
  delete_payment?: Maybe<Payment_Mutation_Response>;
  /** delete single row from the table: "payment" */
  delete_payment_by_pk?: Maybe<Payment>;
  /** delete data from the table: "payment_method" */
  delete_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** delete single row from the table: "payment_method" */
  delete_payment_method_by_pk?: Maybe<Payment_Method>;
  /** delete data from the table: "reservation" */
  delete_reservation?: Maybe<Reservation_Mutation_Response>;
  /** delete single row from the table: "reservation" */
  delete_reservation_by_pk?: Maybe<Reservation>;
  /** delete data from the table: "reservation_change_request_action" */
  delete_reservation_change_request_action?: Maybe<Reservation_Change_Request_Action_Mutation_Response>;
  /** delete single row from the table: "reservation_change_request_action" */
  delete_reservation_change_request_action_by_pk?: Maybe<Reservation_Change_Request_Action>;
  /** delete data from the table: "reservation_status" */
  delete_reservation_status?: Maybe<Reservation_Status_Mutation_Response>;
  /** delete single row from the table: "reservation_status" */
  delete_reservation_status_by_pk?: Maybe<Reservation_Status>;
  /** delete data from the table: "review" */
  delete_review?: Maybe<Review_Mutation_Response>;
  /** delete single row from the table: "review" */
  delete_review_by_pk?: Maybe<Review>;
  /** delete data from the table: "service" */
  delete_service?: Maybe<Service_Mutation_Response>;
  /** delete single row from the table: "service" */
  delete_service_by_pk?: Maybe<Service>;
  /** delete data from the table: "sync" */
  delete_sync?: Maybe<Sync_Mutation_Response>;
  /** delete single row from the table: "sync" */
  delete_sync_by_pk?: Maybe<Sync>;
  /** delete data from the table: "voucher" */
  delete_voucher?: Maybe<Voucher_Mutation_Response>;
  /** delete single row from the table: "voucher" */
  delete_voucher_by_pk?: Maybe<Voucher>;
  /** delete data from the table: "voucher_redemption" */
  delete_voucher_redemption?: Maybe<Voucher_Redemption_Mutation_Response>;
  /** delete single row from the table: "voucher_redemption" */
  delete_voucher_redemption_by_pk?: Maybe<Voucher_Redemption>;
  /** delete data from the table: "vw_admin_100_percent_discount_reservations" */
  delete_vw_admin_100_percent_discount_reservations?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Mutation_Response>;
  /** delete data from the table: "vw_admin_cancelled_reservation" */
  delete_vw_admin_cancelled_reservation?: Maybe<Vw_Admin_Cancelled_Reservation_Mutation_Response>;
  /** delete data from the table: "vw_admin_reservation" */
  delete_vw_admin_reservation?: Maybe<Vw_Admin_Reservation_Mutation_Response>;
  driverAcceptReservationChange?: Maybe<DriverAcceptReservationChangeOutput>;
  driverRejectReservationChange?: Maybe<DriverRejectReservationChangeOutput>;
  finishReservation?: Maybe<FinishReservationOutput>;
  /** insert data into the table: "account" */
  insert_account?: Maybe<Account_Mutation_Response>;
  /** insert data into the table: "account_error" */
  insert_account_error?: Maybe<Account_Error_Mutation_Response>;
  /** insert a single row into the table: "account_error" */
  insert_account_error_one?: Maybe<Account_Error>;
  /** insert data into the table: "account_error_type" */
  insert_account_error_type?: Maybe<Account_Error_Type_Mutation_Response>;
  /** insert a single row into the table: "account_error_type" */
  insert_account_error_type_one?: Maybe<Account_Error_Type>;
  /** insert data into the table: "account_feedback" */
  insert_account_feedback?: Maybe<Account_Feedback_Mutation_Response>;
  /** insert a single row into the table: "account_feedback" */
  insert_account_feedback_one?: Maybe<Account_Feedback>;
  /** insert a single row into the table: "account" */
  insert_account_one?: Maybe<Account>;
  /** insert data into the table: "administrator_profile" */
  insert_administrator_profile?: Maybe<Administrator_Profile_Mutation_Response>;
  /** insert a single row into the table: "administrator_profile" */
  insert_administrator_profile_one?: Maybe<Administrator_Profile>;
  /** insert data into the table: "carvoila.district" */
  insert_carvoila_district?: Maybe<Carvoila_District_Mutation_Response>;
  /** insert a single row into the table: "carvoila.district" */
  insert_carvoila_district_one?: Maybe<Carvoila_District>;
  /** insert data into the table: "carvoila.driver" */
  insert_carvoila_driver?: Maybe<Carvoila_Driver_Mutation_Response>;
  /** insert a single row into the table: "carvoila.driver" */
  insert_carvoila_driver_one?: Maybe<Carvoila_Driver>;
  /** insert data into the table: "carvoila.driver_status" */
  insert_carvoila_driver_status?: Maybe<Carvoila_Driver_Status_Mutation_Response>;
  /** insert a single row into the table: "carvoila.driver_status" */
  insert_carvoila_driver_status_one?: Maybe<Carvoila_Driver_Status>;
  /** insert data into the table: "city" */
  insert_city?: Maybe<City_Mutation_Response>;
  /** insert a single row into the table: "city" */
  insert_city_one?: Maybe<City>;
  /** insert data into the table: "deployment" */
  insert_deployment?: Maybe<Deployment_Mutation_Response>;
  /** insert a single row into the table: "deployment" */
  insert_deployment_one?: Maybe<Deployment>;
  /** insert data into the table: "deployment_type" */
  insert_deployment_type?: Maybe<Deployment_Type_Mutation_Response>;
  /** insert a single row into the table: "deployment_type" */
  insert_deployment_type_one?: Maybe<Deployment_Type>;
  /** insert data into the table: "driver_profile" */
  insert_driver_profile?: Maybe<Driver_Profile_Mutation_Response>;
  /** insert a single row into the table: "driver_profile" */
  insert_driver_profile_one?: Maybe<Driver_Profile>;
  /** insert data into the table: "driver_status" */
  insert_driver_status?: Maybe<Driver_Status_Mutation_Response>;
  /** insert a single row into the table: "driver_status" */
  insert_driver_status_one?: Maybe<Driver_Status>;
  /** insert data into the table: "manual_driver" */
  insert_manual_driver?: Maybe<Manual_Driver_Mutation_Response>;
  /** insert a single row into the table: "manual_driver" */
  insert_manual_driver_one?: Maybe<Manual_Driver>;
  /** insert data into the table: "manual_patient" */
  insert_manual_patient?: Maybe<Manual_Patient_Mutation_Response>;
  /** insert a single row into the table: "manual_patient" */
  insert_manual_patient_one?: Maybe<Manual_Patient>;
  /** insert data into the table: "manual_reservation" */
  insert_manual_reservation?: Maybe<Manual_Reservation_Mutation_Response>;
  /** insert a single row into the table: "manual_reservation" */
  insert_manual_reservation_one?: Maybe<Manual_Reservation>;
  /** insert data into the table: "patient_profile" */
  insert_patient_profile?: Maybe<Patient_Profile_Mutation_Response>;
  /** insert a single row into the table: "patient_profile" */
  insert_patient_profile_one?: Maybe<Patient_Profile>;
  /** insert data into the table: "payment" */
  insert_payment?: Maybe<Payment_Mutation_Response>;
  /** insert data into the table: "payment_method" */
  insert_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** insert a single row into the table: "payment_method" */
  insert_payment_method_one?: Maybe<Payment_Method>;
  /** insert a single row into the table: "payment" */
  insert_payment_one?: Maybe<Payment>;
  /** insert data into the table: "reservation" */
  insert_reservation?: Maybe<Reservation_Mutation_Response>;
  /** insert data into the table: "reservation_change_request_action" */
  insert_reservation_change_request_action?: Maybe<Reservation_Change_Request_Action_Mutation_Response>;
  /** insert a single row into the table: "reservation_change_request_action" */
  insert_reservation_change_request_action_one?: Maybe<Reservation_Change_Request_Action>;
  /** insert a single row into the table: "reservation" */
  insert_reservation_one?: Maybe<Reservation>;
  /** insert data into the table: "reservation_status" */
  insert_reservation_status?: Maybe<Reservation_Status_Mutation_Response>;
  /** insert a single row into the table: "reservation_status" */
  insert_reservation_status_one?: Maybe<Reservation_Status>;
  /** insert data into the table: "review" */
  insert_review?: Maybe<Review_Mutation_Response>;
  /** insert a single row into the table: "review" */
  insert_review_one?: Maybe<Review>;
  /** insert data into the table: "service" */
  insert_service?: Maybe<Service_Mutation_Response>;
  /** insert a single row into the table: "service" */
  insert_service_one?: Maybe<Service>;
  /** insert data into the table: "sync" */
  insert_sync?: Maybe<Sync_Mutation_Response>;
  /** insert a single row into the table: "sync" */
  insert_sync_one?: Maybe<Sync>;
  /** insert data into the table: "voucher" */
  insert_voucher?: Maybe<Voucher_Mutation_Response>;
  /** insert a single row into the table: "voucher" */
  insert_voucher_one?: Maybe<Voucher>;
  /** insert data into the table: "voucher_redemption" */
  insert_voucher_redemption?: Maybe<Voucher_Redemption_Mutation_Response>;
  /** insert a single row into the table: "voucher_redemption" */
  insert_voucher_redemption_one?: Maybe<Voucher_Redemption>;
  /** insert data into the table: "vw_admin_100_percent_discount_reservations" */
  insert_vw_admin_100_percent_discount_reservations?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Mutation_Response>;
  /** insert a single row into the table: "vw_admin_100_percent_discount_reservations" */
  insert_vw_admin_100_percent_discount_reservations_one?: Maybe<Vw_Admin_100_Percent_Discount_Reservations>;
  /** insert data into the table: "vw_admin_cancelled_reservation" */
  insert_vw_admin_cancelled_reservation?: Maybe<Vw_Admin_Cancelled_Reservation_Mutation_Response>;
  /** insert a single row into the table: "vw_admin_cancelled_reservation" */
  insert_vw_admin_cancelled_reservation_one?: Maybe<Vw_Admin_Cancelled_Reservation>;
  /** insert data into the table: "vw_admin_reservation" */
  insert_vw_admin_reservation?: Maybe<Vw_Admin_Reservation_Mutation_Response>;
  /** insert a single row into the table: "vw_admin_reservation" */
  insert_vw_admin_reservation_one?: Maybe<Vw_Admin_Reservation>;
  onboardDriverToStripe?: Maybe<OnboardDriverToStripeOutput>;
  patientCancelReservation?: Maybe<PatientCancelReservationOutput>;
  patientRequestReservationChange?: Maybe<PatientRequestReservationChangeOutput>;
  removePaymentMethod?: Maybe<RemovePaymentMethodOutput>;
  resendStripeCheckoutSessionPaymentRequest?: Maybe<ResendStripeCheckoutSessionPaymentRequestOutput>;
  resendVerificationEmail?: Maybe<ResendVerificationEmailOutput>;
  selectDefaultPaymentMethod?: Maybe<SelectDefaultPaymentMethodOutput>;
  sendNewManualReservationEmailToAllDrivers?: Maybe<SendNewManualReservationEmailToAllDriversOutput>;
  sendNewManualReservationEmailToPreferredDriver?: Maybe<SendNewManualReservationEmailToPreferredDriverOutput>;
  sendPasswordResetRequest?: Maybe<PasswordResetOutput>;
  signIn?: Maybe<SignInOutput>;
  signOut?: Maybe<SignOutOutput>;
  submitReservationRating?: Maybe<SubmitReservationRatingOutput>;
  syncFirebaseAuthenticationUsersWithDbManually?: Maybe<SyncFirebaseAuthenticationUsersWithDbManuallyOutput>;
  tr?: Maybe<TrOutput>;
  /** update data of the table: "account" */
  update_account?: Maybe<Account_Mutation_Response>;
  /** update single row of the table: "account" */
  update_account_by_pk?: Maybe<Account>;
  /** update data of the table: "account_error" */
  update_account_error?: Maybe<Account_Error_Mutation_Response>;
  /** update single row of the table: "account_error" */
  update_account_error_by_pk?: Maybe<Account_Error>;
  /** update multiples rows of table: "account_error" */
  update_account_error_many?: Maybe<Array<Maybe<Account_Error_Mutation_Response>>>;
  /** update data of the table: "account_error_type" */
  update_account_error_type?: Maybe<Account_Error_Type_Mutation_Response>;
  /** update single row of the table: "account_error_type" */
  update_account_error_type_by_pk?: Maybe<Account_Error_Type>;
  /** update multiples rows of table: "account_error_type" */
  update_account_error_type_many?: Maybe<Array<Maybe<Account_Error_Type_Mutation_Response>>>;
  /** update data of the table: "account_feedback" */
  update_account_feedback?: Maybe<Account_Feedback_Mutation_Response>;
  /** update single row of the table: "account_feedback" */
  update_account_feedback_by_pk?: Maybe<Account_Feedback>;
  /** update multiples rows of table: "account_feedback" */
  update_account_feedback_many?: Maybe<Array<Maybe<Account_Feedback_Mutation_Response>>>;
  /** update multiples rows of table: "account" */
  update_account_many?: Maybe<Array<Maybe<Account_Mutation_Response>>>;
  /** update data of the table: "administrator_profile" */
  update_administrator_profile?: Maybe<Administrator_Profile_Mutation_Response>;
  /** update single row of the table: "administrator_profile" */
  update_administrator_profile_by_pk?: Maybe<Administrator_Profile>;
  /** update multiples rows of table: "administrator_profile" */
  update_administrator_profile_many?: Maybe<Array<Maybe<Administrator_Profile_Mutation_Response>>>;
  /** update data of the table: "carvoila.district" */
  update_carvoila_district?: Maybe<Carvoila_District_Mutation_Response>;
  /** update single row of the table: "carvoila.district" */
  update_carvoila_district_by_pk?: Maybe<Carvoila_District>;
  /** update multiples rows of table: "carvoila.district" */
  update_carvoila_district_many?: Maybe<Array<Maybe<Carvoila_District_Mutation_Response>>>;
  /** update data of the table: "carvoila.driver" */
  update_carvoila_driver?: Maybe<Carvoila_Driver_Mutation_Response>;
  /** update single row of the table: "carvoila.driver" */
  update_carvoila_driver_by_pk?: Maybe<Carvoila_Driver>;
  /** update multiples rows of table: "carvoila.driver" */
  update_carvoila_driver_many?: Maybe<Array<Maybe<Carvoila_Driver_Mutation_Response>>>;
  /** update data of the table: "carvoila.driver_status" */
  update_carvoila_driver_status?: Maybe<Carvoila_Driver_Status_Mutation_Response>;
  /** update single row of the table: "carvoila.driver_status" */
  update_carvoila_driver_status_by_pk?: Maybe<Carvoila_Driver_Status>;
  /** update multiples rows of table: "carvoila.driver_status" */
  update_carvoila_driver_status_many?: Maybe<Array<Maybe<Carvoila_Driver_Status_Mutation_Response>>>;
  /** update data of the table: "city" */
  update_city?: Maybe<City_Mutation_Response>;
  /** update single row of the table: "city" */
  update_city_by_pk?: Maybe<City>;
  /** update multiples rows of table: "city" */
  update_city_many?: Maybe<Array<Maybe<City_Mutation_Response>>>;
  /** update data of the table: "deployment" */
  update_deployment?: Maybe<Deployment_Mutation_Response>;
  /** update single row of the table: "deployment" */
  update_deployment_by_pk?: Maybe<Deployment>;
  /** update multiples rows of table: "deployment" */
  update_deployment_many?: Maybe<Array<Maybe<Deployment_Mutation_Response>>>;
  /** update data of the table: "deployment_type" */
  update_deployment_type?: Maybe<Deployment_Type_Mutation_Response>;
  /** update single row of the table: "deployment_type" */
  update_deployment_type_by_pk?: Maybe<Deployment_Type>;
  /** update multiples rows of table: "deployment_type" */
  update_deployment_type_many?: Maybe<Array<Maybe<Deployment_Type_Mutation_Response>>>;
  /** update data of the table: "driver_profile" */
  update_driver_profile?: Maybe<Driver_Profile_Mutation_Response>;
  /** update single row of the table: "driver_profile" */
  update_driver_profile_by_pk?: Maybe<Driver_Profile>;
  /** update multiples rows of table: "driver_profile" */
  update_driver_profile_many?: Maybe<Array<Maybe<Driver_Profile_Mutation_Response>>>;
  /** update data of the table: "driver_status" */
  update_driver_status?: Maybe<Driver_Status_Mutation_Response>;
  /** update single row of the table: "driver_status" */
  update_driver_status_by_pk?: Maybe<Driver_Status>;
  /** update multiples rows of table: "driver_status" */
  update_driver_status_many?: Maybe<Array<Maybe<Driver_Status_Mutation_Response>>>;
  /** update data of the table: "manual_driver" */
  update_manual_driver?: Maybe<Manual_Driver_Mutation_Response>;
  /** update single row of the table: "manual_driver" */
  update_manual_driver_by_pk?: Maybe<Manual_Driver>;
  /** update multiples rows of table: "manual_driver" */
  update_manual_driver_many?: Maybe<Array<Maybe<Manual_Driver_Mutation_Response>>>;
  /** update data of the table: "manual_patient" */
  update_manual_patient?: Maybe<Manual_Patient_Mutation_Response>;
  /** update single row of the table: "manual_patient" */
  update_manual_patient_by_pk?: Maybe<Manual_Patient>;
  /** update multiples rows of table: "manual_patient" */
  update_manual_patient_many?: Maybe<Array<Maybe<Manual_Patient_Mutation_Response>>>;
  /** update data of the table: "manual_reservation" */
  update_manual_reservation?: Maybe<Manual_Reservation_Mutation_Response>;
  /** update single row of the table: "manual_reservation" */
  update_manual_reservation_by_pk?: Maybe<Manual_Reservation>;
  /** update multiples rows of table: "manual_reservation" */
  update_manual_reservation_many?: Maybe<Array<Maybe<Manual_Reservation_Mutation_Response>>>;
  /** update data of the table: "patient_profile" */
  update_patient_profile?: Maybe<Patient_Profile_Mutation_Response>;
  /** update single row of the table: "patient_profile" */
  update_patient_profile_by_pk?: Maybe<Patient_Profile>;
  /** update multiples rows of table: "patient_profile" */
  update_patient_profile_many?: Maybe<Array<Maybe<Patient_Profile_Mutation_Response>>>;
  /** update data of the table: "payment" */
  update_payment?: Maybe<Payment_Mutation_Response>;
  /** update single row of the table: "payment" */
  update_payment_by_pk?: Maybe<Payment>;
  /** update multiples rows of table: "payment" */
  update_payment_many?: Maybe<Array<Maybe<Payment_Mutation_Response>>>;
  /** update data of the table: "payment_method" */
  update_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** update single row of the table: "payment_method" */
  update_payment_method_by_pk?: Maybe<Payment_Method>;
  /** update multiples rows of table: "payment_method" */
  update_payment_method_many?: Maybe<Array<Maybe<Payment_Method_Mutation_Response>>>;
  /** update data of the table: "reservation" */
  update_reservation?: Maybe<Reservation_Mutation_Response>;
  /** update single row of the table: "reservation" */
  update_reservation_by_pk?: Maybe<Reservation>;
  /** update data of the table: "reservation_change_request_action" */
  update_reservation_change_request_action?: Maybe<Reservation_Change_Request_Action_Mutation_Response>;
  /** update single row of the table: "reservation_change_request_action" */
  update_reservation_change_request_action_by_pk?: Maybe<Reservation_Change_Request_Action>;
  /** update multiples rows of table: "reservation_change_request_action" */
  update_reservation_change_request_action_many?: Maybe<Array<Maybe<Reservation_Change_Request_Action_Mutation_Response>>>;
  /** update multiples rows of table: "reservation" */
  update_reservation_many?: Maybe<Array<Maybe<Reservation_Mutation_Response>>>;
  /** update data of the table: "reservation_status" */
  update_reservation_status?: Maybe<Reservation_Status_Mutation_Response>;
  /** update single row of the table: "reservation_status" */
  update_reservation_status_by_pk?: Maybe<Reservation_Status>;
  /** update multiples rows of table: "reservation_status" */
  update_reservation_status_many?: Maybe<Array<Maybe<Reservation_Status_Mutation_Response>>>;
  /** update data of the table: "review" */
  update_review?: Maybe<Review_Mutation_Response>;
  /** update single row of the table: "review" */
  update_review_by_pk?: Maybe<Review>;
  /** update multiples rows of table: "review" */
  update_review_many?: Maybe<Array<Maybe<Review_Mutation_Response>>>;
  /** update data of the table: "service" */
  update_service?: Maybe<Service_Mutation_Response>;
  /** update single row of the table: "service" */
  update_service_by_pk?: Maybe<Service>;
  /** update multiples rows of table: "service" */
  update_service_many?: Maybe<Array<Maybe<Service_Mutation_Response>>>;
  /** update data of the table: "sync" */
  update_sync?: Maybe<Sync_Mutation_Response>;
  /** update single row of the table: "sync" */
  update_sync_by_pk?: Maybe<Sync>;
  /** update multiples rows of table: "sync" */
  update_sync_many?: Maybe<Array<Maybe<Sync_Mutation_Response>>>;
  /** update data of the table: "voucher" */
  update_voucher?: Maybe<Voucher_Mutation_Response>;
  /** update single row of the table: "voucher" */
  update_voucher_by_pk?: Maybe<Voucher>;
  /** update multiples rows of table: "voucher" */
  update_voucher_many?: Maybe<Array<Maybe<Voucher_Mutation_Response>>>;
  /** update data of the table: "voucher_redemption" */
  update_voucher_redemption?: Maybe<Voucher_Redemption_Mutation_Response>;
  /** update single row of the table: "voucher_redemption" */
  update_voucher_redemption_by_pk?: Maybe<Voucher_Redemption>;
  /** update multiples rows of table: "voucher_redemption" */
  update_voucher_redemption_many?: Maybe<Array<Maybe<Voucher_Redemption_Mutation_Response>>>;
  /** update data of the table: "vw_admin_100_percent_discount_reservations" */
  update_vw_admin_100_percent_discount_reservations?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Mutation_Response>;
  /** update multiples rows of table: "vw_admin_100_percent_discount_reservations" */
  update_vw_admin_100_percent_discount_reservations_many?: Maybe<Array<Maybe<Vw_Admin_100_Percent_Discount_Reservations_Mutation_Response>>>;
  /** update data of the table: "vw_admin_cancelled_reservation" */
  update_vw_admin_cancelled_reservation?: Maybe<Vw_Admin_Cancelled_Reservation_Mutation_Response>;
  /** update multiples rows of table: "vw_admin_cancelled_reservation" */
  update_vw_admin_cancelled_reservation_many?: Maybe<Array<Maybe<Vw_Admin_Cancelled_Reservation_Mutation_Response>>>;
  /** update data of the table: "vw_admin_reservation" */
  update_vw_admin_reservation?: Maybe<Vw_Admin_Reservation_Mutation_Response>;
  /** update multiples rows of table: "vw_admin_reservation" */
  update_vw_admin_reservation_many?: Maybe<Array<Maybe<Vw_Admin_Reservation_Mutation_Response>>>;
  verifyEmail?: Maybe<VerifyEmailOutput>;
  verifySkillsincloudSSO?: Maybe<VerifySkillsincloudSsoOutput>;
}


/** mutation root */
export type Mutation_RootAcceptManualReservationArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootActivateAdministratorArgs = {
  account_id: Scalars['String'];
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootActivateDriverArgs = {
  account_id: Scalars['String'];
  city_list: Array<Maybe<Scalars['String']>>;
  service_list: Array<Maybe<Scalars['String']>>;
};


/** mutation root */
export type Mutation_RootAddPaymentMethodArgs = {
  payment_method_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCalculateTransactionFeesArgs = {
  input?: Maybe<CalculateTransactionFeesInput>;
};


/** mutation root */
export type Mutation_RootCancelReservationArgs = {
  cancellation_reason?: Maybe<Scalars['String']>;
  reservation_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootChangeEmailArgs = {
  account_id: Scalars['String'];
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCheckAuthStatusArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateAccountArgs = {
  additional_data?: Maybe<Scalars['jsonb']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  personal_data?: Maybe<Scalars['jsonb']>;
  provider_id?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootCreateQuoteArgs = {
  quote?: Maybe<Scalars['jsonb']>;
};


/** mutation root */
export type Mutation_RootDeactivateDriverArgs = {
  account_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeclineManualReservationArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AccountArgs = {
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Account_ErrorArgs = {
  where: Account_Error_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Error_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Account_Error_TypeArgs = {
  where: Account_Error_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Error_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Account_FeedbackArgs = {
  where: Account_Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Administrator_ProfileArgs = {
  where: Administrator_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Administrator_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Carvoila_DistrictArgs = {
  where: Carvoila_District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Carvoila_District_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Carvoila_DriverArgs = {
  where: Carvoila_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Carvoila_Driver_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Carvoila_Driver_StatusArgs = {
  where: Carvoila_Driver_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Carvoila_Driver_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CityArgs = {
  where: City_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_City_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DeploymentArgs = {
  where: Deployment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deployment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Deployment_TypeArgs = {
  where: Deployment_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deployment_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Driver_ProfileArgs = {
  where: Driver_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Driver_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Driver_StatusArgs = {
  where: Driver_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Driver_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Manual_DriverArgs = {
  where: Manual_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Manual_Driver_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Manual_PatientArgs = {
  where: Manual_Patient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Manual_Patient_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Manual_ReservationArgs = {
  where: Manual_Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Manual_Reservation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_ProfileArgs = {
  where: Patient_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentArgs = {
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_MethodArgs = {
  where: Payment_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Method_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ReservationArgs = {
  where: Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reservation_Change_Request_ActionArgs = {
  where: Reservation_Change_Request_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservation_Change_Request_Action_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Reservation_StatusArgs = {
  where: Reservation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservation_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewArgs = {
  where: Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceArgs = {
  where: Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SyncArgs = {
  where: Sync_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sync_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_VoucherArgs = {
  where: Voucher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Voucher_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Voucher_RedemptionArgs = {
  where: Voucher_Redemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Voucher_Redemption_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Vw_Admin_100_Percent_Discount_ReservationsArgs = {
  where: Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vw_Admin_Cancelled_ReservationArgs = {
  where: Vw_Admin_Cancelled_Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vw_Admin_ReservationArgs = {
  where: Vw_Admin_Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDriverAcceptReservationChangeArgs = {
  reservation_id?: Maybe<Scalars['uuid']>;
};


/** mutation root */
export type Mutation_RootDriverRejectReservationChangeArgs = {
  reservation_id?: Maybe<Scalars['uuid']>;
};


/** mutation root */
export type Mutation_RootFinishReservationArgs = {
  feedback_link: Scalars['String'];
  reservation_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_AccountArgs = {
  objects: Array<Account_Insert_Input>;
  on_conflict?: Maybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_ErrorArgs = {
  objects: Array<Account_Error_Insert_Input>;
  on_conflict?: Maybe<Account_Error_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Error_OneArgs = {
  object: Account_Error_Insert_Input;
  on_conflict?: Maybe<Account_Error_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Error_TypeArgs = {
  objects: Array<Account_Error_Type_Insert_Input>;
  on_conflict?: Maybe<Account_Error_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Error_Type_OneArgs = {
  object: Account_Error_Type_Insert_Input;
  on_conflict?: Maybe<Account_Error_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_FeedbackArgs = {
  objects: Array<Account_Feedback_Insert_Input>;
  on_conflict?: Maybe<Account_Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Feedback_OneArgs = {
  object: Account_Feedback_Insert_Input;
  on_conflict?: Maybe<Account_Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_OneArgs = {
  object: Account_Insert_Input;
  on_conflict?: Maybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Administrator_ProfileArgs = {
  objects: Array<Administrator_Profile_Insert_Input>;
  on_conflict?: Maybe<Administrator_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Administrator_Profile_OneArgs = {
  object: Administrator_Profile_Insert_Input;
  on_conflict?: Maybe<Administrator_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Carvoila_DistrictArgs = {
  objects: Array<Carvoila_District_Insert_Input>;
  on_conflict?: Maybe<Carvoila_District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Carvoila_District_OneArgs = {
  object: Carvoila_District_Insert_Input;
  on_conflict?: Maybe<Carvoila_District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Carvoila_DriverArgs = {
  objects: Array<Carvoila_Driver_Insert_Input>;
  on_conflict?: Maybe<Carvoila_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Carvoila_Driver_OneArgs = {
  object: Carvoila_Driver_Insert_Input;
  on_conflict?: Maybe<Carvoila_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Carvoila_Driver_StatusArgs = {
  objects: Array<Carvoila_Driver_Status_Insert_Input>;
  on_conflict?: Maybe<Carvoila_Driver_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Carvoila_Driver_Status_OneArgs = {
  object: Carvoila_Driver_Status_Insert_Input;
  on_conflict?: Maybe<Carvoila_Driver_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CityArgs = {
  objects: Array<City_Insert_Input>;
  on_conflict?: Maybe<City_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_City_OneArgs = {
  object: City_Insert_Input;
  on_conflict?: Maybe<City_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DeploymentArgs = {
  objects: Array<Deployment_Insert_Input>;
  on_conflict?: Maybe<Deployment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deployment_OneArgs = {
  object: Deployment_Insert_Input;
  on_conflict?: Maybe<Deployment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deployment_TypeArgs = {
  objects: Array<Deployment_Type_Insert_Input>;
  on_conflict?: Maybe<Deployment_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deployment_Type_OneArgs = {
  object: Deployment_Type_Insert_Input;
  on_conflict?: Maybe<Deployment_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Driver_ProfileArgs = {
  objects: Array<Driver_Profile_Insert_Input>;
  on_conflict?: Maybe<Driver_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Driver_Profile_OneArgs = {
  object: Driver_Profile_Insert_Input;
  on_conflict?: Maybe<Driver_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Driver_StatusArgs = {
  objects: Array<Driver_Status_Insert_Input>;
  on_conflict?: Maybe<Driver_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Driver_Status_OneArgs = {
  object: Driver_Status_Insert_Input;
  on_conflict?: Maybe<Driver_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manual_DriverArgs = {
  objects: Array<Manual_Driver_Insert_Input>;
  on_conflict?: Maybe<Manual_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manual_Driver_OneArgs = {
  object: Manual_Driver_Insert_Input;
  on_conflict?: Maybe<Manual_Driver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manual_PatientArgs = {
  objects: Array<Manual_Patient_Insert_Input>;
  on_conflict?: Maybe<Manual_Patient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manual_Patient_OneArgs = {
  object: Manual_Patient_Insert_Input;
  on_conflict?: Maybe<Manual_Patient_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manual_ReservationArgs = {
  objects: Array<Manual_Reservation_Insert_Input>;
  on_conflict?: Maybe<Manual_Reservation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manual_Reservation_OneArgs = {
  object: Manual_Reservation_Insert_Input;
  on_conflict?: Maybe<Manual_Reservation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_ProfileArgs = {
  objects: Array<Patient_Profile_Insert_Input>;
  on_conflict?: Maybe<Patient_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Profile_OneArgs = {
  object: Patient_Profile_Insert_Input;
  on_conflict?: Maybe<Patient_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentArgs = {
  objects: Array<Payment_Insert_Input>;
  on_conflict?: Maybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_MethodArgs = {
  objects: Array<Payment_Method_Insert_Input>;
  on_conflict?: Maybe<Payment_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Method_OneArgs = {
  object: Payment_Method_Insert_Input;
  on_conflict?: Maybe<Payment_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_OneArgs = {
  object: Payment_Insert_Input;
  on_conflict?: Maybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReservationArgs = {
  objects: Array<Reservation_Insert_Input>;
  on_conflict?: Maybe<Reservation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_Change_Request_ActionArgs = {
  objects: Array<Reservation_Change_Request_Action_Insert_Input>;
  on_conflict?: Maybe<Reservation_Change_Request_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_Change_Request_Action_OneArgs = {
  object: Reservation_Change_Request_Action_Insert_Input;
  on_conflict?: Maybe<Reservation_Change_Request_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_OneArgs = {
  object: Reservation_Insert_Input;
  on_conflict?: Maybe<Reservation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_StatusArgs = {
  objects: Array<Reservation_Status_Insert_Input>;
  on_conflict?: Maybe<Reservation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_Status_OneArgs = {
  object: Reservation_Status_Insert_Input;
  on_conflict?: Maybe<Reservation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewArgs = {
  objects: Array<Review_Insert_Input>;
  on_conflict?: Maybe<Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Review_OneArgs = {
  object: Review_Insert_Input;
  on_conflict?: Maybe<Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceArgs = {
  objects: Array<Service_Insert_Input>;
  on_conflict?: Maybe<Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_OneArgs = {
  object: Service_Insert_Input;
  on_conflict?: Maybe<Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SyncArgs = {
  objects: Array<Sync_Insert_Input>;
  on_conflict?: Maybe<Sync_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sync_OneArgs = {
  object: Sync_Insert_Input;
  on_conflict?: Maybe<Sync_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VoucherArgs = {
  objects: Array<Voucher_Insert_Input>;
  on_conflict?: Maybe<Voucher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Voucher_OneArgs = {
  object: Voucher_Insert_Input;
  on_conflict?: Maybe<Voucher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Voucher_RedemptionArgs = {
  objects: Array<Voucher_Redemption_Insert_Input>;
  on_conflict?: Maybe<Voucher_Redemption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Voucher_Redemption_OneArgs = {
  object: Voucher_Redemption_Insert_Input;
  on_conflict?: Maybe<Voucher_Redemption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vw_Admin_100_Percent_Discount_ReservationsArgs = {
  objects: Array<Vw_Admin_100_Percent_Discount_Reservations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Vw_Admin_100_Percent_Discount_Reservations_OneArgs = {
  object: Vw_Admin_100_Percent_Discount_Reservations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Vw_Admin_Cancelled_ReservationArgs = {
  objects: Array<Vw_Admin_Cancelled_Reservation_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Vw_Admin_Cancelled_Reservation_OneArgs = {
  object: Vw_Admin_Cancelled_Reservation_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Vw_Admin_ReservationArgs = {
  objects: Array<Vw_Admin_Reservation_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Vw_Admin_Reservation_OneArgs = {
  object: Vw_Admin_Reservation_Insert_Input;
};


/** mutation root */
export type Mutation_RootOnboardDriverToStripeArgs = {
  origin: Scalars['String'];
};


/** mutation root */
export type Mutation_RootPatientCancelReservationArgs = {
  cancellation_reason?: Maybe<Scalars['String']>;
  reservation_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootPatientRequestReservationChangeArgs = {
  changes?: Maybe<Scalars['jsonb']>;
  reservation_id?: Maybe<Scalars['uuid']>;
};


/** mutation root */
export type Mutation_RootRemovePaymentMethodArgs = {
  payment_method_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootResendStripeCheckoutSessionPaymentRequestArgs = {
  reservation_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootResendVerificationEmailArgs = {
  account_id: Scalars['String'];
  app_origin: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSelectDefaultPaymentMethodArgs = {
  default_payment_method_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendNewManualReservationEmailToAllDriversArgs = {
  reservation_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootSendNewManualReservationEmailToPreferredDriverArgs = {
  reservation_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootSendPasswordResetRequestArgs = {
  email: Scalars['String'];
  redirect_uri: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSignInArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSubmitReservationRatingArgs = {
  feedback?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  reservation_id?: Maybe<Scalars['uuid']>;
};


/** mutation root */
export type Mutation_RootTrArgs = {
  ev: Scalars['String'];
  payload: Scalars['jsonb'];
};


/** mutation root */
export type Mutation_RootUpdate_AccountArgs = {
  _append?: Maybe<Account_Append_Input>;
  _delete_at_path?: Maybe<Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Delete_Key_Input>;
  _prepend?: Maybe<Account_Prepend_Input>;
  _set?: Maybe<Account_Set_Input>;
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_By_PkArgs = {
  _append?: Maybe<Account_Append_Input>;
  _delete_at_path?: Maybe<Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Delete_Key_Input>;
  _prepend?: Maybe<Account_Prepend_Input>;
  _set?: Maybe<Account_Set_Input>;
  pk_columns: Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_ErrorArgs = {
  _append?: Maybe<Account_Error_Append_Input>;
  _delete_at_path?: Maybe<Account_Error_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Error_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Error_Delete_Key_Input>;
  _prepend?: Maybe<Account_Error_Prepend_Input>;
  _set?: Maybe<Account_Error_Set_Input>;
  where: Account_Error_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Error_By_PkArgs = {
  _append?: Maybe<Account_Error_Append_Input>;
  _delete_at_path?: Maybe<Account_Error_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Error_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Error_Delete_Key_Input>;
  _prepend?: Maybe<Account_Error_Prepend_Input>;
  _set?: Maybe<Account_Error_Set_Input>;
  pk_columns: Account_Error_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Error_ManyArgs = {
  updates: Array<Account_Error_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Error_TypeArgs = {
  _set?: Maybe<Account_Error_Type_Set_Input>;
  where: Account_Error_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Error_Type_By_PkArgs = {
  _set?: Maybe<Account_Error_Type_Set_Input>;
  pk_columns: Account_Error_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Error_Type_ManyArgs = {
  updates: Array<Account_Error_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_FeedbackArgs = {
  _append?: Maybe<Account_Feedback_Append_Input>;
  _delete_at_path?: Maybe<Account_Feedback_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Feedback_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Feedback_Delete_Key_Input>;
  _prepend?: Maybe<Account_Feedback_Prepend_Input>;
  _set?: Maybe<Account_Feedback_Set_Input>;
  where: Account_Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Feedback_By_PkArgs = {
  _append?: Maybe<Account_Feedback_Append_Input>;
  _delete_at_path?: Maybe<Account_Feedback_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Feedback_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Feedback_Delete_Key_Input>;
  _prepend?: Maybe<Account_Feedback_Prepend_Input>;
  _set?: Maybe<Account_Feedback_Set_Input>;
  pk_columns: Account_Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Feedback_ManyArgs = {
  updates: Array<Account_Feedback_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_ManyArgs = {
  updates: Array<Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Administrator_ProfileArgs = {
  _set?: Maybe<Administrator_Profile_Set_Input>;
  where: Administrator_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Administrator_Profile_By_PkArgs = {
  _set?: Maybe<Administrator_Profile_Set_Input>;
  pk_columns: Administrator_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Administrator_Profile_ManyArgs = {
  updates: Array<Administrator_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Carvoila_DistrictArgs = {
  _inc?: Maybe<Carvoila_District_Inc_Input>;
  _set?: Maybe<Carvoila_District_Set_Input>;
  where: Carvoila_District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Carvoila_District_By_PkArgs = {
  _inc?: Maybe<Carvoila_District_Inc_Input>;
  _set?: Maybe<Carvoila_District_Set_Input>;
  pk_columns: Carvoila_District_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Carvoila_District_ManyArgs = {
  updates: Array<Carvoila_District_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Carvoila_DriverArgs = {
  _append?: Maybe<Carvoila_Driver_Append_Input>;
  _delete_at_path?: Maybe<Carvoila_Driver_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Carvoila_Driver_Delete_Elem_Input>;
  _delete_key?: Maybe<Carvoila_Driver_Delete_Key_Input>;
  _inc?: Maybe<Carvoila_Driver_Inc_Input>;
  _prepend?: Maybe<Carvoila_Driver_Prepend_Input>;
  _set?: Maybe<Carvoila_Driver_Set_Input>;
  where: Carvoila_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Carvoila_Driver_By_PkArgs = {
  _append?: Maybe<Carvoila_Driver_Append_Input>;
  _delete_at_path?: Maybe<Carvoila_Driver_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Carvoila_Driver_Delete_Elem_Input>;
  _delete_key?: Maybe<Carvoila_Driver_Delete_Key_Input>;
  _inc?: Maybe<Carvoila_Driver_Inc_Input>;
  _prepend?: Maybe<Carvoila_Driver_Prepend_Input>;
  _set?: Maybe<Carvoila_Driver_Set_Input>;
  pk_columns: Carvoila_Driver_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Carvoila_Driver_ManyArgs = {
  updates: Array<Carvoila_Driver_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Carvoila_Driver_StatusArgs = {
  _set?: Maybe<Carvoila_Driver_Status_Set_Input>;
  where: Carvoila_Driver_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Carvoila_Driver_Status_By_PkArgs = {
  _set?: Maybe<Carvoila_Driver_Status_Set_Input>;
  pk_columns: Carvoila_Driver_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Carvoila_Driver_Status_ManyArgs = {
  updates: Array<Carvoila_Driver_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CityArgs = {
  _set?: Maybe<City_Set_Input>;
  where: City_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_City_By_PkArgs = {
  _set?: Maybe<City_Set_Input>;
  pk_columns: City_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_City_ManyArgs = {
  updates: Array<City_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DeploymentArgs = {
  _set?: Maybe<Deployment_Set_Input>;
  where: Deployment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deployment_By_PkArgs = {
  _set?: Maybe<Deployment_Set_Input>;
  pk_columns: Deployment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deployment_ManyArgs = {
  updates: Array<Deployment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Deployment_TypeArgs = {
  _set?: Maybe<Deployment_Type_Set_Input>;
  where: Deployment_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deployment_Type_By_PkArgs = {
  _set?: Maybe<Deployment_Type_Set_Input>;
  pk_columns: Deployment_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deployment_Type_ManyArgs = {
  updates: Array<Deployment_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Driver_ProfileArgs = {
  _append?: Maybe<Driver_Profile_Append_Input>;
  _delete_at_path?: Maybe<Driver_Profile_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Driver_Profile_Delete_Elem_Input>;
  _delete_key?: Maybe<Driver_Profile_Delete_Key_Input>;
  _prepend?: Maybe<Driver_Profile_Prepend_Input>;
  _set?: Maybe<Driver_Profile_Set_Input>;
  where: Driver_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Driver_Profile_By_PkArgs = {
  _append?: Maybe<Driver_Profile_Append_Input>;
  _delete_at_path?: Maybe<Driver_Profile_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Driver_Profile_Delete_Elem_Input>;
  _delete_key?: Maybe<Driver_Profile_Delete_Key_Input>;
  _prepend?: Maybe<Driver_Profile_Prepend_Input>;
  _set?: Maybe<Driver_Profile_Set_Input>;
  pk_columns: Driver_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Driver_Profile_ManyArgs = {
  updates: Array<Driver_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Driver_StatusArgs = {
  _set?: Maybe<Driver_Status_Set_Input>;
  where: Driver_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Driver_Status_By_PkArgs = {
  _set?: Maybe<Driver_Status_Set_Input>;
  pk_columns: Driver_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Driver_Status_ManyArgs = {
  updates: Array<Driver_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Manual_DriverArgs = {
  _append?: Maybe<Manual_Driver_Append_Input>;
  _delete_at_path?: Maybe<Manual_Driver_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Manual_Driver_Delete_Elem_Input>;
  _delete_key?: Maybe<Manual_Driver_Delete_Key_Input>;
  _prepend?: Maybe<Manual_Driver_Prepend_Input>;
  _set?: Maybe<Manual_Driver_Set_Input>;
  where: Manual_Driver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Manual_Driver_By_PkArgs = {
  _append?: Maybe<Manual_Driver_Append_Input>;
  _delete_at_path?: Maybe<Manual_Driver_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Manual_Driver_Delete_Elem_Input>;
  _delete_key?: Maybe<Manual_Driver_Delete_Key_Input>;
  _prepend?: Maybe<Manual_Driver_Prepend_Input>;
  _set?: Maybe<Manual_Driver_Set_Input>;
  pk_columns: Manual_Driver_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Manual_Driver_ManyArgs = {
  updates: Array<Manual_Driver_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Manual_PatientArgs = {
  _inc?: Maybe<Manual_Patient_Inc_Input>;
  _set?: Maybe<Manual_Patient_Set_Input>;
  where: Manual_Patient_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Manual_Patient_By_PkArgs = {
  _inc?: Maybe<Manual_Patient_Inc_Input>;
  _set?: Maybe<Manual_Patient_Set_Input>;
  pk_columns: Manual_Patient_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Manual_Patient_ManyArgs = {
  updates: Array<Manual_Patient_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Manual_ReservationArgs = {
  _append?: Maybe<Manual_Reservation_Append_Input>;
  _delete_at_path?: Maybe<Manual_Reservation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Manual_Reservation_Delete_Elem_Input>;
  _delete_key?: Maybe<Manual_Reservation_Delete_Key_Input>;
  _inc?: Maybe<Manual_Reservation_Inc_Input>;
  _prepend?: Maybe<Manual_Reservation_Prepend_Input>;
  _set?: Maybe<Manual_Reservation_Set_Input>;
  where: Manual_Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Manual_Reservation_By_PkArgs = {
  _append?: Maybe<Manual_Reservation_Append_Input>;
  _delete_at_path?: Maybe<Manual_Reservation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Manual_Reservation_Delete_Elem_Input>;
  _delete_key?: Maybe<Manual_Reservation_Delete_Key_Input>;
  _inc?: Maybe<Manual_Reservation_Inc_Input>;
  _prepend?: Maybe<Manual_Reservation_Prepend_Input>;
  _set?: Maybe<Manual_Reservation_Set_Input>;
  pk_columns: Manual_Reservation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Manual_Reservation_ManyArgs = {
  updates: Array<Manual_Reservation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_ProfileArgs = {
  _set?: Maybe<Patient_Profile_Set_Input>;
  where: Patient_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Profile_By_PkArgs = {
  _set?: Maybe<Patient_Profile_Set_Input>;
  pk_columns: Patient_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Profile_ManyArgs = {
  updates: Array<Patient_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentArgs = {
  _append?: Maybe<Payment_Append_Input>;
  _delete_at_path?: Maybe<Payment_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Payment_Delete_Elem_Input>;
  _delete_key?: Maybe<Payment_Delete_Key_Input>;
  _inc?: Maybe<Payment_Inc_Input>;
  _prepend?: Maybe<Payment_Prepend_Input>;
  _set?: Maybe<Payment_Set_Input>;
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_By_PkArgs = {
  _append?: Maybe<Payment_Append_Input>;
  _delete_at_path?: Maybe<Payment_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Payment_Delete_Elem_Input>;
  _delete_key?: Maybe<Payment_Delete_Key_Input>;
  _inc?: Maybe<Payment_Inc_Input>;
  _prepend?: Maybe<Payment_Prepend_Input>;
  _set?: Maybe<Payment_Set_Input>;
  pk_columns: Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_ManyArgs = {
  updates: Array<Payment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_MethodArgs = {
  _set?: Maybe<Payment_Method_Set_Input>;
  where: Payment_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Method_By_PkArgs = {
  _set?: Maybe<Payment_Method_Set_Input>;
  pk_columns: Payment_Method_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Method_ManyArgs = {
  updates: Array<Payment_Method_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReservationArgs = {
  _append?: Maybe<Reservation_Append_Input>;
  _delete_at_path?: Maybe<Reservation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Reservation_Delete_Elem_Input>;
  _delete_key?: Maybe<Reservation_Delete_Key_Input>;
  _inc?: Maybe<Reservation_Inc_Input>;
  _prepend?: Maybe<Reservation_Prepend_Input>;
  _set?: Maybe<Reservation_Set_Input>;
  where: Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_By_PkArgs = {
  _append?: Maybe<Reservation_Append_Input>;
  _delete_at_path?: Maybe<Reservation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Reservation_Delete_Elem_Input>;
  _delete_key?: Maybe<Reservation_Delete_Key_Input>;
  _inc?: Maybe<Reservation_Inc_Input>;
  _prepend?: Maybe<Reservation_Prepend_Input>;
  _set?: Maybe<Reservation_Set_Input>;
  pk_columns: Reservation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Change_Request_ActionArgs = {
  _set?: Maybe<Reservation_Change_Request_Action_Set_Input>;
  where: Reservation_Change_Request_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Change_Request_Action_By_PkArgs = {
  _set?: Maybe<Reservation_Change_Request_Action_Set_Input>;
  pk_columns: Reservation_Change_Request_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Change_Request_Action_ManyArgs = {
  updates: Array<Reservation_Change_Request_Action_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_ManyArgs = {
  updates: Array<Reservation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_StatusArgs = {
  _set?: Maybe<Reservation_Status_Set_Input>;
  where: Reservation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Status_By_PkArgs = {
  _set?: Maybe<Reservation_Status_Set_Input>;
  pk_columns: Reservation_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Status_ManyArgs = {
  updates: Array<Reservation_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewArgs = {
  _append?: Maybe<Review_Append_Input>;
  _delete_at_path?: Maybe<Review_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Review_Delete_Elem_Input>;
  _delete_key?: Maybe<Review_Delete_Key_Input>;
  _inc?: Maybe<Review_Inc_Input>;
  _prepend?: Maybe<Review_Prepend_Input>;
  _set?: Maybe<Review_Set_Input>;
  where: Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Review_By_PkArgs = {
  _append?: Maybe<Review_Append_Input>;
  _delete_at_path?: Maybe<Review_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Review_Delete_Elem_Input>;
  _delete_key?: Maybe<Review_Delete_Key_Input>;
  _inc?: Maybe<Review_Inc_Input>;
  _prepend?: Maybe<Review_Prepend_Input>;
  _set?: Maybe<Review_Set_Input>;
  pk_columns: Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Review_ManyArgs = {
  updates: Array<Review_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceArgs = {
  _append?: Maybe<Service_Append_Input>;
  _delete_at_path?: Maybe<Service_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Service_Delete_Elem_Input>;
  _delete_key?: Maybe<Service_Delete_Key_Input>;
  _prepend?: Maybe<Service_Prepend_Input>;
  _set?: Maybe<Service_Set_Input>;
  where: Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_By_PkArgs = {
  _append?: Maybe<Service_Append_Input>;
  _delete_at_path?: Maybe<Service_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Service_Delete_Elem_Input>;
  _delete_key?: Maybe<Service_Delete_Key_Input>;
  _prepend?: Maybe<Service_Prepend_Input>;
  _set?: Maybe<Service_Set_Input>;
  pk_columns: Service_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_ManyArgs = {
  updates: Array<Service_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SyncArgs = {
  _set?: Maybe<Sync_Set_Input>;
  where: Sync_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sync_By_PkArgs = {
  _set?: Maybe<Sync_Set_Input>;
  pk_columns: Sync_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sync_ManyArgs = {
  updates: Array<Sync_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VoucherArgs = {
  _inc?: Maybe<Voucher_Inc_Input>;
  _set?: Maybe<Voucher_Set_Input>;
  where: Voucher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Voucher_By_PkArgs = {
  _inc?: Maybe<Voucher_Inc_Input>;
  _set?: Maybe<Voucher_Set_Input>;
  pk_columns: Voucher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Voucher_ManyArgs = {
  updates: Array<Voucher_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Voucher_RedemptionArgs = {
  _set?: Maybe<Voucher_Redemption_Set_Input>;
  where: Voucher_Redemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Voucher_Redemption_By_PkArgs = {
  _set?: Maybe<Voucher_Redemption_Set_Input>;
  pk_columns: Voucher_Redemption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Voucher_Redemption_ManyArgs = {
  updates: Array<Voucher_Redemption_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vw_Admin_100_Percent_Discount_ReservationsArgs = {
  _append?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Append_Input>;
  _delete_at_path?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Delete_Elem_Input>;
  _delete_key?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Delete_Key_Input>;
  _inc?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Inc_Input>;
  _prepend?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Prepend_Input>;
  _set?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Set_Input>;
  where: Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vw_Admin_100_Percent_Discount_Reservations_ManyArgs = {
  updates: Array<Vw_Admin_100_Percent_Discount_Reservations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vw_Admin_Cancelled_ReservationArgs = {
  _append?: Maybe<Vw_Admin_Cancelled_Reservation_Append_Input>;
  _delete_at_path?: Maybe<Vw_Admin_Cancelled_Reservation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Vw_Admin_Cancelled_Reservation_Delete_Elem_Input>;
  _delete_key?: Maybe<Vw_Admin_Cancelled_Reservation_Delete_Key_Input>;
  _prepend?: Maybe<Vw_Admin_Cancelled_Reservation_Prepend_Input>;
  _set?: Maybe<Vw_Admin_Cancelled_Reservation_Set_Input>;
  where: Vw_Admin_Cancelled_Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vw_Admin_Cancelled_Reservation_ManyArgs = {
  updates: Array<Vw_Admin_Cancelled_Reservation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Vw_Admin_ReservationArgs = {
  _append?: Maybe<Vw_Admin_Reservation_Append_Input>;
  _delete_at_path?: Maybe<Vw_Admin_Reservation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Vw_Admin_Reservation_Delete_Elem_Input>;
  _delete_key?: Maybe<Vw_Admin_Reservation_Delete_Key_Input>;
  _inc?: Maybe<Vw_Admin_Reservation_Inc_Input>;
  _prepend?: Maybe<Vw_Admin_Reservation_Prepend_Input>;
  _set?: Maybe<Vw_Admin_Reservation_Set_Input>;
  where: Vw_Admin_Reservation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vw_Admin_Reservation_ManyArgs = {
  updates: Array<Vw_Admin_Reservation_Updates>;
};


/** mutation root */
export type Mutation_RootVerifyEmailArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootVerifySkillsincloudSsoArgs = {
  sig: Scalars['String'];
  sso: Scalars['String'];
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface Numeric_Comparison_Exp {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export interface PatientRequestReservationChangeOutput {
  __typename?: 'patientRequestReservationChangeOutput';
  success?: Maybe<Scalars['Boolean']>;
}

/** columns and relationships of "patient_profile" */
export interface Patient_Profile {
  __typename?: 'patient_profile';
  /** An object relationship */
  account: Account;
  account_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregated selection of "patient_profile" */
export interface Patient_Profile_Aggregate {
  __typename?: 'patient_profile_aggregate';
  aggregate?: Maybe<Patient_Profile_Aggregate_Fields>;
  nodes: Array<Patient_Profile>;
}

/** aggregate fields of "patient_profile" */
export interface Patient_Profile_Aggregate_Fields {
  __typename?: 'patient_profile_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Patient_Profile_Max_Fields>;
  min?: Maybe<Patient_Profile_Min_Fields>;
}


/** aggregate fields of "patient_profile" */
export type Patient_Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Patient_Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "patient_profile". All fields are combined with a logical 'AND'. */
export interface Patient_Profile_Bool_Exp {
  _and?: Maybe<Array<Patient_Profile_Bool_Exp>>;
  _not?: Maybe<Patient_Profile_Bool_Exp>;
  _or?: Maybe<Array<Patient_Profile_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "patient_profile" */
export enum Patient_Profile_Constraint {
  /** unique or primary key constraint on columns "account_id" */
  PatientAccountIdKey = 'patient_account_id_key',
  /** unique or primary key constraint on columns "id" */
  PatientPkey = 'patient_pkey'
}

/** input type for inserting data into table "patient_profile" */
export interface Patient_Profile_Insert_Input {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Patient_Profile_Max_Fields {
  __typename?: 'patient_profile_max_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Patient_Profile_Min_Fields {
  __typename?: 'patient_profile_min_fields';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "patient_profile" */
export interface Patient_Profile_Mutation_Response {
  __typename?: 'patient_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Profile>;
}

/** input type for inserting object relation for remote table "patient_profile" */
export interface Patient_Profile_Obj_Rel_Insert_Input {
  data: Patient_Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Patient_Profile_On_Conflict>;
}

/** on_conflict condition type for table "patient_profile" */
export interface Patient_Profile_On_Conflict {
  constraint: Patient_Profile_Constraint;
  update_columns?: Array<Patient_Profile_Update_Column>;
  where?: Maybe<Patient_Profile_Bool_Exp>;
}

/** Ordering options when selecting data from "patient_profile". */
export interface Patient_Profile_Order_By {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** primary key columns input for table: patient_profile */
export interface Patient_Profile_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "patient_profile" */
export enum Patient_Profile_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "patient_profile" */
export interface Patient_Profile_Set_Input {
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** Streaming cursor of the table "patient_profile" */
export interface Patient_Profile_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Patient_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Patient_Profile_Stream_Cursor_Value_Input {
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** update columns of table "patient_profile" */
export enum Patient_Profile_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Patient_Profile_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Patient_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Profile_Bool_Exp;
}

/** columns and relationships of "payment" */
export interface Payment {
  __typename?: 'payment';
  /** An object relationship */
  account?: Maybe<Account>;
  account_id?: Maybe<Scalars['String']>;
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount: Scalars['numeric'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency: Scalars['String'];
  driver_amount?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  paid: Scalars['Boolean'];
  /** An object relationship */
  reservation?: Maybe<Reservation>;
  reservation_id?: Maybe<Scalars['uuid']>;
  stripe_fee?: Maybe<Scalars['numeric']>;
  ugo_fee?: Maybe<Scalars['numeric']>;
  ugo_fee_percent?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}


/** columns and relationships of "payment" */
export type PaymentHistory_SnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "payment" */
export type PaymentMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "payment" */
export interface Payment_Aggregate {
  __typename?: 'payment_aggregate';
  aggregate?: Maybe<Payment_Aggregate_Fields>;
  nodes: Array<Payment>;
}

export interface Payment_Aggregate_Bool_Exp {
  bool_and?: Maybe<Payment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Payment_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Payment_Aggregate_Bool_Exp_Count>;
}

export interface Payment_Aggregate_Bool_Exp_Bool_And {
  arguments: Payment_Select_Column_Payment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Payment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Payment_Aggregate_Bool_Exp_Bool_Or {
  arguments: Payment_Select_Column_Payment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Payment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Payment_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Payment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Payment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "payment" */
export interface Payment_Aggregate_Fields {
  __typename?: 'payment_aggregate_fields';
  avg?: Maybe<Payment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Max_Fields>;
  min?: Maybe<Payment_Min_Fields>;
  stddev?: Maybe<Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Sum_Fields>;
  var_pop?: Maybe<Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Var_Samp_Fields>;
  variance?: Maybe<Payment_Variance_Fields>;
}


/** aggregate fields of "payment" */
export type Payment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment" */
export interface Payment_Aggregate_Order_By {
  avg?: Maybe<Payment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Max_Order_By>;
  min?: Maybe<Payment_Min_Order_By>;
  stddev?: Maybe<Payment_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Sum_Order_By>;
  var_pop?: Maybe<Payment_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Variance_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Payment_Append_Input {
  history_snapshot?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
}

/** input type for inserting array relation for remote table "payment" */
export interface Payment_Arr_Rel_Insert_Input {
  data: Array<Payment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Payment_On_Conflict>;
}

/** aggregate avg on columns */
export interface Payment_Avg_Fields {
  __typename?: 'payment_avg_fields';
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['Float']>;
  driver_amount?: Maybe<Scalars['Float']>;
  stripe_fee?: Maybe<Scalars['Float']>;
  ugo_fee?: Maybe<Scalars['Float']>;
  ugo_fee_percent?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "payment" */
export interface Payment_Avg_Order_By {
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "payment". All fields are combined with a logical 'AND'. */
export interface Payment_Bool_Exp {
  _and?: Maybe<Array<Payment_Bool_Exp>>;
  _not?: Maybe<Payment_Bool_Exp>;
  _or?: Maybe<Array<Payment_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<String_Comparison_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  driver_amount?: Maybe<Numeric_Comparison_Exp>;
  history_snapshot?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  paid?: Maybe<Boolean_Comparison_Exp>;
  reservation?: Maybe<Reservation_Bool_Exp>;
  reservation_id?: Maybe<Uuid_Comparison_Exp>;
  stripe_fee?: Maybe<Numeric_Comparison_Exp>;
  ugo_fee?: Maybe<Numeric_Comparison_Exp>;
  ugo_fee_percent?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "payment" */
export enum Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentPkey = 'payment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Payment_Delete_At_Path_Input {
  history_snapshot?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Payment_Delete_Elem_Input {
  history_snapshot?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Payment_Delete_Key_Input {
  history_snapshot?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "payment" */
export interface Payment_Inc_Input {
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['numeric']>;
  driver_amount?: Maybe<Scalars['numeric']>;
  stripe_fee?: Maybe<Scalars['numeric']>;
  ugo_fee?: Maybe<Scalars['numeric']>;
  ugo_fee_percent?: Maybe<Scalars['numeric']>;
}

/** input type for inserting data into table "payment" */
export interface Payment_Insert_Input {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['String']>;
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  driver_amount?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  paid?: Maybe<Scalars['Boolean']>;
  reservation?: Maybe<Reservation_Obj_Rel_Insert_Input>;
  reservation_id?: Maybe<Scalars['uuid']>;
  stripe_fee?: Maybe<Scalars['numeric']>;
  ugo_fee?: Maybe<Scalars['numeric']>;
  ugo_fee_percent?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Payment_Max_Fields {
  __typename?: 'payment_max_fields';
  account_id?: Maybe<Scalars['String']>;
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  driver_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  stripe_fee?: Maybe<Scalars['numeric']>;
  ugo_fee?: Maybe<Scalars['numeric']>;
  ugo_fee_percent?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by max() on columns of table "payment" */
export interface Payment_Max_Order_By {
  account_id?: Maybe<Order_By>;
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reservation_id?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** Stripe payment method enum */
export interface Payment_Method {
  __typename?: 'payment_method';
  value: Scalars['String'];
}

/** aggregated selection of "payment_method" */
export interface Payment_Method_Aggregate {
  __typename?: 'payment_method_aggregate';
  aggregate?: Maybe<Payment_Method_Aggregate_Fields>;
  nodes: Array<Payment_Method>;
}

/** aggregate fields of "payment_method" */
export interface Payment_Method_Aggregate_Fields {
  __typename?: 'payment_method_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_Method_Max_Fields>;
  min?: Maybe<Payment_Method_Min_Fields>;
}


/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Method_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_method". All fields are combined with a logical 'AND'. */
export interface Payment_Method_Bool_Exp {
  _and?: Maybe<Array<Payment_Method_Bool_Exp>>;
  _not?: Maybe<Payment_Method_Bool_Exp>;
  _or?: Maybe<Array<Payment_Method_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "payment_method" */
export enum Payment_Method_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentMethodPkey = 'payment_method_pkey'
}

export enum Payment_Method_Enum {
  BankTransfer = 'bank_transfer',
  Card = 'card'
}

/** Boolean expression to compare columns of type "payment_method_enum". All fields are combined with logical 'AND'. */
export interface Payment_Method_Enum_Comparison_Exp {
  _eq?: Maybe<Payment_Method_Enum>;
  _in?: Maybe<Array<Payment_Method_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Payment_Method_Enum>;
  _nin?: Maybe<Array<Payment_Method_Enum>>;
}

/** input type for inserting data into table "payment_method" */
export interface Payment_Method_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Payment_Method_Max_Fields {
  __typename?: 'payment_method_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Payment_Method_Min_Fields {
  __typename?: 'payment_method_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "payment_method" */
export interface Payment_Method_Mutation_Response {
  __typename?: 'payment_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Method>;
}

/** on_conflict condition type for table "payment_method" */
export interface Payment_Method_On_Conflict {
  constraint: Payment_Method_Constraint;
  update_columns?: Array<Payment_Method_Update_Column>;
  where?: Maybe<Payment_Method_Bool_Exp>;
}

/** Ordering options when selecting data from "payment_method". */
export interface Payment_Method_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: payment_method */
export interface Payment_Method_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "payment_method" */
export enum Payment_Method_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_method" */
export interface Payment_Method_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "payment_method" */
export interface Payment_Method_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Payment_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Payment_Method_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "payment_method" */
export enum Payment_Method_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Payment_Method_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payment_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Method_Bool_Exp;
}

/** aggregate min on columns */
export interface Payment_Min_Fields {
  __typename?: 'payment_min_fields';
  account_id?: Maybe<Scalars['String']>;
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  driver_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  stripe_fee?: Maybe<Scalars['numeric']>;
  ugo_fee?: Maybe<Scalars['numeric']>;
  ugo_fee_percent?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** order by min() on columns of table "payment" */
export interface Payment_Min_Order_By {
  account_id?: Maybe<Order_By>;
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reservation_id?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** response of any mutation on the table "payment" */
export interface Payment_Mutation_Response {
  __typename?: 'payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment>;
}

/** on_conflict condition type for table "payment" */
export interface Payment_On_Conflict {
  constraint: Payment_Constraint;
  update_columns?: Array<Payment_Update_Column>;
  where?: Maybe<Payment_Bool_Exp>;
}

/** Ordering options when selecting data from "payment". */
export interface Payment_Order_By {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  history_snapshot?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  paid?: Maybe<Order_By>;
  reservation?: Maybe<Reservation_Order_By>;
  reservation_id?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** primary key columns input for table: payment */
export interface Payment_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Payment_Prepend_Input {
  history_snapshot?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "payment" */
export enum Payment_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DriverAmount = 'driver_amount',
  /** column name */
  HistorySnapshot = 'history_snapshot',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Paid = 'paid',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  StripeFee = 'stripe_fee',
  /** column name */
  UgoFee = 'ugo_fee',
  /** column name */
  UgoFeePercent = 'ugo_fee_percent',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "payment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "payment" */
export enum Payment_Select_Column_Payment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Paid = 'paid'
}

/** select "payment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "payment" */
export enum Payment_Select_Column_Payment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Paid = 'paid'
}

/** input type for updating data in table "payment" */
export interface Payment_Set_Input {
  account_id?: Maybe<Scalars['String']>;
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  driver_amount?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  paid?: Maybe<Scalars['Boolean']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  stripe_fee?: Maybe<Scalars['numeric']>;
  ugo_fee?: Maybe<Scalars['numeric']>;
  ugo_fee_percent?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface Payment_Stddev_Fields {
  __typename?: 'payment_stddev_fields';
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['Float']>;
  driver_amount?: Maybe<Scalars['Float']>;
  stripe_fee?: Maybe<Scalars['Float']>;
  ugo_fee?: Maybe<Scalars['Float']>;
  ugo_fee_percent?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "payment" */
export interface Payment_Stddev_Order_By {
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Payment_Stddev_Pop_Fields {
  __typename?: 'payment_stddev_pop_fields';
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['Float']>;
  driver_amount?: Maybe<Scalars['Float']>;
  stripe_fee?: Maybe<Scalars['Float']>;
  ugo_fee?: Maybe<Scalars['Float']>;
  ugo_fee_percent?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "payment" */
export interface Payment_Stddev_Pop_Order_By {
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Payment_Stddev_Samp_Fields {
  __typename?: 'payment_stddev_samp_fields';
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['Float']>;
  driver_amount?: Maybe<Scalars['Float']>;
  stripe_fee?: Maybe<Scalars['Float']>;
  ugo_fee?: Maybe<Scalars['Float']>;
  ugo_fee_percent?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "payment" */
export interface Payment_Stddev_Samp_Order_By {
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
}

/** Streaming cursor of the table "payment" */
export interface Payment_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Payment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Payment_Stream_Cursor_Value_Input {
  account_id?: Maybe<Scalars['String']>;
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  driver_amount?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  paid?: Maybe<Scalars['Boolean']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  stripe_fee?: Maybe<Scalars['numeric']>;
  ugo_fee?: Maybe<Scalars['numeric']>;
  ugo_fee_percent?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate sum on columns */
export interface Payment_Sum_Fields {
  __typename?: 'payment_sum_fields';
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['numeric']>;
  driver_amount?: Maybe<Scalars['numeric']>;
  stripe_fee?: Maybe<Scalars['numeric']>;
  ugo_fee?: Maybe<Scalars['numeric']>;
  ugo_fee_percent?: Maybe<Scalars['numeric']>;
}

/** order by sum() on columns of table "payment" */
export interface Payment_Sum_Order_By {
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
}

/** update columns of table "payment" */
export enum Payment_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DriverAmount = 'driver_amount',
  /** column name */
  HistorySnapshot = 'history_snapshot',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Paid = 'paid',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  StripeFee = 'stripe_fee',
  /** column name */
  UgoFee = 'ugo_fee',
  /** column name */
  UgoFeePercent = 'ugo_fee_percent',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Payment_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Payment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Payment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Payment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Payment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Payment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Payment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Payment_Var_Pop_Fields {
  __typename?: 'payment_var_pop_fields';
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['Float']>;
  driver_amount?: Maybe<Scalars['Float']>;
  stripe_fee?: Maybe<Scalars['Float']>;
  ugo_fee?: Maybe<Scalars['Float']>;
  ugo_fee_percent?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "payment" */
export interface Payment_Var_Pop_Order_By {
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Payment_Var_Samp_Fields {
  __typename?: 'payment_var_samp_fields';
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['Float']>;
  driver_amount?: Maybe<Scalars['Float']>;
  stripe_fee?: Maybe<Scalars['Float']>;
  ugo_fee?: Maybe<Scalars['Float']>;
  ugo_fee_percent?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "payment" */
export interface Payment_Var_Samp_Order_By {
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Payment_Variance_Fields {
  __typename?: 'payment_variance_fields';
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Scalars['Float']>;
  driver_amount?: Maybe<Scalars['Float']>;
  stripe_fee?: Maybe<Scalars['Float']>;
  ugo_fee?: Maybe<Scalars['Float']>;
  ugo_fee_percent?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "payment" */
export interface Payment_Variance_Order_By {
  /** This is the total amount of the payment (driver_amount + ugo_fee + stripe_fee) */
  amount?: Maybe<Order_By>;
  driver_amount?: Maybe<Order_By>;
  stripe_fee?: Maybe<Order_By>;
  ugo_fee?: Maybe<Order_By>;
  ugo_fee_percent?: Maybe<Order_By>;
}

export interface Query_Root {
  __typename?: 'query_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_error" */
  account_error: Array<Account_Error>;
  /** fetch aggregated fields from the table: "account_error" */
  account_error_aggregate: Account_Error_Aggregate;
  /** fetch data from the table: "account_error" using primary key columns */
  account_error_by_pk?: Maybe<Account_Error>;
  /** fetch data from the table: "account_error_type" */
  account_error_type: Array<Account_Error_Type>;
  /** fetch aggregated fields from the table: "account_error_type" */
  account_error_type_aggregate: Account_Error_Type_Aggregate;
  /** fetch data from the table: "account_error_type" using primary key columns */
  account_error_type_by_pk?: Maybe<Account_Error_Type>;
  /** fetch data from the table: "account_feedback" */
  account_feedback: Array<Account_Feedback>;
  /** fetch aggregated fields from the table: "account_feedback" */
  account_feedback_aggregate: Account_Feedback_Aggregate;
  /** fetch data from the table: "account_feedback" using primary key columns */
  account_feedback_by_pk?: Maybe<Account_Feedback>;
  /** fetch data from the table: "administrator_profile" */
  administrator_profile: Array<Administrator_Profile>;
  /** fetch aggregated fields from the table: "administrator_profile" */
  administrator_profile_aggregate: Administrator_Profile_Aggregate;
  /** fetch data from the table: "administrator_profile" using primary key columns */
  administrator_profile_by_pk?: Maybe<Administrator_Profile>;
  /** fetch data from the table: "carvoila.district" */
  carvoila_district: Array<Carvoila_District>;
  /** fetch aggregated fields from the table: "carvoila.district" */
  carvoila_district_aggregate: Carvoila_District_Aggregate;
  /** fetch data from the table: "carvoila.district" using primary key columns */
  carvoila_district_by_pk?: Maybe<Carvoila_District>;
  /** fetch data from the table: "carvoila.driver" */
  carvoila_driver: Array<Carvoila_Driver>;
  /** fetch aggregated fields from the table: "carvoila.driver" */
  carvoila_driver_aggregate: Carvoila_Driver_Aggregate;
  /** fetch data from the table: "carvoila.driver" using primary key columns */
  carvoila_driver_by_pk?: Maybe<Carvoila_Driver>;
  /** fetch data from the table: "carvoila.driver_status" */
  carvoila_driver_status: Array<Carvoila_Driver_Status>;
  /** fetch aggregated fields from the table: "carvoila.driver_status" */
  carvoila_driver_status_aggregate: Carvoila_Driver_Status_Aggregate;
  /** fetch data from the table: "carvoila.driver_status" using primary key columns */
  carvoila_driver_status_by_pk?: Maybe<Carvoila_Driver_Status>;
  /** fetch data from the table: "city" */
  city: Array<City>;
  /** fetch aggregated fields from the table: "city" */
  city_aggregate: City_Aggregate;
  /** fetch data from the table: "city" using primary key columns */
  city_by_pk?: Maybe<City>;
  /** fetch data from the table: "deployment" */
  deployment: Array<Deployment>;
  /** fetch aggregated fields from the table: "deployment" */
  deployment_aggregate: Deployment_Aggregate;
  /** fetch data from the table: "deployment" using primary key columns */
  deployment_by_pk?: Maybe<Deployment>;
  /** fetch data from the table: "deployment_type" */
  deployment_type: Array<Deployment_Type>;
  /** fetch aggregated fields from the table: "deployment_type" */
  deployment_type_aggregate: Deployment_Type_Aggregate;
  /** fetch data from the table: "deployment_type" using primary key columns */
  deployment_type_by_pk?: Maybe<Deployment_Type>;
  /** fetch data from the table: "driver_profile" */
  driver_profile: Array<Driver_Profile>;
  /** fetch aggregated fields from the table: "driver_profile" */
  driver_profile_aggregate: Driver_Profile_Aggregate;
  /** fetch data from the table: "driver_profile" using primary key columns */
  driver_profile_by_pk?: Maybe<Driver_Profile>;
  /** fetch data from the table: "driver_status" */
  driver_status: Array<Driver_Status>;
  /** fetch aggregated fields from the table: "driver_status" */
  driver_status_aggregate: Driver_Status_Aggregate;
  /** fetch data from the table: "driver_status" using primary key columns */
  driver_status_by_pk?: Maybe<Driver_Status>;
  /** execute function "fn_stats_get_general_user" which returns "vws_stats_general_user" */
  fn_stats_get_general_user: Array<Vws_Stats_General_User>;
  /** execute function "fn_stats_get_general_user" and query aggregates on result of table type "vws_stats_general_user" */
  fn_stats_get_general_user_aggregate: Vws_Stats_General_User_Aggregate;
  /** execute function "fn_stats_get_pareto_distribution_account_reservation" which returns "vws_stats_get_pareto_distribution_account_reservation" */
  fn_stats_get_pareto_distribution_account_reservation: Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation>;
  /** execute function "fn_stats_get_pareto_distribution_account_reservation" and query aggregates on result of table type "vws_stats_get_pareto_distribution_account_reservation" */
  fn_stats_get_pareto_distribution_account_reservation_aggregate: Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Aggregate;
  /** execute function "fn_stats_get_voucher_uses" which returns "vws_stats_get_voucher_uses" */
  fn_stats_get_voucher_uses: Array<Vws_Stats_Get_Voucher_Uses>;
  /** execute function "fn_stats_get_voucher_uses" and query aggregates on result of table type "vws_stats_get_voucher_uses" */
  fn_stats_get_voucher_uses_aggregate: Vws_Stats_Get_Voucher_Uses_Aggregate;
  getCardSetupClientSecret?: Maybe<ClientSecretOutput>;
  getDistanceAndDuration: GetDistanceAndDurationOutput;
  getFirebaseUserDetails?: Maybe<GetFirebaseUserDetailsOutput>;
  getLoginToken?: Maybe<GetLoginTokenOutput>;
  getUserStatistics?: Maybe<GetUserStatisticsOutput>;
  isDriverAvailable?: Maybe<IsDriverAvailableOutput>;
  isDriverFullyOnboardedToStripe?: Maybe<IsDriverFullyOnboardedToStripeOutput>;
  /** fetch data from the table: "manual_driver" */
  manual_driver: Array<Manual_Driver>;
  /** fetch aggregated fields from the table: "manual_driver" */
  manual_driver_aggregate: Manual_Driver_Aggregate;
  /** fetch data from the table: "manual_driver" using primary key columns */
  manual_driver_by_pk?: Maybe<Manual_Driver>;
  /** fetch data from the table: "manual_patient" */
  manual_patient: Array<Manual_Patient>;
  /** fetch aggregated fields from the table: "manual_patient" */
  manual_patient_aggregate: Manual_Patient_Aggregate;
  /** fetch data from the table: "manual_patient" using primary key columns */
  manual_patient_by_pk?: Maybe<Manual_Patient>;
  /** fetch data from the table: "manual_reservation" */
  manual_reservation: Array<Manual_Reservation>;
  /** fetch aggregated fields from the table: "manual_reservation" */
  manual_reservation_aggregate: Manual_Reservation_Aggregate;
  /** fetch data from the table: "manual_reservation" using primary key columns */
  manual_reservation_by_pk?: Maybe<Manual_Reservation>;
  /** fetch data from the table: "patient_profile" */
  patient_profile: Array<Patient_Profile>;
  /** fetch aggregated fields from the table: "patient_profile" */
  patient_profile_aggregate: Patient_Profile_Aggregate;
  /** fetch data from the table: "patient_profile" using primary key columns */
  patient_profile_by_pk?: Maybe<Patient_Profile>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>;
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate;
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>;
  requestLoginToken?: Maybe<RequestLoginTokenOutput>;
  /** fetch data from the table: "reservation" */
  reservation: Array<Reservation>;
  /** fetch aggregated fields from the table: "reservation" */
  reservation_aggregate: Reservation_Aggregate;
  /** fetch data from the table: "reservation" using primary key columns */
  reservation_by_pk?: Maybe<Reservation>;
  /** fetch data from the table: "reservation_change_request_action" */
  reservation_change_request_action: Array<Reservation_Change_Request_Action>;
  /** fetch aggregated fields from the table: "reservation_change_request_action" */
  reservation_change_request_action_aggregate: Reservation_Change_Request_Action_Aggregate;
  /** fetch data from the table: "reservation_change_request_action" using primary key columns */
  reservation_change_request_action_by_pk?: Maybe<Reservation_Change_Request_Action>;
  /** fetch data from the table: "reservation_status" */
  reservation_status: Array<Reservation_Status>;
  /** fetch aggregated fields from the table: "reservation_status" */
  reservation_status_aggregate: Reservation_Status_Aggregate;
  /** fetch data from the table: "reservation_status" using primary key columns */
  reservation_status_by_pk?: Maybe<Reservation_Status>;
  /** fetch data from the table: "review" */
  review: Array<Review>;
  /** fetch aggregated fields from the table: "review" */
  review_aggregate: Review_Aggregate;
  /** fetch data from the table: "review" using primary key columns */
  review_by_pk?: Maybe<Review>;
  /** fetch data from the table: "service" */
  service: Array<Service>;
  /** fetch aggregated fields from the table: "service" */
  service_aggregate: Service_Aggregate;
  /** fetch data from the table: "service" using primary key columns */
  service_by_pk?: Maybe<Service>;
  /** fetch data from the table: "sync" */
  sync: Array<Sync>;
  /** fetch aggregated fields from the table: "sync" */
  sync_aggregate: Sync_Aggregate;
  /** fetch data from the table: "sync" using primary key columns */
  sync_by_pk?: Maybe<Sync>;
  /** verifyVoucher */
  verifyVoucher?: Maybe<VoucherOutput>;
  /** An array relationship */
  voucher: Array<Voucher>;
  /** An aggregate relationship */
  voucher_aggregate: Voucher_Aggregate;
  /** fetch data from the table: "voucher" using primary key columns */
  voucher_by_pk?: Maybe<Voucher>;
  /** fetch data from the table: "voucher_redemption" */
  voucher_redemption: Array<Voucher_Redemption>;
  /** fetch aggregated fields from the table: "voucher_redemption" */
  voucher_redemption_aggregate: Voucher_Redemption_Aggregate;
  /** fetch data from the table: "voucher_redemption" using primary key columns */
  voucher_redemption_by_pk?: Maybe<Voucher_Redemption>;
  /** fetch data from the table: "vw_admin_100_percent_discount_reservations" */
  vw_admin_100_percent_discount_reservations: Array<Vw_Admin_100_Percent_Discount_Reservations>;
  /** fetch aggregated fields from the table: "vw_admin_100_percent_discount_reservations" */
  vw_admin_100_percent_discount_reservations_aggregate: Vw_Admin_100_Percent_Discount_Reservations_Aggregate;
  /** fetch data from the table: "vw_admin_account" */
  vw_admin_account: Array<Vw_Admin_Account>;
  /** fetch aggregated fields from the table: "vw_admin_account" */
  vw_admin_account_aggregate: Vw_Admin_Account_Aggregate;
  /** fetch data from the table: "vw_admin_account_feedback" */
  vw_admin_account_feedback: Array<Vw_Admin_Account_Feedback>;
  /** fetch aggregated fields from the table: "vw_admin_account_feedback" */
  vw_admin_account_feedback_aggregate: Vw_Admin_Account_Feedback_Aggregate;
  /** fetch data from the table: "vw_admin_cancelled_reservation" */
  vw_admin_cancelled_reservation: Array<Vw_Admin_Cancelled_Reservation>;
  /** fetch aggregated fields from the table: "vw_admin_cancelled_reservation" */
  vw_admin_cancelled_reservation_aggregate: Vw_Admin_Cancelled_Reservation_Aggregate;
  /** fetch data from the table: "vw_admin_default_account_credit_cards" */
  vw_admin_default_account_credit_cards: Array<Vw_Admin_Default_Account_Credit_Cards>;
  /** fetch aggregated fields from the table: "vw_admin_default_account_credit_cards" */
  vw_admin_default_account_credit_cards_aggregate: Vw_Admin_Default_Account_Credit_Cards_Aggregate;
  /** fetch data from the table: "vw_admin_dof_drivers" */
  vw_admin_dof_drivers: Array<Vw_Admin_Dof_Drivers>;
  /** fetch aggregated fields from the table: "vw_admin_dof_drivers" */
  vw_admin_dof_drivers_aggregate: Vw_Admin_Dof_Drivers_Aggregate;
  /** fetch data from the table: "vw_admin_driver_profile" */
  vw_admin_driver_profile: Array<Vw_Admin_Driver_Profile>;
  /** fetch aggregated fields from the table: "vw_admin_driver_profile" */
  vw_admin_driver_profile_aggregate: Vw_Admin_Driver_Profile_Aggregate;
  /** fetch data from the table: "vw_admin_manual_reservation" */
  vw_admin_manual_reservation: Array<Vw_Admin_Manual_Reservation>;
  /** fetch aggregated fields from the table: "vw_admin_manual_reservation" */
  vw_admin_manual_reservation_aggregate: Vw_Admin_Manual_Reservation_Aggregate;
  /** fetch data from the table: "vw_admin_manual_reservation_with_preferred_driver" */
  vw_admin_manual_reservation_with_preferred_driver: Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver>;
  /** fetch aggregated fields from the table: "vw_admin_manual_reservation_with_preferred_driver" */
  vw_admin_manual_reservation_with_preferred_driver_aggregate: Vw_Admin_Manual_Reservation_With_Preferred_Driver_Aggregate;
  /** fetch data from the table: "vw_admin_patient_champions" */
  vw_admin_patient_champions: Array<Vw_Admin_Patient_Champions>;
  /** fetch aggregated fields from the table: "vw_admin_patient_champions" */
  vw_admin_patient_champions_aggregate: Vw_Admin_Patient_Champions_Aggregate;
  /** fetch data from the table: "vw_admin_reservation" */
  vw_admin_reservation: Array<Vw_Admin_Reservation>;
  /** fetch aggregated fields from the table: "vw_admin_reservation" */
  vw_admin_reservation_aggregate: Vw_Admin_Reservation_Aggregate;
  /** fetch data from the table: "vw_stats_auth_provider_data" */
  vw_stats_auth_provider_data: Array<Vw_Stats_Auth_Provider_Data>;
  /** fetch aggregated fields from the table: "vw_stats_auth_provider_data" */
  vw_stats_auth_provider_data_aggregate: Vw_Stats_Auth_Provider_Data_Aggregate;
  /** fetch data from the table: "vw_stats_daily_conversions_by_auth_provider" */
  vw_stats_daily_conversions_by_auth_provider: Array<Vw_Stats_Daily_Conversions_By_Auth_Provider>;
  /** fetch aggregated fields from the table: "vw_stats_daily_conversions_by_auth_provider" */
  vw_stats_daily_conversions_by_auth_provider_aggregate: Vw_Stats_Daily_Conversions_By_Auth_Provider_Aggregate;
  /** fetch data from the table: "vw_stats_patient_conversion_reservation" */
  vw_stats_patient_conversion_reservation: Array<Vw_Stats_Patient_Conversion_Reservation>;
  /** fetch aggregated fields from the table: "vw_stats_patient_conversion_reservation" */
  vw_stats_patient_conversion_reservation_aggregate: Vw_Stats_Patient_Conversion_Reservation_Aggregate;
  /** fetch data from the table: "vw_stats_reservation_frequency_by_patient_accounts" */
  vw_stats_reservation_frequency_by_patient_accounts: Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts>;
  /** fetch aggregated fields from the table: "vw_stats_reservation_frequency_by_patient_accounts" */
  vw_stats_reservation_frequency_by_patient_accounts_aggregate: Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Aggregate;
  /** fetch data from the table: "vws_stats_general_user" */
  vws_stats_general_user: Array<Vws_Stats_General_User>;
  /** fetch aggregated fields from the table: "vws_stats_general_user" */
  vws_stats_general_user_aggregate: Vws_Stats_General_User_Aggregate;
  /** fetch data from the table: "vws_stats_get_pareto_distribution_account_reservation" */
  vws_stats_get_pareto_distribution_account_reservation: Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation>;
  /** fetch aggregated fields from the table: "vws_stats_get_pareto_distribution_account_reservation" */
  vws_stats_get_pareto_distribution_account_reservation_aggregate: Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Aggregate;
  /** fetch data from the table: "vws_stats_get_voucher_uses" */
  vws_stats_get_voucher_uses: Array<Vws_Stats_Get_Voucher_Uses>;
  /** fetch aggregated fields from the table: "vws_stats_get_voucher_uses" */
  vws_stats_get_voucher_uses_aggregate: Vws_Stats_Get_Voucher_Uses_Aggregate;
}


export type Query_RootAccountArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Query_RootAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Query_RootAccount_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootAccount_ErrorArgs = {
  distinct_on?: Maybe<Array<Account_Error_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Error_Order_By>>;
  where?: Maybe<Account_Error_Bool_Exp>;
};


export type Query_RootAccount_Error_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Error_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Error_Order_By>>;
  where?: Maybe<Account_Error_Bool_Exp>;
};


export type Query_RootAccount_Error_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAccount_Error_TypeArgs = {
  distinct_on?: Maybe<Array<Account_Error_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Error_Type_Order_By>>;
  where?: Maybe<Account_Error_Type_Bool_Exp>;
};


export type Query_RootAccount_Error_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Error_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Error_Type_Order_By>>;
  where?: Maybe<Account_Error_Type_Bool_Exp>;
};


export type Query_RootAccount_Error_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAccount_FeedbackArgs = {
  distinct_on?: Maybe<Array<Account_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Feedback_Order_By>>;
  where?: Maybe<Account_Feedback_Bool_Exp>;
};


export type Query_RootAccount_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Feedback_Order_By>>;
  where?: Maybe<Account_Feedback_Bool_Exp>;
};


export type Query_RootAccount_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAdministrator_ProfileArgs = {
  distinct_on?: Maybe<Array<Administrator_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Administrator_Profile_Order_By>>;
  where?: Maybe<Administrator_Profile_Bool_Exp>;
};


export type Query_RootAdministrator_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Administrator_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Administrator_Profile_Order_By>>;
  where?: Maybe<Administrator_Profile_Bool_Exp>;
};


export type Query_RootAdministrator_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCarvoila_DistrictArgs = {
  distinct_on?: Maybe<Array<Carvoila_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_District_Order_By>>;
  where?: Maybe<Carvoila_District_Bool_Exp>;
};


export type Query_RootCarvoila_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Carvoila_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_District_Order_By>>;
  where?: Maybe<Carvoila_District_Bool_Exp>;
};


export type Query_RootCarvoila_District_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCarvoila_DriverArgs = {
  distinct_on?: Maybe<Array<Carvoila_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_Driver_Order_By>>;
  where?: Maybe<Carvoila_Driver_Bool_Exp>;
};


export type Query_RootCarvoila_Driver_AggregateArgs = {
  distinct_on?: Maybe<Array<Carvoila_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_Driver_Order_By>>;
  where?: Maybe<Carvoila_Driver_Bool_Exp>;
};


export type Query_RootCarvoila_Driver_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCarvoila_Driver_StatusArgs = {
  distinct_on?: Maybe<Array<Carvoila_Driver_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_Driver_Status_Order_By>>;
  where?: Maybe<Carvoila_Driver_Status_Bool_Exp>;
};


export type Query_RootCarvoila_Driver_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Carvoila_Driver_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_Driver_Status_Order_By>>;
  where?: Maybe<Carvoila_Driver_Status_Bool_Exp>;
};


export type Query_RootCarvoila_Driver_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCityArgs = {
  distinct_on?: Maybe<Array<City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<City_Order_By>>;
  where?: Maybe<City_Bool_Exp>;
};


export type Query_RootCity_AggregateArgs = {
  distinct_on?: Maybe<Array<City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<City_Order_By>>;
  where?: Maybe<City_Bool_Exp>;
};


export type Query_RootCity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeploymentArgs = {
  distinct_on?: Maybe<Array<Deployment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deployment_Order_By>>;
  where?: Maybe<Deployment_Bool_Exp>;
};


export type Query_RootDeployment_AggregateArgs = {
  distinct_on?: Maybe<Array<Deployment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deployment_Order_By>>;
  where?: Maybe<Deployment_Bool_Exp>;
};


export type Query_RootDeployment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDeployment_TypeArgs = {
  distinct_on?: Maybe<Array<Deployment_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deployment_Type_Order_By>>;
  where?: Maybe<Deployment_Type_Bool_Exp>;
};


export type Query_RootDeployment_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Deployment_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deployment_Type_Order_By>>;
  where?: Maybe<Deployment_Type_Bool_Exp>;
};


export type Query_RootDeployment_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootDriver_ProfileArgs = {
  distinct_on?: Maybe<Array<Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Profile_Order_By>>;
  where?: Maybe<Driver_Profile_Bool_Exp>;
};


export type Query_RootDriver_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Profile_Order_By>>;
  where?: Maybe<Driver_Profile_Bool_Exp>;
};


export type Query_RootDriver_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDriver_StatusArgs = {
  distinct_on?: Maybe<Array<Driver_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Status_Order_By>>;
  where?: Maybe<Driver_Status_Bool_Exp>;
};


export type Query_RootDriver_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Driver_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Status_Order_By>>;
  where?: Maybe<Driver_Status_Bool_Exp>;
};


export type Query_RootDriver_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFn_Stats_Get_General_UserArgs = {
  args: Fn_Stats_Get_General_User_Args;
  distinct_on?: Maybe<Array<Vws_Stats_General_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_General_User_Order_By>>;
  where?: Maybe<Vws_Stats_General_User_Bool_Exp>;
};


export type Query_RootFn_Stats_Get_General_User_AggregateArgs = {
  args: Fn_Stats_Get_General_User_Args;
  distinct_on?: Maybe<Array<Vws_Stats_General_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_General_User_Order_By>>;
  where?: Maybe<Vws_Stats_General_User_Bool_Exp>;
};


export type Query_RootFn_Stats_Get_Pareto_Distribution_Account_ReservationArgs = {
  args: Fn_Stats_Get_Pareto_Distribution_Account_Reservation_Args;
  distinct_on?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
};


export type Query_RootFn_Stats_Get_Pareto_Distribution_Account_Reservation_AggregateArgs = {
  args: Fn_Stats_Get_Pareto_Distribution_Account_Reservation_Args;
  distinct_on?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
};


export type Query_RootFn_Stats_Get_Voucher_UsesArgs = {
  args: Fn_Stats_Get_Voucher_Uses_Args;
  distinct_on?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
};


export type Query_RootFn_Stats_Get_Voucher_Uses_AggregateArgs = {
  args: Fn_Stats_Get_Voucher_Uses_Args;
  distinct_on?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
};


export type Query_RootGetDistanceAndDurationArgs = {
  city: LatLngInput;
  departure: LatLngInput;
  destination: LatLngInput;
};


export type Query_RootGetFirebaseUserDetailsArgs = {
  user_id: Scalars['String'];
};


export type Query_RootIsDriverAvailableArgs = {
  from: Scalars['timestamptz'];
  to: Scalars['timestamptz'];
};


export type Query_RootManual_DriverArgs = {
  distinct_on?: Maybe<Array<Manual_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Driver_Order_By>>;
  where?: Maybe<Manual_Driver_Bool_Exp>;
};


export type Query_RootManual_Driver_AggregateArgs = {
  distinct_on?: Maybe<Array<Manual_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Driver_Order_By>>;
  where?: Maybe<Manual_Driver_Bool_Exp>;
};


export type Query_RootManual_Driver_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootManual_PatientArgs = {
  distinct_on?: Maybe<Array<Manual_Patient_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Patient_Order_By>>;
  where?: Maybe<Manual_Patient_Bool_Exp>;
};


export type Query_RootManual_Patient_AggregateArgs = {
  distinct_on?: Maybe<Array<Manual_Patient_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Patient_Order_By>>;
  where?: Maybe<Manual_Patient_Bool_Exp>;
};


export type Query_RootManual_Patient_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootManual_ReservationArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


export type Query_RootManual_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


export type Query_RootManual_Reservation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPatient_ProfileArgs = {
  distinct_on?: Maybe<Array<Patient_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Profile_Order_By>>;
  where?: Maybe<Patient_Profile_Bool_Exp>;
};


export type Query_RootPatient_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Profile_Order_By>>;
  where?: Maybe<Patient_Profile_Bool_Exp>;
};


export type Query_RootPatient_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPayment_MethodArgs = {
  distinct_on?: Maybe<Array<Payment_Method_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Method_Order_By>>;
  where?: Maybe<Payment_Method_Bool_Exp>;
};


export type Query_RootPayment_Method_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Method_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Method_Order_By>>;
  where?: Maybe<Payment_Method_Bool_Exp>;
};


export type Query_RootPayment_Method_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootRequestLoginTokenArgs = {
  token: Scalars['String'];
};


export type Query_RootReservationArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


export type Query_RootReservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


export type Query_RootReservation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReservation_Change_Request_ActionArgs = {
  distinct_on?: Maybe<Array<Reservation_Change_Request_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Change_Request_Action_Order_By>>;
  where?: Maybe<Reservation_Change_Request_Action_Bool_Exp>;
};


export type Query_RootReservation_Change_Request_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Change_Request_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Change_Request_Action_Order_By>>;
  where?: Maybe<Reservation_Change_Request_Action_Bool_Exp>;
};


export type Query_RootReservation_Change_Request_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootReservation_StatusArgs = {
  distinct_on?: Maybe<Array<Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Status_Order_By>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Query_RootReservation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Status_Order_By>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Query_RootReservation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootReviewArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


export type Query_RootReview_AggregateArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


export type Query_RootReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceArgs = {
  distinct_on?: Maybe<Array<Service_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Order_By>>;
  where?: Maybe<Service_Bool_Exp>;
};


export type Query_RootService_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Order_By>>;
  where?: Maybe<Service_Bool_Exp>;
};


export type Query_RootService_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootSyncArgs = {
  distinct_on?: Maybe<Array<Sync_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Order_By>>;
  where?: Maybe<Sync_Bool_Exp>;
};


export type Query_RootSync_AggregateArgs = {
  distinct_on?: Maybe<Array<Sync_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Order_By>>;
  where?: Maybe<Sync_Bool_Exp>;
};


export type Query_RootSync_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootVerifyVoucherArgs = {
  amount: Scalars['numeric'];
  code: Scalars['String'];
};


export type Query_RootVoucherArgs = {
  distinct_on?: Maybe<Array<Voucher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Order_By>>;
  where?: Maybe<Voucher_Bool_Exp>;
};


export type Query_RootVoucher_AggregateArgs = {
  distinct_on?: Maybe<Array<Voucher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Order_By>>;
  where?: Maybe<Voucher_Bool_Exp>;
};


export type Query_RootVoucher_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootVoucher_RedemptionArgs = {
  distinct_on?: Maybe<Array<Voucher_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Redemption_Order_By>>;
  where?: Maybe<Voucher_Redemption_Bool_Exp>;
};


export type Query_RootVoucher_Redemption_AggregateArgs = {
  distinct_on?: Maybe<Array<Voucher_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Redemption_Order_By>>;
  where?: Maybe<Voucher_Redemption_Bool_Exp>;
};


export type Query_RootVoucher_Redemption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVw_Admin_100_Percent_Discount_ReservationsArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Order_By>>;
  where?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp>;
};


export type Query_RootVw_Admin_100_Percent_Discount_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Order_By>>;
  where?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp>;
};


export type Query_RootVw_Admin_AccountArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Account_Order_By>>;
  where?: Maybe<Vw_Admin_Account_Bool_Exp>;
};


export type Query_RootVw_Admin_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Account_Order_By>>;
  where?: Maybe<Vw_Admin_Account_Bool_Exp>;
};


export type Query_RootVw_Admin_Account_FeedbackArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Account_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Account_Feedback_Order_By>>;
  where?: Maybe<Vw_Admin_Account_Feedback_Bool_Exp>;
};


export type Query_RootVw_Admin_Account_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Account_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Account_Feedback_Order_By>>;
  where?: Maybe<Vw_Admin_Account_Feedback_Bool_Exp>;
};


export type Query_RootVw_Admin_Cancelled_ReservationArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Cancelled_Reservation_Bool_Exp>;
};


export type Query_RootVw_Admin_Cancelled_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Cancelled_Reservation_Bool_Exp>;
};


export type Query_RootVw_Admin_Default_Account_Credit_CardsArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Order_By>>;
  where?: Maybe<Vw_Admin_Default_Account_Credit_Cards_Bool_Exp>;
};


export type Query_RootVw_Admin_Default_Account_Credit_Cards_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Order_By>>;
  where?: Maybe<Vw_Admin_Default_Account_Credit_Cards_Bool_Exp>;
};


export type Query_RootVw_Admin_Dof_DriversArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Dof_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Dof_Drivers_Order_By>>;
  where?: Maybe<Vw_Admin_Dof_Drivers_Bool_Exp>;
};


export type Query_RootVw_Admin_Dof_Drivers_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Dof_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Dof_Drivers_Order_By>>;
  where?: Maybe<Vw_Admin_Dof_Drivers_Bool_Exp>;
};


export type Query_RootVw_Admin_Driver_ProfileArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Driver_Profile_Order_By>>;
  where?: Maybe<Vw_Admin_Driver_Profile_Bool_Exp>;
};


export type Query_RootVw_Admin_Driver_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Driver_Profile_Order_By>>;
  where?: Maybe<Vw_Admin_Driver_Profile_Bool_Exp>;
};


export type Query_RootVw_Admin_Manual_ReservationArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Manual_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_Bool_Exp>;
};


export type Query_RootVw_Admin_Manual_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Manual_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_Bool_Exp>;
};


export type Query_RootVw_Admin_Manual_Reservation_With_Preferred_DriverArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Order_By>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Bool_Exp>;
};


export type Query_RootVw_Admin_Manual_Reservation_With_Preferred_Driver_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Order_By>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Bool_Exp>;
};


export type Query_RootVw_Admin_Patient_ChampionsArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Patient_Champions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Patient_Champions_Order_By>>;
  where?: Maybe<Vw_Admin_Patient_Champions_Bool_Exp>;
};


export type Query_RootVw_Admin_Patient_Champions_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Patient_Champions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Patient_Champions_Order_By>>;
  where?: Maybe<Vw_Admin_Patient_Champions_Bool_Exp>;
};


export type Query_RootVw_Admin_ReservationArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Reservation_Bool_Exp>;
};


export type Query_RootVw_Admin_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Reservation_Bool_Exp>;
};


export type Query_RootVw_Stats_Auth_Provider_DataArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Order_By>>;
  where?: Maybe<Vw_Stats_Auth_Provider_Data_Bool_Exp>;
};


export type Query_RootVw_Stats_Auth_Provider_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Order_By>>;
  where?: Maybe<Vw_Stats_Auth_Provider_Data_Bool_Exp>;
};


export type Query_RootVw_Stats_Daily_Conversions_By_Auth_ProviderArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Order_By>>;
  where?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp>;
};


export type Query_RootVw_Stats_Daily_Conversions_By_Auth_Provider_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Order_By>>;
  where?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp>;
};


export type Query_RootVw_Stats_Patient_Conversion_ReservationArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Order_By>>;
  where?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Bool_Exp>;
};


export type Query_RootVw_Stats_Patient_Conversion_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Order_By>>;
  where?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Bool_Exp>;
};


export type Query_RootVw_Stats_Reservation_Frequency_By_Patient_AccountsArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Order_By>>;
  where?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Bool_Exp>;
};


export type Query_RootVw_Stats_Reservation_Frequency_By_Patient_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Order_By>>;
  where?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Bool_Exp>;
};


export type Query_RootVws_Stats_General_UserArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_General_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_General_User_Order_By>>;
  where?: Maybe<Vws_Stats_General_User_Bool_Exp>;
};


export type Query_RootVws_Stats_General_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_General_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_General_User_Order_By>>;
  where?: Maybe<Vws_Stats_General_User_Bool_Exp>;
};


export type Query_RootVws_Stats_Get_Pareto_Distribution_Account_ReservationArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
};


export type Query_RootVws_Stats_Get_Pareto_Distribution_Account_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
};


export type Query_RootVws_Stats_Get_Voucher_UsesArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
};


export type Query_RootVws_Stats_Get_Voucher_Uses_AggregateArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
};

/** reservation table */
export interface Reservation {
  __typename?: 'reservation';
  /** Timestamp of when the reservation was accepted by the driver */
  accepted_at?: Maybe<Scalars['timestamptz']>;
  /** Tracks changes for each `reservation` record */
  activity_log: Scalars['jsonb'];
  administrator_note?: Maybe<Scalars['String']>;
  bank_transfer_payment_intent_id?: Maybe<Scalars['String']>;
  change_request_accepted_at?: Maybe<Scalars['timestamptz']>;
  change_request_changes?: Maybe<Scalars['jsonb']>;
  change_request_driver_action?: Maybe<Reservation_Change_Request_Action_Enum>;
  change_request_driver_response_timeout_at?: Maybe<Scalars['timestamptz']>;
  change_request_rejected_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  /** An object relationship */
  driver?: Maybe<Account>;
  /** A list of all cancellations by the driver per reservation. Array of objects: { driver_id, cancelled_at, cancellation_reason } */
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `driver_id` should "always" fetch driver_profile table */
  driver_id?: Maybe<Scalars['String']>;
  /** Specific driver metadata. { id, ... } */
  driver_metadata?: Maybe<Scalars['jsonb']>;
  driver_rating: Scalars['numeric'];
  driver_rating_feedback?: Maybe<Scalars['String']>;
  driver_suggested_ended_at?: Maybe<Scalars['timestamptz']>;
  driver_suggested_started_at?: Maybe<Scalars['timestamptz']>;
  /** Reference id to the reservation duplication source */
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Actual end of the `reservation`.  Added by the `driver` */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Related to `departure_date` + `departure_time` + `duration` from `quote` field */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** Description of extra costs that driver had to pay during reservation */
  extra_costs_text?: Maybe<Scalars['String']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['numeric']>;
  /** TODO: Implement after MVP */
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  is_manual: Scalars['Boolean'];
  /** Used to determine whether the service/reservation has been provided in the supported city or in the nearby villages or suburbs */
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** Everything that's read often (filterable fields, ...) should be on the top `reservation` level. Everything else should be in the metadata field. { additional_options[], price_estimate, departure_address, departure_lat, departure_lng, destination_address, destination_lat, destination_lng, departure_date, departure_time, distance, duration, license_plate, number_of_passengers, reservation_for, service, service_city } */
  metadata: Scalars['jsonb'];
  /** A reason for the cancellation provided by the patient */
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  /** Timestamp of the cancellation by patient */
  patient_cancelled_at?: Maybe<Scalars['timestamptz']>;
  patient_rating: Scalars['numeric'];
  patient_rating_feedback?: Maybe<Scalars['String']>;
  payment_errors: Scalars['jsonb'];
  payment_failed_reminders_sent_count: Scalars['Int'];
  payment_method: Payment_Method_Enum;
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate;
  /** Indicator (name, email, text) of a preferred driver for some reservors/reservees */
  preferred_driver?: Maybe<Scalars['String']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['numeric']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  /** transaction fees calculated when reservation is finished */
  price_billable_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['numeric']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  /** transaction fees calculated when reservation is created */
  price_estimate_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  reservation_status?: Maybe<Reservation_Status>;
  /** An object relationship */
  reservee?: Maybe<Account>;
  /** ON HOLD (22. 1. 2020) - We're not matching reservee's with existing accounts in our database yet.  | Taxonomy approach. `reservee_id` should be used with `reservee_role` */
  reservee_id?: Maybe<Scalars['String']>;
  /** Specific reservee metadata. { id, ... } */
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `reservee_role` should be used with `reservee_id`. Possible values: 'driver' | 'patient' */
  reservee_role?: Maybe<Scalars['String']>;
  /** An object relationship */
  reservor?: Maybe<Account>;
  /** Taxonomy approach. `reservor_id` shoud be used with `reservor_role` */
  reservor_id?: Maybe<Scalars['String']>;
  /** Specific reservor metadata. { id, ... } */
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `reservor_role` should be used with `reservor_id`. Possible values: 'driver' | 'patient' */
  reservor_role?: Maybe<Scalars['String']>;
  /** An object relationship */
  service?: Maybe<Service>;
  service_id?: Maybe<Scalars['String']>;
  /** DEPRECATED | Taxonomy approach. Should be used together with service. When you put service and service_type together, you know in which table to search for a service. Possible values: 'GENERAL' | 'SPECIALIZED'; corresponding to tables general_service | specialized_service */
  service_type?: Maybe<Scalars['String']>;
  /** Related to the reservation_status SHELVED. Reservations are shelved in order to manually assign them to inexperienced drivers. */
  shelved_at?: Maybe<Scalars['timestamptz']>;
  /** A unique 6 character short ID */
  short_id?: Maybe<Scalars['String']>;
  /** Actual start of the `reservation`.  Added by the `driver` */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Same as `departure_date` + `departure_time` from `quote` field */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Reservation_Status_Enum>;
  stripe_card_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID supplied via Voucherify integration */
  voucher_code?: Maybe<Scalars['String']>;
  /** A reason provided if/when voucher cannot be redeemed. Supplied via Voucherify integration. */
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  /** ID of the redemption object. Supplied via Voucherify integration. */
  voucher_redemption_id?: Maybe<Scalars['String']>;
}


/** reservation table */
export type ReservationActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationChange_Request_ChangesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationDriver_Cancellation_ReasonsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationDriver_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationHistory_SnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationPayment_ErrorsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationPaymentsArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** reservation table */
export type ReservationPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** reservation table */
export type ReservationPrice_Billable_Transaction_FeesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationPrice_Estimate_Transaction_FeesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationReservee_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** reservation table */
export type ReservationReservor_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "reservation" */
export interface Reservation_Aggregate {
  __typename?: 'reservation_aggregate';
  aggregate?: Maybe<Reservation_Aggregate_Fields>;
  nodes: Array<Reservation>;
}

export interface Reservation_Aggregate_Bool_Exp {
  bool_and?: Maybe<Reservation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Reservation_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Reservation_Aggregate_Bool_Exp_Count>;
}

export interface Reservation_Aggregate_Bool_Exp_Bool_And {
  arguments: Reservation_Select_Column_Reservation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Reservation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Reservation_Aggregate_Bool_Exp_Bool_Or {
  arguments: Reservation_Select_Column_Reservation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Reservation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Reservation_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Reservation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Reservation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "reservation" */
export interface Reservation_Aggregate_Fields {
  __typename?: 'reservation_aggregate_fields';
  avg?: Maybe<Reservation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reservation_Max_Fields>;
  min?: Maybe<Reservation_Min_Fields>;
  stddev?: Maybe<Reservation_Stddev_Fields>;
  stddev_pop?: Maybe<Reservation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reservation_Stddev_Samp_Fields>;
  sum?: Maybe<Reservation_Sum_Fields>;
  var_pop?: Maybe<Reservation_Var_Pop_Fields>;
  var_samp?: Maybe<Reservation_Var_Samp_Fields>;
  variance?: Maybe<Reservation_Variance_Fields>;
}


/** aggregate fields of "reservation" */
export type Reservation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reservation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reservation" */
export interface Reservation_Aggregate_Order_By {
  avg?: Maybe<Reservation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Reservation_Max_Order_By>;
  min?: Maybe<Reservation_Min_Order_By>;
  stddev?: Maybe<Reservation_Stddev_Order_By>;
  stddev_pop?: Maybe<Reservation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Reservation_Stddev_Samp_Order_By>;
  sum?: Maybe<Reservation_Sum_Order_By>;
  var_pop?: Maybe<Reservation_Var_Pop_Order_By>;
  var_samp?: Maybe<Reservation_Var_Samp_Order_By>;
  variance?: Maybe<Reservation_Variance_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Reservation_Append_Input {
  /** Tracks changes for each `reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  change_request_changes?: Maybe<Scalars['jsonb']>;
  /** A list of all cancellations by the driver per reservation. Array of objects: { driver_id, cancelled_at, cancellation_reason } */
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  /** Specific driver metadata. { id, ... } */
  driver_metadata?: Maybe<Scalars['jsonb']>;
  /** TODO: Implement after MVP */
  history_snapshot?: Maybe<Scalars['jsonb']>;
  /** Everything that's read often (filterable fields, ...) should be on the top `reservation` level. Everything else should be in the metadata field. { additional_options[], price_estimate, departure_address, departure_lat, departure_lng, destination_address, destination_lat, destination_lng, departure_date, departure_time, distance, duration, license_plate, number_of_passengers, reservation_for, service, service_city } */
  metadata?: Maybe<Scalars['jsonb']>;
  payment_errors?: Maybe<Scalars['jsonb']>;
  /** transaction fees calculated when reservation is finished */
  price_billable_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** transaction fees calculated when reservation is created */
  price_estimate_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** Specific reservee metadata. { id, ... } */
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  /** Specific reservor metadata. { id, ... } */
  reservor_metadata?: Maybe<Scalars['jsonb']>;
}

/** input type for inserting array relation for remote table "reservation" */
export interface Reservation_Arr_Rel_Insert_Input {
  data: Array<Reservation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Reservation_On_Conflict>;
}

/** aggregate avg on columns */
export interface Reservation_Avg_Fields {
  __typename?: 'reservation_avg_fields';
  driver_rating?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['Float']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['Float']>;
  patient_rating?: Maybe<Scalars['Float']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Float']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['Float']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['Float']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
}

/** order by avg() on columns of table "reservation" */
export interface Reservation_Avg_Order_By {
  driver_rating?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "reservation". All fields are combined with a logical 'AND'. */
export interface Reservation_Bool_Exp {
  _and?: Maybe<Array<Reservation_Bool_Exp>>;
  _not?: Maybe<Reservation_Bool_Exp>;
  _or?: Maybe<Array<Reservation_Bool_Exp>>;
  accepted_at?: Maybe<Timestamptz_Comparison_Exp>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  administrator_note?: Maybe<String_Comparison_Exp>;
  bank_transfer_payment_intent_id?: Maybe<String_Comparison_Exp>;
  change_request_accepted_at?: Maybe<Timestamptz_Comparison_Exp>;
  change_request_changes?: Maybe<Jsonb_Comparison_Exp>;
  change_request_driver_action?: Maybe<Reservation_Change_Request_Action_Enum_Comparison_Exp>;
  change_request_driver_response_timeout_at?: Maybe<Timestamptz_Comparison_Exp>;
  change_request_rejected_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  driver?: Maybe<Account_Bool_Exp>;
  driver_cancellation_reasons?: Maybe<Jsonb_Comparison_Exp>;
  driver_id?: Maybe<String_Comparison_Exp>;
  driver_metadata?: Maybe<Jsonb_Comparison_Exp>;
  driver_rating?: Maybe<Numeric_Comparison_Exp>;
  driver_rating_feedback?: Maybe<String_Comparison_Exp>;
  driver_suggested_ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  driver_suggested_started_at?: Maybe<Timestamptz_Comparison_Exp>;
  duplication_reference_id?: Maybe<Uuid_Comparison_Exp>;
  duration_billable?: Maybe<Numeric_Comparison_Exp>;
  duration_estimate?: Maybe<Numeric_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  ends_at?: Maybe<Timestamptz_Comparison_Exp>;
  extra_costs_text?: Maybe<String_Comparison_Exp>;
  extra_costs_value?: Maybe<Numeric_Comparison_Exp>;
  history_snapshot?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_manual?: Maybe<Boolean_Comparison_Exp>;
  is_provided_in_city?: Maybe<Boolean_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  patient_cancellation_reason?: Maybe<String_Comparison_Exp>;
  patient_cancelled_at?: Maybe<Timestamptz_Comparison_Exp>;
  patient_rating?: Maybe<Numeric_Comparison_Exp>;
  patient_rating_feedback?: Maybe<String_Comparison_Exp>;
  payment_errors?: Maybe<Jsonb_Comparison_Exp>;
  payment_failed_reminders_sent_count?: Maybe<Int_Comparison_Exp>;
  payment_method?: Maybe<Payment_Method_Enum_Comparison_Exp>;
  payments?: Maybe<Payment_Bool_Exp>;
  payments_aggregate?: Maybe<Payment_Aggregate_Bool_Exp>;
  preferred_driver?: Maybe<String_Comparison_Exp>;
  price_billable?: Maybe<Numeric_Comparison_Exp>;
  price_billable_discounted?: Maybe<Numeric_Comparison_Exp>;
  price_billable_transaction_fees?: Maybe<Jsonb_Comparison_Exp>;
  price_billable_transaction_fees_driver_amount?: Maybe<Numeric_Comparison_Exp>;
  price_estimate?: Maybe<Numeric_Comparison_Exp>;
  price_estimate_discounted?: Maybe<Numeric_Comparison_Exp>;
  price_estimate_transaction_fees?: Maybe<Jsonb_Comparison_Exp>;
  price_estimate_transaction_fees_driver_amount?: Maybe<Numeric_Comparison_Exp>;
  reservation_status?: Maybe<Reservation_Status_Bool_Exp>;
  reservee?: Maybe<Account_Bool_Exp>;
  reservee_id?: Maybe<String_Comparison_Exp>;
  reservee_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservee_role?: Maybe<String_Comparison_Exp>;
  reservor?: Maybe<Account_Bool_Exp>;
  reservor_id?: Maybe<String_Comparison_Exp>;
  reservor_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservor_role?: Maybe<String_Comparison_Exp>;
  service?: Maybe<Service_Bool_Exp>;
  service_id?: Maybe<String_Comparison_Exp>;
  service_type?: Maybe<String_Comparison_Exp>;
  shelved_at?: Maybe<Timestamptz_Comparison_Exp>;
  short_id?: Maybe<String_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  starts_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Reservation_Status_Enum_Comparison_Exp>;
  stripe_card_payment_intent_id?: Maybe<String_Comparison_Exp>;
  stripe_checkout_session_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  voucher_code?: Maybe<String_Comparison_Exp>;
  voucher_not_applicable_reason?: Maybe<String_Comparison_Exp>;
  voucher_redemption_id?: Maybe<String_Comparison_Exp>;
}

/** Enum table for change request action */
export interface Reservation_Change_Request_Action {
  __typename?: 'reservation_change_request_action';
  value: Scalars['String'];
}

/** aggregated selection of "reservation_change_request_action" */
export interface Reservation_Change_Request_Action_Aggregate {
  __typename?: 'reservation_change_request_action_aggregate';
  aggregate?: Maybe<Reservation_Change_Request_Action_Aggregate_Fields>;
  nodes: Array<Reservation_Change_Request_Action>;
}

/** aggregate fields of "reservation_change_request_action" */
export interface Reservation_Change_Request_Action_Aggregate_Fields {
  __typename?: 'reservation_change_request_action_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reservation_Change_Request_Action_Max_Fields>;
  min?: Maybe<Reservation_Change_Request_Action_Min_Fields>;
}


/** aggregate fields of "reservation_change_request_action" */
export type Reservation_Change_Request_Action_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reservation_Change_Request_Action_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reservation_change_request_action". All fields are combined with a logical 'AND'. */
export interface Reservation_Change_Request_Action_Bool_Exp {
  _and?: Maybe<Array<Reservation_Change_Request_Action_Bool_Exp>>;
  _not?: Maybe<Reservation_Change_Request_Action_Bool_Exp>;
  _or?: Maybe<Array<Reservation_Change_Request_Action_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "reservation_change_request_action" */
export enum Reservation_Change_Request_Action_Constraint {
  /** unique or primary key constraint on columns "value" */
  ReservationChangeRequestActionPkey = 'reservation_change_request_action_pkey'
}

export enum Reservation_Change_Request_Action_Enum {
  DriverAccepted = 'DRIVER_ACCEPTED',
  DriverRejected = 'DRIVER_REJECTED'
}

/** Boolean expression to compare columns of type "reservation_change_request_action_enum". All fields are combined with logical 'AND'. */
export interface Reservation_Change_Request_Action_Enum_Comparison_Exp {
  _eq?: Maybe<Reservation_Change_Request_Action_Enum>;
  _in?: Maybe<Array<Reservation_Change_Request_Action_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Reservation_Change_Request_Action_Enum>;
  _nin?: Maybe<Array<Reservation_Change_Request_Action_Enum>>;
}

/** input type for inserting data into table "reservation_change_request_action" */
export interface Reservation_Change_Request_Action_Insert_Input {
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Reservation_Change_Request_Action_Max_Fields {
  __typename?: 'reservation_change_request_action_max_fields';
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Reservation_Change_Request_Action_Min_Fields {
  __typename?: 'reservation_change_request_action_min_fields';
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "reservation_change_request_action" */
export interface Reservation_Change_Request_Action_Mutation_Response {
  __typename?: 'reservation_change_request_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservation_Change_Request_Action>;
}

/** on_conflict condition type for table "reservation_change_request_action" */
export interface Reservation_Change_Request_Action_On_Conflict {
  constraint: Reservation_Change_Request_Action_Constraint;
  update_columns?: Array<Reservation_Change_Request_Action_Update_Column>;
  where?: Maybe<Reservation_Change_Request_Action_Bool_Exp>;
}

/** Ordering options when selecting data from "reservation_change_request_action". */
export interface Reservation_Change_Request_Action_Order_By {
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: reservation_change_request_action */
export interface Reservation_Change_Request_Action_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "reservation_change_request_action" */
export enum Reservation_Change_Request_Action_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "reservation_change_request_action" */
export interface Reservation_Change_Request_Action_Set_Input {
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "reservation_change_request_action" */
export interface Reservation_Change_Request_Action_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Reservation_Change_Request_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Reservation_Change_Request_Action_Stream_Cursor_Value_Input {
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "reservation_change_request_action" */
export enum Reservation_Change_Request_Action_Update_Column {
  /** column name */
  Value = 'value'
}

export interface Reservation_Change_Request_Action_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Reservation_Change_Request_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservation_Change_Request_Action_Bool_Exp;
}

/** unique or primary key constraints on table "reservation" */
export enum Reservation_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReservationPkey = 'reservation_pkey',
  /** unique or primary key constraint on columns "short_id" */
  ReservationShortIdKey = 'reservation_short_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Reservation_Delete_At_Path_Input {
  /** Tracks changes for each `reservation` record */
  activity_log?: Maybe<Array<Scalars['String']>>;
  change_request_changes?: Maybe<Array<Scalars['String']>>;
  /** A list of all cancellations by the driver per reservation. Array of objects: { driver_id, cancelled_at, cancellation_reason } */
  driver_cancellation_reasons?: Maybe<Array<Scalars['String']>>;
  /** Specific driver metadata. { id, ... } */
  driver_metadata?: Maybe<Array<Scalars['String']>>;
  /** TODO: Implement after MVP */
  history_snapshot?: Maybe<Array<Scalars['String']>>;
  /** Everything that's read often (filterable fields, ...) should be on the top `reservation` level. Everything else should be in the metadata field. { additional_options[], price_estimate, departure_address, departure_lat, departure_lng, destination_address, destination_lat, destination_lng, departure_date, departure_time, distance, duration, license_plate, number_of_passengers, reservation_for, service, service_city } */
  metadata?: Maybe<Array<Scalars['String']>>;
  payment_errors?: Maybe<Array<Scalars['String']>>;
  /** transaction fees calculated when reservation is finished */
  price_billable_transaction_fees?: Maybe<Array<Scalars['String']>>;
  /** transaction fees calculated when reservation is created */
  price_estimate_transaction_fees?: Maybe<Array<Scalars['String']>>;
  /** Specific reservee metadata. { id, ... } */
  reservee_metadata?: Maybe<Array<Scalars['String']>>;
  /** Specific reservor metadata. { id, ... } */
  reservor_metadata?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Reservation_Delete_Elem_Input {
  /** Tracks changes for each `reservation` record */
  activity_log?: Maybe<Scalars['Int']>;
  change_request_changes?: Maybe<Scalars['Int']>;
  /** A list of all cancellations by the driver per reservation. Array of objects: { driver_id, cancelled_at, cancellation_reason } */
  driver_cancellation_reasons?: Maybe<Scalars['Int']>;
  /** Specific driver metadata. { id, ... } */
  driver_metadata?: Maybe<Scalars['Int']>;
  /** TODO: Implement after MVP */
  history_snapshot?: Maybe<Scalars['Int']>;
  /** Everything that's read often (filterable fields, ...) should be on the top `reservation` level. Everything else should be in the metadata field. { additional_options[], price_estimate, departure_address, departure_lat, departure_lng, destination_address, destination_lat, destination_lng, departure_date, departure_time, distance, duration, license_plate, number_of_passengers, reservation_for, service, service_city } */
  metadata?: Maybe<Scalars['Int']>;
  payment_errors?: Maybe<Scalars['Int']>;
  /** transaction fees calculated when reservation is finished */
  price_billable_transaction_fees?: Maybe<Scalars['Int']>;
  /** transaction fees calculated when reservation is created */
  price_estimate_transaction_fees?: Maybe<Scalars['Int']>;
  /** Specific reservee metadata. { id, ... } */
  reservee_metadata?: Maybe<Scalars['Int']>;
  /** Specific reservor metadata. { id, ... } */
  reservor_metadata?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Reservation_Delete_Key_Input {
  /** Tracks changes for each `reservation` record */
  activity_log?: Maybe<Scalars['String']>;
  change_request_changes?: Maybe<Scalars['String']>;
  /** A list of all cancellations by the driver per reservation. Array of objects: { driver_id, cancelled_at, cancellation_reason } */
  driver_cancellation_reasons?: Maybe<Scalars['String']>;
  /** Specific driver metadata. { id, ... } */
  driver_metadata?: Maybe<Scalars['String']>;
  /** TODO: Implement after MVP */
  history_snapshot?: Maybe<Scalars['String']>;
  /** Everything that's read often (filterable fields, ...) should be on the top `reservation` level. Everything else should be in the metadata field. { additional_options[], price_estimate, departure_address, departure_lat, departure_lng, destination_address, destination_lat, destination_lng, departure_date, departure_time, distance, duration, license_plate, number_of_passengers, reservation_for, service, service_city } */
  metadata?: Maybe<Scalars['String']>;
  payment_errors?: Maybe<Scalars['String']>;
  /** transaction fees calculated when reservation is finished */
  price_billable_transaction_fees?: Maybe<Scalars['String']>;
  /** transaction fees calculated when reservation is created */
  price_estimate_transaction_fees?: Maybe<Scalars['String']>;
  /** Specific reservee metadata. { id, ... } */
  reservee_metadata?: Maybe<Scalars['String']>;
  /** Specific reservor metadata. { id, ... } */
  reservor_metadata?: Maybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "reservation" */
export interface Reservation_Inc_Input {
  driver_rating?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['numeric']>;
  patient_rating?: Maybe<Scalars['numeric']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Int']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['numeric']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['numeric']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
}

/** input type for inserting data into table "reservation" */
export interface Reservation_Insert_Input {
  /** Timestamp of when the reservation was accepted by the driver */
  accepted_at?: Maybe<Scalars['timestamptz']>;
  /** Tracks changes for each `reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  administrator_note?: Maybe<Scalars['String']>;
  bank_transfer_payment_intent_id?: Maybe<Scalars['String']>;
  change_request_accepted_at?: Maybe<Scalars['timestamptz']>;
  change_request_changes?: Maybe<Scalars['jsonb']>;
  change_request_driver_action?: Maybe<Reservation_Change_Request_Action_Enum>;
  change_request_driver_response_timeout_at?: Maybe<Scalars['timestamptz']>;
  change_request_rejected_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  driver?: Maybe<Account_Obj_Rel_Insert_Input>;
  /** A list of all cancellations by the driver per reservation. Array of objects: { driver_id, cancelled_at, cancellation_reason } */
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `driver_id` should "always" fetch driver_profile table */
  driver_id?: Maybe<Scalars['String']>;
  /** Specific driver metadata. { id, ... } */
  driver_metadata?: Maybe<Scalars['jsonb']>;
  driver_rating?: Maybe<Scalars['numeric']>;
  driver_rating_feedback?: Maybe<Scalars['String']>;
  driver_suggested_ended_at?: Maybe<Scalars['timestamptz']>;
  driver_suggested_started_at?: Maybe<Scalars['timestamptz']>;
  /** Reference id to the reservation duplication source */
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Actual end of the `reservation`.  Added by the `driver` */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Related to `departure_date` + `departure_time` + `duration` from `quote` field */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** Description of extra costs that driver had to pay during reservation */
  extra_costs_text?: Maybe<Scalars['String']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['numeric']>;
  /** TODO: Implement after MVP */
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  is_manual?: Maybe<Scalars['Boolean']>;
  /** Used to determine whether the service/reservation has been provided in the supported city or in the nearby villages or suburbs */
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** Everything that's read often (filterable fields, ...) should be on the top `reservation` level. Everything else should be in the metadata field. { additional_options[], price_estimate, departure_address, departure_lat, departure_lng, destination_address, destination_lat, destination_lng, departure_date, departure_time, distance, duration, license_plate, number_of_passengers, reservation_for, service, service_city } */
  metadata?: Maybe<Scalars['jsonb']>;
  /** A reason for the cancellation provided by the patient */
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  /** Timestamp of the cancellation by patient */
  patient_cancelled_at?: Maybe<Scalars['timestamptz']>;
  patient_rating?: Maybe<Scalars['numeric']>;
  patient_rating_feedback?: Maybe<Scalars['String']>;
  payment_errors?: Maybe<Scalars['jsonb']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Payment_Method_Enum>;
  payments?: Maybe<Payment_Arr_Rel_Insert_Input>;
  /** Indicator (name, email, text) of a preferred driver for some reservors/reservees */
  preferred_driver?: Maybe<Scalars['String']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['numeric']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  /** transaction fees calculated when reservation is finished */
  price_billable_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['numeric']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  /** transaction fees calculated when reservation is created */
  price_estimate_transaction_fees?: Maybe<Scalars['jsonb']>;
  reservation_status?: Maybe<Reservation_Status_Obj_Rel_Insert_Input>;
  reservee?: Maybe<Account_Obj_Rel_Insert_Input>;
  /** ON HOLD (22. 1. 2020) - We're not matching reservee's with existing accounts in our database yet.  | Taxonomy approach. `reservee_id` should be used with `reservee_role` */
  reservee_id?: Maybe<Scalars['String']>;
  /** Specific reservee metadata. { id, ... } */
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `reservee_role` should be used with `reservee_id`. Possible values: 'driver' | 'patient' */
  reservee_role?: Maybe<Scalars['String']>;
  reservor?: Maybe<Account_Obj_Rel_Insert_Input>;
  /** Taxonomy approach. `reservor_id` shoud be used with `reservor_role` */
  reservor_id?: Maybe<Scalars['String']>;
  /** Specific reservor metadata. { id, ... } */
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `reservor_role` should be used with `reservor_id`. Possible values: 'driver' | 'patient' */
  reservor_role?: Maybe<Scalars['String']>;
  service?: Maybe<Service_Obj_Rel_Insert_Input>;
  service_id?: Maybe<Scalars['String']>;
  /** DEPRECATED | Taxonomy approach. Should be used together with service. When you put service and service_type together, you know in which table to search for a service. Possible values: 'GENERAL' | 'SPECIALIZED'; corresponding to tables general_service | specialized_service */
  service_type?: Maybe<Scalars['String']>;
  /** Related to the reservation_status SHELVED. Reservations are shelved in order to manually assign them to inexperienced drivers. */
  shelved_at?: Maybe<Scalars['timestamptz']>;
  /** A unique 6 character short ID */
  short_id?: Maybe<Scalars['String']>;
  /** Actual start of the `reservation`.  Added by the `driver` */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Same as `departure_date` + `departure_time` from `quote` field */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Reservation_Status_Enum>;
  stripe_card_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID supplied via Voucherify integration */
  voucher_code?: Maybe<Scalars['String']>;
  /** A reason provided if/when voucher cannot be redeemed. Supplied via Voucherify integration. */
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  /** ID of the redemption object. Supplied via Voucherify integration. */
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Reservation_Max_Fields {
  __typename?: 'reservation_max_fields';
  /** Timestamp of when the reservation was accepted by the driver */
  accepted_at?: Maybe<Scalars['timestamptz']>;
  administrator_note?: Maybe<Scalars['String']>;
  bank_transfer_payment_intent_id?: Maybe<Scalars['String']>;
  change_request_accepted_at?: Maybe<Scalars['timestamptz']>;
  change_request_driver_response_timeout_at?: Maybe<Scalars['timestamptz']>;
  change_request_rejected_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `driver_id` should "always" fetch driver_profile table */
  driver_id?: Maybe<Scalars['String']>;
  driver_rating?: Maybe<Scalars['numeric']>;
  driver_rating_feedback?: Maybe<Scalars['String']>;
  driver_suggested_ended_at?: Maybe<Scalars['timestamptz']>;
  driver_suggested_started_at?: Maybe<Scalars['timestamptz']>;
  /** Reference id to the reservation duplication source */
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Actual end of the `reservation`.  Added by the `driver` */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Related to `departure_date` + `departure_time` + `duration` from `quote` field */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** Description of extra costs that driver had to pay during reservation */
  extra_costs_text?: Maybe<Scalars['String']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** A reason for the cancellation provided by the patient */
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  /** Timestamp of the cancellation by patient */
  patient_cancelled_at?: Maybe<Scalars['timestamptz']>;
  patient_rating?: Maybe<Scalars['numeric']>;
  patient_rating_feedback?: Maybe<Scalars['String']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Int']>;
  /** Indicator (name, email, text) of a preferred driver for some reservors/reservees */
  preferred_driver?: Maybe<Scalars['String']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['numeric']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['numeric']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** ON HOLD (22. 1. 2020) - We're not matching reservee's with existing accounts in our database yet.  | Taxonomy approach. `reservee_id` should be used with `reservee_role` */
  reservee_id?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `reservee_role` should be used with `reservee_id`. Possible values: 'driver' | 'patient' */
  reservee_role?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `reservor_id` shoud be used with `reservor_role` */
  reservor_id?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `reservor_role` should be used with `reservor_id`. Possible values: 'driver' | 'patient' */
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  /** DEPRECATED | Taxonomy approach. Should be used together with service. When you put service and service_type together, you know in which table to search for a service. Possible values: 'GENERAL' | 'SPECIALIZED'; corresponding to tables general_service | specialized_service */
  service_type?: Maybe<Scalars['String']>;
  /** Related to the reservation_status SHELVED. Reservations are shelved in order to manually assign them to inexperienced drivers. */
  shelved_at?: Maybe<Scalars['timestamptz']>;
  /** A unique 6 character short ID */
  short_id?: Maybe<Scalars['String']>;
  /** Actual start of the `reservation`.  Added by the `driver` */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Same as `departure_date` + `departure_time` from `quote` field */
  starts_at?: Maybe<Scalars['timestamptz']>;
  stripe_card_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID supplied via Voucherify integration */
  voucher_code?: Maybe<Scalars['String']>;
  /** A reason provided if/when voucher cannot be redeemed. Supplied via Voucherify integration. */
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  /** ID of the redemption object. Supplied via Voucherify integration. */
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "reservation" */
export interface Reservation_Max_Order_By {
  /** Timestamp of when the reservation was accepted by the driver */
  accepted_at?: Maybe<Order_By>;
  administrator_note?: Maybe<Order_By>;
  bank_transfer_payment_intent_id?: Maybe<Order_By>;
  change_request_accepted_at?: Maybe<Order_By>;
  change_request_driver_response_timeout_at?: Maybe<Order_By>;
  change_request_rejected_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Order_By>;
  /** Taxonomy approach. `driver_id` should "always" fetch driver_profile table */
  driver_id?: Maybe<Order_By>;
  driver_rating?: Maybe<Order_By>;
  driver_rating_feedback?: Maybe<Order_By>;
  driver_suggested_ended_at?: Maybe<Order_By>;
  driver_suggested_started_at?: Maybe<Order_By>;
  /** Reference id to the reservation duplication source */
  duplication_reference_id?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Actual end of the `reservation`.  Added by the `driver` */
  ended_at?: Maybe<Order_By>;
  /** Related to `departure_date` + `departure_time` + `duration` from `quote` field */
  ends_at?: Maybe<Order_By>;
  /** Description of extra costs that driver had to pay during reservation */
  extra_costs_text?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Order_By>;
  /** A reason for the cancellation provided by the patient */
  patient_cancellation_reason?: Maybe<Order_By>;
  /** Timestamp of the cancellation by patient */
  patient_cancelled_at?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  patient_rating_feedback?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Indicator (name, email, text) of a preferred driver for some reservors/reservees */
  preferred_driver?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
  /** ON HOLD (22. 1. 2020) - We're not matching reservee's with existing accounts in our database yet.  | Taxonomy approach. `reservee_id` should be used with `reservee_role` */
  reservee_id?: Maybe<Order_By>;
  /** Taxonomy approach. `reservee_role` should be used with `reservee_id`. Possible values: 'driver' | 'patient' */
  reservee_role?: Maybe<Order_By>;
  /** Taxonomy approach. `reservor_id` shoud be used with `reservor_role` */
  reservor_id?: Maybe<Order_By>;
  /** Taxonomy approach. `reservor_role` should be used with `reservor_id`. Possible values: 'driver' | 'patient' */
  reservor_role?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  /** DEPRECATED | Taxonomy approach. Should be used together with service. When you put service and service_type together, you know in which table to search for a service. Possible values: 'GENERAL' | 'SPECIALIZED'; corresponding to tables general_service | specialized_service */
  service_type?: Maybe<Order_By>;
  /** Related to the reservation_status SHELVED. Reservations are shelved in order to manually assign them to inexperienced drivers. */
  shelved_at?: Maybe<Order_By>;
  /** A unique 6 character short ID */
  short_id?: Maybe<Order_By>;
  /** Actual start of the `reservation`.  Added by the `driver` */
  started_at?: Maybe<Order_By>;
  /** Same as `departure_date` + `departure_time` from `quote` field */
  starts_at?: Maybe<Order_By>;
  stripe_card_payment_intent_id?: Maybe<Order_By>;
  stripe_checkout_session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  /** ID supplied via Voucherify integration */
  voucher_code?: Maybe<Order_By>;
  /** A reason provided if/when voucher cannot be redeemed. Supplied via Voucherify integration. */
  voucher_not_applicable_reason?: Maybe<Order_By>;
  /** ID of the redemption object. Supplied via Voucherify integration. */
  voucher_redemption_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Reservation_Min_Fields {
  __typename?: 'reservation_min_fields';
  /** Timestamp of when the reservation was accepted by the driver */
  accepted_at?: Maybe<Scalars['timestamptz']>;
  administrator_note?: Maybe<Scalars['String']>;
  bank_transfer_payment_intent_id?: Maybe<Scalars['String']>;
  change_request_accepted_at?: Maybe<Scalars['timestamptz']>;
  change_request_driver_response_timeout_at?: Maybe<Scalars['timestamptz']>;
  change_request_rejected_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `driver_id` should "always" fetch driver_profile table */
  driver_id?: Maybe<Scalars['String']>;
  driver_rating?: Maybe<Scalars['numeric']>;
  driver_rating_feedback?: Maybe<Scalars['String']>;
  driver_suggested_ended_at?: Maybe<Scalars['timestamptz']>;
  driver_suggested_started_at?: Maybe<Scalars['timestamptz']>;
  /** Reference id to the reservation duplication source */
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Actual end of the `reservation`.  Added by the `driver` */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Related to `departure_date` + `departure_time` + `duration` from `quote` field */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** Description of extra costs that driver had to pay during reservation */
  extra_costs_text?: Maybe<Scalars['String']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** A reason for the cancellation provided by the patient */
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  /** Timestamp of the cancellation by patient */
  patient_cancelled_at?: Maybe<Scalars['timestamptz']>;
  patient_rating?: Maybe<Scalars['numeric']>;
  patient_rating_feedback?: Maybe<Scalars['String']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Int']>;
  /** Indicator (name, email, text) of a preferred driver for some reservors/reservees */
  preferred_driver?: Maybe<Scalars['String']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['numeric']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['numeric']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** ON HOLD (22. 1. 2020) - We're not matching reservee's with existing accounts in our database yet.  | Taxonomy approach. `reservee_id` should be used with `reservee_role` */
  reservee_id?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `reservee_role` should be used with `reservee_id`. Possible values: 'driver' | 'patient' */
  reservee_role?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `reservor_id` shoud be used with `reservor_role` */
  reservor_id?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `reservor_role` should be used with `reservor_id`. Possible values: 'driver' | 'patient' */
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  /** DEPRECATED | Taxonomy approach. Should be used together with service. When you put service and service_type together, you know in which table to search for a service. Possible values: 'GENERAL' | 'SPECIALIZED'; corresponding to tables general_service | specialized_service */
  service_type?: Maybe<Scalars['String']>;
  /** Related to the reservation_status SHELVED. Reservations are shelved in order to manually assign them to inexperienced drivers. */
  shelved_at?: Maybe<Scalars['timestamptz']>;
  /** A unique 6 character short ID */
  short_id?: Maybe<Scalars['String']>;
  /** Actual start of the `reservation`.  Added by the `driver` */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Same as `departure_date` + `departure_time` from `quote` field */
  starts_at?: Maybe<Scalars['timestamptz']>;
  stripe_card_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID supplied via Voucherify integration */
  voucher_code?: Maybe<Scalars['String']>;
  /** A reason provided if/when voucher cannot be redeemed. Supplied via Voucherify integration. */
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  /** ID of the redemption object. Supplied via Voucherify integration. */
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "reservation" */
export interface Reservation_Min_Order_By {
  /** Timestamp of when the reservation was accepted by the driver */
  accepted_at?: Maybe<Order_By>;
  administrator_note?: Maybe<Order_By>;
  bank_transfer_payment_intent_id?: Maybe<Order_By>;
  change_request_accepted_at?: Maybe<Order_By>;
  change_request_driver_response_timeout_at?: Maybe<Order_By>;
  change_request_rejected_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Order_By>;
  /** Taxonomy approach. `driver_id` should "always" fetch driver_profile table */
  driver_id?: Maybe<Order_By>;
  driver_rating?: Maybe<Order_By>;
  driver_rating_feedback?: Maybe<Order_By>;
  driver_suggested_ended_at?: Maybe<Order_By>;
  driver_suggested_started_at?: Maybe<Order_By>;
  /** Reference id to the reservation duplication source */
  duplication_reference_id?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Actual end of the `reservation`.  Added by the `driver` */
  ended_at?: Maybe<Order_By>;
  /** Related to `departure_date` + `departure_time` + `duration` from `quote` field */
  ends_at?: Maybe<Order_By>;
  /** Description of extra costs that driver had to pay during reservation */
  extra_costs_text?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Order_By>;
  /** A reason for the cancellation provided by the patient */
  patient_cancellation_reason?: Maybe<Order_By>;
  /** Timestamp of the cancellation by patient */
  patient_cancelled_at?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  patient_rating_feedback?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Indicator (name, email, text) of a preferred driver for some reservors/reservees */
  preferred_driver?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
  /** ON HOLD (22. 1. 2020) - We're not matching reservee's with existing accounts in our database yet.  | Taxonomy approach. `reservee_id` should be used with `reservee_role` */
  reservee_id?: Maybe<Order_By>;
  /** Taxonomy approach. `reservee_role` should be used with `reservee_id`. Possible values: 'driver' | 'patient' */
  reservee_role?: Maybe<Order_By>;
  /** Taxonomy approach. `reservor_id` shoud be used with `reservor_role` */
  reservor_id?: Maybe<Order_By>;
  /** Taxonomy approach. `reservor_role` should be used with `reservor_id`. Possible values: 'driver' | 'patient' */
  reservor_role?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  /** DEPRECATED | Taxonomy approach. Should be used together with service. When you put service and service_type together, you know in which table to search for a service. Possible values: 'GENERAL' | 'SPECIALIZED'; corresponding to tables general_service | specialized_service */
  service_type?: Maybe<Order_By>;
  /** Related to the reservation_status SHELVED. Reservations are shelved in order to manually assign them to inexperienced drivers. */
  shelved_at?: Maybe<Order_By>;
  /** A unique 6 character short ID */
  short_id?: Maybe<Order_By>;
  /** Actual start of the `reservation`.  Added by the `driver` */
  started_at?: Maybe<Order_By>;
  /** Same as `departure_date` + `departure_time` from `quote` field */
  starts_at?: Maybe<Order_By>;
  stripe_card_payment_intent_id?: Maybe<Order_By>;
  stripe_checkout_session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  /** ID supplied via Voucherify integration */
  voucher_code?: Maybe<Order_By>;
  /** A reason provided if/when voucher cannot be redeemed. Supplied via Voucherify integration. */
  voucher_not_applicable_reason?: Maybe<Order_By>;
  /** ID of the redemption object. Supplied via Voucherify integration. */
  voucher_redemption_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "reservation" */
export interface Reservation_Mutation_Response {
  __typename?: 'reservation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservation>;
}

/** input type for inserting object relation for remote table "reservation" */
export interface Reservation_Obj_Rel_Insert_Input {
  data: Reservation_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Reservation_On_Conflict>;
}

/** on_conflict condition type for table "reservation" */
export interface Reservation_On_Conflict {
  constraint: Reservation_Constraint;
  update_columns?: Array<Reservation_Update_Column>;
  where?: Maybe<Reservation_Bool_Exp>;
}

/** Ordering options when selecting data from "reservation". */
export interface Reservation_Order_By {
  accepted_at?: Maybe<Order_By>;
  activity_log?: Maybe<Order_By>;
  administrator_note?: Maybe<Order_By>;
  bank_transfer_payment_intent_id?: Maybe<Order_By>;
  change_request_accepted_at?: Maybe<Order_By>;
  change_request_changes?: Maybe<Order_By>;
  change_request_driver_action?: Maybe<Order_By>;
  change_request_driver_response_timeout_at?: Maybe<Order_By>;
  change_request_rejected_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  driver?: Maybe<Account_Order_By>;
  driver_cancellation_reasons?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  driver_metadata?: Maybe<Order_By>;
  driver_rating?: Maybe<Order_By>;
  driver_rating_feedback?: Maybe<Order_By>;
  driver_suggested_ended_at?: Maybe<Order_By>;
  driver_suggested_started_at?: Maybe<Order_By>;
  duplication_reference_id?: Maybe<Order_By>;
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  extra_costs_text?: Maybe<Order_By>;
  extra_costs_value?: Maybe<Order_By>;
  history_snapshot?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_manual?: Maybe<Order_By>;
  is_provided_in_city?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  patient_cancellation_reason?: Maybe<Order_By>;
  patient_cancelled_at?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  patient_rating_feedback?: Maybe<Order_By>;
  payment_errors?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  payment_method?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  preferred_driver?: Maybe<Order_By>;
  price_billable?: Maybe<Order_By>;
  price_billable_discounted?: Maybe<Order_By>;
  price_billable_transaction_fees?: Maybe<Order_By>;
  price_billable_transaction_fees_driver_amount?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  price_estimate_discounted?: Maybe<Order_By>;
  price_estimate_transaction_fees?: Maybe<Order_By>;
  price_estimate_transaction_fees_driver_amount?: Maybe<Order_By>;
  reservation_status?: Maybe<Reservation_Status_Order_By>;
  reservee?: Maybe<Account_Order_By>;
  reservee_id?: Maybe<Order_By>;
  reservee_metadata?: Maybe<Order_By>;
  reservee_role?: Maybe<Order_By>;
  reservor?: Maybe<Account_Order_By>;
  reservor_id?: Maybe<Order_By>;
  reservor_metadata?: Maybe<Order_By>;
  reservor_role?: Maybe<Order_By>;
  service?: Maybe<Service_Order_By>;
  service_id?: Maybe<Order_By>;
  service_type?: Maybe<Order_By>;
  shelved_at?: Maybe<Order_By>;
  short_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stripe_card_payment_intent_id?: Maybe<Order_By>;
  stripe_checkout_session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  voucher_code?: Maybe<Order_By>;
  voucher_not_applicable_reason?: Maybe<Order_By>;
  voucher_redemption_id?: Maybe<Order_By>;
}

/** primary key columns input for table: reservation */
export interface Reservation_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Reservation_Prepend_Input {
  /** Tracks changes for each `reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  change_request_changes?: Maybe<Scalars['jsonb']>;
  /** A list of all cancellations by the driver per reservation. Array of objects: { driver_id, cancelled_at, cancellation_reason } */
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  /** Specific driver metadata. { id, ... } */
  driver_metadata?: Maybe<Scalars['jsonb']>;
  /** TODO: Implement after MVP */
  history_snapshot?: Maybe<Scalars['jsonb']>;
  /** Everything that's read often (filterable fields, ...) should be on the top `reservation` level. Everything else should be in the metadata field. { additional_options[], price_estimate, departure_address, departure_lat, departure_lng, destination_address, destination_lat, destination_lng, departure_date, departure_time, distance, duration, license_plate, number_of_passengers, reservation_for, service, service_city } */
  metadata?: Maybe<Scalars['jsonb']>;
  payment_errors?: Maybe<Scalars['jsonb']>;
  /** transaction fees calculated when reservation is finished */
  price_billable_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** transaction fees calculated when reservation is created */
  price_estimate_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** Specific reservee metadata. { id, ... } */
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  /** Specific reservor metadata. { id, ... } */
  reservor_metadata?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "reservation" */
export enum Reservation_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  AdministratorNote = 'administrator_note',
  /** column name */
  BankTransferPaymentIntentId = 'bank_transfer_payment_intent_id',
  /** column name */
  ChangeRequestAcceptedAt = 'change_request_accepted_at',
  /** column name */
  ChangeRequestChanges = 'change_request_changes',
  /** column name */
  ChangeRequestDriverAction = 'change_request_driver_action',
  /** column name */
  ChangeRequestDriverResponseTimeoutAt = 'change_request_driver_response_timeout_at',
  /** column name */
  ChangeRequestRejectedAt = 'change_request_rejected_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DriverCancellationReasons = 'driver_cancellation_reasons',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverMetadata = 'driver_metadata',
  /** column name */
  DriverRating = 'driver_rating',
  /** column name */
  DriverRatingFeedback = 'driver_rating_feedback',
  /** column name */
  DriverSuggestedEndedAt = 'driver_suggested_ended_at',
  /** column name */
  DriverSuggestedStartedAt = 'driver_suggested_started_at',
  /** column name */
  DuplicationReferenceId = 'duplication_reference_id',
  /** column name */
  DurationBillable = 'duration_billable',
  /** column name */
  DurationEstimate = 'duration_estimate',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraCostsText = 'extra_costs_text',
  /** column name */
  ExtraCostsValue = 'extra_costs_value',
  /** column name */
  HistorySnapshot = 'history_snapshot',
  /** column name */
  Id = 'id',
  /** column name */
  IsManual = 'is_manual',
  /** column name */
  IsProvidedInCity = 'is_provided_in_city',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PatientCancellationReason = 'patient_cancellation_reason',
  /** column name */
  PatientCancelledAt = 'patient_cancelled_at',
  /** column name */
  PatientRating = 'patient_rating',
  /** column name */
  PatientRatingFeedback = 'patient_rating_feedback',
  /** column name */
  PaymentErrors = 'payment_errors',
  /** column name */
  PaymentFailedRemindersSentCount = 'payment_failed_reminders_sent_count',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PreferredDriver = 'preferred_driver',
  /** column name */
  PriceBillable = 'price_billable',
  /** column name */
  PriceBillableDiscounted = 'price_billable_discounted',
  /** column name */
  PriceBillableTransactionFees = 'price_billable_transaction_fees',
  /** column name */
  PriceEstimate = 'price_estimate',
  /** column name */
  PriceEstimateDiscounted = 'price_estimate_discounted',
  /** column name */
  PriceEstimateTransactionFees = 'price_estimate_transaction_fees',
  /** column name */
  ReserveeId = 'reservee_id',
  /** column name */
  ReserveeMetadata = 'reservee_metadata',
  /** column name */
  ReserveeRole = 'reservee_role',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  ReservorMetadata = 'reservor_metadata',
  /** column name */
  ReservorRole = 'reservor_role',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  ShelvedAt = 'shelved_at',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCardPaymentIntentId = 'stripe_card_payment_intent_id',
  /** column name */
  StripeCheckoutSessionId = 'stripe_checkout_session_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherCode = 'voucher_code',
  /** column name */
  VoucherNotApplicableReason = 'voucher_not_applicable_reason',
  /** column name */
  VoucherRedemptionId = 'voucher_redemption_id'
}

/** select "reservation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reservation" */
export enum Reservation_Select_Column_Reservation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsManual = 'is_manual',
  /** column name */
  IsProvidedInCity = 'is_provided_in_city'
}

/** select "reservation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reservation" */
export enum Reservation_Select_Column_Reservation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsManual = 'is_manual',
  /** column name */
  IsProvidedInCity = 'is_provided_in_city'
}

/** input type for updating data in table "reservation" */
export interface Reservation_Set_Input {
  /** Timestamp of when the reservation was accepted by the driver */
  accepted_at?: Maybe<Scalars['timestamptz']>;
  /** Tracks changes for each `reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  administrator_note?: Maybe<Scalars['String']>;
  bank_transfer_payment_intent_id?: Maybe<Scalars['String']>;
  change_request_accepted_at?: Maybe<Scalars['timestamptz']>;
  change_request_changes?: Maybe<Scalars['jsonb']>;
  change_request_driver_action?: Maybe<Reservation_Change_Request_Action_Enum>;
  change_request_driver_response_timeout_at?: Maybe<Scalars['timestamptz']>;
  change_request_rejected_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  /** A list of all cancellations by the driver per reservation. Array of objects: { driver_id, cancelled_at, cancellation_reason } */
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `driver_id` should "always" fetch driver_profile table */
  driver_id?: Maybe<Scalars['String']>;
  /** Specific driver metadata. { id, ... } */
  driver_metadata?: Maybe<Scalars['jsonb']>;
  driver_rating?: Maybe<Scalars['numeric']>;
  driver_rating_feedback?: Maybe<Scalars['String']>;
  driver_suggested_ended_at?: Maybe<Scalars['timestamptz']>;
  driver_suggested_started_at?: Maybe<Scalars['timestamptz']>;
  /** Reference id to the reservation duplication source */
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Actual end of the `reservation`.  Added by the `driver` */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Related to `departure_date` + `departure_time` + `duration` from `quote` field */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** Description of extra costs that driver had to pay during reservation */
  extra_costs_text?: Maybe<Scalars['String']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['numeric']>;
  /** TODO: Implement after MVP */
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  is_manual?: Maybe<Scalars['Boolean']>;
  /** Used to determine whether the service/reservation has been provided in the supported city or in the nearby villages or suburbs */
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** Everything that's read often (filterable fields, ...) should be on the top `reservation` level. Everything else should be in the metadata field. { additional_options[], price_estimate, departure_address, departure_lat, departure_lng, destination_address, destination_lat, destination_lng, departure_date, departure_time, distance, duration, license_plate, number_of_passengers, reservation_for, service, service_city } */
  metadata?: Maybe<Scalars['jsonb']>;
  /** A reason for the cancellation provided by the patient */
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  /** Timestamp of the cancellation by patient */
  patient_cancelled_at?: Maybe<Scalars['timestamptz']>;
  patient_rating?: Maybe<Scalars['numeric']>;
  patient_rating_feedback?: Maybe<Scalars['String']>;
  payment_errors?: Maybe<Scalars['jsonb']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Payment_Method_Enum>;
  /** Indicator (name, email, text) of a preferred driver for some reservors/reservees */
  preferred_driver?: Maybe<Scalars['String']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['numeric']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  /** transaction fees calculated when reservation is finished */
  price_billable_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['numeric']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  /** transaction fees calculated when reservation is created */
  price_estimate_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** ON HOLD (22. 1. 2020) - We're not matching reservee's with existing accounts in our database yet.  | Taxonomy approach. `reservee_id` should be used with `reservee_role` */
  reservee_id?: Maybe<Scalars['String']>;
  /** Specific reservee metadata. { id, ... } */
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `reservee_role` should be used with `reservee_id`. Possible values: 'driver' | 'patient' */
  reservee_role?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `reservor_id` shoud be used with `reservor_role` */
  reservor_id?: Maybe<Scalars['String']>;
  /** Specific reservor metadata. { id, ... } */
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `reservor_role` should be used with `reservor_id`. Possible values: 'driver' | 'patient' */
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  /** DEPRECATED | Taxonomy approach. Should be used together with service. When you put service and service_type together, you know in which table to search for a service. Possible values: 'GENERAL' | 'SPECIALIZED'; corresponding to tables general_service | specialized_service */
  service_type?: Maybe<Scalars['String']>;
  /** Related to the reservation_status SHELVED. Reservations are shelved in order to manually assign them to inexperienced drivers. */
  shelved_at?: Maybe<Scalars['timestamptz']>;
  /** A unique 6 character short ID */
  short_id?: Maybe<Scalars['String']>;
  /** Actual start of the `reservation`.  Added by the `driver` */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Same as `departure_date` + `departure_time` from `quote` field */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Reservation_Status_Enum>;
  stripe_card_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID supplied via Voucherify integration */
  voucher_code?: Maybe<Scalars['String']>;
  /** A reason provided if/when voucher cannot be redeemed. Supplied via Voucherify integration. */
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  /** ID of the redemption object. Supplied via Voucherify integration. */
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** columns and relationships of "reservation_status" */
export interface Reservation_Status {
  __typename?: 'reservation_status';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  reservations: Array<Reservation>;
  /** An aggregate relationship */
  reservations_aggregate: Reservation_Aggregate;
  value: Scalars['String'];
}


/** columns and relationships of "reservation_status" */
export type Reservation_StatusReservationsArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


/** columns and relationships of "reservation_status" */
export type Reservation_StatusReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};

/** aggregated selection of "reservation_status" */
export interface Reservation_Status_Aggregate {
  __typename?: 'reservation_status_aggregate';
  aggregate?: Maybe<Reservation_Status_Aggregate_Fields>;
  nodes: Array<Reservation_Status>;
}

/** aggregate fields of "reservation_status" */
export interface Reservation_Status_Aggregate_Fields {
  __typename?: 'reservation_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reservation_Status_Max_Fields>;
  min?: Maybe<Reservation_Status_Min_Fields>;
}


/** aggregate fields of "reservation_status" */
export type Reservation_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reservation_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reservation_status". All fields are combined with a logical 'AND'. */
export interface Reservation_Status_Bool_Exp {
  _and?: Maybe<Array<Reservation_Status_Bool_Exp>>;
  _not?: Maybe<Reservation_Status_Bool_Exp>;
  _or?: Maybe<Array<Reservation_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  reservations?: Maybe<Reservation_Bool_Exp>;
  reservations_aggregate?: Maybe<Reservation_Aggregate_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "reservation_status" */
export enum Reservation_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ReservationStatusPkey = 'reservation_status_pkey'
}

export enum Reservation_Status_Enum {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  CanceledByPatient = 'CANCELED_BY_PATIENT',
  DeclinedByPreferredDriver = 'DECLINED_BY_PREFERRED_DRIVER',
  Finished = 'FINISHED',
  FinishReservationOverdue = 'FINISH_RESERVATION_OVERDUE',
  InProgress = 'IN_PROGRESS',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentInProgress = 'PAYMENT_IN_PROGRESS',
  Shelved = 'SHELVED',
  Waiting = 'WAITING'
}

/** Boolean expression to compare columns of type "reservation_status_enum". All fields are combined with logical 'AND'. */
export interface Reservation_Status_Enum_Comparison_Exp {
  _eq?: Maybe<Reservation_Status_Enum>;
  _in?: Maybe<Array<Reservation_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Reservation_Status_Enum>;
  _nin?: Maybe<Array<Reservation_Status_Enum>>;
}

/** input type for inserting data into table "reservation_status" */
export interface Reservation_Status_Insert_Input {
  description?: Maybe<Scalars['String']>;
  reservations?: Maybe<Reservation_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Reservation_Status_Max_Fields {
  __typename?: 'reservation_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Reservation_Status_Min_Fields {
  __typename?: 'reservation_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "reservation_status" */
export interface Reservation_Status_Mutation_Response {
  __typename?: 'reservation_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservation_Status>;
}

/** input type for inserting object relation for remote table "reservation_status" */
export interface Reservation_Status_Obj_Rel_Insert_Input {
  data: Reservation_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Reservation_Status_On_Conflict>;
}

/** on_conflict condition type for table "reservation_status" */
export interface Reservation_Status_On_Conflict {
  constraint: Reservation_Status_Constraint;
  update_columns?: Array<Reservation_Status_Update_Column>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "reservation_status". */
export interface Reservation_Status_Order_By {
  description?: Maybe<Order_By>;
  reservations_aggregate?: Maybe<Reservation_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
}

/** primary key columns input for table: reservation_status */
export interface Reservation_Status_Pk_Columns_Input {
  value: Scalars['String'];
}

/** select columns of table "reservation_status" */
export enum Reservation_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "reservation_status" */
export interface Reservation_Status_Set_Input {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "reservation_status" */
export interface Reservation_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Reservation_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Reservation_Status_Stream_Cursor_Value_Input {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "reservation_status" */
export enum Reservation_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export interface Reservation_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Reservation_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservation_Status_Bool_Exp;
}

/** aggregate stddev on columns */
export interface Reservation_Stddev_Fields {
  __typename?: 'reservation_stddev_fields';
  driver_rating?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['Float']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['Float']>;
  patient_rating?: Maybe<Scalars['Float']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Float']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['Float']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['Float']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
}

/** order by stddev() on columns of table "reservation" */
export interface Reservation_Stddev_Order_By {
  driver_rating?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Reservation_Stddev_Pop_Fields {
  __typename?: 'reservation_stddev_pop_fields';
  driver_rating?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['Float']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['Float']>;
  patient_rating?: Maybe<Scalars['Float']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Float']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['Float']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['Float']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
}

/** order by stddev_pop() on columns of table "reservation" */
export interface Reservation_Stddev_Pop_Order_By {
  driver_rating?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Reservation_Stddev_Samp_Fields {
  __typename?: 'reservation_stddev_samp_fields';
  driver_rating?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['Float']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['Float']>;
  patient_rating?: Maybe<Scalars['Float']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Float']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['Float']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['Float']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
}

/** order by stddev_samp() on columns of table "reservation" */
export interface Reservation_Stddev_Samp_Order_By {
  driver_rating?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
}

/** Streaming cursor of the table "reservation" */
export interface Reservation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Reservation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Reservation_Stream_Cursor_Value_Input {
  /** Timestamp of when the reservation was accepted by the driver */
  accepted_at?: Maybe<Scalars['timestamptz']>;
  /** Tracks changes for each `reservation` record */
  activity_log?: Maybe<Scalars['jsonb']>;
  administrator_note?: Maybe<Scalars['String']>;
  bank_transfer_payment_intent_id?: Maybe<Scalars['String']>;
  change_request_accepted_at?: Maybe<Scalars['timestamptz']>;
  change_request_changes?: Maybe<Scalars['jsonb']>;
  change_request_driver_action?: Maybe<Reservation_Change_Request_Action_Enum>;
  change_request_driver_response_timeout_at?: Maybe<Scalars['timestamptz']>;
  change_request_rejected_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Reference: https://www.iban.com/currency-codes */
  currency?: Maybe<Scalars['String']>;
  /** A list of all cancellations by the driver per reservation. Array of objects: { driver_id, cancelled_at, cancellation_reason } */
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `driver_id` should "always" fetch driver_profile table */
  driver_id?: Maybe<Scalars['String']>;
  /** Specific driver metadata. { id, ... } */
  driver_metadata?: Maybe<Scalars['jsonb']>;
  driver_rating?: Maybe<Scalars['numeric']>;
  driver_rating_feedback?: Maybe<Scalars['String']>;
  driver_suggested_ended_at?: Maybe<Scalars['timestamptz']>;
  driver_suggested_started_at?: Maybe<Scalars['timestamptz']>;
  /** Reference id to the reservation duplication source */
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Actual end of the `reservation`.  Added by the `driver` */
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** Related to `departure_date` + `departure_time` + `duration` from `quote` field */
  ends_at?: Maybe<Scalars['timestamptz']>;
  /** Description of extra costs that driver had to pay during reservation */
  extra_costs_text?: Maybe<Scalars['String']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['numeric']>;
  /** TODO: Implement after MVP */
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  is_manual?: Maybe<Scalars['Boolean']>;
  /** Used to determine whether the service/reservation has been provided in the supported city or in the nearby villages or suburbs */
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  /** Used for tracking trigger names in `activity_log`. Fallback if trigger name cannot be retrieved from session variables - usually via frontend. */
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** Everything that's read often (filterable fields, ...) should be on the top `reservation` level. Everything else should be in the metadata field. { additional_options[], price_estimate, departure_address, departure_lat, departure_lng, destination_address, destination_lat, destination_lng, departure_date, departure_time, distance, duration, license_plate, number_of_passengers, reservation_for, service, service_city } */
  metadata?: Maybe<Scalars['jsonb']>;
  /** A reason for the cancellation provided by the patient */
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  /** Timestamp of the cancellation by patient */
  patient_cancelled_at?: Maybe<Scalars['timestamptz']>;
  patient_rating?: Maybe<Scalars['numeric']>;
  patient_rating_feedback?: Maybe<Scalars['String']>;
  payment_errors?: Maybe<Scalars['jsonb']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Payment_Method_Enum>;
  /** Indicator (name, email, text) of a preferred driver for some reservors/reservees */
  preferred_driver?: Maybe<Scalars['String']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['numeric']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  /** transaction fees calculated when reservation is finished */
  price_billable_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['numeric']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  /** transaction fees calculated when reservation is created */
  price_estimate_transaction_fees?: Maybe<Scalars['jsonb']>;
  /** ON HOLD (22. 1. 2020) - We're not matching reservee's with existing accounts in our database yet.  | Taxonomy approach. `reservee_id` should be used with `reservee_role` */
  reservee_id?: Maybe<Scalars['String']>;
  /** Specific reservee metadata. { id, ... } */
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `reservee_role` should be used with `reservee_id`. Possible values: 'driver' | 'patient' */
  reservee_role?: Maybe<Scalars['String']>;
  /** Taxonomy approach. `reservor_id` shoud be used with `reservor_role` */
  reservor_id?: Maybe<Scalars['String']>;
  /** Specific reservor metadata. { id, ... } */
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  /** Taxonomy approach. `reservor_role` should be used with `reservor_id`. Possible values: 'driver' | 'patient' */
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  /** DEPRECATED | Taxonomy approach. Should be used together with service. When you put service and service_type together, you know in which table to search for a service. Possible values: 'GENERAL' | 'SPECIALIZED'; corresponding to tables general_service | specialized_service */
  service_type?: Maybe<Scalars['String']>;
  /** Related to the reservation_status SHELVED. Reservations are shelved in order to manually assign them to inexperienced drivers. */
  shelved_at?: Maybe<Scalars['timestamptz']>;
  /** A unique 6 character short ID */
  short_id?: Maybe<Scalars['String']>;
  /** Actual start of the `reservation`.  Added by the `driver` */
  started_at?: Maybe<Scalars['timestamptz']>;
  /** Same as `departure_date` + `departure_time` from `quote` field */
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Reservation_Status_Enum>;
  stripe_card_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** ID supplied via Voucherify integration */
  voucher_code?: Maybe<Scalars['String']>;
  /** A reason provided if/when voucher cannot be redeemed. Supplied via Voucherify integration. */
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  /** ID of the redemption object. Supplied via Voucherify integration. */
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Reservation_Sum_Fields {
  __typename?: 'reservation_sum_fields';
  driver_rating?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['numeric']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['numeric']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['numeric']>;
  patient_rating?: Maybe<Scalars['numeric']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Int']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['numeric']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['numeric']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
}

/** order by sum() on columns of table "reservation" */
export interface Reservation_Sum_Order_By {
  driver_rating?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
}

/** update columns of table "reservation" */
export enum Reservation_Update_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  AdministratorNote = 'administrator_note',
  /** column name */
  BankTransferPaymentIntentId = 'bank_transfer_payment_intent_id',
  /** column name */
  ChangeRequestAcceptedAt = 'change_request_accepted_at',
  /** column name */
  ChangeRequestChanges = 'change_request_changes',
  /** column name */
  ChangeRequestDriverAction = 'change_request_driver_action',
  /** column name */
  ChangeRequestDriverResponseTimeoutAt = 'change_request_driver_response_timeout_at',
  /** column name */
  ChangeRequestRejectedAt = 'change_request_rejected_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DriverCancellationReasons = 'driver_cancellation_reasons',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverMetadata = 'driver_metadata',
  /** column name */
  DriverRating = 'driver_rating',
  /** column name */
  DriverRatingFeedback = 'driver_rating_feedback',
  /** column name */
  DriverSuggestedEndedAt = 'driver_suggested_ended_at',
  /** column name */
  DriverSuggestedStartedAt = 'driver_suggested_started_at',
  /** column name */
  DuplicationReferenceId = 'duplication_reference_id',
  /** column name */
  DurationBillable = 'duration_billable',
  /** column name */
  DurationEstimate = 'duration_estimate',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraCostsText = 'extra_costs_text',
  /** column name */
  ExtraCostsValue = 'extra_costs_value',
  /** column name */
  HistorySnapshot = 'history_snapshot',
  /** column name */
  Id = 'id',
  /** column name */
  IsManual = 'is_manual',
  /** column name */
  IsProvidedInCity = 'is_provided_in_city',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PatientCancellationReason = 'patient_cancellation_reason',
  /** column name */
  PatientCancelledAt = 'patient_cancelled_at',
  /** column name */
  PatientRating = 'patient_rating',
  /** column name */
  PatientRatingFeedback = 'patient_rating_feedback',
  /** column name */
  PaymentErrors = 'payment_errors',
  /** column name */
  PaymentFailedRemindersSentCount = 'payment_failed_reminders_sent_count',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PreferredDriver = 'preferred_driver',
  /** column name */
  PriceBillable = 'price_billable',
  /** column name */
  PriceBillableDiscounted = 'price_billable_discounted',
  /** column name */
  PriceBillableTransactionFees = 'price_billable_transaction_fees',
  /** column name */
  PriceEstimate = 'price_estimate',
  /** column name */
  PriceEstimateDiscounted = 'price_estimate_discounted',
  /** column name */
  PriceEstimateTransactionFees = 'price_estimate_transaction_fees',
  /** column name */
  ReserveeId = 'reservee_id',
  /** column name */
  ReserveeMetadata = 'reservee_metadata',
  /** column name */
  ReserveeRole = 'reservee_role',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  ReservorMetadata = 'reservor_metadata',
  /** column name */
  ReservorRole = 'reservor_role',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  ShelvedAt = 'shelved_at',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCardPaymentIntentId = 'stripe_card_payment_intent_id',
  /** column name */
  StripeCheckoutSessionId = 'stripe_checkout_session_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherCode = 'voucher_code',
  /** column name */
  VoucherNotApplicableReason = 'voucher_not_applicable_reason',
  /** column name */
  VoucherRedemptionId = 'voucher_redemption_id'
}

export interface Reservation_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Reservation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Reservation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Reservation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Reservation_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Reservation_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Reservation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Reservation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservation_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Reservation_Var_Pop_Fields {
  __typename?: 'reservation_var_pop_fields';
  driver_rating?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['Float']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['Float']>;
  patient_rating?: Maybe<Scalars['Float']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Float']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['Float']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['Float']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
}

/** order by var_pop() on columns of table "reservation" */
export interface Reservation_Var_Pop_Order_By {
  driver_rating?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Reservation_Var_Samp_Fields {
  __typename?: 'reservation_var_samp_fields';
  driver_rating?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['Float']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['Float']>;
  patient_rating?: Maybe<Scalars['Float']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Float']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['Float']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['Float']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
}

/** order by var_samp() on columns of table "reservation" */
export interface Reservation_Var_Samp_Order_By {
  driver_rating?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Reservation_Variance_Fields {
  __typename?: 'reservation_variance_fields';
  driver_rating?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Scalars['Float']>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Scalars['Float']>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Scalars['Float']>;
  patient_rating?: Maybe<Scalars['Float']>;
  payment_failed_reminders_sent_count?: Maybe<Scalars['Float']>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Scalars['Float']>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_billable_transaction_fees */
  price_billable_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Scalars['Float']>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Scalars['Float']>;
  /** Returns _driver_amount from price_estimate_transaction_fees */
  price_estimate_transaction_fees_driver_amount?: Maybe<Scalars['numeric']>;
}

/** order by variance() on columns of table "reservation" */
export interface Reservation_Variance_Order_By {
  driver_rating?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_billable?: Maybe<Order_By>;
  /** Duration in hours with decimals */
  duration_estimate?: Maybe<Order_By>;
  /** Value of extra costs that driver had to pay during reservation */
  extra_costs_value?: Maybe<Order_By>;
  patient_rating?: Maybe<Order_By>;
  payment_failed_reminders_sent_count?: Maybe<Order_By>;
  /** Price that is calculated from the actual, billable data (duration (in hours) and/or distance) */
  price_billable?: Maybe<Order_By>;
  /** Billable price deducted by the discount amount. Supplied via Voucherify integration. */
  price_billable_discounted?: Maybe<Order_By>;
  /** Price that is calculated from estimated data (duration (in hours) and/or distance) */
  price_estimate?: Maybe<Order_By>;
  /** Estimated price deducted by the discount amount. Supplied via Voucherify integration. */
  price_estimate_discounted?: Maybe<Order_By>;
}

/** Reviews from different sources around the web */
export interface Review {
  __typename?: 'review';
  comment_eng?: Maybe<Scalars['String']>;
  comment_ita?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  rating: Scalars['numeric'];
  reply_ita?: Maybe<Scalars['String']>;
  review_source?: Maybe<Scalars['String']>;
  reviewer_name?: Maybe<Scalars['String']>;
  reviewer_photo_url?: Maybe<Scalars['String']>;
  source_comment_eng?: Maybe<Scalars['String']>;
  source_comment_ita?: Maybe<Scalars['String']>;
  source_created_at?: Maybe<Scalars['timestamptz']>;
  source_reply_ita?: Maybe<Scalars['String']>;
  source_review_id?: Maybe<Scalars['String']>;
  source_reviewer_name?: Maybe<Scalars['String']>;
  source_updated_at?: Maybe<Scalars['timestamptz']>;
  tags: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
}


/** Reviews from different sources around the web */
export type ReviewTagsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "review" */
export interface Review_Aggregate {
  __typename?: 'review_aggregate';
  aggregate?: Maybe<Review_Aggregate_Fields>;
  nodes: Array<Review>;
}

/** aggregate fields of "review" */
export interface Review_Aggregate_Fields {
  __typename?: 'review_aggregate_fields';
  avg?: Maybe<Review_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Review_Max_Fields>;
  min?: Maybe<Review_Min_Fields>;
  stddev?: Maybe<Review_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Sum_Fields>;
  var_pop?: Maybe<Review_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Var_Samp_Fields>;
  variance?: Maybe<Review_Variance_Fields>;
}


/** aggregate fields of "review" */
export type Review_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Review_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Review_Append_Input {
  tags?: Maybe<Scalars['jsonb']>;
}

/** aggregate avg on columns */
export interface Review_Avg_Fields {
  __typename?: 'review_avg_fields';
  rating?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "review". All fields are combined with a logical 'AND'. */
export interface Review_Bool_Exp {
  _and?: Maybe<Array<Review_Bool_Exp>>;
  _not?: Maybe<Review_Bool_Exp>;
  _or?: Maybe<Array<Review_Bool_Exp>>;
  comment_eng?: Maybe<String_Comparison_Exp>;
  comment_ita?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  rating?: Maybe<Numeric_Comparison_Exp>;
  reply_ita?: Maybe<String_Comparison_Exp>;
  review_source?: Maybe<String_Comparison_Exp>;
  reviewer_name?: Maybe<String_Comparison_Exp>;
  reviewer_photo_url?: Maybe<String_Comparison_Exp>;
  source_comment_eng?: Maybe<String_Comparison_Exp>;
  source_comment_ita?: Maybe<String_Comparison_Exp>;
  source_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  source_reply_ita?: Maybe<String_Comparison_Exp>;
  source_review_id?: Maybe<String_Comparison_Exp>;
  source_reviewer_name?: Maybe<String_Comparison_Exp>;
  source_updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  tags?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "review" */
export enum Review_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewPkey = 'review_pkey',
  /** unique or primary key constraint on columns "source_review_id" */
  ReviewSourceReviewIdKey = 'review_source_review_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Review_Delete_At_Path_Input {
  tags?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Review_Delete_Elem_Input {
  tags?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Review_Delete_Key_Input {
  tags?: Maybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "review" */
export interface Review_Inc_Input {
  rating?: Maybe<Scalars['numeric']>;
}

/** input type for inserting data into table "review" */
export interface Review_Insert_Input {
  comment_eng?: Maybe<Scalars['String']>;
  comment_ita?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  reply_ita?: Maybe<Scalars['String']>;
  review_source?: Maybe<Scalars['String']>;
  reviewer_name?: Maybe<Scalars['String']>;
  reviewer_photo_url?: Maybe<Scalars['String']>;
  source_comment_eng?: Maybe<Scalars['String']>;
  source_comment_ita?: Maybe<Scalars['String']>;
  source_created_at?: Maybe<Scalars['timestamptz']>;
  source_reply_ita?: Maybe<Scalars['String']>;
  source_review_id?: Maybe<Scalars['String']>;
  source_reviewer_name?: Maybe<Scalars['String']>;
  source_updated_at?: Maybe<Scalars['timestamptz']>;
  tags?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Review_Max_Fields {
  __typename?: 'review_max_fields';
  comment_eng?: Maybe<Scalars['String']>;
  comment_ita?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  reply_ita?: Maybe<Scalars['String']>;
  review_source?: Maybe<Scalars['String']>;
  reviewer_name?: Maybe<Scalars['String']>;
  reviewer_photo_url?: Maybe<Scalars['String']>;
  source_comment_eng?: Maybe<Scalars['String']>;
  source_comment_ita?: Maybe<Scalars['String']>;
  source_created_at?: Maybe<Scalars['timestamptz']>;
  source_reply_ita?: Maybe<Scalars['String']>;
  source_review_id?: Maybe<Scalars['String']>;
  source_reviewer_name?: Maybe<Scalars['String']>;
  source_updated_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Review_Min_Fields {
  __typename?: 'review_min_fields';
  comment_eng?: Maybe<Scalars['String']>;
  comment_ita?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  reply_ita?: Maybe<Scalars['String']>;
  review_source?: Maybe<Scalars['String']>;
  reviewer_name?: Maybe<Scalars['String']>;
  reviewer_photo_url?: Maybe<Scalars['String']>;
  source_comment_eng?: Maybe<Scalars['String']>;
  source_comment_ita?: Maybe<Scalars['String']>;
  source_created_at?: Maybe<Scalars['timestamptz']>;
  source_reply_ita?: Maybe<Scalars['String']>;
  source_review_id?: Maybe<Scalars['String']>;
  source_reviewer_name?: Maybe<Scalars['String']>;
  source_updated_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "review" */
export interface Review_Mutation_Response {
  __typename?: 'review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Review>;
}

/** on_conflict condition type for table "review" */
export interface Review_On_Conflict {
  constraint: Review_Constraint;
  update_columns?: Array<Review_Update_Column>;
  where?: Maybe<Review_Bool_Exp>;
}

/** Ordering options when selecting data from "review". */
export interface Review_Order_By {
  comment_eng?: Maybe<Order_By>;
  comment_ita?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  reply_ita?: Maybe<Order_By>;
  review_source?: Maybe<Order_By>;
  reviewer_name?: Maybe<Order_By>;
  reviewer_photo_url?: Maybe<Order_By>;
  source_comment_eng?: Maybe<Order_By>;
  source_comment_ita?: Maybe<Order_By>;
  source_created_at?: Maybe<Order_By>;
  source_reply_ita?: Maybe<Order_By>;
  source_review_id?: Maybe<Order_By>;
  source_reviewer_name?: Maybe<Order_By>;
  source_updated_at?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** primary key columns input for table: review */
export interface Review_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Review_Prepend_Input {
  tags?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "review" */
export enum Review_Select_Column {
  /** column name */
  CommentEng = 'comment_eng',
  /** column name */
  CommentIta = 'comment_ita',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReplyIta = 'reply_ita',
  /** column name */
  ReviewSource = 'review_source',
  /** column name */
  ReviewerName = 'reviewer_name',
  /** column name */
  ReviewerPhotoUrl = 'reviewer_photo_url',
  /** column name */
  SourceCommentEng = 'source_comment_eng',
  /** column name */
  SourceCommentIta = 'source_comment_ita',
  /** column name */
  SourceCreatedAt = 'source_created_at',
  /** column name */
  SourceReplyIta = 'source_reply_ita',
  /** column name */
  SourceReviewId = 'source_review_id',
  /** column name */
  SourceReviewerName = 'source_reviewer_name',
  /** column name */
  SourceUpdatedAt = 'source_updated_at',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "review" */
export interface Review_Set_Input {
  comment_eng?: Maybe<Scalars['String']>;
  comment_ita?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  reply_ita?: Maybe<Scalars['String']>;
  review_source?: Maybe<Scalars['String']>;
  reviewer_name?: Maybe<Scalars['String']>;
  reviewer_photo_url?: Maybe<Scalars['String']>;
  source_comment_eng?: Maybe<Scalars['String']>;
  source_comment_ita?: Maybe<Scalars['String']>;
  source_created_at?: Maybe<Scalars['timestamptz']>;
  source_reply_ita?: Maybe<Scalars['String']>;
  source_review_id?: Maybe<Scalars['String']>;
  source_reviewer_name?: Maybe<Scalars['String']>;
  source_updated_at?: Maybe<Scalars['timestamptz']>;
  tags?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate stddev on columns */
export interface Review_Stddev_Fields {
  __typename?: 'review_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Review_Stddev_Pop_Fields {
  __typename?: 'review_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Review_Stddev_Samp_Fields {
  __typename?: 'review_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "review" */
export interface Review_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Review_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Review_Stream_Cursor_Value_Input {
  comment_eng?: Maybe<Scalars['String']>;
  comment_ita?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['numeric']>;
  reply_ita?: Maybe<Scalars['String']>;
  review_source?: Maybe<Scalars['String']>;
  reviewer_name?: Maybe<Scalars['String']>;
  reviewer_photo_url?: Maybe<Scalars['String']>;
  source_comment_eng?: Maybe<Scalars['String']>;
  source_comment_ita?: Maybe<Scalars['String']>;
  source_created_at?: Maybe<Scalars['timestamptz']>;
  source_reply_ita?: Maybe<Scalars['String']>;
  source_review_id?: Maybe<Scalars['String']>;
  source_reviewer_name?: Maybe<Scalars['String']>;
  source_updated_at?: Maybe<Scalars['timestamptz']>;
  tags?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate sum on columns */
export interface Review_Sum_Fields {
  __typename?: 'review_sum_fields';
  rating?: Maybe<Scalars['numeric']>;
}

/** update columns of table "review" */
export enum Review_Update_Column {
  /** column name */
  CommentEng = 'comment_eng',
  /** column name */
  CommentIta = 'comment_ita',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReplyIta = 'reply_ita',
  /** column name */
  ReviewSource = 'review_source',
  /** column name */
  ReviewerName = 'reviewer_name',
  /** column name */
  ReviewerPhotoUrl = 'reviewer_photo_url',
  /** column name */
  SourceCommentEng = 'source_comment_eng',
  /** column name */
  SourceCommentIta = 'source_comment_ita',
  /** column name */
  SourceCreatedAt = 'source_created_at',
  /** column name */
  SourceReplyIta = 'source_reply_ita',
  /** column name */
  SourceReviewId = 'source_review_id',
  /** column name */
  SourceReviewerName = 'source_reviewer_name',
  /** column name */
  SourceUpdatedAt = 'source_updated_at',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at'
}

export interface Review_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Review_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Review_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Review_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Review_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Review_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Review_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Review_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Review_Var_Pop_Fields {
  __typename?: 'review_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Review_Var_Samp_Fields {
  __typename?: 'review_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Review_Variance_Fields {
  __typename?: 'review_variance_fields';
  rating?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "service" */
export interface Service {
  __typename?: 'service';
  active: Scalars['Boolean'];
  /** Should be a descriptive `SCREAMING_SNAKE_CASE` */
  id: Scalars['String'];
  /** Generally used with `SPECIALIZED` services. It lists requirements that the `driver` needs to posses/know/know how to handle. */
  requirements?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  reservations: Array<Reservation>;
  /** An aggregate relationship */
  reservations_aggregate: Reservation_Aggregate;
  /** interface { eng: string; ita: string; ... } */
  translations?: Maybe<Scalars['jsonb']>;
  /** enum { GENERAL | SPECIALIZED } Anyone can perform `GENERAL` services. Only verified users can perform specific `SPECIALIZED` services (eg. lethal injection). */
  type?: Maybe<Scalars['String']>;
}


/** columns and relationships of "service" */
export type ServiceRequirementsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "service" */
export type ServiceReservationsArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceTranslationsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "service" */
export interface Service_Aggregate {
  __typename?: 'service_aggregate';
  aggregate?: Maybe<Service_Aggregate_Fields>;
  nodes: Array<Service>;
}

/** aggregate fields of "service" */
export interface Service_Aggregate_Fields {
  __typename?: 'service_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Service_Max_Fields>;
  min?: Maybe<Service_Min_Fields>;
}


/** aggregate fields of "service" */
export type Service_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Service_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Service_Append_Input {
  /** Generally used with `SPECIALIZED` services. It lists requirements that the `driver` needs to posses/know/know how to handle. */
  requirements?: Maybe<Scalars['jsonb']>;
  /** interface { eng: string; ita: string; ... } */
  translations?: Maybe<Scalars['jsonb']>;
}

/** Boolean expression to filter rows from the table "service". All fields are combined with a logical 'AND'. */
export interface Service_Bool_Exp {
  _and?: Maybe<Array<Service_Bool_Exp>>;
  _not?: Maybe<Service_Bool_Exp>;
  _or?: Maybe<Array<Service_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  requirements?: Maybe<Jsonb_Comparison_Exp>;
  reservations?: Maybe<Reservation_Bool_Exp>;
  reservations_aggregate?: Maybe<Reservation_Aggregate_Bool_Exp>;
  translations?: Maybe<Jsonb_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "service" */
export enum Service_Constraint {
  /** unique or primary key constraint on columns "id" */
  GeneralServicePkey = 'general_service_pkey',
  /** unique or primary key constraint on columns "id" */
  GeneralServiceValueKey = 'general_service_value_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Service_Delete_At_Path_Input {
  /** Generally used with `SPECIALIZED` services. It lists requirements that the `driver` needs to posses/know/know how to handle. */
  requirements?: Maybe<Array<Scalars['String']>>;
  /** interface { eng: string; ita: string; ... } */
  translations?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Service_Delete_Elem_Input {
  /** Generally used with `SPECIALIZED` services. It lists requirements that the `driver` needs to posses/know/know how to handle. */
  requirements?: Maybe<Scalars['Int']>;
  /** interface { eng: string; ita: string; ... } */
  translations?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Service_Delete_Key_Input {
  /** Generally used with `SPECIALIZED` services. It lists requirements that the `driver` needs to posses/know/know how to handle. */
  requirements?: Maybe<Scalars['String']>;
  /** interface { eng: string; ita: string; ... } */
  translations?: Maybe<Scalars['String']>;
}

/** input type for inserting data into table "service" */
export interface Service_Insert_Input {
  active?: Maybe<Scalars['Boolean']>;
  /** Should be a descriptive `SCREAMING_SNAKE_CASE` */
  id?: Maybe<Scalars['String']>;
  /** Generally used with `SPECIALIZED` services. It lists requirements that the `driver` needs to posses/know/know how to handle. */
  requirements?: Maybe<Scalars['jsonb']>;
  reservations?: Maybe<Reservation_Arr_Rel_Insert_Input>;
  /** interface { eng: string; ita: string; ... } */
  translations?: Maybe<Scalars['jsonb']>;
  /** enum { GENERAL | SPECIALIZED } Anyone can perform `GENERAL` services. Only verified users can perform specific `SPECIALIZED` services (eg. lethal injection). */
  type?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Service_Max_Fields {
  __typename?: 'service_max_fields';
  /** Should be a descriptive `SCREAMING_SNAKE_CASE` */
  id?: Maybe<Scalars['String']>;
  /** enum { GENERAL | SPECIALIZED } Anyone can perform `GENERAL` services. Only verified users can perform specific `SPECIALIZED` services (eg. lethal injection). */
  type?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Service_Min_Fields {
  __typename?: 'service_min_fields';
  /** Should be a descriptive `SCREAMING_SNAKE_CASE` */
  id?: Maybe<Scalars['String']>;
  /** enum { GENERAL | SPECIALIZED } Anyone can perform `GENERAL` services. Only verified users can perform specific `SPECIALIZED` services (eg. lethal injection). */
  type?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "service" */
export interface Service_Mutation_Response {
  __typename?: 'service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service>;
}

/** input type for inserting object relation for remote table "service" */
export interface Service_Obj_Rel_Insert_Input {
  data: Service_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Service_On_Conflict>;
}

/** on_conflict condition type for table "service" */
export interface Service_On_Conflict {
  constraint: Service_Constraint;
  update_columns?: Array<Service_Update_Column>;
  where?: Maybe<Service_Bool_Exp>;
}

/** Ordering options when selecting data from "service". */
export interface Service_Order_By {
  active?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  requirements?: Maybe<Order_By>;
  reservations_aggregate?: Maybe<Reservation_Aggregate_Order_By>;
  translations?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
}

/** primary key columns input for table: service */
export interface Service_Pk_Columns_Input {
  /** Should be a descriptive `SCREAMING_SNAKE_CASE` */
  id: Scalars['String'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Service_Prepend_Input {
  /** Generally used with `SPECIALIZED` services. It lists requirements that the `driver` needs to posses/know/know how to handle. */
  requirements?: Maybe<Scalars['jsonb']>;
  /** interface { eng: string; ita: string; ... } */
  translations?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "service" */
export enum Service_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  Requirements = 'requirements',
  /** column name */
  Translations = 'translations',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "service" */
export interface Service_Set_Input {
  active?: Maybe<Scalars['Boolean']>;
  /** Should be a descriptive `SCREAMING_SNAKE_CASE` */
  id?: Maybe<Scalars['String']>;
  /** Generally used with `SPECIALIZED` services. It lists requirements that the `driver` needs to posses/know/know how to handle. */
  requirements?: Maybe<Scalars['jsonb']>;
  /** interface { eng: string; ita: string; ... } */
  translations?: Maybe<Scalars['jsonb']>;
  /** enum { GENERAL | SPECIALIZED } Anyone can perform `GENERAL` services. Only verified users can perform specific `SPECIALIZED` services (eg. lethal injection). */
  type?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "service" */
export interface Service_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Service_Stream_Cursor_Value_Input {
  active?: Maybe<Scalars['Boolean']>;
  /** Should be a descriptive `SCREAMING_SNAKE_CASE` */
  id?: Maybe<Scalars['String']>;
  /** Generally used with `SPECIALIZED` services. It lists requirements that the `driver` needs to posses/know/know how to handle. */
  requirements?: Maybe<Scalars['jsonb']>;
  /** interface { eng: string; ita: string; ... } */
  translations?: Maybe<Scalars['jsonb']>;
  /** enum { GENERAL | SPECIALIZED } Anyone can perform `GENERAL` services. Only verified users can perform specific `SPECIALIZED` services (eg. lethal injection). */
  type?: Maybe<Scalars['String']>;
}

/** update columns of table "service" */
export enum Service_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  Requirements = 'requirements',
  /** column name */
  Translations = 'translations',
  /** column name */
  Type = 'type'
}

export interface Service_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Service_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Service_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Service_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Service_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Service_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Service_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Bool_Exp;
}

export interface Subscription_Root {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_error" */
  account_error: Array<Account_Error>;
  /** fetch aggregated fields from the table: "account_error" */
  account_error_aggregate: Account_Error_Aggregate;
  /** fetch data from the table: "account_error" using primary key columns */
  account_error_by_pk?: Maybe<Account_Error>;
  /** fetch data from the table in a streaming manner: "account_error" */
  account_error_stream: Array<Account_Error>;
  /** fetch data from the table: "account_error_type" */
  account_error_type: Array<Account_Error_Type>;
  /** fetch aggregated fields from the table: "account_error_type" */
  account_error_type_aggregate: Account_Error_Type_Aggregate;
  /** fetch data from the table: "account_error_type" using primary key columns */
  account_error_type_by_pk?: Maybe<Account_Error_Type>;
  /** fetch data from the table in a streaming manner: "account_error_type" */
  account_error_type_stream: Array<Account_Error_Type>;
  /** fetch data from the table: "account_feedback" */
  account_feedback: Array<Account_Feedback>;
  /** fetch aggregated fields from the table: "account_feedback" */
  account_feedback_aggregate: Account_Feedback_Aggregate;
  /** fetch data from the table: "account_feedback" using primary key columns */
  account_feedback_by_pk?: Maybe<Account_Feedback>;
  /** fetch data from the table in a streaming manner: "account_feedback" */
  account_feedback_stream: Array<Account_Feedback>;
  /** fetch data from the table in a streaming manner: "account" */
  account_stream: Array<Account>;
  /** fetch data from the table: "administrator_profile" */
  administrator_profile: Array<Administrator_Profile>;
  /** fetch aggregated fields from the table: "administrator_profile" */
  administrator_profile_aggregate: Administrator_Profile_Aggregate;
  /** fetch data from the table: "administrator_profile" using primary key columns */
  administrator_profile_by_pk?: Maybe<Administrator_Profile>;
  /** fetch data from the table in a streaming manner: "administrator_profile" */
  administrator_profile_stream: Array<Administrator_Profile>;
  /** fetch data from the table: "carvoila.district" */
  carvoila_district: Array<Carvoila_District>;
  /** fetch aggregated fields from the table: "carvoila.district" */
  carvoila_district_aggregate: Carvoila_District_Aggregate;
  /** fetch data from the table: "carvoila.district" using primary key columns */
  carvoila_district_by_pk?: Maybe<Carvoila_District>;
  /** fetch data from the table in a streaming manner: "carvoila.district" */
  carvoila_district_stream: Array<Carvoila_District>;
  /** fetch data from the table: "carvoila.driver" */
  carvoila_driver: Array<Carvoila_Driver>;
  /** fetch aggregated fields from the table: "carvoila.driver" */
  carvoila_driver_aggregate: Carvoila_Driver_Aggregate;
  /** fetch data from the table: "carvoila.driver" using primary key columns */
  carvoila_driver_by_pk?: Maybe<Carvoila_Driver>;
  /** fetch data from the table: "carvoila.driver_status" */
  carvoila_driver_status: Array<Carvoila_Driver_Status>;
  /** fetch aggregated fields from the table: "carvoila.driver_status" */
  carvoila_driver_status_aggregate: Carvoila_Driver_Status_Aggregate;
  /** fetch data from the table: "carvoila.driver_status" using primary key columns */
  carvoila_driver_status_by_pk?: Maybe<Carvoila_Driver_Status>;
  /** fetch data from the table in a streaming manner: "carvoila.driver_status" */
  carvoila_driver_status_stream: Array<Carvoila_Driver_Status>;
  /** fetch data from the table in a streaming manner: "carvoila.driver" */
  carvoila_driver_stream: Array<Carvoila_Driver>;
  /** fetch data from the table: "city" */
  city: Array<City>;
  /** fetch aggregated fields from the table: "city" */
  city_aggregate: City_Aggregate;
  /** fetch data from the table: "city" using primary key columns */
  city_by_pk?: Maybe<City>;
  /** fetch data from the table in a streaming manner: "city" */
  city_stream: Array<City>;
  /** fetch data from the table: "deployment" */
  deployment: Array<Deployment>;
  /** fetch aggregated fields from the table: "deployment" */
  deployment_aggregate: Deployment_Aggregate;
  /** fetch data from the table: "deployment" using primary key columns */
  deployment_by_pk?: Maybe<Deployment>;
  /** fetch data from the table in a streaming manner: "deployment" */
  deployment_stream: Array<Deployment>;
  /** fetch data from the table: "deployment_type" */
  deployment_type: Array<Deployment_Type>;
  /** fetch aggregated fields from the table: "deployment_type" */
  deployment_type_aggregate: Deployment_Type_Aggregate;
  /** fetch data from the table: "deployment_type" using primary key columns */
  deployment_type_by_pk?: Maybe<Deployment_Type>;
  /** fetch data from the table in a streaming manner: "deployment_type" */
  deployment_type_stream: Array<Deployment_Type>;
  /** fetch data from the table: "driver_profile" */
  driver_profile: Array<Driver_Profile>;
  /** fetch aggregated fields from the table: "driver_profile" */
  driver_profile_aggregate: Driver_Profile_Aggregate;
  /** fetch data from the table: "driver_profile" using primary key columns */
  driver_profile_by_pk?: Maybe<Driver_Profile>;
  /** fetch data from the table in a streaming manner: "driver_profile" */
  driver_profile_stream: Array<Driver_Profile>;
  /** fetch data from the table: "driver_status" */
  driver_status: Array<Driver_Status>;
  /** fetch aggregated fields from the table: "driver_status" */
  driver_status_aggregate: Driver_Status_Aggregate;
  /** fetch data from the table: "driver_status" using primary key columns */
  driver_status_by_pk?: Maybe<Driver_Status>;
  /** fetch data from the table in a streaming manner: "driver_status" */
  driver_status_stream: Array<Driver_Status>;
  /** execute function "fn_stats_get_general_user" which returns "vws_stats_general_user" */
  fn_stats_get_general_user: Array<Vws_Stats_General_User>;
  /** execute function "fn_stats_get_general_user" and query aggregates on result of table type "vws_stats_general_user" */
  fn_stats_get_general_user_aggregate: Vws_Stats_General_User_Aggregate;
  /** execute function "fn_stats_get_pareto_distribution_account_reservation" which returns "vws_stats_get_pareto_distribution_account_reservation" */
  fn_stats_get_pareto_distribution_account_reservation: Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation>;
  /** execute function "fn_stats_get_pareto_distribution_account_reservation" and query aggregates on result of table type "vws_stats_get_pareto_distribution_account_reservation" */
  fn_stats_get_pareto_distribution_account_reservation_aggregate: Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Aggregate;
  /** execute function "fn_stats_get_voucher_uses" which returns "vws_stats_get_voucher_uses" */
  fn_stats_get_voucher_uses: Array<Vws_Stats_Get_Voucher_Uses>;
  /** execute function "fn_stats_get_voucher_uses" and query aggregates on result of table type "vws_stats_get_voucher_uses" */
  fn_stats_get_voucher_uses_aggregate: Vws_Stats_Get_Voucher_Uses_Aggregate;
  /** fetch data from the table: "manual_driver" */
  manual_driver: Array<Manual_Driver>;
  /** fetch aggregated fields from the table: "manual_driver" */
  manual_driver_aggregate: Manual_Driver_Aggregate;
  /** fetch data from the table: "manual_driver" using primary key columns */
  manual_driver_by_pk?: Maybe<Manual_Driver>;
  /** fetch data from the table in a streaming manner: "manual_driver" */
  manual_driver_stream: Array<Manual_Driver>;
  /** fetch data from the table: "manual_patient" */
  manual_patient: Array<Manual_Patient>;
  /** fetch aggregated fields from the table: "manual_patient" */
  manual_patient_aggregate: Manual_Patient_Aggregate;
  /** fetch data from the table: "manual_patient" using primary key columns */
  manual_patient_by_pk?: Maybe<Manual_Patient>;
  /** fetch data from the table in a streaming manner: "manual_patient" */
  manual_patient_stream: Array<Manual_Patient>;
  /** fetch data from the table: "manual_reservation" */
  manual_reservation: Array<Manual_Reservation>;
  /** fetch aggregated fields from the table: "manual_reservation" */
  manual_reservation_aggregate: Manual_Reservation_Aggregate;
  /** fetch data from the table: "manual_reservation" using primary key columns */
  manual_reservation_by_pk?: Maybe<Manual_Reservation>;
  /** fetch data from the table in a streaming manner: "manual_reservation" */
  manual_reservation_stream: Array<Manual_Reservation>;
  /** fetch data from the table: "patient_profile" */
  patient_profile: Array<Patient_Profile>;
  /** fetch aggregated fields from the table: "patient_profile" */
  patient_profile_aggregate: Patient_Profile_Aggregate;
  /** fetch data from the table: "patient_profile" using primary key columns */
  patient_profile_by_pk?: Maybe<Patient_Profile>;
  /** fetch data from the table in a streaming manner: "patient_profile" */
  patient_profile_stream: Array<Patient_Profile>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>;
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate;
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>;
  /** fetch data from the table in a streaming manner: "payment_method" */
  payment_method_stream: Array<Payment_Method>;
  /** fetch data from the table in a streaming manner: "payment" */
  payment_stream: Array<Payment>;
  /** fetch data from the table: "reservation" */
  reservation: Array<Reservation>;
  /** fetch aggregated fields from the table: "reservation" */
  reservation_aggregate: Reservation_Aggregate;
  /** fetch data from the table: "reservation" using primary key columns */
  reservation_by_pk?: Maybe<Reservation>;
  /** fetch data from the table: "reservation_change_request_action" */
  reservation_change_request_action: Array<Reservation_Change_Request_Action>;
  /** fetch aggregated fields from the table: "reservation_change_request_action" */
  reservation_change_request_action_aggregate: Reservation_Change_Request_Action_Aggregate;
  /** fetch data from the table: "reservation_change_request_action" using primary key columns */
  reservation_change_request_action_by_pk?: Maybe<Reservation_Change_Request_Action>;
  /** fetch data from the table in a streaming manner: "reservation_change_request_action" */
  reservation_change_request_action_stream: Array<Reservation_Change_Request_Action>;
  /** fetch data from the table: "reservation_status" */
  reservation_status: Array<Reservation_Status>;
  /** fetch aggregated fields from the table: "reservation_status" */
  reservation_status_aggregate: Reservation_Status_Aggregate;
  /** fetch data from the table: "reservation_status" using primary key columns */
  reservation_status_by_pk?: Maybe<Reservation_Status>;
  /** fetch data from the table in a streaming manner: "reservation_status" */
  reservation_status_stream: Array<Reservation_Status>;
  /** fetch data from the table in a streaming manner: "reservation" */
  reservation_stream: Array<Reservation>;
  /** fetch data from the table: "review" */
  review: Array<Review>;
  /** fetch aggregated fields from the table: "review" */
  review_aggregate: Review_Aggregate;
  /** fetch data from the table: "review" using primary key columns */
  review_by_pk?: Maybe<Review>;
  /** fetch data from the table in a streaming manner: "review" */
  review_stream: Array<Review>;
  /** fetch data from the table: "service" */
  service: Array<Service>;
  /** fetch aggregated fields from the table: "service" */
  service_aggregate: Service_Aggregate;
  /** fetch data from the table: "service" using primary key columns */
  service_by_pk?: Maybe<Service>;
  /** fetch data from the table in a streaming manner: "service" */
  service_stream: Array<Service>;
  /** fetch data from the table: "sync" */
  sync: Array<Sync>;
  /** fetch aggregated fields from the table: "sync" */
  sync_aggregate: Sync_Aggregate;
  /** fetch data from the table: "sync" using primary key columns */
  sync_by_pk?: Maybe<Sync>;
  /** fetch data from the table in a streaming manner: "sync" */
  sync_stream: Array<Sync>;
  /** An array relationship */
  voucher: Array<Voucher>;
  /** An aggregate relationship */
  voucher_aggregate: Voucher_Aggregate;
  /** fetch data from the table: "voucher" using primary key columns */
  voucher_by_pk?: Maybe<Voucher>;
  /** fetch data from the table: "voucher_redemption" */
  voucher_redemption: Array<Voucher_Redemption>;
  /** fetch aggregated fields from the table: "voucher_redemption" */
  voucher_redemption_aggregate: Voucher_Redemption_Aggregate;
  /** fetch data from the table: "voucher_redemption" using primary key columns */
  voucher_redemption_by_pk?: Maybe<Voucher_Redemption>;
  /** fetch data from the table in a streaming manner: "voucher_redemption" */
  voucher_redemption_stream: Array<Voucher_Redemption>;
  /** fetch data from the table in a streaming manner: "voucher" */
  voucher_stream: Array<Voucher>;
  /** fetch data from the table: "vw_admin_100_percent_discount_reservations" */
  vw_admin_100_percent_discount_reservations: Array<Vw_Admin_100_Percent_Discount_Reservations>;
  /** fetch aggregated fields from the table: "vw_admin_100_percent_discount_reservations" */
  vw_admin_100_percent_discount_reservations_aggregate: Vw_Admin_100_Percent_Discount_Reservations_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_100_percent_discount_reservations" */
  vw_admin_100_percent_discount_reservations_stream: Array<Vw_Admin_100_Percent_Discount_Reservations>;
  /** fetch data from the table: "vw_admin_account" */
  vw_admin_account: Array<Vw_Admin_Account>;
  /** fetch aggregated fields from the table: "vw_admin_account" */
  vw_admin_account_aggregate: Vw_Admin_Account_Aggregate;
  /** fetch data from the table: "vw_admin_account_feedback" */
  vw_admin_account_feedback: Array<Vw_Admin_Account_Feedback>;
  /** fetch aggregated fields from the table: "vw_admin_account_feedback" */
  vw_admin_account_feedback_aggregate: Vw_Admin_Account_Feedback_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_account_feedback" */
  vw_admin_account_feedback_stream: Array<Vw_Admin_Account_Feedback>;
  /** fetch data from the table in a streaming manner: "vw_admin_account" */
  vw_admin_account_stream: Array<Vw_Admin_Account>;
  /** fetch data from the table: "vw_admin_cancelled_reservation" */
  vw_admin_cancelled_reservation: Array<Vw_Admin_Cancelled_Reservation>;
  /** fetch aggregated fields from the table: "vw_admin_cancelled_reservation" */
  vw_admin_cancelled_reservation_aggregate: Vw_Admin_Cancelled_Reservation_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_cancelled_reservation" */
  vw_admin_cancelled_reservation_stream: Array<Vw_Admin_Cancelled_Reservation>;
  /** fetch data from the table: "vw_admin_default_account_credit_cards" */
  vw_admin_default_account_credit_cards: Array<Vw_Admin_Default_Account_Credit_Cards>;
  /** fetch aggregated fields from the table: "vw_admin_default_account_credit_cards" */
  vw_admin_default_account_credit_cards_aggregate: Vw_Admin_Default_Account_Credit_Cards_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_default_account_credit_cards" */
  vw_admin_default_account_credit_cards_stream: Array<Vw_Admin_Default_Account_Credit_Cards>;
  /** fetch data from the table: "vw_admin_dof_drivers" */
  vw_admin_dof_drivers: Array<Vw_Admin_Dof_Drivers>;
  /** fetch aggregated fields from the table: "vw_admin_dof_drivers" */
  vw_admin_dof_drivers_aggregate: Vw_Admin_Dof_Drivers_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_dof_drivers" */
  vw_admin_dof_drivers_stream: Array<Vw_Admin_Dof_Drivers>;
  /** fetch data from the table: "vw_admin_driver_profile" */
  vw_admin_driver_profile: Array<Vw_Admin_Driver_Profile>;
  /** fetch aggregated fields from the table: "vw_admin_driver_profile" */
  vw_admin_driver_profile_aggregate: Vw_Admin_Driver_Profile_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_driver_profile" */
  vw_admin_driver_profile_stream: Array<Vw_Admin_Driver_Profile>;
  /** fetch data from the table: "vw_admin_manual_reservation" */
  vw_admin_manual_reservation: Array<Vw_Admin_Manual_Reservation>;
  /** fetch aggregated fields from the table: "vw_admin_manual_reservation" */
  vw_admin_manual_reservation_aggregate: Vw_Admin_Manual_Reservation_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_manual_reservation" */
  vw_admin_manual_reservation_stream: Array<Vw_Admin_Manual_Reservation>;
  /** fetch data from the table: "vw_admin_manual_reservation_with_preferred_driver" */
  vw_admin_manual_reservation_with_preferred_driver: Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver>;
  /** fetch aggregated fields from the table: "vw_admin_manual_reservation_with_preferred_driver" */
  vw_admin_manual_reservation_with_preferred_driver_aggregate: Vw_Admin_Manual_Reservation_With_Preferred_Driver_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_manual_reservation_with_preferred_driver" */
  vw_admin_manual_reservation_with_preferred_driver_stream: Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver>;
  /** fetch data from the table: "vw_admin_patient_champions" */
  vw_admin_patient_champions: Array<Vw_Admin_Patient_Champions>;
  /** fetch aggregated fields from the table: "vw_admin_patient_champions" */
  vw_admin_patient_champions_aggregate: Vw_Admin_Patient_Champions_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_patient_champions" */
  vw_admin_patient_champions_stream: Array<Vw_Admin_Patient_Champions>;
  /** fetch data from the table: "vw_admin_reservation" */
  vw_admin_reservation: Array<Vw_Admin_Reservation>;
  /** fetch aggregated fields from the table: "vw_admin_reservation" */
  vw_admin_reservation_aggregate: Vw_Admin_Reservation_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_admin_reservation" */
  vw_admin_reservation_stream: Array<Vw_Admin_Reservation>;
  /** fetch data from the table: "vw_stats_auth_provider_data" */
  vw_stats_auth_provider_data: Array<Vw_Stats_Auth_Provider_Data>;
  /** fetch aggregated fields from the table: "vw_stats_auth_provider_data" */
  vw_stats_auth_provider_data_aggregate: Vw_Stats_Auth_Provider_Data_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_stats_auth_provider_data" */
  vw_stats_auth_provider_data_stream: Array<Vw_Stats_Auth_Provider_Data>;
  /** fetch data from the table: "vw_stats_daily_conversions_by_auth_provider" */
  vw_stats_daily_conversions_by_auth_provider: Array<Vw_Stats_Daily_Conversions_By_Auth_Provider>;
  /** fetch aggregated fields from the table: "vw_stats_daily_conversions_by_auth_provider" */
  vw_stats_daily_conversions_by_auth_provider_aggregate: Vw_Stats_Daily_Conversions_By_Auth_Provider_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_stats_daily_conversions_by_auth_provider" */
  vw_stats_daily_conversions_by_auth_provider_stream: Array<Vw_Stats_Daily_Conversions_By_Auth_Provider>;
  /** fetch data from the table: "vw_stats_patient_conversion_reservation" */
  vw_stats_patient_conversion_reservation: Array<Vw_Stats_Patient_Conversion_Reservation>;
  /** fetch aggregated fields from the table: "vw_stats_patient_conversion_reservation" */
  vw_stats_patient_conversion_reservation_aggregate: Vw_Stats_Patient_Conversion_Reservation_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_stats_patient_conversion_reservation" */
  vw_stats_patient_conversion_reservation_stream: Array<Vw_Stats_Patient_Conversion_Reservation>;
  /** fetch data from the table: "vw_stats_reservation_frequency_by_patient_accounts" */
  vw_stats_reservation_frequency_by_patient_accounts: Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts>;
  /** fetch aggregated fields from the table: "vw_stats_reservation_frequency_by_patient_accounts" */
  vw_stats_reservation_frequency_by_patient_accounts_aggregate: Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_stats_reservation_frequency_by_patient_accounts" */
  vw_stats_reservation_frequency_by_patient_accounts_stream: Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts>;
  /** fetch data from the table: "vws_stats_general_user" */
  vws_stats_general_user: Array<Vws_Stats_General_User>;
  /** fetch aggregated fields from the table: "vws_stats_general_user" */
  vws_stats_general_user_aggregate: Vws_Stats_General_User_Aggregate;
  /** fetch data from the table in a streaming manner: "vws_stats_general_user" */
  vws_stats_general_user_stream: Array<Vws_Stats_General_User>;
  /** fetch data from the table: "vws_stats_get_pareto_distribution_account_reservation" */
  vws_stats_get_pareto_distribution_account_reservation: Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation>;
  /** fetch aggregated fields from the table: "vws_stats_get_pareto_distribution_account_reservation" */
  vws_stats_get_pareto_distribution_account_reservation_aggregate: Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Aggregate;
  /** fetch data from the table in a streaming manner: "vws_stats_get_pareto_distribution_account_reservation" */
  vws_stats_get_pareto_distribution_account_reservation_stream: Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation>;
  /** fetch data from the table: "vws_stats_get_voucher_uses" */
  vws_stats_get_voucher_uses: Array<Vws_Stats_Get_Voucher_Uses>;
  /** fetch aggregated fields from the table: "vws_stats_get_voucher_uses" */
  vws_stats_get_voucher_uses_aggregate: Vws_Stats_Get_Voucher_Uses_Aggregate;
  /** fetch data from the table in a streaming manner: "vws_stats_get_voucher_uses" */
  vws_stats_get_voucher_uses_stream: Array<Vws_Stats_Get_Voucher_Uses>;
}


export type Subscription_RootAccountArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootAccount_ErrorArgs = {
  distinct_on?: Maybe<Array<Account_Error_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Error_Order_By>>;
  where?: Maybe<Account_Error_Bool_Exp>;
};


export type Subscription_RootAccount_Error_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Error_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Error_Order_By>>;
  where?: Maybe<Account_Error_Bool_Exp>;
};


export type Subscription_RootAccount_Error_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccount_Error_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Account_Error_Stream_Cursor_Input>>;
  where?: Maybe<Account_Error_Bool_Exp>;
};


export type Subscription_RootAccount_Error_TypeArgs = {
  distinct_on?: Maybe<Array<Account_Error_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Error_Type_Order_By>>;
  where?: Maybe<Account_Error_Type_Bool_Exp>;
};


export type Subscription_RootAccount_Error_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Error_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Error_Type_Order_By>>;
  where?: Maybe<Account_Error_Type_Bool_Exp>;
};


export type Subscription_RootAccount_Error_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAccount_Error_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Account_Error_Type_Stream_Cursor_Input>>;
  where?: Maybe<Account_Error_Type_Bool_Exp>;
};


export type Subscription_RootAccount_FeedbackArgs = {
  distinct_on?: Maybe<Array<Account_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Feedback_Order_By>>;
  where?: Maybe<Account_Feedback_Bool_Exp>;
};


export type Subscription_RootAccount_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Feedback_Order_By>>;
  where?: Maybe<Account_Feedback_Bool_Exp>;
};


export type Subscription_RootAccount_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccount_Feedback_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Account_Feedback_Stream_Cursor_Input>>;
  where?: Maybe<Account_Feedback_Bool_Exp>;
};


export type Subscription_RootAccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Account_Stream_Cursor_Input>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAdministrator_ProfileArgs = {
  distinct_on?: Maybe<Array<Administrator_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Administrator_Profile_Order_By>>;
  where?: Maybe<Administrator_Profile_Bool_Exp>;
};


export type Subscription_RootAdministrator_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Administrator_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Administrator_Profile_Order_By>>;
  where?: Maybe<Administrator_Profile_Bool_Exp>;
};


export type Subscription_RootAdministrator_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAdministrator_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Administrator_Profile_Stream_Cursor_Input>>;
  where?: Maybe<Administrator_Profile_Bool_Exp>;
};


export type Subscription_RootCarvoila_DistrictArgs = {
  distinct_on?: Maybe<Array<Carvoila_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_District_Order_By>>;
  where?: Maybe<Carvoila_District_Bool_Exp>;
};


export type Subscription_RootCarvoila_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Carvoila_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_District_Order_By>>;
  where?: Maybe<Carvoila_District_Bool_Exp>;
};


export type Subscription_RootCarvoila_District_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCarvoila_District_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Carvoila_District_Stream_Cursor_Input>>;
  where?: Maybe<Carvoila_District_Bool_Exp>;
};


export type Subscription_RootCarvoila_DriverArgs = {
  distinct_on?: Maybe<Array<Carvoila_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_Driver_Order_By>>;
  where?: Maybe<Carvoila_Driver_Bool_Exp>;
};


export type Subscription_RootCarvoila_Driver_AggregateArgs = {
  distinct_on?: Maybe<Array<Carvoila_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_Driver_Order_By>>;
  where?: Maybe<Carvoila_Driver_Bool_Exp>;
};


export type Subscription_RootCarvoila_Driver_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCarvoila_Driver_StatusArgs = {
  distinct_on?: Maybe<Array<Carvoila_Driver_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_Driver_Status_Order_By>>;
  where?: Maybe<Carvoila_Driver_Status_Bool_Exp>;
};


export type Subscription_RootCarvoila_Driver_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Carvoila_Driver_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Carvoila_Driver_Status_Order_By>>;
  where?: Maybe<Carvoila_Driver_Status_Bool_Exp>;
};


export type Subscription_RootCarvoila_Driver_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCarvoila_Driver_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Carvoila_Driver_Status_Stream_Cursor_Input>>;
  where?: Maybe<Carvoila_Driver_Status_Bool_Exp>;
};


export type Subscription_RootCarvoila_Driver_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Carvoila_Driver_Stream_Cursor_Input>>;
  where?: Maybe<Carvoila_Driver_Bool_Exp>;
};


export type Subscription_RootCityArgs = {
  distinct_on?: Maybe<Array<City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<City_Order_By>>;
  where?: Maybe<City_Bool_Exp>;
};


export type Subscription_RootCity_AggregateArgs = {
  distinct_on?: Maybe<Array<City_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<City_Order_By>>;
  where?: Maybe<City_Bool_Exp>;
};


export type Subscription_RootCity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<City_Stream_Cursor_Input>>;
  where?: Maybe<City_Bool_Exp>;
};


export type Subscription_RootDeploymentArgs = {
  distinct_on?: Maybe<Array<Deployment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deployment_Order_By>>;
  where?: Maybe<Deployment_Bool_Exp>;
};


export type Subscription_RootDeployment_AggregateArgs = {
  distinct_on?: Maybe<Array<Deployment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deployment_Order_By>>;
  where?: Maybe<Deployment_Bool_Exp>;
};


export type Subscription_RootDeployment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDeployment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deployment_Stream_Cursor_Input>>;
  where?: Maybe<Deployment_Bool_Exp>;
};


export type Subscription_RootDeployment_TypeArgs = {
  distinct_on?: Maybe<Array<Deployment_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deployment_Type_Order_By>>;
  where?: Maybe<Deployment_Type_Bool_Exp>;
};


export type Subscription_RootDeployment_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Deployment_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deployment_Type_Order_By>>;
  where?: Maybe<Deployment_Type_Bool_Exp>;
};


export type Subscription_RootDeployment_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootDeployment_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Deployment_Type_Stream_Cursor_Input>>;
  where?: Maybe<Deployment_Type_Bool_Exp>;
};


export type Subscription_RootDriver_ProfileArgs = {
  distinct_on?: Maybe<Array<Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Profile_Order_By>>;
  where?: Maybe<Driver_Profile_Bool_Exp>;
};


export type Subscription_RootDriver_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Profile_Order_By>>;
  where?: Maybe<Driver_Profile_Bool_Exp>;
};


export type Subscription_RootDriver_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDriver_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Driver_Profile_Stream_Cursor_Input>>;
  where?: Maybe<Driver_Profile_Bool_Exp>;
};


export type Subscription_RootDriver_StatusArgs = {
  distinct_on?: Maybe<Array<Driver_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Status_Order_By>>;
  where?: Maybe<Driver_Status_Bool_Exp>;
};


export type Subscription_RootDriver_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Driver_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Driver_Status_Order_By>>;
  where?: Maybe<Driver_Status_Bool_Exp>;
};


export type Subscription_RootDriver_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootDriver_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Driver_Status_Stream_Cursor_Input>>;
  where?: Maybe<Driver_Status_Bool_Exp>;
};


export type Subscription_RootFn_Stats_Get_General_UserArgs = {
  args: Fn_Stats_Get_General_User_Args;
  distinct_on?: Maybe<Array<Vws_Stats_General_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_General_User_Order_By>>;
  where?: Maybe<Vws_Stats_General_User_Bool_Exp>;
};


export type Subscription_RootFn_Stats_Get_General_User_AggregateArgs = {
  args: Fn_Stats_Get_General_User_Args;
  distinct_on?: Maybe<Array<Vws_Stats_General_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_General_User_Order_By>>;
  where?: Maybe<Vws_Stats_General_User_Bool_Exp>;
};


export type Subscription_RootFn_Stats_Get_Pareto_Distribution_Account_ReservationArgs = {
  args: Fn_Stats_Get_Pareto_Distribution_Account_Reservation_Args;
  distinct_on?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
};


export type Subscription_RootFn_Stats_Get_Pareto_Distribution_Account_Reservation_AggregateArgs = {
  args: Fn_Stats_Get_Pareto_Distribution_Account_Reservation_Args;
  distinct_on?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
};


export type Subscription_RootFn_Stats_Get_Voucher_UsesArgs = {
  args: Fn_Stats_Get_Voucher_Uses_Args;
  distinct_on?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
};


export type Subscription_RootFn_Stats_Get_Voucher_Uses_AggregateArgs = {
  args: Fn_Stats_Get_Voucher_Uses_Args;
  distinct_on?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
};


export type Subscription_RootManual_DriverArgs = {
  distinct_on?: Maybe<Array<Manual_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Driver_Order_By>>;
  where?: Maybe<Manual_Driver_Bool_Exp>;
};


export type Subscription_RootManual_Driver_AggregateArgs = {
  distinct_on?: Maybe<Array<Manual_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Driver_Order_By>>;
  where?: Maybe<Manual_Driver_Bool_Exp>;
};


export type Subscription_RootManual_Driver_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootManual_Driver_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Manual_Driver_Stream_Cursor_Input>>;
  where?: Maybe<Manual_Driver_Bool_Exp>;
};


export type Subscription_RootManual_PatientArgs = {
  distinct_on?: Maybe<Array<Manual_Patient_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Patient_Order_By>>;
  where?: Maybe<Manual_Patient_Bool_Exp>;
};


export type Subscription_RootManual_Patient_AggregateArgs = {
  distinct_on?: Maybe<Array<Manual_Patient_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Patient_Order_By>>;
  where?: Maybe<Manual_Patient_Bool_Exp>;
};


export type Subscription_RootManual_Patient_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootManual_Patient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Manual_Patient_Stream_Cursor_Input>>;
  where?: Maybe<Manual_Patient_Bool_Exp>;
};


export type Subscription_RootManual_ReservationArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


export type Subscription_RootManual_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Manual_Reservation_Order_By>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


export type Subscription_RootManual_Reservation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootManual_Reservation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Manual_Reservation_Stream_Cursor_Input>>;
  where?: Maybe<Manual_Reservation_Bool_Exp>;
};


export type Subscription_RootPatient_ProfileArgs = {
  distinct_on?: Maybe<Array<Patient_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Profile_Order_By>>;
  where?: Maybe<Patient_Profile_Bool_Exp>;
};


export type Subscription_RootPatient_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Profile_Order_By>>;
  where?: Maybe<Patient_Profile_Bool_Exp>;
};


export type Subscription_RootPatient_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPatient_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Patient_Profile_Stream_Cursor_Input>>;
  where?: Maybe<Patient_Profile_Bool_Exp>;
};


export type Subscription_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayment_MethodArgs = {
  distinct_on?: Maybe<Array<Payment_Method_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Method_Order_By>>;
  where?: Maybe<Payment_Method_Bool_Exp>;
};


export type Subscription_RootPayment_Method_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Method_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Method_Order_By>>;
  where?: Maybe<Payment_Method_Bool_Exp>;
};


export type Subscription_RootPayment_Method_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPayment_Method_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payment_Method_Stream_Cursor_Input>>;
  where?: Maybe<Payment_Method_Bool_Exp>;
};


export type Subscription_RootPayment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payment_Stream_Cursor_Input>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Subscription_RootReservationArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


export type Subscription_RootReservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Order_By>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


export type Subscription_RootReservation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReservation_Change_Request_ActionArgs = {
  distinct_on?: Maybe<Array<Reservation_Change_Request_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Change_Request_Action_Order_By>>;
  where?: Maybe<Reservation_Change_Request_Action_Bool_Exp>;
};


export type Subscription_RootReservation_Change_Request_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Change_Request_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Change_Request_Action_Order_By>>;
  where?: Maybe<Reservation_Change_Request_Action_Bool_Exp>;
};


export type Subscription_RootReservation_Change_Request_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootReservation_Change_Request_Action_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Reservation_Change_Request_Action_Stream_Cursor_Input>>;
  where?: Maybe<Reservation_Change_Request_Action_Bool_Exp>;
};


export type Subscription_RootReservation_StatusArgs = {
  distinct_on?: Maybe<Array<Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Status_Order_By>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Subscription_RootReservation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservation_Status_Order_By>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Subscription_RootReservation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootReservation_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Reservation_Status_Stream_Cursor_Input>>;
  where?: Maybe<Reservation_Status_Bool_Exp>;
};


export type Subscription_RootReservation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Reservation_Stream_Cursor_Input>>;
  where?: Maybe<Reservation_Bool_Exp>;
};


export type Subscription_RootReviewArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


export type Subscription_RootReview_AggregateArgs = {
  distinct_on?: Maybe<Array<Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Review_Order_By>>;
  where?: Maybe<Review_Bool_Exp>;
};


export type Subscription_RootReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Review_Stream_Cursor_Input>>;
  where?: Maybe<Review_Bool_Exp>;
};


export type Subscription_RootServiceArgs = {
  distinct_on?: Maybe<Array<Service_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Order_By>>;
  where?: Maybe<Service_Bool_Exp>;
};


export type Subscription_RootService_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Order_By>>;
  where?: Maybe<Service_Bool_Exp>;
};


export type Subscription_RootService_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootService_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Service_Stream_Cursor_Input>>;
  where?: Maybe<Service_Bool_Exp>;
};


export type Subscription_RootSyncArgs = {
  distinct_on?: Maybe<Array<Sync_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Order_By>>;
  where?: Maybe<Sync_Bool_Exp>;
};


export type Subscription_RootSync_AggregateArgs = {
  distinct_on?: Maybe<Array<Sync_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sync_Order_By>>;
  where?: Maybe<Sync_Bool_Exp>;
};


export type Subscription_RootSync_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootSync_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Sync_Stream_Cursor_Input>>;
  where?: Maybe<Sync_Bool_Exp>;
};


export type Subscription_RootVoucherArgs = {
  distinct_on?: Maybe<Array<Voucher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Order_By>>;
  where?: Maybe<Voucher_Bool_Exp>;
};


export type Subscription_RootVoucher_AggregateArgs = {
  distinct_on?: Maybe<Array<Voucher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Order_By>>;
  where?: Maybe<Voucher_Bool_Exp>;
};


export type Subscription_RootVoucher_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootVoucher_RedemptionArgs = {
  distinct_on?: Maybe<Array<Voucher_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Redemption_Order_By>>;
  where?: Maybe<Voucher_Redemption_Bool_Exp>;
};


export type Subscription_RootVoucher_Redemption_AggregateArgs = {
  distinct_on?: Maybe<Array<Voucher_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voucher_Redemption_Order_By>>;
  where?: Maybe<Voucher_Redemption_Bool_Exp>;
};


export type Subscription_RootVoucher_Redemption_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVoucher_Redemption_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Voucher_Redemption_Stream_Cursor_Input>>;
  where?: Maybe<Voucher_Redemption_Bool_Exp>;
};


export type Subscription_RootVoucher_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Voucher_Stream_Cursor_Input>>;
  where?: Maybe<Voucher_Bool_Exp>;
};


export type Subscription_RootVw_Admin_100_Percent_Discount_ReservationsArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Order_By>>;
  where?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp>;
};


export type Subscription_RootVw_Admin_100_Percent_Discount_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Order_By>>;
  where?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp>;
};


export type Subscription_RootVw_Admin_100_Percent_Discount_Reservations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_100_Percent_Discount_Reservations_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp>;
};


export type Subscription_RootVw_Admin_AccountArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Account_Order_By>>;
  where?: Maybe<Vw_Admin_Account_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Account_Order_By>>;
  where?: Maybe<Vw_Admin_Account_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Account_FeedbackArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Account_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Account_Feedback_Order_By>>;
  where?: Maybe<Vw_Admin_Account_Feedback_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Account_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Account_Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Account_Feedback_Order_By>>;
  where?: Maybe<Vw_Admin_Account_Feedback_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Account_Feedback_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Account_Feedback_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Account_Feedback_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Account_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Account_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Account_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Cancelled_ReservationArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Cancelled_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Cancelled_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Cancelled_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Cancelled_Reservation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Cancelled_Reservation_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Cancelled_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Default_Account_Credit_CardsArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Order_By>>;
  where?: Maybe<Vw_Admin_Default_Account_Credit_Cards_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Default_Account_Credit_Cards_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Order_By>>;
  where?: Maybe<Vw_Admin_Default_Account_Credit_Cards_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Default_Account_Credit_Cards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Default_Account_Credit_Cards_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Default_Account_Credit_Cards_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Dof_DriversArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Dof_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Dof_Drivers_Order_By>>;
  where?: Maybe<Vw_Admin_Dof_Drivers_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Dof_Drivers_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Dof_Drivers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Dof_Drivers_Order_By>>;
  where?: Maybe<Vw_Admin_Dof_Drivers_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Dof_Drivers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Dof_Drivers_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Dof_Drivers_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Driver_ProfileArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Driver_Profile_Order_By>>;
  where?: Maybe<Vw_Admin_Driver_Profile_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Driver_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Driver_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Driver_Profile_Order_By>>;
  where?: Maybe<Vw_Admin_Driver_Profile_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Driver_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Driver_Profile_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Driver_Profile_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Manual_ReservationArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Manual_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Manual_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Manual_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Manual_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Manual_Reservation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Manual_Reservation_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Manual_Reservation_With_Preferred_DriverArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Order_By>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Manual_Reservation_With_Preferred_Driver_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Order_By>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Manual_Reservation_With_Preferred_Driver_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Patient_ChampionsArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Patient_Champions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Patient_Champions_Order_By>>;
  where?: Maybe<Vw_Admin_Patient_Champions_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Patient_Champions_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Patient_Champions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Patient_Champions_Order_By>>;
  where?: Maybe<Vw_Admin_Patient_Champions_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Patient_Champions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Patient_Champions_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Patient_Champions_Bool_Exp>;
};


export type Subscription_RootVw_Admin_ReservationArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Admin_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Admin_Reservation_Order_By>>;
  where?: Maybe<Vw_Admin_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Admin_Reservation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Admin_Reservation_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Admin_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Auth_Provider_DataArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Order_By>>;
  where?: Maybe<Vw_Stats_Auth_Provider_Data_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Auth_Provider_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Order_By>>;
  where?: Maybe<Vw_Stats_Auth_Provider_Data_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Auth_Provider_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Stats_Auth_Provider_Data_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Stats_Auth_Provider_Data_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Daily_Conversions_By_Auth_ProviderArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Order_By>>;
  where?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Daily_Conversions_By_Auth_Provider_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Order_By>>;
  where?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Daily_Conversions_By_Auth_Provider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Patient_Conversion_ReservationArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Order_By>>;
  where?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Patient_Conversion_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Order_By>>;
  where?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Patient_Conversion_Reservation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Stats_Patient_Conversion_Reservation_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Reservation_Frequency_By_Patient_AccountsArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Order_By>>;
  where?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Reservation_Frequency_By_Patient_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Order_By>>;
  where?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Bool_Exp>;
};


export type Subscription_RootVw_Stats_Reservation_Frequency_By_Patient_Accounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Bool_Exp>;
};


export type Subscription_RootVws_Stats_General_UserArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_General_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_General_User_Order_By>>;
  where?: Maybe<Vws_Stats_General_User_Bool_Exp>;
};


export type Subscription_RootVws_Stats_General_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_General_User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_General_User_Order_By>>;
  where?: Maybe<Vws_Stats_General_User_Bool_Exp>;
};


export type Subscription_RootVws_Stats_General_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vws_Stats_General_User_Stream_Cursor_Input>>;
  where?: Maybe<Vws_Stats_General_User_Bool_Exp>;
};


export type Subscription_RootVws_Stats_Get_Pareto_Distribution_Account_ReservationArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
};


export type Subscription_RootVws_Stats_Get_Pareto_Distribution_Account_Reservation_AggregateArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
};


export type Subscription_RootVws_Stats_Get_Pareto_Distribution_Account_Reservation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stream_Cursor_Input>>;
  where?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
};


export type Subscription_RootVws_Stats_Get_Voucher_UsesArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
};


export type Subscription_RootVws_Stats_Get_Voucher_Uses_AggregateArgs = {
  distinct_on?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Order_By>>;
  where?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
};


export type Subscription_RootVws_Stats_Get_Voucher_Uses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Vws_Stats_Get_Voucher_Uses_Stream_Cursor_Input>>;
  where?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
};

/** Metadata about data syncs with 3rd party services/data providers */
export interface Sync {
  __typename?: 'sync';
  id: Scalars['String'];
  is_sync_in_progress: Scalars['Boolean'];
  synced_at: Scalars['timestamptz'];
}

/** aggregated selection of "sync" */
export interface Sync_Aggregate {
  __typename?: 'sync_aggregate';
  aggregate?: Maybe<Sync_Aggregate_Fields>;
  nodes: Array<Sync>;
}

/** aggregate fields of "sync" */
export interface Sync_Aggregate_Fields {
  __typename?: 'sync_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sync_Max_Fields>;
  min?: Maybe<Sync_Min_Fields>;
}


/** aggregate fields of "sync" */
export type Sync_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sync_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sync". All fields are combined with a logical 'AND'. */
export interface Sync_Bool_Exp {
  _and?: Maybe<Array<Sync_Bool_Exp>>;
  _not?: Maybe<Sync_Bool_Exp>;
  _or?: Maybe<Array<Sync_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  is_sync_in_progress?: Maybe<Boolean_Comparison_Exp>;
  synced_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "sync" */
export enum Sync_Constraint {
  /** unique or primary key constraint on columns "id" */
  SyncPkey = 'sync_pkey'
}

/** input type for inserting data into table "sync" */
export interface Sync_Insert_Input {
  id?: Maybe<Scalars['String']>;
  is_sync_in_progress?: Maybe<Scalars['Boolean']>;
  synced_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Sync_Max_Fields {
  __typename?: 'sync_max_fields';
  id?: Maybe<Scalars['String']>;
  synced_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Sync_Min_Fields {
  __typename?: 'sync_min_fields';
  id?: Maybe<Scalars['String']>;
  synced_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "sync" */
export interface Sync_Mutation_Response {
  __typename?: 'sync_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sync>;
}

/** on_conflict condition type for table "sync" */
export interface Sync_On_Conflict {
  constraint: Sync_Constraint;
  update_columns?: Array<Sync_Update_Column>;
  where?: Maybe<Sync_Bool_Exp>;
}

/** Ordering options when selecting data from "sync". */
export interface Sync_Order_By {
  id?: Maybe<Order_By>;
  is_sync_in_progress?: Maybe<Order_By>;
  synced_at?: Maybe<Order_By>;
}

/** primary key columns input for table: sync */
export interface Sync_Pk_Columns_Input {
  id: Scalars['String'];
}

/** select columns of table "sync" */
export enum Sync_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSyncInProgress = 'is_sync_in_progress',
  /** column name */
  SyncedAt = 'synced_at'
}

/** input type for updating data in table "sync" */
export interface Sync_Set_Input {
  id?: Maybe<Scalars['String']>;
  is_sync_in_progress?: Maybe<Scalars['Boolean']>;
  synced_at?: Maybe<Scalars['timestamptz']>;
}

/** Streaming cursor of the table "sync" */
export interface Sync_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Sync_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Sync_Stream_Cursor_Value_Input {
  id?: Maybe<Scalars['String']>;
  is_sync_in_progress?: Maybe<Scalars['Boolean']>;
  synced_at?: Maybe<Scalars['timestamptz']>;
}

/** update columns of table "sync" */
export enum Sync_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSyncInProgress = 'is_sync_in_progress',
  /** column name */
  SyncedAt = 'synced_at'
}

export interface Sync_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Sync_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sync_Bool_Exp;
}


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export interface Timestamp_Comparison_Exp {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
}


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface Timestamptz_Comparison_Exp {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface Uuid_Comparison_Exp {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
}

export interface VerifySkillsincloudSsoOutput {
  __typename?: 'verifySkillsincloudSSOOutput';
  url: Scalars['String'];
}

/** columns and relationships of "voucher" */
export interface Voucher {
  __typename?: 'voucher';
  amount_value?: Maybe<Scalars['numeric']>;
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  enabled: Scalars['Boolean'];
  expires_at?: Maybe<Scalars['timestamptz']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['numeric']>;
  /** Related to account table */
  referrer_id?: Maybe<Scalars['String']>;
}

/** aggregated selection of "voucher" */
export interface Voucher_Aggregate {
  __typename?: 'voucher_aggregate';
  aggregate?: Maybe<Voucher_Aggregate_Fields>;
  nodes: Array<Voucher>;
}

export interface Voucher_Aggregate_Bool_Exp {
  bool_and?: Maybe<Voucher_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Voucher_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Voucher_Aggregate_Bool_Exp_Count>;
}

export interface Voucher_Aggregate_Bool_Exp_Bool_And {
  arguments: Voucher_Select_Column_Voucher_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Voucher_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Voucher_Aggregate_Bool_Exp_Bool_Or {
  arguments: Voucher_Select_Column_Voucher_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Voucher_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Voucher_Aggregate_Bool_Exp_Count {
  arguments?: Maybe<Array<Voucher_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Voucher_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "voucher" */
export interface Voucher_Aggregate_Fields {
  __typename?: 'voucher_aggregate_fields';
  avg?: Maybe<Voucher_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Voucher_Max_Fields>;
  min?: Maybe<Voucher_Min_Fields>;
  stddev?: Maybe<Voucher_Stddev_Fields>;
  stddev_pop?: Maybe<Voucher_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Voucher_Stddev_Samp_Fields>;
  sum?: Maybe<Voucher_Sum_Fields>;
  var_pop?: Maybe<Voucher_Var_Pop_Fields>;
  var_samp?: Maybe<Voucher_Var_Samp_Fields>;
  variance?: Maybe<Voucher_Variance_Fields>;
}


/** aggregate fields of "voucher" */
export type Voucher_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Voucher_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "voucher" */
export interface Voucher_Aggregate_Order_By {
  avg?: Maybe<Voucher_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Voucher_Max_Order_By>;
  min?: Maybe<Voucher_Min_Order_By>;
  stddev?: Maybe<Voucher_Stddev_Order_By>;
  stddev_pop?: Maybe<Voucher_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Voucher_Stddev_Samp_Order_By>;
  sum?: Maybe<Voucher_Sum_Order_By>;
  var_pop?: Maybe<Voucher_Var_Pop_Order_By>;
  var_samp?: Maybe<Voucher_Var_Samp_Order_By>;
  variance?: Maybe<Voucher_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "voucher" */
export interface Voucher_Arr_Rel_Insert_Input {
  data: Array<Voucher_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Voucher_On_Conflict>;
}

/** aggregate avg on columns */
export interface Voucher_Avg_Fields {
  __typename?: 'voucher_avg_fields';
  amount_value?: Maybe<Scalars['Float']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['Float']>;
}

/** order by avg() on columns of table "voucher" */
export interface Voucher_Avg_Order_By {
  amount_value?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
}

/** Boolean expression to filter rows from the table "voucher". All fields are combined with a logical 'AND'. */
export interface Voucher_Bool_Exp {
  _and?: Maybe<Array<Voucher_Bool_Exp>>;
  _not?: Maybe<Voucher_Bool_Exp>;
  _or?: Maybe<Array<Voucher_Bool_Exp>>;
  amount_value?: Maybe<Numeric_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  enabled?: Maybe<Boolean_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  percent_value?: Maybe<Numeric_Comparison_Exp>;
  referrer_id?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "voucher" */
export enum Voucher_Constraint {
  /** unique or primary key constraint on columns "code" */
  VoucherPkey = 'voucher_pkey'
}

/** input type for incrementing numeric columns in table "voucher" */
export interface Voucher_Inc_Input {
  amount_value?: Maybe<Scalars['numeric']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['numeric']>;
}

/** input type for inserting data into table "voucher" */
export interface Voucher_Insert_Input {
  amount_value?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['numeric']>;
  /** Related to account table */
  referrer_id?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Voucher_Max_Fields {
  __typename?: 'voucher_max_fields';
  amount_value?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['numeric']>;
  /** Related to account table */
  referrer_id?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "voucher" */
export interface Voucher_Max_Order_By {
  amount_value?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
  /** Related to account table */
  referrer_id?: Maybe<Order_By>;
}

/** aggregate min on columns */
export interface Voucher_Min_Fields {
  __typename?: 'voucher_min_fields';
  amount_value?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['numeric']>;
  /** Related to account table */
  referrer_id?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "voucher" */
export interface Voucher_Min_Order_By {
  amount_value?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
  /** Related to account table */
  referrer_id?: Maybe<Order_By>;
}

/** response of any mutation on the table "voucher" */
export interface Voucher_Mutation_Response {
  __typename?: 'voucher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Voucher>;
}

/** on_conflict condition type for table "voucher" */
export interface Voucher_On_Conflict {
  constraint: Voucher_Constraint;
  update_columns?: Array<Voucher_Update_Column>;
  where?: Maybe<Voucher_Bool_Exp>;
}

/** Ordering options when selecting data from "voucher". */
export interface Voucher_Order_By {
  amount_value?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  enabled?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  percent_value?: Maybe<Order_By>;
  referrer_id?: Maybe<Order_By>;
}

/** primary key columns input for table: voucher */
export interface Voucher_Pk_Columns_Input {
  code: Scalars['String'];
}

/** columns and relationships of "voucher_redemption" */
export interface Voucher_Redemption {
  __typename?: 'voucher_redemption';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  user_id?: Maybe<Scalars['String']>;
}

/** aggregated selection of "voucher_redemption" */
export interface Voucher_Redemption_Aggregate {
  __typename?: 'voucher_redemption_aggregate';
  aggregate?: Maybe<Voucher_Redemption_Aggregate_Fields>;
  nodes: Array<Voucher_Redemption>;
}

/** aggregate fields of "voucher_redemption" */
export interface Voucher_Redemption_Aggregate_Fields {
  __typename?: 'voucher_redemption_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Voucher_Redemption_Max_Fields>;
  min?: Maybe<Voucher_Redemption_Min_Fields>;
}


/** aggregate fields of "voucher_redemption" */
export type Voucher_Redemption_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Voucher_Redemption_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "voucher_redemption". All fields are combined with a logical 'AND'. */
export interface Voucher_Redemption_Bool_Exp {
  _and?: Maybe<Array<Voucher_Redemption_Bool_Exp>>;
  _not?: Maybe<Voucher_Redemption_Bool_Exp>;
  _or?: Maybe<Array<Voucher_Redemption_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "voucher_redemption" */
export enum Voucher_Redemption_Constraint {
  /** unique or primary key constraint on columns "id" */
  VoucherRedemptionPkey = 'voucher_redemption_pkey'
}

/** input type for inserting data into table "voucher_redemption" */
export interface Voucher_Redemption_Insert_Input {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Voucher_Redemption_Max_Fields {
  __typename?: 'voucher_redemption_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Voucher_Redemption_Min_Fields {
  __typename?: 'voucher_redemption_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "voucher_redemption" */
export interface Voucher_Redemption_Mutation_Response {
  __typename?: 'voucher_redemption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Voucher_Redemption>;
}

/** on_conflict condition type for table "voucher_redemption" */
export interface Voucher_Redemption_On_Conflict {
  constraint: Voucher_Redemption_Constraint;
  update_columns?: Array<Voucher_Redemption_Update_Column>;
  where?: Maybe<Voucher_Redemption_Bool_Exp>;
}

/** Ordering options when selecting data from "voucher_redemption". */
export interface Voucher_Redemption_Order_By {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
}

/** primary key columns input for table: voucher_redemption */
export interface Voucher_Redemption_Pk_Columns_Input {
  id: Scalars['uuid'];
}

/** select columns of table "voucher_redemption" */
export enum Voucher_Redemption_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "voucher_redemption" */
export interface Voucher_Redemption_Set_Input {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
}

/** Streaming cursor of the table "voucher_redemption" */
export interface Voucher_Redemption_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Voucher_Redemption_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Voucher_Redemption_Stream_Cursor_Value_Input {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
}

/** update columns of table "voucher_redemption" */
export enum Voucher_Redemption_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export interface Voucher_Redemption_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Voucher_Redemption_Set_Input>;
  /** filter the rows which have to be updated */
  where: Voucher_Redemption_Bool_Exp;
}

/** select columns of table "voucher" */
export enum Voucher_Select_Column {
  /** column name */
  AmountValue = 'amount_value',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  PercentValue = 'percent_value',
  /** column name */
  ReferrerId = 'referrer_id'
}

/** select "voucher_aggregate_bool_exp_bool_and_arguments_columns" columns of table "voucher" */
export enum Voucher_Select_Column_Voucher_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** select "voucher_aggregate_bool_exp_bool_or_arguments_columns" columns of table "voucher" */
export enum Voucher_Select_Column_Voucher_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** input type for updating data in table "voucher" */
export interface Voucher_Set_Input {
  amount_value?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['numeric']>;
  /** Related to account table */
  referrer_id?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface Voucher_Stddev_Fields {
  __typename?: 'voucher_stddev_fields';
  amount_value?: Maybe<Scalars['Float']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['Float']>;
}

/** order by stddev() on columns of table "voucher" */
export interface Voucher_Stddev_Order_By {
  amount_value?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Voucher_Stddev_Pop_Fields {
  __typename?: 'voucher_stddev_pop_fields';
  amount_value?: Maybe<Scalars['Float']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['Float']>;
}

/** order by stddev_pop() on columns of table "voucher" */
export interface Voucher_Stddev_Pop_Order_By {
  amount_value?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Voucher_Stddev_Samp_Fields {
  __typename?: 'voucher_stddev_samp_fields';
  amount_value?: Maybe<Scalars['Float']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['Float']>;
}

/** order by stddev_samp() on columns of table "voucher" */
export interface Voucher_Stddev_Samp_Order_By {
  amount_value?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
}

/** Streaming cursor of the table "voucher" */
export interface Voucher_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Voucher_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Voucher_Stream_Cursor_Value_Input {
  amount_value?: Maybe<Scalars['numeric']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['numeric']>;
  /** Related to account table */
  referrer_id?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Voucher_Sum_Fields {
  __typename?: 'voucher_sum_fields';
  amount_value?: Maybe<Scalars['numeric']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['numeric']>;
}

/** order by sum() on columns of table "voucher" */
export interface Voucher_Sum_Order_By {
  amount_value?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
}

/** update columns of table "voucher" */
export enum Voucher_Update_Column {
  /** column name */
  AmountValue = 'amount_value',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  PercentValue = 'percent_value',
  /** column name */
  ReferrerId = 'referrer_id'
}

export interface Voucher_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Voucher_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Voucher_Set_Input>;
  /** filter the rows which have to be updated */
  where: Voucher_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Voucher_Var_Pop_Fields {
  __typename?: 'voucher_var_pop_fields';
  amount_value?: Maybe<Scalars['Float']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['Float']>;
}

/** order by var_pop() on columns of table "voucher" */
export interface Voucher_Var_Pop_Order_By {
  amount_value?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Voucher_Var_Samp_Fields {
  __typename?: 'voucher_var_samp_fields';
  amount_value?: Maybe<Scalars['Float']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['Float']>;
}

/** order by var_samp() on columns of table "voucher" */
export interface Voucher_Var_Samp_Order_By {
  amount_value?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
}

/** aggregate variance on columns */
export interface Voucher_Variance_Fields {
  __typename?: 'voucher_variance_fields';
  amount_value?: Maybe<Scalars['Float']>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Scalars['Float']>;
}

/** order by variance() on columns of table "voucher" */
export interface Voucher_Variance_Order_By {
  amount_value?: Maybe<Order_By>;
  /** Percent value from 1-100 */
  percent_value?: Maybe<Order_By>;
}

/** Used to display 100% discounted reservations */
export interface Vw_Admin_100_Percent_Discount_Reservations {
  __typename?: 'vw_admin_100_percent_discount_reservations';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}


/** Used to display 100% discounted reservations */
export type Vw_Admin_100_Percent_Discount_ReservationsDriver_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Used to display 100% discounted reservations */
export type Vw_Admin_100_Percent_Discount_ReservationsHistory_SnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Used to display 100% discounted reservations */
export type Vw_Admin_100_Percent_Discount_ReservationsMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Used to display 100% discounted reservations */
export type Vw_Admin_100_Percent_Discount_ReservationsReservee_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Used to display 100% discounted reservations */
export type Vw_Admin_100_Percent_Discount_ReservationsReservor_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_admin_100_percent_discount_reservations" */
export interface Vw_Admin_100_Percent_Discount_Reservations_Aggregate {
  __typename?: 'vw_admin_100_percent_discount_reservations_aggregate';
  aggregate?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Aggregate_Fields>;
  nodes: Array<Vw_Admin_100_Percent_Discount_Reservations>;
}

/** aggregate fields of "vw_admin_100_percent_discount_reservations" */
export interface Vw_Admin_100_Percent_Discount_Reservations_Aggregate_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_aggregate_fields';
  avg?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Max_Fields>;
  min?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Min_Fields>;
  stddev?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Sum_Fields>;
  var_pop?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Var_Samp_Fields>;
  variance?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Variance_Fields>;
}


/** aggregate fields of "vw_admin_100_percent_discount_reservations" */
export type Vw_Admin_100_Percent_Discount_Reservations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Vw_Admin_100_Percent_Discount_Reservations_Append_Input {
  driver_metadata?: Maybe<Scalars['jsonb']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
}

/** aggregate avg on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Avg_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_avg_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_admin_100_percent_discount_reservations". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp>>;
  accepted_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  driver_id?: Maybe<String_Comparison_Exp>;
  driver_metadata?: Maybe<Jsonb_Comparison_Exp>;
  duration_billable?: Maybe<Numeric_Comparison_Exp>;
  duration_estimate?: Maybe<Numeric_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  ends_at?: Maybe<Timestamptz_Comparison_Exp>;
  extra_costs_text?: Maybe<String_Comparison_Exp>;
  extra_costs_value?: Maybe<Numeric_Comparison_Exp>;
  history_snapshot?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  preferred_driver?: Maybe<String_Comparison_Exp>;
  price_billable?: Maybe<Numeric_Comparison_Exp>;
  price_billable_discounted?: Maybe<Numeric_Comparison_Exp>;
  price_estimate?: Maybe<Numeric_Comparison_Exp>;
  price_estimate_discounted?: Maybe<Numeric_Comparison_Exp>;
  reservee_id?: Maybe<String_Comparison_Exp>;
  reservee_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservee_role?: Maybe<String_Comparison_Exp>;
  reservor_id?: Maybe<String_Comparison_Exp>;
  reservor_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservor_role?: Maybe<String_Comparison_Exp>;
  service_id?: Maybe<String_Comparison_Exp>;
  service_type?: Maybe<String_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  starts_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  voucher_code?: Maybe<String_Comparison_Exp>;
  voucher_not_applicable_reason?: Maybe<String_Comparison_Exp>;
  voucher_redemption_id?: Maybe<String_Comparison_Exp>;
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Vw_Admin_100_Percent_Discount_Reservations_Delete_At_Path_Input {
  driver_metadata?: Maybe<Array<Scalars['String']>>;
  history_snapshot?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
  reservee_metadata?: Maybe<Array<Scalars['String']>>;
  reservor_metadata?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Vw_Admin_100_Percent_Discount_Reservations_Delete_Elem_Input {
  driver_metadata?: Maybe<Scalars['Int']>;
  history_snapshot?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
  reservee_metadata?: Maybe<Scalars['Int']>;
  reservor_metadata?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Vw_Admin_100_Percent_Discount_Reservations_Delete_Key_Input {
  driver_metadata?: Maybe<Scalars['String']>;
  history_snapshot?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "vw_admin_100_percent_discount_reservations" */
export interface Vw_Admin_100_Percent_Discount_Reservations_Inc_Input {
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
}

/** input type for inserting data into table "vw_admin_100_percent_discount_reservations" */
export interface Vw_Admin_100_Percent_Discount_Reservations_Insert_Input {
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Max_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_max_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Min_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_min_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "vw_admin_100_percent_discount_reservations" */
export interface Vw_Admin_100_Percent_Discount_Reservations_Mutation_Response {
  __typename?: 'vw_admin_100_percent_discount_reservations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vw_Admin_100_Percent_Discount_Reservations>;
}

/** Ordering options when selecting data from "vw_admin_100_percent_discount_reservations". */
export interface Vw_Admin_100_Percent_Discount_Reservations_Order_By {
  accepted_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  driver_metadata?: Maybe<Order_By>;
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  extra_costs_text?: Maybe<Order_By>;
  extra_costs_value?: Maybe<Order_By>;
  history_snapshot?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  preferred_driver?: Maybe<Order_By>;
  price_billable?: Maybe<Order_By>;
  price_billable_discounted?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  price_estimate_discounted?: Maybe<Order_By>;
  reservee_id?: Maybe<Order_By>;
  reservee_metadata?: Maybe<Order_By>;
  reservee_role?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
  reservor_metadata?: Maybe<Order_By>;
  reservor_role?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  service_type?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  voucher_code?: Maybe<Order_By>;
  voucher_not_applicable_reason?: Maybe<Order_By>;
  voucher_redemption_id?: Maybe<Order_By>;
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Vw_Admin_100_Percent_Discount_Reservations_Prepend_Input {
  driver_metadata?: Maybe<Scalars['jsonb']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "vw_admin_100_percent_discount_reservations" */
export enum Vw_Admin_100_Percent_Discount_Reservations_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverMetadata = 'driver_metadata',
  /** column name */
  DurationBillable = 'duration_billable',
  /** column name */
  DurationEstimate = 'duration_estimate',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraCostsText = 'extra_costs_text',
  /** column name */
  ExtraCostsValue = 'extra_costs_value',
  /** column name */
  HistorySnapshot = 'history_snapshot',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PreferredDriver = 'preferred_driver',
  /** column name */
  PriceBillable = 'price_billable',
  /** column name */
  PriceBillableDiscounted = 'price_billable_discounted',
  /** column name */
  PriceEstimate = 'price_estimate',
  /** column name */
  PriceEstimateDiscounted = 'price_estimate_discounted',
  /** column name */
  ReserveeId = 'reservee_id',
  /** column name */
  ReserveeMetadata = 'reservee_metadata',
  /** column name */
  ReserveeRole = 'reservee_role',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  ReservorMetadata = 'reservor_metadata',
  /** column name */
  ReservorRole = 'reservor_role',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherCode = 'voucher_code',
  /** column name */
  VoucherNotApplicableReason = 'voucher_not_applicable_reason',
  /** column name */
  VoucherRedemptionId = 'voucher_redemption_id'
}

/** input type for updating data in table "vw_admin_100_percent_discount_reservations" */
export interface Vw_Admin_100_Percent_Discount_Reservations_Set_Input {
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Stddev_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_stddev_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Stddev_Pop_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_stddev_pop_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Stddev_Samp_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_stddev_samp_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_admin_100_percent_discount_reservations" */
export interface Vw_Admin_100_Percent_Discount_Reservations_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_100_Percent_Discount_Reservations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_100_Percent_Discount_Reservations_Stream_Cursor_Value_Input {
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Sum_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_sum_fields';
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
}

export interface Vw_Admin_100_Percent_Discount_Reservations_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vw_Admin_100_Percent_Discount_Reservations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vw_Admin_100_Percent_Discount_Reservations_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Var_Pop_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_var_pop_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Var_Samp_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_var_samp_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Admin_100_Percent_Discount_Reservations_Variance_Fields {
  __typename?: 'vw_admin_100_percent_discount_reservations_variance_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_admin_account" */
export interface Vw_Admin_Account {
  __typename?: 'vw_admin_account';
  birth_date?: Maybe<Scalars['String']>;
  cities?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_driver_license_verified?: Maybe<Scalars['Boolean']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  document_verification_id_verified?: Maybe<Scalars['Boolean']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_status?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  home_address?: Maybe<Scalars['String']>;
  iban_connected?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  is_administrator?: Maybe<Scalars['Boolean']>;
  is_champion_patient?: Maybe<Scalars['Boolean']>;
  is_covid_passport_approved?: Maybe<Scalars['Boolean']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  is_document_verification_driver_license_approved?: Maybe<Scalars['Boolean']>;
  is_document_verification_id_approved?: Maybe<Scalars['Boolean']>;
  is_driver?: Maybe<Scalars['Boolean']>;
  is_email_verified?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_patient?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  license_plate?: Maybe<Scalars['String']>;
  payment_metadata?: Maybe<Scalars['jsonb']>;
  payment_methods?: Maybe<Scalars['jsonb']>;
  personal_data?: Maybe<Scalars['jsonb']>;
  phone_number?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  roles?: Maybe<Scalars['jsonb']>;
  sex?: Maybe<Scalars['String']>;
  stripe_connected_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucherify_customer_id?: Maybe<Scalars['String']>;
}


/** columns and relationships of "vw_admin_account" */
export type Vw_Admin_AccountCitiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_account" */
export type Vw_Admin_AccountPayment_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_account" */
export type Vw_Admin_AccountPayment_MethodsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_account" */
export type Vw_Admin_AccountPersonal_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_account" */
export type Vw_Admin_AccountRolesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_admin_account" */
export interface Vw_Admin_Account_Aggregate {
  __typename?: 'vw_admin_account_aggregate';
  aggregate?: Maybe<Vw_Admin_Account_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Account>;
}

/** aggregate fields of "vw_admin_account" */
export interface Vw_Admin_Account_Aggregate_Fields {
  __typename?: 'vw_admin_account_aggregate_fields';
  avg?: Maybe<Vw_Admin_Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Account_Max_Fields>;
  min?: Maybe<Vw_Admin_Account_Min_Fields>;
  stddev?: Maybe<Vw_Admin_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Admin_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Admin_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Admin_Account_Sum_Fields>;
  var_pop?: Maybe<Vw_Admin_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Admin_Account_Var_Samp_Fields>;
  variance?: Maybe<Vw_Admin_Account_Variance_Fields>;
}


/** aggregate fields of "vw_admin_account" */
export type Vw_Admin_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Admin_Account_Avg_Fields {
  __typename?: 'vw_admin_account_avg_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_admin_account". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Account_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Account_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Account_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Account_Bool_Exp>>;
  birth_date?: Maybe<String_Comparison_Exp>;
  cities?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_driver_license_status?: Maybe<String_Comparison_Exp>;
  document_verification_driver_license_verified?: Maybe<Boolean_Comparison_Exp>;
  document_verification_id_status?: Maybe<String_Comparison_Exp>;
  document_verification_id_verified?: Maybe<Boolean_Comparison_Exp>;
  dof_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  dof_requested_city?: Maybe<String_Comparison_Exp>;
  driver_status?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  fiscal_code?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  home_address?: Maybe<String_Comparison_Exp>;
  iban_connected?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  is_academy_completed?: Maybe<Boolean_Comparison_Exp>;
  is_administrator?: Maybe<Boolean_Comparison_Exp>;
  is_champion_patient?: Maybe<Boolean_Comparison_Exp>;
  is_covid_passport_approved?: Maybe<Boolean_Comparison_Exp>;
  is_disabled?: Maybe<Boolean_Comparison_Exp>;
  is_document_verification_driver_license_approved?: Maybe<Boolean_Comparison_Exp>;
  is_document_verification_id_approved?: Maybe<Boolean_Comparison_Exp>;
  is_driver?: Maybe<Boolean_Comparison_Exp>;
  is_email_verified?: Maybe<Boolean_Comparison_Exp>;
  is_general_info_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_patient?: Maybe<Boolean_Comparison_Exp>;
  is_typeform_documents_approved?: Maybe<Boolean_Comparison_Exp>;
  is_typeform_documents_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_video_verification_approved?: Maybe<Boolean_Comparison_Exp>;
  is_video_verification_submitted?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  license_plate?: Maybe<String_Comparison_Exp>;
  payment_metadata?: Maybe<Jsonb_Comparison_Exp>;
  payment_methods?: Maybe<Jsonb_Comparison_Exp>;
  personal_data?: Maybe<Jsonb_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  reservation_count?: Maybe<Bigint_Comparison_Exp>;
  roles?: Maybe<Jsonb_Comparison_Exp>;
  sex?: Maybe<String_Comparison_Exp>;
  stripe_connected_account_id?: Maybe<String_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  voucherify_customer_id?: Maybe<String_Comparison_Exp>;
}

/** columns and relationships of "vw_admin_account_feedback" */
export interface Vw_Admin_Account_Feedback {
  __typename?: 'vw_admin_account_feedback';
  account_feedback_id?: Maybe<Scalars['uuid']>;
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  payment_metadata?: Maybe<Scalars['jsonb']>;
  payment_methods?: Maybe<Scalars['jsonb']>;
  personal_data?: Maybe<Scalars['jsonb']>;
  traffic_source?: Maybe<Scalars['String']>;
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  traffic_source_created_at?: Maybe<Scalars['timestamptz']>;
  traffic_source_other_text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}


/** columns and relationships of "vw_admin_account_feedback" */
export type Vw_Admin_Account_FeedbackPayment_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_account_feedback" */
export type Vw_Admin_Account_FeedbackPayment_MethodsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_account_feedback" */
export type Vw_Admin_Account_FeedbackPersonal_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_admin_account_feedback" */
export interface Vw_Admin_Account_Feedback_Aggregate {
  __typename?: 'vw_admin_account_feedback_aggregate';
  aggregate?: Maybe<Vw_Admin_Account_Feedback_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Account_Feedback>;
}

/** aggregate fields of "vw_admin_account_feedback" */
export interface Vw_Admin_Account_Feedback_Aggregate_Fields {
  __typename?: 'vw_admin_account_feedback_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Account_Feedback_Max_Fields>;
  min?: Maybe<Vw_Admin_Account_Feedback_Min_Fields>;
}


/** aggregate fields of "vw_admin_account_feedback" */
export type Vw_Admin_Account_Feedback_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Account_Feedback_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vw_admin_account_feedback". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Account_Feedback_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Account_Feedback_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Account_Feedback_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Account_Feedback_Bool_Exp>>;
  account_feedback_id?: Maybe<Uuid_Comparison_Exp>;
  account_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  payment_metadata?: Maybe<Jsonb_Comparison_Exp>;
  payment_methods?: Maybe<Jsonb_Comparison_Exp>;
  personal_data?: Maybe<Jsonb_Comparison_Exp>;
  traffic_source?: Maybe<String_Comparison_Exp>;
  traffic_source_affiliate_text?: Maybe<String_Comparison_Exp>;
  traffic_source_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  traffic_source_other_text?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Admin_Account_Feedback_Max_Fields {
  __typename?: 'vw_admin_account_feedback_max_fields';
  account_feedback_id?: Maybe<Scalars['uuid']>;
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  traffic_source?: Maybe<Scalars['String']>;
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  traffic_source_created_at?: Maybe<Scalars['timestamptz']>;
  traffic_source_other_text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Account_Feedback_Min_Fields {
  __typename?: 'vw_admin_account_feedback_min_fields';
  account_feedback_id?: Maybe<Scalars['uuid']>;
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  traffic_source?: Maybe<Scalars['String']>;
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  traffic_source_created_at?: Maybe<Scalars['timestamptz']>;
  traffic_source_other_text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** Ordering options when selecting data from "vw_admin_account_feedback". */
export interface Vw_Admin_Account_Feedback_Order_By {
  account_feedback_id?: Maybe<Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_metadata?: Maybe<Order_By>;
  payment_methods?: Maybe<Order_By>;
  personal_data?: Maybe<Order_By>;
  traffic_source?: Maybe<Order_By>;
  traffic_source_affiliate_text?: Maybe<Order_By>;
  traffic_source_created_at?: Maybe<Order_By>;
  traffic_source_other_text?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** select columns of table "vw_admin_account_feedback" */
export enum Vw_Admin_Account_Feedback_Select_Column {
  /** column name */
  AccountFeedbackId = 'account_feedback_id',
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMetadata = 'payment_metadata',
  /** column name */
  PaymentMethods = 'payment_methods',
  /** column name */
  PersonalData = 'personal_data',
  /** column name */
  TrafficSource = 'traffic_source',
  /** column name */
  TrafficSourceAffiliateText = 'traffic_source_affiliate_text',
  /** column name */
  TrafficSourceCreatedAt = 'traffic_source_created_at',
  /** column name */
  TrafficSourceOtherText = 'traffic_source_other_text',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "vw_admin_account_feedback" */
export interface Vw_Admin_Account_Feedback_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Account_Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Account_Feedback_Stream_Cursor_Value_Input {
  account_feedback_id?: Maybe<Scalars['uuid']>;
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  payment_metadata?: Maybe<Scalars['jsonb']>;
  payment_methods?: Maybe<Scalars['jsonb']>;
  personal_data?: Maybe<Scalars['jsonb']>;
  traffic_source?: Maybe<Scalars['String']>;
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  traffic_source_created_at?: Maybe<Scalars['timestamptz']>;
  traffic_source_other_text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Vw_Admin_Account_Max_Fields {
  __typename?: 'vw_admin_account_max_fields';
  birth_date?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_status?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  license_plate?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  sex?: Maybe<Scalars['String']>;
  stripe_connected_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucherify_customer_id?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Account_Min_Fields {
  __typename?: 'vw_admin_account_min_fields';
  birth_date?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_status?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  license_plate?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  sex?: Maybe<Scalars['String']>;
  stripe_connected_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucherify_customer_id?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "vw_admin_account". */
export interface Vw_Admin_Account_Order_By {
  birth_date?: Maybe<Order_By>;
  cities?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  document_verification_driver_license_status?: Maybe<Order_By>;
  document_verification_driver_license_verified?: Maybe<Order_By>;
  document_verification_id_status?: Maybe<Order_By>;
  document_verification_id_verified?: Maybe<Order_By>;
  dof_approved_at?: Maybe<Order_By>;
  dof_requested_city?: Maybe<Order_By>;
  driver_status?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  fiscal_code?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  iban_connected?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_academy_completed?: Maybe<Order_By>;
  is_administrator?: Maybe<Order_By>;
  is_champion_patient?: Maybe<Order_By>;
  is_covid_passport_approved?: Maybe<Order_By>;
  is_disabled?: Maybe<Order_By>;
  is_document_verification_driver_license_approved?: Maybe<Order_By>;
  is_document_verification_id_approved?: Maybe<Order_By>;
  is_driver?: Maybe<Order_By>;
  is_email_verified?: Maybe<Order_By>;
  is_general_info_submitted?: Maybe<Order_By>;
  is_patient?: Maybe<Order_By>;
  is_typeform_documents_approved?: Maybe<Order_By>;
  is_typeform_documents_submitted?: Maybe<Order_By>;
  is_video_verification_approved?: Maybe<Order_By>;
  is_video_verification_submitted?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  license_plate?: Maybe<Order_By>;
  payment_metadata?: Maybe<Order_By>;
  payment_methods?: Maybe<Order_By>;
  personal_data?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  reservation_count?: Maybe<Order_By>;
  roles?: Maybe<Order_By>;
  sex?: Maybe<Order_By>;
  stripe_connected_account_id?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  voucherify_customer_id?: Maybe<Order_By>;
}

/** select columns of table "vw_admin_account" */
export enum Vw_Admin_Account_Select_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  Cities = 'cities',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentVerificationDriverLicenseStatus = 'document_verification_driver_license_status',
  /** column name */
  DocumentVerificationDriverLicenseVerified = 'document_verification_driver_license_verified',
  /** column name */
  DocumentVerificationIdStatus = 'document_verification_id_status',
  /** column name */
  DocumentVerificationIdVerified = 'document_verification_id_verified',
  /** column name */
  DofApprovedAt = 'dof_approved_at',
  /** column name */
  DofRequestedCity = 'dof_requested_city',
  /** column name */
  DriverStatus = 'driver_status',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FiscalCode = 'fiscal_code',
  /** column name */
  FullName = 'full_name',
  /** column name */
  HomeAddress = 'home_address',
  /** column name */
  IbanConnected = 'iban_connected',
  /** column name */
  Id = 'id',
  /** column name */
  IsAcademyCompleted = 'is_academy_completed',
  /** column name */
  IsAdministrator = 'is_administrator',
  /** column name */
  IsChampionPatient = 'is_champion_patient',
  /** column name */
  IsCovidPassportApproved = 'is_covid_passport_approved',
  /** column name */
  IsDisabled = 'is_disabled',
  /** column name */
  IsDocumentVerificationDriverLicenseApproved = 'is_document_verification_driver_license_approved',
  /** column name */
  IsDocumentVerificationIdApproved = 'is_document_verification_id_approved',
  /** column name */
  IsDriver = 'is_driver',
  /** column name */
  IsEmailVerified = 'is_email_verified',
  /** column name */
  IsGeneralInfoSubmitted = 'is_general_info_submitted',
  /** column name */
  IsPatient = 'is_patient',
  /** column name */
  IsTypeformDocumentsApproved = 'is_typeform_documents_approved',
  /** column name */
  IsTypeformDocumentsSubmitted = 'is_typeform_documents_submitted',
  /** column name */
  IsVideoVerificationApproved = 'is_video_verification_approved',
  /** column name */
  IsVideoVerificationSubmitted = 'is_video_verification_submitted',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LicensePlate = 'license_plate',
  /** column name */
  PaymentMetadata = 'payment_metadata',
  /** column name */
  PaymentMethods = 'payment_methods',
  /** column name */
  PersonalData = 'personal_data',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ReservationCount = 'reservation_count',
  /** column name */
  Roles = 'roles',
  /** column name */
  Sex = 'sex',
  /** column name */
  StripeConnectedAccountId = 'stripe_connected_account_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherifyCustomerId = 'voucherify_customer_id'
}

/** aggregate stddev on columns */
export interface Vw_Admin_Account_Stddev_Fields {
  __typename?: 'vw_admin_account_stddev_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Admin_Account_Stddev_Pop_Fields {
  __typename?: 'vw_admin_account_stddev_pop_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Admin_Account_Stddev_Samp_Fields {
  __typename?: 'vw_admin_account_stddev_samp_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_admin_account" */
export interface Vw_Admin_Account_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Account_Stream_Cursor_Value_Input {
  birth_date?: Maybe<Scalars['String']>;
  cities?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_driver_license_verified?: Maybe<Scalars['Boolean']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  document_verification_id_verified?: Maybe<Scalars['Boolean']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_status?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  fiscal_code?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  home_address?: Maybe<Scalars['String']>;
  iban_connected?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  is_administrator?: Maybe<Scalars['Boolean']>;
  is_champion_patient?: Maybe<Scalars['Boolean']>;
  is_covid_passport_approved?: Maybe<Scalars['Boolean']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  is_document_verification_driver_license_approved?: Maybe<Scalars['Boolean']>;
  is_document_verification_id_approved?: Maybe<Scalars['Boolean']>;
  is_driver?: Maybe<Scalars['Boolean']>;
  is_email_verified?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_patient?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  license_plate?: Maybe<Scalars['String']>;
  payment_metadata?: Maybe<Scalars['jsonb']>;
  payment_methods?: Maybe<Scalars['jsonb']>;
  personal_data?: Maybe<Scalars['jsonb']>;
  phone_number?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  roles?: Maybe<Scalars['jsonb']>;
  sex?: Maybe<Scalars['String']>;
  stripe_connected_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucherify_customer_id?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Vw_Admin_Account_Sum_Fields {
  __typename?: 'vw_admin_account_sum_fields';
  reservation_count?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface Vw_Admin_Account_Var_Pop_Fields {
  __typename?: 'vw_admin_account_var_pop_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Admin_Account_Var_Samp_Fields {
  __typename?: 'vw_admin_account_var_samp_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Admin_Account_Variance_Fields {
  __typename?: 'vw_admin_account_variance_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_admin_cancelled_reservation" */
export interface Vw_Admin_Cancelled_Reservation {
  __typename?: 'vw_admin_cancelled_reservation';
  created_at?: Maybe<Scalars['timestamptz']>;
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  driver_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}


/** columns and relationships of "vw_admin_cancelled_reservation" */
export type Vw_Admin_Cancelled_ReservationDriver_Cancellation_ReasonsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_cancelled_reservation" */
export type Vw_Admin_Cancelled_ReservationMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_admin_cancelled_reservation" */
export interface Vw_Admin_Cancelled_Reservation_Aggregate {
  __typename?: 'vw_admin_cancelled_reservation_aggregate';
  aggregate?: Maybe<Vw_Admin_Cancelled_Reservation_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Cancelled_Reservation>;
}

/** aggregate fields of "vw_admin_cancelled_reservation" */
export interface Vw_Admin_Cancelled_Reservation_Aggregate_Fields {
  __typename?: 'vw_admin_cancelled_reservation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Cancelled_Reservation_Max_Fields>;
  min?: Maybe<Vw_Admin_Cancelled_Reservation_Min_Fields>;
}


/** aggregate fields of "vw_admin_cancelled_reservation" */
export type Vw_Admin_Cancelled_Reservation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Vw_Admin_Cancelled_Reservation_Append_Input {
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
}

/** Boolean expression to filter rows from the table "vw_admin_cancelled_reservation". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Cancelled_Reservation_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Cancelled_Reservation_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Cancelled_Reservation_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  driver_cancellation_reasons?: Maybe<Jsonb_Comparison_Exp>;
  driver_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  patient_cancellation_reason?: Maybe<String_Comparison_Exp>;
  starts_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Vw_Admin_Cancelled_Reservation_Delete_At_Path_Input {
  driver_cancellation_reasons?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Vw_Admin_Cancelled_Reservation_Delete_Elem_Input {
  driver_cancellation_reasons?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Vw_Admin_Cancelled_Reservation_Delete_Key_Input {
  driver_cancellation_reasons?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
}

/** input type for inserting data into table "vw_admin_cancelled_reservation" */
export interface Vw_Admin_Cancelled_Reservation_Insert_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  driver_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate max on columns */
export interface Vw_Admin_Cancelled_Reservation_Max_Fields {
  __typename?: 'vw_admin_cancelled_reservation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  driver_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Cancelled_Reservation_Min_Fields {
  __typename?: 'vw_admin_cancelled_reservation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  driver_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** response of any mutation on the table "vw_admin_cancelled_reservation" */
export interface Vw_Admin_Cancelled_Reservation_Mutation_Response {
  __typename?: 'vw_admin_cancelled_reservation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vw_Admin_Cancelled_Reservation>;
}

/** Ordering options when selecting data from "vw_admin_cancelled_reservation". */
export interface Vw_Admin_Cancelled_Reservation_Order_By {
  created_at?: Maybe<Order_By>;
  driver_cancellation_reasons?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  patient_cancellation_reason?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Vw_Admin_Cancelled_Reservation_Prepend_Input {
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "vw_admin_cancelled_reservation" */
export enum Vw_Admin_Cancelled_Reservation_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DriverCancellationReasons = 'driver_cancellation_reasons',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PatientCancellationReason = 'patient_cancellation_reason',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "vw_admin_cancelled_reservation" */
export interface Vw_Admin_Cancelled_Reservation_Set_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  driver_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** Streaming cursor of the table "vw_admin_cancelled_reservation" */
export interface Vw_Admin_Cancelled_Reservation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Cancelled_Reservation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Cancelled_Reservation_Stream_Cursor_Value_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  driver_cancellation_reasons?: Maybe<Scalars['jsonb']>;
  driver_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  patient_cancellation_reason?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

export interface Vw_Admin_Cancelled_Reservation_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Vw_Admin_Cancelled_Reservation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Vw_Admin_Cancelled_Reservation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Vw_Admin_Cancelled_Reservation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Vw_Admin_Cancelled_Reservation_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Vw_Admin_Cancelled_Reservation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vw_Admin_Cancelled_Reservation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vw_Admin_Cancelled_Reservation_Bool_Exp;
}

/** columns and relationships of "vw_admin_default_account_credit_cards" */
export interface Vw_Admin_Default_Account_Credit_Cards {
  __typename?: 'vw_admin_default_account_credit_cards';
  email?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
}

/** aggregated selection of "vw_admin_default_account_credit_cards" */
export interface Vw_Admin_Default_Account_Credit_Cards_Aggregate {
  __typename?: 'vw_admin_default_account_credit_cards_aggregate';
  aggregate?: Maybe<Vw_Admin_Default_Account_Credit_Cards_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Default_Account_Credit_Cards>;
}

/** aggregate fields of "vw_admin_default_account_credit_cards" */
export interface Vw_Admin_Default_Account_Credit_Cards_Aggregate_Fields {
  __typename?: 'vw_admin_default_account_credit_cards_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Default_Account_Credit_Cards_Max_Fields>;
  min?: Maybe<Vw_Admin_Default_Account_Credit_Cards_Min_Fields>;
}


/** aggregate fields of "vw_admin_default_account_credit_cards" */
export type Vw_Admin_Default_Account_Credit_Cards_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vw_admin_default_account_credit_cards". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Default_Account_Credit_Cards_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Default_Account_Credit_Cards_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Default_Account_Credit_Cards_Bool_Exp>>;
  email?: Maybe<String_Comparison_Exp>;
  expiry_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last4?: Maybe<String_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Admin_Default_Account_Credit_Cards_Max_Fields {
  __typename?: 'vw_admin_default_account_credit_cards_max_fields';
  email?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Default_Account_Credit_Cards_Min_Fields {
  __typename?: 'vw_admin_default_account_credit_cards_min_fields';
  email?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "vw_admin_default_account_credit_cards". */
export interface Vw_Admin_Default_Account_Credit_Cards_Order_By {
  email?: Maybe<Order_By>;
  expiry_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last4?: Maybe<Order_By>;
}

/** select columns of table "vw_admin_default_account_credit_cards" */
export enum Vw_Admin_Default_Account_Credit_Cards_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  ExpiryDate = 'expiry_date',
  /** column name */
  Id = 'id',
  /** column name */
  Last4 = 'last4'
}

/** Streaming cursor of the table "vw_admin_default_account_credit_cards" */
export interface Vw_Admin_Default_Account_Credit_Cards_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Default_Account_Credit_Cards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Default_Account_Credit_Cards_Stream_Cursor_Value_Input {
  email?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
}

/** columns and relationships of "vw_admin_dof_drivers" */
export interface Vw_Admin_Dof_Drivers {
  __typename?: 'vw_admin_dof_drivers';
  created_at?: Maybe<Scalars['timestamptz']>;
  dof_approved?: Maybe<Scalars['Boolean']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  is_driver_license_verified?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_id_verified?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  logged_in_academy_at_least_once?: Maybe<Scalars['Boolean']>;
  reservation_count?: Maybe<Scalars['bigint']>;
}

/** aggregated selection of "vw_admin_dof_drivers" */
export interface Vw_Admin_Dof_Drivers_Aggregate {
  __typename?: 'vw_admin_dof_drivers_aggregate';
  aggregate?: Maybe<Vw_Admin_Dof_Drivers_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Dof_Drivers>;
}

/** aggregate fields of "vw_admin_dof_drivers" */
export interface Vw_Admin_Dof_Drivers_Aggregate_Fields {
  __typename?: 'vw_admin_dof_drivers_aggregate_fields';
  avg?: Maybe<Vw_Admin_Dof_Drivers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Dof_Drivers_Max_Fields>;
  min?: Maybe<Vw_Admin_Dof_Drivers_Min_Fields>;
  stddev?: Maybe<Vw_Admin_Dof_Drivers_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Admin_Dof_Drivers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Admin_Dof_Drivers_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Admin_Dof_Drivers_Sum_Fields>;
  var_pop?: Maybe<Vw_Admin_Dof_Drivers_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Admin_Dof_Drivers_Var_Samp_Fields>;
  variance?: Maybe<Vw_Admin_Dof_Drivers_Variance_Fields>;
}


/** aggregate fields of "vw_admin_dof_drivers" */
export type Vw_Admin_Dof_Drivers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Dof_Drivers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Admin_Dof_Drivers_Avg_Fields {
  __typename?: 'vw_admin_dof_drivers_avg_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_admin_dof_drivers". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Dof_Drivers_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Dof_Drivers_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Dof_Drivers_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Dof_Drivers_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dof_approved?: Maybe<Boolean_Comparison_Exp>;
  dof_requested_city?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_academy_completed?: Maybe<Boolean_Comparison_Exp>;
  is_driver_license_verified?: Maybe<Boolean_Comparison_Exp>;
  is_general_info_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_id_verified?: Maybe<Boolean_Comparison_Exp>;
  is_video_verification_submitted?: Maybe<Boolean_Comparison_Exp>;
  logged_in_academy_at_least_once?: Maybe<Boolean_Comparison_Exp>;
  reservation_count?: Maybe<Bigint_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Admin_Dof_Drivers_Max_Fields {
  __typename?: 'vw_admin_dof_drivers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  reservation_count?: Maybe<Scalars['bigint']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Dof_Drivers_Min_Fields {
  __typename?: 'vw_admin_dof_drivers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  reservation_count?: Maybe<Scalars['bigint']>;
}

/** Ordering options when selecting data from "vw_admin_dof_drivers". */
export interface Vw_Admin_Dof_Drivers_Order_By {
  created_at?: Maybe<Order_By>;
  dof_approved?: Maybe<Order_By>;
  dof_requested_city?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_academy_completed?: Maybe<Order_By>;
  is_driver_license_verified?: Maybe<Order_By>;
  is_general_info_submitted?: Maybe<Order_By>;
  is_id_verified?: Maybe<Order_By>;
  is_video_verification_submitted?: Maybe<Order_By>;
  logged_in_academy_at_least_once?: Maybe<Order_By>;
  reservation_count?: Maybe<Order_By>;
}

/** select columns of table "vw_admin_dof_drivers" */
export enum Vw_Admin_Dof_Drivers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DofApproved = 'dof_approved',
  /** column name */
  DofRequestedCity = 'dof_requested_city',
  /** column name */
  Id = 'id',
  /** column name */
  IsAcademyCompleted = 'is_academy_completed',
  /** column name */
  IsDriverLicenseVerified = 'is_driver_license_verified',
  /** column name */
  IsGeneralInfoSubmitted = 'is_general_info_submitted',
  /** column name */
  IsIdVerified = 'is_id_verified',
  /** column name */
  IsVideoVerificationSubmitted = 'is_video_verification_submitted',
  /** column name */
  LoggedInAcademyAtLeastOnce = 'logged_in_academy_at_least_once',
  /** column name */
  ReservationCount = 'reservation_count'
}

/** aggregate stddev on columns */
export interface Vw_Admin_Dof_Drivers_Stddev_Fields {
  __typename?: 'vw_admin_dof_drivers_stddev_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Admin_Dof_Drivers_Stddev_Pop_Fields {
  __typename?: 'vw_admin_dof_drivers_stddev_pop_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Admin_Dof_Drivers_Stddev_Samp_Fields {
  __typename?: 'vw_admin_dof_drivers_stddev_samp_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_admin_dof_drivers" */
export interface Vw_Admin_Dof_Drivers_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Dof_Drivers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Dof_Drivers_Stream_Cursor_Value_Input {
  created_at?: Maybe<Scalars['timestamptz']>;
  dof_approved?: Maybe<Scalars['Boolean']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  is_driver_license_verified?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_id_verified?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  logged_in_academy_at_least_once?: Maybe<Scalars['Boolean']>;
  reservation_count?: Maybe<Scalars['bigint']>;
}

/** aggregate sum on columns */
export interface Vw_Admin_Dof_Drivers_Sum_Fields {
  __typename?: 'vw_admin_dof_drivers_sum_fields';
  reservation_count?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface Vw_Admin_Dof_Drivers_Var_Pop_Fields {
  __typename?: 'vw_admin_dof_drivers_var_pop_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Admin_Dof_Drivers_Var_Samp_Fields {
  __typename?: 'vw_admin_dof_drivers_var_samp_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Admin_Dof_Drivers_Variance_Fields {
  __typename?: 'vw_admin_dof_drivers_variance_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_admin_driver_profile" */
export interface Vw_Admin_Driver_Profile {
  __typename?: 'vw_admin_driver_profile';
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  account_id?: Maybe<Scalars['String']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  cities?: Maybe<Scalars['jsonb']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_payload?: Maybe<Scalars['jsonb']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  general_info_payload?: Maybe<Scalars['jsonb']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  iban_connected?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  is_academy_email_reminder_sent?: Maybe<Scalars['Boolean']>;
  is_covid_passport_approved?: Maybe<Scalars['Boolean']>;
  is_covid_passport_submitted?: Maybe<Scalars['Boolean']>;
  is_document_verification_driver_license_approved?: Maybe<Scalars['Boolean']>;
  is_document_verification_id_approved?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}


/** columns and relationships of "vw_admin_driver_profile" */
export type Vw_Admin_Driver_ProfileActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_driver_profile" */
export type Vw_Admin_Driver_ProfileCitiesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_driver_profile" */
export type Vw_Admin_Driver_ProfileCovid_Passport_PayloadArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_driver_profile" */
export type Vw_Admin_Driver_ProfileDocument_Verification_Driver_License_SnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_driver_profile" */
export type Vw_Admin_Driver_ProfileDocument_Verification_Id_SnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_driver_profile" */
export type Vw_Admin_Driver_ProfileGeneral_Info_PayloadArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_driver_profile" */
export type Vw_Admin_Driver_ProfileServicesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_driver_profile" */
export type Vw_Admin_Driver_ProfileTypeform_Documents_PayloadArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_admin_driver_profile" */
export interface Vw_Admin_Driver_Profile_Aggregate {
  __typename?: 'vw_admin_driver_profile_aggregate';
  aggregate?: Maybe<Vw_Admin_Driver_Profile_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Driver_Profile>;
}

/** aggregate fields of "vw_admin_driver_profile" */
export interface Vw_Admin_Driver_Profile_Aggregate_Fields {
  __typename?: 'vw_admin_driver_profile_aggregate_fields';
  avg?: Maybe<Vw_Admin_Driver_Profile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Driver_Profile_Max_Fields>;
  min?: Maybe<Vw_Admin_Driver_Profile_Min_Fields>;
  stddev?: Maybe<Vw_Admin_Driver_Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Admin_Driver_Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Admin_Driver_Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Admin_Driver_Profile_Sum_Fields>;
  var_pop?: Maybe<Vw_Admin_Driver_Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Admin_Driver_Profile_Var_Samp_Fields>;
  variance?: Maybe<Vw_Admin_Driver_Profile_Variance_Fields>;
}


/** aggregate fields of "vw_admin_driver_profile" */
export type Vw_Admin_Driver_Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Driver_Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Admin_Driver_Profile_Avg_Fields {
  __typename?: 'vw_admin_driver_profile_avg_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_admin_driver_profile". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Driver_Profile_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Driver_Profile_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Driver_Profile_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Driver_Profile_Bool_Exp>>;
  academy_first_login_at?: Maybe<Timestamptz_Comparison_Exp>;
  account_id?: Maybe<String_Comparison_Exp>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  cities?: Maybe<Jsonb_Comparison_Exp>;
  covid_passport_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  covid_passport_payload?: Maybe<Jsonb_Comparison_Exp>;
  covid_passport_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_driver_license_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_driver_license_force_verified?: Maybe<Boolean_Comparison_Exp>;
  document_verification_driver_license_force_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_driver_license_session_id?: Maybe<String_Comparison_Exp>;
  document_verification_driver_license_snapshot?: Maybe<Jsonb_Comparison_Exp>;
  document_verification_driver_license_status?: Maybe<String_Comparison_Exp>;
  document_verification_id_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_id_force_verified?: Maybe<Boolean_Comparison_Exp>;
  document_verification_id_force_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  document_verification_id_session_id?: Maybe<String_Comparison_Exp>;
  document_verification_id_snapshot?: Maybe<Jsonb_Comparison_Exp>;
  document_verification_id_status?: Maybe<String_Comparison_Exp>;
  dof_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  dof_rejected_at?: Maybe<Timestamptz_Comparison_Exp>;
  dof_rejected_reason?: Maybe<String_Comparison_Exp>;
  dof_requested_city?: Maybe<String_Comparison_Exp>;
  driver_license_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  general_info_payload?: Maybe<Jsonb_Comparison_Exp>;
  general_info_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  iban_connected?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  id_card_verified_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_academy_completed?: Maybe<Boolean_Comparison_Exp>;
  is_academy_email_reminder_sent?: Maybe<Boolean_Comparison_Exp>;
  is_covid_passport_approved?: Maybe<Boolean_Comparison_Exp>;
  is_covid_passport_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_document_verification_driver_license_approved?: Maybe<Boolean_Comparison_Exp>;
  is_document_verification_id_approved?: Maybe<Boolean_Comparison_Exp>;
  is_general_info_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_typeform_documents_approved?: Maybe<Boolean_Comparison_Exp>;
  is_typeform_documents_submitted?: Maybe<Boolean_Comparison_Exp>;
  is_video_verification_approved?: Maybe<Boolean_Comparison_Exp>;
  is_video_verification_submitted?: Maybe<Boolean_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  reservation_count?: Maybe<Bigint_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  typeform_documents_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  typeform_documents_payload?: Maybe<Jsonb_Comparison_Exp>;
  typeform_documents_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_verification_approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_verification_submitted_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_verification_uri?: Maybe<String_Comparison_Exp>;
  videoask_contact_id?: Maybe<String_Comparison_Exp>;
  videoask_form_id?: Maybe<String_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Admin_Driver_Profile_Max_Fields {
  __typename?: 'vw_admin_driver_profile_max_fields';
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  account_id?: Maybe<Scalars['String']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Driver_Profile_Min_Fields {
  __typename?: 'vw_admin_driver_profile_min_fields';
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  account_id?: Maybe<Scalars['String']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "vw_admin_driver_profile". */
export interface Vw_Admin_Driver_Profile_Order_By {
  academy_first_login_at?: Maybe<Order_By>;
  account_id?: Maybe<Order_By>;
  activity_log?: Maybe<Order_By>;
  cities?: Maybe<Order_By>;
  covid_passport_approved_at?: Maybe<Order_By>;
  covid_passport_payload?: Maybe<Order_By>;
  covid_passport_submitted_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  document_verification_driver_license_approved_at?: Maybe<Order_By>;
  document_verification_driver_license_force_verified?: Maybe<Order_By>;
  document_verification_driver_license_force_verified_at?: Maybe<Order_By>;
  document_verification_driver_license_session_id?: Maybe<Order_By>;
  document_verification_driver_license_snapshot?: Maybe<Order_By>;
  document_verification_driver_license_status?: Maybe<Order_By>;
  document_verification_id_approved_at?: Maybe<Order_By>;
  document_verification_id_force_verified?: Maybe<Order_By>;
  document_verification_id_force_verified_at?: Maybe<Order_By>;
  document_verification_id_session_id?: Maybe<Order_By>;
  document_verification_id_snapshot?: Maybe<Order_By>;
  document_verification_id_status?: Maybe<Order_By>;
  dof_approved_at?: Maybe<Order_By>;
  dof_rejected_at?: Maybe<Order_By>;
  dof_rejected_reason?: Maybe<Order_By>;
  dof_requested_city?: Maybe<Order_By>;
  driver_license_verified_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  general_info_payload?: Maybe<Order_By>;
  general_info_submitted_at?: Maybe<Order_By>;
  iban_connected?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_card_verified_at?: Maybe<Order_By>;
  is_academy_completed?: Maybe<Order_By>;
  is_academy_email_reminder_sent?: Maybe<Order_By>;
  is_covid_passport_approved?: Maybe<Order_By>;
  is_covid_passport_submitted?: Maybe<Order_By>;
  is_document_verification_driver_license_approved?: Maybe<Order_By>;
  is_document_verification_id_approved?: Maybe<Order_By>;
  is_general_info_submitted?: Maybe<Order_By>;
  is_typeform_documents_approved?: Maybe<Order_By>;
  is_typeform_documents_submitted?: Maybe<Order_By>;
  is_video_verification_approved?: Maybe<Order_By>;
  is_video_verification_submitted?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  reservation_count?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  typeform_documents_approved_at?: Maybe<Order_By>;
  typeform_documents_payload?: Maybe<Order_By>;
  typeform_documents_submitted_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_verification_approved_at?: Maybe<Order_By>;
  video_verification_submitted_at?: Maybe<Order_By>;
  video_verification_uri?: Maybe<Order_By>;
  videoask_contact_id?: Maybe<Order_By>;
  videoask_form_id?: Maybe<Order_By>;
}

/** select columns of table "vw_admin_driver_profile" */
export enum Vw_Admin_Driver_Profile_Select_Column {
  /** column name */
  AcademyFirstLoginAt = 'academy_first_login_at',
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  Cities = 'cities',
  /** column name */
  CovidPassportApprovedAt = 'covid_passport_approved_at',
  /** column name */
  CovidPassportPayload = 'covid_passport_payload',
  /** column name */
  CovidPassportSubmittedAt = 'covid_passport_submitted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentVerificationDriverLicenseApprovedAt = 'document_verification_driver_license_approved_at',
  /** column name */
  DocumentVerificationDriverLicenseForceVerified = 'document_verification_driver_license_force_verified',
  /** column name */
  DocumentVerificationDriverLicenseForceVerifiedAt = 'document_verification_driver_license_force_verified_at',
  /** column name */
  DocumentVerificationDriverLicenseSessionId = 'document_verification_driver_license_session_id',
  /** column name */
  DocumentVerificationDriverLicenseSnapshot = 'document_verification_driver_license_snapshot',
  /** column name */
  DocumentVerificationDriverLicenseStatus = 'document_verification_driver_license_status',
  /** column name */
  DocumentVerificationIdApprovedAt = 'document_verification_id_approved_at',
  /** column name */
  DocumentVerificationIdForceVerified = 'document_verification_id_force_verified',
  /** column name */
  DocumentVerificationIdForceVerifiedAt = 'document_verification_id_force_verified_at',
  /** column name */
  DocumentVerificationIdSessionId = 'document_verification_id_session_id',
  /** column name */
  DocumentVerificationIdSnapshot = 'document_verification_id_snapshot',
  /** column name */
  DocumentVerificationIdStatus = 'document_verification_id_status',
  /** column name */
  DofApprovedAt = 'dof_approved_at',
  /** column name */
  DofRejectedAt = 'dof_rejected_at',
  /** column name */
  DofRejectedReason = 'dof_rejected_reason',
  /** column name */
  DofRequestedCity = 'dof_requested_city',
  /** column name */
  DriverLicenseVerifiedAt = 'driver_license_verified_at',
  /** column name */
  Email = 'email',
  /** column name */
  GeneralInfoPayload = 'general_info_payload',
  /** column name */
  GeneralInfoSubmittedAt = 'general_info_submitted_at',
  /** column name */
  IbanConnected = 'iban_connected',
  /** column name */
  Id = 'id',
  /** column name */
  IdCardVerifiedAt = 'id_card_verified_at',
  /** column name */
  IsAcademyCompleted = 'is_academy_completed',
  /** column name */
  IsAcademyEmailReminderSent = 'is_academy_email_reminder_sent',
  /** column name */
  IsCovidPassportApproved = 'is_covid_passport_approved',
  /** column name */
  IsCovidPassportSubmitted = 'is_covid_passport_submitted',
  /** column name */
  IsDocumentVerificationDriverLicenseApproved = 'is_document_verification_driver_license_approved',
  /** column name */
  IsDocumentVerificationIdApproved = 'is_document_verification_id_approved',
  /** column name */
  IsGeneralInfoSubmitted = 'is_general_info_submitted',
  /** column name */
  IsTypeformDocumentsApproved = 'is_typeform_documents_approved',
  /** column name */
  IsTypeformDocumentsSubmitted = 'is_typeform_documents_submitted',
  /** column name */
  IsVideoVerificationApproved = 'is_video_verification_approved',
  /** column name */
  IsVideoVerificationSubmitted = 'is_video_verification_submitted',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  ReservationCount = 'reservation_count',
  /** column name */
  Services = 'services',
  /** column name */
  Status = 'status',
  /** column name */
  TypeformDocumentsApprovedAt = 'typeform_documents_approved_at',
  /** column name */
  TypeformDocumentsPayload = 'typeform_documents_payload',
  /** column name */
  TypeformDocumentsSubmittedAt = 'typeform_documents_submitted_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoVerificationApprovedAt = 'video_verification_approved_at',
  /** column name */
  VideoVerificationSubmittedAt = 'video_verification_submitted_at',
  /** column name */
  VideoVerificationUri = 'video_verification_uri',
  /** column name */
  VideoaskContactId = 'videoask_contact_id',
  /** column name */
  VideoaskFormId = 'videoask_form_id'
}

/** aggregate stddev on columns */
export interface Vw_Admin_Driver_Profile_Stddev_Fields {
  __typename?: 'vw_admin_driver_profile_stddev_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Admin_Driver_Profile_Stddev_Pop_Fields {
  __typename?: 'vw_admin_driver_profile_stddev_pop_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Admin_Driver_Profile_Stddev_Samp_Fields {
  __typename?: 'vw_admin_driver_profile_stddev_samp_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_admin_driver_profile" */
export interface Vw_Admin_Driver_Profile_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Driver_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Driver_Profile_Stream_Cursor_Value_Input {
  academy_first_login_at?: Maybe<Scalars['timestamptz']>;
  account_id?: Maybe<Scalars['String']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  cities?: Maybe<Scalars['jsonb']>;
  covid_passport_approved_at?: Maybe<Scalars['timestamptz']>;
  covid_passport_payload?: Maybe<Scalars['jsonb']>;
  covid_passport_submitted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_driver_license_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_driver_license_session_id?: Maybe<Scalars['String']>;
  document_verification_driver_license_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_driver_license_status?: Maybe<Scalars['String']>;
  document_verification_id_approved_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_force_verified?: Maybe<Scalars['Boolean']>;
  document_verification_id_force_verified_at?: Maybe<Scalars['timestamptz']>;
  document_verification_id_session_id?: Maybe<Scalars['String']>;
  document_verification_id_snapshot?: Maybe<Scalars['jsonb']>;
  document_verification_id_status?: Maybe<Scalars['String']>;
  dof_approved_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_at?: Maybe<Scalars['timestamptz']>;
  dof_rejected_reason?: Maybe<Scalars['String']>;
  dof_requested_city?: Maybe<Scalars['String']>;
  driver_license_verified_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  general_info_payload?: Maybe<Scalars['jsonb']>;
  general_info_submitted_at?: Maybe<Scalars['timestamptz']>;
  iban_connected?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  id_card_verified_at?: Maybe<Scalars['timestamptz']>;
  is_academy_completed?: Maybe<Scalars['Boolean']>;
  is_academy_email_reminder_sent?: Maybe<Scalars['Boolean']>;
  is_covid_passport_approved?: Maybe<Scalars['Boolean']>;
  is_covid_passport_submitted?: Maybe<Scalars['Boolean']>;
  is_document_verification_driver_license_approved?: Maybe<Scalars['Boolean']>;
  is_document_verification_id_approved?: Maybe<Scalars['Boolean']>;
  is_general_info_submitted?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_approved?: Maybe<Scalars['Boolean']>;
  is_typeform_documents_submitted?: Maybe<Scalars['Boolean']>;
  is_video_verification_approved?: Maybe<Scalars['Boolean']>;
  is_video_verification_submitted?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  services?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  typeform_documents_approved_at?: Maybe<Scalars['timestamptz']>;
  typeform_documents_payload?: Maybe<Scalars['jsonb']>;
  typeform_documents_submitted_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_verification_approved_at?: Maybe<Scalars['timestamptz']>;
  video_verification_submitted_at?: Maybe<Scalars['timestamptz']>;
  video_verification_uri?: Maybe<Scalars['String']>;
  videoask_contact_id?: Maybe<Scalars['String']>;
  videoask_form_id?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Vw_Admin_Driver_Profile_Sum_Fields {
  __typename?: 'vw_admin_driver_profile_sum_fields';
  reservation_count?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface Vw_Admin_Driver_Profile_Var_Pop_Fields {
  __typename?: 'vw_admin_driver_profile_var_pop_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Admin_Driver_Profile_Var_Samp_Fields {
  __typename?: 'vw_admin_driver_profile_var_samp_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Admin_Driver_Profile_Variance_Fields {
  __typename?: 'vw_admin_driver_profile_variance_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_admin_manual_reservation" */
export interface Vw_Admin_Manual_Reservation {
  __typename?: 'vw_admin_manual_reservation';
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  has_preferred_driver?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_two_way?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  manual_driver_first_name?: Maybe<Scalars['String']>;
  manual_driver_last_name?: Maybe<Scalars['String']>;
  manual_patient_first_name?: Maybe<Scalars['String']>;
  manual_patient_last_name?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  service?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}


/** columns and relationships of "vw_admin_manual_reservation" */
export type Vw_Admin_Manual_ReservationActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_manual_reservation" */
export type Vw_Admin_Manual_ReservationReservee_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_admin_manual_reservation" */
export interface Vw_Admin_Manual_Reservation_Aggregate {
  __typename?: 'vw_admin_manual_reservation_aggregate';
  aggregate?: Maybe<Vw_Admin_Manual_Reservation_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Manual_Reservation>;
}

/** aggregate fields of "vw_admin_manual_reservation" */
export interface Vw_Admin_Manual_Reservation_Aggregate_Fields {
  __typename?: 'vw_admin_manual_reservation_aggregate_fields';
  avg?: Maybe<Vw_Admin_Manual_Reservation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Manual_Reservation_Max_Fields>;
  min?: Maybe<Vw_Admin_Manual_Reservation_Min_Fields>;
  stddev?: Maybe<Vw_Admin_Manual_Reservation_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Admin_Manual_Reservation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Admin_Manual_Reservation_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Admin_Manual_Reservation_Sum_Fields>;
  var_pop?: Maybe<Vw_Admin_Manual_Reservation_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Admin_Manual_Reservation_Var_Samp_Fields>;
  variance?: Maybe<Vw_Admin_Manual_Reservation_Variance_Fields>;
}


/** aggregate fields of "vw_admin_manual_reservation" */
export type Vw_Admin_Manual_Reservation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Manual_Reservation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Admin_Manual_Reservation_Avg_Fields {
  __typename?: 'vw_admin_manual_reservation_avg_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_admin_manual_reservation". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Manual_Reservation_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Manual_Reservation_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Manual_Reservation_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Manual_Reservation_Bool_Exp>>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<String_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  departure_address?: Maybe<String_Comparison_Exp>;
  departure_lat?: Maybe<String_Comparison_Exp>;
  departure_lng?: Maybe<String_Comparison_Exp>;
  destination_address?: Maybe<String_Comparison_Exp>;
  destination_lat?: Maybe<String_Comparison_Exp>;
  destination_lng?: Maybe<String_Comparison_Exp>;
  distance_billable?: Maybe<Numeric_Comparison_Exp>;
  driver_id?: Maybe<Uuid_Comparison_Exp>;
  driver_preferred_id?: Maybe<Uuid_Comparison_Exp>;
  duration_billable?: Maybe<Numeric_Comparison_Exp>;
  duration_estimate?: Maybe<Numeric_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  ends_at?: Maybe<Timestamptz_Comparison_Exp>;
  extra_cost?: Maybe<Numeric_Comparison_Exp>;
  has_preferred_driver?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_two_way?: Maybe<Boolean_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  manual_driver_first_name?: Maybe<String_Comparison_Exp>;
  manual_driver_last_name?: Maybe<String_Comparison_Exp>;
  manual_patient_first_name?: Maybe<String_Comparison_Exp>;
  manual_patient_last_name?: Maybe<String_Comparison_Exp>;
  market_segment?: Maybe<String_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  preferred_driver_action?: Maybe<String_Comparison_Exp>;
  price_billable?: Maybe<Numeric_Comparison_Exp>;
  price_estimate?: Maybe<Numeric_Comparison_Exp>;
  project_name?: Maybe<String_Comparison_Exp>;
  reservee_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservor_id?: Maybe<Bigint_Comparison_Exp>;
  service?: Maybe<String_Comparison_Exp>;
  service_city?: Maybe<String_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  starts_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Admin_Manual_Reservation_Max_Fields {
  __typename?: 'vw_admin_manual_reservation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  manual_driver_first_name?: Maybe<Scalars['String']>;
  manual_driver_last_name?: Maybe<Scalars['String']>;
  manual_patient_first_name?: Maybe<Scalars['String']>;
  manual_patient_last_name?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  service?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Manual_Reservation_Min_Fields {
  __typename?: 'vw_admin_manual_reservation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  manual_driver_first_name?: Maybe<Scalars['String']>;
  manual_driver_last_name?: Maybe<Scalars['String']>;
  manual_patient_first_name?: Maybe<Scalars['String']>;
  manual_patient_last_name?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  service?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** Ordering options when selecting data from "vw_admin_manual_reservation". */
export interface Vw_Admin_Manual_Reservation_Order_By {
  activity_log?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  departure_address?: Maybe<Order_By>;
  departure_lat?: Maybe<Order_By>;
  departure_lng?: Maybe<Order_By>;
  destination_address?: Maybe<Order_By>;
  destination_lat?: Maybe<Order_By>;
  destination_lng?: Maybe<Order_By>;
  distance_billable?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  driver_preferred_id?: Maybe<Order_By>;
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  extra_cost?: Maybe<Order_By>;
  has_preferred_driver?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_two_way?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  manual_driver_first_name?: Maybe<Order_By>;
  manual_driver_last_name?: Maybe<Order_By>;
  manual_patient_first_name?: Maybe<Order_By>;
  manual_patient_last_name?: Maybe<Order_By>;
  market_segment?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  preferred_driver_action?: Maybe<Order_By>;
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  reservee_metadata?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
  service?: Maybe<Order_By>;
  service_city?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** select columns of table "vw_admin_manual_reservation" */
export enum Vw_Admin_Manual_Reservation_Select_Column {
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  DepartureAddress = 'departure_address',
  /** column name */
  DepartureLat = 'departure_lat',
  /** column name */
  DepartureLng = 'departure_lng',
  /** column name */
  DestinationAddress = 'destination_address',
  /** column name */
  DestinationLat = 'destination_lat',
  /** column name */
  DestinationLng = 'destination_lng',
  /** column name */
  DistanceBillable = 'distance_billable',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverPreferredId = 'driver_preferred_id',
  /** column name */
  DurationBillable = 'duration_billable',
  /** column name */
  DurationEstimate = 'duration_estimate',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraCost = 'extra_cost',
  /** column name */
  HasPreferredDriver = 'has_preferred_driver',
  /** column name */
  Id = 'id',
  /** column name */
  IsTwoWay = 'is_two_way',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  ManualDriverFirstName = 'manual_driver_first_name',
  /** column name */
  ManualDriverLastName = 'manual_driver_last_name',
  /** column name */
  ManualPatientFirstName = 'manual_patient_first_name',
  /** column name */
  ManualPatientLastName = 'manual_patient_last_name',
  /** column name */
  MarketSegment = 'market_segment',
  /** column name */
  Note = 'note',
  /** column name */
  PreferredDriverAction = 'preferred_driver_action',
  /** column name */
  PriceBillable = 'price_billable',
  /** column name */
  PriceEstimate = 'price_estimate',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  ReserveeMetadata = 'reservee_metadata',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  Service = 'service',
  /** column name */
  ServiceCity = 'service_city',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export interface Vw_Admin_Manual_Reservation_Stddev_Fields {
  __typename?: 'vw_admin_manual_reservation_stddev_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Admin_Manual_Reservation_Stddev_Pop_Fields {
  __typename?: 'vw_admin_manual_reservation_stddev_pop_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Admin_Manual_Reservation_Stddev_Samp_Fields {
  __typename?: 'vw_admin_manual_reservation_stddev_samp_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_admin_manual_reservation" */
export interface Vw_Admin_Manual_Reservation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Manual_Reservation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Manual_Reservation_Stream_Cursor_Value_Input {
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  has_preferred_driver?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_two_way?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  manual_driver_first_name?: Maybe<Scalars['String']>;
  manual_driver_last_name?: Maybe<Scalars['String']>;
  manual_patient_first_name?: Maybe<Scalars['String']>;
  manual_patient_last_name?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  service?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate sum on columns */
export interface Vw_Admin_Manual_Reservation_Sum_Fields {
  __typename?: 'vw_admin_manual_reservation_sum_fields';
  distance_billable?: Maybe<Scalars['numeric']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  reservor_id?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface Vw_Admin_Manual_Reservation_Var_Pop_Fields {
  __typename?: 'vw_admin_manual_reservation_var_pop_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Admin_Manual_Reservation_Var_Samp_Fields {
  __typename?: 'vw_admin_manual_reservation_var_samp_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Admin_Manual_Reservation_Variance_Fields {
  __typename?: 'vw_admin_manual_reservation_variance_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_admin_manual_reservation_with_preferred_driver" */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver';
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  has_preferred_driver?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_two_way?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  /** An object relationship */
  manual_driver?: Maybe<Manual_Driver>;
  manual_driver_first_name?: Maybe<Scalars['String']>;
  manual_driver_last_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  manual_patient?: Maybe<Manual_Patient>;
  manual_patient_first_name?: Maybe<Scalars['String']>;
  manual_patient_last_name?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  service?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}


/** columns and relationships of "vw_admin_manual_reservation_with_preferred_driver" */
export type Vw_Admin_Manual_Reservation_With_Preferred_DriverActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_manual_reservation_with_preferred_driver" */
export type Vw_Admin_Manual_Reservation_With_Preferred_DriverReservee_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_admin_manual_reservation_with_preferred_driver" */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Aggregate {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_aggregate';
  aggregate?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver>;
}

/** aggregate fields of "vw_admin_manual_reservation_with_preferred_driver" */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Aggregate_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_aggregate_fields';
  avg?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Max_Fields>;
  min?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Min_Fields>;
  stddev?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Sum_Fields>;
  var_pop?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Var_Samp_Fields>;
  variance?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Variance_Fields>;
}


/** aggregate fields of "vw_admin_manual_reservation_with_preferred_driver" */
export type Vw_Admin_Manual_Reservation_With_Preferred_Driver_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Avg_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_avg_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_admin_manual_reservation_with_preferred_driver". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Manual_Reservation_With_Preferred_Driver_Bool_Exp>>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<String_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  departure_address?: Maybe<String_Comparison_Exp>;
  departure_lat?: Maybe<String_Comparison_Exp>;
  departure_lng?: Maybe<String_Comparison_Exp>;
  destination_address?: Maybe<String_Comparison_Exp>;
  destination_lat?: Maybe<String_Comparison_Exp>;
  destination_lng?: Maybe<String_Comparison_Exp>;
  distance_billable?: Maybe<Numeric_Comparison_Exp>;
  driver_id?: Maybe<Uuid_Comparison_Exp>;
  driver_preferred_id?: Maybe<Uuid_Comparison_Exp>;
  duration_billable?: Maybe<Numeric_Comparison_Exp>;
  duration_estimate?: Maybe<Numeric_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  ends_at?: Maybe<Timestamptz_Comparison_Exp>;
  extra_cost?: Maybe<Numeric_Comparison_Exp>;
  has_preferred_driver?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_two_way?: Maybe<Boolean_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  manual_driver?: Maybe<Manual_Driver_Bool_Exp>;
  manual_driver_first_name?: Maybe<String_Comparison_Exp>;
  manual_driver_last_name?: Maybe<String_Comparison_Exp>;
  manual_patient?: Maybe<Manual_Patient_Bool_Exp>;
  manual_patient_first_name?: Maybe<String_Comparison_Exp>;
  manual_patient_last_name?: Maybe<String_Comparison_Exp>;
  market_segment?: Maybe<String_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  preferred_driver_action?: Maybe<String_Comparison_Exp>;
  price_billable?: Maybe<Numeric_Comparison_Exp>;
  price_estimate?: Maybe<Numeric_Comparison_Exp>;
  project_name?: Maybe<String_Comparison_Exp>;
  reservee_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservor_id?: Maybe<Bigint_Comparison_Exp>;
  service?: Maybe<String_Comparison_Exp>;
  service_city?: Maybe<String_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  starts_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Max_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  manual_driver_first_name?: Maybe<Scalars['String']>;
  manual_driver_last_name?: Maybe<Scalars['String']>;
  manual_patient_first_name?: Maybe<Scalars['String']>;
  manual_patient_last_name?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  service?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Min_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  manual_driver_first_name?: Maybe<Scalars['String']>;
  manual_driver_last_name?: Maybe<Scalars['String']>;
  manual_patient_first_name?: Maybe<Scalars['String']>;
  manual_patient_last_name?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  service?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** Ordering options when selecting data from "vw_admin_manual_reservation_with_preferred_driver". */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Order_By {
  activity_log?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  departure_address?: Maybe<Order_By>;
  departure_lat?: Maybe<Order_By>;
  departure_lng?: Maybe<Order_By>;
  destination_address?: Maybe<Order_By>;
  destination_lat?: Maybe<Order_By>;
  destination_lng?: Maybe<Order_By>;
  distance_billable?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  driver_preferred_id?: Maybe<Order_By>;
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  extra_cost?: Maybe<Order_By>;
  has_preferred_driver?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_two_way?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  manual_driver?: Maybe<Manual_Driver_Order_By>;
  manual_driver_first_name?: Maybe<Order_By>;
  manual_driver_last_name?: Maybe<Order_By>;
  manual_patient?: Maybe<Manual_Patient_Order_By>;
  manual_patient_first_name?: Maybe<Order_By>;
  manual_patient_last_name?: Maybe<Order_By>;
  market_segment?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  preferred_driver_action?: Maybe<Order_By>;
  price_billable?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  reservee_metadata?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
  service?: Maybe<Order_By>;
  service_city?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
}

/** select columns of table "vw_admin_manual_reservation_with_preferred_driver" */
export enum Vw_Admin_Manual_Reservation_With_Preferred_Driver_Select_Column {
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Currency = 'currency',
  /** column name */
  DepartureAddress = 'departure_address',
  /** column name */
  DepartureLat = 'departure_lat',
  /** column name */
  DepartureLng = 'departure_lng',
  /** column name */
  DestinationAddress = 'destination_address',
  /** column name */
  DestinationLat = 'destination_lat',
  /** column name */
  DestinationLng = 'destination_lng',
  /** column name */
  DistanceBillable = 'distance_billable',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverPreferredId = 'driver_preferred_id',
  /** column name */
  DurationBillable = 'duration_billable',
  /** column name */
  DurationEstimate = 'duration_estimate',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraCost = 'extra_cost',
  /** column name */
  HasPreferredDriver = 'has_preferred_driver',
  /** column name */
  Id = 'id',
  /** column name */
  IsTwoWay = 'is_two_way',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  ManualDriverFirstName = 'manual_driver_first_name',
  /** column name */
  ManualDriverLastName = 'manual_driver_last_name',
  /** column name */
  ManualPatientFirstName = 'manual_patient_first_name',
  /** column name */
  ManualPatientLastName = 'manual_patient_last_name',
  /** column name */
  MarketSegment = 'market_segment',
  /** column name */
  Note = 'note',
  /** column name */
  PreferredDriverAction = 'preferred_driver_action',
  /** column name */
  PriceBillable = 'price_billable',
  /** column name */
  PriceEstimate = 'price_estimate',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  ReserveeMetadata = 'reservee_metadata',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  Service = 'service',
  /** column name */
  ServiceCity = 'service_city',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stddev_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_stddev_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stddev_Pop_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_stddev_pop_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stddev_Samp_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_stddev_samp_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_admin_manual_reservation_with_preferred_driver" */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Stream_Cursor_Value_Input {
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  departure_address?: Maybe<Scalars['String']>;
  departure_lat?: Maybe<Scalars['String']>;
  departure_lng?: Maybe<Scalars['String']>;
  destination_address?: Maybe<Scalars['String']>;
  destination_lat?: Maybe<Scalars['String']>;
  destination_lng?: Maybe<Scalars['String']>;
  distance_billable?: Maybe<Scalars['numeric']>;
  driver_id?: Maybe<Scalars['uuid']>;
  driver_preferred_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  has_preferred_driver?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_two_way?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  manual_driver_first_name?: Maybe<Scalars['String']>;
  manual_driver_last_name?: Maybe<Scalars['String']>;
  manual_patient_first_name?: Maybe<Scalars['String']>;
  manual_patient_last_name?: Maybe<Scalars['String']>;
  market_segment?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  preferred_driver_action?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  project_name?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_id?: Maybe<Scalars['bigint']>;
  service?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
}

/** aggregate sum on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Sum_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_sum_fields';
  distance_billable?: Maybe<Scalars['numeric']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  extra_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  reservor_id?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Var_Pop_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_var_pop_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Var_Samp_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_var_samp_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Admin_Manual_Reservation_With_Preferred_Driver_Variance_Fields {
  __typename?: 'vw_admin_manual_reservation_with_preferred_driver_variance_fields';
  distance_billable?: Maybe<Scalars['Float']>;
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  reservor_id?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_admin_patient_champions" */
export interface Vw_Admin_Patient_Champions {
  __typename?: 'vw_admin_patient_champions';
  email?: Maybe<Scalars['String']>;
  percent_rank?: Maybe<Scalars['float8']>;
  reservor_id?: Maybe<Scalars['String']>;
  total_reservations?: Maybe<Scalars['bigint']>;
}

/** aggregated selection of "vw_admin_patient_champions" */
export interface Vw_Admin_Patient_Champions_Aggregate {
  __typename?: 'vw_admin_patient_champions_aggregate';
  aggregate?: Maybe<Vw_Admin_Patient_Champions_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Patient_Champions>;
}

/** aggregate fields of "vw_admin_patient_champions" */
export interface Vw_Admin_Patient_Champions_Aggregate_Fields {
  __typename?: 'vw_admin_patient_champions_aggregate_fields';
  avg?: Maybe<Vw_Admin_Patient_Champions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Patient_Champions_Max_Fields>;
  min?: Maybe<Vw_Admin_Patient_Champions_Min_Fields>;
  stddev?: Maybe<Vw_Admin_Patient_Champions_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Admin_Patient_Champions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Admin_Patient_Champions_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Admin_Patient_Champions_Sum_Fields>;
  var_pop?: Maybe<Vw_Admin_Patient_Champions_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Admin_Patient_Champions_Var_Samp_Fields>;
  variance?: Maybe<Vw_Admin_Patient_Champions_Variance_Fields>;
}


/** aggregate fields of "vw_admin_patient_champions" */
export type Vw_Admin_Patient_Champions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Patient_Champions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Admin_Patient_Champions_Avg_Fields {
  __typename?: 'vw_admin_patient_champions_avg_fields';
  percent_rank?: Maybe<Scalars['Float']>;
  total_reservations?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_admin_patient_champions". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Patient_Champions_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Patient_Champions_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Patient_Champions_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Patient_Champions_Bool_Exp>>;
  email?: Maybe<String_Comparison_Exp>;
  percent_rank?: Maybe<Float8_Comparison_Exp>;
  reservor_id?: Maybe<String_Comparison_Exp>;
  total_reservations?: Maybe<Bigint_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Admin_Patient_Champions_Max_Fields {
  __typename?: 'vw_admin_patient_champions_max_fields';
  email?: Maybe<Scalars['String']>;
  percent_rank?: Maybe<Scalars['float8']>;
  reservor_id?: Maybe<Scalars['String']>;
  total_reservations?: Maybe<Scalars['bigint']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Patient_Champions_Min_Fields {
  __typename?: 'vw_admin_patient_champions_min_fields';
  email?: Maybe<Scalars['String']>;
  percent_rank?: Maybe<Scalars['float8']>;
  reservor_id?: Maybe<Scalars['String']>;
  total_reservations?: Maybe<Scalars['bigint']>;
}

/** Ordering options when selecting data from "vw_admin_patient_champions". */
export interface Vw_Admin_Patient_Champions_Order_By {
  email?: Maybe<Order_By>;
  percent_rank?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
  total_reservations?: Maybe<Order_By>;
}

/** select columns of table "vw_admin_patient_champions" */
export enum Vw_Admin_Patient_Champions_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  PercentRank = 'percent_rank',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  TotalReservations = 'total_reservations'
}

/** aggregate stddev on columns */
export interface Vw_Admin_Patient_Champions_Stddev_Fields {
  __typename?: 'vw_admin_patient_champions_stddev_fields';
  percent_rank?: Maybe<Scalars['Float']>;
  total_reservations?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Admin_Patient_Champions_Stddev_Pop_Fields {
  __typename?: 'vw_admin_patient_champions_stddev_pop_fields';
  percent_rank?: Maybe<Scalars['Float']>;
  total_reservations?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Admin_Patient_Champions_Stddev_Samp_Fields {
  __typename?: 'vw_admin_patient_champions_stddev_samp_fields';
  percent_rank?: Maybe<Scalars['Float']>;
  total_reservations?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_admin_patient_champions" */
export interface Vw_Admin_Patient_Champions_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Patient_Champions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Patient_Champions_Stream_Cursor_Value_Input {
  email?: Maybe<Scalars['String']>;
  percent_rank?: Maybe<Scalars['float8']>;
  reservor_id?: Maybe<Scalars['String']>;
  total_reservations?: Maybe<Scalars['bigint']>;
}

/** aggregate sum on columns */
export interface Vw_Admin_Patient_Champions_Sum_Fields {
  __typename?: 'vw_admin_patient_champions_sum_fields';
  percent_rank?: Maybe<Scalars['float8']>;
  total_reservations?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface Vw_Admin_Patient_Champions_Var_Pop_Fields {
  __typename?: 'vw_admin_patient_champions_var_pop_fields';
  percent_rank?: Maybe<Scalars['Float']>;
  total_reservations?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Admin_Patient_Champions_Var_Samp_Fields {
  __typename?: 'vw_admin_patient_champions_var_samp_fields';
  percent_rank?: Maybe<Scalars['Float']>;
  total_reservations?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Admin_Patient_Champions_Variance_Fields {
  __typename?: 'vw_admin_patient_champions_variance_fields';
  percent_rank?: Maybe<Scalars['Float']>;
  total_reservations?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_admin_reservation" */
export interface Vw_Admin_Reservation {
  __typename?: 'vw_admin_reservation';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}


/** columns and relationships of "vw_admin_reservation" */
export type Vw_Admin_ReservationActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_reservation" */
export type Vw_Admin_ReservationDriver_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_reservation" */
export type Vw_Admin_ReservationHistory_SnapshotArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_reservation" */
export type Vw_Admin_ReservationMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_reservation" */
export type Vw_Admin_ReservationReservee_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_admin_reservation" */
export type Vw_Admin_ReservationReservor_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_admin_reservation" */
export interface Vw_Admin_Reservation_Aggregate {
  __typename?: 'vw_admin_reservation_aggregate';
  aggregate?: Maybe<Vw_Admin_Reservation_Aggregate_Fields>;
  nodes: Array<Vw_Admin_Reservation>;
}

/** aggregate fields of "vw_admin_reservation" */
export interface Vw_Admin_Reservation_Aggregate_Fields {
  __typename?: 'vw_admin_reservation_aggregate_fields';
  avg?: Maybe<Vw_Admin_Reservation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Admin_Reservation_Max_Fields>;
  min?: Maybe<Vw_Admin_Reservation_Min_Fields>;
  stddev?: Maybe<Vw_Admin_Reservation_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Admin_Reservation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Admin_Reservation_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Admin_Reservation_Sum_Fields>;
  var_pop?: Maybe<Vw_Admin_Reservation_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Admin_Reservation_Var_Samp_Fields>;
  variance?: Maybe<Vw_Admin_Reservation_Variance_Fields>;
}


/** aggregate fields of "vw_admin_reservation" */
export type Vw_Admin_Reservation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Admin_Reservation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Vw_Admin_Reservation_Append_Input {
  activity_log?: Maybe<Scalars['jsonb']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
}

/** aggregate avg on columns */
export interface Vw_Admin_Reservation_Avg_Fields {
  __typename?: 'vw_admin_reservation_avg_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_admin_reservation". All fields are combined with a logical 'AND'. */
export interface Vw_Admin_Reservation_Bool_Exp {
  _and?: Maybe<Array<Vw_Admin_Reservation_Bool_Exp>>;
  _not?: Maybe<Vw_Admin_Reservation_Bool_Exp>;
  _or?: Maybe<Array<Vw_Admin_Reservation_Bool_Exp>>;
  accepted_at?: Maybe<Timestamptz_Comparison_Exp>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  driver_id?: Maybe<String_Comparison_Exp>;
  driver_metadata?: Maybe<Jsonb_Comparison_Exp>;
  duplication_reference_id?: Maybe<Uuid_Comparison_Exp>;
  duration_billable?: Maybe<Numeric_Comparison_Exp>;
  duration_estimate?: Maybe<Numeric_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  ends_at?: Maybe<Timestamptz_Comparison_Exp>;
  extra_costs_text?: Maybe<String_Comparison_Exp>;
  extra_costs_value?: Maybe<Numeric_Comparison_Exp>;
  history_snapshot?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  is_provided_in_city?: Maybe<Boolean_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  preferred_driver?: Maybe<String_Comparison_Exp>;
  price_billable?: Maybe<Numeric_Comparison_Exp>;
  price_billable_discounted?: Maybe<Numeric_Comparison_Exp>;
  price_estimate?: Maybe<Numeric_Comparison_Exp>;
  price_estimate_discounted?: Maybe<Numeric_Comparison_Exp>;
  reservee_id?: Maybe<String_Comparison_Exp>;
  reservee_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservee_role?: Maybe<String_Comparison_Exp>;
  reservor_id?: Maybe<String_Comparison_Exp>;
  reservor_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservor_role?: Maybe<String_Comparison_Exp>;
  service_city?: Maybe<String_Comparison_Exp>;
  service_id?: Maybe<String_Comparison_Exp>;
  service_type?: Maybe<String_Comparison_Exp>;
  short_id?: Maybe<String_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  starts_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  voucher_code?: Maybe<String_Comparison_Exp>;
  voucher_not_applicable_reason?: Maybe<String_Comparison_Exp>;
  voucher_redemption_id?: Maybe<String_Comparison_Exp>;
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Vw_Admin_Reservation_Delete_At_Path_Input {
  activity_log?: Maybe<Array<Scalars['String']>>;
  driver_metadata?: Maybe<Array<Scalars['String']>>;
  history_snapshot?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<Scalars['String']>>;
  reservee_metadata?: Maybe<Array<Scalars['String']>>;
  reservor_metadata?: Maybe<Array<Scalars['String']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Vw_Admin_Reservation_Delete_Elem_Input {
  activity_log?: Maybe<Scalars['Int']>;
  driver_metadata?: Maybe<Scalars['Int']>;
  history_snapshot?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['Int']>;
  reservee_metadata?: Maybe<Scalars['Int']>;
  reservor_metadata?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Vw_Admin_Reservation_Delete_Key_Input {
  activity_log?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['String']>;
  history_snapshot?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['String']>;
}

/** input type for incrementing numeric columns in table "vw_admin_reservation" */
export interface Vw_Admin_Reservation_Inc_Input {
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
}

/** input type for inserting data into table "vw_admin_reservation" */
export interface Vw_Admin_Reservation_Insert_Input {
  accepted_at?: Maybe<Scalars['timestamptz']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface Vw_Admin_Reservation_Max_Fields {
  __typename?: 'vw_admin_reservation_max_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Vw_Admin_Reservation_Min_Fields {
  __typename?: 'vw_admin_reservation_min_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** response of any mutation on the table "vw_admin_reservation" */
export interface Vw_Admin_Reservation_Mutation_Response {
  __typename?: 'vw_admin_reservation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vw_Admin_Reservation>;
}

/** Ordering options when selecting data from "vw_admin_reservation". */
export interface Vw_Admin_Reservation_Order_By {
  accepted_at?: Maybe<Order_By>;
  activity_log?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  driver_metadata?: Maybe<Order_By>;
  duplication_reference_id?: Maybe<Order_By>;
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  extra_costs_text?: Maybe<Order_By>;
  extra_costs_value?: Maybe<Order_By>;
  history_snapshot?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_provided_in_city?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  preferred_driver?: Maybe<Order_By>;
  price_billable?: Maybe<Order_By>;
  price_billable_discounted?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  price_estimate_discounted?: Maybe<Order_By>;
  reservee_id?: Maybe<Order_By>;
  reservee_metadata?: Maybe<Order_By>;
  reservee_role?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
  reservor_metadata?: Maybe<Order_By>;
  reservor_role?: Maybe<Order_By>;
  service_city?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  service_type?: Maybe<Order_By>;
  short_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  voucher_code?: Maybe<Order_By>;
  voucher_not_applicable_reason?: Maybe<Order_By>;
  voucher_redemption_id?: Maybe<Order_By>;
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Vw_Admin_Reservation_Prepend_Input {
  activity_log?: Maybe<Scalars['jsonb']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  metadata?: Maybe<Scalars['jsonb']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "vw_admin_reservation" */
export enum Vw_Admin_Reservation_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverMetadata = 'driver_metadata',
  /** column name */
  DuplicationReferenceId = 'duplication_reference_id',
  /** column name */
  DurationBillable = 'duration_billable',
  /** column name */
  DurationEstimate = 'duration_estimate',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraCostsText = 'extra_costs_text',
  /** column name */
  ExtraCostsValue = 'extra_costs_value',
  /** column name */
  HistorySnapshot = 'history_snapshot',
  /** column name */
  Id = 'id',
  /** column name */
  IsProvidedInCity = 'is_provided_in_city',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PreferredDriver = 'preferred_driver',
  /** column name */
  PriceBillable = 'price_billable',
  /** column name */
  PriceBillableDiscounted = 'price_billable_discounted',
  /** column name */
  PriceEstimate = 'price_estimate',
  /** column name */
  PriceEstimateDiscounted = 'price_estimate_discounted',
  /** column name */
  ReserveeId = 'reservee_id',
  /** column name */
  ReserveeMetadata = 'reservee_metadata',
  /** column name */
  ReserveeRole = 'reservee_role',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  ReservorMetadata = 'reservor_metadata',
  /** column name */
  ReservorRole = 'reservor_role',
  /** column name */
  ServiceCity = 'service_city',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherCode = 'voucher_code',
  /** column name */
  VoucherNotApplicableReason = 'voucher_not_applicable_reason',
  /** column name */
  VoucherRedemptionId = 'voucher_redemption_id'
}

/** input type for updating data in table "vw_admin_reservation" */
export interface Vw_Admin_Reservation_Set_Input {
  accepted_at?: Maybe<Scalars['timestamptz']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate stddev on columns */
export interface Vw_Admin_Reservation_Stddev_Fields {
  __typename?: 'vw_admin_reservation_stddev_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Admin_Reservation_Stddev_Pop_Fields {
  __typename?: 'vw_admin_reservation_stddev_pop_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Admin_Reservation_Stddev_Samp_Fields {
  __typename?: 'vw_admin_reservation_stddev_samp_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_admin_reservation" */
export interface Vw_Admin_Reservation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Admin_Reservation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Admin_Reservation_Stream_Cursor_Value_Input {
  accepted_at?: Maybe<Scalars['timestamptz']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  history_snapshot?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Vw_Admin_Reservation_Sum_Fields {
  __typename?: 'vw_admin_reservation_sum_fields';
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
}

export interface Vw_Admin_Reservation_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Vw_Admin_Reservation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Vw_Admin_Reservation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Vw_Admin_Reservation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Vw_Admin_Reservation_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Vw_Admin_Reservation_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Vw_Admin_Reservation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Vw_Admin_Reservation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vw_Admin_Reservation_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Vw_Admin_Reservation_Var_Pop_Fields {
  __typename?: 'vw_admin_reservation_var_pop_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Admin_Reservation_Var_Samp_Fields {
  __typename?: 'vw_admin_reservation_var_samp_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Admin_Reservation_Variance_Fields {
  __typename?: 'vw_admin_reservation_variance_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_stats_auth_provider_data" */
export interface Vw_Stats_Auth_Provider_Data {
  __typename?: 'vw_stats_auth_provider_data';
  day?: Maybe<Scalars['timestamptz']>;
  facebook?: Maybe<Scalars['bigint']>;
  facebook_converted?: Maybe<Scalars['bigint']>;
  google?: Maybe<Scalars['bigint']>;
  google_converted?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['bigint']>;
  password_converted?: Maybe<Scalars['bigint']>;
}

/** aggregated selection of "vw_stats_auth_provider_data" */
export interface Vw_Stats_Auth_Provider_Data_Aggregate {
  __typename?: 'vw_stats_auth_provider_data_aggregate';
  aggregate?: Maybe<Vw_Stats_Auth_Provider_Data_Aggregate_Fields>;
  nodes: Array<Vw_Stats_Auth_Provider_Data>;
}

/** aggregate fields of "vw_stats_auth_provider_data" */
export interface Vw_Stats_Auth_Provider_Data_Aggregate_Fields {
  __typename?: 'vw_stats_auth_provider_data_aggregate_fields';
  avg?: Maybe<Vw_Stats_Auth_Provider_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Stats_Auth_Provider_Data_Max_Fields>;
  min?: Maybe<Vw_Stats_Auth_Provider_Data_Min_Fields>;
  stddev?: Maybe<Vw_Stats_Auth_Provider_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Stats_Auth_Provider_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Stats_Auth_Provider_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Stats_Auth_Provider_Data_Sum_Fields>;
  var_pop?: Maybe<Vw_Stats_Auth_Provider_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Stats_Auth_Provider_Data_Var_Samp_Fields>;
  variance?: Maybe<Vw_Stats_Auth_Provider_Data_Variance_Fields>;
}


/** aggregate fields of "vw_stats_auth_provider_data" */
export type Vw_Stats_Auth_Provider_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Stats_Auth_Provider_Data_Avg_Fields {
  __typename?: 'vw_stats_auth_provider_data_avg_fields';
  facebook?: Maybe<Scalars['Float']>;
  facebook_converted?: Maybe<Scalars['Float']>;
  google?: Maybe<Scalars['Float']>;
  google_converted?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['Float']>;
  password_converted?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_stats_auth_provider_data". All fields are combined with a logical 'AND'. */
export interface Vw_Stats_Auth_Provider_Data_Bool_Exp {
  _and?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Bool_Exp>>;
  _not?: Maybe<Vw_Stats_Auth_Provider_Data_Bool_Exp>;
  _or?: Maybe<Array<Vw_Stats_Auth_Provider_Data_Bool_Exp>>;
  day?: Maybe<Timestamptz_Comparison_Exp>;
  facebook?: Maybe<Bigint_Comparison_Exp>;
  facebook_converted?: Maybe<Bigint_Comparison_Exp>;
  google?: Maybe<Bigint_Comparison_Exp>;
  google_converted?: Maybe<Bigint_Comparison_Exp>;
  password?: Maybe<Bigint_Comparison_Exp>;
  password_converted?: Maybe<Bigint_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Stats_Auth_Provider_Data_Max_Fields {
  __typename?: 'vw_stats_auth_provider_data_max_fields';
  day?: Maybe<Scalars['timestamptz']>;
  facebook?: Maybe<Scalars['bigint']>;
  facebook_converted?: Maybe<Scalars['bigint']>;
  google?: Maybe<Scalars['bigint']>;
  google_converted?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['bigint']>;
  password_converted?: Maybe<Scalars['bigint']>;
}

/** aggregate min on columns */
export interface Vw_Stats_Auth_Provider_Data_Min_Fields {
  __typename?: 'vw_stats_auth_provider_data_min_fields';
  day?: Maybe<Scalars['timestamptz']>;
  facebook?: Maybe<Scalars['bigint']>;
  facebook_converted?: Maybe<Scalars['bigint']>;
  google?: Maybe<Scalars['bigint']>;
  google_converted?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['bigint']>;
  password_converted?: Maybe<Scalars['bigint']>;
}

/** Ordering options when selecting data from "vw_stats_auth_provider_data". */
export interface Vw_Stats_Auth_Provider_Data_Order_By {
  day?: Maybe<Order_By>;
  facebook?: Maybe<Order_By>;
  facebook_converted?: Maybe<Order_By>;
  google?: Maybe<Order_By>;
  google_converted?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  password_converted?: Maybe<Order_By>;
}

/** select columns of table "vw_stats_auth_provider_data" */
export enum Vw_Stats_Auth_Provider_Data_Select_Column {
  /** column name */
  Day = 'day',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  FacebookConverted = 'facebook_converted',
  /** column name */
  Google = 'google',
  /** column name */
  GoogleConverted = 'google_converted',
  /** column name */
  Password = 'password',
  /** column name */
  PasswordConverted = 'password_converted'
}

/** aggregate stddev on columns */
export interface Vw_Stats_Auth_Provider_Data_Stddev_Fields {
  __typename?: 'vw_stats_auth_provider_data_stddev_fields';
  facebook?: Maybe<Scalars['Float']>;
  facebook_converted?: Maybe<Scalars['Float']>;
  google?: Maybe<Scalars['Float']>;
  google_converted?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['Float']>;
  password_converted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Stats_Auth_Provider_Data_Stddev_Pop_Fields {
  __typename?: 'vw_stats_auth_provider_data_stddev_pop_fields';
  facebook?: Maybe<Scalars['Float']>;
  facebook_converted?: Maybe<Scalars['Float']>;
  google?: Maybe<Scalars['Float']>;
  google_converted?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['Float']>;
  password_converted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Stats_Auth_Provider_Data_Stddev_Samp_Fields {
  __typename?: 'vw_stats_auth_provider_data_stddev_samp_fields';
  facebook?: Maybe<Scalars['Float']>;
  facebook_converted?: Maybe<Scalars['Float']>;
  google?: Maybe<Scalars['Float']>;
  google_converted?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['Float']>;
  password_converted?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_stats_auth_provider_data" */
export interface Vw_Stats_Auth_Provider_Data_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Stats_Auth_Provider_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Stats_Auth_Provider_Data_Stream_Cursor_Value_Input {
  day?: Maybe<Scalars['timestamptz']>;
  facebook?: Maybe<Scalars['bigint']>;
  facebook_converted?: Maybe<Scalars['bigint']>;
  google?: Maybe<Scalars['bigint']>;
  google_converted?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['bigint']>;
  password_converted?: Maybe<Scalars['bigint']>;
}

/** aggregate sum on columns */
export interface Vw_Stats_Auth_Provider_Data_Sum_Fields {
  __typename?: 'vw_stats_auth_provider_data_sum_fields';
  facebook?: Maybe<Scalars['bigint']>;
  facebook_converted?: Maybe<Scalars['bigint']>;
  google?: Maybe<Scalars['bigint']>;
  google_converted?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['bigint']>;
  password_converted?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface Vw_Stats_Auth_Provider_Data_Var_Pop_Fields {
  __typename?: 'vw_stats_auth_provider_data_var_pop_fields';
  facebook?: Maybe<Scalars['Float']>;
  facebook_converted?: Maybe<Scalars['Float']>;
  google?: Maybe<Scalars['Float']>;
  google_converted?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['Float']>;
  password_converted?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Stats_Auth_Provider_Data_Var_Samp_Fields {
  __typename?: 'vw_stats_auth_provider_data_var_samp_fields';
  facebook?: Maybe<Scalars['Float']>;
  facebook_converted?: Maybe<Scalars['Float']>;
  google?: Maybe<Scalars['Float']>;
  google_converted?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['Float']>;
  password_converted?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Stats_Auth_Provider_Data_Variance_Fields {
  __typename?: 'vw_stats_auth_provider_data_variance_fields';
  facebook?: Maybe<Scalars['Float']>;
  facebook_converted?: Maybe<Scalars['Float']>;
  google?: Maybe<Scalars['Float']>;
  google_converted?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['Float']>;
  password_converted?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_stats_daily_conversions_by_auth_provider" */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider';
  accounts_converted?: Maybe<Scalars['bigint']>;
  auth_provider?: Maybe<Scalars['String']>;
  converted_at?: Maybe<Scalars['timestamp']>;
}

/** aggregated selection of "vw_stats_daily_conversions_by_auth_provider" */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Aggregate {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_aggregate';
  aggregate?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Aggregate_Fields>;
  nodes: Array<Vw_Stats_Daily_Conversions_By_Auth_Provider>;
}

/** aggregate fields of "vw_stats_daily_conversions_by_auth_provider" */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Aggregate_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_aggregate_fields';
  avg?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Max_Fields>;
  min?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Min_Fields>;
  stddev?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Sum_Fields>;
  var_pop?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Var_Samp_Fields>;
  variance?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Variance_Fields>;
}


/** aggregate fields of "vw_stats_daily_conversions_by_auth_provider" */
export type Vw_Stats_Daily_Conversions_By_Auth_Provider_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Avg_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_avg_fields';
  accounts_converted?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_stats_daily_conversions_by_auth_provider". All fields are combined with a logical 'AND'. */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp {
  _and?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp>>;
  _not?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp>;
  _or?: Maybe<Array<Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp>>;
  accounts_converted?: Maybe<Bigint_Comparison_Exp>;
  auth_provider?: Maybe<String_Comparison_Exp>;
  converted_at?: Maybe<Timestamp_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Max_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_max_fields';
  accounts_converted?: Maybe<Scalars['bigint']>;
  auth_provider?: Maybe<Scalars['String']>;
  converted_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate min on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Min_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_min_fields';
  accounts_converted?: Maybe<Scalars['bigint']>;
  auth_provider?: Maybe<Scalars['String']>;
  converted_at?: Maybe<Scalars['timestamp']>;
}

/** Ordering options when selecting data from "vw_stats_daily_conversions_by_auth_provider". */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Order_By {
  accounts_converted?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  converted_at?: Maybe<Order_By>;
}

/** select columns of table "vw_stats_daily_conversions_by_auth_provider" */
export enum Vw_Stats_Daily_Conversions_By_Auth_Provider_Select_Column {
  /** column name */
  AccountsConverted = 'accounts_converted',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  ConvertedAt = 'converted_at'
}

/** aggregate stddev on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Stddev_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_stddev_fields';
  accounts_converted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Stddev_Pop_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_stddev_pop_fields';
  accounts_converted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Stddev_Samp_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_stddev_samp_fields';
  accounts_converted?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_stats_daily_conversions_by_auth_provider" */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Stats_Daily_Conversions_By_Auth_Provider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Stream_Cursor_Value_Input {
  accounts_converted?: Maybe<Scalars['bigint']>;
  auth_provider?: Maybe<Scalars['String']>;
  converted_at?: Maybe<Scalars['timestamp']>;
}

/** aggregate sum on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Sum_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_sum_fields';
  accounts_converted?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Var_Pop_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_var_pop_fields';
  accounts_converted?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Var_Samp_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_var_samp_fields';
  accounts_converted?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Stats_Daily_Conversions_By_Auth_Provider_Variance_Fields {
  __typename?: 'vw_stats_daily_conversions_by_auth_provider_variance_fields';
  accounts_converted?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_stats_patient_conversion_reservation" */
export interface Vw_Stats_Patient_Conversion_Reservation {
  __typename?: 'vw_stats_patient_conversion_reservation';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['String']>;
  is_champion_patient?: Maybe<Scalars['Boolean']>;
  is_manual?: Maybe<Scalars['Boolean']>;
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservation_metadata?: Maybe<Scalars['jsonb']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  traffic_source?: Maybe<Scalars['String']>;
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  traffic_source_other_text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}


/** columns and relationships of "vw_stats_patient_conversion_reservation" */
export type Vw_Stats_Patient_Conversion_ReservationActivity_LogArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_stats_patient_conversion_reservation" */
export type Vw_Stats_Patient_Conversion_ReservationDriver_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_stats_patient_conversion_reservation" */
export type Vw_Stats_Patient_Conversion_ReservationMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_stats_patient_conversion_reservation" */
export type Vw_Stats_Patient_Conversion_ReservationReservation_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_stats_patient_conversion_reservation" */
export type Vw_Stats_Patient_Conversion_ReservationReservee_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_stats_patient_conversion_reservation" */
export type Vw_Stats_Patient_Conversion_ReservationReservor_MetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_stats_patient_conversion_reservation" */
export interface Vw_Stats_Patient_Conversion_Reservation_Aggregate {
  __typename?: 'vw_stats_patient_conversion_reservation_aggregate';
  aggregate?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Aggregate_Fields>;
  nodes: Array<Vw_Stats_Patient_Conversion_Reservation>;
}

/** aggregate fields of "vw_stats_patient_conversion_reservation" */
export interface Vw_Stats_Patient_Conversion_Reservation_Aggregate_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_aggregate_fields';
  avg?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Max_Fields>;
  min?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Min_Fields>;
  stddev?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Sum_Fields>;
  var_pop?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Var_Samp_Fields>;
  variance?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Variance_Fields>;
}


/** aggregate fields of "vw_stats_patient_conversion_reservation" */
export type Vw_Stats_Patient_Conversion_Reservation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Avg_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_avg_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_stats_patient_conversion_reservation". All fields are combined with a logical 'AND'. */
export interface Vw_Stats_Patient_Conversion_Reservation_Bool_Exp {
  _and?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Bool_Exp>>;
  _not?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Bool_Exp>;
  _or?: Maybe<Array<Vw_Stats_Patient_Conversion_Reservation_Bool_Exp>>;
  accepted_at?: Maybe<Timestamptz_Comparison_Exp>;
  activity_log?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  driver_id?: Maybe<String_Comparison_Exp>;
  driver_metadata?: Maybe<Jsonb_Comparison_Exp>;
  duplication_reference_id?: Maybe<Uuid_Comparison_Exp>;
  duration_billable?: Maybe<Numeric_Comparison_Exp>;
  duration_estimate?: Maybe<Numeric_Comparison_Exp>;
  ended_at?: Maybe<Timestamptz_Comparison_Exp>;
  ends_at?: Maybe<Timestamptz_Comparison_Exp>;
  extra_costs_text?: Maybe<String_Comparison_Exp>;
  extra_costs_value?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  is_champion_patient?: Maybe<Boolean_Comparison_Exp>;
  is_manual?: Maybe<Boolean_Comparison_Exp>;
  is_provided_in_city?: Maybe<Boolean_Comparison_Exp>;
  latest_graphql_operation?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  preferred_driver?: Maybe<String_Comparison_Exp>;
  price_billable?: Maybe<Numeric_Comparison_Exp>;
  price_billable_discounted?: Maybe<Numeric_Comparison_Exp>;
  price_estimate?: Maybe<Numeric_Comparison_Exp>;
  price_estimate_discounted?: Maybe<Numeric_Comparison_Exp>;
  reservation_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservee_id?: Maybe<String_Comparison_Exp>;
  reservee_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservee_role?: Maybe<String_Comparison_Exp>;
  reservor_id?: Maybe<String_Comparison_Exp>;
  reservor_metadata?: Maybe<Jsonb_Comparison_Exp>;
  reservor_role?: Maybe<String_Comparison_Exp>;
  service_city?: Maybe<String_Comparison_Exp>;
  service_id?: Maybe<String_Comparison_Exp>;
  service_type?: Maybe<String_Comparison_Exp>;
  short_id?: Maybe<String_Comparison_Exp>;
  started_at?: Maybe<Timestamptz_Comparison_Exp>;
  starts_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  traffic_source?: Maybe<String_Comparison_Exp>;
  traffic_source_affiliate_text?: Maybe<String_Comparison_Exp>;
  traffic_source_other_text?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  voucher_code?: Maybe<String_Comparison_Exp>;
  voucher_not_applicable_reason?: Maybe<String_Comparison_Exp>;
  voucher_redemption_id?: Maybe<String_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Max_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_max_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  traffic_source?: Maybe<Scalars['String']>;
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  traffic_source_other_text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Min_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_min_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['String']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  traffic_source?: Maybe<Scalars['String']>;
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  traffic_source_other_text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "vw_stats_patient_conversion_reservation". */
export interface Vw_Stats_Patient_Conversion_Reservation_Order_By {
  accepted_at?: Maybe<Order_By>;
  activity_log?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  driver_id?: Maybe<Order_By>;
  driver_metadata?: Maybe<Order_By>;
  duplication_reference_id?: Maybe<Order_By>;
  duration_billable?: Maybe<Order_By>;
  duration_estimate?: Maybe<Order_By>;
  ended_at?: Maybe<Order_By>;
  ends_at?: Maybe<Order_By>;
  extra_costs_text?: Maybe<Order_By>;
  extra_costs_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_champion_patient?: Maybe<Order_By>;
  is_manual?: Maybe<Order_By>;
  is_provided_in_city?: Maybe<Order_By>;
  latest_graphql_operation?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  preferred_driver?: Maybe<Order_By>;
  price_billable?: Maybe<Order_By>;
  price_billable_discounted?: Maybe<Order_By>;
  price_estimate?: Maybe<Order_By>;
  price_estimate_discounted?: Maybe<Order_By>;
  reservation_metadata?: Maybe<Order_By>;
  reservee_id?: Maybe<Order_By>;
  reservee_metadata?: Maybe<Order_By>;
  reservee_role?: Maybe<Order_By>;
  reservor_id?: Maybe<Order_By>;
  reservor_metadata?: Maybe<Order_By>;
  reservor_role?: Maybe<Order_By>;
  service_city?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  service_type?: Maybe<Order_By>;
  short_id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  starts_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  traffic_source?: Maybe<Order_By>;
  traffic_source_affiliate_text?: Maybe<Order_By>;
  traffic_source_other_text?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  voucher_code?: Maybe<Order_By>;
  voucher_not_applicable_reason?: Maybe<Order_By>;
  voucher_redemption_id?: Maybe<Order_By>;
}

/** select columns of table "vw_stats_patient_conversion_reservation" */
export enum Vw_Stats_Patient_Conversion_Reservation_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  ActivityLog = 'activity_log',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DriverId = 'driver_id',
  /** column name */
  DriverMetadata = 'driver_metadata',
  /** column name */
  DuplicationReferenceId = 'duplication_reference_id',
  /** column name */
  DurationBillable = 'duration_billable',
  /** column name */
  DurationEstimate = 'duration_estimate',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  ExtraCostsText = 'extra_costs_text',
  /** column name */
  ExtraCostsValue = 'extra_costs_value',
  /** column name */
  Id = 'id',
  /** column name */
  IsChampionPatient = 'is_champion_patient',
  /** column name */
  IsManual = 'is_manual',
  /** column name */
  IsProvidedInCity = 'is_provided_in_city',
  /** column name */
  LatestGraphqlOperation = 'latest_graphql_operation',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PreferredDriver = 'preferred_driver',
  /** column name */
  PriceBillable = 'price_billable',
  /** column name */
  PriceBillableDiscounted = 'price_billable_discounted',
  /** column name */
  PriceEstimate = 'price_estimate',
  /** column name */
  PriceEstimateDiscounted = 'price_estimate_discounted',
  /** column name */
  ReservationMetadata = 'reservation_metadata',
  /** column name */
  ReserveeId = 'reservee_id',
  /** column name */
  ReserveeMetadata = 'reservee_metadata',
  /** column name */
  ReserveeRole = 'reservee_role',
  /** column name */
  ReservorId = 'reservor_id',
  /** column name */
  ReservorMetadata = 'reservor_metadata',
  /** column name */
  ReservorRole = 'reservor_role',
  /** column name */
  ServiceCity = 'service_city',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  TrafficSource = 'traffic_source',
  /** column name */
  TrafficSourceAffiliateText = 'traffic_source_affiliate_text',
  /** column name */
  TrafficSourceOtherText = 'traffic_source_other_text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoucherCode = 'voucher_code',
  /** column name */
  VoucherNotApplicableReason = 'voucher_not_applicable_reason',
  /** column name */
  VoucherRedemptionId = 'voucher_redemption_id'
}

/** aggregate stddev on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Stddev_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_stddev_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Stddev_Pop_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_stddev_pop_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Stddev_Samp_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_stddev_samp_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_stats_patient_conversion_reservation" */
export interface Vw_Stats_Patient_Conversion_Reservation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Stats_Patient_Conversion_Reservation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Stats_Patient_Conversion_Reservation_Stream_Cursor_Value_Input {
  accepted_at?: Maybe<Scalars['timestamptz']>;
  activity_log?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['String']>;
  driver_metadata?: Maybe<Scalars['jsonb']>;
  duplication_reference_id?: Maybe<Scalars['uuid']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  ends_at?: Maybe<Scalars['timestamptz']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['String']>;
  is_champion_patient?: Maybe<Scalars['Boolean']>;
  is_manual?: Maybe<Scalars['Boolean']>;
  is_provided_in_city?: Maybe<Scalars['Boolean']>;
  latest_graphql_operation?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  preferred_driver?: Maybe<Scalars['String']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
  reservation_metadata?: Maybe<Scalars['jsonb']>;
  reservee_id?: Maybe<Scalars['String']>;
  reservee_metadata?: Maybe<Scalars['jsonb']>;
  reservee_role?: Maybe<Scalars['String']>;
  reservor_id?: Maybe<Scalars['String']>;
  reservor_metadata?: Maybe<Scalars['jsonb']>;
  reservor_role?: Maybe<Scalars['String']>;
  service_city?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  short_id?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  starts_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  traffic_source?: Maybe<Scalars['String']>;
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
  traffic_source_other_text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voucher_code?: Maybe<Scalars['String']>;
  voucher_not_applicable_reason?: Maybe<Scalars['String']>;
  voucher_redemption_id?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Sum_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_sum_fields';
  duration_billable?: Maybe<Scalars['numeric']>;
  duration_estimate?: Maybe<Scalars['numeric']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  price_billable?: Maybe<Scalars['numeric']>;
  price_billable_discounted?: Maybe<Scalars['numeric']>;
  price_estimate?: Maybe<Scalars['numeric']>;
  price_estimate_discounted?: Maybe<Scalars['numeric']>;
}

/** aggregate var_pop on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Var_Pop_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_var_pop_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Var_Samp_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_var_samp_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Stats_Patient_Conversion_Reservation_Variance_Fields {
  __typename?: 'vw_stats_patient_conversion_reservation_variance_fields';
  duration_billable?: Maybe<Scalars['Float']>;
  duration_estimate?: Maybe<Scalars['Float']>;
  extra_costs_value?: Maybe<Scalars['Float']>;
  price_billable?: Maybe<Scalars['Float']>;
  price_billable_discounted?: Maybe<Scalars['Float']>;
  price_estimate?: Maybe<Scalars['Float']>;
  price_estimate_discounted?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vw_stats_reservation_frequency_by_patient_accounts" */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts';
  account_id?: Maybe<Scalars['String']>;
  created_ats?: Maybe<Scalars['jsonb']>;
  days_since_first_reservation?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  prev_created_ats?: Maybe<Scalars['jsonb']>;
  reservation_count?: Maybe<Scalars['Int']>;
}


/** columns and relationships of "vw_stats_reservation_frequency_by_patient_accounts" */
export type Vw_Stats_Reservation_Frequency_By_Patient_AccountsCreated_AtsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_stats_reservation_frequency_by_patient_accounts" */
export type Vw_Stats_Reservation_Frequency_By_Patient_AccountsDays_Since_First_ReservationArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "vw_stats_reservation_frequency_by_patient_accounts" */
export type Vw_Stats_Reservation_Frequency_By_Patient_AccountsPrev_Created_AtsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_stats_reservation_frequency_by_patient_accounts" */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Aggregate {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_aggregate';
  aggregate?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Aggregate_Fields>;
  nodes: Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts>;
}

/** aggregate fields of "vw_stats_reservation_frequency_by_patient_accounts" */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Aggregate_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_aggregate_fields';
  avg?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Max_Fields>;
  min?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Min_Fields>;
  stddev?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Sum_Fields>;
  var_pop?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Var_Samp_Fields>;
  variance?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Variance_Fields>;
}


/** aggregate fields of "vw_stats_reservation_frequency_by_patient_accounts" */
export type Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Avg_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_avg_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vw_stats_reservation_frequency_by_patient_accounts". All fields are combined with a logical 'AND'. */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Bool_Exp {
  _and?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Bool_Exp>>;
  _not?: Maybe<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Bool_Exp>;
  _or?: Maybe<Array<Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Bool_Exp>>;
  account_id?: Maybe<String_Comparison_Exp>;
  created_ats?: Maybe<Jsonb_Comparison_Exp>;
  days_since_first_reservation?: Maybe<Jsonb_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  prev_created_ats?: Maybe<Jsonb_Comparison_Exp>;
  reservation_count?: Maybe<Int_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Max_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_max_fields';
  account_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['Int']>;
}

/** aggregate min on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Min_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_min_fields';
  account_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  reservation_count?: Maybe<Scalars['Int']>;
}

/** Ordering options when selecting data from "vw_stats_reservation_frequency_by_patient_accounts". */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Order_By {
  account_id?: Maybe<Order_By>;
  created_ats?: Maybe<Order_By>;
  days_since_first_reservation?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  prev_created_ats?: Maybe<Order_By>;
  reservation_count?: Maybe<Order_By>;
}

/** select columns of table "vw_stats_reservation_frequency_by_patient_accounts" */
export enum Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAts = 'created_ats',
  /** column name */
  DaysSinceFirstReservation = 'days_since_first_reservation',
  /** column name */
  Email = 'email',
  /** column name */
  PrevCreatedAts = 'prev_created_ats',
  /** column name */
  ReservationCount = 'reservation_count'
}

/** aggregate stddev on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stddev_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_stddev_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stddev_Pop_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_stddev_pop_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stddev_Samp_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_stddev_samp_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vw_stats_reservation_frequency_by_patient_accounts" */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Stream_Cursor_Value_Input {
  account_id?: Maybe<Scalars['String']>;
  created_ats?: Maybe<Scalars['jsonb']>;
  days_since_first_reservation?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  prev_created_ats?: Maybe<Scalars['jsonb']>;
  reservation_count?: Maybe<Scalars['Int']>;
}

/** aggregate sum on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Sum_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_sum_fields';
  reservation_count?: Maybe<Scalars['Int']>;
}

/** aggregate var_pop on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Var_Pop_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_var_pop_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Var_Samp_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_var_samp_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vw_Stats_Reservation_Frequency_By_Patient_Accounts_Variance_Fields {
  __typename?: 'vw_stats_reservation_frequency_by_patient_accounts_variance_fields';
  reservation_count?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vws_stats_general_user" */
export interface Vws_Stats_General_User {
  __typename?: 'vws_stats_general_user';
  active_customer_count?: Maybe<Scalars['bigint']>;
  avg_lt_days?: Maybe<Scalars['float8']>;
  avg_ltv?: Maybe<Scalars['numeric']>;
  avg_service_cost?: Maybe<Scalars['numeric']>;
  new_customer_count?: Maybe<Scalars['bigint']>;
  new_customer_service_count?: Maybe<Scalars['bigint']>;
  new_user_count?: Maybe<Scalars['bigint']>;
  repeating_customer_count?: Maybe<Scalars['bigint']>;
  service_count?: Maybe<Scalars['bigint']>;
  services_per_customer?: Maybe<Scalars['numeric']>;
}

export interface Vws_Stats_General_User_Aggregate {
  __typename?: 'vws_stats_general_user_aggregate';
  aggregate?: Maybe<Vws_Stats_General_User_Aggregate_Fields>;
  nodes: Array<Vws_Stats_General_User>;
}

/** aggregate fields of "vws_stats_general_user" */
export interface Vws_Stats_General_User_Aggregate_Fields {
  __typename?: 'vws_stats_general_user_aggregate_fields';
  avg?: Maybe<Vws_Stats_General_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vws_Stats_General_User_Max_Fields>;
  min?: Maybe<Vws_Stats_General_User_Min_Fields>;
  stddev?: Maybe<Vws_Stats_General_User_Stddev_Fields>;
  stddev_pop?: Maybe<Vws_Stats_General_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vws_Stats_General_User_Stddev_Samp_Fields>;
  sum?: Maybe<Vws_Stats_General_User_Sum_Fields>;
  var_pop?: Maybe<Vws_Stats_General_User_Var_Pop_Fields>;
  var_samp?: Maybe<Vws_Stats_General_User_Var_Samp_Fields>;
  variance?: Maybe<Vws_Stats_General_User_Variance_Fields>;
}


/** aggregate fields of "vws_stats_general_user" */
export type Vws_Stats_General_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vws_Stats_General_User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vws_Stats_General_User_Avg_Fields {
  __typename?: 'vws_stats_general_user_avg_fields';
  active_customer_count?: Maybe<Scalars['Float']>;
  avg_lt_days?: Maybe<Scalars['Float']>;
  avg_ltv?: Maybe<Scalars['Float']>;
  avg_service_cost?: Maybe<Scalars['Float']>;
  new_customer_count?: Maybe<Scalars['Float']>;
  new_customer_service_count?: Maybe<Scalars['Float']>;
  new_user_count?: Maybe<Scalars['Float']>;
  repeating_customer_count?: Maybe<Scalars['Float']>;
  service_count?: Maybe<Scalars['Float']>;
  services_per_customer?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vws_stats_general_user". All fields are combined with a logical 'AND'. */
export interface Vws_Stats_General_User_Bool_Exp {
  _and?: Maybe<Array<Vws_Stats_General_User_Bool_Exp>>;
  _not?: Maybe<Vws_Stats_General_User_Bool_Exp>;
  _or?: Maybe<Array<Vws_Stats_General_User_Bool_Exp>>;
  active_customer_count?: Maybe<Bigint_Comparison_Exp>;
  avg_lt_days?: Maybe<Float8_Comparison_Exp>;
  avg_ltv?: Maybe<Numeric_Comparison_Exp>;
  avg_service_cost?: Maybe<Numeric_Comparison_Exp>;
  new_customer_count?: Maybe<Bigint_Comparison_Exp>;
  new_customer_service_count?: Maybe<Bigint_Comparison_Exp>;
  new_user_count?: Maybe<Bigint_Comparison_Exp>;
  repeating_customer_count?: Maybe<Bigint_Comparison_Exp>;
  service_count?: Maybe<Bigint_Comparison_Exp>;
  services_per_customer?: Maybe<Numeric_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vws_Stats_General_User_Max_Fields {
  __typename?: 'vws_stats_general_user_max_fields';
  active_customer_count?: Maybe<Scalars['bigint']>;
  avg_lt_days?: Maybe<Scalars['float8']>;
  avg_ltv?: Maybe<Scalars['numeric']>;
  avg_service_cost?: Maybe<Scalars['numeric']>;
  new_customer_count?: Maybe<Scalars['bigint']>;
  new_customer_service_count?: Maybe<Scalars['bigint']>;
  new_user_count?: Maybe<Scalars['bigint']>;
  repeating_customer_count?: Maybe<Scalars['bigint']>;
  service_count?: Maybe<Scalars['bigint']>;
  services_per_customer?: Maybe<Scalars['numeric']>;
}

/** aggregate min on columns */
export interface Vws_Stats_General_User_Min_Fields {
  __typename?: 'vws_stats_general_user_min_fields';
  active_customer_count?: Maybe<Scalars['bigint']>;
  avg_lt_days?: Maybe<Scalars['float8']>;
  avg_ltv?: Maybe<Scalars['numeric']>;
  avg_service_cost?: Maybe<Scalars['numeric']>;
  new_customer_count?: Maybe<Scalars['bigint']>;
  new_customer_service_count?: Maybe<Scalars['bigint']>;
  new_user_count?: Maybe<Scalars['bigint']>;
  repeating_customer_count?: Maybe<Scalars['bigint']>;
  service_count?: Maybe<Scalars['bigint']>;
  services_per_customer?: Maybe<Scalars['numeric']>;
}

/** Ordering options when selecting data from "vws_stats_general_user". */
export interface Vws_Stats_General_User_Order_By {
  active_customer_count?: Maybe<Order_By>;
  avg_lt_days?: Maybe<Order_By>;
  avg_ltv?: Maybe<Order_By>;
  avg_service_cost?: Maybe<Order_By>;
  new_customer_count?: Maybe<Order_By>;
  new_customer_service_count?: Maybe<Order_By>;
  new_user_count?: Maybe<Order_By>;
  repeating_customer_count?: Maybe<Order_By>;
  service_count?: Maybe<Order_By>;
  services_per_customer?: Maybe<Order_By>;
}

/** select columns of table "vws_stats_general_user" */
export enum Vws_Stats_General_User_Select_Column {
  /** column name */
  ActiveCustomerCount = 'active_customer_count',
  /** column name */
  AvgLtDays = 'avg_lt_days',
  /** column name */
  AvgLtv = 'avg_ltv',
  /** column name */
  AvgServiceCost = 'avg_service_cost',
  /** column name */
  NewCustomerCount = 'new_customer_count',
  /** column name */
  NewCustomerServiceCount = 'new_customer_service_count',
  /** column name */
  NewUserCount = 'new_user_count',
  /** column name */
  RepeatingCustomerCount = 'repeating_customer_count',
  /** column name */
  ServiceCount = 'service_count',
  /** column name */
  ServicesPerCustomer = 'services_per_customer'
}

/** aggregate stddev on columns */
export interface Vws_Stats_General_User_Stddev_Fields {
  __typename?: 'vws_stats_general_user_stddev_fields';
  active_customer_count?: Maybe<Scalars['Float']>;
  avg_lt_days?: Maybe<Scalars['Float']>;
  avg_ltv?: Maybe<Scalars['Float']>;
  avg_service_cost?: Maybe<Scalars['Float']>;
  new_customer_count?: Maybe<Scalars['Float']>;
  new_customer_service_count?: Maybe<Scalars['Float']>;
  new_user_count?: Maybe<Scalars['Float']>;
  repeating_customer_count?: Maybe<Scalars['Float']>;
  service_count?: Maybe<Scalars['Float']>;
  services_per_customer?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vws_Stats_General_User_Stddev_Pop_Fields {
  __typename?: 'vws_stats_general_user_stddev_pop_fields';
  active_customer_count?: Maybe<Scalars['Float']>;
  avg_lt_days?: Maybe<Scalars['Float']>;
  avg_ltv?: Maybe<Scalars['Float']>;
  avg_service_cost?: Maybe<Scalars['Float']>;
  new_customer_count?: Maybe<Scalars['Float']>;
  new_customer_service_count?: Maybe<Scalars['Float']>;
  new_user_count?: Maybe<Scalars['Float']>;
  repeating_customer_count?: Maybe<Scalars['Float']>;
  service_count?: Maybe<Scalars['Float']>;
  services_per_customer?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vws_Stats_General_User_Stddev_Samp_Fields {
  __typename?: 'vws_stats_general_user_stddev_samp_fields';
  active_customer_count?: Maybe<Scalars['Float']>;
  avg_lt_days?: Maybe<Scalars['Float']>;
  avg_ltv?: Maybe<Scalars['Float']>;
  avg_service_cost?: Maybe<Scalars['Float']>;
  new_customer_count?: Maybe<Scalars['Float']>;
  new_customer_service_count?: Maybe<Scalars['Float']>;
  new_user_count?: Maybe<Scalars['Float']>;
  repeating_customer_count?: Maybe<Scalars['Float']>;
  service_count?: Maybe<Scalars['Float']>;
  services_per_customer?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vws_stats_general_user" */
export interface Vws_Stats_General_User_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vws_Stats_General_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vws_Stats_General_User_Stream_Cursor_Value_Input {
  active_customer_count?: Maybe<Scalars['bigint']>;
  avg_lt_days?: Maybe<Scalars['float8']>;
  avg_ltv?: Maybe<Scalars['numeric']>;
  avg_service_cost?: Maybe<Scalars['numeric']>;
  new_customer_count?: Maybe<Scalars['bigint']>;
  new_customer_service_count?: Maybe<Scalars['bigint']>;
  new_user_count?: Maybe<Scalars['bigint']>;
  repeating_customer_count?: Maybe<Scalars['bigint']>;
  service_count?: Maybe<Scalars['bigint']>;
  services_per_customer?: Maybe<Scalars['numeric']>;
}

/** aggregate sum on columns */
export interface Vws_Stats_General_User_Sum_Fields {
  __typename?: 'vws_stats_general_user_sum_fields';
  active_customer_count?: Maybe<Scalars['bigint']>;
  avg_lt_days?: Maybe<Scalars['float8']>;
  avg_ltv?: Maybe<Scalars['numeric']>;
  avg_service_cost?: Maybe<Scalars['numeric']>;
  new_customer_count?: Maybe<Scalars['bigint']>;
  new_customer_service_count?: Maybe<Scalars['bigint']>;
  new_user_count?: Maybe<Scalars['bigint']>;
  repeating_customer_count?: Maybe<Scalars['bigint']>;
  service_count?: Maybe<Scalars['bigint']>;
  services_per_customer?: Maybe<Scalars['numeric']>;
}

/** aggregate var_pop on columns */
export interface Vws_Stats_General_User_Var_Pop_Fields {
  __typename?: 'vws_stats_general_user_var_pop_fields';
  active_customer_count?: Maybe<Scalars['Float']>;
  avg_lt_days?: Maybe<Scalars['Float']>;
  avg_ltv?: Maybe<Scalars['Float']>;
  avg_service_cost?: Maybe<Scalars['Float']>;
  new_customer_count?: Maybe<Scalars['Float']>;
  new_customer_service_count?: Maybe<Scalars['Float']>;
  new_user_count?: Maybe<Scalars['Float']>;
  repeating_customer_count?: Maybe<Scalars['Float']>;
  service_count?: Maybe<Scalars['Float']>;
  services_per_customer?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vws_Stats_General_User_Var_Samp_Fields {
  __typename?: 'vws_stats_general_user_var_samp_fields';
  active_customer_count?: Maybe<Scalars['Float']>;
  avg_lt_days?: Maybe<Scalars['Float']>;
  avg_ltv?: Maybe<Scalars['Float']>;
  avg_service_cost?: Maybe<Scalars['Float']>;
  new_customer_count?: Maybe<Scalars['Float']>;
  new_customer_service_count?: Maybe<Scalars['Float']>;
  new_user_count?: Maybe<Scalars['Float']>;
  repeating_customer_count?: Maybe<Scalars['Float']>;
  service_count?: Maybe<Scalars['Float']>;
  services_per_customer?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vws_Stats_General_User_Variance_Fields {
  __typename?: 'vws_stats_general_user_variance_fields';
  active_customer_count?: Maybe<Scalars['Float']>;
  avg_lt_days?: Maybe<Scalars['Float']>;
  avg_ltv?: Maybe<Scalars['Float']>;
  avg_service_cost?: Maybe<Scalars['Float']>;
  new_customer_count?: Maybe<Scalars['Float']>;
  new_customer_service_count?: Maybe<Scalars['Float']>;
  new_user_count?: Maybe<Scalars['Float']>;
  repeating_customer_count?: Maybe<Scalars['Float']>;
  service_count?: Maybe<Scalars['Float']>;
  services_per_customer?: Maybe<Scalars['Float']>;
}

/** Schema for fn_stats_get_pareto_distribution_account_reservation */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation';
  accounts_percentage?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cumulative_accounts_percentage?: Maybe<Scalars['numeric']>;
  cumulative_reservations_percentage?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  price_estimate_average?: Maybe<Scalars['numeric']>;
  price_estimates?: Maybe<Scalars['jsonb']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  reservations_percentage?: Maybe<Scalars['numeric']>;
  reservee_metadatas?: Maybe<Scalars['jsonb']>;
  reservor_metadatas?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
}


/** Schema for fn_stats_get_pareto_distribution_account_reservation */
export type Vws_Stats_Get_Pareto_Distribution_Account_ReservationPrice_EstimatesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Schema for fn_stats_get_pareto_distribution_account_reservation */
export type Vws_Stats_Get_Pareto_Distribution_Account_ReservationReservee_MetadatasArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Schema for fn_stats_get_pareto_distribution_account_reservation */
export type Vws_Stats_Get_Pareto_Distribution_Account_ReservationReservor_MetadatasArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Schema for fn_stats_get_pareto_distribution_account_reservation */
export type Vws_Stats_Get_Pareto_Distribution_Account_ReservationServicesArgs = {
  path?: Maybe<Scalars['String']>;
};

export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Aggregate {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_aggregate';
  aggregate?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Aggregate_Fields>;
  nodes: Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation>;
}

/** aggregate fields of "vws_stats_get_pareto_distribution_account_reservation" */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Aggregate_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_aggregate_fields';
  avg?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Max_Fields>;
  min?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Min_Fields>;
  stddev?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stddev_Fields>;
  stddev_pop?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stddev_Samp_Fields>;
  sum?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Sum_Fields>;
  var_pop?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Var_Pop_Fields>;
  var_samp?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Var_Samp_Fields>;
  variance?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Variance_Fields>;
}


/** aggregate fields of "vws_stats_get_pareto_distribution_account_reservation" */
export type Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Avg_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_avg_fields';
  accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_reservations_percentage?: Maybe<Scalars['Float']>;
  price_estimate_average?: Maybe<Scalars['Float']>;
  reservation_count?: Maybe<Scalars['Float']>;
  reservations_percentage?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vws_stats_get_pareto_distribution_account_reservation". All fields are combined with a logical 'AND'. */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp {
  _and?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>>;
  _not?: Maybe<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>;
  _or?: Maybe<Array<Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Bool_Exp>>;
  accounts_percentage?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cumulative_accounts_percentage?: Maybe<Numeric_Comparison_Exp>;
  cumulative_reservations_percentage?: Maybe<Numeric_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  price_estimate_average?: Maybe<Numeric_Comparison_Exp>;
  price_estimates?: Maybe<Jsonb_Comparison_Exp>;
  reservation_count?: Maybe<Bigint_Comparison_Exp>;
  reservations_percentage?: Maybe<Numeric_Comparison_Exp>;
  reservee_metadatas?: Maybe<Jsonb_Comparison_Exp>;
  reservor_metadatas?: Maybe<Jsonb_Comparison_Exp>;
  services?: Maybe<Jsonb_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Max_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_max_fields';
  accounts_percentage?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cumulative_accounts_percentage?: Maybe<Scalars['numeric']>;
  cumulative_reservations_percentage?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  price_estimate_average?: Maybe<Scalars['numeric']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  reservations_percentage?: Maybe<Scalars['numeric']>;
}

/** aggregate min on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Min_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_min_fields';
  accounts_percentage?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cumulative_accounts_percentage?: Maybe<Scalars['numeric']>;
  cumulative_reservations_percentage?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  price_estimate_average?: Maybe<Scalars['numeric']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  reservations_percentage?: Maybe<Scalars['numeric']>;
}

/** Ordering options when selecting data from "vws_stats_get_pareto_distribution_account_reservation". */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Order_By {
  accounts_percentage?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cumulative_accounts_percentage?: Maybe<Order_By>;
  cumulative_reservations_percentage?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_estimate_average?: Maybe<Order_By>;
  price_estimates?: Maybe<Order_By>;
  reservation_count?: Maybe<Order_By>;
  reservations_percentage?: Maybe<Order_By>;
  reservee_metadatas?: Maybe<Order_By>;
  reservor_metadatas?: Maybe<Order_By>;
  services?: Maybe<Order_By>;
}

/** select columns of table "vws_stats_get_pareto_distribution_account_reservation" */
export enum Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Select_Column {
  /** column name */
  AccountsPercentage = 'accounts_percentage',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CumulativeAccountsPercentage = 'cumulative_accounts_percentage',
  /** column name */
  CumulativeReservationsPercentage = 'cumulative_reservations_percentage',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PriceEstimateAverage = 'price_estimate_average',
  /** column name */
  PriceEstimates = 'price_estimates',
  /** column name */
  ReservationCount = 'reservation_count',
  /** column name */
  ReservationsPercentage = 'reservations_percentage',
  /** column name */
  ReserveeMetadatas = 'reservee_metadatas',
  /** column name */
  ReservorMetadatas = 'reservor_metadatas',
  /** column name */
  Services = 'services'
}

/** aggregate stddev on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stddev_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_stddev_fields';
  accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_reservations_percentage?: Maybe<Scalars['Float']>;
  price_estimate_average?: Maybe<Scalars['Float']>;
  reservation_count?: Maybe<Scalars['Float']>;
  reservations_percentage?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stddev_Pop_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_stddev_pop_fields';
  accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_reservations_percentage?: Maybe<Scalars['Float']>;
  price_estimate_average?: Maybe<Scalars['Float']>;
  reservation_count?: Maybe<Scalars['Float']>;
  reservations_percentage?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stddev_Samp_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_stddev_samp_fields';
  accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_reservations_percentage?: Maybe<Scalars['Float']>;
  price_estimate_average?: Maybe<Scalars['Float']>;
  reservation_count?: Maybe<Scalars['Float']>;
  reservations_percentage?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vws_stats_get_pareto_distribution_account_reservation" */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Stream_Cursor_Value_Input {
  accounts_percentage?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cumulative_accounts_percentage?: Maybe<Scalars['numeric']>;
  cumulative_reservations_percentage?: Maybe<Scalars['numeric']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  price_estimate_average?: Maybe<Scalars['numeric']>;
  price_estimates?: Maybe<Scalars['jsonb']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  reservations_percentage?: Maybe<Scalars['numeric']>;
  reservee_metadatas?: Maybe<Scalars['jsonb']>;
  reservor_metadatas?: Maybe<Scalars['jsonb']>;
  services?: Maybe<Scalars['jsonb']>;
}

/** aggregate sum on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Sum_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_sum_fields';
  accounts_percentage?: Maybe<Scalars['numeric']>;
  cumulative_accounts_percentage?: Maybe<Scalars['numeric']>;
  cumulative_reservations_percentage?: Maybe<Scalars['numeric']>;
  price_estimate_average?: Maybe<Scalars['numeric']>;
  reservation_count?: Maybe<Scalars['bigint']>;
  reservations_percentage?: Maybe<Scalars['numeric']>;
}

/** aggregate var_pop on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Var_Pop_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_var_pop_fields';
  accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_reservations_percentage?: Maybe<Scalars['Float']>;
  price_estimate_average?: Maybe<Scalars['Float']>;
  reservation_count?: Maybe<Scalars['Float']>;
  reservations_percentage?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Var_Samp_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_var_samp_fields';
  accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_reservations_percentage?: Maybe<Scalars['Float']>;
  price_estimate_average?: Maybe<Scalars['Float']>;
  reservation_count?: Maybe<Scalars['Float']>;
  reservations_percentage?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vws_Stats_Get_Pareto_Distribution_Account_Reservation_Variance_Fields {
  __typename?: 'vws_stats_get_pareto_distribution_account_reservation_variance_fields';
  accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_accounts_percentage?: Maybe<Scalars['Float']>;
  cumulative_reservations_percentage?: Maybe<Scalars['Float']>;
  price_estimate_average?: Maybe<Scalars['Float']>;
  reservation_count?: Maybe<Scalars['Float']>;
  reservations_percentage?: Maybe<Scalars['Float']>;
}

/** columns and relationships of "vws_stats_get_voucher_uses" */
export interface Vws_Stats_Get_Voucher_Uses {
  __typename?: 'vws_stats_get_voucher_uses';
  new_user_uses?: Maybe<Scalars['bigint']>;
  repeating_user_uses?: Maybe<Scalars['bigint']>;
  total_uses?: Maybe<Scalars['bigint']>;
  unique_user_uses?: Maybe<Scalars['bigint']>;
  voucher_code?: Maybe<Scalars['String']>;
}

export interface Vws_Stats_Get_Voucher_Uses_Aggregate {
  __typename?: 'vws_stats_get_voucher_uses_aggregate';
  aggregate?: Maybe<Vws_Stats_Get_Voucher_Uses_Aggregate_Fields>;
  nodes: Array<Vws_Stats_Get_Voucher_Uses>;
}

/** aggregate fields of "vws_stats_get_voucher_uses" */
export interface Vws_Stats_Get_Voucher_Uses_Aggregate_Fields {
  __typename?: 'vws_stats_get_voucher_uses_aggregate_fields';
  avg?: Maybe<Vws_Stats_Get_Voucher_Uses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vws_Stats_Get_Voucher_Uses_Max_Fields>;
  min?: Maybe<Vws_Stats_Get_Voucher_Uses_Min_Fields>;
  stddev?: Maybe<Vws_Stats_Get_Voucher_Uses_Stddev_Fields>;
  stddev_pop?: Maybe<Vws_Stats_Get_Voucher_Uses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vws_Stats_Get_Voucher_Uses_Stddev_Samp_Fields>;
  sum?: Maybe<Vws_Stats_Get_Voucher_Uses_Sum_Fields>;
  var_pop?: Maybe<Vws_Stats_Get_Voucher_Uses_Var_Pop_Fields>;
  var_samp?: Maybe<Vws_Stats_Get_Voucher_Uses_Var_Samp_Fields>;
  variance?: Maybe<Vws_Stats_Get_Voucher_Uses_Variance_Fields>;
}


/** aggregate fields of "vws_stats_get_voucher_uses" */
export type Vws_Stats_Get_Voucher_Uses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export interface Vws_Stats_Get_Voucher_Uses_Avg_Fields {
  __typename?: 'vws_stats_get_voucher_uses_avg_fields';
  new_user_uses?: Maybe<Scalars['Float']>;
  repeating_user_uses?: Maybe<Scalars['Float']>;
  total_uses?: Maybe<Scalars['Float']>;
  unique_user_uses?: Maybe<Scalars['Float']>;
}

/** Boolean expression to filter rows from the table "vws_stats_get_voucher_uses". All fields are combined with a logical 'AND'. */
export interface Vws_Stats_Get_Voucher_Uses_Bool_Exp {
  _and?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Bool_Exp>>;
  _not?: Maybe<Vws_Stats_Get_Voucher_Uses_Bool_Exp>;
  _or?: Maybe<Array<Vws_Stats_Get_Voucher_Uses_Bool_Exp>>;
  new_user_uses?: Maybe<Bigint_Comparison_Exp>;
  repeating_user_uses?: Maybe<Bigint_Comparison_Exp>;
  total_uses?: Maybe<Bigint_Comparison_Exp>;
  unique_user_uses?: Maybe<Bigint_Comparison_Exp>;
  voucher_code?: Maybe<String_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Vws_Stats_Get_Voucher_Uses_Max_Fields {
  __typename?: 'vws_stats_get_voucher_uses_max_fields';
  new_user_uses?: Maybe<Scalars['bigint']>;
  repeating_user_uses?: Maybe<Scalars['bigint']>;
  total_uses?: Maybe<Scalars['bigint']>;
  unique_user_uses?: Maybe<Scalars['bigint']>;
  voucher_code?: Maybe<Scalars['String']>;
}

/** aggregate min on columns */
export interface Vws_Stats_Get_Voucher_Uses_Min_Fields {
  __typename?: 'vws_stats_get_voucher_uses_min_fields';
  new_user_uses?: Maybe<Scalars['bigint']>;
  repeating_user_uses?: Maybe<Scalars['bigint']>;
  total_uses?: Maybe<Scalars['bigint']>;
  unique_user_uses?: Maybe<Scalars['bigint']>;
  voucher_code?: Maybe<Scalars['String']>;
}

/** Ordering options when selecting data from "vws_stats_get_voucher_uses". */
export interface Vws_Stats_Get_Voucher_Uses_Order_By {
  new_user_uses?: Maybe<Order_By>;
  repeating_user_uses?: Maybe<Order_By>;
  total_uses?: Maybe<Order_By>;
  unique_user_uses?: Maybe<Order_By>;
  voucher_code?: Maybe<Order_By>;
}

/** select columns of table "vws_stats_get_voucher_uses" */
export enum Vws_Stats_Get_Voucher_Uses_Select_Column {
  /** column name */
  NewUserUses = 'new_user_uses',
  /** column name */
  RepeatingUserUses = 'repeating_user_uses',
  /** column name */
  TotalUses = 'total_uses',
  /** column name */
  UniqueUserUses = 'unique_user_uses',
  /** column name */
  VoucherCode = 'voucher_code'
}

/** aggregate stddev on columns */
export interface Vws_Stats_Get_Voucher_Uses_Stddev_Fields {
  __typename?: 'vws_stats_get_voucher_uses_stddev_fields';
  new_user_uses?: Maybe<Scalars['Float']>;
  repeating_user_uses?: Maybe<Scalars['Float']>;
  total_uses?: Maybe<Scalars['Float']>;
  unique_user_uses?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_pop on columns */
export interface Vws_Stats_Get_Voucher_Uses_Stddev_Pop_Fields {
  __typename?: 'vws_stats_get_voucher_uses_stddev_pop_fields';
  new_user_uses?: Maybe<Scalars['Float']>;
  repeating_user_uses?: Maybe<Scalars['Float']>;
  total_uses?: Maybe<Scalars['Float']>;
  unique_user_uses?: Maybe<Scalars['Float']>;
}

/** aggregate stddev_samp on columns */
export interface Vws_Stats_Get_Voucher_Uses_Stddev_Samp_Fields {
  __typename?: 'vws_stats_get_voucher_uses_stddev_samp_fields';
  new_user_uses?: Maybe<Scalars['Float']>;
  repeating_user_uses?: Maybe<Scalars['Float']>;
  total_uses?: Maybe<Scalars['Float']>;
  unique_user_uses?: Maybe<Scalars['Float']>;
}

/** Streaming cursor of the table "vws_stats_get_voucher_uses" */
export interface Vws_Stats_Get_Voucher_Uses_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Vws_Stats_Get_Voucher_Uses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Vws_Stats_Get_Voucher_Uses_Stream_Cursor_Value_Input {
  new_user_uses?: Maybe<Scalars['bigint']>;
  repeating_user_uses?: Maybe<Scalars['bigint']>;
  total_uses?: Maybe<Scalars['bigint']>;
  unique_user_uses?: Maybe<Scalars['bigint']>;
  voucher_code?: Maybe<Scalars['String']>;
}

/** aggregate sum on columns */
export interface Vws_Stats_Get_Voucher_Uses_Sum_Fields {
  __typename?: 'vws_stats_get_voucher_uses_sum_fields';
  new_user_uses?: Maybe<Scalars['bigint']>;
  repeating_user_uses?: Maybe<Scalars['bigint']>;
  total_uses?: Maybe<Scalars['bigint']>;
  unique_user_uses?: Maybe<Scalars['bigint']>;
}

/** aggregate var_pop on columns */
export interface Vws_Stats_Get_Voucher_Uses_Var_Pop_Fields {
  __typename?: 'vws_stats_get_voucher_uses_var_pop_fields';
  new_user_uses?: Maybe<Scalars['Float']>;
  repeating_user_uses?: Maybe<Scalars['Float']>;
  total_uses?: Maybe<Scalars['Float']>;
  unique_user_uses?: Maybe<Scalars['Float']>;
}

/** aggregate var_samp on columns */
export interface Vws_Stats_Get_Voucher_Uses_Var_Samp_Fields {
  __typename?: 'vws_stats_get_voucher_uses_var_samp_fields';
  new_user_uses?: Maybe<Scalars['Float']>;
  repeating_user_uses?: Maybe<Scalars['Float']>;
  total_uses?: Maybe<Scalars['Float']>;
  unique_user_uses?: Maybe<Scalars['Float']>;
}

/** aggregate variance on columns */
export interface Vws_Stats_Get_Voucher_Uses_Variance_Fields {
  __typename?: 'vws_stats_get_voucher_uses_variance_fields';
  new_user_uses?: Maybe<Scalars['Float']>;
  repeating_user_uses?: Maybe<Scalars['Float']>;
  total_uses?: Maybe<Scalars['Float']>;
  unique_user_uses?: Maybe<Scalars['Float']>;
}

export type CreateDeploymentMutationVariables = Exact<{
  commit: Scalars['String'];
}>;


export type CreateDeploymentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_deployment_one?: Maybe<(
    { __typename?: 'deployment' }
    & Pick<Deployment, 'id'>
  )> }
);

export type AcceptReservationMutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  driver_id: Scalars['String'];
  reservation_id: Scalars['uuid'];
  accepted_at: Scalars['timestamptz'];
}>;


export type AcceptReservationMutation = (
  { __typename?: 'mutation_root' }
  & { update_reservation?: Maybe<(
    { __typename?: 'reservation_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reservation' }
      & Pick<Reservation, 'id' | 'status' | 'driver_id'>
    )> }
  )> }
);

export type ActivateDriverMutationVariables = Exact<{
  account_id: Scalars['String'];
  service_list: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  city_list: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type ActivateDriverMutation = (
  { __typename?: 'mutation_root' }
  & { activateDriver?: Maybe<(
    { __typename?: 'DriverActivationOutput' }
    & Pick<DriverActivationOutput, 'status'>
  )> }
);

export type AddPaymentMethodMutationVariables = Exact<{
  payment_method_id: Scalars['String'];
}>;


export type AddPaymentMethodMutation = (
  { __typename?: 'mutation_root' }
  & { addPaymentMethod?: Maybe<(
    { __typename?: 'AddPaymentMethodOutput' }
    & Pick<AddPaymentMethodOutput, 'id' | 'provider' | 'snapshot'>
  )> }
);

export type AddServiceToReservationMutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  id: Scalars['uuid'];
  service_id: Scalars['String'];
}>;


export type AddServiceToReservationMutation = (
  { __typename?: 'mutation_root' }
  & { update_reservation_by_pk?: Maybe<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'id' | 'service_id'>
  )> }
);

export type CalculateTransactionFeesMutationVariables = Exact<{
  input?: Maybe<CalculateTransactionFeesInput>;
}>;


export type CalculateTransactionFeesMutation = (
  { __typename?: 'mutation_root' }
  & { calculateTransactionFees?: Maybe<(
    { __typename?: 'CalculateTransactionFeesOutput' }
    & Pick<CalculateTransactionFeesOutput, 'result'>
  )> }
);

export type CancelReservationMutationVariables = Exact<{
  reservation_id: Scalars['uuid'];
  cancellation_reason?: Maybe<Scalars['String']>;
}>;


export type CancelReservationMutation = (
  { __typename?: 'mutation_root' }
  & { cancelReservation?: Maybe<(
    { __typename?: 'CancelReservationOutput' }
    & Pick<CancelReservationOutput, 'message' | 'success'>
  )> }
);

export type ChangeEmailMutationVariables = Exact<{
  account_id: Scalars['String'];
  email: Scalars['String'];
}>;


export type ChangeEmailMutation = (
  { __typename?: 'mutation_root' }
  & { changeEmail?: Maybe<(
    { __typename?: 'EmailChangeOutput' }
    & Pick<EmailChangeOutput, 'data'>
  )> }
);

export type CheckAuthStatusMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckAuthStatusMutation = (
  { __typename?: 'mutation_root' }
  & { checkAuthStatus?: Maybe<(
    { __typename?: 'CheckAuthStatusOutput' }
    & Pick<CheckAuthStatusOutput, 'token'>
  )> }
);

export type CreateAccount_23082021MutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  additional_data?: Maybe<Scalars['jsonb']>;
  personal_data?: Maybe<Scalars['jsonb']>;
}>;


export type CreateAccount_23082021Mutation = (
  { __typename?: 'mutation_root' }
  & { createAccount?: Maybe<(
    { __typename?: 'CreateAccountOutput' }
    & Pick<CreateAccountOutput, 'account_id'>
  )> }
);

export type CreateAccount_30042021MutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  additional_data?: Maybe<Scalars['jsonb']>;
}>;


export type CreateAccount_30042021Mutation = (
  { __typename?: 'mutation_root' }
  & { createAccount?: Maybe<(
    { __typename?: 'CreateAccountOutput' }
    & Pick<CreateAccountOutput, 'account_id'>
  )> }
);

export type CreateAccount_30092021MutationVariables = Exact<{
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  additional_data?: Maybe<Scalars['jsonb']>;
  personal_data?: Maybe<Scalars['jsonb']>;
}>;


export type CreateAccount_30092021Mutation = (
  { __typename?: 'mutation_root' }
  & { createAccount?: Maybe<(
    { __typename?: 'CreateAccountOutput' }
    & Pick<CreateAccountOutput, 'account_id'>
  )> }
);

export type CreatePatientProfileForAccountMutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  account_id: Scalars['String'];
}>;


export type CreatePatientProfileForAccountMutation = (
  { __typename?: 'mutation_root' }
  & { insert_patient_profile?: Maybe<(
    { __typename?: 'patient_profile_mutation_response' }
    & { returning: Array<(
      { __typename?: 'patient_profile' }
      & Pick<Patient_Profile, 'id' | 'role'>
    )> }
  )> }
);

export type CreateQuoteMutationVariables = Exact<{
  quote?: Maybe<Scalars['jsonb']>;
}>;


export type CreateQuoteMutation = (
  { __typename?: 'mutation_root' }
  & { createQuote?: Maybe<(
    { __typename?: 'createQuoteOutput' }
    & Pick<CreateQuoteOutput, 'reservation_id'>
  )> }
);

export type DeactivateDriverMutationVariables = Exact<{
  account_id: Scalars['String'];
}>;


export type DeactivateDriverMutation = (
  { __typename?: 'mutation_root' }
  & { deactivateDriver?: Maybe<(
    { __typename?: 'DeactivateDriverOutput' }
    & Pick<DeactivateDriverOutput, 'success'>
  )> }
);

export type DeclineReservationDueToUnavailabilityMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeclineReservationDueToUnavailabilityMutation = (
  { __typename?: 'mutation_root' }
  & { update_reservation_by_pk?: Maybe<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'status'>
  )>, waiting?: Maybe<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'status'>
  )> }
);

export type DriverAcceptReservationChangeMutationVariables = Exact<{
  reservation_id?: Maybe<Scalars['uuid']>;
}>;


export type DriverAcceptReservationChangeMutation = (
  { __typename?: 'mutation_root' }
  & { driverAcceptReservationChange?: Maybe<(
    { __typename?: 'DriverAcceptReservationChangeOutput' }
    & Pick<DriverAcceptReservationChangeOutput, 'success'>
  )> }
);

export type DriverRejectReservationChangeMutationVariables = Exact<{
  reservation_id?: Maybe<Scalars['uuid']>;
}>;


export type DriverRejectReservationChangeMutation = (
  { __typename?: 'mutation_root' }
  & { driverRejectReservationChange?: Maybe<(
    { __typename?: 'driverRejectReservationChangeOutput' }
    & Pick<DriverRejectReservationChangeOutput, 'success'>
  )> }
);

export type FinishReservationMutationVariables = Exact<{
  reservation_id: Scalars['uuid'];
  feedback_link: Scalars['String'];
}>;


export type FinishReservationMutation = (
  { __typename?: 'mutation_root' }
  & { finishReservation?: Maybe<(
    { __typename?: 'FinishReservationOutput' }
    & Pick<FinishReservationOutput, 'success'>
  )> }
);

export type GetAcceptedReservationsByDriverIdQueryVariables = Exact<{
  driver_id: Scalars['String'];
  now: Scalars['timestamptz'];
}>;


export type GetAcceptedReservationsByDriverIdQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetAcceptedReservationsByDriverId_20210226QueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetAcceptedReservationsByDriverId_20210226Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetAcceptedReservationsByDriverId_20210527QueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetAcceptedReservationsByDriverId_20210527Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetAcceptedReservationsByDriverId_20221031QueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetAcceptedReservationsByDriverId_20221031Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'short_id'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetAcceptedReservationsByDriverId_20230411QueryVariables = Exact<{
  driver_id: Scalars['String'];
  q?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetAcceptedReservationsByDriverId_20230411Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'short_id'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )>, reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetAcceptedReservationsCountByDriverId_20220930QueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetAcceptedReservationsCountByDriverId_20220930Query = (
  { __typename?: 'query_root' }
  & { reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetAccountByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetAccountByEmailQuery = (
  { __typename?: 'query_root' }
  & { account: Array<(
    { __typename?: 'account' }
    & Pick<Account, 'id' | 'email' | 'personal_data'>
  )> }
);

export type GetAccountByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAccountByIdQuery = (
  { __typename?: 'query_root' }
  & { account_by_pk?: Maybe<(
    { __typename?: 'account' }
    & Pick<Account, 'id' | 'email' | 'payment_methods' | 'personal_data'>
    & { voucher: Array<(
      { __typename?: 'voucher' }
      & Pick<Voucher, 'code'>
    )> }
  )> }
);

export type GetAccountDriverQueryVariables = Exact<{
  account_id: Scalars['String'];
}>;


export type GetAccountDriverQuery = (
  { __typename?: 'query_root' }
  & { driver_profile: Array<(
    { __typename?: 'driver_profile' }
    & Pick<Driver_Profile, 'id' | 'account_id' | 'cities' | 'services' | 'dof_requested_city'>
    & { driver_status?: Maybe<(
      { __typename?: 'driver_status' }
      & Pick<Driver_Status, 'value'>
    )> }
  )> }
);

export type GetAccountFeedbackQueryVariables = Exact<{
  account_id: Scalars['String'];
}>;


export type GetAccountFeedbackQuery = (
  { __typename?: 'query_root' }
  & { account_feedback: Array<(
    { __typename?: 'account_feedback' }
    & Pick<Account_Feedback, 'id'>
  )> }
);

export type GetAccountRegistrationAndFirstReservationDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountRegistrationAndFirstReservationDatesQuery = (
  { __typename?: 'query_root' }
  & { account: Array<(
    { __typename?: 'account' }
    & Pick<Account, 'email' | 'created_at'>
    & { reservor_reservations: Array<(
      { __typename?: 'reservation' }
      & Pick<Reservation, 'created_at'>
    )> }
  )> }
);

export type GetAccountReservationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountReservationsQuery = (
  { __typename?: 'query_root' }
  & { account: Array<(
    { __typename?: 'account' }
    & Pick<Account, 'email'>
    & { reservor_reservations: Array<(
      { __typename?: 'reservation' }
      & Pick<Reservation, 'id' | 'ended_at' | 'status'>
    )> }
  )> }
);

export type GetAllPatientReservationsQueryVariables = Exact<{
  reservor_id?: Maybe<Scalars['String']>;
}>;


export type GetAllPatientReservationsQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'id'>
  )> }
);

export type GetArchivedReservationsByDriverIdQueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetArchivedReservationsByDriverIdQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetArchivedReservationsByDriverId_20210527QueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetArchivedReservationsByDriverId_20210527Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetArchivedReservationsByDriverId_20220823QueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetArchivedReservationsByDriverId_20220823Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'patient_rating'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetArchivedReservationsByDriverId_20221031QueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetArchivedReservationsByDriverId_20221031Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'patient_rating' | 'short_id'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetArchivedReservationsByDriverId_20230411QueryVariables = Exact<{
  driver_id: Scalars['String'];
  q?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetArchivedReservationsByDriverId_20230411Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'patient_rating' | 'short_id'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )>, reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetArchivedReservationsByPatientIdQueryVariables = Exact<{
  reservor_id?: Maybe<Scalars['String']>;
}>;


export type GetArchivedReservationsByPatientIdQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetArchivedReservationsByPatientId_20220530QueryVariables = Exact<{
  reservor_id?: Maybe<Scalars['String']>;
}>;


export type GetArchivedReservationsByPatientId_20220530Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'is_manual' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetArchivedReservationsByPatientId_20220823QueryVariables = Exact<{
  reservor_id?: Maybe<Scalars['String']>;
}>;


export type GetArchivedReservationsByPatientId_20220823Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'is_manual' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'driver_rating' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetArchivedReservationsByPatientId_20230411QueryVariables = Exact<{
  reservor_id?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetArchivedReservationsByPatientId_20230411Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'is_manual' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'driver_rating' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )>, reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetAuthProviderDataQueryVariables = Exact<{
  where?: Maybe<Vw_Stats_Auth_Provider_Data_Bool_Exp>;
}>;


export type GetAuthProviderDataQuery = (
  { __typename?: 'query_root' }
  & { vw_stats_auth_provider_data: Array<(
    { __typename?: 'vw_stats_auth_provider_data' }
    & Pick<Vw_Stats_Auth_Provider_Data, 'day' | 'password' | 'password_converted' | 'facebook' | 'facebook_converted' | 'google' | 'google_converted'>
  )> }
);

export type GetCardSetupClientSecretQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardSetupClientSecretQuery = (
  { __typename?: 'query_root' }
  & { getCardSetupClientSecret?: Maybe<(
    { __typename?: 'ClientSecretOutput' }
    & Pick<ClientSecretOutput, 'client_secret'>
  )> }
);

export type GetDailyConversionsByAuthProviderQueryVariables = Exact<{
  where?: Maybe<Vw_Stats_Daily_Conversions_By_Auth_Provider_Bool_Exp>;
}>;


export type GetDailyConversionsByAuthProviderQuery = (
  { __typename?: 'query_root' }
  & { vw_stats_daily_conversions_by_auth_provider: Array<(
    { __typename?: 'vw_stats_daily_conversions_by_auth_provider' }
    & Pick<Vw_Stats_Daily_Conversions_By_Auth_Provider, 'converted_at' | 'accounts_converted' | 'auth_provider'>
  )> }
);

export type GetDistanceAndDurationQueryVariables = Exact<{
  departure: LatLngInput;
  destination: LatLngInput;
  city: LatLngInput;
}>;


export type GetDistanceAndDurationQuery = (
  { __typename?: 'query_root' }
  & { getDistanceAndDuration: (
    { __typename?: 'GetDistanceAndDurationOutput' }
    & Pick<GetDistanceAndDurationOutput, 'ab' | 'a0' | 'b0' | 'original_distance_matrix_response'>
  ) }
);

export type GetDistanceAndDuration_25052022QueryVariables = Exact<{
  departure: LatLngInput;
  destination: LatLngInput;
  city: LatLngInput;
}>;


export type GetDistanceAndDuration_25052022Query = (
  { __typename?: 'query_root' }
  & { getDistanceAndDuration: (
    { __typename?: 'GetDistanceAndDurationOutput' }
    & Pick<GetDistanceAndDurationOutput, 'ab' | 'a0' | 'b0'>
  ) }
);

export type GetDistanceAndDuration_26072021QueryVariables = Exact<{
  departure: LatLngInput;
  destination: LatLngInput;
  city: LatLngInput;
}>;


export type GetDistanceAndDuration_26072021Query = (
  { __typename?: 'query_root' }
  & { getDistanceAndDuration: (
    { __typename?: 'GetDistanceAndDurationOutput' }
    & Pick<GetDistanceAndDurationOutput, 'ab' | 'a0' | 'b0' | 'original_distance_matrix_response'>
  ) }
);

export type GetDistinctReservationVoucherValidationFailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDistinctReservationVoucherValidationFailsQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'voucher_not_applicable_reason'>
  )> }
);

export type GetDofDriversQueryVariables = Exact<{
  start_date: Scalars['timestamptz'];
  end_date: Scalars['timestamptz'];
}>;


export type GetDofDriversQuery = (
  { __typename?: 'query_root' }
  & { vw_admin_dof_drivers: Array<(
    { __typename?: 'vw_admin_dof_drivers' }
    & Pick<Vw_Admin_Dof_Drivers, 'id' | 'created_at' | 'dof_requested_city' | 'is_general_info_submitted' | 'is_video_verification_submitted' | 'is_driver_license_verified' | 'is_id_verified' | 'logged_in_academy_at_least_once' | 'is_academy_completed' | 'dof_approved' | 'reservation_count'>
  )> }
);

export type GetDriverProfileByAccountIdQueryVariables = Exact<{
  account_id: Scalars['String'];
}>;


export type GetDriverProfileByAccountIdQuery = (
  { __typename?: 'query_root' }
  & { driver_profile: Array<(
    { __typename?: 'driver_profile' }
    & Pick<Driver_Profile, 'id' | 'status' | 'services' | 'cities' | 'is_covid_passport_submitted' | 'is_covid_passport_approved' | 'is_general_info_submitted'>
  )> }
);

export type GetDuplicatedReservationsQueryVariables = Exact<{
  where?: Maybe<Reservation_Bool_Exp>;
}>;


export type GetDuplicatedReservationsQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'id' | 'price_estimate' | 'starts_at' | 'created_at'>
    & { reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )> }
  )>, reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
      & { sum?: Maybe<(
        { __typename?: 'reservation_sum_fields' }
        & Pick<Reservation_Sum_Fields, 'price_estimate'>
      )> }
    )> }
  ) }
);

export type GetExcludedAccountsQueryVariables = Exact<{
  _nin?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetExcludedAccountsQuery = (
  { __typename?: 'query_root' }
  & { account: Array<(
    { __typename?: 'account' }
    & Pick<Account, 'email'>
    & { driver_profile?: Maybe<(
      { __typename?: 'driver_profile' }
      & Pick<Driver_Profile, 'account_id'>
    )> }
  )> }
);

export type GetFirebaseUserDetailsQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GetFirebaseUserDetailsQuery = (
  { __typename?: 'query_root' }
  & { getFirebaseUserDetails?: Maybe<(
    { __typename?: 'GetFirebaseUserDetailsOutput' }
    & Pick<GetFirebaseUserDetailsOutput, 'firebase_user'>
  )> }
);

export type GetFutureReservationsByPatientQueryVariables = Exact<{
  now: Scalars['timestamptz'];
  reservor_id?: Maybe<Scalars['String']>;
}>;


export type GetFutureReservationsByPatientQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetFutureReservationsByPatientId_20220530QueryVariables = Exact<{
  now: Scalars['timestamptz'];
  reservor_id?: Maybe<Scalars['String']>;
}>;


export type GetFutureReservationsByPatientId_20220530Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'is_manual' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetFutureReservationsByPatientId_20230405QueryVariables = Exact<{
  now: Scalars['timestamptz'];
  reservor_id?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetFutureReservationsByPatientId_20230405Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'is_manual' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )>, reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetGeneralUserStatsQueryVariables = Exact<{
  start_date?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
}>;


export type GetGeneralUserStatsQuery = (
  { __typename?: 'query_root' }
  & { fn_stats_get_general_user: Array<(
    { __typename?: 'vws_stats_general_user' }
    & Pick<Vws_Stats_General_User, 'service_count' | 'active_customer_count' | 'services_per_customer' | 'repeating_customer_count' | 'new_customer_count' | 'new_user_count' | 'avg_service_cost' | 'new_customer_service_count' | 'avg_lt_days' | 'avg_ltv'>
  )> }
);

export type GetInProgressReservationsByDriverIdQueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetInProgressReservationsByDriverIdQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetLastReservationByPatientIdAndServiceIdQueryVariables = Exact<{
  reservor_id: Scalars['String'];
  service_id: Scalars['String'];
}>;


export type GetLastReservationByPatientIdAndServiceIdQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'metadata'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )> }
  )> }
);

export type GetLoginTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoginTokenQuery = (
  { __typename?: 'query_root' }
  & { getLoginToken?: Maybe<(
    { __typename?: 'GetLoginTokenOutput' }
    & Pick<GetLoginTokenOutput, 'token'>
  )> }
);

export type GetOverdueReservationsByDriverIdQueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetOverdueReservationsByDriverIdQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'driver_suggested_ended_at' | 'driver_suggested_started_at'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetParetoDistributionForAccountsVsReservationsQueryVariables = Exact<{
  min_reservation_count: Scalars['Int'];
}>;


export type GetParetoDistributionForAccountsVsReservationsQuery = (
  { __typename?: 'query_root' }
  & { fn_stats_get_pareto_distribution_account_reservation: Array<(
    { __typename?: 'vws_stats_get_pareto_distribution_account_reservation' }
    & Pick<Vws_Stats_Get_Pareto_Distribution_Account_Reservation, 'id' | 'email' | 'accounts_percentage' | 'reservations_percentage' | 'cumulative_accounts_percentage' | 'cumulative_reservations_percentage' | 'price_estimate_average' | 'price_estimates' | 'reservation_count'>
  )> }
);

export type GetPatientConversionReservationQueryVariables = Exact<{
  where?: Maybe<Vw_Stats_Patient_Conversion_Reservation_Bool_Exp>;
}>;


export type GetPatientConversionReservationQuery = (
  { __typename?: 'query_root' }
  & { vw_stats_patient_conversion_reservation: Array<(
    { __typename?: 'vw_stats_patient_conversion_reservation' }
    & Pick<Vw_Stats_Patient_Conversion_Reservation, 'id' | 'created_at' | 'traffic_source' | 'service_city' | 'reservation_metadata' | 'is_manual'>
  )> }
);

export type GetPatientProfileByAccountIdQueryVariables = Exact<{
  account_id: Scalars['String'];
}>;


export type GetPatientProfileByAccountIdQuery = (
  { __typename?: 'query_root' }
  & { patient_profile: Array<(
    { __typename?: 'patient_profile' }
    & Pick<Patient_Profile, 'id' | 'role'>
  )> }
);

export type GetPatientsByConversionStatusQueryVariables = Exact<{
  where?: Maybe<Reservation_Bool_Exp>;
}>;


export type GetPatientsByConversionStatusQuery = (
  { __typename?: 'query_root' }
  & { reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ), reservation: Array<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'id' | 'created_at' | 'starts_at' | 'ends_at' | 'metadata' | 'status'>
    & { reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'email' | 'created_at'>
      & { account_feedback?: Maybe<(
        { __typename?: 'account_feedback' }
        & Pick<Account_Feedback, 'traffic_source'>
      )> }
    )> }
  )> }
);

export type GetPatientsByPartialEmailOrNameQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type GetPatientsByPartialEmailOrNameQuery = (
  { __typename?: 'query_root' }
  & { vw_admin_account: Array<(
    { __typename?: 'vw_admin_account' }
    & Pick<Vw_Admin_Account, 'id' | 'email' | 'personal_data'>
  )> }
);

export type GetPaymentFailedReservationsByPatientIdQueryVariables = Exact<{
  patient_id: Scalars['String'];
}>;


export type GetPaymentFailedReservationsByPatientIdQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationByIdQuery = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_20210610QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_20210610Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_20210622QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_20210622Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'extra_costs_value' | 'extra_costs_text'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_20210707QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_20210707Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'voucher_redemption_id' | 'extra_costs_value' | 'extra_costs_text'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_20210520QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_20210520Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'voucher_redemption_id' | 'extra_costs_value' | 'extra_costs_text' | 'is_manual'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_20220531QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_20220531Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'voucher_redemption_id' | 'extra_costs_value' | 'extra_costs_text' | 'price_estimate_transaction_fees_driver_amount' | 'price_billable_transaction_fees_driver_amount'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_20220802QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_20220802Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'voucher_redemption_id' | 'extra_costs_value' | 'extra_costs_text' | 'price_estimate_transaction_fees_driver_amount' | 'price_billable_transaction_fees_driver_amount' | 'driver_suggested_started_at' | 'driver_suggested_ended_at'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_Administrator_20220601QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_Administrator_20220601Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'voucher_redemption_id' | 'extra_costs_value' | 'extra_costs_text' | 'price_estimate_transaction_fees' | 'price_billable_transaction_fees'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_Driver_20220823QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_Driver_20220823Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'voucher_redemption_id' | 'extra_costs_value' | 'patient_rating' | 'extra_costs_text'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_Driver_20221031QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_Driver_20221031Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'voucher_redemption_id' | 'extra_costs_value' | 'extra_costs_text' | 'price_estimate_transaction_fees_driver_amount' | 'price_billable_transaction_fees_driver_amount' | 'driver_suggested_started_at' | 'driver_suggested_ended_at' | 'patient_rating' | 'short_id' | 'change_request_changes' | 'change_request_driver_action'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_Patient_20220823QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_Patient_20220823Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'voucher_redemption_id' | 'extra_costs_value' | 'driver_rating' | 'extra_costs_text'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationById_Patient_20221018QueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationById_Patient_20221018Query = (
  { __typename?: 'query_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'preferred_driver' | 'created_at' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'duration_estimate' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason' | 'voucher_redemption_id' | 'extra_costs_value' | 'extra_costs_text' | 'change_request_driver_action'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationByIdSubSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReservationByIdSubSubscription = (
  { __typename?: 'subscription_root' }
  & { reservation_by_pk?: Maybe<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationFrequencyByPatientAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReservationFrequencyByPatientAccountsQuery = (
  { __typename?: 'query_root' }
  & { vw_stats_reservation_frequency_by_patient_accounts: Array<(
    { __typename?: 'vw_stats_reservation_frequency_by_patient_accounts' }
    & Pick<Vw_Stats_Reservation_Frequency_By_Patient_Accounts, 'account_id' | 'email' | 'reservation_count' | 'days_since_first_reservation'>
  )> }
);

export type GetReservationsByPatientIdQueryVariables = Exact<{
  reservor_id: Scalars['String'];
}>;


export type GetReservationsByPatientIdQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetReservationsCountByReservorId_20221001QueryVariables = Exact<{
  reservor_id: Scalars['String'];
}>;


export type GetReservationsCountByReservorId_20221001Query = (
  { __typename?: 'query_root' }
  & { reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetReservationsWithChangeRequestByDriverIdQueryVariables = Exact<{
  driver_id: Scalars['String'];
}>;


export type GetReservationsWithChangeRequestByDriverIdQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'change_request_changes' | 'change_request_driver_action'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServicesQuery = (
  { __typename?: 'query_root' }
  & { service: Array<(
    { __typename?: 'service' }
    & Pick<Service, 'id' | 'requirements' | 'type' | 'translations'>
  )> }
);

export type GetSyncsInProgressCountSubSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetSyncsInProgressCountSubSubscription = (
  { __typename?: 'subscription_root' }
  & { sync_aggregate: (
    { __typename?: 'sync_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'sync_aggregate_fields' }
      & Pick<Sync_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetSyncsSubSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetSyncsSubSubscription = (
  { __typename?: 'subscription_root' }
  & { sync: Array<(
    { __typename?: 'sync' }
    & Pick<Sync, 'id' | 'is_sync_in_progress' | 'synced_at'>
  )> }
);

export type GetTrafficSourceByAccountIdQueryVariables = Exact<{
  account_id: Scalars['String'];
}>;


export type GetTrafficSourceByAccountIdQuery = (
  { __typename?: 'query_root' }
  & { account_feedback: Array<(
    { __typename?: 'account_feedback' }
    & Pick<Account_Feedback, 'traffic_source'>
  )> }
);

export type GetUserStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserStatisticsQuery = (
  { __typename?: 'query_root' }
  & { getUserStatistics?: Maybe<(
    { __typename?: 'GetUserStatisticsOutput' }
    & Pick<GetUserStatisticsOutput, 'total' | 'admins' | 'patients' | 'drivers' | 'patientsActivated' | 'driversActivated' | 'totalActivated'>
  )> }
);

export type GetVoucherUseStatsQueryVariables = Exact<{
  start_date?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
}>;


export type GetVoucherUseStatsQuery = (
  { __typename?: 'query_root' }
  & { fn_stats_get_voucher_uses: Array<(
    { __typename?: 'vws_stats_get_voucher_uses' }
    & Pick<Vws_Stats_Get_Voucher_Uses, 'voucher_code' | 'total_uses' | 'unique_user_uses' | 'new_user_uses' | 'repeating_user_uses'>
  )> }
);

export type GetWaitingReservationsQueryVariables = Exact<{
  cities_expression_array?: Maybe<Array<Reservation_Bool_Exp> | Reservation_Bool_Exp>;
  services?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  now: Scalars['timestamptz'];
}>;


export type GetWaitingReservationsQuery = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetWaitingReservations_20210527QueryVariables = Exact<{
  cities_expression_array?: Maybe<Array<Reservation_Bool_Exp> | Reservation_Bool_Exp>;
  services?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  now: Scalars['timestamptz'];
}>;


export type GetWaitingReservations_20210527Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )> }
);

export type GetWaitingReservations_20230411QueryVariables = Exact<{
  cities_expression_array?: Maybe<Array<Reservation_Bool_Exp> | Reservation_Bool_Exp>;
  services?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  now: Scalars['timestamptz'];
  q?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type GetWaitingReservations_20230411Query = (
  { __typename?: 'query_root' }
  & { reservation: Array<(
    { __typename: 'reservation' }
    & Pick<Reservation, 'id' | 'reservor_metadata' | 'reservee_metadata' | 'service_id' | 'status' | 'starts_at' | 'ends_at' | 'started_at' | 'ended_at' | 'metadata' | 'currency' | 'price_estimate' | 'price_billable' | 'price_estimate_discounted' | 'price_billable_discounted' | 'voucher_code' | 'voucher_not_applicable_reason'>
    & { driver?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, reservor?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email' | 'personal_data'>
    )>, reservee?: Maybe<(
      { __typename?: 'account' }
      & Pick<Account, 'id' | 'email'>
    )>, payments: Array<(
      { __typename?: 'payment' }
      & Pick<Payment, 'paid'>
    )> }
  )>, reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IsDriverAvailableQueryVariables = Exact<{
  from_timestamptz: Scalars['timestamptz'];
  to_timestamptz: Scalars['timestamptz'];
  driver_id: Scalars['String'];
}>;


export type IsDriverAvailableQuery = (
  { __typename?: 'query_root' }
  & { reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IsDriverAvailable_20220810QueryVariables = Exact<{
  from_timestamptz: Scalars['timestamptz'];
  to_timestamptz: Scalars['timestamptz'];
}>;


export type IsDriverAvailable_20220810Query = (
  { __typename?: 'query_root' }
  & { isDriverAvailable?: Maybe<(
    { __typename?: 'IsDriverAvailableOutput' }
    & Pick<IsDriverAvailableOutput, 'is_driver_available'>
  )> }
);

export type IsDriverFullyOnboardedToStripeQueryVariables = Exact<{ [key: string]: never; }>;


export type IsDriverFullyOnboardedToStripeQuery = (
  { __typename?: 'query_root' }
  & { isDriverFullyOnboardedToStripe?: Maybe<(
    { __typename?: 'IsDriverFullyOnboardedToStripeOutput' }
    & Pick<IsDriverFullyOnboardedToStripeOutput, 'is_fully_onboarded'>
  )> }
);

export type IsNewerAppVersionAvailableSubscriptionVariables = Exact<{
  date: Scalars['timestamptz'];
}>;


export type IsNewerAppVersionAvailableSubscription = (
  { __typename?: 'subscription_root' }
  & { deployment_aggregate: (
    { __typename?: 'deployment_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'deployment_aggregate_fields' }
      & Pick<Deployment_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IsPatientAvailableQueryVariables = Exact<{
  from_timestamptz: Scalars['timestamptz'];
  to_timestamptz: Scalars['timestamptz'];
  reservor_id: Scalars['String'];
}>;


export type IsPatientAvailableQuery = (
  { __typename?: 'query_root' }
  & { reservation_aggregate: (
    { __typename?: 'reservation_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reservation_aggregate_fields' }
      & Pick<Reservation_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AcceptManualReservationMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AcceptManualReservationMutation = (
  { __typename?: 'mutation_root' }
  & { acceptManualReservation?: Maybe<(
    { __typename?: 'AcceptManualReservationOutput' }
    & Pick<AcceptManualReservationOutput, 'success' | 'error'>
  )> }
);

export type DeclineManualReservationMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type DeclineManualReservationMutation = (
  { __typename?: 'mutation_root' }
  & { declineManualReservation?: Maybe<(
    { __typename?: 'DeclineManualReservationOutput' }
    & Pick<DeclineManualReservationOutput, 'success' | 'error'>
  )> }
);

export type OnboardDriverToStripeMutationVariables = Exact<{
  origin: Scalars['String'];
}>;


export type OnboardDriverToStripeMutation = (
  { __typename?: 'mutation_root' }
  & { onboardDriverToStripe?: Maybe<(
    { __typename?: 'OnboardDriverToStripeOutput' }
    & Pick<OnboardDriverToStripeOutput, 'account_link'>
  )> }
);

export type PatientCancelReservationMutationVariables = Exact<{
  reservation_id: Scalars['uuid'];
  cancellation_reason?: Maybe<Scalars['String']>;
}>;


export type PatientCancelReservationMutation = (
  { __typename?: 'mutation_root' }
  & { patientCancelReservation?: Maybe<(
    { __typename?: 'PatientCancelReservationOutput' }
    & Pick<PatientCancelReservationOutput, 'success' | 'message'>
  )> }
);

export type PatientRequestReservationChangeMutationVariables = Exact<{
  reservation_id?: Maybe<Scalars['uuid']>;
  changes?: Maybe<Scalars['jsonb']>;
}>;


export type PatientRequestReservationChangeMutation = (
  { __typename?: 'mutation_root' }
  & { patientRequestReservationChange?: Maybe<(
    { __typename?: 'patientRequestReservationChangeOutput' }
    & Pick<PatientRequestReservationChangeOutput, 'success'>
  )> }
);

export type RemovePaymentMethodMutationVariables = Exact<{
  payment_method_id: Scalars['String'];
}>;


export type RemovePaymentMethodMutation = (
  { __typename?: 'mutation_root' }
  & { removePaymentMethod?: Maybe<(
    { __typename?: 'RemovePaymentMethodOutput' }
    & Pick<RemovePaymentMethodOutput, 'payment_method_id'>
  )> }
);

export type RequestReservationChangeMutationVariables = Exact<{
  reservation_id: Scalars['uuid'];
  changes?: Maybe<Scalars['jsonb']>;
}>;


export type RequestReservationChangeMutation = (
  { __typename?: 'mutation_root' }
  & { update_reservation_by_pk?: Maybe<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'id'>
  )> }
);

export type RequestLoginTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type RequestLoginTokenQuery = (
  { __typename?: 'query_root' }
  & { requestLoginToken?: Maybe<(
    { __typename?: 'RequestLoginTokenOutput' }
    & Pick<RequestLoginTokenOutput, 'token'>
  )> }
);

export type ResendStripeCheckoutSessionPaymentRequestMutationVariables = Exact<{
  reservation_id: Scalars['uuid'];
}>;


export type ResendStripeCheckoutSessionPaymentRequestMutation = (
  { __typename?: 'mutation_root' }
  & { resendStripeCheckoutSessionPaymentRequest?: Maybe<(
    { __typename?: 'ResendStripeCheckoutSessionPaymentRequestOutput' }
    & Pick<ResendStripeCheckoutSessionPaymentRequestOutput, 'success'>
  )> }
);

export type ResendVerificationEmailMutationVariables = Exact<{
  account_id: Scalars['String'];
  app_origin: Scalars['String'];
}>;


export type ResendVerificationEmailMutation = (
  { __typename?: 'mutation_root' }
  & { resendVerificationEmail?: Maybe<(
    { __typename?: 'ResendVerificationEmailOutput' }
    & Pick<ResendVerificationEmailOutput, 'success' | 'error'>
  )> }
);

export type SelectDefaultPaymentMethodMutationVariables = Exact<{
  default_payment_method_id: Scalars['String'];
}>;


export type SelectDefaultPaymentMethodMutation = (
  { __typename?: 'mutation_root' }
  & { selectDefaultPaymentMethod?: Maybe<(
    { __typename?: 'SelectDefaultPaymentMethodOutput' }
    & Pick<SelectDefaultPaymentMethodOutput, 'success'>
  )> }
);

export type SendDriverProfileRequestMutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  account_id: Scalars['String'];
}>;


export type SendDriverProfileRequestMutation = (
  { __typename?: 'mutation_root' }
  & { insert_driver_profile?: Maybe<(
    { __typename?: 'driver_profile_mutation_response' }
    & { returning: Array<(
      { __typename?: 'driver_profile' }
      & Pick<Driver_Profile, 'id' | 'status'>
    )> }
  )> }
);

export type SendNewManualReservationEmailToAllDriversMutationVariables = Exact<{
  reservation_id: Scalars['bigint'];
}>;


export type SendNewManualReservationEmailToAllDriversMutation = (
  { __typename?: 'mutation_root' }
  & { sendNewManualReservationEmailToAllDrivers?: Maybe<(
    { __typename?: 'SendNewManualReservationEmailToAllDriversOutput' }
    & Pick<SendNewManualReservationEmailToAllDriversOutput, 'success'>
  )> }
);

export type SendNewManualReservationEmailToPreferredDriverMutationVariables = Exact<{
  reservation_id: Scalars['bigint'];
}>;


export type SendNewManualReservationEmailToPreferredDriverMutation = (
  { __typename?: 'mutation_root' }
  & { sendNewManualReservationEmailToPreferredDriver?: Maybe<(
    { __typename?: 'SendNewManualReservationEmailToPreferredDriverOutput' }
    & Pick<SendNewManualReservationEmailToPreferredDriverOutput, 'success'>
  )> }
);

export type SendPasswordResetRequestMutationVariables = Exact<{
  email: Scalars['String'];
  redirect_uri: Scalars['String'];
}>;


export type SendPasswordResetRequestMutation = (
  { __typename?: 'mutation_root' }
  & { sendPasswordResetRequest?: Maybe<(
    { __typename?: 'PasswordResetOutput' }
    & Pick<PasswordResetOutput, 'success'>
  )> }
);

export type SignInMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SignInMutation = (
  { __typename?: 'mutation_root' }
  & { signIn?: Maybe<(
    { __typename?: 'SignInOutput' }
    & Pick<SignInOutput, 'success'>
  )> }
);

export type SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignOutMutation = (
  { __typename?: 'mutation_root' }
  & { signOut?: Maybe<(
    { __typename?: 'SignOutOutput' }
    & Pick<SignOutOutput, 'success'>
  )> }
);

export type StartReservationMutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  reservation_id: Scalars['uuid'];
  now: Scalars['timestamptz'];
}>;


export type StartReservationMutation = (
  { __typename?: 'mutation_root' }
  & { update_reservation_by_pk?: Maybe<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'id' | 'status' | 'driver_id' | 'started_at'>
  )> }
);

export type SubmitReservationRatingMutationVariables = Exact<{
  reservation_id: Scalars['uuid'];
  rating: Scalars['Int'];
  feedback: Scalars['String'];
}>;


export type SubmitReservationRatingMutation = (
  { __typename?: 'mutation_root' }
  & { submitReservationRating?: Maybe<(
    { __typename?: 'SubmitReservationRatingOutput' }
    & Pick<SubmitReservationRatingOutput, 'success'>
  )> }
);

export type SubmitTrafficSourceMutationVariables = Exact<{
  account_id: Scalars['String'];
  traffic_source: Scalars['String'];
  traffic_source_other_text?: Maybe<Scalars['String']>;
  traffic_source_affiliate_text?: Maybe<Scalars['String']>;
}>;


export type SubmitTrafficSourceMutation = (
  { __typename?: 'mutation_root' }
  & { update_account_feedback?: Maybe<(
    { __typename?: 'account_feedback_mutation_response' }
    & { returning: Array<(
      { __typename?: 'account_feedback' }
      & Pick<Account_Feedback, 'id' | 'account_id' | 'traffic_source' | 'traffic_source_other_text' | 'traffic_source_affiliate_text'>
    )> }
  )> }
);

export type SyncFirebaseAuthenticationUsersWithDbManuallyMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncFirebaseAuthenticationUsersWithDbManuallyMutation = (
  { __typename?: 'mutation_root' }
  & { syncFirebaseAuthenticationUsersWithDbManually?: Maybe<(
    { __typename?: 'SyncFirebaseAuthenticationUsersWithDbManuallyOutput' }
    & Pick<SyncFirebaseAuthenticationUsersWithDbManuallyOutput, 'success'>
  )> }
);

export type TrMutationVariables = Exact<{
  ev: Scalars['String'];
  payload: Scalars['jsonb'];
}>;


export type TrMutation = (
  { __typename?: 'mutation_root' }
  & { tr?: Maybe<(
    { __typename?: 'TrOutput' }
    & Pick<TrOutput, 'success'>
  )> }
);

export type UpdateCitiesOnDriverProfileMutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  account_id: Scalars['String'];
  cities?: Maybe<Scalars['jsonb']>;
}>;


export type UpdateCitiesOnDriverProfileMutation = (
  { __typename?: 'mutation_root' }
  & { update_driver_profile?: Maybe<(
    { __typename?: 'driver_profile_mutation_response' }
    & { returning: Array<(
      { __typename?: 'driver_profile' }
      & Pick<Driver_Profile, 'id' | 'account_id' | 'cities'>
    )> }
  )> }
);

export type UpdateServicesOnDriverProfileMutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  account_id: Scalars['String'];
  services: Scalars['jsonb'];
}>;


export type UpdateServicesOnDriverProfileMutation = (
  { __typename?: 'mutation_root' }
  & { update_driver_profile?: Maybe<(
    { __typename?: 'driver_profile_mutation_response' }
    & { returning: Array<(
      { __typename?: 'driver_profile' }
      & Pick<Driver_Profile, 'id' | 'account_id' | 'services'>
    )> }
  )> }
);

export type UpdateOverdueReservationMutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  reservation_id: Scalars['uuid'];
  driver_suggested_ended_at: Scalars['timestamptz'];
  driver_suggested_started_at: Scalars['timestamptz'];
  extra_costs_value?: Maybe<Scalars['numeric']>;
  extra_costs_text?: Maybe<Scalars['String']>;
}>;


export type UpdateOverdueReservationMutation = (
  { __typename?: 'mutation_root' }
  & { update_reservation_by_pk?: Maybe<(
    { __typename?: 'reservation' }
    & Pick<Reservation, 'id' | 'status' | 'driver_id' | 'driver_suggested_ended_at' | 'driver_suggested_started_at'>
  )> }
);

export type UpdatePersonalDataMutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  account_id?: Maybe<Scalars['String']>;
  personal_data: Scalars['jsonb'];
}>;


export type UpdatePersonalDataMutation = (
  { __typename?: 'mutation_root' }
  & { update_account?: Maybe<(
    { __typename?: 'account_mutation_response' }
    & { returning: Array<(
      { __typename?: 'account' }
      & Pick<Account, 'personal_data'>
    )> }
  )> }
);

export type UpdateReservationBeforeFinish_20210617MutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  reservation_id: Scalars['uuid'];
  ended_at: Scalars['timestamptz'];
  price_billable?: Maybe<Scalars['numeric']>;
  duration_billable?: Maybe<Scalars['numeric']>;
}>;


export type UpdateReservationBeforeFinish_20210617Mutation = (
  { __typename?: 'mutation_root' }
  & { update_reservation?: Maybe<(
    { __typename?: 'reservation_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reservation' }
      & Pick<Reservation, 'id' | 'status' | 'driver_id' | 'ended_at' | 'price_billable' | 'duration_billable'>
    )> }
  )> }
);

export type UpdateReservationBeforeFinish_20210622MutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  reservation_id: Scalars['uuid'];
  ended_at: Scalars['timestamptz'];
  price_billable?: Maybe<Scalars['numeric']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  extra_costs_text?: Maybe<Scalars['String']>;
}>;


export type UpdateReservationBeforeFinish_20210622Mutation = (
  { __typename?: 'mutation_root' }
  & { update_reservation?: Maybe<(
    { __typename?: 'reservation_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reservation' }
      & Pick<Reservation, 'id' | 'status' | 'driver_id' | 'ended_at' | 'price_billable' | 'duration_billable'>
    )> }
  )> }
);

export type UpdateReservationBeforeFinish_20230126MutationVariables = Exact<{
  latest_graphql_operation?: Scalars['String'];
  reservation_id: Scalars['uuid'];
  ended_at: Scalars['timestamptz'];
  price_billable?: Maybe<Scalars['numeric']>;
  duration_billable?: Maybe<Scalars['numeric']>;
  extra_costs_value?: Maybe<Scalars['numeric']>;
  extra_costs_text?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
}>;


export type UpdateReservationBeforeFinish_20230126Mutation = (
  { __typename?: 'mutation_root' }
  & { update_reservation?: Maybe<(
    { __typename?: 'reservation_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reservation' }
      & Pick<Reservation, 'id' | 'status' | 'driver_id' | 'ended_at' | 'price_billable' | 'duration_billable'>
    )> }
  )> }
);

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'mutation_root' }
  & { verifyEmail?: Maybe<(
    { __typename?: 'VerifyEmailOutput' }
    & Pick<VerifyEmailOutput, 'success' | 'error'>
  )> }
);

export type VerifySkillsincloudSsoMutationVariables = Exact<{
  sso: Scalars['String'];
  sig: Scalars['String'];
}>;


export type VerifySkillsincloudSsoMutation = (
  { __typename?: 'mutation_root' }
  & { verifySkillsincloudSSO?: Maybe<(
    { __typename?: 'verifySkillsincloudSSOOutput' }
    & Pick<VerifySkillsincloudSsoOutput, 'url'>
  )> }
);

export type VerifyVoucherQueryVariables = Exact<{
  code: Scalars['String'];
  amount: Scalars['numeric'];
}>;


export type VerifyVoucherQuery = (
  { __typename?: 'query_root' }
  & { verifyVoucher?: Maybe<(
    { __typename?: 'VoucherOutput' }
    & Pick<VoucherOutput, 'total_amount' | 'code' | 'discount'>
  )> }
);


export const CreateDeploymentDocument = gql`
    mutation createDeployment($commit: String!) {
  insert_deployment_one(object: {type: APP, commit: $commit}) {
    id
  }
}
    `;
export const AcceptReservationDocument = gql`
    mutation acceptReservation($latest_graphql_operation: String! = "acceptReservation", $driver_id: String!, $reservation_id: uuid!, $accepted_at: timestamptz!) {
  update_reservation(
    where: {id: {_eq: $reservation_id}, _and: {driver_id: {_is_null: true}}}
    _set: {status: ACCEPTED, driver_id: $driver_id, accepted_at: $accepted_at, latest_graphql_operation: $latest_graphql_operation}
  ) {
    returning {
      id
      status
      driver_id
    }
  }
}
    `;
export const ActivateDriverDocument = gql`
    mutation activateDriver($account_id: String!, $service_list: [String]!, $city_list: [String]!) {
  activateDriver(
    account_id: $account_id
    service_list: $service_list
    city_list: $city_list
  ) {
    status
  }
}
    `;
export const AddPaymentMethodDocument = gql`
    mutation addPaymentMethod($payment_method_id: String!) {
  addPaymentMethod(payment_method_id: $payment_method_id) {
    id
    provider
    snapshot
  }
}
    `;
export const AddServiceToReservationDocument = gql`
    mutation addServiceToReservation($latest_graphql_operation: String! = "addServiceToReservation", $id: uuid!, $service_id: String!) {
  update_reservation_by_pk(
    pk_columns: {id: $id}
    _set: {latest_graphql_operation: $latest_graphql_operation, service_id: $service_id}
  ) {
    id
    service_id
  }
}
    `;
export const CalculateTransactionFeesDocument = gql`
    mutation calculateTransactionFees($input: CalculateTransactionFeesInput) {
  calculateTransactionFees(input: $input) {
    result
  }
}
    `;
export const CancelReservationDocument = gql`
    mutation cancelReservation($reservation_id: uuid!, $cancellation_reason: String) {
  cancelReservation(
    reservation_id: $reservation_id
    cancellation_reason: $cancellation_reason
  ) {
    message
    success
  }
}
    `;
export const ChangeEmailDocument = gql`
    mutation changeEmail($account_id: String!, $email: String!) {
  changeEmail(account_id: $account_id, email: $email) {
    data
  }
}
    `;
export const CheckAuthStatusDocument = gql`
    mutation checkAuthStatus($token: String!) {
  checkAuthStatus(token: $token) {
    token
  }
}
    `;
export const CreateAccount_23082021Document = gql`
    mutation createAccount_23082021($email: String!, $password: String!, $additional_data: jsonb, $personal_data: jsonb) {
  createAccount(
    email: $email
    password: $password
    additional_data: $additional_data
    personal_data: $personal_data
  ) {
    account_id
  }
}
    `;
export const CreateAccount_30042021Document = gql`
    mutation createAccount_30042021($email: String!, $password: String!, $additional_data: jsonb) {
  createAccount(
    email: $email
    password: $password
    additional_data: $additional_data
  ) {
    account_id
  }
}
    `;
export const CreateAccount_30092021Document = gql`
    mutation createAccount_30092021($email: String!, $password: String, $provider_id: String, $additional_data: jsonb, $personal_data: jsonb) {
  createAccount(
    email: $email
    password: $password
    provider_id: $provider_id
    additional_data: $additional_data
    personal_data: $personal_data
  ) {
    account_id
  }
}
    `;
export const CreatePatientProfileForAccountDocument = gql`
    mutation createPatientProfileForAccount($latest_graphql_operation: String! = "createPatientProfileForAccount", $account_id: String!) {
  insert_patient_profile(objects: {account_id: $account_id}) {
    returning {
      id
      role
    }
  }
}
    `;
export const CreateQuoteDocument = gql`
    mutation createQuote($quote: jsonb) {
  createQuote(quote: $quote) {
    reservation_id
  }
}
    `;
export const DeactivateDriverDocument = gql`
    mutation deactivateDriver($account_id: String!) {
  deactivateDriver(account_id: $account_id) {
    success
  }
}
    `;
export const DeclineReservationDueToUnavailabilityDocument = gql`
    mutation declineReservationDueToUnavailability($id: uuid!) {
  update_reservation_by_pk(
    pk_columns: {id: $id}
    _set: {preferred_driver: "", status: DECLINED_BY_PREFERRED_DRIVER}
  ) {
    id
    preferred_driver
    status
  }
  waiting: update_reservation_by_pk(
    pk_columns: {id: $id}
    _set: {status: WAITING}
  ) {
    id
    preferred_driver
    status
  }
}
    `;
export const DriverAcceptReservationChangeDocument = gql`
    mutation driverAcceptReservationChange($reservation_id: uuid) {
  driverAcceptReservationChange(reservation_id: $reservation_id) {
    success
  }
}
    `;
export const DriverRejectReservationChangeDocument = gql`
    mutation driverRejectReservationChange($reservation_id: uuid) {
  driverRejectReservationChange(reservation_id: $reservation_id) {
    success
  }
}
    `;
export const FinishReservationDocument = gql`
    mutation finishReservation($reservation_id: uuid!, $feedback_link: String!) {
  finishReservation(
    reservation_id: $reservation_id
    feedback_link: $feedback_link
  ) {
    success
  }
}
    `;
export const GetAcceptedReservationsByDriverIdDocument = gql`
    query getAcceptedReservationsByDriverId($driver_id: String!, $now: timestamptz!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_eq: ACCEPTED}, starts_at: {_gte: $now}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetAcceptedReservationsByDriverId_20210226Document = gql`
    query getAcceptedReservationsByDriverId_20210226($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_eq: ACCEPTED}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetAcceptedReservationsByDriverId_20210527Document = gql`
    query getAcceptedReservationsByDriverId_20210527($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_eq: ACCEPTED}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
  }
}
    `;
export const GetAcceptedReservationsByDriverId_20221031Document = gql`
    query getAcceptedReservationsByDriverId_20221031($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_eq: ACCEPTED}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    short_id
  }
}
    `;
export const GetAcceptedReservationsByDriverId_20230411Document = gql`
    query getAcceptedReservationsByDriverId_20230411($driver_id: String!, $q: String, $offset: Int, $pageSize: Int) {
  reservation(
    where: {_and: [{driver_id: {_eq: $driver_id}}, {status: {_eq: ACCEPTED}}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    short_id
  }
  reservation_aggregate(
    where: {_and: [{driver_id: {_eq: $driver_id}}, {status: {_eq: ACCEPTED}}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetAcceptedReservationsCountByDriverId_20220930Document = gql`
    query getAcceptedReservationsCountByDriverId_20220930($driver_id: String!) {
  reservation_aggregate(where: {driver_id: {_eq: $driver_id}}) {
    aggregate {
      count
    }
  }
}
    `;
export const GetAccountByEmailDocument = gql`
    query getAccountByEmail($email: String!) {
  account(where: {email: {_eq: $email}}) {
    id
    email
    personal_data
  }
}
    `;
export const GetAccountByIdDocument = gql`
    query getAccountById($id: String!) {
  account_by_pk(id: $id) {
    id
    email
    payment_methods
    personal_data
    voucher {
      code
    }
  }
}
    `;
export const GetAccountDriverDocument = gql`
    query getAccountDriver($account_id: String!) {
  driver_profile(where: {account_id: {_eq: $account_id}}) {
    id
    account_id
    cities
    services
    dof_requested_city
    driver_status {
      value
    }
  }
}
    `;
export const GetAccountFeedbackDocument = gql`
    query getAccountFeedback($account_id: String!) {
  account_feedback(where: {account_id: {_eq: $account_id}}) {
    id
  }
}
    `;
export const GetAccountRegistrationAndFirstReservationDatesDocument = gql`
    query getAccountRegistrationAndFirstReservationDates {
  account {
    email
    created_at
    reservor_reservations(order_by: {created_at: asc}, limit: 1) {
      created_at
    }
  }
}
    `;
export const GetAccountReservationsDocument = gql`
    query getAccountReservations {
  account {
    email
    reservor_reservations {
      id
      ended_at
      status
    }
  }
}
    `;
export const GetAllPatientReservationsDocument = gql`
    query getAllPatientReservations($reservor_id: String) {
  reservation(where: {reservor_id: {_eq: $reservor_id}}) {
    id
  }
}
    `;
export const GetArchivedReservationsByDriverIdDocument = gql`
    query getArchivedReservationsByDriverId($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_nin: [WAITING, ACCEPTED, IN_PROGRESS]}}
    order_by: {starts_at: desc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetArchivedReservationsByDriverId_20210527Document = gql`
    query getArchivedReservationsByDriverId_20210527($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_nin: [WAITING, ACCEPTED, IN_PROGRESS]}}
    order_by: {starts_at: desc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
  }
}
    `;
export const GetArchivedReservationsByDriverId_20220823Document = gql`
    query getArchivedReservationsByDriverId_20220823($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_nin: [WAITING, ACCEPTED, IN_PROGRESS]}}
    order_by: {starts_at: desc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    patient_rating
  }
}
    `;
export const GetArchivedReservationsByDriverId_20221031Document = gql`
    query getArchivedReservationsByDriverId_20221031($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_nin: [WAITING, ACCEPTED, IN_PROGRESS]}}
    order_by: {starts_at: desc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    patient_rating
    short_id
  }
}
    `;
export const GetArchivedReservationsByDriverId_20230411Document = gql`
    query getArchivedReservationsByDriverId_20230411($driver_id: String!, $q: String, $offset: Int, $pageSize: Int) {
  reservation(
    where: {_and: [{driver_id: {_eq: $driver_id}}, {status: {_nin: [WAITING, ACCEPTED, IN_PROGRESS]}}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
    limit: $pageSize
    offset: $offset
    order_by: {starts_at: desc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    patient_rating
    short_id
  }
  reservation_aggregate(
    where: {_and: [{driver_id: {_eq: $driver_id}}, {status: {_nin: [WAITING, ACCEPTED, IN_PROGRESS]}}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetArchivedReservationsByPatientIdDocument = gql`
    query getArchivedReservationsByPatientId($reservor_id: String) {
  reservation(
    where: {reservor_id: {_eq: $reservor_id}, status: {_nin: [WAITING, ACCEPTED]}}
    order_by: {starts_at: desc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetArchivedReservationsByPatientId_20220530Document = gql`
    query getArchivedReservationsByPatientId_20220530($reservor_id: String) {
  reservation(
    where: {reservor_id: {_eq: $reservor_id}, status: {_nin: [WAITING, ACCEPTED]}}
    order_by: {starts_at: desc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    is_manual
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetArchivedReservationsByPatientId_20220823Document = gql`
    query getArchivedReservationsByPatientId_20220823($reservor_id: String) {
  reservation(
    where: {reservor_id: {_eq: $reservor_id}, status: {_nin: [WAITING, ACCEPTED, SHELVED]}}
    order_by: {starts_at: desc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    is_manual
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    driver_rating
    price_estimate
    price_billable
  }
}
    `;
export const GetArchivedReservationsByPatientId_20230411Document = gql`
    query getArchivedReservationsByPatientId_20230411($reservor_id: String, $q: String, $offset: Int, $pageSize: Int) {
  reservation(
    where: {_and: [{reservor_id: {_eq: $reservor_id}}, {status: {_nin: [WAITING, ACCEPTED, SHELVED]}}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
    limit: $pageSize
    offset: $offset
    order_by: {starts_at: desc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    is_manual
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    driver_rating
    price_estimate
    price_billable
  }
  reservation_aggregate(
    where: {_and: [{reservor_id: {_eq: $reservor_id}}, {status: {_nin: [WAITING, ACCEPTED, SHELVED]}}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetAuthProviderDataDocument = gql`
    query getAuthProviderData($where: vw_stats_auth_provider_data_bool_exp) {
  vw_stats_auth_provider_data(where: $where, order_by: {day: asc}) {
    day
    password
    password_converted
    facebook
    facebook_converted
    google
    google_converted
  }
}
    `;
export const GetCardSetupClientSecretDocument = gql`
    query getCardSetupClientSecret {
  getCardSetupClientSecret {
    client_secret
  }
}
    `;
export const GetDailyConversionsByAuthProviderDocument = gql`
    query getDailyConversionsByAuthProvider($where: vw_stats_daily_conversions_by_auth_provider_bool_exp) {
  vw_stats_daily_conversions_by_auth_provider(
    where: $where
    order_by: {converted_at: asc}
  ) {
    converted_at
    accounts_converted
    auth_provider
  }
}
    `;
export const GetDistanceAndDurationDocument = gql`
    query getDistanceAndDuration($departure: LatLngInput!, $destination: LatLngInput!, $city: LatLngInput!) {
  getDistanceAndDuration(
    departure: $departure
    destination: $destination
    city: $city
  ) {
    ab
    a0
    b0
    original_distance_matrix_response
  }
}
    `;
export const GetDistanceAndDuration_25052022Document = gql`
    query getDistanceAndDuration_25052022($departure: LatLngInput!, $destination: LatLngInput!, $city: LatLngInput!) {
  getDistanceAndDuration(
    departure: $departure
    destination: $destination
    city: $city
  ) {
    ab
    a0
    b0
  }
}
    `;
export const GetDistanceAndDuration_26072021Document = gql`
    query getDistanceAndDuration_26072021($departure: LatLngInput!, $destination: LatLngInput!, $city: LatLngInput!) {
  getDistanceAndDuration(
    departure: $departure
    destination: $destination
    city: $city
  ) {
    ab
    a0
    b0
    original_distance_matrix_response
  }
}
    `;
export const GetDistinctReservationVoucherValidationFailsDocument = gql`
    query getDistinctReservationVoucherValidationFails {
  reservation(distinct_on: [voucher_not_applicable_reason]) {
    voucher_not_applicable_reason
  }
}
    `;
export const GetDofDriversDocument = gql`
    query getDofDrivers($start_date: timestamptz!, $end_date: timestamptz!) {
  vw_admin_dof_drivers(where: {created_at: {_gte: $start_date, _lte: $end_date}}) {
    id
    created_at
    dof_requested_city
    is_general_info_submitted
    is_video_verification_submitted
    is_driver_license_verified
    is_id_verified
    logged_in_academy_at_least_once
    is_academy_completed
    dof_approved
    reservation_count
  }
}
    `;
export const GetDriverProfileByAccountIdDocument = gql`
    query getDriverProfileByAccountId($account_id: String!) {
  driver_profile(where: {account_id: {_eq: $account_id}}) {
    id
    status
    services
    cities
    is_covid_passport_submitted
    is_covid_passport_approved
    is_general_info_submitted
  }
}
    `;
export const GetDuplicatedReservationsDocument = gql`
    query getDuplicatedReservations($where: reservation_bool_exp = {duplication_reference_id: {_is_null: false}}) {
  reservation(where: $where) {
    id
    reservor {
      id
      email
      personal_data
    }
    price_estimate
    starts_at
    created_at
  }
  reservation_aggregate(where: $where) {
    aggregate {
      count
      sum {
        price_estimate
      }
    }
  }
}
    `;
export const GetExcludedAccountsDocument = gql`
    query getExcludedAccounts($_nin: [String!] = []) {
  account(where: {email: {_nin: $_nin}}) {
    email
    driver_profile {
      account_id
    }
  }
}
    `;
export const GetFirebaseUserDetailsDocument = gql`
    query getFirebaseUserDetails($user_id: String!) {
  getFirebaseUserDetails(user_id: $user_id) {
    firebase_user
  }
}
    `;
export const GetFutureReservationsByPatientDocument = gql`
    query getFutureReservationsByPatient($now: timestamptz!, $reservor_id: String) {
  reservation(
    where: {reservor_id: {_eq: $reservor_id}, starts_at: {_gte: $now}, status: {_in: [WAITING, ACCEPTED]}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetFutureReservationsByPatientId_20220530Document = gql`
    query getFutureReservationsByPatientId_20220530($now: timestamptz!, $reservor_id: String) {
  reservation(
    where: {reservor_id: {_eq: $reservor_id}, starts_at: {_gte: $now}, status: {_in: [WAITING, ACCEPTED, SHELVED]}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    is_manual
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetFutureReservationsByPatientId_20230405Document = gql`
    query getFutureReservationsByPatientId_20230405($now: timestamptz!, $reservor_id: String, $q: String, $offset: Int, $pageSize: Int) {
  reservation(
    where: {_and: [{reservor_id: {_eq: $reservor_id}}, {starts_at: {_gte: $now}}, {status: {_in: [WAITING, ACCEPTED, SHELVED]}}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
    limit: $pageSize
    offset: $offset
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    is_manual
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
  reservation_aggregate(
    where: {_and: [{reservor_id: {_eq: $reservor_id}}, {starts_at: {_gte: $now}}, {status: {_in: [WAITING, ACCEPTED, SHELVED]}}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetGeneralUserStatsDocument = gql`
    query getGeneralUserStats($start_date: timestamptz, $end_date: timestamptz) {
  fn_stats_get_general_user(
    args: {_start_date: $start_date, _end_date: $end_date}
  ) {
    service_count
    active_customer_count
    services_per_customer
    repeating_customer_count
    new_customer_count
    new_user_count
    avg_service_cost
    new_customer_service_count
    avg_lt_days
    avg_ltv
  }
}
    `;
export const GetInProgressReservationsByDriverIdDocument = gql`
    query getInProgressReservationsByDriverId($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_eq: IN_PROGRESS}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetLastReservationByPatientIdAndServiceIdDocument = gql`
    query getLastReservationByPatientIdAndServiceId($reservor_id: String!, $service_id: String!) {
  reservation(
    where: {reservor_id: {_eq: $reservor_id}, service_id: {_eq: $service_id}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    metadata
  }
}
    `;
export const GetLoginTokenDocument = gql`
    query getLoginToken {
  getLoginToken {
    token
  }
}
    `;
export const GetOverdueReservationsByDriverIdDocument = gql`
    query getOverdueReservationsByDriverId($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, status: {_eq: FINISH_RESERVATION_OVERDUE}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    driver_suggested_ended_at
    driver_suggested_started_at
  }
}
    `;
export const GetParetoDistributionForAccountsVsReservationsDocument = gql`
    query getParetoDistributionForAccountsVsReservations($min_reservation_count: Int!) {
  fn_stats_get_pareto_distribution_account_reservation(
    args: {min_reservation_count: $min_reservation_count}
  ) {
    id
    email
    accounts_percentage
    reservations_percentage
    cumulative_accounts_percentage
    cumulative_reservations_percentage
    price_estimate_average
    price_estimates
    reservation_count
  }
}
    `;
export const GetPatientConversionReservationDocument = gql`
    query getPatientConversionReservation($where: vw_stats_patient_conversion_reservation_bool_exp) {
  vw_stats_patient_conversion_reservation(
    where: $where
    order_by: {created_at: asc}
  ) {
    id
    created_at
    traffic_source
    service_city
    reservation_metadata
    is_manual
  }
}
    `;
export const GetPatientProfileByAccountIdDocument = gql`
    query getPatientProfileByAccountId($account_id: String!) {
  patient_profile(where: {account_id: {_eq: $account_id}}) {
    id
    role
  }
}
    `;
export const GetPatientsByConversionStatusDocument = gql`
    query getPatientsByConversionStatus($where: reservation_bool_exp) {
  reservation_aggregate(
    where: $where
    distinct_on: reservor_id
    order_by: {reservor_id: asc, created_at: asc}
  ) {
    aggregate {
      count
    }
  }
  reservation(
    where: $where
    distinct_on: reservor_id
    order_by: {reservor_id: asc, created_at: asc}
  ) {
    id
    created_at
    starts_at
    ends_at
    reservor {
      email
      created_at
      account_feedback {
        traffic_source
      }
    }
    metadata
    status
  }
}
    `;
export const GetPatientsByPartialEmailOrNameDocument = gql`
    query getPatientsByPartialEmailOrName($query: String!) {
  vw_admin_account(
    where: {_or: [{email: {_ilike: $query}}, {full_name: {_ilike: $query}}], _and: [{is_driver: {_neq: true}}, {_or: [{is_driver: {_eq: null}}, {is_driver: {_eq: false}}]}]}
  ) {
    id
    email
    personal_data
  }
}
    `;
export const GetPaymentFailedReservationsByPatientIdDocument = gql`
    query getPaymentFailedReservationsByPatientId($patient_id: String!) {
  reservation(
    where: {reservor_id: {_eq: $patient_id}, status: {_eq: PAYMENT_FAILED}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetReservationByIdDocument = gql`
    query getReservationById($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetReservationById_20210610Document = gql`
    query getReservationById_20210610($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
  }
}
    `;
export const GetReservationById_20210622Document = gql`
    query getReservationById_20210622($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    extra_costs_value
    extra_costs_text
  }
}
    `;
export const GetReservationById_20210707Document = gql`
    query getReservationById_20210707($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    voucher_redemption_id
    extra_costs_value
    extra_costs_text
  }
}
    `;
export const GetReservationById_20210520Document = gql`
    query getReservationById_20210520($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    voucher_redemption_id
    extra_costs_value
    extra_costs_text
    is_manual
  }
}
    `;
export const GetReservationById_20220531Document = gql`
    query getReservationById_20220531($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    voucher_redemption_id
    extra_costs_value
    extra_costs_text
    price_estimate_transaction_fees_driver_amount
    price_billable_transaction_fees_driver_amount
  }
}
    `;
export const GetReservationById_20220802Document = gql`
    query getReservationById_20220802($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    voucher_redemption_id
    extra_costs_value
    extra_costs_text
    price_estimate_transaction_fees_driver_amount
    price_billable_transaction_fees_driver_amount
    driver_suggested_started_at
    driver_suggested_ended_at
  }
}
    `;
export const GetReservationById_Administrator_20220601Document = gql`
    query getReservationById_administrator_20220601($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    voucher_redemption_id
    extra_costs_value
    extra_costs_text
    price_estimate_transaction_fees
    price_billable_transaction_fees
  }
}
    `;
export const GetReservationById_Driver_20220823Document = gql`
    query getReservationById_driver_20220823($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    voucher_redemption_id
    extra_costs_value
    patient_rating
    extra_costs_text
  }
}
    `;
export const GetReservationById_Driver_20221031Document = gql`
    query getReservationById_driver_20221031($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    voucher_redemption_id
    extra_costs_value
    extra_costs_text
    price_estimate_transaction_fees_driver_amount
    price_billable_transaction_fees_driver_amount
    driver_suggested_started_at
    driver_suggested_ended_at
    patient_rating
    short_id
    change_request_changes
    change_request_driver_action
  }
}
    `;
export const GetReservationById_Patient_20220823Document = gql`
    query getReservationById_patient_20220823($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    voucher_redemption_id
    extra_costs_value
    driver_rating
    extra_costs_text
  }
}
    `;
export const GetReservationById_Patient_20221018Document = gql`
    query getReservationById_patient_20221018($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    preferred_driver
    created_at
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    duration_estimate
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
    voucher_redemption_id
    extra_costs_value
    extra_costs_text
    change_request_driver_action
  }
}
    `;
export const GetReservationByIdSubDocument = gql`
    subscription getReservationByIdSub($id: uuid!) {
  reservation_by_pk(id: $id) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetReservationFrequencyByPatientAccountsDocument = gql`
    query getReservationFrequencyByPatientAccounts {
  vw_stats_reservation_frequency_by_patient_accounts {
    account_id
    email
    reservation_count
    days_since_first_reservation
  }
}
    `;
export const GetReservationsByPatientIdDocument = gql`
    query getReservationsByPatientId($reservor_id: String!) {
  reservation(where: {reservor_id: {_eq: $reservor_id}}) {
    __typename
    id
    driver {
      id
      email
      personal_data
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetReservationsCountByReservorId_20221001Document = gql`
    query getReservationsCountByReservorId_20221001($reservor_id: String!) {
  reservation_aggregate(where: {reservor_id: {_eq: $reservor_id}}) {
    aggregate {
      count
    }
  }
}
    `;
export const GetReservationsWithChangeRequestByDriverIdDocument = gql`
    query getReservationsWithChangeRequestByDriverId($driver_id: String!) {
  reservation(
    where: {driver_id: {_eq: $driver_id}, change_request_driver_action: {_is_null: true}, change_request_changes: {_is_null: false}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    change_request_changes
    change_request_driver_action
  }
}
    `;
export const GetServicesDocument = gql`
    query getServices {
  service(where: {active: {_eq: true}}) {
    id
    requirements
    type
    translations
  }
}
    `;
export const GetSyncsInProgressCountSubDocument = gql`
    subscription getSyncsInProgressCountSub {
  sync_aggregate(where: {is_sync_in_progress: {_eq: true}}) {
    aggregate {
      count
    }
  }
}
    `;
export const GetSyncsSubDocument = gql`
    subscription getSyncsSub {
  sync {
    id
    is_sync_in_progress
    synced_at
  }
}
    `;
export const GetTrafficSourceByAccountIdDocument = gql`
    query getTrafficSourceByAccountId($account_id: String!) {
  account_feedback(where: {account_id: {_eq: $account_id}}) {
    traffic_source
  }
}
    `;
export const GetUserStatisticsDocument = gql`
    query GetUserStatistics {
  getUserStatistics {
    total
    admins
    patients
    drivers
    patientsActivated
    driversActivated
    totalActivated
  }
}
    `;
export const GetVoucherUseStatsDocument = gql`
    query getVoucherUseStats($start_date: timestamptz, $end_date: timestamptz) {
  fn_stats_get_voucher_uses(
    args: {_start_date: $start_date, _end_date: $end_date}
  ) {
    voucher_code
    total_uses
    unique_user_uses
    new_user_uses
    repeating_user_uses
  }
}
    `;
export const GetWaitingReservationsDocument = gql`
    query getWaitingReservations($cities_expression_array: [reservation_bool_exp!], $services: [String!], $now: timestamptz!) {
  reservation(
    where: {status: {_eq: WAITING}, service_id: {_in: $services}, starts_at: {_gte: $now}, _and: {_or: $cities_expression_array}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
  }
}
    `;
export const GetWaitingReservations_20210527Document = gql`
    query getWaitingReservations_20210527($cities_expression_array: [reservation_bool_exp!], $services: [String!], $now: timestamptz!) {
  reservation(
    where: {status: {_eq: WAITING}, service_id: {_in: $services}, starts_at: {_gte: $now}, _and: {_or: $cities_expression_array}}
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
  }
}
    `;
export const GetWaitingReservations_20230411Document = gql`
    query getWaitingReservations_20230411($cities_expression_array: [reservation_bool_exp!], $services: [String!], $now: timestamptz!, $q: String, $offset: Int, $pageSize: Int) {
  reservation(
    where: {_and: [{status: {_eq: WAITING}}, {service_id: {_in: $services}}, {starts_at: {_gte: $now}}, {_or: $cities_expression_array}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
    limit: $pageSize
    offset: $offset
    order_by: {starts_at: asc_nulls_last}
  ) {
    __typename
    id
    driver {
      id
      email
    }
    reservor {
      id
      email
      personal_data
    }
    reservor_metadata
    reservee {
      id
      email
    }
    reservee_metadata
    payments {
      paid
    }
    service_id
    status
    starts_at
    ends_at
    started_at
    ended_at
    metadata
    currency
    price_estimate
    price_billable
    price_estimate_discounted
    price_billable_discounted
    voucher_code
    voucher_not_applicable_reason
  }
  reservation_aggregate(
    where: {_and: [{status: {_eq: WAITING}}, {service_id: {_in: $services}}, {starts_at: {_gte: $now}}, {_or: $cities_expression_array}, {_or: [{driver: {email: {_ilike: $q}}}, {short_id: {_ilike: $q}}, {metadata: {_cast: {String: {_ilike: $q}}}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const IsDriverAvailableDocument = gql`
    query isDriverAvailable($from_timestamptz: timestamptz!, $to_timestamptz: timestamptz!, $driver_id: String!) {
  reservation_aggregate(
    where: {_or: [{starts_at: {_gte: $from_timestamptz, _lte: $to_timestamptz}}, {ends_at: {_gte: $from_timestamptz, _lte: $to_timestamptz}}], status: {_in: [ACCEPTED, IN_PROGRESS]}, driver_id: {_eq: $driver_id}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const IsDriverAvailable_20220810Document = gql`
    query isDriverAvailable_20220810($from_timestamptz: timestamptz!, $to_timestamptz: timestamptz!) {
  isDriverAvailable(from: $from_timestamptz, to: $to_timestamptz) {
    is_driver_available
  }
}
    `;
export const IsDriverFullyOnboardedToStripeDocument = gql`
    query isDriverFullyOnboardedToStripe {
  isDriverFullyOnboardedToStripe {
    is_fully_onboarded
  }
}
    `;
export const IsNewerAppVersionAvailableDocument = gql`
    subscription isNewerAppVersionAvailable($date: timestamptz!) {
  deployment_aggregate(where: {type: {_eq: APP}, created_at: {_gt: $date}}) {
    aggregate {
      count
    }
  }
}
    `;
export const IsPatientAvailableDocument = gql`
    query isPatientAvailable($from_timestamptz: timestamptz!, $to_timestamptz: timestamptz!, $reservor_id: String!) {
  reservation_aggregate(
    where: {_or: [{starts_at: {_gte: $from_timestamptz, _lte: $to_timestamptz}}, {ends_at: {_gte: $from_timestamptz, _lte: $to_timestamptz}}], status: {_in: [ACCEPTED, IN_PROGRESS, WAITING]}, reservor_id: {_eq: $reservor_id}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const AcceptManualReservationDocument = gql`
    mutation acceptManualReservation($token: String!) {
  acceptManualReservation(token: $token) {
    success
    error
  }
}
    `;
export const DeclineManualReservationDocument = gql`
    mutation declineManualReservation($token: String!) {
  declineManualReservation(token: $token) {
    success
    error
  }
}
    `;
export const OnboardDriverToStripeDocument = gql`
    mutation onboardDriverToStripe($origin: String!) {
  onboardDriverToStripe(origin: $origin) {
    account_link
  }
}
    `;
export const PatientCancelReservationDocument = gql`
    mutation patientCancelReservation($reservation_id: uuid!, $cancellation_reason: String) {
  patientCancelReservation(
    reservation_id: $reservation_id
    cancellation_reason: $cancellation_reason
  ) {
    success
    message
  }
}
    `;
export const PatientRequestReservationChangeDocument = gql`
    mutation patientRequestReservationChange($reservation_id: uuid, $changes: jsonb) {
  patientRequestReservationChange(
    reservation_id: $reservation_id
    changes: $changes
  ) {
    success
  }
}
    `;
export const RemovePaymentMethodDocument = gql`
    mutation removePaymentMethod($payment_method_id: String!) {
  removePaymentMethod(payment_method_id: $payment_method_id) {
    payment_method_id
  }
}
    `;
export const RequestReservationChangeDocument = gql`
    mutation requestReservationChange($reservation_id: uuid!, $changes: jsonb) {
  update_reservation_by_pk(
    pk_columns: {id: $reservation_id}
    _set: {change_request_changes: $changes}
  ) {
    id
  }
}
    `;
export const RequestLoginTokenDocument = gql`
    query requestLoginToken($token: String!) {
  requestLoginToken(token: $token) {
    token
  }
}
    `;
export const ResendStripeCheckoutSessionPaymentRequestDocument = gql`
    mutation resendStripeCheckoutSessionPaymentRequest($reservation_id: uuid!) {
  resendStripeCheckoutSessionPaymentRequest(reservation_id: $reservation_id) {
    success
  }
}
    `;
export const ResendVerificationEmailDocument = gql`
    mutation resendVerificationEmail($account_id: String!, $app_origin: String!) {
  resendVerificationEmail(account_id: $account_id, app_origin: $app_origin) {
    success
    error
  }
}
    `;
export const SelectDefaultPaymentMethodDocument = gql`
    mutation selectDefaultPaymentMethod($default_payment_method_id: String!) {
  selectDefaultPaymentMethod(
    default_payment_method_id: $default_payment_method_id
  ) {
    success
  }
}
    `;
export const SendDriverProfileRequestDocument = gql`
    mutation sendDriverProfileRequest($latest_graphql_operation: String! = "sendDriverProfileRequest", $account_id: String!) {
  insert_driver_profile(objects: {account_id: $account_id, status: REQUESTED}) {
    returning {
      id
      status
    }
  }
}
    `;
export const SendNewManualReservationEmailToAllDriversDocument = gql`
    mutation sendNewManualReservationEmailToAllDrivers($reservation_id: bigint!) {
  sendNewManualReservationEmailToAllDrivers(reservation_id: $reservation_id) {
    success
  }
}
    `;
export const SendNewManualReservationEmailToPreferredDriverDocument = gql`
    mutation sendNewManualReservationEmailToPreferredDriver($reservation_id: bigint!) {
  sendNewManualReservationEmailToPreferredDriver(reservation_id: $reservation_id) {
    success
  }
}
    `;
export const SendPasswordResetRequestDocument = gql`
    mutation sendPasswordResetRequest($email: String!, $redirect_uri: String!) {
  sendPasswordResetRequest(email: $email, redirect_uri: $redirect_uri) {
    success
  }
}
    `;
export const SignInDocument = gql`
    mutation signIn($token: String!) {
  signIn(token: $token) {
    success
  }
}
    `;
export const SignOutDocument = gql`
    mutation signOut {
  signOut {
    success
  }
}
    `;
export const StartReservationDocument = gql`
    mutation startReservation($latest_graphql_operation: String! = "startReservation", $reservation_id: uuid!, $now: timestamptz!) {
  update_reservation_by_pk(
    pk_columns: {id: $reservation_id}
    _set: {latest_graphql_operation: $latest_graphql_operation, status: IN_PROGRESS, started_at: $now}
  ) {
    id
    status
    driver_id
    started_at
  }
}
    `;
export const SubmitReservationRatingDocument = gql`
    mutation submitReservationRating($reservation_id: uuid!, $rating: Int!, $feedback: String!) {
  submitReservationRating(
    reservation_id: $reservation_id
    rating: $rating
    feedback: $feedback
  ) {
    success
  }
}
    `;
export const SubmitTrafficSourceDocument = gql`
    mutation submitTrafficSource($account_id: String!, $traffic_source: String!, $traffic_source_other_text: String, $traffic_source_affiliate_text: String) {
  update_account_feedback(
    where: {account_id: {_eq: $account_id}}
    _set: {traffic_source: $traffic_source, traffic_source_other_text: $traffic_source_other_text, traffic_source_affiliate_text: $traffic_source_affiliate_text}
  ) {
    returning {
      id
      account_id
      traffic_source
      traffic_source_other_text
      traffic_source_affiliate_text
    }
  }
}
    `;
export const SyncFirebaseAuthenticationUsersWithDbManuallyDocument = gql`
    mutation syncFirebaseAuthenticationUsersWithDbManually {
  syncFirebaseAuthenticationUsersWithDbManually {
    success
  }
}
    `;
export const TrDocument = gql`
    mutation tr($ev: String!, $payload: jsonb!) {
  tr(ev: $ev, payload: $payload) {
    success
  }
}
    `;
export const UpdateCitiesOnDriverProfileDocument = gql`
    mutation updateCitiesOnDriverProfile($latest_graphql_operation: String! = "updateCitiesOnDriverProfile", $account_id: String!, $cities: jsonb) {
  update_driver_profile(
    where: {account_id: {_eq: $account_id}}
    _set: {cities: $cities}
  ) {
    returning {
      id
      account_id
      cities
    }
  }
}
    `;
export const UpdateServicesOnDriverProfileDocument = gql`
    mutation updateServicesOnDriverProfile($latest_graphql_operation: String! = "updateServicesOnDriverProfile", $account_id: String!, $services: jsonb!) {
  update_driver_profile(
    where: {account_id: {_eq: $account_id}}
    _set: {services: $services}
  ) {
    returning {
      id
      account_id
      services
    }
  }
}
    `;
export const UpdateOverdueReservationDocument = gql`
    mutation updateOverdueReservation($latest_graphql_operation: String! = "updateOverdueReservation", $reservation_id: uuid!, $driver_suggested_ended_at: timestamptz!, $driver_suggested_started_at: timestamptz!, $extra_costs_value: numeric, $extra_costs_text: String) {
  update_reservation_by_pk(
    pk_columns: {id: $reservation_id}
    _set: {latest_graphql_operation: $latest_graphql_operation, driver_suggested_ended_at: $driver_suggested_ended_at, driver_suggested_started_at: $driver_suggested_started_at, extra_costs_value: $extra_costs_value, extra_costs_text: $extra_costs_text}
  ) {
    id
    status
    driver_id
    driver_suggested_ended_at
    driver_suggested_started_at
  }
}
    `;
export const UpdatePersonalDataDocument = gql`
    mutation updatePersonalData($latest_graphql_operation: String! = "updatePersonalData", $account_id: String, $personal_data: jsonb!) {
  update_account(
    where: {id: {_eq: $account_id}}
    _set: {latest_graphql_operation: $latest_graphql_operation, personal_data: $personal_data}
  ) {
    returning {
      personal_data
    }
  }
}
    `;
export const UpdateReservationBeforeFinish_20210617Document = gql`
    mutation updateReservationBeforeFinish_20210617($latest_graphql_operation: String! = "updateReservationBeforeFinish_20210617", $reservation_id: uuid!, $ended_at: timestamptz!, $price_billable: numeric, $duration_billable: numeric) {
  update_reservation(
    where: {_and: {id: {_eq: $reservation_id}, ended_at: {_is_null: true}, status: {_eq: IN_PROGRESS}}}
    _set: {latest_graphql_operation: $latest_graphql_operation, ended_at: $ended_at, price_billable: $price_billable, duration_billable: $duration_billable}
  ) {
    returning {
      id
      status
      driver_id
      ended_at
      price_billable
      duration_billable
    }
  }
}
    `;
export const UpdateReservationBeforeFinish_20210622Document = gql`
    mutation updateReservationBeforeFinish_20210622($latest_graphql_operation: String! = "updateReservationBeforeFinish_20210622", $reservation_id: uuid!, $ended_at: timestamptz!, $price_billable: numeric, $duration_billable: numeric, $extra_costs_value: numeric, $extra_costs_text: String) {
  update_reservation(
    where: {_and: {id: {_eq: $reservation_id}, ended_at: {_is_null: true}, status: {_eq: IN_PROGRESS}}}
    _set: {latest_graphql_operation: $latest_graphql_operation, ended_at: $ended_at, price_billable: $price_billable, duration_billable: $duration_billable, extra_costs_value: $extra_costs_value, extra_costs_text: $extra_costs_text}
  ) {
    returning {
      id
      status
      driver_id
      ended_at
      price_billable
      duration_billable
    }
  }
}
    `;
export const UpdateReservationBeforeFinish_20230126Document = gql`
    mutation updateReservationBeforeFinish_20230126($latest_graphql_operation: String! = "updateReservationBeforeFinish_20230126", $reservation_id: uuid!, $ended_at: timestamptz!, $price_billable: numeric, $duration_billable: numeric, $extra_costs_value: numeric, $extra_costs_text: String, $metadata: jsonb) {
  update_reservation(
    where: {_and: {id: {_eq: $reservation_id}, ended_at: {_is_null: true}, status: {_eq: IN_PROGRESS}}}
    _set: {latest_graphql_operation: $latest_graphql_operation, ended_at: $ended_at, price_billable: $price_billable, duration_billable: $duration_billable, extra_costs_value: $extra_costs_value, extra_costs_text: $extra_costs_text, metadata: $metadata}
  ) {
    returning {
      id
      status
      driver_id
      ended_at
      price_billable
      duration_billable
    }
  }
}
    `;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($token: String!) {
  verifyEmail(token: $token) {
    success
    error
  }
}
    `;
export const VerifySkillsincloudSsoDocument = gql`
    mutation verifySkillsincloudSSO($sso: String!, $sig: String!) {
  verifySkillsincloudSSO(sig: $sig, sso: $sso) {
    url
  }
}
    `;
export const VerifyVoucherDocument = gql`
    query verifyVoucher($code: String!, $amount: numeric!) {
  verifyVoucher(code: $code, amount: $amount) {
    total_amount
    code
    discount
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createDeployment(variables: CreateDeploymentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDeploymentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDeploymentMutation>(CreateDeploymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createDeployment');
    },
    acceptReservation(variables: AcceptReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AcceptReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AcceptReservationMutation>(AcceptReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'acceptReservation');
    },
    activateDriver(variables: ActivateDriverMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ActivateDriverMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ActivateDriverMutation>(ActivateDriverDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'activateDriver');
    },
    addPaymentMethod(variables: AddPaymentMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddPaymentMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddPaymentMethodMutation>(AddPaymentMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addPaymentMethod');
    },
    addServiceToReservation(variables: AddServiceToReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddServiceToReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddServiceToReservationMutation>(AddServiceToReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'addServiceToReservation');
    },
    calculateTransactionFees(variables?: CalculateTransactionFeesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CalculateTransactionFeesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CalculateTransactionFeesMutation>(CalculateTransactionFeesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'calculateTransactionFees');
    },
    cancelReservation(variables: CancelReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelReservationMutation>(CancelReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelReservation');
    },
    changeEmail(variables: ChangeEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ChangeEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChangeEmailMutation>(ChangeEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'changeEmail');
    },
    checkAuthStatus(variables: CheckAuthStatusMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CheckAuthStatusMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CheckAuthStatusMutation>(CheckAuthStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'checkAuthStatus');
    },
    createAccount_23082021(variables: CreateAccount_23082021MutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAccount_23082021Mutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAccount_23082021Mutation>(CreateAccount_23082021Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAccount_23082021');
    },
    createAccount_30042021(variables: CreateAccount_30042021MutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAccount_30042021Mutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAccount_30042021Mutation>(CreateAccount_30042021Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAccount_30042021');
    },
    createAccount_30092021(variables: CreateAccount_30092021MutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAccount_30092021Mutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAccount_30092021Mutation>(CreateAccount_30092021Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAccount_30092021');
    },
    createPatientProfileForAccount(variables: CreatePatientProfileForAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreatePatientProfileForAccountMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreatePatientProfileForAccountMutation>(CreatePatientProfileForAccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createPatientProfileForAccount');
    },
    createQuote(variables?: CreateQuoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateQuoteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateQuoteMutation>(CreateQuoteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createQuote');
    },
    deactivateDriver(variables: DeactivateDriverMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeactivateDriverMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeactivateDriverMutation>(DeactivateDriverDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deactivateDriver');
    },
    declineReservationDueToUnavailability(variables: DeclineReservationDueToUnavailabilityMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeclineReservationDueToUnavailabilityMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeclineReservationDueToUnavailabilityMutation>(DeclineReservationDueToUnavailabilityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'declineReservationDueToUnavailability');
    },
    driverAcceptReservationChange(variables?: DriverAcceptReservationChangeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DriverAcceptReservationChangeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DriverAcceptReservationChangeMutation>(DriverAcceptReservationChangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'driverAcceptReservationChange');
    },
    driverRejectReservationChange(variables?: DriverRejectReservationChangeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DriverRejectReservationChangeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DriverRejectReservationChangeMutation>(DriverRejectReservationChangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'driverRejectReservationChange');
    },
    finishReservation(variables: FinishReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FinishReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<FinishReservationMutation>(FinishReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'finishReservation');
    },
    getAcceptedReservationsByDriverId(variables: GetAcceptedReservationsByDriverIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAcceptedReservationsByDriverIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAcceptedReservationsByDriverIdQuery>(GetAcceptedReservationsByDriverIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAcceptedReservationsByDriverId');
    },
    getAcceptedReservationsByDriverId_20210226(variables: GetAcceptedReservationsByDriverId_20210226QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAcceptedReservationsByDriverId_20210226Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAcceptedReservationsByDriverId_20210226Query>(GetAcceptedReservationsByDriverId_20210226Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAcceptedReservationsByDriverId_20210226');
    },
    getAcceptedReservationsByDriverId_20210527(variables: GetAcceptedReservationsByDriverId_20210527QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAcceptedReservationsByDriverId_20210527Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAcceptedReservationsByDriverId_20210527Query>(GetAcceptedReservationsByDriverId_20210527Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAcceptedReservationsByDriverId_20210527');
    },
    getAcceptedReservationsByDriverId_20221031(variables: GetAcceptedReservationsByDriverId_20221031QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAcceptedReservationsByDriverId_20221031Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAcceptedReservationsByDriverId_20221031Query>(GetAcceptedReservationsByDriverId_20221031Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAcceptedReservationsByDriverId_20221031');
    },
    getAcceptedReservationsByDriverId_20230411(variables: GetAcceptedReservationsByDriverId_20230411QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAcceptedReservationsByDriverId_20230411Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAcceptedReservationsByDriverId_20230411Query>(GetAcceptedReservationsByDriverId_20230411Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAcceptedReservationsByDriverId_20230411');
    },
    getAcceptedReservationsCountByDriverId_20220930(variables: GetAcceptedReservationsCountByDriverId_20220930QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAcceptedReservationsCountByDriverId_20220930Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAcceptedReservationsCountByDriverId_20220930Query>(GetAcceptedReservationsCountByDriverId_20220930Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAcceptedReservationsCountByDriverId_20220930');
    },
    getAccountByEmail(variables: GetAccountByEmailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAccountByEmailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAccountByEmailQuery>(GetAccountByEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountByEmail');
    },
    getAccountById(variables: GetAccountByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAccountByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAccountByIdQuery>(GetAccountByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountById');
    },
    getAccountDriver(variables: GetAccountDriverQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAccountDriverQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAccountDriverQuery>(GetAccountDriverDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountDriver');
    },
    getAccountFeedback(variables: GetAccountFeedbackQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAccountFeedbackQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAccountFeedbackQuery>(GetAccountFeedbackDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountFeedback');
    },
    getAccountRegistrationAndFirstReservationDates(variables?: GetAccountRegistrationAndFirstReservationDatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAccountRegistrationAndFirstReservationDatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAccountRegistrationAndFirstReservationDatesQuery>(GetAccountRegistrationAndFirstReservationDatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountRegistrationAndFirstReservationDates');
    },
    getAccountReservations(variables?: GetAccountReservationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAccountReservationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAccountReservationsQuery>(GetAccountReservationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountReservations');
    },
    getAllPatientReservations(variables?: GetAllPatientReservationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllPatientReservationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAllPatientReservationsQuery>(GetAllPatientReservationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllPatientReservations');
    },
    getArchivedReservationsByDriverId(variables: GetArchivedReservationsByDriverIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetArchivedReservationsByDriverIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArchivedReservationsByDriverIdQuery>(GetArchivedReservationsByDriverIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArchivedReservationsByDriverId');
    },
    getArchivedReservationsByDriverId_20210527(variables: GetArchivedReservationsByDriverId_20210527QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetArchivedReservationsByDriverId_20210527Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArchivedReservationsByDriverId_20210527Query>(GetArchivedReservationsByDriverId_20210527Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArchivedReservationsByDriverId_20210527');
    },
    getArchivedReservationsByDriverId_20220823(variables: GetArchivedReservationsByDriverId_20220823QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetArchivedReservationsByDriverId_20220823Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArchivedReservationsByDriverId_20220823Query>(GetArchivedReservationsByDriverId_20220823Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArchivedReservationsByDriverId_20220823');
    },
    getArchivedReservationsByDriverId_20221031(variables: GetArchivedReservationsByDriverId_20221031QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetArchivedReservationsByDriverId_20221031Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArchivedReservationsByDriverId_20221031Query>(GetArchivedReservationsByDriverId_20221031Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArchivedReservationsByDriverId_20221031');
    },
    getArchivedReservationsByDriverId_20230411(variables: GetArchivedReservationsByDriverId_20230411QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetArchivedReservationsByDriverId_20230411Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArchivedReservationsByDriverId_20230411Query>(GetArchivedReservationsByDriverId_20230411Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArchivedReservationsByDriverId_20230411');
    },
    getArchivedReservationsByPatientId(variables?: GetArchivedReservationsByPatientIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetArchivedReservationsByPatientIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArchivedReservationsByPatientIdQuery>(GetArchivedReservationsByPatientIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArchivedReservationsByPatientId');
    },
    getArchivedReservationsByPatientId_20220530(variables?: GetArchivedReservationsByPatientId_20220530QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetArchivedReservationsByPatientId_20220530Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArchivedReservationsByPatientId_20220530Query>(GetArchivedReservationsByPatientId_20220530Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArchivedReservationsByPatientId_20220530');
    },
    getArchivedReservationsByPatientId_20220823(variables?: GetArchivedReservationsByPatientId_20220823QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetArchivedReservationsByPatientId_20220823Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArchivedReservationsByPatientId_20220823Query>(GetArchivedReservationsByPatientId_20220823Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArchivedReservationsByPatientId_20220823');
    },
    getArchivedReservationsByPatientId_20230411(variables?: GetArchivedReservationsByPatientId_20230411QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetArchivedReservationsByPatientId_20230411Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArchivedReservationsByPatientId_20230411Query>(GetArchivedReservationsByPatientId_20230411Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArchivedReservationsByPatientId_20230411');
    },
    getAuthProviderData(variables?: GetAuthProviderDataQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAuthProviderDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAuthProviderDataQuery>(GetAuthProviderDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAuthProviderData');
    },
    getCardSetupClientSecret(variables?: GetCardSetupClientSecretQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCardSetupClientSecretQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCardSetupClientSecretQuery>(GetCardSetupClientSecretDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCardSetupClientSecret');
    },
    getDailyConversionsByAuthProvider(variables?: GetDailyConversionsByAuthProviderQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDailyConversionsByAuthProviderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDailyConversionsByAuthProviderQuery>(GetDailyConversionsByAuthProviderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDailyConversionsByAuthProvider');
    },
    getDistanceAndDuration(variables: GetDistanceAndDurationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDistanceAndDurationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDistanceAndDurationQuery>(GetDistanceAndDurationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDistanceAndDuration');
    },
    getDistanceAndDuration_25052022(variables: GetDistanceAndDuration_25052022QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDistanceAndDuration_25052022Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDistanceAndDuration_25052022Query>(GetDistanceAndDuration_25052022Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDistanceAndDuration_25052022');
    },
    getDistanceAndDuration_26072021(variables: GetDistanceAndDuration_26072021QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDistanceAndDuration_26072021Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDistanceAndDuration_26072021Query>(GetDistanceAndDuration_26072021Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDistanceAndDuration_26072021');
    },
    getDistinctReservationVoucherValidationFails(variables?: GetDistinctReservationVoucherValidationFailsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDistinctReservationVoucherValidationFailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDistinctReservationVoucherValidationFailsQuery>(GetDistinctReservationVoucherValidationFailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDistinctReservationVoucherValidationFails');
    },
    getDofDrivers(variables: GetDofDriversQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDofDriversQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDofDriversQuery>(GetDofDriversDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDofDrivers');
    },
    getDriverProfileByAccountId(variables: GetDriverProfileByAccountIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDriverProfileByAccountIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDriverProfileByAccountIdQuery>(GetDriverProfileByAccountIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDriverProfileByAccountId');
    },
    getDuplicatedReservations(variables?: GetDuplicatedReservationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDuplicatedReservationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDuplicatedReservationsQuery>(GetDuplicatedReservationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDuplicatedReservations');
    },
    getExcludedAccounts(variables?: GetExcludedAccountsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetExcludedAccountsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetExcludedAccountsQuery>(GetExcludedAccountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getExcludedAccounts');
    },
    getFirebaseUserDetails(variables: GetFirebaseUserDetailsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetFirebaseUserDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetFirebaseUserDetailsQuery>(GetFirebaseUserDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFirebaseUserDetails');
    },
    getFutureReservationsByPatient(variables: GetFutureReservationsByPatientQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetFutureReservationsByPatientQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetFutureReservationsByPatientQuery>(GetFutureReservationsByPatientDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFutureReservationsByPatient');
    },
    getFutureReservationsByPatientId_20220530(variables: GetFutureReservationsByPatientId_20220530QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetFutureReservationsByPatientId_20220530Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetFutureReservationsByPatientId_20220530Query>(GetFutureReservationsByPatientId_20220530Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFutureReservationsByPatientId_20220530');
    },
    getFutureReservationsByPatientId_20230405(variables: GetFutureReservationsByPatientId_20230405QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetFutureReservationsByPatientId_20230405Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetFutureReservationsByPatientId_20230405Query>(GetFutureReservationsByPatientId_20230405Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFutureReservationsByPatientId_20230405');
    },
    getGeneralUserStats(variables?: GetGeneralUserStatsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGeneralUserStatsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetGeneralUserStatsQuery>(GetGeneralUserStatsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getGeneralUserStats');
    },
    getInProgressReservationsByDriverId(variables: GetInProgressReservationsByDriverIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetInProgressReservationsByDriverIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetInProgressReservationsByDriverIdQuery>(GetInProgressReservationsByDriverIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getInProgressReservationsByDriverId');
    },
    getLastReservationByPatientIdAndServiceId(variables: GetLastReservationByPatientIdAndServiceIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLastReservationByPatientIdAndServiceIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLastReservationByPatientIdAndServiceIdQuery>(GetLastReservationByPatientIdAndServiceIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLastReservationByPatientIdAndServiceId');
    },
    getLoginToken(variables?: GetLoginTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLoginTokenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLoginTokenQuery>(GetLoginTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLoginToken');
    },
    getOverdueReservationsByDriverId(variables: GetOverdueReservationsByDriverIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetOverdueReservationsByDriverIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOverdueReservationsByDriverIdQuery>(GetOverdueReservationsByDriverIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getOverdueReservationsByDriverId');
    },
    getParetoDistributionForAccountsVsReservations(variables: GetParetoDistributionForAccountsVsReservationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetParetoDistributionForAccountsVsReservationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetParetoDistributionForAccountsVsReservationsQuery>(GetParetoDistributionForAccountsVsReservationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getParetoDistributionForAccountsVsReservations');
    },
    getPatientConversionReservation(variables?: GetPatientConversionReservationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPatientConversionReservationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPatientConversionReservationQuery>(GetPatientConversionReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPatientConversionReservation');
    },
    getPatientProfileByAccountId(variables: GetPatientProfileByAccountIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPatientProfileByAccountIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPatientProfileByAccountIdQuery>(GetPatientProfileByAccountIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPatientProfileByAccountId');
    },
    getPatientsByConversionStatus(variables?: GetPatientsByConversionStatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPatientsByConversionStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPatientsByConversionStatusQuery>(GetPatientsByConversionStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPatientsByConversionStatus');
    },
    getPatientsByPartialEmailOrName(variables: GetPatientsByPartialEmailOrNameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPatientsByPartialEmailOrNameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPatientsByPartialEmailOrNameQuery>(GetPatientsByPartialEmailOrNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPatientsByPartialEmailOrName');
    },
    getPaymentFailedReservationsByPatientId(variables: GetPaymentFailedReservationsByPatientIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPaymentFailedReservationsByPatientIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPaymentFailedReservationsByPatientIdQuery>(GetPaymentFailedReservationsByPatientIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPaymentFailedReservationsByPatientId');
    },
    getReservationById(variables: GetReservationByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationByIdQuery>(GetReservationByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById');
    },
    getReservationById_20210610(variables: GetReservationById_20210610QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_20210610Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_20210610Query>(GetReservationById_20210610Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_20210610');
    },
    getReservationById_20210622(variables: GetReservationById_20210622QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_20210622Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_20210622Query>(GetReservationById_20210622Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_20210622');
    },
    getReservationById_20210707(variables: GetReservationById_20210707QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_20210707Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_20210707Query>(GetReservationById_20210707Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_20210707');
    },
    getReservationById_20210520(variables: GetReservationById_20210520QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_20210520Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_20210520Query>(GetReservationById_20210520Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_20210520');
    },
    getReservationById_20220531(variables: GetReservationById_20220531QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_20220531Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_20220531Query>(GetReservationById_20220531Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_20220531');
    },
    getReservationById_20220802(variables: GetReservationById_20220802QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_20220802Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_20220802Query>(GetReservationById_20220802Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_20220802');
    },
    getReservationById_administrator_20220601(variables: GetReservationById_Administrator_20220601QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_Administrator_20220601Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_Administrator_20220601Query>(GetReservationById_Administrator_20220601Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_administrator_20220601');
    },
    getReservationById_driver_20220823(variables: GetReservationById_Driver_20220823QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_Driver_20220823Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_Driver_20220823Query>(GetReservationById_Driver_20220823Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_driver_20220823');
    },
    getReservationById_driver_20221031(variables: GetReservationById_Driver_20221031QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_Driver_20221031Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_Driver_20221031Query>(GetReservationById_Driver_20221031Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_driver_20221031');
    },
    getReservationById_patient_20220823(variables: GetReservationById_Patient_20220823QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_Patient_20220823Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_Patient_20220823Query>(GetReservationById_Patient_20220823Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_patient_20220823');
    },
    getReservationById_patient_20221018(variables: GetReservationById_Patient_20221018QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationById_Patient_20221018Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationById_Patient_20221018Query>(GetReservationById_Patient_20221018Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationById_patient_20221018');
    },
    getReservationByIdSub(variables: GetReservationByIdSubSubscriptionVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationByIdSubSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationByIdSubSubscription>(GetReservationByIdSubDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationByIdSub');
    },
    getReservationFrequencyByPatientAccounts(variables?: GetReservationFrequencyByPatientAccountsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationFrequencyByPatientAccountsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationFrequencyByPatientAccountsQuery>(GetReservationFrequencyByPatientAccountsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationFrequencyByPatientAccounts');
    },
    getReservationsByPatientId(variables: GetReservationsByPatientIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationsByPatientIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationsByPatientIdQuery>(GetReservationsByPatientIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationsByPatientId');
    },
    getReservationsCountByReservorId_20221001(variables: GetReservationsCountByReservorId_20221001QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationsCountByReservorId_20221001Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationsCountByReservorId_20221001Query>(GetReservationsCountByReservorId_20221001Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationsCountByReservorId_20221001');
    },
    getReservationsWithChangeRequestByDriverId(variables: GetReservationsWithChangeRequestByDriverIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReservationsWithChangeRequestByDriverIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReservationsWithChangeRequestByDriverIdQuery>(GetReservationsWithChangeRequestByDriverIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReservationsWithChangeRequestByDriverId');
    },
    getServices(variables?: GetServicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetServicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetServicesQuery>(GetServicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getServices');
    },
    getSyncsInProgressCountSub(variables?: GetSyncsInProgressCountSubSubscriptionVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSyncsInProgressCountSubSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSyncsInProgressCountSubSubscription>(GetSyncsInProgressCountSubDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSyncsInProgressCountSub');
    },
    getSyncsSub(variables?: GetSyncsSubSubscriptionVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetSyncsSubSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSyncsSubSubscription>(GetSyncsSubDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSyncsSub');
    },
    getTrafficSourceByAccountId(variables: GetTrafficSourceByAccountIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetTrafficSourceByAccountIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTrafficSourceByAccountIdQuery>(GetTrafficSourceByAccountIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getTrafficSourceByAccountId');
    },
    GetUserStatistics(variables?: GetUserStatisticsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserStatisticsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserStatisticsQuery>(GetUserStatisticsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUserStatistics');
    },
    getVoucherUseStats(variables?: GetVoucherUseStatsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetVoucherUseStatsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetVoucherUseStatsQuery>(GetVoucherUseStatsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getVoucherUseStats');
    },
    getWaitingReservations(variables: GetWaitingReservationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetWaitingReservationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetWaitingReservationsQuery>(GetWaitingReservationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getWaitingReservations');
    },
    getWaitingReservations_20210527(variables: GetWaitingReservations_20210527QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetWaitingReservations_20210527Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetWaitingReservations_20210527Query>(GetWaitingReservations_20210527Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getWaitingReservations_20210527');
    },
    getWaitingReservations_20230411(variables: GetWaitingReservations_20230411QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetWaitingReservations_20230411Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetWaitingReservations_20230411Query>(GetWaitingReservations_20230411Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getWaitingReservations_20230411');
    },
    isDriverAvailable(variables: IsDriverAvailableQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IsDriverAvailableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IsDriverAvailableQuery>(IsDriverAvailableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'isDriverAvailable');
    },
    isDriverAvailable_20220810(variables: IsDriverAvailable_20220810QueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IsDriverAvailable_20220810Query> {
      return withWrapper((wrappedRequestHeaders) => client.request<IsDriverAvailable_20220810Query>(IsDriverAvailable_20220810Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'isDriverAvailable_20220810');
    },
    isDriverFullyOnboardedToStripe(variables?: IsDriverFullyOnboardedToStripeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IsDriverFullyOnboardedToStripeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IsDriverFullyOnboardedToStripeQuery>(IsDriverFullyOnboardedToStripeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'isDriverFullyOnboardedToStripe');
    },
    isNewerAppVersionAvailable(variables: IsNewerAppVersionAvailableSubscriptionVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IsNewerAppVersionAvailableSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<IsNewerAppVersionAvailableSubscription>(IsNewerAppVersionAvailableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'isNewerAppVersionAvailable');
    },
    isPatientAvailable(variables: IsPatientAvailableQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IsPatientAvailableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IsPatientAvailableQuery>(IsPatientAvailableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'isPatientAvailable');
    },
    acceptManualReservation(variables: AcceptManualReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AcceptManualReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AcceptManualReservationMutation>(AcceptManualReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'acceptManualReservation');
    },
    declineManualReservation(variables: DeclineManualReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeclineManualReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeclineManualReservationMutation>(DeclineManualReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'declineManualReservation');
    },
    onboardDriverToStripe(variables: OnboardDriverToStripeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OnboardDriverToStripeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<OnboardDriverToStripeMutation>(OnboardDriverToStripeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'onboardDriverToStripe');
    },
    patientCancelReservation(variables: PatientCancelReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PatientCancelReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PatientCancelReservationMutation>(PatientCancelReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'patientCancelReservation');
    },
    patientRequestReservationChange(variables?: PatientRequestReservationChangeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PatientRequestReservationChangeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PatientRequestReservationChangeMutation>(PatientRequestReservationChangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'patientRequestReservationChange');
    },
    removePaymentMethod(variables: RemovePaymentMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemovePaymentMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemovePaymentMethodMutation>(RemovePaymentMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'removePaymentMethod');
    },
    requestReservationChange(variables: RequestReservationChangeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RequestReservationChangeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RequestReservationChangeMutation>(RequestReservationChangeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'requestReservationChange');
    },
    requestLoginToken(variables: RequestLoginTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RequestLoginTokenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RequestLoginTokenQuery>(RequestLoginTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'requestLoginToken');
    },
    resendStripeCheckoutSessionPaymentRequest(variables: ResendStripeCheckoutSessionPaymentRequestMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResendStripeCheckoutSessionPaymentRequestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResendStripeCheckoutSessionPaymentRequestMutation>(ResendStripeCheckoutSessionPaymentRequestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'resendStripeCheckoutSessionPaymentRequest');
    },
    resendVerificationEmail(variables: ResendVerificationEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResendVerificationEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ResendVerificationEmailMutation>(ResendVerificationEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'resendVerificationEmail');
    },
    selectDefaultPaymentMethod(variables: SelectDefaultPaymentMethodMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SelectDefaultPaymentMethodMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SelectDefaultPaymentMethodMutation>(SelectDefaultPaymentMethodDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'selectDefaultPaymentMethod');
    },
    sendDriverProfileRequest(variables: SendDriverProfileRequestMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendDriverProfileRequestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendDriverProfileRequestMutation>(SendDriverProfileRequestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendDriverProfileRequest');
    },
    sendNewManualReservationEmailToAllDrivers(variables: SendNewManualReservationEmailToAllDriversMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendNewManualReservationEmailToAllDriversMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendNewManualReservationEmailToAllDriversMutation>(SendNewManualReservationEmailToAllDriversDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendNewManualReservationEmailToAllDrivers');
    },
    sendNewManualReservationEmailToPreferredDriver(variables: SendNewManualReservationEmailToPreferredDriverMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendNewManualReservationEmailToPreferredDriverMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendNewManualReservationEmailToPreferredDriverMutation>(SendNewManualReservationEmailToPreferredDriverDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendNewManualReservationEmailToPreferredDriver');
    },
    sendPasswordResetRequest(variables: SendPasswordResetRequestMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendPasswordResetRequestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendPasswordResetRequestMutation>(SendPasswordResetRequestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendPasswordResetRequest');
    },
    signIn(variables: SignInMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SignInMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SignInMutation>(SignInDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'signIn');
    },
    signOut(variables?: SignOutMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SignOutMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SignOutMutation>(SignOutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'signOut');
    },
    startReservation(variables: StartReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StartReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<StartReservationMutation>(StartReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'startReservation');
    },
    submitReservationRating(variables: SubmitReservationRatingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SubmitReservationRatingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SubmitReservationRatingMutation>(SubmitReservationRatingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'submitReservationRating');
    },
    submitTrafficSource(variables: SubmitTrafficSourceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SubmitTrafficSourceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SubmitTrafficSourceMutation>(SubmitTrafficSourceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'submitTrafficSource');
    },
    syncFirebaseAuthenticationUsersWithDbManually(variables?: SyncFirebaseAuthenticationUsersWithDbManuallyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SyncFirebaseAuthenticationUsersWithDbManuallyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SyncFirebaseAuthenticationUsersWithDbManuallyMutation>(SyncFirebaseAuthenticationUsersWithDbManuallyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'syncFirebaseAuthenticationUsersWithDbManually');
    },
    tr(variables: TrMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TrMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TrMutation>(TrDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'tr');
    },
    updateCitiesOnDriverProfile(variables: UpdateCitiesOnDriverProfileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCitiesOnDriverProfileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCitiesOnDriverProfileMutation>(UpdateCitiesOnDriverProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCitiesOnDriverProfile');
    },
    updateServicesOnDriverProfile(variables: UpdateServicesOnDriverProfileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateServicesOnDriverProfileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateServicesOnDriverProfileMutation>(UpdateServicesOnDriverProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateServicesOnDriverProfile');
    },
    updateOverdueReservation(variables: UpdateOverdueReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateOverdueReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateOverdueReservationMutation>(UpdateOverdueReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateOverdueReservation');
    },
    updatePersonalData(variables: UpdatePersonalDataMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePersonalDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePersonalDataMutation>(UpdatePersonalDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePersonalData');
    },
    updateReservationBeforeFinish_20210617(variables: UpdateReservationBeforeFinish_20210617MutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateReservationBeforeFinish_20210617Mutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateReservationBeforeFinish_20210617Mutation>(UpdateReservationBeforeFinish_20210617Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateReservationBeforeFinish_20210617');
    },
    updateReservationBeforeFinish_20210622(variables: UpdateReservationBeforeFinish_20210622MutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateReservationBeforeFinish_20210622Mutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateReservationBeforeFinish_20210622Mutation>(UpdateReservationBeforeFinish_20210622Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateReservationBeforeFinish_20210622');
    },
    updateReservationBeforeFinish_20230126(variables: UpdateReservationBeforeFinish_20230126MutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateReservationBeforeFinish_20230126Mutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateReservationBeforeFinish_20230126Mutation>(UpdateReservationBeforeFinish_20230126Document, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateReservationBeforeFinish_20230126');
    },
    verifyEmail(variables: VerifyEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VerifyEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<VerifyEmailMutation>(VerifyEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'verifyEmail');
    },
    verifySkillsincloudSSO(variables: VerifySkillsincloudSsoMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VerifySkillsincloudSsoMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<VerifySkillsincloudSsoMutation>(VerifySkillsincloudSsoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'verifySkillsincloudSSO');
    },
    verifyVoucher(variables: VerifyVoucherQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VerifyVoucherQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VerifyVoucherQuery>(VerifyVoucherDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'verifyVoucher');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;