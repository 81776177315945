/**
 * @description Services related to manual_* (b2b) flows
 */
export const MANUAL_SERVICES = [
	{ id: 'CAREGIVING_WITH_UGOS_CAR', name: 'Caregiving With Ugos Car' },
	{ id: 'CAREGIVING_WITH_USERS_CAR', name: 'Caregiving With Users Car' },
	{ id: 'CAREGIVING_WITHOUT_CAR', name: 'Caregiving Without Car' },
	{ id: 'CAREGIVING_VIA_TELEMEDICINE', name: 'Caregiving Via Telemedicine' },
	{ id: 'ERRANDS', name: 'Errands' },
	{ id: 'PHONE_ASSISTANCE', name: 'Phone Assistance' },
];
