import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Dialog } from '@material-ui/core';
import { Button } from '@material-ui/core';

export const EmailWillBeSentOutAlert = ({
	isOpen,
	onResolve,
	onReject,
}: any) => {
	return (
		<Dialog
			open={isOpen}
			onClose={e => onReject(e)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Emails will be sent out</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					You have changed the status from CANCELED to WAITING!
					<br />
					After saving this reservation, emails will be sent out to relevant
					drivers.
					<br />
					<br />
					Select OK to continue.
					<br />
					Select Cancel to return status to CANCELED.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="primary"
					size="small"
					variant={'outlined'}
					onClick={() => onResolve(false)}
				>
					Cancel
				</Button>
				<Button
					color="primary"
					size="small"
					variant="contained"
					onClick={() => onResolve(true)}
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};
