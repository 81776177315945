export enum HasuraSessionVariables {
  Claims = 'https://hasura.io/jwt/claims',
  ActiveRole = 'x-hasura-role',
  DefaultRole = 'x-hasura-default-role',
  AllowedRoles = 'x-hasura-allowed-roles',
  /**
   * @description Mapped to account.id in our database
   */
  UserId = 'x-hasura-user-id',
  AdminSecret = 'x-hasura-admin-secret',
}
