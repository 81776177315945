import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import React, { useEffect } from 'react';
import { useUtilities } from '@ugo/utils';

export function VoucherEdit(props) {
  const { removeDeleteButtonFromDom } = useUtilities();

  useEffect(() => {
    removeDeleteButtonFromDom();
  }, []);

  return (
    <Edit {...props}>
      {/*<FormWithRedirect {...props}></FormWithRedirect>*/}
      <SimpleForm {...props}>
        <TextInput label="Code" source="code" />
        <BooleanInput label="Enabled" source="enabled" defaultChecked={true} />
        <BooleanInput
          label="First service only"
          source="first_reservation_only"
          defaultChecked={false}
        />
        <NumberInput label="Percent discount" source="percent_value" />
        <ReferenceInput
          label="Referrer"
          source="referrer_id"
          reference="account"
          filterToQuery={(searchText) => ({
            email: searchText,
          })}
        >
          <AutocompleteInput optionText="email" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
