import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Bar } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { Box, Card } from '@material-ui/core';
import { ChartComponentProps } from '@ugo/models';
import { getDay } from 'date-fns';
import { groupBy, map, pipe, values } from 'ramda';
import { switchFirstAndLastItemInArray } from './ServicesCreatedAtByDayOfWeekBarChart';

const groupServicesByDayOfWeek = (reservations: any) => {
  const allServicesByDayOfWeek = pipe(
    // @ts-ignore
    groupBy((r) =>
      // @ts-ignore
      getDay(new Date(r.starts_at)).toString()
    )
    // @ts-ignore
  )(Object.values(reservations));

  const allServicesByDayOfWeekCount = pipe(
    // @ts-ignore
    map((dayReservations) => dayReservations.length),
    values,
    switchFirstAndLastItemInArray
    // @ts-ignore
  )(allServicesByDayOfWeek);

  const duplicatedServicesByDayOfWeekCount = pipe(
    // @ts-ignore
    map((drs) => drs.filter((drs) => drs.duplication_reference_id)),
    // @ts-ignore
    map((drs) => drs.length),
    values,
    switchFirstAndLastItemInArray
    // @ts-ignore
  )(allServicesByDayOfWeek);

  const nonDuplicatedServicesByDayOfWeekCount = pipe(
    // @ts-ignore
    map((drs) => drs.filter((drs) => !drs.duplication_reference_id)),
    // @ts-ignore
    map((drs) => drs.length),
    values,
    switchFirstAndLastItemInArray
    // @ts-ignore
  )(allServicesByDayOfWeek);

  return [
    allServicesByDayOfWeekCount,
    nonDuplicatedServicesByDayOfWeekCount,
    duplicatedServicesByDayOfWeekCount,
  ];
};

const ServicesStartAtByDayOfWeekBarChart = (props: ChartComponentProps) => {
  const [graphData, setGraphData] = useState<number[]>([]);

  const { data } = useGetList(
    'reservation',
    { page: 1, perPage: 999999 },
    { field: 'starts_at', order: 'ASC' },
    {
      'starts_at@_gte': props.startDate,
      'starts_at@_lte': props.endDate,
      'is_manual@_eq': props.shouldIncludeManualB2cReservations,
    }
  );

  useEffect(() => {
    // @ts-ignore
    setGraphData(groupServicesByDayOfWeek(data));
  }, [data]);

  return (
    <Card>
      <Box p={2}>
        <Bar
          data={{
            labels: [
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday',
            ],
            datasets: [
              {
                label: 'All',
                data: graphData[0],
                backgroundColor: colorScheme[0],
              },
              {
                label: 'Non-duplicated',
                data: graphData[1],
                backgroundColor: colorScheme[1],
              },
              {
                label: 'Duplicated',
                data: graphData[2],
                backgroundColor: colorScheme[2],
              },
            ],
          }}
          options={{
            title: {
              display: true,
              text: 'Services start at by day of week',
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </Box>
    </Card>
  );
};
export default ServicesStartAtByDayOfWeekBarChart;
