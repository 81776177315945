import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextField,
  TextInput,
  useNotify,
} from 'react-admin';
import {
  B2B_PROJECT_NAMES,
  MANUAL_CITIES,
  MANUAL_SERVICES,
  MANUAL_STATUSES,
  MARKET_SEGMENTS,
} from '@ugo/data';
import { LocationInput } from '../shared/LocationInput';
import Box from '@material-ui/core/Box';
import { FormWithRedirect } from 'ra-core';
import { useFormState } from 'react-final-form';
import {
  calculateFinalTravelDistanceAndDuration,
  calculateQuotePrice,
  getTotalReservationDistance,
  QuoteCalculator,
  UGO_FEE,
  useQuoteCalculations,
} from '@ugo/quote-calculations';
import { useGetDistanceAndDuration_25052022Query } from '@ugo/graphql-operations';
import { HasuraSessionVariables, Roles, TypeOfTravel } from '@ugo/models';
import { FormWithRedirectProps } from 'ra-core/esm/form/FormWithRedirect';
import { FirebaseApp } from '../../shared/vendor/firebase';
import { Toolbar } from '@material-ui/core';
import { Button } from 'ra-ui-materialui';
import { Computer } from '@material-ui/icons';
import { addMinutes } from 'date-fns';
import { BlockingOverlay } from '../shared/BlockingOverlay';

export const ManualReservationWithPreferredDriverForm = ({
  isEdit,
  ...props
}: Omit<FormWithRedirectProps, 'render'> & {
  isEdit?: boolean;
}) => {
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FormWithRedirect
      {...props}
      render={(props) => {
        const isFirstRender = React.useRef(true);
        const saveButtonRef = React.useRef<HTMLButtonElement | null>(null);
        const formState = useFormState();
        const { getDistanceAndDurationInputBySelectedService } =
          useQuoteCalculations();

        const { refetch: getDistanceAndDuration } =
          useGetDistanceAndDuration_25052022Query({
            skip: true,
            context: {
              [HasuraSessionVariables.ActiveRole]: Roles.Administrator,
            },
          });

        const calculate = async ({ isTwoWay }) => {
          setIsLoading(true);
          if (
            !formState.values.departure_address ||
            !formState.values.destination_address ||
            !formState.values.service_city
          ) {
            notify('Missing addresses for calculation', { type: 'error' });

            setIsLoading(false);
            return;
          }

          const queryInput = getDistanceAndDurationInputBySelectedService({
            departure: {
              lat: parseFloat(formState.values.departure_lat),
              lng: parseFloat(formState.values.departure_lng),
            },
            destination: {
              lat: parseFloat(formState.values.destination_lat),
              lng: parseFloat(formState.values.destination_lng),
            },
            serviceCity: formState.values.service_city,
            service: formState.values.service,
            businessType: 'b2b',
          });

          const { data } = await getDistanceAndDuration(queryInput);

          const values = formState.values as {
            service: string;
            duration_estimate: number;
            is_two_way: boolean;
            service_city: string;
            status: string;
            starts_at: string | Date;
            ends_at: string | Date;
          };

          const inputData = calculateFinalTravelDistanceAndDuration(data, {});

          const calculator = new QuoteCalculator(
            values.service as any,
            values.duration_estimate as number,
            isTwoWay,
            values.service_city,
            inputData.distance_between_0_and_a,
            inputData.distance_between_0_and_b,
            inputData.travel_distance
          );

          const cost = calculator.calculate();

          props.form.change(
            'distance_between_0_and_a_meters',
            parseInt(
              ((inputData.distance_between_0_and_a || 0) * 1000).toFixed(0)
            )
          );
          props.form.change(
            'distance_between_0_and_b_meters',
            parseInt(
              ((inputData.distance_between_0_and_b || 0) * 1000).toFixed(0)
            )
          );
          props.form.change(
            'distance_between_a_and_b_meters',
            parseInt(((inputData.travel_distance || 0) * 1000).toFixed(0))
          );

          props.form.change(
            'distance_billable',
            calculator.travelDistance + calculator.driverDistance
          );

          props.form.change('price_estimate', cost * (1 - UGO_FEE));

          props.form.change(
            'ends_at',
            addMinutes(
              typeof values.starts_at === 'string'
                ? new Date(values.starts_at)
                : values.starts_at,
              Number(values.duration_estimate) * 60
            )
          );

          if (cost) {
            notify('Price and distance updated', {
              type: 'success',
              autoHideDuration: 1000,
            });
            if (isEdit) {
              saveButtonRef.current?.click();
            }
          } else {
            notify("Can't calculate. Check all parameters", { type: 'error' });
          }
          setIsLoading(false);
        };

        useEffect(() => {
          if (isFirstRender.current) {
            isFirstRender.current = false;
            if (formState.values.duplicate) {
              calculate({ isTwoWay: formState.values.is_two_way });
            }
            return;
          }

          if (isEdit || formState.values.duplicate) {
            if (
              !formState.values.departure_address ||
              !formState.values.destination_address ||
              !formState.values.service_city ||
              !formState.values.duration_estimate
            ) {
              return;
            }
            calculate({ isTwoWay: formState.values.is_two_way });
          }
        }, [
          formState.values.departure_address,
          formState.values.destination_address,
          formState.values.service_city,
          formState.values.duration_estimate,
        ]);

        const onManualReservationIsTwoWayChange = async (isTwoWay) => {
          await calculate({ isTwoWay });
        };

        const onProjectSelectChange = (e: { id: number; name: string }) => {
          props.form.change('project_name', e.name);
        };

        const onStartAtOrDurationEstimateChange = (e: any) => {
          if (e.target.value !== '') {
            const startsAt = props.form.getFieldState('starts_at');
            const durationEstimate =
              props.form.getFieldState('duration_estimate');

            props.form.change(
              'ends_at',
              addMinutes(
                typeof startsAt?.value === 'string'
                  ? new Date(startsAt.value)
                  : startsAt?.value,
                Number(durationEstimate?.value) * 60
              )
            );
          }
        };

        return (
          <>
            <BlockingOverlay show={isLoading} />
            <Box display="flex">
              <Box flex={1} p="1em">
                <SelectInput
                  source="service"
                  choices={MANUAL_SERVICES}
                  fullWidth
                />

                <SelectInput
                  source="service_city"
                  choices={MANUAL_CITIES}
                  fullWidth
                />

                <SelectInput
                  source="status"
                  choices={MANUAL_STATUSES}
                  defaultValue={'DRAFT'}
                  fullWidth
                />
              </Box>
              <Box flex={1} p="1em">
                {/* @ts-ignore */}
                {props?.record.driver_id && (
                  <ReferenceInput
                    allowEmpty
                    label="Driver"
                    source="driver_id"
                    reference="manual_driver"
                    perPage={20}
                    filterToQuery={(searchText) => ({
                      email: searchText,
                    })}
                    fullWidth
                  >
                    <AutocompleteInput optionText="email" />
                  </ReferenceInput>
                )}
                <ReferenceInput
                  label="Preferred driver"
                  source="driver_preferred_id"
                  reference="manual_driver"
                  perPage={20}
                  filterToQuery={(searchText) => ({
                    email: searchText,
                  })}
                  fullWidth
                >
                  <AutocompleteInput optionText="email" />
                </ReferenceInput>
                <ReferenceInput
                  label="Patient"
                  source="reservor_id"
                  reference="manual_patient"
                  perPage={20}
                  filterToQuery={(searchText) => ({
                    email: searchText,
                  })}
                  fullWidth
                >
                  <AutocompleteInput optionText="email" />
                </ReferenceInput>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} p="1em">
                <DateTimeInput
                  source="starts_at"
                  emptyText="n/a"
                  fullWidth
                  onChange={onStartAtOrDurationEstimateChange}
                />
              </Box>
              <Box flex={1} p="1em">
                <TextInput
                  source="duration_estimate"
                  fullWidth
                  onChange={onStartAtOrDurationEstimateChange}
                />
              </Box>
            </Box>
            {/* @ts-ignore */}
            <Box display={props.record.status !== 'DRAFT' ? 'flex' : 'none'}>
              <Box flex={1} p="1em">
                <DateTimeInput source="started_at" emptyText="n/a" fullWidth />
              </Box>
              <Box flex={1} p="1em">
                <DateTimeInput source="ended_at" emptyText="n/a" fullWidth />
              </Box>
            </Box>

            <Box p="1em">
              <LocationInput
                addressField="departure_address"
                latField="departure_lat"
                lngField="departure_lng"
              />
              <LocationInput
                addressField="destination_address"
                latField="destination_lat"
                lngField="destination_lng"
              />
            </Box>

            <Box p="1em">
              <Box mb={1} fontSize={14}>
                Automatically calculate distance and price based on current
                inputs.
              </Box>
              <Box>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() =>
                    calculate({ isTwoWay: formState.values.is_two_way })
                  }
                  label="Calculate"
                  icon={<Computer />}
                />
              </Box>
            </Box>

            <Box p="1rem">
              <small>
                Distance billable consists of driver distance from{' '}
                <b>city center to A|B</b> and actual{' '}
                <b>reservation distance A-B</b>
              </small>
              <NumberInput source="distance_billable" fullWidth />
            </Box>

            <Box p="1rem">
              <TextInput source="note" multiline fullWidth rows="3" />
            </Box>

            <Box display="flex">
              <Box flex={1} p="1em">
                <NumberInput source="price_estimate" fullWidth />
              </Box>
              <Box flex={1} p="1em">
                <NumberInput source="extra_cost" fullWidth />
              </Box>
            </Box>

            <Box p="1rem">
              <BooleanInput
                source="is_two_way"
                defaultValue={false}
                onChange={onManualReservationIsTwoWayChange}
              />

              <BooleanInput
                source="has_preferred_driver"
                defaultValue={true}
                disabled
              />
            </Box>
            <Box p="1rem">
              <BooleanInput source="driver_paid" defaultValue={false} />
            </Box>
            {props.record?.manual_reservation_cancellations?.length > 0 && (
              <Box p="1rem">
                <p>
                  <b>Cancellations</b>
                </p>
                <ol>
                  {props.record?.manual_reservation_cancellations?.map(
                    (cancellation: any, i: number) => {
                      return (
                        <li key={i}>
                          <b>{cancellation.manual_driver.account.email}:</b>{' '}
                          {cancellation.note}
                        </li>
                      );
                    }
                  )}
                </ol>
              </Box>
            )}
            <Box display="flex">
              <Box flex={1} p="1rem">
                <label
                  style={{
                    display: 'block',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  Project name
                </label>
                <TextField label="Project name" source="project_name" />
                <p style={{ fontSize: '12px' }}>
                  project_name is archived and is being used here for backward
                  compatibility. Projects are stored in the database and
                  connected to its specific partner if it exists.
                </p>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} p="1rem">
                <ReferenceInput
                  label="Partner"
                  source="partner_id"
                  reference="partner"
                  perPage={20}
                  filterToQuery={(searchText) => ({
                    name: searchText,
                  })}
                  fullWidth
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Box flex={1} p="1rem">
                <ReferenceInput
                  label="Project"
                  source="project_id"
                  reference="project"
                  perPage={20}
                  filterToQuery={(searchText) => ({
                    name: searchText,
                  })}
                  fullWidth
                >
                  <AutocompleteInput
                    onSelect={onProjectSelectChange}
                    id="project-select"
                    optionText="name"
                  />
                </ReferenceInput>
                {/*<SelectInput*/}
                {/*  required*/}
                {/*  source="project_name"*/}
                {/*  validate={[required()]}*/}
                {/*  choices={B2B_PROJECT_NAMES}*/}
                {/*  fullWidth*/}
                {/*/>*/}
              </Box>

              <Box flex={1} p="1rem">
                <SelectInput
                  source="market_segment"
                  choices={MARKET_SEGMENTS}
                  fullWidth
                />
              </Box>
              <Box flex={1} p="1rem">
                <TextInput
                  source="created_by"
                  defaultValue={FirebaseApp().auth().currentUser?.email}
                  disabled
                />
              </Box>
            </Box>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton
                  buttonRef={saveButtonRef}
                  redirect={false}
                  disabled={props.pristine}
                  saving={props.saving}
                  handleSubmitWithRedirect={() => {
                    if (!formState.values.extra_cost) {
                      props.form.change('extra_cost', 0);
                    }

                    if (props.handleSubmitWithRedirect) {
                      props.handleSubmitWithRedirect(false);
                    }
                  }}
                />
              </Box>
            </Toolbar>
          </>
        );
      }}
    />
  );
};
