import Dialog from '@material-ui/core/Dialog';
import { useNotify } from 'react-admin';
import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDeactivateDriverMutation } from '@ugo/graphql-operations';
import { HasuraSessionVariables, Roles } from '@ugo/models';
import { Button } from '@material-ui/core';

export const DeactivateDriverDialog = ({
  open,
  closeCallback,
  accountId,
}: {
  open: boolean;
  closeCallback: () => void;
  accountId: string;
}) => {
  const notify = useNotify();
  const [deactivateDriver, { loading }] = useDeactivateDriverMutation();

  const handleDeactivateDriver = async () => {
    try {
      const { data } = await deactivateDriver({
        variables: {
          account_id: accountId,
        },
        context: {
          headers: {
            [HasuraSessionVariables.ActiveRole]: Roles.Administrator,
          },
        },
      });
      if (data?.deactivateDriver?.success) {
        notify('Driver deactivated');
        closeCallback();
      } else {
        notify('Error deactivating driver');
      }
    } catch (e) {
      notify('Error deactivating driver');
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      // onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Account email change</DialogTitle>
      <DialogContent>
        Are you sure you want to deactivate this driver?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeCallback}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeactivateDriver}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};
