import React from 'react';
import {
  Edit,
  FormWithRedirect,
  SaveButton,
  SelectInput,
  TextInput,
} from 'react-admin';
import { Box, Toolbar } from '@material-ui/core';
import { TRAFFIC_SOURCES } from '@ugo/data';

export const AccountFeedbackEdit = (props: any) => {
  return (
    <>
      <Edit {...props}>
        <FormWithRedirect
          {...props}
          render={({ record, form, ...formProps }) => (
            <form>
              <Box p="1em">
                <Box>
                  <SelectInput
                    label="Traffic Source"
                    source="traffic_source"
                    choices={TRAFFIC_SOURCES}
                    fullWidth
                  />
                </Box>
                <Box>
                  <TextInput
                    label="Traffic Source Conversion Text"
                    source="traffic_source_other_text"
                    fullWidth
                    multiline
                    rows="3"
                  />
                </Box>
                <Box>
                  <TextInput
                    label="Traffic Source Affiliate Text"
                    source="traffic_source_affiliate_text"
                    fullWidth
                    multiline
                    rows="3"
                  />
                </Box>
              </Box>
              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <SaveButton
                    redirect={false}
                    disabled={formProps.pristine}
                    saving={formProps.saving}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />
                </Box>
              </Toolbar>
            </form>
          )}
        />
      </Edit>
    </>
  );
};
