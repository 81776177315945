import React, { useCallback, useEffect } from 'react';
import {
  Edit,
  FormWithRedirect,
  Labeled,
  SaveButton,
  TextField,
  TextInput,
  TopToolbar,
  useMutation,
  SelectInput,
  DateInput,
} from 'react-admin';
import {
  Box,
  Chip,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CarvoilaCarvoilaDriverOnboardingFlowData from './DriverOnboardingFlowData';
import { useNotify, useQuery } from 'ra-core';
import { BooleanInput, DateField } from 'ra-ui-materialui';
import { Carvoila_Driver_Status_Enum } from '@ugo/graphql-operations';
import { Alert } from '@material-ui/lab';

const AccountEditActions = () => (
  <TopToolbar>
    {/*<Button*/}
    {/*	startIcon={<DirectionsCar />}*/}
    {/*	color="primary"*/}
    {/*	size="small"*/}
    {/*	onClick={() => console.log('Asd')}*/}
    {/*>*/}
    {/*	Promote to driver*/}
    {/*</Button>*/}
  </TopToolbar>
);

export const CarvoilaDriverEdit = (props: any) => {
  const [mutate] = useMutation();
  const [selectedDistricts, setSelectedDistricts] = React.useState<string[]>(
    []
  );
  const notify = useNotify();

  const { data: districts } = useQuery({
    type: 'getList',
    resource: 'carvoila_district',
    payload: {},
  });

  const save = useCallback(
    async (values) => {
      // Currently only updating districts
      const data = {
        districts: selectedDistricts,
        note: values.note,
        status: values.status,
        is_registered_on_dev4side: values.is_registered_on_dev4side,
        vat_number: values.vat_number,
        iban: values.iban,
        phone_number: values.phone_number,
        first_name: values.first_name,
        last_name: values.last_name,
        account_holder_name: values.account_holder_name,
        email: values.email,
        tax_regime: values.tax_regime,
        address: values.address,
        city: values.city,
        zip_code: values.zip_code,
        residence_permit_number: values.residence_permit_number,
        residence_permit_expiry: values.residence_permit_expiry?.length
          ? values.residence_permit_expiry
          : null,
        fiscal_code: values.fiscal_code,
        citizenship: values.citizenship,
      };

      try {
        await mutate(
          {
            type: 'update',
            resource: 'carvoila_driver',
            payload: { id: props.id, data },
          },
          { returnPromise: true, mutationMode: 'pessimistic' }
        );
        notify('Saved successfully', 'success');
      } catch (error: any) {
        notify('Error saving', 'error');
        console.log(error);
      }
    },
    [mutate, selectedDistricts]
  );

  const handleDistrictChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedDistricts(event.target.value as string[]);
  };

  useEffect(() => {
    console.log(selectedDistricts);
  }, [selectedDistricts]);

  return (
    <>
      <Edit
        actions={<AccountEditActions />}
        mutationMode="pessimistic"
        {...props}
      >
        <FormWithRedirect
          save={save}
          {...props}
          render={({ record, form, ...formProps }) => {
            useEffect(() => {
              if (record?.districts) {
                setSelectedDistricts(record?.districts);
              }
            }, []);

            return (
              <form>
                <Box display="flex" flexWrap="wrap">
                  <Box width={1 / 2}>
                    <Box p="1em">
                      <Box>
                        <TextInput
                          source="first_name"
                          label="First Name"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="last_name"
                          label="Last Name"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <TextInput source="address" label="Address" fullWidth />
                      </Box>
                      <Box>
                        <TextInput
                          source="zip_code"
                          label="Zip Code"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <TextInput source="city" label="City" fullWidth />
                      </Box>
                      <Box>
                        <TextInput
                          source="citizenship"
                          label="Citizenship"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <TextInput source="email" label="Email" fullWidth />
                      </Box>
                      <Box>
                        <TextInput source="vat_number" label="VAT" fullWidth />
                      </Box>
                      <Box>
                        <SelectInput
                          source="tax_regime"
                          label="Tax Regime"
                          fullWidth
                          choices={[
                            { id: 'WITHOLDING', name: 'Ritenuta' },
                            { id: 'FLAT', name: 'IVA Forfettaria' },
                            { id: 'MINIMUM', name: 'IVA minimi' },
                            { id: 'ORDINARY', name: 'IVA ordinaria' },
                            { id: 'INDIVIDUAL', name: 'Impresa individuale' },
                            { id: 'COMPANY', name: 'VAT Società' },
                          ]}
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="residence_permit_number"
                          label="Residence permit number"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <DateInput
                          source="residence_permit_expiry"
                          label="Residence Permit Expiry"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <DateInput
                          source="license_expiry"
                          label="License Expiry"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="fiscal_code"
                          label="Fiscal Code"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <TextInput source="iban" label="IBAN" fullWidth />
                      </Box>
                      <Box>
                        <TextInput
                          source="account_holder_name"
                          label="Account Holder"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <TextInput
                          source="phone_number"
                          label="Phone"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <BooleanInput
                          source="is_registered_on_dev4side"
                          label="Is registered on Dev 4 Side"
                        />
                      </Box>
                      <Box>
                        <DateField
                          source="dof_approved_at"
                          label="Dev 4 Side Driver ID"
                        />
                      </Box>
                      {districts && (
                        <Box mb={3}>
                          <FormControl fullWidth>
                            <InputLabel>Districts</InputLabel>
                            <Select
                              multiple
                              value={selectedDistricts}
                              onChange={handleDistrictChange}
                              fullWidth
                              input={<Input id="select-multiple-chip" />}
                              renderValue={(selected) => (
                                <Box display="flex" flexWrap="wrap">
                                  {(selected as string[]).map((value) => (
                                    <Box key={value} p="0.15em">
                                      <Chip key={value} label={value} />
                                    </Box>
                                  ))}
                                </Box>
                              )}
                            >
                              {districts.map((district: any) => (
                                <MenuItem
                                  key={district.name}
                                  value={district.name}
                                >
                                  {district.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      <Box>
                        <TextInput
                          fullWidth
                          rows={3}
                          multiline
                          source="note"
                          label="Note"
                        />
                      </Box>
                      <Box display="flex">
                        <Box>
                          <SelectInput
                            source="status"
                            choices={[
                              {
                                id: Carvoila_Driver_Status_Enum.Disabled,
                                name: 'Disabled',
                              },
                              {
                                id: Carvoila_Driver_Status_Enum.Approved,
                                name: 'Approved',
                              },
                              {
                                id: Carvoila_Driver_Status_Enum.Pending,
                                name: 'Pending',
                              },
                              {
                                id: Carvoila_Driver_Status_Enum.DofRejected,
                                name: 'DOF Rejected',
                              },
                            ]}
                          />
                        </Box>
                        <Box pl={3}>
                          <small>
                            NOTE: To initially approve the driver during DOF,
                            click the button in the DOF section below.
                            <br />
                            <br />
                            <b>Status change actions:</b>
                            <br />- From PENDING to APPROVED: Sends out CDOF
                            approval email
                          </small>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box width={1 / 2}>
                    <Box p={1}>
                      <CarvoilaDriverSummary record={record} />
                    </Box>
                  </Box>
                  {!record?.is_imported && (
                    <Box width={1}>
                      <Box p="1em">
                        <Typography variant="h6">
                          Driver onboarding flow
                        </Typography>
                      </Box>
                      <Box display="flex" p="1em">
                        {record?.id && (
                          <CarvoilaCarvoilaDriverOnboardingFlowData
                            driver={record}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
                <Toolbar>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <SaveButton
                      redirect={false}
                      disabled={false}
                      saving={formProps.saving}
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect
                      }
                    />
                  </Box>
                </Toolbar>
              </form>
            );
          }}
        />
      </Edit>
    </>
  );
};

function CarvoilaDriverSummary(props: { record: any }) {
  const notify = useNotify();

  const copyOnClick = (value: string) => {
    if (value) {
      navigator.clipboard.writeText(value);
      notify('Copied to clipboard', 'success');
    }
  };

  return (
    <Box>
      {props.record?.is_imported && (
        <Box pt={2} pr={1}>
          <Alert severity="info">
            This driver was imported from old Google Sheet
          </Alert>
        </Box>
      )}
      <h4>
        Summary &nbsp;
        <small style={{ fontSize: '0.75rem', fontWeight: 400 }}>
          (Click on text to copy)
        </small>
      </h4>
      <Box>
        <Labeled label="ID" record={props.record}>
          <TextField
            source="id"
            label="ID"
            record={props.record}
            emptyText="n/a"
            onClick={() => copyOnClick(props.record?.id)}
          />
        </Labeled>
      </Box>
      <Box>
        <Labeled label="Email" record={props.record}>
          <TextField
            source="email"
            label="Email"
            record={props.record}
            emptyText="n/a"
            onClick={() => copyOnClick(props.record?.email)}
          />
        </Labeled>
      </Box>
      <Box>
        <Labeled label="First name" record={props.record}>
          <TextField
            source="first_name"
            label="First name"
            record={props.record}
            emptyText="n/a"
            onClick={() => copyOnClick(props.record?.first_name)}
          />
        </Labeled>
      </Box>
      <Box>
        <Labeled label="Last name" record={props.record}>
          <TextField
            source="last_name"
            label="Last name"
            record={props.record}
            emptyText="n/a"
            onClick={() => copyOnClick(props.record?.last_name)}
          />
        </Labeled>
      </Box>
      <Box>
        <Labeled label="Phone number" record={props.record}>
          <TextField
            source="phone_number"
            label="Phone number"
            record={props.record}
            emptyText="n/a"
            onClick={() => copyOnClick(props.record?.phone_number)}
          />
        </Labeled>
      </Box>
      <Box>
        <Box
          onClick={() =>
            copyOnClick(props.record?.districts?.toString().replace(',', ', '))
          }
        >
          <Box mb={1}>
            <FormLabel
              style={{
                fontSize: '0.75rem',
              }}
            >
              Districts
            </FormLabel>
          </Box>
          <Typography variant="body2">
            {props.record?.districts?.toString().replace(',', ', ')}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Labeled label="Fiscal Code" record={props.record}>
          <TextField
            source="fiscal_code"
            label="Fiscal code"
            record={props.record}
            emptyText="n/a"
            onClick={() => copyOnClick(props.record?.fiscal_code)}
          />
        </Labeled>
      </Box>
      <Box>
        <Box mb={1}>
          <FormLabel
            style={{
              fontSize: '0.75rem',
            }}
          >
            Image
          </FormLabel>
        </Box>
        <small>
          {props.record?.typeform_documents_payload ? (
            <a
              href={`${
                props.record?.typeform_documents_payload['selfie'] ||
                props.record?.typeform_documents_payload[
                  '*Allega qui una tua foto - mezzo busto*'
                ]
              }`}
              download
            >
              Download
            </a>
          ) : (
            <span>n/a</span>
          )}
        </small>
      </Box>
    </Box>
  );
}
