import React, { useState } from 'react';
import { Box, Card, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  Account,
  useGetAccountRegistrationAndFirstReservationDatesQuery,
} from '@ugo/graphql-operations';
import {
  filter,
  fromPairs,
  groupBy,
  map,
  pair,
  pipe,
  toPairs,
  values,
} from 'ramda';
import { differenceInDays } from 'date-fns';
import { Doughnut } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const RegistrationToFirstReservation = () => {
  const [value, setValue] = useState(0);
  const [
    numberOfAccountsByTimeToFirstReservation,
    setNumberOfAccountsByTimeToFirstReservation,
  ] = useState<any>([]);
  const [accountsByTimeToFirstReservation, setAcountsByTimeToFirstReservation] =
    useState<any>([]);

  const [
    numberOfAllAccountsThatMadeReservations,
    setNumberOfAllAccountsThatMadeReservations,
  ] = useState<number>(0);
  const [
    numberOfAccountsThatMadeRegistrationAndReservationOnSameDay,
    setNumberOfAccountsThatMadeRegistrationAndReservationOnSameDay,
  ] = useState<number>(0);
  const [
    percentageOfFirstDayReservationsVsOthers,
    setPercentageOfFirstDayReservationsVsOthers,
  ] = useState<string>('0');

  useGetAccountRegistrationAndFirstReservationDatesQuery({
    onCompleted: (data) => {
      const accountsThatMadeReservations = pipe(
        map((account: Account) => {
          const days_from_registration_to_reservation = differenceInDays(
            new Date(account.reservor_reservations[0]?.created_at),
            new Date(account.created_at)
          );

          return {
            email: account.email,
            registered_at: account.created_at || null,
            first_reservation_created_at:
              account.reservor_reservations[0]?.created_at || null,
            days_from_registration_to_reservation:
              days_from_registration_to_reservation >= 0
                ? days_from_registration_to_reservation
                : 'NO_RESERVATIONS_YET',
          };
        }),
        filter(
          (a: any) =>
            a.days_from_registration_to_reservation !== 'NO_RESERVATIONS_YET'
        )
        // @ts-ignore
      )(data?.account || []);

      const _numberOfAllAccountsThatMadeReservations =
        accountsThatMadeReservations.length;
      const _numberOfAccountsThatMadeRegistrationAndReservationOnSameDay =
        accountsThatMadeReservations.filter(
          (a) => a.days_from_registration_to_reservation === 0
        ).length;
      const _percentageOfFirstDayReservationsVsOthers = (
        (_numberOfAccountsThatMadeRegistrationAndReservationOnSameDay /
          _numberOfAllAccountsThatMadeReservations) *
        100
      ).toFixed(2);

      const accountsByTimeToFirstReservation = pipe(
        groupBy((a: any) => a.days_from_registration_to_reservation)
        // @ts-ignore
      )(accountsThatMadeReservations);

      const numberOfAccountsByTimeToFirstReservation = pipe(
        toPairs,
        // @ts-ignore
        map(([key, value]) => pair(key, value.length)),
        // @ts-ignore
        values,
        fromPairs
        // @ts-ignore
      )(accountsByTimeToFirstReservation);

      setNumberOfAccountsByTimeToFirstReservation(
        numberOfAccountsByTimeToFirstReservation
      );
      setAcountsByTimeToFirstReservation(accountsByTimeToFirstReservation);
      setNumberOfAllAccountsThatMadeReservations(
        _numberOfAllAccountsThatMadeReservations
      );
      setNumberOfAccountsThatMadeRegistrationAndReservationOnSameDay(
        _numberOfAccountsThatMadeRegistrationAndReservationOnSameDay
      );
      setPercentageOfFirstDayReservationsVsOthers(
        _percentageOfFirstDayReservationsVsOthers
      );
    },
  });

  const handleTabChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box mt={3}>
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            color="white"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab label={`Time to first reservation chart`} />
            <Tab label={`Patients by time to first reservation`} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <Paper>
            <Box p={2}>
              <div>
                <span>Number of all accounts that made reservations: </span>
                <b>{numberOfAllAccountsThatMadeReservations}</b>
              </div>
              <div>
                <span>
                  Number of accounts that made registration and reservation on
                  the first day:{' '}
                </span>
                <b>
                  {numberOfAccountsThatMadeRegistrationAndReservationOnSameDay}
                </b>
              </div>
              <div>
                <span>Percentage of first day reservations vs. others: </span>
                <b>{percentageOfFirstDayReservationsVsOthers}%</b>
              </div>
            </Box>
          </Paper>
          <Card>
            <Box p={2}>
              <Typography align="center" variant="h6">
                Time from registration to the first reservation (in days)
              </Typography>
              <Box position="relative">
                <Doughnut
                  data={{
                    labels: Object.keys(
                      numberOfAccountsByTimeToFirstReservation
                    ),
                    datasets: [
                      {
                        label:
                          'Time from registration to the first reservation (in days)',
                        data: Object.values(
                          numberOfAccountsByTimeToFirstReservation
                        ),
                        backgroundColor: colorScheme,
                      },
                    ],
                  }}
                />
              </Box>
            </Box>
          </Card>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Paper>
                  <Box p={2}>
                    Time between registration to the first reservation (in days)
                  </Box>
                </Paper>
                <Paper>
                  <Box mt={2} p={4}>
                    {Object.keys(accountsByTimeToFirstReservation).map(
                      (day: any, idx: number) => (
                        <div key={idx}>
                          <h4>Days apart: {day}</h4>
                          {accountsByTimeToFirstReservation[day]?.map(
                            (account: any) => (
                              <div key={account.email}>{account.email},</div>
                            )
                          )}
                          <br />
                          <hr />
                        </div>
                      )
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default RegistrationToFirstReservation;
