import { getDistanceCost, getHourlyIntervalTimeCost } from '../utils';
import { CalculationData, CalculationStrategy } from './calculation-strategy';

export class CaregivingWithUsersCarStrategy implements CalculationStrategy {
  calculate(data: CalculationData) {
    const CAREGIVING_WITH_USERS_CAR_PRICE_PER_HOUR = 15;
    const CAREGIVING_WITH_USERS_CAR_KASKO_PER_50_KM = 2.5;

    if (!data?.travel) {
      throw new Error('Travel data is required');
    }

    if (
      !data?.caregivingDuration &&
      typeof data.caregivingDuration !== 'number'
    ) {
      throw new Error('Caregiving duration is required');
    }

    const travelDistance = data.travel.getTravelDistance();
    const driverDistance = data.travel.getDriverDistance();
    const timeCost = getHourlyIntervalTimeCost(
      data?.caregivingDuration,
      CAREGIVING_WITH_USERS_CAR_PRICE_PER_HOUR
    );

    // Kasko
    const fiftyKmChunks = Math.ceil(travelDistance / 50);
    const kaskoCost = fiftyKmChunks * CAREGIVING_WITH_USERS_CAR_KASKO_PER_50_KM;

    const distanceCost = getDistanceCost(driverDistance, 0);

    return {
      timeCost,
      distanceCost,
      caregivingDuration: data.caregivingDuration,
      kaskoCost,
      driverDistance,
      travelDistance,
      totalCost: timeCost + distanceCost + kaskoCost,
    };
  }
}
