import React from 'react';
import { Box, Card, CircularProgress } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { useGetUserStatisticsQuery } from '@ugo/graphql-operations';
import Typography from '@material-ui/core/Typography';

const ActivatedUsersDoughnutChart = () => {
  const { data, loading } = useGetUserStatisticsQuery();

  return (
    <Card>
      <Box p={2}>
        <Typography align="center" variant="h6">
          Users
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box position="relative">
            <Doughnut
              data={{
                labels: ['Activated', 'Not activated'],
                datasets: [
                  {
                    label: 'Services booked by city',
                    data: [
                      data?.getUserStatistics?.patientsActivated,
                      data?.getUserStatistics?.patients &&
                      data?.getUserStatistics?.patientsActivated
                        ? data?.getUserStatistics?.patients -
                          data?.getUserStatistics?.patientsActivated
                        : 'Err',
                    ],
                    backgroundColor: colorScheme,
                  },
                ],
              }}
            />
            <Box
              position="absolute"
              top={'50%'}
              left={'50%'}
              style={{ transform: 'translateX(-50%) translateY(4px)' }}
            >
              {data?.getUserStatistics?.patients || 'err'}
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default ActivatedUsersDoughnutChart;
