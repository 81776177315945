import { CITY_RADIUS_LIMIT_IN_KMS } from './utils';

interface Distances {
  distanceBetween0AndA: number;
  distanceBetween0AndB: number;
  distanceBetweenAAndB: number;
}

export class Travel {
  public readonly distanceBetween0AndA: number;
  public readonly distanceBetween0AndB: number;
  public readonly distanceBetweenAAndB: number;
  private readonly isTwoWay: boolean;

  constructor(distances: Distances, isTwoWay: boolean) {
    this.distanceBetween0AndA = distances.distanceBetween0AndA;
    this.distanceBetween0AndB = distances.distanceBetween0AndB;
    this.distanceBetweenAAndB = distances.distanceBetweenAAndB;
    this.isTwoWay = isTwoWay;
  }

  getTravelDistance() {
    if (this.isTwoWay) {
      return this.distanceBetweenAAndB * 2;
    }

    return this.distanceBetweenAAndB;
  }

  getDriverDistance() {
    const isDistance0APayable =
      this.distanceBetween0AndA > CITY_RADIUS_LIMIT_IN_KMS;
    const isDistance0BPayable =
      this.distanceBetween0AndB > CITY_RADIUS_LIMIT_IN_KMS;

    let driverDistance = 0;

    if (this.isTwoWay) {
      /*
       * CC ➝ A ➝ B ➝ A ➝ CC
       * Driver always returns to A (because is_two_way = true) and back to CC
       */

      // Adding 0A if 0A > 15km
      // Multiplying by 2 because is_two_way always returns on 0A
      if (isDistance0APayable) {
        driverDistance += this.distanceBetween0AndA * 2;
      }
    } else {
      /**
       * CC ➝ A ➝ B ➝ CC
       * Driver returns on 0B because is_two_way = true
       */
      if (isDistance0APayable) {
        driverDistance += this.distanceBetween0AndA;
      }

      if (isDistance0BPayable) {
        driverDistance += this.distanceBetween0AndB;
      }

    }

    return driverDistance;
  }
}
