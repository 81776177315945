import React, { useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
} from '@material-ui/core';
import { GetPatientConversionReservationDocument } from '@ugo/graphql-operations';
import { print } from 'graphql';
import { ConversionsPerDayLineChart } from '../dashboard/charts/ConversionsPerDayLineChart';
import { ConversionsPerWeekLineChart } from '../dashboard/charts/ConversionsPerWeekLineChart';
import DateFilter from '../shared/DateFilter';
import { getDatePresetValue } from '@ugo/utils';
import { DateRange } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { ADMIN_SUPPORTED_CITIES, TRAFFIC_SOURCES } from '@ugo/data';
import { datePresetValueAtom } from '../../shared/globalState';
import { useAtom } from 'jotai';
import { useQueries } from '../../hooks/useQueries';
import { TabPanel } from '../shared/TabPanel';
import { ConversionsPerMonthLineChart } from '../dashboard/charts/ConversionsPerMonthLineChart';
import { DailyConversionsByAuthProvider } from '../dashboard/charts/DailyConversionsByAuthProvider';
import { WeeklyConversionsByAuthProvider } from '../dashboard/charts/WeeklyConversionsByAuthProvider';
import { ConversionsPerDayLineChartWithVariantId } from '../dashboard/charts/ConversionsPerDayLineChartWithVariantId';
import { ConversionsPerWeekLineChartWithVariantId } from '../dashboard/charts/ConversionsPerWeekLineChartWithVariantId';
import { ConversionsPerMonthLineChartWithVariantId } from '../dashboard/charts/ConversionsPerMonthLineChartWithVariantId';
import { TotalVsManualConversionsPerDayLineChart } from '../dashboard/charts/TotalVsManualConversionsPerDayLineChart';

const Conversion = () => {
  const [datePresetValue] = useAtom(datePresetValueAtom);
  const [value, setValue] = React.useState(0);
  const [numberOfConvertedPatients, setNumberOfConvertedPatients] =
    useState<number>(0);
  const [
    numberOfManuallyConvertedPatients,
    setNumberOfManuallyConvertedPatients,
  ] = useState<number>(0);
  const [dateRange, setDateRange] = useState<DateRange<Date>>(
    getDatePresetValue(datePresetValue)
  );
  const [
    shouldIncludeManualConversionReservations,
    setShouldIncludeManualConversionReservations,
  ] = useState(false);
  const [trafficSource, setTrafficSource] = useState(null);
  const [city, setCity] = useState(null);
  const { refetchPatientConversionReservations } = useQueries();

  const [conversionReservations, setConversionReservations] = useState([]);
  const [allConversionReservations, setAllConversionReservations] = useState(
    []
  );

  const onHandleChange = (event: any, newValue: any) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        setShouldIncludeManualConversionReservations(false);
        refetchData({ _shouldIncludeManualConversionReservations: false });
        break;
      case 1:
        setShouldIncludeManualConversionReservations(true);
        refetchData({ _shouldIncludeManualConversionReservations: true });
        refetchData({});
        break;
    }
  };

  const refetchData = async ({
    _trafficSource,
    _city,
    _dateRange,
    _shouldIncludeManualConversionReservations,
  }: {
    _trafficSource?: string;
    _city?: string;
    _dateRange?: any;
    _shouldIncludeManualConversionReservations?: boolean;
  }) => {
    const patientConversionReservationsInput = {
      service_city: _city || city,
      traffic_source: _trafficSource || trafficSource,
      dateRange: _dateRange || dateRange || [],
    };

    if (typeof _shouldIncludeManualConversionReservations !== 'undefined') {
      patientConversionReservationsInput.shouldIncludeManualConversionReservations =
        typeof _shouldIncludeManualConversionReservations === 'boolean'
          ? _shouldIncludeManualConversionReservations
          : shouldIncludeManualConversionReservations;
    }

    const _conversionReservation = await refetchPatientConversionReservations(
      patientConversionReservationsInput
    );

    if (typeof _shouldIncludeManualConversionReservations !== 'undefined') {
      // @ts-ignore
      setConversionReservations(_conversionReservation);

      if (_shouldIncludeManualConversionReservations) {
        setNumberOfManuallyConvertedPatients(_conversionReservation.length);
      } else {
        setNumberOfConvertedPatients(_conversionReservation.length);
      }
    } else {
      setAllConversionReservations(_conversionReservation);
    }
  };

  /**
   * @description Triggers on date range change
   *  - It also triggers when the page loads initially because the date range is set to the default value
   */
  const onDateRangeChange = async (_dateRange: any) => {
    setDateRange(_dateRange);

    refetchData({
      _dateRange,
    });
  };

  const onTrafficSourceChange = async (event: any) => {
    const _trafficSource = event?.target?.value || undefined;
    setTrafficSource(_trafficSource);

    refetchData({
      _trafficSource,
    });
  };

  const onCityChange = async (event: any) => {
    const _city = event?.target?.value || undefined;
    setCity(_city);

    refetchData({
      _city,
    });
  };

  return (
    <Box>
      <Paper>
        <Box p={1} display="flex" justifyContent="space-between">
          <Box>
            <FormControl>
              <InputLabel id="traffic-source-label">
                <Box m={1}>Traffic Source</Box>
              </InputLabel>
              <Select
                labelId="traffic-source-label"
                margin="dense"
                variant="filled"
                onChange={onTrafficSourceChange}
                inputProps={{ variant: 'filled', size: 'large' }}
                placeholder="Traffic Source"
                value={trafficSource}
              >
                <MenuItem value={'_'}>Any Source</MenuItem>
                {TRAFFIC_SOURCES.map((ts) => (
                  <MenuItem key={ts.id} value={ts.id}>
                    {ts.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box mr={2} display="inline" />

            <FormControl>
              <InputLabel id="city-label">
                <Box m={1}>City</Box>
              </InputLabel>
              <Select
                labelId="city-label"
                margin="dense"
                variant="filled"
                onChange={onCityChange}
                inputProps={{ variant: 'filled', size: 'large' }}
                placeholder="City"
                value={city}
              >
                <MenuItem value={'_'}>All cities</MenuItem>
                {ADMIN_SUPPORTED_CITIES.map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <DateFilter
            defaultPreset={datePresetValue}
            rangeChange={onDateRangeChange}
          />
        </Box>
      </Paper>
      <Box mt={3}>
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            color="white"
            textColor="primary"
            onChange={onHandleChange}
            aria-label="simple tabs example"
          >
            <Tab label={`Converted Patients (${numberOfConvertedPatients})`} />
            <Tab
              label={`Manually Converted Patients (${numberOfManuallyConvertedPatients})`}
            />
            <Tab label={`Converted by provider`} />
            <Tab label={`Converted by VARIANT_ID`} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Paper>
                  <Box height="350px" p={2}>
                    <ConversionsPerDayLineChart
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
                <Paper>
                  <Box height="350px" mt={2} p={2}>
                    <ConversionsPerWeekLineChart
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
                <Paper>
                  <Box height="350px" mt={2} p={2}>
                    <ConversionsPerMonthLineChart
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Paper>
                  <Box height="350px" p={2}>
                    <TotalVsManualConversionsPerDayLineChart
                      allConversionReservations={allConversionReservations}
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
                <Paper>
                  <Box height="350px" mt={2} p={2}>
                    <ConversionsPerDayLineChart
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
                <Paper>
                  <Box height="350px" mt={2} p={2}>
                    <ConversionsPerWeekLineChart
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
                <Paper>
                  <Box height="350px" mt={2} p={2}>
                    <ConversionsPerMonthLineChart
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Paper>
                  <Box height="350px" p={2}>
                    <DailyConversionsByAuthProvider
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item sm={12}>
                <Paper>
                  <Box height="350px" p={2}>
                    <WeeklyConversionsByAuthProvider
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Paper>
                  <Box height="350px" p={2}>
                    <ConversionsPerDayLineChartWithVariantId
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
                <Paper>
                  <Box height="350px" mt={2} p={2}>
                    <ConversionsPerWeekLineChartWithVariantId
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
                <Paper>
                  <Box height="350px" mt={2} p={2}>
                    <ConversionsPerMonthLineChartWithVariantId
                      reservations={conversionReservations}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Conversion;
