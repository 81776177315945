import React, { useEffect, useState } from 'react';
import { colorScheme } from '@ugo/data';
import { Line } from 'react-chartjs-2';
import { endOfWeek, format, isSameWeek, startOfWeek } from 'date-fns';
import { getWeeksArray, useUtilities } from '@ugo/utils';
import { useQueries } from '../../../hooks/useQueries';
import {
  Scalars,
  useGetDailyConversionsByAuthProviderQuery,
} from '@ugo/graphql-operations';
import { isNil, reject } from 'ramda';
import { CircularProgress } from '@material-ui/core';

export const WeeklyConversionsByAuthProvider = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  const [weeks, setWeeks] = useState<Date[]>([]);
  const [googleData, setGoogleData] = useState();
  const [facebookData, setFacebookData] = useState();
  const [passwordData, setPasswordData] = useState();
  const [totalData, setTotalData] = useState();
  const { data, loading } = useGetDailyConversionsByAuthProviderQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: reject(isNil, {
        converted_at: {
          _gte: startDate as Scalars['timestamptz'],
          _lte: endDate as Scalars['timestamptz'],
        },
      }),
    },
  });

  useEffect(() => {
    setWeeks(getWeeksArray(startDate, endDate));
    if (data) {
      const _googleData = weeks.map((day) =>
        // @ts-ignore
        data.vw_stats_daily_conversions_by_auth_provider
          .filter((d) => d.auth_provider === 'google.com')
          .filter((d) =>
            isSameWeek(new Date(day), new Date(d.converted_at), {
              weekStartsOn: 1,
            })
          )
          .reduce((acc, curr) => acc + curr.accounts_converted || 0, 0)
      );
      // @ts-ignore
      setGoogleData(_googleData);

      const _facebookData = weeks.map((day) =>
        // @ts-ignore
        data.vw_stats_daily_conversions_by_auth_provider
          .filter((d) => d.auth_provider === 'facebook.com')
          .filter((d) =>
            isSameWeek(new Date(day), new Date(d.converted_at), {
              weekStartsOn: 1,
            })
          )
          .reduce((acc, curr) => acc + curr.accounts_converted || 0, 0)
      );
      // @ts-ignore
      setFacebookData(_facebookData);

      const _passwordData = weeks.map((day) =>
        // @ts-ignore
        data.vw_stats_daily_conversions_by_auth_provider
          .filter((d) => d.auth_provider === 'password')
          .filter((d) =>
            isSameWeek(new Date(day), new Date(d.converted_at), {
              weekStartsOn: 1,
            })
          )
          .reduce((acc, curr) => acc + curr.accounts_converted || 0, 0)
      );

      // @ts-ignore
      setPasswordData(_passwordData);

      const _totalData = weeks.map((day) =>
        // @ts-ignore
        data.vw_stats_daily_conversions_by_auth_provider
          .filter((d) =>
            isSameWeek(new Date(day), new Date(d.converted_at), {
              weekStartsOn: 1,
            })
          )
          .reduce((acc, curr) => acc + (curr.accounts_converted || 0), 0)
      );

      // @ts-ignore
      setTotalData(_totalData);
    }
  }, [startDate, endDate, data]);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      <Line
        data={{
          labels: weeks.map((w) => {
            const date = new Date(w);
            const weekStart = startOfWeek(date, { weekStartsOn: 1 });
            const weekEnd = endOfWeek(date, {
              weekStartsOn: 1,
            });

            return `${format(weekStart, 'dd/MM')} - ${format(
              weekEnd,
              'dd/MM'
            )}`;
          }),
          datasets: [
            {
              label: 'Total',
              data: totalData,
              lineTension: 0,
              pointRadius: 5,
              borderColor: colorScheme[0],
              backgroundColor: 'transparent',
            },
            {
              label: 'Google',
              data: googleData,
              lineTension: 0,
              pointRadius: 5,
              borderColor: colorScheme[5],
              backgroundColor: 'transparent',
            },
            {
              label: 'Facebook',
              data: facebookData,
              lineTension: 0,
              pointRadius: 5,
              borderColor: colorScheme[3],
              backgroundColor: 'transparent',
            },
            {
              label: 'Password',
              data: passwordData,
              lineTension: 0,
              pointRadius: 5,
              borderColor: colorScheme[2],
              backgroundColor: 'transparent',
            },
          ],
        }}
        options={{
          title: {
            display: true,
            text: 'Conversions by provider',
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                  beginAtZero: true,
                  min: 0,
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
