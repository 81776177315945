import {
  addDays,
  addMonths,
  addWeeks,
  differenceInMilliseconds,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameWeek,
  setSeconds,
  startOfDay,
  startOfDecade,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import {
  format as _format,
  toDate,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz';
import { SupportedTimezones } from '@ugo/models';

export enum DateRangePreset {
  Today,
  ThisWeek,
  ThisMonth,
  ThisYear,
  AllTime,
}

/**
 * Returns an array of days ranging from start to end
 */
export const getDaysArray = (
  startDate: Date,
  endDate: Date,
  stripAfterToday = false
) => {
  if (isBefore(endDate, startDate)) {
    throw 'Start date should be before end date';
  }

  let _startDate = new Date(startDate.toISOString());
  const _endDate = new Date(endDate.toISOString());
  const days: Date[] = [];

  while (!isSameDay(_startDate, _endDate)) {
    const day = startOfDay(_startDate);
    if (stripAfterToday && isAfter(day, startOfDay(new Date()))) {
      _startDate = _endDate;
    } else {
      days.push(day);
      _startDate = addDays(_startDate, 1);
    }
  }

  return days;
};

/**
 * Returns an array of weeks ranging from start to end
 */
export const getWeeksArray = (startDate: Date, endDate: Date) => {
  if (isBefore(endDate, startDate)) {
    throw 'Start date should be before end date';
  }

  let _startDate = new Date(startDate.toISOString());
  const _endDate = new Date(endDate.toISOString());
  const weeks: Date[] = [];

  while (!isSameWeek(_startDate, _endDate, { weekStartsOn: 1 })) {
    weeks.push(startOfWeek(_startDate, { weekStartsOn: 1 }));
    _startDate = addWeeks(_startDate, 1);
  }

  return weeks;
};

/**
 * Returns an array of months ranging from start to end
 */
export const getMonthsArray = (startDate: Date, endDate: Date) => {
  if (isBefore(endDate, startDate)) {
    throw 'Start date should be before end date';
  }

  let _startDate = new Date(startDate.toISOString());
  const _endDate = new Date(endDate.toISOString());
  const months: Date[] = [];

  while (!isSameMonth(_startDate, _endDate)) {
    months.push(startOfMonth(_startDate));
    _startDate = addMonths(_startDate, 1);
  }

  return months;
};

export const getDatePresetValue = (
  preset: DateRangePreset
): [dateStart: Date, dateEnd: Date] => {
  switch (preset) {
    case DateRangePreset.Today: {
      return [startOfDay(new Date()), endOfDay(new Date())];
    }
    case DateRangePreset.ThisWeek: {
      return [startOfWeek(new Date()), endOfWeek(new Date())];
    }
    case DateRangePreset.ThisMonth: {
      return [startOfMonth(new Date()), endOfMonth(new Date())];
    }
    case DateRangePreset.ThisYear: {
      return [startOfYear(new Date()), endOfYear(new Date())];
    }
    case DateRangePreset.AllTime: {
      return [startOfDecade(new Date()), endOfYear(new Date())];
    }
  }
};

export const getClientTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const toTimestampz = (date: any) => {
  return date
    ? _format(
        utcToZonedTime(date, getClientTimezone()),
        "yyyy-MM-dd'T'HH:mm:ssXXX",
        {
          timeZone: getClientTimezone(),
        }
      )
    : '';
};

export const fromTimestamptz = (timestamptz: string): Date => {
  return zonedTimeToUtc(timestamptz, getClientTimezone());
};

export const getTimeFromTimestamptz = (
  timestamptz: string,
  format?: string
) => {
  try {
    return (
      (timestamptz &&
        _format(fromTimestamptz(timestamptz), format || 'HH:mm')) ||
      ''
    );
  } catch (err) {
    console.error(err);
    return '';
  }
};

export const getDateFromTimestamptz = (
  timestamptz: string,
  format?: string
) => {
  try {
    return (
      (timestamptz &&
        _format(fromTimestamptz(timestamptz), format || 'yyyy-MM-dd')) ||
      ''
    );
  } catch (err) {
    console.error(err);
    return '';
  }
};

/**
 * @description Returns a very very nice date from
 * timestamptz or date (eg. 2021-01-24)
 */
export const toNiceDate = (timestamptz: string, format?: string) => {
  try {
    return timestamptz
      ? _format(toDate(timestamptz), format || 'dd/MM/yyyy', {
          timeZone: getClientTimezone(),
        })
      : '';
  } catch (err) {
    console.error(err);
    return '';
  }
};

/**
 * @description Formats UTC date to zoned date time
 *  - Mainly used for exports, but not limited to
 */
export const formatDateToZonedDatetime = (date: Date) => {
  const zoned = utcToZonedTime(date, SupportedTimezones.Rome);
  return format(zoned, 'dd/MM/yyyy HH:mm');
};

/**
 * @description Returns milliseconds from hours
 */
export const getMillisecondsFromHours = (hours: any): number => {
  return hours * 1000 * 60 * 60;
};

/**
 * @description Returns hours with decimal precision
 */
export const getHoursFromMilliseconds = (milliseconds: number) => {
  return milliseconds / (1000 * 60 * 60);
};

/**
 * @description Returns duration in hours with decimal precision
 */
export const getDurationInHours = (start: Date, end: Date) => {
  return getHoursFromMilliseconds(differenceInMilliseconds(start, end));
};

export const convertBrowserTimeToItalian = ({ date }: { date: Date }) => {
  const utcDate = zonedTimeToUtc(date, getClientTimezone());
  const italianDate = utcToZonedTime(utcDate, SupportedTimezones.Rome);

  return italianDate;
};

/**
 * @description
 * Returns timestamptz Date.now() value for the client's timezone
 *  - without the seconds (this saves making multiple requests
 *  that apollo makes if there are new parameters)
 *
 *  TODO: rename to nowTimestamptz
 */
export const now = () => {
  return toTimestampz(setSeconds(Date.now(), 0));
};
