import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { ADMIN_SUPPORTED_CITIES, SUPPORTED_SERVICES } from '@ugo/data';
import {
  GetAccountDriverDocument,
  GetAccountDriverQuery,
  useActivateDriverMutation,
  useChangeEmailMutation,
} from '@ugo/graphql-operations';
import { useNotify } from 'react-admin';
import { HasuraSessionVariables, Roles } from '@ugo/models';

export interface ConfirmDriverSettingsValue {
  city_list: string[];
  service_list: string[];
}

export const AccountEmailChangeDialog = ({
  open,
  closeCallback,
  accountId,
}: {
  open: boolean;
  closeCallback: () => void;
  accountId: string;
}) => {
  const notify = useNotify();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const [changeEmail, { loading }] = useChangeEmailMutation();
  const [responseData, setResponseData] = useState<any>(null);

  const handleChangeEmail = async () => {
    const emailRegex = new RegExp(/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/);
    if (!emailRegex.test(email)) {
      setError('Email must be a valid email');
      return;
    }
    try {
      const data = await changeEmail({
        variables: {
          account_id: accountId,
          email,
        },
        context: {
          headers: {
            [HasuraSessionVariables.ActiveRole]: Roles.Administrator,
          },
        },
      });

      setResponseData(data?.data?.changeEmail?.data);
    } catch (e: any) {
      setError(e.message);
      console.error(e);
      notify(e.message);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      // onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Account email change</DialogTitle>
      <DialogContent>
        {loading && <span>Loading...</span>}
        {responseData && (
          <div>
            <p style={{ marginBottom: 0 }}>
              <b>NOTE:</b>
            </p>
            <p style={{ marginBottom: 0 }}>
              After a succesfful update, you are required to also update Skillsincloud user manually in case the user is a driver.
            </p>
            <p style={{ marginBottom: 5 }}></p>
            <pre style={{ fontSize: 12 }}>
              <code>{JSON.stringify(responseData, null, 2)}</code>
            </pre>
          </div>
        )}
        {!responseData && !loading && (
          <>
            <DialogContentText>
              <b>NOTE:</b> This action will attempt to update the email address
              across all relevant platforms and services. If any of the actions
              fail, you will have to manually update the email address for that
              platform or service.
            </DialogContentText>
            <Input
              placeholder="New email"
              fullWidth
              value={email}
              onChange={(v) => setEmail(v.target.value)}
            />
            {error && <p style={{ color: 'red', fontSize: 12 }}>{error}</p>}
            <Box mb="2em"></Box>
            <Box></Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeCallback} color="primary">
          Close
        </Button>
        {!responseData && (
          <Button
            onClick={handleChangeEmail}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            Change
          </Button>
        )}
        {/*<Button disabled={loading} onClick={handleCancel} color="primary">*/}
        {/*  <Close />*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*  disabled={loading}*/}
        {/*  onClick={handleConfirm}*/}
        {/*  color="primary"*/}
        {/*  variant="contained"*/}
        {/*  autoFocus*/}
        {/*>*/}
        {/*  <Check />*/}
        {/*</Button>*/}
      </DialogActions>
    </Dialog>
  );
};
