import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Bar } from 'react-chartjs-2';
import { ADMIN_SUPPORTED_CITIES } from '../../../../../../libs/data/src/admin-cities-data';
import { colorScheme } from '@ugo/data';
import { Box, Card } from '@material-ui/core';
import { ChartComponentProps } from '@ugo/models';
import { Reservation_Status_Enum } from '@ugo/graphql-operations';

const groupServiceCountByCity = (services: any) => {
  const servicesByCities: number[] = [];
  const finishedServicesByCities: number[] = [];
  const duplicatedServicesByCities: number[] = [];

  const indexedCities: { [key: string]: number } = {};
  ADMIN_SUPPORTED_CITIES.forEach((c, i) => {
    indexedCities[c] = i;
    servicesByCities.push(0);
    finishedServicesByCities.push(0);
    duplicatedServicesByCities.push(0);
  });

  Object.keys(services).forEach((spk) => {
    const city = services[spk].metadata.service_city;
    if (ADMIN_SUPPORTED_CITIES.indexOf(city) > -1) {
      servicesByCities[indexedCities[city]] += 1;

      if (services[spk].status === Reservation_Status_Enum.Finished) {
        finishedServicesByCities[indexedCities[city]] += 1;
      }

      if (services[spk].duplication_reference_id) {
        duplicatedServicesByCities[indexedCities[city]] += 1;
      }
    }
  });

  return [
    servicesByCities,
    finishedServicesByCities,
    duplicatedServicesByCities,
  ];
};

const ServicesBookedByCityBarChart = (props: ChartComponentProps) => {
  const [graphData, setGraphData] = useState<Array<number[]>>([]);

  const { data } = useGetList(
    'reservation',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'DESC' },
    {
      'created_at@_gte': props.startDate,
      'created_at@_lte': props.endDate,
      'is_manual@_eq': props.shouldIncludeManualB2cReservations,
    }
  );

  useEffect(() => {
    setGraphData(groupServiceCountByCity(data));
  }, [data]);

  return (
    <Card>
      <Box p={2}>
        <Bar
          data={{
            labels: ADMIN_SUPPORTED_CITIES,
            datasets: [
              {
                label: 'All',
                data: graphData[0],
                backgroundColor: colorScheme[0],
              },
              {
                label: 'Finished',
                data: graphData[1],
                backgroundColor: colorScheme[1],
              },
              {
                label: 'Duplicated',
                data: graphData[2],
                backgroundColor: colorScheme[2],
              },
            ],
          }}
          options={{
            title: {
              display: true,
              text: 'Services booked by city',
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    precision: 0,
                    beginAtZero: true,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </Box>
    </Card>
  );
};
export default ServicesBookedByCityBarChart;
