import React, { useState } from 'react';
import { Box, Card, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../shared/TabPanel';
import { Line } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { useGetParetoDistributionForAccountsVsReservationsQuery } from '@ugo/graphql-operations';

export const ParetoDistributionForAccountsVsReservations = () => {
  const [
    convertedPatientAccountsReservations,
    setConvertedPatientAccountsReservations,
  ] = useState([]);
  const [allPatientAccountsReservations, setAllPatientAccountsReservations] =
    useState([]);

  useGetParetoDistributionForAccountsVsReservationsQuery({
    variables: {
      min_reservation_count: 0,
    },
    onCompleted: (data) => {
      setAllPatientAccountsReservations(
        // @ts-ignore
        data?.fn_stats_get_pareto_distribution_account_reservation || []
      );
    },
  });

  useGetParetoDistributionForAccountsVsReservationsQuery({
    variables: {
      min_reservation_count: 1,
    },
    onCompleted: (data) => {
      setConvertedPatientAccountsReservations(
        // @ts-ignore
        data?.fn_stats_get_pareto_distribution_account_reservation || []
      );
    },
  });

  const allPatientAccountsLabels = allPatientAccountsReservations.map(
    (ra) =>
      `${ra.cumulative_accounts_percentage?.toFixed(2)}% - ${
        ra.reservation_count
      }`
  );
  const allPatientReservationsLabels = allPatientAccountsReservations.map(
    (ra) => ra.cumulative_reservations_percentage?.toFixed(2)
  );

  const convertedPatientAccountsLabels =
    convertedPatientAccountsReservations.map(
      (ra) =>
        `${ra.cumulative_accounts_percentage?.toFixed(2)}% - ${
          ra.reservation_count
        }`
    );
  const convertedPatientReservationsLabels =
    convertedPatientAccountsReservations.map((ra) =>
      ra.cumulative_reservations_percentage?.toFixed(2)
    );

  const [value, setValue] = React.useState<string>(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box mt={3}>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          color="white"
          textColor="primary"
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label={<div>Converted Patients</div>} />
          <Tab label={<div>All Patients</div>} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box mt={2}>
                <Paper>
                  <Card>
                    <Box height="350px" p={2}>
                      <Line
                        data={{
                          labels: convertedPatientAccountsLabels,
                          datasets: [
                            {
                              label: '% of reservations',
                              data: convertedPatientReservationsLabels,
                              lineTension: 0,
                              pointRadius: 5,
                              borderColor: colorScheme[0],
                              backgroundColor: 'transparent',
                            },
                          ],
                        }}
                        options={{
                          title: {
                            display: true,
                            text: 'Cumulative amount of reservations made by converted patient accounts',
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            yAxes: [
                              {
                                scaleLabel: {
                                  display: true,
                                  labelString: '% of reservations',
                                },
                                ticks: {
                                  precision: 0,
                                  beginAtZero: true,
                                  min: 0,
                                  callback: (value, index, values) => {
                                    return `${value}%`;
                                  },
                                },
                              },
                            ],
                            xAxes: [
                              {
                                scaleLabel: {
                                  display: true,
                                  labelString: '% of accounts',
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </Box>
                  </Card>
                </Paper>
              </Box>
              <Box mt={2}>
                <Paper p={2}>
                  <pre>
                    {JSON.stringify(
                      convertedPatientAccountsReservations,
                      null,
                      2
                    )}
                  </pre>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper>
          <Card>
            <Box height="350px" p={2}>
              <Line
                data={{
                  labels: allPatientAccountsLabels,
                  datasets: [
                    {
                      label: '% of reservations',
                      data: allPatientReservationsLabels,
                      lineTension: 0,
                      pointRadius: 5,
                      borderColor: colorScheme[0],
                      backgroundColor: 'transparent',
                    },
                  ],
                }}
                options={{
                  title: {
                    display: true,
                    text: 'Cumulative amount of reservations made by all patient accounts',
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: '% of reservations',
                        },
                        ticks: {
                          precision: 0,
                          beginAtZero: true,
                          min: 0,
                          callback: (value, index, values) => {
                            return `${value}%`;
                          },
                        },
                      },
                    ],
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: '% of accounts',
                        },
                      },
                    ],
                  },
                }}
              />
            </Box>
          </Card>
        </Paper>
        <Box mt={2}>
          <Paper>
            <pre>{JSON.stringify(allPatientAccountsReservations, null, 2)}</pre>
          </Paper>
        </Box>
      </TabPanel>
    </Box>
  );
};
