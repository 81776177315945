import React from 'react';
import { Create } from 'react-admin';
import validator from 'validator';
import { ManualPatientForm } from './ManualPatientForm';

// TODO: Move shared form logic to a separate component
export const ManualPatientCreate = (props: any) => {
  const validateManualPatientCreation = (values: any) => {
    const errors = {
      ...(['email', 'first_name', 'last_name', 'phone_number']
        .filter((v) => !values[v])
        .reduce((accumulator: any, value: any) => {
          accumulator[value] = `${value} is required`;
          return accumulator;
        }, {}) || {}),
    };

    if (!validator.isEmail(values.email || '')) {
      errors.email = `${values.email} is not a valid email`;
    }

    return errors;
  };

  return (
    <Create {...props}>
      <ManualPatientForm {...props} validate={validateManualPatientCreation} />
    </Create>
  );
};
