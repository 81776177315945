import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useGetDuplicatedReservationsQuery } from '@ugo/graphql-operations';

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const CarnetDuplication = () => {
  const [value, setValue] = React.useState(0);
  const [duplicatedReservations, setDuplicatedReservations] = useState([]);
  const [numberOfDuplicatedReservations, setNumberOfDuplicatedReservations] =
    useState(0);
  const [
    priceEstimateSumOfDuplicatedReservations,
    setPriceEstimateSumOfDuplicatedReservations,
  ] = useState(0);

  useGetDuplicatedReservationsQuery({
    onCompleted: (data) => {
      setDuplicatedReservations(
        // @ts-ignore
        data.reservation.map((res) => ({
          id: res.id,
          reservor_id: res.reservor?.id || '',
          reservor_email: res.reservor?.email || '',
          price_estimate: res.price_estimate || 0,
          personal_data: res.reservor?.personal_data || {},
          starts_at: res.starts_at,
          created_at: res.created_at,
        })) || []
      );

      setNumberOfDuplicatedReservations(
        data.reservation_aggregate.aggregate?.count || 0
      );
      setPriceEstimateSumOfDuplicatedReservations(
        // @ts-ignore
        data.reservation_aggregate.aggregate?.sum?.price_estimate || 0
      );
    },
  });

  // @ts-ignore
  useEffect(async () => {
    //
  }, []);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box mt={3}>
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            color="white"
            textColor="primary"
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              label={`Duplicated Reservations (${numberOfDuplicatedReservations})`}
            />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Paper>
                  <Box p={4}>
                    {`Value of all duplicated reservations (price_estimate) -  ${priceEstimateSumOfDuplicatedReservations}€`}
                  </Box>
                </Paper>
                <Paper>
                  <Box mt={2} p={4}>
                    {duplicatedReservations.map((res: any) => (
                      <div key={res.id}>
                        <pre>{JSON.stringify(res, null, 2)}</pre>
                        <br />
                      </div>
                    ))}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default CarnetDuplication;
