import React, { useEffect, useState } from 'react';
import { colorScheme } from '@ugo/data';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { useUtilities } from '@ugo/utils';

export const ConversionsPerDayLineChart = ({
  reservations,
  startDate,
  endDate,
}: {
  reservations: any[];
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { groupServiceCountByDay } = useUtilities();
  const [graphData, setGraphData] = useState<Array<number[]>>([]);
  const [days, setDays] = useState<string[]>([]);

  useEffect(() => {
    const { days, reservationsByDays } = groupServiceCountByDay(
      reservations,
      [],
      startDate,
      endDate
    );
    setDays(days.map((d) => format(d, 'yyyy-MM-dd')));
    setGraphData([reservationsByDays]);
  }, [reservations, startDate, endDate]);

  return (
    <Line
      data={{
        labels: days,
        datasets: [
          {
            label: 'First reservations',
            data: graphData[0],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[0],
            backgroundColor: 'transparent',
          },
        ],
      }}
      options={{
        title: {
          display: true,
          text: 'Conversions per day',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
      }}
    />
  );
};
