import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Bar } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { Box, Card } from '@material-ui/core';
import { SUPPORTED_SERVICES } from '../../../../../../libs/data/src/services-data';
import { ChartComponentProps } from '@ugo/models';
import { Reservation_Status_Enum } from '@ugo/graphql-operations';

const groupServiceCountByServiceType = (services: any) => {
  const servicesByService: number[] = [];
  const finishedServicesByService: number[] = [];
  const duplicatedServicesByService: number[] = [];
  const indexedServices: { [key: string]: number } = {};

  SUPPORTED_SERVICES.forEach((c, i) => {
    indexedServices[c] = i;
    servicesByService.push(0);
    finishedServicesByService.push(0);
    duplicatedServicesByService.push(0);
  });

  Object.keys(services).forEach((spk) => {
    const service = services[spk].service_id;
    if (SUPPORTED_SERVICES.indexOf(service) > -1) {
      servicesByService[indexedServices[service]] += 1;

      if (services[spk].status === Reservation_Status_Enum.Finished) {
        finishedServicesByService[indexedServices[service]] += 1;
      }

      if (services[spk].duplication_reference_id) {
        duplicatedServicesByService[indexedServices[service]] += 1;
      }
    }
  });

  return [
    servicesByService,
    finishedServicesByService,
    duplicatedServicesByService,
  ];
};

const ServicesBookedByCityBarChart = (props: ChartComponentProps) => {
  const [graphData, setGraphData] = useState<Array<number[]>>([]);

  const { data } = useGetList(
    'reservation',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'DESC' },
    {
      'created_at@_gte': props.startDate,
      'created_at@_lte': props.endDate,
      'is_manual@_eq': props.shouldIncludeManualB2cReservations,
    }
  );

  useEffect(() => {
    setGraphData(groupServiceCountByServiceType(data));
  }, [data]);

  return (
    <Card>
      <Box p={2}>
        <Bar
          data={{
            labels: SUPPORTED_SERVICES,
            datasets: [
              {
                label: 'All',
                data: graphData[0],
                backgroundColor: colorScheme[0],
              },
              {
                label: 'Finished',
                data: graphData[1],
                backgroundColor: colorScheme[1],
              },
              {
                label: 'Duplicated',
                data: graphData[2],
                backgroundColor: colorScheme[2],
              },
            ],
          }}
          options={{
            title: {
              display: true,
              text: 'Services booked by service type',
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    precision: 0,
                    beginAtZero: true,
                    min: 0,
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    display: false,
                  },
                },
              ],
            },
          }}
        />
      </Box>
    </Card>
  );
};
export default ServicesBookedByCityBarChart;
