import { Box } from '@material-ui/core';
import React from 'react';
import {
  DateField,
  Edit,
  FormWithRedirect,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
} from 'react-admin';

export const AccountPenaltyEdit = (props: any) => {
  return (
    <Edit {...props}>
      <FormWithRedirect
        render={(props) => {
          return (
            <>
              <Box p="1em">
                <Box mb="1em">
                  <label
                    style={{
                      display: 'block',
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    Id
                  </label>
                  <TextField label="id" source="id" />
                </Box>
                <Box mb="1em">
                  <label
                    style={{
                      display: 'block',
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    Created at
                  </label>
                  <DateField showTime={true} source="created_at" />
                </Box>
                <Box mb="1em">
                  <label
                    style={{
                      display: 'block',
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    Type
                  </label>
                  {props?.record?.type}
                  <label
                    style={{
                      marginTop: '1em',
                      display: 'block',
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    Reason
                  </label>
                  {props?.record?.type === 'PATIENT_CANCEL' && (
                    <div>
                      {props?.record?.reservation?.patient_cancellation_reason}
                    </div>
                  )}
                  {props?.record?.type === 'DRIVER_CANCEL' &&
                    props.record.account_penalty_reservation_cancellation
                      ?.note && (
                      <div>
                        {JSON.stringify(
                          props.record.account_penalty_reservation_cancellation
                            ?.note
                        )}
                      </div>
                    )}
                </Box>
                <Box mb="1em">
                  <SelectInput
                    choices={[
                      { id: 'CANCELED', name: 'CANCELED' },
                      { id: 'APPROVED', name: 'APPROVED' },
                      { id: 'PAID', name: 'PAID' },
                      { id: 'IN_REVIEW', name: 'IN_REVIEW' },
                    ]}
                    source={'status'}
                  />
                </Box>
                <p style={{ fontSize: '12px' }}>
                  NOTE:
                  <br />
                  Changing status from IN_REVIEW to APPROVED will trigger
                  penalty process. User will receive a payment link and will be
                  blocked from accepting new services in case the user is a
                  driver.
                </p>
              </Box>
              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <SaveButton
                    redirect={false}
                    disabled={props.pristine}
                    saving={props.saving}
                    handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                  />
                </Box>
              </Toolbar>
            </>
          );
        }}
      />
    </Edit>
  );
};
