/**
 * @description A list of all b2b projects
 */
export const B2B_PROJECT_NAMES = [
  { id: 'TBD', name: 'TBD' },
  { id: 'AMGPSP01', name: 'AMGPSP01' },
  { id: 'AMGPSP02', name: 'AMGPSP02' },
  { id: 'ANTPSP01', name: 'ANTPSP01' },
  { id: 'AMGCOV19', name: 'AMGCOV19' },
  { id: 'MPSCOV19', name: 'MPSCOV19' },
  { id: 'TIMCOV19', name: 'TIMCOV19' },
  { id: 'ROCPSP01', name: 'ROCPSP01' },
  { id: 'ICCCONS01', name: 'ICCCONS01' },
  { id: 'ERPCONS01', name: 'ERPCONS01' },
  { id: 'AMOICH01', name: 'AMOICH01' },
  { id: 'AMOCONS01', name: 'AMOCONS01' },
  { id: 'NOVPSP01', name: 'NOVPSP01' },
  { id: 'COMFIR01', name: 'COMFIR01' },
  { id: 'ROCPSP02', name: 'ROCPSP02' },
  { id: 'AXA01', name: 'AXA01' },
  { id: 'DAYW01', name: 'DAYW01' },
  { id: 'SSMS01', name: 'SSMS01' },
  { id: 'BIBLIOEXP20', name: 'BIBLIOEXP20' },
  { id: 'COOP20', name: 'COOP20' },
  { id: 'OFFBUONE20', name: 'OFFBUONE20' },
  { id: 'GSKPSP01', name: 'GSKPSP01' },
  { id: 'HNP2021', name: 'HNP2021' },
  { id: 'PHACR21', name: 'PHACR21' },
  { id: 'AMPXMAS2021', name: 'AMPXMAS2021' },
  { id: 'NOVLKPSP01', name: 'NOVLKPSP01' },
  { id: 'DTLPRO22', name: 'DTLPRO22' },
  { id: 'JNTWEL01', name: 'JNTWEL01' },
  { id: 'CUOFRA22', name: 'CUOFRA22' },
  { id: 'AARIV23', name: 'AARIV23' },
  { id: 'USOS24', name: 'USOS24' },
  { id: 'JFAST01', name: 'JFAST01' },
  { id: 'TNWGIL25', name: 'TNWGIL25' },
  { id: 'OVERH01', name: 'OVERH01' },
  { id: 'AABREZ', name: 'AABREZ' },
  { id: 'AASFAUST', name: 'AASFAUST' },
  { id: 'AAMNAV', name: 'AAMNAV' },
  { id: 'AAMSLUC', name: 'AAMSLUC' },
  { id: 'AAMVRE', name: 'AAMVRE' },
  { id: 'AABCAST', name: 'AABCAST' },
  { id: 'AASPON', name: 'AASPON' },
  { id: 'AAPVEI', name: 'AAPVEI' },
  { id: 'AADOM', name: 'AADOM' },
  { id: 'AACINI', name: 'AACINI' },
  { id: 'CPD', name: 'CPD' },
  { id: 'MDCON', name: 'MDCON' },
  { id: 'UVPP', name: 'UVPP' },
  { id: 'GTT', name: 'GTT' },
  { id: 'FMIR0', name: 'FMIR0' },
  { id: 'AASEMP', name: 'AASEMP' },
  { id: 'TPA', name: 'TPA' },
  { id: 'IPA', name: 'IPA' },
  { id: 'RUK23', name: 'RUK23' },
];
