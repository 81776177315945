import React, { useEffect, useState } from 'react';
import { colorScheme } from '@ugo/data';
import { Line } from 'react-chartjs-2';
import { format, isSameDay } from 'date-fns';
import { getDaysArray, useUtilities } from '@ugo/utils';
import { useQueries } from '../../../hooks/useQueries';
import {
  Scalars,
  useGetDailyConversionsByAuthProviderQuery,
} from '@ugo/graphql-operations';
import { isNil, reject } from 'ramda';
import { CircularProgress } from '@material-ui/core';

export const DailyConversionsByAuthProvider = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) => {
  const [days, setDays] = useState<Date[]>([]);
  const [googleData, setGoogleData] = useState();
  const [facebookData, setFacebookData] = useState();
  const [passwordData, setPasswordData] = useState();
  const [totalData, setTotalData] = useState();
  const { data, loading } = useGetDailyConversionsByAuthProviderQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: reject(isNil, {
        converted_at: {
          _gte: startDate as Scalars['timestamptz'],
          _lte: endDate as Scalars['timestamptz'],
        },
      }),
    },
  });

  useEffect(() => {
    setDays(getDaysArray(startDate, endDate, true));
    if (data) {
      const _googleData = days.map((day) =>
        // @ts-ignore
        data.vw_stats_daily_conversions_by_auth_provider
          .filter((d) => d.auth_provider === 'google.com')
          .filter((d) => isSameDay(new Date(day), new Date(d.converted_at)))
          .reduce((acc, curr) => acc + curr.accounts_converted || 0, 0)
      );
      // @ts-ignore
      setGoogleData(_googleData);

      const _facebookData = days.map((day) =>
        // @ts-ignore
        data.vw_stats_daily_conversions_by_auth_provider
          .filter((d) => d.auth_provider === 'facebook.com')
          .filter((d) => isSameDay(new Date(day), new Date(d.converted_at)))
          .reduce((acc, curr) => acc + curr.accounts_converted || 0, 0)
      );
      // @ts-ignore
      setFacebookData(_facebookData);

      const _passwordData = days.map((day) =>
        // @ts-ignore
        data.vw_stats_daily_conversions_by_auth_provider
          .filter((d) => d.auth_provider === 'password')
          .filter((d) => isSameDay(new Date(day), new Date(d.converted_at)))
          .reduce((acc, curr) => acc + curr.accounts_converted || 0, 0)
      );

      // @ts-ignore
      setPasswordData(_passwordData);

      const _totalData = days.map((day) =>
        // @ts-ignore
        data.vw_stats_daily_conversions_by_auth_provider
          .filter((d) => isSameDay(new Date(day), new Date(d.converted_at)))
          .reduce((acc, curr) => acc + curr.accounts_converted || 0, 0)
      );

      // @ts-ignore
      setTotalData(_totalData);
    }
  }, [startDate, endDate, data]);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      <Line
        data={{
          labels: days.map((date) => format(new Date(date), 'dd/MM/yyyy')),
          datasets: [
            {
              label: 'Total',
              data: totalData,
              lineTension: 0,
              pointRadius: 5,
              borderColor: colorScheme[0],
              backgroundColor: 'transparent',
            },
            {
              label: 'Google',
              data: googleData,
              lineTension: 0,
              pointRadius: 5,
              borderColor: colorScheme[5],
              backgroundColor: 'transparent',
            },
            {
              label: 'Facebook',
              data: facebookData,
              lineTension: 0,
              pointRadius: 5,
              borderColor: colorScheme[3],
              backgroundColor: 'transparent',
            },
            {
              label: 'Password',
              data: passwordData,
              lineTension: 0,
              pointRadius: 5,
              borderColor: colorScheme[2],
              backgroundColor: 'transparent',
            },
          ],
        }}
        options={{
          title: {
            display: true,
            text: 'Conversions by provider',
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0,
                  beginAtZero: true,
                  min: 0,
                },
              },
            ],
          },
        }}
      />
    </>
  );
};

/**
 * Proof of correct data for daily conversions by auth provider
 vw_stats_daily_conversions_by_auth_provider(
 where: {
      converted_at:{
        _gte: "2021-07-06T00:00:00"
        _lte:"2021-07-06T23:59:59"
      }
    }
 order_by: {converted_at: asc}
 ) {
    auth_provider
    accounts_converted
    converted_at
  }

 reservation(
 distinct_on: reservor_id,
 where:{
      created_at: {
        _gte: "2021-07-06T00:00:00"
        _lte:"2021-07-06T23:59:59"
      }
    }
 ) {
    id
    created_at
    reservor_id
    reservor {
      email
    	created_at
      reservor_reservations(order_by: {created_at:asc} limit:1) {
        created_at
      }
    }
  }
 */
