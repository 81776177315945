import { Box, Button, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { FirebaseApp } from '../../shared/vendor/firebase';
import axios from 'axios';
import { useNotify } from 'react-admin';

export const AdminDashboard = () => {
  const [switchingHasura, setSwitchingHasura] = useState(false);
  const notify = useNotify();
  const triggerNextHasura = async () => {
    setSwitchingHasura(true);
    try {
      const userToken = await FirebaseApp().auth().currentUser?.getIdToken();
      await axios.post(
        'https://europe-west3-hellougo-7d800.cloudfunctions.net/switchToNextAdminHasuraInstance',
        {
          firebase_token: userToken,
        }
      );
      setSwitchingHasura(false);
      notify('Hasura switched refresh admin in approx 5 minutes', {
        type: 'success',
        autoHideDuration: 20000,
      });
    } catch (e) {
      console.error(e);
      setSwitchingHasura(false);
    }
  };

  return (
    <Box pt={3}>
      <Paper>
        <Box p={2}>
          <Box>
            <p>
              <b style={{ color: 'red' }}>[PROD]</b> Switching to next Hasura
              instance manually can be done by pressing the button below
            </p>
            <Button
              disabled={switchingHasura}
              onClick={triggerNextHasura}
              color="primary"
              variant="contained"
            >
              Switch to next hasura
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
