import React from 'react';
import { Edit, TopToolbar, ListButton } from 'react-admin';
import { ReservationEditForm } from './ReservationEditForm';
import { ChevronLeft } from '@material-ui/icons';
import { Box } from '@material-ui/core';

const ReservationEditActions = ({ basePath }: any) => (
  <TopToolbar>
    <Box display="flex" flex="1" justifyContent="start">
      <ListButton
        icon={<ChevronLeft />}
        label="Reservations"
        basePath={basePath}
      />
      <Box display="flex">{/*  Actions */}</Box>
    </Box>
  </TopToolbar>
);

export const ReservationEdit = (props: any) => {
  return (
    <>
      <Edit
        actions={<ReservationEditActions />}
        mutationMode="pessimistic"
        {...props}
      >
        <ReservationEditForm {...props} submitOnEnter={false} />
      </Edit>
    </>
  );
};
