import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Line } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { Box, Card } from '@material-ui/core';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { ChartComponentProps } from '@ugo/models';
import { useUtilities } from '@ugo/utils';
import { useQueries } from '../../../hooks/useQueries';

const ServicesMadePerWeekLineChart = (props: ChartComponentProps) => {
  const { groupServiceCountByWeek } = useUtilities();
  const [graphData, setGraphData] = useState<Array<number[]>>([]);
  const [weeks, setWeeks] = useState<string[]>([]);
  const { refetchPatientConversionReservations } = useQueries();

  const { data } = useGetList(
    'reservation',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'ASC' },
    {
      'created_at@_gte': props.startDate,
      'created_at@_lte': props.endDate,
      'is_manual@_eq': props.shouldIncludeManualB2cReservations,
    }
  );

  // @ts-ignore
  useEffect(async () => {
    const firstReservations = await refetchPatientConversionReservations({
      dateRange: [props.startDate, props.endDate],
    });

    const {
      weeks,
      servicesByWeeks,
      duplicatedServicesByWeeks,
      reservationsByReturningPatientsByWeeks,
    } = groupServiceCountByWeek(
      data,
      firstReservations,
      props.startDate,
      props.endDate
    );

    // @ts-ignore
    setWeeks(weeks);
    setGraphData([
      // @ts-ignore
      servicesByWeeks,
      // @ts-ignore
      reservationsByReturningPatientsByWeeks,
      // @ts-ignore
      duplicatedServicesByWeeks,
    ]);
  }, [data]);

  return (
    <Card>
      <Box height="350px" p={2}>
        <Line
          data={{
            labels: weeks.map((w) => {
              const date = new Date(w);
              const weekStart = startOfWeek(date, { weekStartsOn: 1 });
              const weekEnd = endOfWeek(date, {
                weekStartsOn: 1,
              });

              return `${format(weekStart, 'dd/MM')} - ${format(
                weekEnd,
                'dd/MM'
              )}`;
            }),
            datasets: [
              {
                label: 'Booked',
                data: graphData[0],
                lineTension: 0,
                pointRadius: 5,
                borderColor: colorScheme[0],
                backgroundColor: 'transparent',
              },
              {
                label: 'Returning patients',
                data: graphData[1],
                lineTension: 0,
                pointRadius: 5,
                borderColor: colorScheme[1],
                backgroundColor: 'transparent',
              },
              {
                label: 'Duplicated (Returning patients)',
                data: graphData[2],
                lineTension: 0,
                pointRadius: 5,
                borderColor: colorScheme[2],
                backgroundColor: 'transparent',
              },
            ],
          }}
          options={{
            title: {
              display: true,
              text: 'Services made per week',
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    precision: 0,
                    beginAtZero: true,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </Box>
    </Card>
  );
};

export default ServicesMadePerWeekLineChart;
