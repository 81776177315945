import React, { useEffect, useState } from 'react';
import { Box, Card, CircularProgress } from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { GetAuthProviderDataQuery } from '@ugo/graphql-operations';
import Typography from '@material-ui/core/Typography';
import { useQueries } from '../../../hooks/useQueries';

const UsersConvertedByProviderPieChart = ({
  startDate,
  endDate,
}: {
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { refetchGetAuthProviderData } = useQueries();
  const [data, setData] =
    useState<GetAuthProviderDataQuery['vw_stats_auth_provider_data']>();

  useEffect(() => {
    const fetch = async () => {
      const authProviderData = await refetchGetAuthProviderData({
        dateRange: [startDate, endDate],
      });
      setData(authProviderData);
    };

    fetch();
  }, [startDate, endDate]);

  return (
    <Card>
      <Box p={2}>
        <Typography align="center" variant="h6">
          Patients converted by provider in date range
        </Typography>
        {!data ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box position="relative">
            <Pie
              data={{
                labels: ['Google', 'Facebook', 'Password'],
                datasets: [
                  {
                    label: 'Services booked by city',
                    data: [
                      data.reduce((d, a) => d + a.google_converted, 0),
                      data.reduce((d, a) => d + a.facebook_converted, 0),
                      data.reduce((d, a) => d + a.password_converted, 0),
                    ],
                    backgroundColor: colorScheme,
                  },
                ],
              }}
            />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default UsersConvertedByProviderPieChart;
