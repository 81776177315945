import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { colorScheme } from '@ugo/data';
import { useUtilities } from '@ugo/utils';

export const ConversionsPerMonthLineChart = ({
  reservations,
  startDate,
  endDate,
}: {
  reservations: any[];
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { groupServiceCountByMonth } = useUtilities();
  const [graphData, setGraphData] = useState<Array<number[]>>([]);
  const [month, setMonths] = useState<string[]>([]);

  useEffect(() => {
    const { months, servicesByMonths } = groupServiceCountByMonth(
      reservations,
      [],
      startDate,
      endDate
    );

    // @ts-ignore
    setMonths(months);
    setGraphData([
      // @ts-ignore
      servicesByMonths,
    ]);
  }, [reservations, startDate, endDate]);

  return (
    <Line
      data={{
        labels: month.map((m) => {
          const date = new Date(m);
          const monthStart = startOfMonth(date);
          const monthEnd = endOfMonth(date);

          return `${format(monthStart, 'dd/MM')} - ${format(
            monthEnd,
            'dd/MM'
          )}`;
        }),
        datasets: [
          {
            label: 'First reservations',
            data: graphData[0],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[0],
            backgroundColor: 'transparent',
          },
        ],
      }}
      options={{
        title: {
          display: true,
          text: 'Conversions per month',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
      }}
    />
  );
};
