import {
  CITY_MIN_DISTANCE,
  CITY_MIN_DISTANCE_DEFAULT,
  getDistanceCost,
  getHourlyIntervalTimeCost,
} from '../utils';
import { CalculationData, CalculationStrategy } from './calculation-strategy';

export class CaregivingWithUgosCarStrategy implements CalculationStrategy {
  calculate(data: CalculationData) {
    const CAREGIVING_WITH_UGOS_CAR_PRICE_PER_HOUR = 15;

    if (!data?.travel) {
      throw new Error('Travel data is required');
    }

    if (
      !data?.caregivingDuration &&
      typeof data.caregivingDuration !== 'number'
    ) {
      throw new Error('Caregiving duration is required');
    }

    if (!data?.city) {
      throw new Error('City is required');
    }

    const timeCost = getHourlyIntervalTimeCost(
      data.caregivingDuration,
      CAREGIVING_WITH_UGOS_CAR_PRICE_PER_HOUR
    );
    const driverDistance = data.travel.getDriverDistance();
    const travelDistance = data.travel.getTravelDistance();
    const minDistancePrice =
      CITY_MIN_DISTANCE[data.city] || CITY_MIN_DISTANCE_DEFAULT;

    const distanceCost = getDistanceCost(
      travelDistance + driverDistance,
      minDistancePrice
    );

    return {
      timeCost,
      distanceCost,
      driverDistance,
      travelDistance,
      caregivingDuration: data.caregivingDuration,
      totalCost: timeCost + distanceCost,
    };
  }
}
