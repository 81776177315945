import { Manual_Reservation } from '@ugo/graphql-operations';
import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import { utcToZonedTime } from 'date-fns-tz';
import { SupportedTimezones } from '@ugo/models';
import { format } from 'date-fns';

const formatToReadableZonedData = (date: Date) => {
  const zoned = utcToZonedTime(date, SupportedTimezones.Rome);
  return format(zoned, 'dd/MM/yyyy HH:mm');
};

export const manualReservationExported = async (
  manualReservations: Manual_Reservation[],
  fetchRelatedRecords: any
) => {
  const drivers = await fetchRelatedRecords(
    manualReservations,
    'driver_id',
    'manual_driver'
  );

  const postsForExport = manualReservations.map((manualReservation) => {
    const {
      starts_at,
      ends_at,
      started_at,
      ended_at,
      updated_at,
      created_at,
      distance_billable,
    } = manualReservation;

    if (created_at) {
      manualReservation.created_at = formatToReadableZonedData(
        new Date(created_at)
      );
    }
    if (starts_at) {
      manualReservation.starts_at = formatToReadableZonedData(
        new Date(starts_at)
      );
    }
    if (ends_at) {
      manualReservation.ends_at = formatToReadableZonedData(new Date(ends_at));
    }
    if (started_at) {
      manualReservation.started_at = formatToReadableZonedData(
        new Date(started_at)
      );
    }
    if (ended_at) {
      manualReservation.ended_at = formatToReadableZonedData(
        new Date(ended_at)
      );
    }
    if (updated_at) {
      manualReservation.updated_at = formatToReadableZonedData(
        new Date(updated_at)
      );
    }
    if (distance_billable) {
      // @ts-ignore
      manualReservation.distance_billable_one_way = manualReservation.is_two_way
        ? manualReservation.distance_billable / 2
        : manualReservation.distance_billable;

      // @ts-ignore
      manualReservation.distance_billable_one_way =
        // @ts-ignore
        manualReservation.distance_billable_one_way
          .toString()
          .replace('.', ',');

      manualReservation.distance_billable = distance_billable
        .toString()
        .replace('.', ',');
    }
    if (manualReservation.note) {
      manualReservation.note = `"${manualReservation.note}"`;
    }

    if (manualReservation.destination_lat) {
      manualReservation.destination_lat = manualReservation.destination_lat
        .toString()
        .replace('.', ',');
    }

    if (manualReservation.departure_lat) {
      manualReservation.departure_lat = manualReservation.departure_lat
        .toString()
        .replace('.', ',');
    }

    if (manualReservation.destination_lng) {
      manualReservation.destination_lat = manualReservation.destination_lng
        .toString()
        .replace('.', ',');
    }

    if (manualReservation.departure_lng) {
      manualReservation.departure_lat = manualReservation.departure_lng
        .toString()
        .replace('.', ',');
    }

    if (manualReservation.price_billable) {
      manualReservation.price_billable = manualReservation.price_billable
        .toString()
        .replace('.', ',');
    }

    if (manualReservation.extra_cost) {
      manualReservation.extra_cost = manualReservation.extra_cost
        .toString()
        .replace('.', ',');
    }

    if (manualReservation.price_estimate) {
      manualReservation.price_estimate = manualReservation.price_estimate
        .toString()
        .replace('.', ',');
    }

    // @ts-ignore
    manualReservation.is_two_way_number = manualReservation.is_two_way ? 2 : 1;

    // @ts-ignore
    manualReservation.driver_email =
      {
        ...drivers[manualReservation.driver_id],
      }.email || '';

    return manualReservation;
  });

  const csv = convertToCSV(
    {
      data: postsForExport,
      fields: [
        'created_at',
        'status',
        'created_by',
        'id',
        'is_two_way',
        'is_two_way_number',
        'distance_billable',
        'distance_billable_one_way',
        'market_segment',
        'project_name',
        'reservor_id',
        'service',
        'service_city',
        'departure_address',
        'departure_lat',
        'departure_lng',
        'destination_address',
        'destination_lat',
        'destination_lng',
        'driver_id',
        'driver_email',
        'preferred_driver_action',
        'has_preferred_driver',
        'driver_preferred_id',
        'duration_billable',
        'duration_estimate',
        'extra_cost',
        'has_preferred_driver',
        'starts_at',
        'updated_at',
        'ended_at',
        'started_at',
        'price_billable',
        'price_estimate',
        'currency',
        'note',
      ],
    },
    { delimiter: ';' }
  );
  downloadCSV(csv, 'reservations_' + Date.now());
};
