import React, { useState } from 'react';
import DriversPerCityBarChart from './charts/DriversPerCityBarChart';
import { Box, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import ServicesBookedByCityBarChart from './charts/ServicesBookedByCityBarChart';
import ServicesBookedByServiceTypeBarChart from './charts/ServicesBookedByServiceTypeBarChart';
import ServicesMadeLineChart from './charts/ServicesMadeLineChart';
import Typography from '@material-ui/core/Typography';
import { DateRange } from '@material-ui/pickers';
import { getDatePresetValue } from '@ugo/utils';
import ActivatedUsersDoughnutChart from './charts/ActivatedUsersDoughnutChart';
import NumberOfActiveDriversLineChart from './charts/NumberOfActiveDriversLineChart';
import AverageDriversEarningLineChart from './charts/AverageDriversEarningLineChart';
import TotalMoneyFlow from './single-data/TotalMoneyFlow';
import TotalUgoIncome from './single-data/TotalUgoIncome';
import TotalIncomePerCityBarChart from './charts/TotalIncomePerCityBarChart';
import TotalIncomePerServiceBarChart from './charts/TotalIncomePerServiceBarChart';
import AveragePurchasePerUser from './single-data/AveragePurchasePerUser';
import AveragePurchasePerService from './single-data/AveragePurchasePerService';
import NumberOfActiveUsersLineChart from './charts/NumberOfActiveUsersLineChart';
import TotalServicesBooked from './single-data/TotalServicesBooked';
import TotalServicesFinished from './single-data/TotalServicesFinished';
import DateFilter from '../shared/DateFilter';
import TotalDuplicatedServices from './single-data/TotalDuplicatedServices';
import ServicesCreatedAtByDayOfWeekBarChart from './charts/ServicesCreatedAtByDayOfWeekBarChart';
import ServicesStartAtByDayOfWeekBarChart from './charts/ServicesStartAtByDayOfWeekBarChart';
import ServicesMadePerWeekLineChart from './charts/ServicesMadePerWeekLineChart';
import { useAtom } from 'jotai';
import { datePresetValueAtom } from '../../shared/globalState';
import { ServicesMadePerMonthLineChart } from './charts/ServicesMadePerMonthLineChart';
import UsersRegisteredByProviderPieChart from './charts/UsersRegisteredByProviderPieChart';
import UsersConvertedByProviderPieChart from './charts/UsersConvertedByProviderPieChart';
import { TotalVsManualServicesLineChart } from './charts/TotalVsManualServicesLineChart';

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Dashboard = () => {
  const [datePresetValue] = useAtom(datePresetValueAtom);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const [dateRange, setDateRange] = useState<DateRange<Date>>(
    getDatePresetValue(datePresetValue)
  );

  return (
    <Box>
      <Paper>
        <Box p={1}>
          <DateFilter
            defaultPreset={datePresetValue}
            rangeChange={(v) => setDateRange(v)}
          />
        </Box>
      </Paper>
      <Box mt={3}>
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            color="white"
            textColor="primary"
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Services" />
            <Tab label="Manual Services" />
            <Tab label="Users" />
            <Tab label="Drivers" />
            <Tab label="Economics" />
            <Tab label="General" />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box>
                  <ServicesMadeLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box>
                  <ServicesMadePerWeekLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box>
                  <ServicesMadePerMonthLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box>
                  <TotalServicesBooked
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box>
                  <TotalServicesFinished
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box>
                  <TotalDuplicatedServices
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <ServicesBookedByCityBarChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <ServicesBookedByServiceTypeBarChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <ServicesCreatedAtByDayOfWeekBarChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <ServicesStartAtByDayOfWeekBarChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box>
                  <TotalVsManualServicesLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box>
                  <ServicesMadeLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box>
                  <ServicesMadePerWeekLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box>
                  <ServicesMadePerMonthLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box>
                  <TotalServicesBooked
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box>
                  <TotalServicesFinished
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box>
                  <TotalDuplicatedServices
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <ServicesBookedByCityBarChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <ServicesBookedByServiceTypeBarChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <ServicesCreatedAtByDayOfWeekBarChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <ServicesStartAtByDayOfWeekBarChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    shouldIncludeManualB2cReservations={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Box>
                  <AveragePurchasePerUser
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box>
                  <AveragePurchasePerService
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box>
                  <NumberOfActiveUsersLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <ActivatedUsersDoughnutChart />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <UsersRegisteredByProviderPieChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <UsersConvertedByProviderPieChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                  />
                </Box>
              </Grid>
              {/* This is commented out because it's not needed anymore ATM - 8. 11. 2021 */}
              {/* If we need it in the future we will uncomment it */}
              {/*<Grid item sm={12}>*/}
              {/*  <Box>*/}
              {/*    <UserLtvBarChart*/}
              {/*      startDate={dateRange[0]}*/}
              {/*      endDate={dateRange[1]}*/}
              {/*    />*/}
              {/*  </Box>*/}
              {/*</Grid>*/}
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box>
                  <NumberOfActiveDriversLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <DriversPerCityBarChart />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <AverageDriversEarningLineChart
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box>
            View Economics dashboard{' '}
            <a href="https://metabase-production-281a.up.railway.app/dashboard/3-economics?date_filter=thisweek">
              <b>HERE</b>
            </a>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box>
            View General dashboard{' '}
            <a href="https://metabase-production-281a.up.railway.app/dashboard/1-general?date_filter=thisweek">
              <b>HERE</b>
            </a>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Dashboard;
