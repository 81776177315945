import React, { useState } from 'react';
import { useCalculateTransactionFeesMutation } from '@ugo/graphql-operations';
import { Field, Form } from 'react-final-form';

export const CalculateTransactionFees = () => {
  const [result, setResult] = useState(JSON.stringify({}));
  const [calculateTransactionFeesMutation] =
    useCalculateTransactionFeesMutation();

  const onCalculateTransactionsFees = async (values) => {
    const _result = await calculateTransactionFeesMutation({
      variables: {
        input: {
          total_amount: parseFloat(values.total_amount),
          total_amount_discounted: parseFloat(values.total_amount_discounted),
          extra_cost: parseFloat(values.extra_cost),
        },
      },
    });

    setResult(_result);
  };

  return (
    <>
      <pre>{JSON.stringify(result, null, 2)}</pre>
      <Form
        onSubmit={onCalculateTransactionsFees}
        initialValues={{
          total_amount: 0,
          total_amount_discounted: 0,
          extra_cost: 0,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label>total_amount</label>
              <Field
                name="total_amount"
                component="input"
                type="number"
                placeholder="total_amount"
              />
            </div>
            <div>
              <label>total_amount_discounted</label>
              <Field
                name="total_amount_discounted"
                component="input"
                type="number"
                placeholder="total_amount_discounted"
              />
            </div>
            <div>
              <label>extra_cost</label>
              <Field
                name="extra_cost"
                component="input"
                type="number"
                placeholder="extra_cost"
              />
            </div>
            <button type="submit">Calculate</button>
          </form>
        )}
      />
    </>
  );
};
