import React from 'react';
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

export function ProjectEdit(props: any) {
  return (
    <Edit {...props}>
      <SimpleForm {...props}>
        <TextInput label="Name" source="name" />
        <ReferenceInput
          label="Partner"
          source="partner_id"
          reference="partner"
          filterToQuery={(searchText) => ({
            name: searchText,
          })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
