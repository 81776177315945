import React, { useEffect, useState } from 'react';
// @ts-ignore
import buildHasuraProvider, {
  buildFields,
  buildArgs,
  buildMetaArgs,
  buildApolloArgs,
  buildQuery,
  buildVariables,
} from 'ra-data-hasura';
import './App.css';
import { Admin, Resource } from 'react-admin';
import 'firebase/auth';
import { AccountList } from './components/accounts/AccountList';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import { ReservationList } from './components/reservations/ReservationList';
import { AccountEdit } from './components/accounts/AccountEdit';
import { ReservationEdit } from './components/reservations/ReservationEdit';
import { ugoTheme } from './style/theme';
import { FirebaseApp } from './shared/vendor/firebase';
import { authGQLClient } from './shared/ApolloClient';
import { authProvider } from './shared/AuthProvider';
import { ApolloProvider } from '@apollo/client';
import Routes from './Routes';
import Layout from './components/shared/Layout';
import gql from 'graphql-tag';
import LoginPage from './components/auth/LoginPage';
import { ManualReservationEdit } from './components/manual-reservations/ManualReservationEdit';
import { ManualReservationCreate } from './components/manual-reservations/ManualReservationCreate';
import { ManualReservationList } from './components/manual-reservations/ManualReservationList';
import { ManualDriverCreate } from './components/manual-drivers/ManualDriverCreate';
import { ManualPatientCreate } from './components/manual-patients/ManualPatientCreate';
import { ManualPatientEdit } from './components/manual-patients/ManualPatientEdit';
import { ManualDriverList } from './components/manual-drivers/ManualDriverList';
import { ManualDriverEdit } from './components/manual-drivers/ManualDriverEdit';
import { ManualPatientList } from './components/manual-patients/ManualPatientList';
import { VwAdminAccountFeedbackList } from './components/vw-account-feedbacks/VwAdminAccountFeedbackList';
import { AccountFeedbackEdit } from './components/account-feedbacks/AccountFeedbackEdit';
import UgoLoadingOverlayPage from './components/shared/UgoLoadingOverlay';
import { ManualReservationWithPreferredDriverList } from './components/manual-reservations-with-preferred-driver/ManualReservationWithPreferredDriverList';
import { CarvoilaDriverEdit } from './carvoila/components/DriverEdit';
import { DistrictCreate } from './carvoila/components/DistrictCreate';
import { ReviewList } from './components/review/ReviewList';
import { ReviewEdit } from './components/review/ReviewEdit';
import { CancelledReservationList } from './components/reservations/CancelledReservationList';
import { AccountErrorList } from './components/accounts/AccountErrorList';
import { PartnerList } from './components/partner/PartnerList';
import { PartnerEdit } from './components/partner/PartnerEdit';
import { ProjectList } from './components/project/ProjectList';
import { ProjectNew } from './components/project/ProjectNew';
import { VoucherList } from './components/voucher/VoucherList';
import { VoucherEdit } from './components/voucher/VoucherEdit';
import { DataProvider } from 'ra-core';
import { IntrospectionField } from 'graphql/index';
import { VoucherCreate } from './components/voucher/VocuherCreate';
import { ProjectEdit } from './components/project/ProjectEdit';
import { AccountPenaltyList } from './components/accounts/AccountPenaltyList';
import { AccountPenaltyEdit } from './components/accounts/AccountPenaltyEdit';

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst: any) => {
  return queryAst.definitions[0].selectionSet.selections;
};

const EXTENDED_GET_PARTNER = gql`
  {
    projects {
      id
      name
    }
  }
`;

const EXTENDED_GET_LIST_PAYMENT = gql`
  {
    reservation {
      id
      metadata
      service_id
      reservor_id
      created_at
    }
  }
`;

const EXTENDED_GET_LIST_MANUAL_PATIENT = gql`
  {
    partner {
      id
      name
    }
  }
`;
const EXTENDED_GET_ONE_ACCOUNT = gql`
  {
    driver_profile {
      id
      video_verification_uri
      document_verification_id_status
      document_verification_driver_license_status
      is_general_info_submitted
      general_info_submitted_at
      is_video_verification_approved
      is_video_verification_submitted
      video_verification_submitted_at
      video_verification_approved_at
      general_info_payload
      document_verification_id_snapshot
      document_verification_id_session_id
      is_document_verification_id_approved
      document_verification_id_approved_at
      document_verification_driver_license_snapshot
      document_verification_driver_license_session_id
      is_document_verification_driver_license_approved
      document_verification_driver_license_approved_at
      videoask_contact_id
      videoask_form_id
      is_academy_completed
      status
      is_covid_passport_approved
      is_covid_passport_submitted
      is_typeform_documents_approved
      is_typeform_documents_submitted
      typeform_documents_payload
      typeform_documents_approved_at
      typeform_documents_submitted_at
      covid_passport_payload
      dof_requested_city
      document_verification_id_status
      document_verification_driver_license_status
      document_verification_id_force_verified
      document_verification_driver_license_force_verified
      citizenship
      id_document_front_object_path
      id_document_back_object_path
      id_document_expiration_date
      id_document_number
      driver_license_front_object_path
      driver_license_back_object_path
      driver_license_expiration_date
      bank_account_number
      bank_account_holder_name
      id_document_state_of_issue
      tax_regime
      residence_permit
    }
  }
`;
const EXTENDED_GET_LIST_ACCOUNT = EXTENDED_GET_ONE_ACCOUNT;

const EXTENDED_GET_ONE_ACCOUNT_PENALTY = gql`
  {
    reservation {
      patient_cancellation_reason
      driver_cancellation_reasons
    }
    account_penalty_reservation_cancellation {
      id
      note
    }
  }
`;

const EXTENDED_UPDATE_ONE_ACCOUNT = gql`
  {
    driver_profile {
      id
      video_verification_uri
      document_verification_id_status
      document_verification_driver_license_status
      is_general_info_submitted
      general_info_submitted_at
      is_video_verification_approved
      is_video_verification_submitted
      video_verification_submitted_at
      video_verification_approved_at
      general_info_payload
      document_verification_id_snapshot
      document_verification_id_session_id
      is_document_verification_id_approved
      document_verification_id_approved_at
      document_verification_driver_license_snapshot
      document_verification_driver_license_session_id
      is_document_verification_driver_license_approved
      document_verification_driver_license_approved_at
      videoask_contact_id
      videoask_form_id
      is_academy_completed
      status
      is_covid_passport_approved
      is_covid_passport_submitted
      covid_passport_payload
      dof_requested_city
      document_verification_id_status
      document_verification_driver_license_status
      document_verification_id_force_verified
      document_verification_driver_license_force_verified
    }
  }
`;

const EXTENDED_GET_ONE_RESERVATION = gql`
  {
    reservor {
      id
      email
      discount_percent
    }
  }
`;

const EXTENDED_GET_ONE_MANUAL_RESERVATION = gql`
  {
    manual_reservation_cancellations {
      manual_driver {
        account {
          email
        }
      }
      note
    }
  }
`;

const GET_ONE_VOUCHER = gql`
  {
    code
    referrer_id
    enabled
    expires_at
    amount_value
    created_at
    percent_value
    first_reservation_only
  }
`;
const customBuildFields = (type: any, fetchType: any) => {
  const resourceName = type.name;

  const defaultFields = buildFields(type, fetchType);

  if (resourceName === 'voucher' && fetchType === 'GET_LIST') {
    // Map code to id field
    return extractFieldsFromQuery(GET_ONE_VOUCHER);
  }

  if (resourceName === 'payment' && fetchType === 'GET_LIST') {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_GET_LIST_PAYMENT);
    defaultFields.push(...relatedEntities);
  }

  if (resourceName === 'account' && fetchType === 'GET_ONE') {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_GET_ONE_ACCOUNT);
    defaultFields.push(...relatedEntities);
  }
  if (resourceName === 'partner' && fetchType === 'GET_ONE') {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_GET_PARTNER);
    defaultFields.push(...relatedEntities);
  }

  if (resourceName === 'account' && fetchType === 'UPDATE') {
    const relatedEntities = extractFieldsFromQuery(EXTENDED_UPDATE_ONE_ACCOUNT);
    defaultFields.push(...relatedEntities);
  }

  if (resourceName === 'manual_patient' && fetchType === 'GET_LIST') {
    const relatedEntities = extractFieldsFromQuery(
      EXTENDED_GET_LIST_MANUAL_PATIENT
    );
    defaultFields.push(...relatedEntities);
  }

  if (resourceName === 'reservation' && fetchType === 'GET_ONE') {
    const relatedEntities = extractFieldsFromQuery(
      EXTENDED_GET_ONE_RESERVATION
    );
    defaultFields.push(...relatedEntities);
  }

  if (resourceName === 'account_penalty' && fetchType === 'GET_ONE') {
    const relatedEntities = extractFieldsFromQuery(
      EXTENDED_GET_ONE_ACCOUNT_PENALTY
    );
    defaultFields.push(...relatedEntities);
  }

  if (resourceName === 'manual_reservation' && fetchType === 'GET_ONE') {
    const relatedEntities = extractFieldsFromQuery(
      EXTENDED_GET_ONE_MANUAL_RESERVATION
    );
    defaultFields.push(...relatedEntities);
  }
  // No custom query defined, so use the default query fields (all, but no related/nested).
  return defaultFields;
};

const customBuildArgs = (query: IntrospectionField, variables: any) => {
  console.log(variables);
  console.log(query.type);
  if (query.name === 'voucher') {
    console.log('1.5');
    variables = {
      where: {
        _and: [{ code: 'ASDASDAS6' }],
      },
    };
  }
  console.log('2');
  console.log(variables);
  return buildArgs(query, variables);
};

function App() {
  const [resolvedDataProvider, setResolvedDataProvider] =
    useState<DataProvider | null>();

  useEffect(() => {
    FirebaseApp()
      .auth()
      .onAuthStateChanged(() => {
        buildHasuraProvider(
          {
            client: authGQLClient,
          },
          {
            buildFields: customBuildFields,
          },
          (query) => (a, b, c, d) => {
            if (b === 'GET_ONE' && a.type.name === 'voucher') {
              return {
                where: {
                  code: { _eq: c.id },
                },
              };
            }

            if (b === 'UPDATE' && a.type.name === 'voucher') {
              const updateData = c.data;
              delete updateData.id;
              delete updateData.created_at;
              return {
                where: {
                  code: { _eq: c.id },
                },
                _set: updateData,
              };
            }

            return buildVariables(query)(a, b, c, d);
          }
        ).then((dataProviderHasura) => {
          const modifiedProvider: DataProvider = {
            getList: async (resource, params) => {
              // eslint-disable-next-line prefer-const
              let { data, ...metadata } = await dataProviderHasura.getList(
                resource,
                params
              );

              if (resource === 'voucher') {
                data = data?.map((val: any) => ({
                  ...val,
                  id: val.code,
                }));
              }
              return {
                data: data as any[],
                ...metadata,
              };
            },
            getOne: async (resource, params) => {
              // eslint-disable-next-line prefer-const
              let { data, ...metadata } = await dataProviderHasura.getOne(
                resource,
                params
              );

              if (resource === 'voucher') {
                data = {
                  ...data,
                  id: data.code,
                };
              }
              return {
                data: data as any,
                ...metadata,
              };
            },
            getMany: (resource, params) =>
              dataProviderHasura.getMany(resource, params),
            getManyReference: (resource, params) =>
              dataProviderHasura.getManyReference(resource, params),
            update: async (resource, params) => {
              // eslint-disable-next-line prefer-const
              let { data, ...metadata } = await dataProviderHasura.update(
                resource,
                params
              );

              if (resource === 'voucher') {
                data = {
                  ...data,
                  id: data.code,
                };
              }
              return {
                data: data as any,
                ...metadata,
              };
            },
            updateMany: (resource, params) =>
              dataProviderHasura.updateMany(resource, params),
            create: async (resource, params) => {
              // eslint-disable-next-line prefer-const
              let { data, ...metadata } = await dataProviderHasura.create(
                resource,
                params
              );

              if (resource === 'voucher') {
                data = {
                  ...data,
                  id: data.code,
                };
              }
              return {
                data: data as any,
                ...metadata,
              };
            },
            delete: (resource, params) =>
              dataProviderHasura.delete(resource, params),
            deleteMany: (resource, params) =>
              dataProviderHasura.deleteMany(resource, params),
          };
          setResolvedDataProvider(() => modifiedProvider);
        });
      });
  }, []);

  if (!resolvedDataProvider || !authGQLClient) {
    return <UgoLoadingOverlayPage />;
  }

  return (
    <ApolloProvider client={authGQLClient}>
      <Admin
        // @ts-ignore
        login={LoginPage}
        layout={Layout}
        theme={ugoTheme}
        title="Ugo Admin"
        //@ts-ignore
        dataProvider={resolvedDataProvider}
        authProvider={authProvider}
        customRoutes={Routes}
      >
        <Resource
          icon={PeopleIcon}
          name="account"
          edit={AccountEdit}
          // Show components commented out to avoid upkeep in the short term
          // list={AccountList}
          // create={AccountCreate}
          // show={AccountShow}
        />
        <Resource
          icon={PeopleIcon}
          name="vw_admin_account"
          options={{ label: 'Accounts' }}
          list={AccountList}
        />
        <Resource name="account_feedback" edit={AccountFeedbackEdit} />
        <Resource
          name="vw_admin_account_feedback"
          list={VwAdminAccountFeedbackList}
          icon={PeopleIcon}
          options={{ label: 'User Feedback' }}
        />
        <Resource
          name="reservation"
          icon={ListIcon}
          edit={ReservationEdit}
          // options={{ label: 'Reservations' }}
          // list={ReservationList}
          // create={ReservationCreate}
          // Show components commented out to avoid upkeep in the short term
          // show={ReservationShow}
        />
        <Resource
          icon={ListIcon}
          name="vw_admin_reservation"
          options={{ label: 'Reservations' }}
          list={ReservationList}
        />
        <Resource
          icon={ListIcon}
          name="partner"
          options={{ label: 'Partners' }}
          list={PartnerList}
          edit={PartnerEdit}
        />
        <Resource
          icon={ListIcon}
          name="project"
          list={ProjectList}
          create={ProjectNew}
          edit={ProjectEdit}
          options={{ label: 'Projects' }}
        />
        <Resource
          icon={ListIcon}
          name="vw_admin_cancelled_reservation"
          options={{ label: 'Cancelled Reservations' }}
          list={CancelledReservationList}
        />
        <Resource
          name="vw_admin_100_percent_discount_reservations"
          list={ReservationList}
          icon={ListIcon}
          options={{ label: 'Reservations with 100% discount' }}
          edit={ReservationEdit}
        />
        <Resource
          name="vw_stats_patient_conversion_reservation"
          list={ReservationList}
          options={{ label: 'First Reservations' }}
          icon={ListIcon}
        />
        <Resource
          name="account_error"
          list={AccountErrorList}
          options={{ label: 'Account errors' }}
          icon={ListIcon}
        />
        <Resource
          name="account_penalty"
          list={AccountPenaltyList}
          edit={AccountPenaltyEdit}
          options={{ label: 'Account penalties' }}
          icon={ListIcon}
        />
        <Resource
          icon={ListIcon}
          name="review"
          options={{ label: 'Reviews' }}
          list={ReviewList}
          edit={ReviewEdit}
        />
        <Resource
          icon={ListIcon}
          name="voucher"
          options={{ label: 'Vouchers' }}
          list={VoucherList}
          edit={VoucherEdit}
          create={VoucherCreate}
        />
        <Resource
          name="manual_reservation"
          icon={ListIcon}
          create={ManualReservationCreate}
          edit={ManualReservationEdit}
        />
        <Resource
          name="vw_admin_manual_reservation_with_preferred_driver"
          list={ManualReservationWithPreferredDriverList}
          icon={ListIcon}
          options={{ label: 'Manual Reservations with preferred driver' }}
        />
        <Resource
          name="vw_admin_manual_reservation"
          list={ManualReservationList}
          icon={ListIcon}
          options={{ label: 'Manual Reservations' }}
        />
        <Resource name="vw_admin_default_account_credit_cards" />
        <Resource
          name="manual_driver"
          list={ManualDriverList}
          icon={ListIcon}
          options={{ label: 'Manual Drivers' }}
          create={ManualDriverCreate}
          edit={ManualDriverEdit}
        />
        <Resource
          name="manual_patient"
          list={ManualPatientList}
          icon={ListIcon}
          options={{ label: 'Manual Patients' }}
          create={ManualPatientCreate}
          edit={ManualPatientEdit}
        />
        <Resource name="driver_profile" />
        <Resource name="carvoila_driver" edit={CarvoilaDriverEdit} />
        <Resource name="vw_admin_driver_profile" />
        <Resource
          name="carvoila_district"
          create={DistrictCreate}
          options={{ label: 'Carvoila District' }}
        />
        <Resource name="payment" />
      </Admin>
    </ApolloProvider>
  );
}

export default App;
