import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { ChartComponentProps } from '@ugo/models';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const AveragePurchasePerService = (props: ChartComponentProps) => {
  const [sum, setSum] = useState<number>(0);

  const { data, loading } = useGetList(
    'payment',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'ASC' },

    { 'created_at@_gte': props.startDate, 'created_at@_lte': props.endDate }
  );

  useEffect(() => {
    const payments = data as any;

    const sum = Object.keys(payments)
      .map((pid) => payments[pid])
      .filter(
        (p) =>
          p.reservation.metadata.service_city !== 'PRODUCTION_TEST_CITY' &&
          !!p.reservation.metadata.service
      )
      .reduce((c, v) => c + Number(v.metadata.amount), 0);

    setSum(sum / Object.keys(payments).length || 0);
  }, [data]);

  return (
    <Card>
      <Box p={2}>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Typography align="center" variant="h6">
              Average income per service
            </Typography>
            <Typography align="center" color="primary" variant="h5">
              <b>{sum.toFixed(2)} €</b>
            </Typography>
          </>
        )}
      </Box>
    </Card>
  );
};

export default AveragePurchasePerService;
