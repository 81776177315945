import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { ADMIN_SUPPORTED_CITIES, SUPPORTED_SERVICES } from '@ugo/data';
import {
  GetAccountDriverDocument,
  GetAccountDriverQuery,
  useActivateDriverMutation,
} from '@ugo/graphql-operations';
import { useNotify } from 'react-admin';

export interface ConfirmDriverSettingsValue {
  city_list: string[];
  service_list: string[];
}

// Currently cities are handled as object with "city name" and boolean "pair value".
// Services are currently also olny "GENERAL" value
const objectKeysToArray = (data: {
  [key: string]: string | boolean;
}): string[] => {
  return Object.keys(data);
};

export const DriverSettingsDialog = ({
  open,
  closeCallback,
  driverProfile,
  accountId,
}: {
  open: boolean;
  closeCallback: () => void;
  accountId: string;
  driverProfile?: GetAccountDriverQuery['driver_profile'][number] | null;
}) => {
  const notify = useNotify();
  const [activateDriverMutation, { loading }] = useActivateDriverMutation();

  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);

  const handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCities(event.target.value as string[]);
  };

  const handleConfirm = async () => {
    try {
      await activateDriverMutation({
        variables: {
          account_id: accountId,
          city_list: selectedCities,
          service_list: selectedServices,
        },
        refetchQueries: [
          {
            query: GetAccountDriverDocument,
            variables: {
              account_id: accountId,
            },
          },
        ],
      });

      notify('Driver updated', 'info');
      closeCallback();
    } catch (err) {
      notify('Issue modifying driver', 'error');
    }
  };

  useEffect(() => {
    setSelectedServices(
      driverProfile ? objectKeysToArray(driverProfile?.services) : []
    );
    setSelectedCities(
      driverProfile ? objectKeysToArray(driverProfile?.cities) : []
    );
  }, [driverProfile]);

  const handleCancel = () => {
    closeCallback();
  };

  const handleServiceChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedServices(event.target.value as string[]);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Driver settings</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please configure drivers settings.
        </DialogContentText>
        {driverProfile?.dof_requested_city ? (
          <Box my="2em">
            City requested by typeform: {driverProfile?.dof_requested_city}
          </Box>
        ) : null}

        <Box mb="2em">
          <FormControl fullWidth>
            <InputLabel>Cities</InputLabel>
            <Select
              multiple
              value={selectedCities}
              onChange={handleCityChange}
              fullWidth
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <Box display="flex" flexWrap="wrap">
                  {(selected as string[]).map((value) => (
                    <Box key={value} p="0.15em">
                      <Chip key={value} label={value} />
                    </Box>
                  ))}
                </Box>
              )}
            >
              {ADMIN_SUPPORTED_CITIES.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl fullWidth>
            <InputLabel>Services</InputLabel>
            <Select
              multiple
              value={selectedServices}
              onChange={handleServiceChange}
              fullWidth
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <Box display="flex" flexWrap="wrap">
                  {(selected as string[]).map((value) => (
                    <Box key={value} p="0.15em">
                      <Chip key={value} label={value} />
                    </Box>
                  ))}
                </Box>
              )}
            >
              {SUPPORTED_SERVICES.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleCancel} color="primary">
          <Close />
        </Button>
        <Button
          disabled={loading}
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          autoFocus
        >
          <Check />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
