import * as React from 'react';
import {
  Datagrid,
  DateField,
  ExportButton,
  Filter,
  FunctionField,
  List,
  ResourceComponentProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { GLOBAL_EXPORTER_SETTINGS } from '@ugo/data';
import { Driver_Status_Enum } from '@ugo/graphql-operations';
import { renderBooleanIcons } from '../../components/reservations/ReservationList';
import { carvoilaDriverExporter, CarvoilaDriverFilter } from './DriverList';
import { ChipArrayField } from '../../components/shared/ChipArrayField';
import { useQuery } from 'ra-core';

export const CarvoilaDriverOnboarding = (props: ResourceComponentProps) => {
  const {
    data: districts,
    loading,
    error,
  } = useQuery({
    type: 'getList',
    resource: 'carvoila_district',
    payload: {},
  });

  return (
    <>
      <h4>1. Video verification in progress</h4>
      <List
        exporter={carvoilaDriverExporter}
        bulkActionButtons={false}
        {...props}
        filters={<CarvoilaDriverFilter districts={districts} />}
        actions={<DriverActions />}
        filterDefaultValues={{
          status: Driver_Status_Enum.Pending,
          is_typeform_general_info_submitted: true,
          'is_video_verification_submitted@_neq': true,
        }}
        sort={{
          field: 'created_at',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <TextField source="email" />
          <TextField source="first_name" emptyText="n/a" label="First name" />
          <TextField source="last_name" emptyText="n/a" label="Last name" />
          <TextField source="districts" emptyText="n/a" label="Districts" />
          <DateField source="created_at" />

          <FunctionField
            label="Video Approved"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_video_verification_approved);
            }}
          />

          <FunctionField
            label="Dev4Side registered"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_registered_on_dev4side);
            }}
          />

          <FunctionField
            render={(record: any) => (
              <Link to={`/carvoila_driver/${record.id}`}>VIEW</Link>
            )}
          />
        </Datagrid>
      </List>
      <h4>2. Academy in progress</h4>
      <List
        exporter={carvoilaDriverExporter}
        bulkActionButtons={false}
        {...props}
        filters={<CarvoilaDriverFilter districts={districts} />}
        actions={<DriverActions />}
        filterDefaultValues={{
          status: Driver_Status_Enum.Pending,
          is_video_verification_submitted: true,
          is_typeform_documents_submitted: false,
          is_gforms_academy_completed: false,
        }}
        sort={{
          field: 'created_at',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <TextField source="id" label="ID" />
          <TextField source="Dev4Side" emptyText="n/a" />
          <TextField source="email" />
          <TextField source="first_name" label="First name" />
          <TextField source="last_name" emptyText="n/a" label="Last name" />
          <TextField source="districts" emptyText="n/a" label="Districts" />
          <TextField
            source="phone_number"
            emptyText="n/a"
            label="Phone number"
          />
          <ChipArrayField
            {...props}
            source="districts"
            label="Districts"
            emptyText="n/a"
          />
          <TextField
            source="typeform_general_info_payload.score"
            emptyText="n/a"
            label="TF1 Score"
          />
          <DateField source="created_at" />

          <FunctionField
            label="Video Approved"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_video_verification_approved);
            }}
          />

          <FunctionField
            label="Dev4Side registered"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_registered_on_dev4side);
            }}
          />

          <FunctionField
            render={(record: any) => (
              <Link to={`/carvoila_driver/${record.id}`}>VIEW</Link>
            )}
          />
        </Datagrid>
      </List>
      <h4>3. Document verification in progress</h4>
      <List
        exporter={carvoilaDriverExporter}
        bulkActionButtons={false}
        {...props}
        filters={<CarvoilaDriverFilter districts={districts} />}
        actions={<DriverActions />}
        filterDefaultValues={{
          status: Driver_Status_Enum.Pending,
          is_video_verification_submitted: true,
          is_gforms_academy_completed: true,
          is_typeform_documents_submitted: false,
        }}
        sort={{
          field: 'created_at',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <TextField source="email" />
          <TextField source="first_name" emptyText="n/a" label="First name" />
          <TextField source="last_name" emptyText="n/a" label="Last name" />
          <TextField source="districts" emptyText="n/a" label="Districts" />
          <DateField source="created_at" />

          <FunctionField
            label="Video Approved"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_video_verification_approved);
            }}
          />

          <FunctionField
            label="Dev4Side registered"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_registered_on_dev4side);
            }}
          />

          <FunctionField
            render={(record: any) => (
              <Link to={`/carvoila_driver/${record.id}`}>VIEW</Link>
            )}
          />
        </Datagrid>
      </List>
      <h4>4. Final HR approval in progress</h4>
      <List
        exporter={carvoilaDriverExporter}
        bulkActionButtons={false}
        {...props}
        filters={<CarvoilaDriverFilter districts={districts} />}
        actions={<DriverActions />}
        filterDefaultValues={{
          status: Driver_Status_Enum.Pending,
          is_video_verification_submitted: true,
          is_typeform_documents_submitted: true,
          is_gforms_academy_completed: true,
        }}
        sort={{
          field: 'created_at',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <TextField source="email" />
          <TextField source="first_name" emptyText="n/a" label="First name" />
          <TextField source="last_name" emptyText="n/a" label="Last name" />
          <TextField source="districts" emptyText="n/a" label="Districts" />
          <DateField source="created_at" />

          <FunctionField
            label="Video Approved"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_video_verification_approved);
            }}
          />

          <FunctionField
            label="Dev4Side registered"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_registered_on_dev4side);
            }}
          />

          <FunctionField
            render={(record: any) => (
              <Link to={`/carvoila_driver/${record.id}`}>VIEW</Link>
            )}
          />
        </Datagrid>
      </List>
      <h2>OLD Before TF2 and Academy inversion</h2>
      <h4>2. Document verification in progress</h4>
      <List
        exporter={carvoilaDriverExporter}
        bulkActionButtons={false}
        {...props}
        filters={<CarvoilaDriverFilter districts={districts} />}
        actions={<DriverActions />}
        filterDefaultValues={{
          status: Driver_Status_Enum.Pending,
          is_video_verification_submitted: true,
          is_typeform_documents_submitted: false,
        }}
        sort={{
          field: 'created_at',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <TextField source="email" />
          <TextField source="first_name" emptyText="n/a" label="First name" />
          <TextField source="last_name" emptyText="n/a" label="Last name" />
          <TextField source="districts" emptyText="n/a" label="Districts" />
          <DateField source="created_at" />

          <FunctionField
            label="Video Approved"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_video_verification_approved);
            }}
          />

          <FunctionField
            label="Dev4Side registered"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_registered_on_dev4side);
            }}
          />

          <FunctionField
            render={(record: any) => (
              <Link to={`/carvoila_driver/${record.id}`}>VIEW</Link>
            )}
          />
        </Datagrid>
      </List>
      <h4>3. Academy in progress</h4>
      <List
        exporter={carvoilaDriverExporter}
        bulkActionButtons={false}
        {...props}
        filters={<CarvoilaDriverFilter districts={districts} />}
        actions={<DriverActions />}
        filterDefaultValues={{
          status: Driver_Status_Enum.Pending,
          is_video_verification_submitted: true,
          is_typeform_documents_submitted: true,
          is_gforms_academy_completed: false,
        }}
        sort={{
          field: 'created_at',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <TextField source="id" label="ID" />
          <TextField source="Dev4Side" emptyText="n/a" />
          <TextField source="email" />
          <TextField source="first_name" label="First name" />
          <TextField source="last_name" emptyText="n/a" label="Last name" />
          <TextField source="districts" emptyText="n/a" label="Districts" />
          <TextField
            source="phone_number"
            emptyText="n/a"
            label="Phone number"
          />
          <ChipArrayField
            {...props}
            source="districts"
            label="Districts"
            emptyText="n/a"
          />
          <TextField
            source="typeform_general_info_payload.score"
            emptyText="n/a"
            label="TF1 Score"
          />
          <DateField source="created_at" />

          <FunctionField
            label="Video Approved"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_video_verification_approved);
            }}
          />

          <FunctionField
            label="Dev4Side registered"
            render={(r: any) => {
              return renderBooleanIcons(r?.is_registered_on_dev4side);
            }}
          />

          <FunctionField
            render={(record: any) => (
              <Link to={`/carvoila_driver/${record.id}`}>VIEW</Link>
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

const DriverActions = (props: any) => {
  const { className, basePath, total, resource, currentSort, filters } = props;

  const { showFilter, displayedFilters, filterValues, exporter } =
    useListContext();

  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={exporter || undefined}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};
