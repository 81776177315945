import React, { useCallback, useEffect } from 'react';
import {
  BooleanInput,
  Edit,
  FormWithRedirect,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  useMutation,
} from 'react-admin';
import { useUtilities } from '@ugo/utils';
import { ArrayField, SingleFieldList } from 'ra-ui-materialui';
import { Box } from '@material-ui/core';

export function PartnerEdit(props: any) {
  const [mutate] = useMutation();
  const { removeDeleteButtonFromDom } = useUtilities();

  const save = useCallback(
    async (values) => {
      const data = { ...values };
      delete data.projects;
      delete data.id;
      delete data.created_at;
      // delete data.name;

      try {
        await mutate(
          {
            type: 'update',
            resource: 'partner',
            payload: { id: props.id, data },
          },
          { returnPromise: true, mutationMode: 'pessimistic' }
        );
      } catch (error: any) {
        console.log(error);
      }
    },
    [mutate]
  );
  useEffect(() => {
    removeDeleteButtonFromDom();
  }, []);

  return (
    <Edit {...props}>
      <FormWithRedirect
        {...props}
        save={save}
        render={(formProps) => {
          return (
            <Box flex={1} p="1em">
              <Box flex={1}>
                <TextInput label="Name" source="name" />
              </Box>
              <Box flex={1}>
                <TextInput label="Contact email" source="contact_email" />
              </Box>
              <Box flex={1}>
                <TextInput label="VAT Number" source="vat" />
              </Box>
              <Box flex={1}>
                <TextInput label="SDI code" source="sdi_code" />
              </Box>
              <Box flex={1}>
                <TextInput label="Address" source="address" />
              </Box>
              <Box flex={1} py={'16px'}>
                <Box mb={1}>Projects</Box>
                <ArrayField source="projects">
                  <SingleFieldList linkType={false}>
                    <TextField source="name" style={{ padding: '0px 5px' }} />
                  </SingleFieldList>
                </ArrayField>
              </Box>
              <Box flex={1}>
                <BooleanInput label="Enabled" source="enabled" />
              </Box>
              <SaveButton
                redirect={false}
                disabled={formProps.pristine}
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Box>
          );
        }}
      />
    </Edit>
  );
}
