import { Reservation_Status_Enum } from '@ugo/graphql-operations';
import { ServicesEnum } from '@ugo/models';

/**
 *
 * @description A list of reservation statuses
 */
export const reservationData = [
  { id: Reservation_Status_Enum.Accepted, name: 'Accepted' },
  { id: Reservation_Status_Enum.Canceled, name: 'Canceled' },
  { id: Reservation_Status_Enum.Finished, name: 'Finished' },
  { id: Reservation_Status_Enum.PaymentFailed, name: 'Payment failed' },
  { id: Reservation_Status_Enum.InProgress, name: 'In progress' },
  {
    id: Reservation_Status_Enum.PaymentInProgress,
    name: 'Payment in progress',
  },
  { id: Reservation_Status_Enum.Waiting, name: 'Waiting' },
  {
    id: Reservation_Status_Enum.FinishReservationOverdue,
    name: 'Finish reservation overdue',
  },
  {
    id: Reservation_Status_Enum.CanceledByPatient,
    name: 'Canceled by patient',
  },
  {
    id: Reservation_Status_Enum.Shelved,
    name: 'Shelved',
  },
  {
    id: Reservation_Status_Enum.DeclinedByPreferredDriver,
    name: 'Declined by preferred driver',
  },
];

/**
 *
 * @description A list of reservation services
 */
export const reservationServiceData = [
  { id: ServicesEnum.CaregivingWithUgosCar, name: 'Caregiving with Ugos car' },
  {
    id: ServicesEnum.CaregivingWithUsersCar,
    name: 'Caregiving with users car',
  },
  { id: ServicesEnum.CaregivingWithoutCar, name: 'Caregiving without car' },
  { id: ServicesEnum.CaregivingViaTelemedicine, name: 'Telemedicine' },
  { id: ServicesEnum.Errands, name: 'Errands' },
  { id: ServicesEnum.PhoneAssistance, name: 'Phone assistance' },
];
