import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { colorScheme } from '@ugo/data';
import { useUtilities } from '@ugo/utils';

export const ConversionsPerWeekLineChartWithVariantId = ({
  reservations,
  startDate,
  endDate,
}: {
  reservations: any[];
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { groupServiceCountByWeek } = useUtilities();
  const [graphData, setGraphData] = useState<Array<number[]>>([]);
  const [weeks, setWeeks] = useState<string[]>([]);
  const [variant0services, setVariant0services] = useState([]);
  const [variant1services, setVariant1services] = useState([]);
  const [variant2services, setVariant2services] = useState([]);

  useEffect(() => {
    const variant0reservations = reservations.filter(
      (r) => r.reservation_metadata.variant_id === '0'
    );

    const variant1reservations = reservations.filter(
      (r) => r.reservation_metadata.variant_id === '1'
    );

    const variant2reservations = reservations.filter(
      (r) => r.reservation_metadata.variant_id === '2'
    );

    const { weeks, servicesByWeeks } = groupServiceCountByWeek(
      reservations,
      [],
      startDate,
      endDate
    );

    const { servicesByWeeks: servicesByWeeksByVariant0 } =
      groupServiceCountByWeek(variant0reservations, [], startDate, endDate);

    const { servicesByWeeks: servicesByWeeksByVariant1 } =
      groupServiceCountByWeek(variant1reservations, [], startDate, endDate);

    const { servicesByWeeks: servicesByWeeksByVariant2 } =
      groupServiceCountByWeek(variant2reservations, [], startDate, endDate);

    // @ts-ignore
    setWeeks(weeks);
    setGraphData([
      // @ts-ignore
      servicesByWeeks,
      servicesByWeeksByVariant0,
      servicesByWeeksByVariant1,
      servicesByWeeksByVariant2,
    ]);
  }, [reservations, startDate, endDate]);

  return (
    <Line
      data={{
        labels: weeks.map((w) => {
          const date = new Date(w);
          const weekStart = startOfWeek(date, { weekStartsOn: 1 });
          const weekEnd = endOfWeek(date, {
            weekStartsOn: 1,
          });

          return `${format(weekStart, 'dd/MM')} - ${format(weekEnd, 'dd/MM')}`;
        }),
        datasets: [
          {
            label: 'Total first reservations',
            data: graphData[0],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[0],
            backgroundColor: 'transparent',
          },
          {
            label: 'Variant 0',
            data: graphData[1],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[1],
            backgroundColor: 'transparent',
          },
          {
            label: 'Variant 1',
            data: graphData[2],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[2],
            backgroundColor: 'transparent',
          },
          {
            label: 'Variant 2',
            data: graphData[3],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[3],
            backgroundColor: 'transparent',
          },
        ],
      }}
      options={{
        title: {
          display: true,
          text: 'Conversions per week',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
      }}
    />
  );
};
