import { useState } from 'react';
import { getDaysArray, getMonthsArray, getWeeksArray } from '@ugo/utils';
import { eachMonthOfInterval, eachWeekOfInterval, isSameDay } from 'date-fns';
import { Reservation, Reservation_Status_Enum } from '@ugo/graphql-operations';
import {
  filter,
  fromPairs,
  groupBy,
  map,
  merge,
  pair,
  pipe,
  prop,
  toPairs,
  values,
} from 'ramda';
/**
 * @description Recursively flattens deep objects
 *  - foo.bar.baz is transformed to foo_bar_baz
 */
export function flattenObjectWithParentKeys(
  obj: any,
  parent?: string,
  res = {}
) {
  for (const key in obj) {
    const propName = parent ? parent + '_' + key : key;

    if (typeof obj[key] === typeof {} && !Array.isArray(obj[key])) {
      flattenObjectWithParentKeys(obj[key], propName, res);
    } else {
      // @ts-ignore
      res[propName] = obj[key];
    }
  }
  return res;
}

/**
 * @description A collection of utilities
 */
export const useUtilities = () => {
  function removeDeleteButtonFromDom(): boolean {
    const el = document && document.querySelector('.ra-delete-button');

    if (el) {
      (el as any).remove('ra-delete-button');
    }

    return true;
  }

  function groupServiceCountByDay(
    allReservations: any,
    firstReservations: any[],
    startDate: Date | null,
    endDate: Date | null
  ) {
    let days: Date[] = [];
    const reservationsByDays: number[] = [];
    const finishedReservationsByDays: number[] = [];
    const duplicatedReservationsByDays: number[] = [];
    const reservationsByReturningPatientsByDays: number[] = [];
    const manualReservationsByDays: number[] = [];

    if (!startDate || !endDate) {
      return {
        days,
        reservationsByDays,
        finishedReservationsByDays,
        duplicatedReservationsByDays,
        reservationsByReturningPatientsByDays,
        manualReservationsByDays,
      };
    }

    days = getDaysArray(startDate, endDate, true);

    days.forEach(() => {
      reservationsByDays.push(0);
      finishedReservationsByDays.push(0);
      duplicatedReservationsByDays.push(0);
      reservationsByReturningPatientsByDays.push(0);
      manualReservationsByDays.push(0);
    });

    Object.keys(allReservations || {}).forEach((sid) => {
      const dayIndex = days.findIndex((day) =>
        isSameDay(day, new Date(allReservations[sid].created_at))
      );

      if (dayIndex > -1) {
        reservationsByDays[dayIndex] += 1;

        if (allReservations[sid]?.status === Reservation_Status_Enum.Finished) {
          finishedReservationsByDays[dayIndex] += 1;
        }

        if (allReservations[sid]?.duplication_reference_id) {
          duplicatedReservationsByDays[dayIndex] += 1;
        }

        if (
          !firstReservations
            .map((fr: any) => fr.id)
            .filter((frid: any) => !!frid)
            .includes(sid)
        ) {
          reservationsByReturningPatientsByDays[dayIndex] += 1;
        }

        if (allReservations[sid]?.is_manual) {
          manualReservationsByDays[dayIndex] += 1;
        }
      }
    });

    return {
      days,
      reservationsByDays,
      finishedReservationsByDays,
      duplicatedReservationsByDays,
      reservationsByReturningPatientsByDays,
      manualReservationsByDays,
    };
  }

  function getServicesByWeek(services: any[]) {
    return pipe(
      // @ts-ignore
      map((s: any) => ({
        week: eachWeekOfInterval(
          {
            start: new Date(s.created_at),
            end: new Date(s.created_at),
          },
          { weekStartsOn: 1 }
        )[0].toUTCString(),
      })),
      values,
      // @ts-ignore
      groupBy(prop('week')),
      toPairs,
      // @ts-ignore
      map(([key, value]) => pair(key, value.length)),
      values,
      fromPairs
      // @ts-ignore
    )(services || []);
  }

  function getServicesByMonth(services: any[]) {
    return pipe(
      // @ts-ignore
      map((s: any) => ({
        month: eachMonthOfInterval({
          start: new Date(s.created_at),
          end: new Date(s.created_at),
        })[0].toUTCString(),
      })),
      values,
      // @ts-ignore
      groupBy(prop('month')),
      toPairs,
      // @ts-ignore
      map(([key, value]) => pair(key, value.length)),
      values,
      fromPairs
      // @ts-ignore
    )(services || []);
  }

  function groupServiceCountByWeek(
    allReservations: any,
    firstReservations: any[],
    startDate: Date | null,
    endDate: Date | null
  ) {
    let weeks: string[] = [];
    let servicesByWeeks: number[] = [];
    let duplicatedServicesByWeeks: number[] = [];
    let reservationsByReturningPatientsByWeeks: number[] = [];

    if (!startDate || !endDate) {
      return {
        weeks,
        servicesByWeeks,
        duplicatedServicesByWeeks,
        reservationsByReturningPatientsByWeeks,
      };
    }

    // TODO: Add this logic to getWeeksArray
    // @ts-ignore
    weeks = pipe(
      map((w: Date) => pair(w.toUTCString(), 0)),
      // @ts-ignore
      values,
      // @ts-ignore
      fromPairs
      // @ts-ignore
    )(getWeeksArray(startDate, endDate));

    const reservationsByReturningPatients = pipe(
      filter(
        (r: Reservation) =>
          !!r.id &&
          !firstReservations
            .map((fr: any) => fr.id)
            .filter((frid: any) => !!frid)
            .includes(r.id)
      )
    )(allReservations || []);

    const duplicatedServices = pipe(
      filter((s: any) => s?.duplication_reference_id)
    )(allReservations || []);

    // @ts-ignore
    servicesByWeeks = getServicesByWeek(allReservations);
    // @ts-ignore
    duplicatedServicesByWeeks = getServicesByWeek(duplicatedServices);
    // @ts-ignore
    reservationsByReturningPatientsByWeeks = getServicesByWeek(
      // @ts-ignore
      reservationsByReturningPatients
    );

    const numberOfServicesByWeek = merge(weeks, servicesByWeeks);
    const numberOfDuplicatedServicesByWeeks = merge(
      weeks,
      duplicatedServicesByWeeks
    );
    const numberOfReturningPatientsByWeeks = merge(
      weeks,
      reservationsByReturningPatientsByWeeks
    );

    // @ts-ignore
    return {
      weeks: Object.keys(numberOfServicesByWeek).sort(
        // @ts-ignore
        (a, b) => new Date(a) - new Date(b)
      ),
      servicesByWeeks: Object.values(numberOfServicesByWeek),
      duplicatedServicesByWeeks: Object.values(
        numberOfDuplicatedServicesByWeeks
      ),
      reservationsByReturningPatientsByWeeks: Object.values(
        numberOfReturningPatientsByWeeks
      ),
    };
  }

  function groupServiceCountByMonth(
    allReservations: any,
    firstReservations: any[],
    startDate: Date | null,
    endDate: Date | null
  ) {
    let months: string[] = [];
    let servicesByMonths: number[] = [];
    let duplicatedServicesByMonths: number[] = [];
    let reservationsByReturningPatientsByMonths: number[] = [];

    if (!startDate || !endDate) {
      return {
        months,
        servicesByMonths,
        duplicatedServicesByMonths,
        reservationsByReturningPatientsByMonths,
      };
    }

    // @ts-ignore
    months = pipe(
      map((m: Date) => pair(m.toUTCString(), 0)),
      // @ts-ignore
      values,
      // @ts-ignore
      fromPairs
      // @ts-ignore
    )(getMonthsArray(startDate, endDate));

    const reservationsByReturningPatients = pipe(
      filter(
        (r: Reservation) =>
          !!r.id &&
          !firstReservations
            .map((fr: any) => fr.id)
            .filter((frid: any) => !!frid)
            .includes(r.id)
      )
    )(allReservations || []);

    const duplicatedServices = pipe(
      filter((s: any) => s?.duplication_reference_id)
    )(allReservations || []);

    // @ts-ignore
    servicesByMonths = getServicesByMonth(allReservations);
    // @ts-ignore
    duplicatedServicesByMonths = getServicesByMonth(duplicatedServices);
    // @ts-ignore
    reservationsByReturningPatientsByMonths = getServicesByMonth(
      // @ts-ignore
      reservationsByReturningPatients
    );

    const numberOfServicesByMonths = merge(months, servicesByMonths);
    const numberOfDuplicatedServicesByMonths = merge(
      months,
      duplicatedServicesByMonths
    );
    const numberOfReturningPatientsByMonths = merge(
      months,
      reservationsByReturningPatientsByMonths
    );

    return {
      months: Object.keys(months),
      servicesByMonths: Object.values(numberOfServicesByMonths),
      duplicatedServicesByMonths: Object.values(
        numberOfDuplicatedServicesByMonths
      ),
      reservationsByReturningPatientsByMonths: Object.values(
        numberOfReturningPatientsByMonths
      ),
    };
  }

  const [utilState] = useState({
    removeDeleteButtonFromDom,
    flattenObjectWithParentKeys,
    groupServiceCountByDay,
    groupServiceCountByWeek,
    groupServiceCountByMonth,
  });

  return utilState;
};
