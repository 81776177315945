import React from 'react';
import { Create, useNotify, useRedirect, useRefresh } from 'react-admin';
import { ManualReservationWithPreferredDriverForm } from './ManualReservationWithPreferredDriverForm';

export const ManualReservationWithPreferredDriverCreate = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const validateManualReservationCreation = (values: any) => {
    const errors = {
      ...([
        'service',
        'service_city',
        'status',
        'reservor_id',
        'starts_at',
        'duration_estimate',
        'departure_address',
        'destination_address',
        'price_estimate',
        'driver_preferred_id',
      ]
        .filter((v) => !values[v])
        .reduce((accumulator: any, value: any) => {
          accumulator[value] = `${value} is required`;
          return accumulator;
        }, {}) || {}),
    };

    return errors;
  };

  const onSuccess = (e) => {
    console.log(e);
    notify(`Changes saved`, { type: 'success' });
    redirect(`/manual_reservation_with_preferred_driver/${e.data.id}`);
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <ManualReservationWithPreferredDriverForm
        {...props}
        validate={validateManualReservationCreation}
        submitOnEnter={false}
      />
    </Create>
  );
};
