import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Line } from 'react-chartjs-2';
import { colorScheme } from '@ugo/data';
import { Box, Card, Typography } from '@material-ui/core';
import { endOfDay, format, isBefore } from 'date-fns';
import { getDaysArray } from '@ugo/utils';
import { ChartComponentProps } from '@ugo/models';

/**
 * Returns grouped payment average
 */
const evaluatePaymentAverageByDays = (
  payments: any,
  startDate: Date | null,
  endDate: Date | null,
  driverCount: number
) => {
  const rangePaymentAverage: number[] = [];
  let days: Date[] = [];

  if (!startDate || !endDate) {
    return { days, rangePaymentAverage };
  }

  const paymentsArray = Object.keys(payments).map((pid) => payments[pid]);

  days = getDaysArray(startDate, endDate, true);

  days.forEach(() => rangePaymentAverage.push(0));

  days.forEach((d, i) => {
    const paymentsInRangeForDayArray = paymentsArray.filter(
      (p) => p.metadata && isBefore(new Date(p.created_at), endOfDay(d))
    );

    const totalUpToDay = paymentsInRangeForDayArray.reduce((a, c) => {
      return a + c.metadata.driver_amount;
    }, 0);

    rangePaymentAverage[i] = totalUpToDay / driverCount;
  });

  return { days, rangePaymentAverage };
};

const AverageDriversEarningLineChart = (props: ChartComponentProps) => {
  const [graphData, setGraphData] = useState<number[]>([]);
  const [days, setDays] = useState<string[]>([]);
  const [totalDrivers, setTotalDrivers] = useState<number>();

  const { data: paymentData } = useGetList(
    'payment',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'ASC' },
    {
      'created_at@_gte': props.startDate,
      'created_at@_lte': props.endDate,
    }
  );
  const { data: driverData } = useGetList(
    'driver_profile',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'ASC' },
    {
      'created_at@_gte': props.startDate,
      'created_at@_lte': props.endDate,
    }
  );

  useEffect(() => {
    if (paymentData && driverData) {
      const { days, rangePaymentAverage } = evaluatePaymentAverageByDays(
        paymentData,
        props.startDate,
        props.endDate,
        Object.keys(driverData).length
      );
      setTotalDrivers(Object.keys(driverData).length);
      setDays(days.map((d) => format(d, 'yyyy-MM-dd')));
      setGraphData(rangePaymentAverage);
    }
  }, [paymentData, driverData]);

  return (
    <Card>
      <Box px={2} pt={2}>
        <Typography color="textSecondary">
          <small>
            Driver earning average up to day for {totalDrivers} drivers
          </small>
        </Typography>
      </Box>
      <Box height="350px" px={2} pb={2}>
        <Line
          data={{
            labels: days,
            datasets: [
              {
                label: 'Average',
                data: graphData,
                lineTension: 0,
                pointRadius: 2,
                borderColor: colorScheme[0],
                backgroundColor: 'transparent',
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    precision: 0,
                    beginAtZero: true,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </Box>
    </Card>
  );
};
export default AverageDriversEarningLineChart;
