import React, { useEffect, useState } from 'react';
import { colorScheme } from '@ugo/data';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { useUtilities } from '@ugo/utils';

export const TotalVsManualConversionsPerDayLineChart = ({
  allConversionReservations,
  reservations,
  startDate,
  endDate,
}: {
  allConversionReservations: any[];
  reservations: any[];
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { groupServiceCountByDay } = useUtilities();
  const [graphData, setGraphData] = useState<Array<number[]>>([]);
  const [days, setDays] = useState<string[]>([]);

  useEffect(() => {
    const { days, reservationsByDays: manualConversionReservationsByDays } =
      groupServiceCountByDay(reservations, [], startDate, endDate);

    const { reservationsByDays: allConversionReservationsByDays } =
      groupServiceCountByDay(allConversionReservations, [], startDate, endDate);

    setDays(
      days
        .map((d) => format(d, 'yyyy-MM-dd'))
        .map(
          (d, idx) =>
            `${(
              (manualConversionReservationsByDays[idx] /
                allConversionReservationsByDays[idx] || 0) * 100
            ).toFixed(2)}% - ${d}`
        )
    );
    setGraphData([
      allConversionReservationsByDays,
      manualConversionReservationsByDays,
    ]);
  }, [allConversionReservations, reservations, startDate, endDate]);

  return (
    <Line
      data={{
        labels: days,
        datasets: [
          {
            label: 'Total Conversions',
            data: graphData[0],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[0],
            backgroundColor: 'transparent',
          },
          {
            label: 'Manual Conversions',
            data: graphData[1],
            lineTension: 0,
            pointRadius: 5,
            borderColor: colorScheme[1],
            backgroundColor: 'transparent',
          },
        ],
      }}
      options={{
        title: {
          display: true,
          text: 'Total / Manual conversions per day ratio',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
      }}
    />
  );
};
