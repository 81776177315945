import React, { useState } from 'react';
import { AppBar, Layout } from 'react-admin';
import MyMenu from './Menu';
import { UserMenu } from 'ra-ui-materialui';
import { Chip, makeStyles, Typography } from '@material-ui/core';
import { useGetSyncsInProgressCountSubSubscription } from '@ugo/graphql-operations';

const MyLayout = (props: any) => (
  <Layout
    {...props}
    style={{ maxWidth: '100%', marginTop: '32px' }}
    menu={MyMenu}
    appBar={MyAppBar}
  />
);

// export const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
//   <MenuItemLink
//     ref={ref}
//     to="/configuration"
//     primaryText="Configuration"
//     onClick={onClick} // close the menu on click
//   />
// ));

export const MyUserMenu = (props) => (
  <UserMenu {...props}>{/*<ConfigurationMenu />*/}</UserMenu>
);

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  appBar: {
    padding: '16px',
  },
});
export const MyAppBar = (props) => {
  const classes = useStyles();
  const [isAnySyncInProgress, setIsAnySyncInProgress] = useState(false);

  useGetSyncsInProgressCountSubSubscription({
    onSubscriptionData: (payload) => {
      setIsAnySyncInProgress(
        !!payload.subscriptionData?.data?.sync_aggregate.aggregate.count
      );
    },
  });

  return (
    <AppBar {...props} className={classes.appBar} userMenu={<MyUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      >
        Ugo
      </Typography>
      <span className={classes.spacer} />
      {isAnySyncInProgress && <Chip label="Syncing..." />}
    </AppBar>
  );
};

export default MyLayout;
