/**
 * @description Traffic sources that we get from asking our customers
 */
export const TRAFFIC_SOURCES: any[] = [
	{
		id: 'GOOGLE',
		name: 'Google',
	},
	{
		id: 'FACEBOOK',
		name: 'Facebook',
	},
	{
		id: 'WORD_OF_MOUTH',
		name: "Word Of Mouth",
	},
	{
		id: 'BLOG',
		name: 'Blog',
	},
	{
		id: 'AFFILIATE',
		name: 'Affiliate',
	},
	{
		id: 'FLYER',
		name: 'Flyer',
	},
	{
		id: 'OTHER',
		name: 'Other',
	},
];
