import {
  CalculationData,
  CalculationStrategy,
  StrategyCalculateResponse,
} from './strategies/calculation-strategy';
import { CaregivingWithUgosCarStrategy } from './strategies/caregiving-with-ugos-car-strategy';
import { CaregivingWithUsersCarStrategy } from './strategies/caregiving-with-users-car-strategy';
import { CaregivingWithoutCarStrategy } from './strategies/caregiving-without-car-strategy';
import { CaregivingViaTelemedicineStrategy } from './strategies/caregiving-via-telemedicine-strategy';
import { CaregivingErrandsStrategy } from './strategies/caregiving-errands-strategy';
import { Service } from './utils';
import { CaregivingPhoneAssistanceStrategy } from './strategies/caregiving-phone-assistance-strategy';

export class Cost {
  private value: number;

  constructor(cost: number) {
    this.value = cost;
  }

  getRawValue() {
    return this.value;
  }

  getRoundedValue() {
    return Math.round(this.value * 2) / 2;
  }
}

class CalculationResult {
  private totalCost: Cost;
  private totalCostDiscounted?: Cost;
  private timeCost: number | undefined;
  private distanceCost: number | undefined;
  private driverDistance: number | undefined;
  private caregivingDuration: number | undefined;
  private kaskoCost: number | undefined;
  private travelDistance: number | undefined;

  constructor(data: StrategyCalculateResponse) {
    this.totalCost = new Cost(data.totalCost);

    if (data.travelDistance) {
      this.travelDistance = data.travelDistance;
    }

    if (data.caregivingDuration) {
      this.caregivingDuration = data.caregivingDuration;
    }

    if (data.driverDistance) {
      this.driverDistance = data.driverDistance;
    }

    if (data.timeCost) {
      this.timeCost = data.timeCost;
    }
    if (data.distanceCost) {
      this.distanceCost = data.distanceCost;
    }
    if (data.kaskoCost) {
      this.kaskoCost = data.kaskoCost;
    }
  }

  getCost() {
    return this.totalCost;
  }

  applyPercentDiscount(percent: number) {
    const discount = this.totalCost.getRawValue() * (percent / 100);

    this.totalCostDiscounted = new Cost(
      this.totalCost.getRawValue() - discount
    );

    return this.totalCostDiscounted;
  }
}

class QuoteCalculator {
  private calculationStrategy: CalculationStrategy;

  constructor(strategy: CalculationStrategy) {
    this.calculationStrategy = strategy;
  }

  calculate(data: CalculationData) {
    return new CalculationResult(this.calculationStrategy.calculate(data));
  }
}

export type QuoteCalculatorType = QuoteCalculator;

export class QuoteCalculatorFactory {
  static withStrategy(service: Service): QuoteCalculator {
    let strategy: CalculationStrategy;
    switch (service) {
      case 'CAREGIVING_WITH_UGOS_CAR': {
        strategy = new CaregivingWithUgosCarStrategy();
        break;
      }
      case 'CAREGIVING_WITH_USERS_CAR': {
        strategy = new CaregivingWithUsersCarStrategy();
        break;
      }
      case 'CAREGIVING_WITHOUT_CAR': {
        strategy = new CaregivingWithoutCarStrategy();
        break;
      }
      case 'CAREGIVING_VIA_TELEMEDICINE': {
        strategy = new CaregivingViaTelemedicineStrategy();
        break;
      }
      case 'ERRANDS': {
        strategy = new CaregivingErrandsStrategy();
        break;
      }
      case 'PHONE_ASSISTANCE': {
        strategy = new CaregivingPhoneAssistanceStrategy();
        break;
      }
      default: {
        throw new Error(`Service ${service} not supported`);
      }
    }

    return new QuoteCalculator(strategy);
  }
}
