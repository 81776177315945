import {
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  TextField,
} from 'react-admin';
import { NumberField } from 'ra-ui-materialui';
import React from 'react';
import {
  ReservationListFilter,
  ReservationStatusField,
} from '../reservations/ReservationList';
import { Reservation_Status_Enum } from '@ugo/graphql-operations';
import { Chip } from '@material-ui/core';

export function FailedPaymentsReservations(props: any) {
  return (
    <List
      {...props}
      sort={{ field: 'payment_failed_reminders_sent_count', order: 'DESC' }}
      filterDefaultValues={{ status: Reservation_Status_Enum.PaymentFailed }}
      filters={<ReservationListFilter />}
    >
      <Datagrid>
        <FunctionField
          label="Severity"
          render={(r: any) => (
            <ReminderSeverityStatus
              reminderSentCount={r.payment_failed_reminders_sent_count}
            />
          )}
        />
        <TextField source="short_id" label="Short ID" />
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <DateField source="starts_at" showTime />
        <TextField source="metadata.service" />
        <NumberField source="payment_failed_reminders_sent_count" />
        <TextField
          style={{ textTransform: 'capitalize' }}
          source="metadata.service_city"
          label="City"
          emptyText="n/a"
        />
        <ReservationStatusField source="status" emptyText="n/a" />
        <FunctionField
          label="View"
          render={(r: any) => <Link to={`/reservation/${r.id}`}>VIEW</Link>}
        />
      </Datagrid>
    </List>
  );
}

const ReminderSeverityStatus = ({
  reminderSentCount,
}: {
  reminderSentCount: number;
}) => {
  if (reminderSentCount < 3) {
    return <Chip style={{ backgroundColor: '#e1c66b' }} label="Warn" />;
  }
  if (reminderSentCount >= 3) {
    return <Chip style={{ backgroundColor: '#e1736b' }} label="Critical" />;
  }

  return <Chip label="No warn" />;
};
