import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Bar } from 'react-chartjs-2';
import { ADMIN_SUPPORTED_CITIES } from '../../../../../../libs/data/src/admin-cities-data';
import { colorScheme } from '@ugo/data';
import { Box, Card } from '@material-ui/core';

const groupDriverCountByCities = (driverProfiles: any) => {
  const driversByCities: number[] = [];
  const indexedCities: { [key: string]: number } = {};
  ADMIN_SUPPORTED_CITIES.forEach((c, i) => {
    indexedCities[c] = i;
    driversByCities.push(0);
  });

  Object.keys(driverProfiles).forEach((dpk) => {
    Object.keys(driverProfiles[dpk].cities).forEach((c) => {
      if (
        driverProfiles[dpk].cities[c] &&
        ADMIN_SUPPORTED_CITIES.indexOf(c) > -1
      ) {
        driversByCities[indexedCities[c]] += 1;
      }
    });
  });

  return driversByCities;
};

const DriversPerCityBarChart = () => {
  const [graphData, setGraphData] = useState<number[]>([]);

  const { data } = useGetList(
    'driver_profile',
    { page: 1, perPage: 999999 },
    { field: 'created_at', order: 'DESC' },
    {}
  );

  useEffect(() => {
    setGraphData(groupDriverCountByCities(data));
  }, [data]);

  return (
    <Card>
      <Box p={2}>
        <Bar
          data={{
            labels: ADMIN_SUPPORTED_CITIES,
            datasets: [
              {
                label: 'Total drivers per city',
                data: graphData,
                backgroundColor: colorScheme[0],
              },
            ],
          }}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </Box>
    </Card>
  );
};
export default DriversPerCityBarChart;
