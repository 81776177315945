import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

/**
 * Adding new fields:
 * Create a new object with a property name
 *
 * In the future we can set up different types of inputs (select, toggle...)
 */
export const accountFeedbackFields = {
  // property_1: {
  // 	name: 'Property 1',
  // },
};

const AccountMetadata = ({ initialState = {}, onChange }: any) => {
  const [accountFeedbackMetadata, setAccountFeedbackMetadata] = useState({});

  useEffect(() => {
    if (Object.keys(initialState).length) {
      setAccountFeedbackMetadata(initialState);
    }
  }, [initialState]);

  useEffect(() => {
    onChange(accountFeedbackMetadata);
  }, [accountFeedbackMetadata]);

  // ø

  return (
    /**
     * BEFORE ADDING READ:
     * Property also needs to be added to the `accountMetadataFields` along with using a component to edit the property
     */
    <Box>
      {/*<Box display="flex" px="1em" mb="1em">*/}
      {/*	<Box mr={3}>Property 1:</Box>*/}
      {/*	<Box>*/}
      {/*		<input*/}
      {/*			placeholder={accountFeedbackFields['property_1'].name}*/}
      {/*			value={accountFeedbackMetadata['property_1']}*/}
      {/*			onChange={e => changeMetadataField(e, 'property_1')}*/}
      {/*		/>*/}
      {/*	</Box>*/}
      {/*</Box>*/}
    </Box>
  );
};

export default AccountMetadata;
