import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';

export const BlockingOverlay = ({ show }: { show: boolean }) => {
  return (
    <Dialog open={show}>
      <DialogTitle>Operation in progress</DialogTitle>
      <DialogContent>
        <Box mb={3}>Please wait while operations are in progress...</Box>
      </DialogContent>
    </Dialog>
  );
};
