import * as React from 'react';
import { useState } from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  Filter,
  FunctionField,
  List,
  ResourceComponentProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { GLOBAL_EXPORTER_SETTINGS } from '@ugo/data';
import { MenuItem, Select } from '@material-ui/core';
import { omit } from 'ramda';
import { Driver_Status_Enum } from '@ugo/graphql-operations';
import { renderBooleanIcons } from '../reservations/ReservationList';

const DFilter = (props: any) => {
  const { setFilters, filterValues } = useListContext();
  const [selectedRole, setSelectedRole] = useState('-1');

  const onRoleSelect = (e: any) => {
    const val = e.target.value;

    let filter;

    if (val === 'patient') {
      filter = { ...filterValues, is_patient: true, is_driver: false };
      setSelectedRole('patient');
    } else if (val === 'driver') {
      filter = { ...filterValues, is_driver: true, is_patient: false };
      setSelectedRole('driver');
    } else {
      filter = omit(['is_driver', 'is_patient'], { ...filterValues });
      setSelectedRole('-1');
    }

    setFilters(filter, filter);
  };

  return (
    <div>
      <Filter {...props}>
        <TextInput source="id@_ilike" label="ID" alwaysOn />
        <TextInput source="email@_ilike" label="Email" alwaysOn />
        <TextInput source="last_name@_ilike" label="Last name" alwaysOn />
        <TextInput
          source="voucherify_customer_id@_ilike"
          label="Voucherify ID"
        />
      </Filter>
    </div>
  );
};

export const DriverOnboarding = (props: ResourceComponentProps) => (
  <>
    <h4>1. Video verification in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        driver_status: Driver_Status_Enum.Pending,
        is_general_info_submitted: true,
        'is_video_verification_submitted@_is_null': true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <DateField source="created_at" />

        <FunctionField
          label="Video Approved"
          render={(r: any) => {
            return renderBooleanIcons(r?.is_video_verification_approved);
          }}
        />

        <TextField source="dof_requested_city" emptyText="n/a" label="City" />

        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
    <h4>[NEW] 2. Academy in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        driver_status: Driver_Status_Enum.Pending,
        is_video_verification_submitted: true,
        'is_typeform_documents_submitted@_is_null': true,
        'is_academy_completed@_is_null': true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <DateField source="created_at" />
        <FunctionField
          label="Video Approved"
          render={(r: any) =>
            renderBooleanIcons(r?.is_video_verification_approved)
          }
        />
        <TextField source="dof_requested_city" emptyText="n/a" label="City" />
        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
    <h4>[NEW] 3. Document verification in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        driver_status: Driver_Status_Enum.Pending,
        is_video_verification_submitted: true,
        is_academy_completed: true,
        'is_typeform_documents_submitted@_is_null': true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <DateField source="created_at" />
        <FunctionField
          label="Video Approved"
          render={(r: any) =>
            renderBooleanIcons(r?.is_video_verification_approved)
          }
        />

        <TextField source="dof_requested_city" emptyText="n/a" label="City" />

        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
    <h4>[NEW]4. Final HR approval in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        driver_status: Driver_Status_Enum.Pending,
        is_typeform_documents_submitted: true,
        is_general_info_submitted: true,
        is_video_verification_submitted: true,
        is_academy_completed: true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <TextField
          source="is_video_verification_approved"
          emptyText="n/a"
          label="Video Approved"
        />
        <TextField
          source="is_document_verification_approved"
          emptyText="n/a"
          label="Documents Approved"
        />
        <DateField source="created_at" />
        <FunctionField
          label="Video Approved"
          render={(r: any) =>
            renderBooleanIcons(r?.is_video_verification_approved)
          }
        />

        <TextField source="dof_requested_city" emptyText="n/a" label="City" />

        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
    {/* <h4>2. Document verification in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        is_video_verification_submitted: true,
        'is_typeform_documents_submitted@_is_null': true,
        'is_academy_completed@_is_null': true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <DateField source="created_at" />
        <FunctionField
          label="Video Approved"
          render={(r: any) =>
            renderBooleanIcons(r?.is_video_verification_approved)
          }
        />

        <TextField source="dof_requested_city" emptyText="n/a" label="City" />

        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List> */}
    <h4>[OLD] 2.1. ID card verification in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        is_video_verification_submitted: true,
        document_verification_id_verified: false,
        'is_typeform_documents_submitted@_neq': true,
        'is_academy_completed@_is_null': true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <TextField
          source="document_verification_id_status"
          emptyText="n/a"
          label="ID card verification status"
        />
        <DateField source="created_at" />
        <FunctionField
          label="Video Approved"
          render={(r: any) =>
            renderBooleanIcons(r?.is_video_verification_approved)
          }
        />

        <TextField source="dof_requested_city" emptyText="n/a" label="City" />

        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
    <h4>[OLD] 2.2. Driver license verification in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        is_video_verification_submitted: true,
        document_verification_id_verified: true,
        document_verification_driver_license_verified: false,
        'is_typeform_documents_submitted@_neq': true,
        'is_academy_completed@_is_null': true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <TextField
          source="document_verification_driver_license_status"
          emptyText="n/a"
          label="Driver license verification status"
        />
        <DateField source="created_at" />
        <FunctionField
          label="Video Approved"
          render={(r: any) =>
            renderBooleanIcons(r?.is_video_verification_approved)
          }
        />

        <TextField source="dof_requested_city" emptyText="n/a" label="City" />

        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
    {/* <h4>4. Academy in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        driver_status: Driver_Status_Enum.Pending,
        is_typeform_documents_submitted: true,
        'is_academy_completed@_is_null': true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <DateField source="created_at" />
        <FunctionField
          label="Video Approved"
          render={(r: any) =>
            renderBooleanIcons(r?.is_video_verification_approved)
          }
        />
        <TextField source="dof_requested_city" emptyText="n/a" label="City" />
        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
    <h4>5. Final HR approval in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        driver_status: Driver_Status_Enum.Pending,
        is_typeform_documents_submitted: true,
        is_general_info_submitted: true,
        is_video_verification_submitted: true,
        is_academy_completed: true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <TextField
          source="is_video_verification_approved"
          emptyText="n/a"
          label="Video Approved"
        />
        <TextField
          source="is_document_verification_approved"
          emptyText="n/a"
          label="Documents Approved"
        />
        <DateField source="created_at" />
        <FunctionField
          label="Video Approved"
          render={(r: any) =>
            renderBooleanIcons(r?.is_video_verification_approved)
          }
        />

        <TextField source="dof_requested_city" emptyText="n/a" label="City" />

        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List> */}
    <h4>[OLD] 5.1. Final HR approval in progress</h4>
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DFilter />}
      actions={<DriverActions />}
      filterDefaultValues={{
        is_driver: true,
        driver_status: Driver_Status_Enum.Pending,
        document_verification_status: 'verified',
        is_general_info_submitted: true,
        is_video_verification_submitted: true,
        is_academy_completed: true,
      }}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField
          source="personal_data.first_name"
          emptyText="n/a"
          label="First name"
        />
        <TextField
          source="personal_data.last_name"
          emptyText="n/a"
          label="Last name"
        />
        <TextField
          source="is_video_verification_approved"
          emptyText="n/a"
          label="Video Approved"
        />
        <TextField
          source="is_document_verification_approved"
          emptyText="n/a"
          label="Documents Approved"
        />
        <DateField source="created_at" />
        <FunctionField
          label="Video Approved"
          render={(r: any) =>
            renderBooleanIcons(r?.is_video_verification_approved)
          }
        />

        <TextField source="dof_requested_city" emptyText="n/a" label="City" />

        <FunctionField
          render={(record: any) => (
            <Link to={`/account/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
  </>
);

const DriverActions = (props: any) => {
  const { className, basePath, total, resource, currentSort, filters } = props;

  const { showFilter, displayedFilters, filterValues, exporter } =
    useListContext();

  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={exporter || undefined}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};
