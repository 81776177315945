import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import { TabPanel } from '../../shared/TabPanel';
import { useGetDofDriversQuery } from '@ugo/graphql-operations';
import DateFilter from '../../shared/DateFilter';
import { useAtom } from 'jotai';
import { datePresetValueAtom } from '../../../shared/globalState';
import { SUPPORTED_CITIES } from '@ugo/data';
import InputLabel from '@material-ui/core/InputLabel';

const DOFAnalytics = (props: any) => {
  const [dofData, setDofData] = useState();
  const [datePresetValue] = useAtom(datePresetValueAtom);
  const [dateFilter, setDateFilter] = useState<Date[]>([]);
  const [cityFilter, setCityFilter] = useState<number | string>(-1);

  const { data } = useGetDofDriversQuery({
    skip: !dateFilter?.length,
    variables: {
      start_date: dateFilter[0],
      end_date: dateFilter[1],
    },
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  useEffect(() => {
    const _data = {};

    if (data?.vw_admin_dof_drivers?.length) {
      const filteredDriversByCity = data?.vw_admin_dof_drivers.filter((d) =>
        cityFilter !== -1
          ? d.dof_requested_city?.toLowerCase() === cityFilter
          : true
      );

      _data.isGeneralInfoSubmittedDrivers =
        filteredDriversByCity.filter((d) => d.is_general_info_submitted) || [];
      _data.isVideoVerificationSubmittedDrivers =
        filteredDriversByCity.filter(
          (d) => d.is_video_verification_submitted
        ) || [];
      _data.isDriverLicenseVerifiedDrivers =
        filteredDriversByCity.filter((d) => d.is_driver_license_verified) || [];
      _data.isIdVerifiedDrivers =
        filteredDriversByCity.filter((d) => d.is_id_verified) || [];
      _data.loggedInAcademyAtleastOnceDrivers =
        filteredDriversByCity.filter(
          (d) => d.logged_in_academy_at_least_once
        ) || [];
      _data.isAcademyCompletedDrivers =
        filteredDriversByCity.filter((d) => d.is_academy_completed) || [];
      _data.isDofApprovedDrivers =
        filteredDriversByCity.filter((d) => d.dof_approved) || [];
      _data.completedAtleastOneReservationDrivers =
        filteredDriversByCity.filter((d) => d.reservation_count > 0) || [];

      setDofData(_data);
    } else {
      setDofData([]);
    }
  }, [data, cityFilter]);

  return (
    <Box mt={3}>
      <DateFilter
        defaultPreset={datePresetValue}
        rangeChange={(v) => setDateFilter(v)}
      />
      <Box mt={3}>
        <Box mb={2}>
          <FormControl>
            <InputLabel id="city-label">
              <Box m={1}>City</Box>
            </InputLabel>
            <Select
              placeholder="City"
              labelId="city-label"
              defaultValue={cityFilter}
              variant="filled"
              onChange={(e) => setCityFilter(e.target.value)}
            >
              <MenuItem value={-1}>All cities</MenuItem>
              {SUPPORTED_CITIES.map((c) => {
                return (
                  <MenuItem key={c.name} value={c.name}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            color="white"
            textColor="primary"
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="General DOF" />
          </Tabs>
        </Paper>
      </Box>
      <TabPanel value={value} index={0}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Paper>
                <Box p={1}>
                  <b>Submitted general info</b>
                  <Typography variant="h6">
                    {dofData?.isGeneralInfoSubmittedDrivers?.length}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={3}>
              <Paper>
                <Box p={1}>
                  <b>Submitted video verification</b>
                  <Typography variant="h6">
                    {dofData?.isVideoVerificationSubmittedDrivers?.length}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={3}>
              <Paper>
                <Box p={1}>
                  <b>ID Documents Verified</b>
                  <Typography variant="h6">
                    {dofData?.isIdVerifiedDrivers?.length}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={3}>
              <Paper>
                <Box p={1}>
                  <b>Driver license verified</b>
                  <Typography variant="h6">
                    {dofData?.isDriverLicenseVerifiedDrivers?.length}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={3}>
              <Paper>
                <Box p={1}>
                  <b>Logged in SIC</b>
                  <Typography variant="h6">
                    {dofData?.loggedInAcademyAtleastOnceDrivers?.length}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={3}>
              <Paper>
                <Box p={1}>
                  <b>Academy completed</b>
                  <Typography variant="h6">
                    {dofData?.isAcademyCompletedDrivers?.length}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={3}>
              <Paper>
                <Box p={1}>
                  <b>DOF Approved</b>
                  <Typography variant="h6">
                    {dofData?.isDofApprovedDrivers?.length}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={3}>
              <Paper>
                <Box p={1}>
                  <b>Accepted reservation</b>
                  <Typography variant="h6">
                    {dofData?.completedAtleastOneReservationDrivers?.length}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <Box mt={3}>
        <h6>Conversion table</h6>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>
                <b>Step</b>
              </TableCell>
              <TableCell>
                <b>Count</b>
              </TableCell>
              <TableCell>
                <b>Conversion rate</b>
              </TableCell>
            </TableHead>
            <TableRow>
              <TableCell>Submitted general info</TableCell>
              <TableCell>
                {dofData?.isGeneralInfoSubmittedDrivers?.length}
              </TableCell>
              <TableCell>
                {dofData?.isGeneralInfoSubmittedDrivers?.length
                  ? (100).toFixed(2)
                  : (0).toFixed(2)}{' '}
                %
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Submitted video verification</TableCell>
              <TableCell>
                {dofData?.isVideoVerificationSubmittedDrivers?.length}
              </TableCell>
              <TableCell>
                {(
                  (dofData?.isVideoVerificationSubmittedDrivers?.length /
                    dofData?.isGeneralInfoSubmittedDrivers?.length) *
                    100 || 0
                ).toFixed(2)}{' '}
                %
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID Documents Verified</TableCell>
              <TableCell>{dofData?.isIdVerifiedDrivers?.length}</TableCell>
              <TableCell>
                {(
                  (dofData?.isIdVerifiedDrivers?.length /
                    dofData?.isGeneralInfoSubmittedDrivers?.length) *
                    100 || 0
                ).toFixed(2)}{' '}
                %
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Driver license verified</TableCell>
              <TableCell>
                {dofData?.isDriverLicenseVerifiedDrivers?.length}
              </TableCell>
              <TableCell>
                {(
                  (dofData?.isDriverLicenseVerifiedDrivers?.length /
                    dofData?.isGeneralInfoSubmittedDrivers?.length) *
                    100 || 0
                ).toFixed(2)}{' '}
                %
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Logged in SIC</TableCell>
              <TableCell>
                {dofData?.loggedInAcademyAtleastOnceDrivers?.length}
              </TableCell>
              <TableCell>
                {(
                  (dofData?.loggedInAcademyAtleastOnceDrivers?.length /
                    dofData?.isGeneralInfoSubmittedDrivers?.length) *
                    100 || 0
                ).toFixed(2)}{' '}
                %
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Academy completed</TableCell>
              <TableCell>
                {dofData?.isAcademyCompletedDrivers?.length}
              </TableCell>
              <TableCell>
                {(
                  (dofData?.isAcademyCompletedDrivers?.length /
                    dofData?.isGeneralInfoSubmittedDrivers?.length) *
                    100 || 0
                ).toFixed(2)}{' '}
                %
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DOF Approved</TableCell>
              <TableCell>{dofData?.isDofApprovedDrivers?.length}</TableCell>
              <TableCell>
                {(
                  (dofData?.isDofApprovedDrivers?.length /
                    dofData?.isGeneralInfoSubmittedDrivers?.length) *
                    100 || 0
                ).toFixed(2)}{' '}
                %
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Accepted atleast one reservation</TableCell>
              <TableCell>
                {dofData?.completedAtleastOneReservationDrivers?.length}
              </TableCell>
              <TableCell>
                {(
                  (dofData?.completedAtleastOneReservationDrivers?.length /
                    dofData?.isGeneralInfoSubmittedDrivers?.length) *
                    100 || 0
                ).toFixed(2)}{' '}
                %
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DOFAnalytics;
