import React, { useEffect, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { useDataProvider, useMutation, useNotify, useRefresh } from 'ra-core';
import { Driver_Status_Enum } from '@ugo/graphql-operations';
import { create } from 'react-modal-promise';
import { DOFRejectDriver } from '../shared/DOFRejectDriver';
import TypeformPayloadRender from '../shared/TypeformPayloadRender';
import { FirebaseApp } from '../../shared/vendor/firebase';
import axios from 'axios';

export const STRIPE_DASHBOARD_DOCUMENT_VERIFICATION_SESSION_BASE_URI = `https://dashboard.stripe.com/${
  process.env.NODE_ENV !== 'production' ? 'test/' : ''
}identity/verification-sessions`;

export const STRIPE_DASHBOARD_ACCOUNTS_BASE_URI = `https://dashboard.stripe.com/${
  process.env.NODE_ENV !== 'production' ? 'test/' : ''
}connect/accounts`;

const DriverOnboardingFlowData = ({ account }: any) => {
  const notify = useNotify();
  const [updateMutation] = useMutation();
  const refresh = useRefresh();
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [driverDocumentPhotos, setDriverDocumentPhotos] = useState<{
    id_front: Blob;
    id_back: Blob;
    driver_license_front: Blob;
    driver_license_back: Blob;
  } | null>(null);

  const ID_DOCUMENT_VERIFICATION_SESSION_URL = account.driver_profile
    ?.document_verification_id_session_id
    ? `${STRIPE_DASHBOARD_DOCUMENT_VERIFICATION_SESSION_BASE_URI}/${account.driver_profile.document_verification_id_session_id}`
    : null;

  const DRIVER_LICENSE_DOCUMENT_VERIFICATION_SESSION_URL = account
    .driver_profile?.document_verification_driver_license_session_id
    ? `${STRIPE_DASHBOARD_DOCUMENT_VERIFICATION_SESSION_BASE_URI}/${account.driver_profile.document_verification_driver_license_session_id}`
    : null;

  /**
   * Used to update either the *_approved or *_force_verified fields on the account
   */
  const updateApprovals = async (event: any, field: string) => {
    const value = event.target.checked;

    let timestampField;

    switch (field) {
      case 'is_document_verification_id_approved': {
        timestampField = 'document_verification_id_approved_at';
        break;
      }
      case 'is_document_verification_driver_license_approved': {
        timestampField = 'document_verification_driver_license_approved_at';
        break;
      }
      case 'is_video_verification_approved': {
        timestampField = 'video_verification_approved_at';
        break;
      }
      case 'document_verification_id_force_verified': {
        timestampField = 'document_verification_id_force_verified_at';
        break;
      }
      case 'document_verification_driver_license_force_verified': {
        timestampField =
          'document_verification_driver_license_force_verified_at';
        break;
      }
      case 'is_typeform_documents_approved': {
        timestampField = 'typeform_documents_approved_at';
        break;
      }
    }

    const data = { [field]: value };

    if (timestampField) {
      data[timestampField] = value ? new Date() : null;
    }

    try {
      await updateMutation({
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data,
        },
      });

      setTimeout(() => {
        refresh();
      }, 500);

      notify('Successfully updated', 'success');
    } catch (e) {
      console.log(e);
      notify('Error updating', 'error');
    }
  };

  const unsubmitCovidPassport = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: {
            is_covid_passport_submitted: false,
            is_covid_passport_approved: false,
          },
        },
      },
      { returnPromise: true }
    );

    notify('Covid passport upload requested again.', 'success');
  };

  const resendVideoaskEmail = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { is_general_info_submitted: false },
        },
      },
      { returnPromise: true }
    );
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { is_general_info_submitted: true },
        },
      },
      { returnPromise: true }
    );
    notify('Videoask email sent.', 'success');
  };

  const resendTypeformDocumentsEmail = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { is_academy_completed: false },
        },
      },
      { returnPromise: true }
    );
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { is_academy_completed: true },
        },
      },
      { returnPromise: true }
    );
    notify('Typeform documents email sent.', 'success');
  };

  const resendStripeIdVerificationEmail = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { is_video_verification_submitted: false },
        },
      },
      { returnPromise: true }
    );
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { is_video_verification_submitted: true },
        },
      },
      { returnPromise: true }
    );
    notify('Stripe ID verification email sent', 'success');
  };

  const resendStripeDriversLicenseVerificationEmail = async () => {
    const currentDocumentVerificationIdStatus =
      account.driver_profile.document_verification_id_status;

    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { document_verification_id_status: 'created' },
        },
      },
      { returnPromise: true }
    );
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: {
            document_verification_id_status:
              currentDocumentVerificationIdStatus,
          },
        },
      },
      { returnPromise: true }
    );
    notify('Stripe driver license verification email sent', 'success');
  };

  const resendLoginEmail = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { status: null },
        },
      },
      { returnPromise: true }
    );
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { status: Driver_Status_Enum.Approved },
        },
      },
      { returnPromise: true }
    );
    notify('Login email sent.', 'success');
  };

  const sendAcademyReminderEmail = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: { is_academy_email_reminder_sent: false },
        },
      },
      { returnPromise: true }
    );
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: {
            is_academy_email_reminder_sent: true,
          },
        },
      },
      { returnPromise: true }
    );
    notify('Academy email sent.', 'success');
  };

  const approveDriver = async () => {
    await updateMutation(
      {
        type: 'update',
        resource: 'driver_profile',
        payload: {
          id: account.driver_profile.id,
          data: {
            status: Driver_Status_Enum.Approved,
            dof_approved_at: new Date(),
          },
        },
      },
      { returnPromise: true }
    );

    notify(
      'Driver successfully approved. Please modify their cities and services.',
      'success'
    );
  };

  const handleRejectDriver = async ({ rejectedReason }: any) => {
    setRejectModalOpen(false);
    try {
      await updateMutation(
        {
          type: 'update',
          resource: 'driver_profile',
          payload: {
            id: account.driver_profile.id,
            data: {
              status: Driver_Status_Enum.DofRejected,
              dof_rejected_reason: rejectedReason,
              dof_rejected_at: new Date(),
            },
          },
        },
        { returnPromise: true }
      );
      notify(
        'Driver successfully rejected. They will be notified via email.',
        'success'
      );
    } catch (e) {
      notify('Error rejecting user. Try again later.', 'error');
    }
  };

  const areDocumentsVerified = (): boolean => {
    return (
      ((account.driver_profile.document_verification_id_status === 'verified' ||
        account.driver_profile.document_verification_id_force_verified) &&
        (account.driver_profile.document_verification_driver_license_status ===
          'verified' ||
          account.driver_profile
            .document_verification_driver_license_force_verified)) ||
      account.driver_profile.is_typeform_documents_submitted
    );
  };

  useEffect(() => {
    const fetchDriverDocumentPhotos = async () => {
      const userToken = await FirebaseApp().auth().currentUser?.getIdToken();
      if (account.driver_profile.is_typeform_documents_submitted) {
        const headers = {
          firebase_auth_token: userToken,
          'Content-Type': 'application/json',
        };
        const [
          idFrontResponse,
          idBackResponse,
          driverLicenseFrontResponse,
          driverLicenseBackResponse,
        ] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_APP_URL}/api/files/${account.driver_profile.id_document_front_object_path}`,
            {
              method: 'GET',
              headers,
              responseType: 'blob',
            }
          ),
          axios.get(
            `${process.env.REACT_APP_APP_URL}/api/files/${account.driver_profile.id_document_back_object_path}`,
            {
              method: 'GET',
              headers,
              responseType: 'blob',
            }
          ),
          axios.get(
            `${process.env.REACT_APP_APP_URL}/api/files/${account.driver_profile.driver_license_front_object_path}`,
            {
              method: 'GET',
              headers,
              responseType: 'blob',
            }
          ),
          axios.get(
            `${process.env.REACT_APP_APP_URL}/api/files/${account.driver_profile.driver_license_back_object_path}`,
            {
              method: 'GET',
              headers,
              responseType: 'blob',
            }
          ),
        ]);

        setDriverDocumentPhotos({
          id_front: idFrontResponse.data,
          id_back: idBackResponse.data,
          driver_license_front: driverLicenseFrontResponse.data,
          driver_license_back: driverLicenseBackResponse.data,
        });
      }
    };

    fetchDriverDocumentPhotos();
  }, []);

  return (
    <Box position="relative">
      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>1. General Info (Typeform)</span>
          &nbsp;&nbsp;
          {account.driver_profile.is_general_info_submitted && (
            <CheckIcon style={{ color: 'green' }} />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box mb={4}>
              General info submitted:&nbsp;&nbsp;
              <CheckIcon style={{ color: 'green' }} />
            </Box>
            {account?.driver_profile?.general_info_payload && (
              <Box>
                {Object.keys(account?.driver_profile?.general_info_payload).map(
                  (k) => {
                    return (
                      <Box key={k} mb={3}>
                        <Box>
                          <span style={{ fontWeight: 'bold' }}>
                            {k.replace('*', '')}
                          </span>
                        </Box>
                        <Box>
                          {typeof account.driver_profile.general_info_payload[
                            k
                          ] !== 'boolean'
                            ? account.driver_profile.general_info_payload[k]
                            : account.driver_profile.general_info_payload[k]
                            ? 'Si'
                            : 'No'}
                        </Box>
                      </Box>
                    );
                  }
                )}
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>
            2. Video verification (Videoask)
          </span>
          {account.driver_profile.is_video_verification_submitted &&
            account.driver_profile.is_video_verification_approved && (
              <CheckIcon style={{ color: 'green' }} />
            )}

          {account.driver_profile.is_video_verification_submitted &&
            !account.driver_profile.is_video_verification_approved && (
              <>
                <span>&nbsp;|&nbsp; Submitted: </span>
                <CheckIcon
                  style={{ color: 'green' }}
                /> &nbsp;|&nbsp;Approved: <ClearIcon style={{ color: 'red' }} />
              </>
            )}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box>
              <Box mb={4}>
                Video response approved:{' '}
                {/*<ReferenceInput reference="driver_profile" source="account_id">*/}
                <input
                  type="checkbox"
                  defaultChecked={
                    account.driver_profile.is_video_verification_approved
                  }
                  onChange={(e) =>
                    updateApprovals(e, 'is_video_verification_approved')
                  }
                />
                <div>
                  <small>
                    (once HR reviews the video response, they should approve by
                    ticking the checkbox)
                  </small>
                </div>
                {/*</ReferenceInput>*/}
              </Box>
              <Box>
                <a
                  href={`https://app.videoask.com/app/organizations/b7f6e547-e55a-4801-8001-6dfb3f85bd63/form?contactId=${account.driver_profile.videoask_contact_id}&formId=${account.driver_profile.videoask_form_id}&mode=contacts`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Direct Videoask Response
                </a>
              </Box>
              <Box my={1}>
                {
                  "If the above link doesn't work, use one of the following links:"
                }
              </Box>
              <Box mb={1}>
                <a
                  href="https://app.videoask.com/app/organizations/b7f6e547-e55a-4801-8001-6dfb3f85bd63/form?contactId=d0f38dd4-5d91-4d54-ab6a-451a7c26a3d5&formId=7dea71e4-f84a-4aaf-a005-b275a009da52&mode=contacts"
                  target="_blank"
                  rel="noreferrer"
                >
                  General Videoask Responses
                </a>
              </Box>
              <Box mb={1}>
                <a
                  href={account.driver_profile.video_verification_uri}
                  target="_blank"
                  rel="noreferrer"
                >
                  General Videoask Responses 2
                </a>
              </Box>
              {account.driver_profile.is_general_info_submitted && (
                <Box mt={4}>
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    onClick={resendVideoaskEmail}
                  >
                    Resend Videoask email
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>
            3. Academy (Skillsincloud){' '}
            {account.driver_profile.is_academy_completed && (
              <CheckIcon style={{ color: 'green' }} />
            )}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            Academy completed:{' '}
            <input
              type="checkbox"
              defaultChecked={account.driver_profile.is_academy_completed}
              onChange={(e) => updateApprovals(e, 'is_academy_completed')}
            />
            <Box mt={4}>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={sendAcademyReminderEmail}
              >
                Send academy reminder email
              </Button>
              <br />
              <small>
                This is a different email than the original one. Includes
                reminder-style content.
              </small>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>
            4. Document verifications (Typeform)
          </span>
          {account.driver_profile.is_typeform_documents_submitted &&
            account.driver_profile.is_typeform_documents_approved && (
              <CheckIcon style={{ color: 'green' }} />
            )}

          {account.driver_profile.is_typeform_documents_submitted &&
            !account.driver_profile.is_typeform_documents_approved && (
              <>
                <span>&nbsp;|&nbsp; Submitted: </span>
                <CheckIcon
                  style={{ color: 'green' }}
                /> &nbsp;|&nbsp;Approved: <ClearIcon style={{ color: 'red' }} />
              </>
            )}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box mb={4}>
              Typeform documents approved:{' '}
              {/*<ReferenceInput reference="driver_profile" source="account_id">*/}
              <input
                type="checkbox"
                defaultChecked={
                  account.driver_profile.is_typeform_documents_approved
                }
                onChange={(e) =>
                  updateApprovals(e, 'is_typeform_documents_approved')
                }
              />
              <div>
                <small>
                  (once HR reviews the video response, they should approve by
                  ticking the checkbox)
                </small>
              </div>
              {/*</ReferenceInput>*/}
            </Box>
            <Box mb={4}>
              <Box>
                <h4>Document Details</h4>
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ fontSize: '14px' }}
                    >
                      ID Document Number
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {account.driver_profile.id_document_number || 'N/A'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ fontSize: '14px' }}
                    >
                      ID Document State of Issue
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {account.driver_profile.id_document_state_of_issue ||
                        'N/A'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" color="textSecondary">
                      ID Document Expiration Date
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {account.driver_profile.id_document_expiration_date
                        ? new Date(
                            account.driver_profile.id_document_expiration_date
                          ).toLocaleDateString()
                        : 'N/A'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" color="textSecondary">
                      Drivers License Expiration Date
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {account.driver_profile.driver_license_expiration_date
                        ? new Date(
                            account.driver_profile.driver_license_expiration_date
                          ).toLocaleDateString()
                        : 'N/A'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {driverDocumentPhotos && (
              <TypeformPayloadRender payload={driverDocumentPhotos} />
            )}
            <Box mt={4}>
              <Button
                variant="outlined"
                type="button"
                color="primary"
                onClick={resendTypeformDocumentsEmail}
              >
                Resend Typeform Documents Email
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>
            3.1. [OLD] ID Document verification (Stripe) -{' '}
            {account.driver_profile.document_verification_id_force_verified
              ? 'verified'
              : account.driver_profile.document_verification_id_status}
          </span>
          {(account.driver_profile.document_verification_id_status ===
            'verified' ||
            account.driver_profile.document_verification_id_force_verified) &&
            account.driver_profile.is_document_verification_id_approved && (
              <CheckIcon style={{ color: 'green' }} />
            )}

          {(account.driver_profile.document_verification_id_status ===
            'verified' ||
            account.driver_profile.document_verification_id_force_verified) &&
            !account.driver_profile.is_document_verification_id_approved && (
              <>
                <span>&nbsp;|&nbsp; Verified: </span>
                <CheckIcon
                  style={{ color: 'green' }}
                /> &nbsp;|&nbsp;Approved: <ClearIcon style={{ color: 'red' }} />
              </>
            )}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box mb={4}>
              Force ID document verification
              <input
                type="checkbox"
                defaultChecked={
                  account.driver_profile.document_verification_id_force_verified
                }
                disabled={
                  account.driver_profile
                    .document_verification_id_force_verified ||
                  account.driver_profile.document_verification_id_status ===
                    'verified'
                }
                onChange={(e) =>
                  updateApprovals(e, 'document_verification_id_force_verified')
                }
              />
              <div>
                <small>
                  (Stripe verification can be forced by toggling this setting.
                  NOTE: This will trigger the continuation of the DOF. Only
                  proceed if you know what you are doing.)
                </small>
              </div>
            </Box>
            <Box mb={4}>
              ID document verification approved:{' '}
              <input
                type="checkbox"
                defaultChecked={
                  account.driver_profile.is_document_verification_id_approved
                }
                onChange={(e) =>
                  updateApprovals(e, 'is_document_verification_id_approved')
                }
              />
              <div>
                <small>
                  (once HR reviews the documents, they should approve by ticking
                  the checkbox)
                </small>
              </div>
            </Box>
            <Box>
              {ID_DOCUMENT_VERIFICATION_SESSION_URL && (
                <Box mb={4}>
                  <a
                    href={ID_DOCUMENT_VERIFICATION_SESSION_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    STRIPE: ID Document Verification Session
                  </a>
                </Box>
              )}
              <Box mb={4}>
                Status:{' '}
                <b>{account.driver_profile.document_verification_id_status}</b>
              </Box>
              {account.driver_profile.document_verification_id_snapshot && (
                <Box>
                  <Box>Snapshot:</Box>
                  <Box>
                    <pre>
                      {JSON.stringify(
                        account.driver_profile
                          .document_verification_id_snapshot,
                        null,
                        2
                      )}
                    </pre>
                  </Box>
                </Box>
              )}
            </Box>
            {account.driver_profile.document_verification_id_status !==
            'verified' ? (
              <Box mt={4}>
                <Button
                  variant="outlined"
                  type="button"
                  color="primary"
                  onClick={resendStripeIdVerificationEmail}
                >
                  Resend ID verification email
                </Button>
              </Box>
            ) : (
              <Box mt={4}>
                Email can not be resent because the verification is already done
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>
            3.2. [OLD] Driver license document verification (Stripe) -{' '}
            {account.driver_profile
              .document_verification_driver_license_force_verified
              ? 'verified'
              : account.driver_profile
                  .document_verification_driver_license_status}
          </span>
          {(account.driver_profile
            .document_verification_driver_license_status === 'verified' ||
            account.driver_profile
              .document_verification_driver_license_force_verified) &&
            account.driver_profile
              .is_document_verification_driver_license_approved && (
              <CheckIcon style={{ color: 'green' }} />
            )}

          {(account.driver_profile
            .document_verification_driver_license_status === 'verified' ||
            account.driver_profile
              .document_verification_driver_license_force_verified) &&
            !account.driver_profile
              .is_document_verification_driver_license_approved && (
              <>
                <span>&nbsp;|&nbsp; Verified: </span>
                <CheckIcon
                  style={{ color: 'green' }}
                /> &nbsp;|&nbsp;Approved: <ClearIcon style={{ color: 'red' }} />
              </>
            )}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box mb={4}>
              Force driver license document verification
              <input
                type="checkbox"
                defaultChecked={
                  account.driver_profile
                    .document_verification_driver_license_force_verified
                }
                disabled={
                  account.driver_profile
                    .document_verification_driver_license_force_verified ||
                  account.driver_profile
                    .document_verification_driver_license_status === 'verified'
                }
                onChange={(e) =>
                  updateApprovals(
                    e,
                    'document_verification_driver_license_force_verified'
                  )
                }
              />
              <div>
                <small>
                  (Stripe verification can be forced by toggling this setting.
                  NOTE: This will trigger the continuation of the DOF. Only
                  proceed if you know what you are doing.)
                </small>
              </div>
            </Box>
            <Box mb={4}>
              Driver license document verification approved:{' '}
              <input
                type="checkbox"
                defaultChecked={
                  account.driver_profile
                    .is_document_verification_driver_license_approved
                }
                onChange={(e) =>
                  updateApprovals(
                    e,
                    'is_document_verification_driver_license_approved'
                  )
                }
              />
              <div>
                <small>
                  (once HR reviews the documents, they should approve by ticking
                  the checkbox)
                </small>
              </div>
            </Box>
            <Box>
              {DRIVER_LICENSE_DOCUMENT_VERIFICATION_SESSION_URL && (
                <Box mb={4}>
                  <a
                    href={DRIVER_LICENSE_DOCUMENT_VERIFICATION_SESSION_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    STRIPE: Document Verification Session
                  </a>
                </Box>
              )}
              <Box mb={4}>
                Status:{' '}
                <b>
                  {
                    account.driver_profile
                      .document_verification_driver_license_status
                  }
                </b>
              </Box>
              {account.driver_profile
                .document_verification_driver_license_snapshot && (
                <Box>
                  <Box>Snapshot:</Box>
                  <Box>
                    <pre>
                      {JSON.stringify(
                        account.driver_profile
                          .document_verification_driver_license_snapshot,
                        null,
                        2
                      )}
                    </pre>
                  </Box>
                </Box>
              )}
            </Box>
            {account.driver_profile
              .document_verification_driver_license_status !== 'verified' ? (
              <Box mt={4}>
                <Button
                  variant="outlined"
                  type="button"
                  color="primary"
                  onClick={resendStripeDriversLicenseVerificationEmail}
                >
                  Resend Drivers License verification email
                </Button>
              </Box>
            ) : (
              <Box mt={4}>
                Email can not be resent because the verification is already done
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>
          <span style={{ fontWeight: 'bold' }}>
            5. Covid passport (not required)
            {account.driver_profile.is_covid_passport_submitted &&
              account.driver_profile.is_covid_passport_approved && (
                <CheckIcon style={{ color: 'green' }} />
              )}
            {account.driver_profile.is_covid_passport_submitted &&
              !account.driver_profile.is_covid_passport_approved && (
                <>
                  <span>&nbsp;|&nbsp; Submitted: </span>
                  <CheckIcon style={{ color: 'green' }} />{' '}
                  &nbsp;|&nbsp;Approved: <ClearIcon style={{ color: 'red' }} />
                </>
              )}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box>
              <small>
                User sees a banner in the dashboard, requesting a Covid passport
                upload. User can only upload the banner once they have completed
                onboarding.
              </small>
            </Box>
            <br />
            <Box>
              Covid passport submitted:{' '}
              {account.driver_profile.is_covid_passport_submitted
                ? 'Yes'
                : 'No'}
            </Box>
            <Box>
              Covid passport approved:{' '}
              <input
                type="checkbox"
                defaultChecked={
                  account.driver_profile.is_covid_passport_approved
                }
                onChange={(e) =>
                  updateApprovals(e, 'is_covid_passport_approved')
                }
              />
            </Box>
            <br />
            <Box>
              {account.driver_profile.is_covid_passport_submitted &&
                !account.driver_profile.is_covid_passport_approved && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={unsubmitCovidPassport}
                    >
                      Request another upload
                    </Button>
                    <br />
                    <small>
                      Requesting another upload will show the Covid passport
                      banner again to the user.
                    </small>
                  </>
                )}
            </Box>
            {account.driver_profile.covid_passport_payload && (
              <Box>
                <Box mb={2}>
                  <Box>Answers:</Box>
                  <Box>
                    <pre style={{ width: '800px', overflow: 'scroll' }}>
                      {JSON.stringify(
                        account.driver_profile.covid_passport_payload,
                        null,
                        2
                      )}
                    </pre>
                  </Box>
                </Box>
                <Box>
                  <h5>Covid passport upload:</h5>
                  <img
                    style={{ width: '800px' }}
                    src={
                      account.driver_profile.covid_passport_payload[
                        'Caricare *green pass * valido'
                      ]
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box
        py={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          Driver approval status: <b>{account.driver_profile.status}</b>
          <br />
          {(!account.driver_profile.is_video_verification_approved ||
            !account.driver_profile.is_document_verification_id_approved ||
            !account.driver_profile
              .is_document_verification_driver_license_approved ||
            !account.driver_profile.is_academy_completed) && (
            <small>
              In order to approve driver, please approve{' '}
              <b>
                Video, Document (id and driver license) verification and
                <br /> Academy completion.
              </b>
            </small>
          )}
        </Box>
        {account.driver_profile.status !== Driver_Status_Enum.Approved ? (
          <Box>
            <Button
              disabled={
                ((!account.driver_profile.is_video_verification_approved ||
                  !account.driver_profile
                    .is_document_verification_id_approved ||
                  !account.driver_profile
                    .is_document_verification_driver_license_approved) &&
                  !account.driver_profile.is_typeform_documents_approved) ||
                !account.driver_profile.is_academy_completed
              }
              type="button"
              variant="outlined"
              color="primary"
              onClick={approveDriver}
            >
              Approve driver
            </Button>
          </Box>
        ) : (
          <Button size="small" onClick={resendLoginEmail}>
            Resend driver approval email
          </Button>
        )}
      </Box>
      {account.driver_profile.status === Driver_Status_Enum.Pending && (
        <Box display="flex" justifyContent="between">
          <Box pr={4}>
            Driver can be rejected at any time during the application process
            before approval.
          </Box>
          <Box flexShrink={0}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => setRejectModalOpen(true)}
            >
              Reject driver
            </Button>
          </Box>
          <DOFRejectDriver
            isOpen={rejectModalOpen}
            onResolve={handleRejectDriver}
            onReject={() => setRejectModalOpen(false)}
          />
        </Box>
      )}
    </Box>
  );
};

export default DriverOnboardingFlowData;
