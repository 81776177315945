import {
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  TextField,
} from 'react-admin';
import * as React from 'react';

export const PartnerList = (props: any) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      // filters={<ReservationListFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      // actions={<ReservationActions />}
    >
      <Datagrid>
        <TextField source="id" />
        <DateField source="created_at" showTime />
        <TextField source="name" />
        <TextField source="enabled" />
        <FunctionField
          label="View"
          render={(r: any) => <Link to={`/partner/${r.id}`}>VIEW</Link>}
        />
      </Datagrid>
    </List>
  );
};
