const DATA_SCHEME = process.env.DATA_SCHEME || 'http';
const WS_SCHEME = DATA_SCHEME === 'https' ? 'wss' : 'ws';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

export const Config = {
  httpDataHost: `https://${process.env.REACT_APP_API_HOST}`,
  // wsDataHost: `${WS_SCHEME}:${process.env.REACT_APP_API_HOST}`,
  wsDataHost: `wss:${process.env.REACT_APP_API_HOST}`,
  hasuraClientName: 'web',
  version: process.env.VERSION || '1.0.0',
  debug: true,
  firebase: {
    ...firebaseConfig,
  },
};
