import { getDistanceCost, getHourlyIntervalTimeCost } from '../utils';
import { CalculationData, CalculationStrategy } from './calculation-strategy';

export class CaregivingViaTelemedicineStrategy implements CalculationStrategy {
  calculate(data: CalculationData) {
    const TELEMEDICINE_PRICE_PER_HOUR = 15;

    if (!data?.travel) {
      throw new Error('Travel data is required');
    }

    if (
      !data?.caregivingDuration &&
      typeof data.caregivingDuration !== 'number'
    ) {
      throw new Error('Caregiving duration is required');
    }

    const driverDistance = data.travel.getDriverDistance();
    const timeCost = getHourlyIntervalTimeCost(
      data.caregivingDuration,
      TELEMEDICINE_PRICE_PER_HOUR
    );

    const distanceCost = getDistanceCost(driverDistance, 0);

    return {
      timeCost,
      distanceCost,
      driverDistance,
      caregivingDuration: data.caregivingDuration,
      totalCost: timeCost + distanceCost,
    };
  }
}
