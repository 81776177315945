import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ResourceComponentProps,
  TextField,
  TextInput,
  Filter,
  SelectInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  DateInput,
} from 'react-admin';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { useQuery } from 'ra-core';
import { NumberInput } from 'ra-ui-materialui';
import {
  Carvoila_District,
  Carvoila_Driver,
  Carvoila_Driver_Status_Enum,
} from '@ugo/graphql-operations';

import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import { GLOBAL_EXPORTER_SETTINGS } from '@ugo/data';

export const carvoilaDriverExporter = async (
  carvoilaDrivers: Carvoila_Driver[]
) => {
  const csv = convertToCSV(
    {
      data: carvoilaDrivers,
      fields: [
        'created_at',
        'districts',
        'email',
        'first_name',
        'fiscal_code',
        'id',
        'is_gforms_academy_completed',
        'is_imported',
        'is_registered_on_dev4side',
        'is_typeform_documents_approved',
        'is_typeform_documents_submitted',
        'is_typeform_general_info_submitted',
        'is_video_verification_approved',
        'is_video_verification_submitted',
        'last_name',
        'phone_number',
        'status',
        'vat_number',
        'account_holder_name',
        'iban',
        'note',
        'license_expiry',
        'city',
        'zip_code',
        'address',
        `residence_permit_expiry`,
        `residence_permit_number`,
        'citizenship',
        'tax_regime',
      ],
    },
    { delimiter: ';' }
  );
  downloadCSV(csv, 'carvoila_drivers_' + Date.now());
};

export const carvoilaDriverStatuses = [
  {
    id: Carvoila_Driver_Status_Enum.Approved,
    name: Carvoila_Driver_Status_Enum.Approved,
  },
  {
    id: Carvoila_Driver_Status_Enum.Disabled,
    name: Carvoila_Driver_Status_Enum.Disabled,
  },
  {
    id: Carvoila_Driver_Status_Enum.DofRejected,
    name: Carvoila_Driver_Status_Enum.DofRejected,
  },
  {
    id: Carvoila_Driver_Status_Enum.Pending,
    name: Carvoila_Driver_Status_Enum.Pending,
  },
];

export const CarvoilaDriverFilter = (props: {
  districts: [Carvoila_District];
}) => {
  return (
    <div>
      <Filter {...props}>
        <TextInput source="email@_ilike" label="Email" alwaysOn />
        <SelectInput
          source="districts@_has_key"
          label="City"
          choices={props.districts?.map((d: any) => ({
            name: d.name,
            id: d.name,
          }))}
          alwaysOn
        />
        <SelectInput
          source="status@_eq"
          label="Status"
          choices={carvoilaDriverStatuses}
        />
        <DateInput source="created_at@_gte" label="Created from" />
        <DateInput source="created_at@_lte" label="Created to" />
        <TextInput source="first_name@_ilike" label="First name" />
        <TextInput source="last_name@_ilike" label="Last name" />
        <NumberInput source="id@_eq" label="ID" />
        <NumberInput source="dev4side_id@_eq" label="Dev4Side ID" />
      </Filter>
    </div>
  );
};

export const CarvoilaDriverList = (props: ResourceComponentProps) => {
  const {
    data: districts,
    loading,
    error,
  } = useQuery({
    type: 'getList',
    resource: 'carvoila_district',
    payload: {},
  });

  return (
    <List
      exporter={carvoilaDriverExporter}
      bulkActionButtons={false}
      actions={<CarvoilaDriverListActions />}
      {...props}
      filters={<CarvoilaDriverFilter districts={districts} />}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField source="first_name" emptyText="n/a" label="First name" />
        <TextField source="last_name" emptyText="n/a" label="Last name" />
        <DateField source="created_at" />

        <FunctionField
          render={(record: any) => (
            <Link to={`/carvoila_driver/${record.id}`}>VIEW</Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

const CarvoilaDriverListActions = (props: any) => {
  const {
    className,
    total,
    resource,
    currentSort,
    filters,
    showFilter,
    displayedFilters,
    filterValues,
  } = props;
  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};
