import {
  Datagrid,
  FunctionField,
  Link,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import * as React from 'react';

export const ProjectList = (props: any) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      // filters={<ReservationListFilter />}
      // actions={<ReservationActions />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="partner_id" reference="partner">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="View"
          render={(r: any) => <Link to={`/project/${r.id}`}>VIEW</Link>}
        />
      </Datagrid>
    </List>
  );
};
