import * as React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  downloadCSV,
  ExportButton,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import {
  ADMIN_SUPPORTED_CITIES,
  GLOBAL_EXPORTER_SETTINGS,
  reservationData,
} from '@ugo/data';
import { Check, Close } from '@material-ui/icons';
import { Reservation } from '@ugo/graphql-operations';
import { unparse as convertToCSV } from 'papaparse';
import { useDates } from '../../hooks/useDates';
import { useUtilities } from '@ugo/utils';

export const CancelledReservationListFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Id" source="id@_ilike" alwaysOn />
    <TextInput label="Short ID" source="short_id@_ilike" alwaysOn />
    <SelectInput source="status" choices={reservationData} alwaysOn />
    <TextInput label="Patient email" source="reservor_email@_ilike" alwaysOn />
    <TextInput label="Driver email" source="driver_email@_ilike" alwaysOn />

    <SelectInput
      label="City"
      source="service_city"
      choices={ADMIN_SUPPORTED_CITIES.map((c: string) => ({
        id: c,
        name: c.charAt(0).toUpperCase() + c.slice(1),
      }))}
      alwaysOn
    />
    <DateInput source="starts_at@_gte" label="From" />
    <DateInput source="ends_at@_lte" label="To" />
  </Filter>
);

export const CancelledReservationStatusField = ({
  source,
  record = {},
}: any) => (
  <span>{reservationData.find((r) => record[source] === r.id)?.name}</span>
);

export const CancelledReservationList = (props: any) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={<CancelledReservationListFilter />}
      sort={{ field: 'updated_at', order: 'DESC' }}
      actions={<CancelledReservationActions />}
    >
      <Datagrid>
        <TextField source="id" />
        <CancelledReservationStatusField source="status" emptyText="n/a" />
        <TextField source="patient_cancellation_reason" />
        <TextField source="driver_cancellation_reasons" />
        <TextField source="driver_id" />
        <TextField source="starts_at" />
        <DateField source="updated_at" showTime />
        <DateField source="created_at" showTime />
        <TextField source="metadata.service" />
        <TextField
          style={{ textTransform: 'capitalize' }}
          source="metadata.service_city"
          label="City"
          emptyText="n/a"
        />
        <FunctionField
          label="View"
          render={(r: any) => <Link to={`/reservation/${r.id}`}>VIEW</Link>}
        />
      </Datagrid>
    </List>
  );
};

const CancelledReservationActions = (props: any) => {
  const { className, total, resource, currentSort, filters } = props;

  const { showFilter, displayedFilters, filterValues } = useListContext();

  const { formatDateToZonedDatetime, toNiceDate } = useDates();
  const { flattenObjectWithParentKeys } = useUtilities();

  async function reservationExporter(
    reservations: Reservation[],
    fetchRelatedRecords: any
  ) {
    const drivers = await fetchRelatedRecords(
      reservations,
      'driver_id',
      'account'
    );
    const reservors = await fetchRelatedRecords(
      reservations,
      'reservor_id',
      'account'
    );
    const account_feedbacks = await fetchRelatedRecords(
      Object.values(reservors),
      'id',
      'vw_admin_account_feedback'
    );

    const reservationsForExport = reservations.map((reservation) => {
      const {
        starts_at,
        ends_at,
        started_at,
        ended_at,
        updated_at,
        accepted_at,
        created_at,
      } = reservation;

      if (created_at) {
        reservation.created_at = formatDateToZonedDatetime(
          new Date(created_at)
        );
      }
      if (reservation.metadata.note) {
        reservation.metadata.note = `"${reservation.metadata.note}"`;
      }
      if (starts_at) {
        reservation.starts_at = formatDateToZonedDatetime(new Date(starts_at));
      }
      if (accepted_at) {
        reservation.starts_at = formatDateToZonedDatetime(
          new Date(accepted_at)
        );
      }
      if (ends_at) {
        reservation.ends_at = formatDateToZonedDatetime(new Date(ends_at));
      }
      if (started_at) {
        reservation.started_at = formatDateToZonedDatetime(
          new Date(started_at)
        );
      }
      if (ended_at) {
        reservation.ended_at = formatDateToZonedDatetime(new Date(ended_at));
      }
      if (updated_at) {
        reservation.updated_at = formatDateToZonedDatetime(
          new Date(updated_at)
        );
      }

      // @ts-ignore
      const flattenedDriver = flattenObjectWithParentKeys(
        drivers[reservation.driver_id],
        'driver'
      );
      const flattenedReservorMetadata = flattenObjectWithParentKeys(
        reservation.reservor_metadata,
        'reservor_metadata'
      );
      const flattenedReserveeMetadata = flattenObjectWithParentKeys(
        reservation.reservee_metadata,
        'reservee_metadata'
      );
      const flattenedReservationMetadata = flattenObjectWithParentKeys(
        reservation.metadata,
        'metadata'
      );
      // @ts-ignore
      const flattenedVwAdminAccountFeedback = flattenObjectWithParentKeys(
        account_feedbacks[reservation.reservor_id],
        'account_feedback'
      );

      return {
        ...reservation,
        ...flattenedDriver,
        ...flattenedReservorMetadata,
        ...flattenedReserveeMetadata,
        ...flattenedReservationMetadata,
        ...flattenedVwAdminAccountFeedback,
        // @ts-ignore
        data_servizio: toNiceDate(new Date(reservation.starts_at)),
        frequenza: reservations.filter(
          (r) => r.reservor_id === reservation.reservor_id
        ).length,
        // @ts-ignore
        come_ci_ha_conosciuto:
          flattenedVwAdminAccountFeedback.account_feedback_traffic_source,
      };
    });

    const csv = convertToCSV(
      {
        data: reservationsForExport,
        fields: [
          'accepted_at',
          'created_at',
          'currency',
          'driver_id',
          'driver_email',
          'duration_billable',
          'duration_estimate',
          'ends_at',
          'id',
          'is_champion_patient',
          'metadata_date',
          'metadata_note',
          'metadata_time',
          'metadata_service',
          'metadata_service_city',
          'metadata_departure_lat',
          'metadata_departure_lng',
          'metadata_type_of_travel',
          'metadata_destination_lat',
          'metadata_destination_lng',
          'metadata_reservation_for',
          'metadata_travel_distance',
          'metadata_travel_duration',
          'metadata_departure_address',
          'metadata_duration_estimate',
          'metadata_caregiving_duration',
          'metadata_destination_address',
          'metadata_number_of_passengers',
          'preferred_driver',
          'price_billable',
          'price_estimate',
          'reservee_metadata_id',
          'reservee_metadata_email',
          'reservee_metadata_personal_data_age',
          'reservee_metadata_personal_data_sex',
          'reservee_metadata_personal_data_last_name',
          'reservee_metadata_personal_data_birth_date',
          'reservee_metadata_personal_data_first_name',
          'reservee_metadata_personal_data_fiscal_code',
          'reservee_metadata_personal_data_home_address',
          'reservee_metadata_personal_data_phone_number',
          'reservee_metadata_personal_data_license_plate',
          'reservor_id',
          'reservor_metadata_id',
          'reservor_metadata_email',
          'reservor_metadata_personal_data_age',
          'reservor_metadata_personal_data_sex',
          'reservor_metadata_personal_data_last_name',
          'reservor_metadata_personal_data_birth_date',
          'reservor_metadata_personal_data_first_name',
          'reservor_metadata_personal_data_fiscal_code',
          'reservor_metadata_personal_data_home_address',
          'reservor_metadata_personal_data_phone_number',
          'reservor_metadata_personal_data_license_plate',
          'reservor_role',
          'service_id',
          // DATA?
          'status',
          'updated_at',
          'metadata_additional_options',
          'ended_at',
          'started_at',
          'metadata_errand',
          'metadata_time_priority',
          'metadata_license_plate',
          'metadata_phone_number',
          'price_estimate_discounted',
          'voucher_code',
          'voucher_not_applicable_reason',
          'extra_costs_value',
          'metadata_voucher_code',
          //
          'price_billable_discounted',
          'metadata_price_estimate_discounted',
          'frequenza',
          'data_servizio',
          'come_ci_ha_conosciuto',
          'account_feedback_traffic_source',
          'account_feedback_traffic_source_other_text',
          'account_feedback_traffic_source_affiliate_text',
          'patient_cancellation_reason',
          'driver_cancellation_reasons',
          //
          // 'DATA',
          // '',
          // 'Frequenza',
          // 'FREQUENZA ACQUISTO',
          // 'Preferred driver',
          // 'Data servizio',
          // 'STATUS',
          // 'Utente OLD?',
          // 'Quando è entrato in contatto con noi?',
          // 'Come ci ha conosciuto?',
        ],
      },
      { delimiter: ';' }
    );
    downloadCSV(csv, 'reservations_' + Date.now());
  }

  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={reservationExporter}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};

export function renderBooleanIcons(flag: boolean) {
  return flag ? <Check /> : <Close />;
}
