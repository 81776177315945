/**
 * @description Statuses related to manual_* (b2b) flows
 *
 * 1. DRAFT - Customer care creates a manual reservation
 * 2. WAITING - Customer care sends an email
 * 3. ACCEPTED - Driver accepts an email
 * 4. FINISHED - Manual reservation is finished
 * 5. CANCELED - Manual reservation is canceled
 */
export const MANUAL_STATUSES = [
  { id: 'DRAFT', name: 'Draft' },
  { id: 'WAITING', name: 'Waiting' },
  { id: 'ACCEPTED', name: 'Accepted' },
  { id: 'FINISHED', name: 'Finished' },
  { id: 'CANCELED', name: 'Canceled' },
  { id: 'IN_PROGRESS', name: 'In progress' },
  { id: 'FINISH_RESERVATION_OVERDUE', name: 'Overdue' },
];

export enum ManualStatusEnum {
  Draft = 'DRAFT',
  Waiting = 'WAITING',
  Accepted = 'ACCEPTED',
  Finished = 'FINISHED',
  Canceled = 'CANCELED',
  InProgress = 'IN_PROGRESS',
  FinishReservationOverdue = 'FINISH_RESERVATION_OVERDUE',
}
