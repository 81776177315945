import * as React from 'react';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { B2B_PROJECT_NAMES } from '@ugo/data';
import { GLOBAL_EXPORTER_SETTINGS } from '@ugo/data';

const ManualPatientListFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="id" label="ID" alwaysOn />
      <TextInput source="first_name@_ilike" label="First name" alwaysOn />
      <TextInput source="last_name@_ilike" label="Last name" alwaysOn />
      <TextInput source="email@_ilike" label="Email" alwaysOn />
      <TextInput source="phone_number@_ilike" label="Phone" alwaysOn />
      <SelectInput source="project_name" choices={B2B_PROJECT_NAMES} alwaysOn />

      <ReferenceInput
        label="Partner"
        source="partner_id"
        reference="partner"
        perPage={20}
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
        fullWidth
      >
        <AutocompleteInput id="project-select" optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

export const ManualPatientList = (props: ResourceComponentProps) => (
  <List
    {...props}
    filters={<ManualPatientListFilter />}
    actions={<ManualPatientListActions />}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="first_name" emptyText="n/a" label="First name" />
      <TextField source="last_name" emptyText="n/a" label="Last name" />
      <TextField source="phone_number" emptyText="n/a" label="Phone number" />
      <TextField source="partner.name" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton label="View" />
    </Datagrid>
  </List>
);

const ManualPatientListActions = (props: any) => {
  const { className, basePath, total, resource, currentSort, filters } = props;

  const { showFilter, displayedFilters, filterValues, exporter } =
    useListContext();

  return (
    <TopToolbar className={className}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        exporter={exporter || undefined}
        maxResults={GLOBAL_EXPORTER_SETTINGS.MAX_RESULTS}
      />
    </TopToolbar>
  );
};
