import React, { useEffect, useState } from 'react';
import { Button, Edit, TopToolbar, useNotify, useRefresh } from 'react-admin';
import { ManualReservationWithPreferredDriverForm } from './ManualReservationWithPreferredDriverForm';
import { useSendNewManualReservationEmailToPreferredDriverMutation } from '@ugo/graphql-operations';
import { validateManualReservationEdit } from '../manual-reservations/ManualReservationEdit';
import { useUtilities } from '@ugo/utils';

export const ManualReservationWithPreferredDriverEdit = (props: any) => {
  const { removeDeleteButtonFromDom } = useUtilities();

  useEffect(() => {
    removeDeleteButtonFromDom();
  });

  return (
    <>
      <Edit
        {...props}
        actions={<ManualReservationWithPreferredDriverEditActions {...props} />}
        mutationMode="pessimistic"
      >
        <ManualReservationWithPreferredDriverForm
          {...props}
          validate={validateManualReservationEdit}
          submitOnEnter={false}
          isEdit={true}
        />
      </Edit>
    </>
  );
};

const ManualReservationWithPreferredDriverEditActions = (props: any) => {
  const [
    sendNewManualReservationEmailToPreferredDriver,
    { loading: isSendingEmailToPreferredDriver },
  ] = useSendNewManualReservationEmailToPreferredDriverMutation();
  const [canSendEmail, setCanSendEmail] = useState<boolean>(false);

  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    if (props?.data) {
      setCanSendEmail(
        !Object.keys(validateManualReservationEdit(props.data)).length &&
          props?.data?.status === 'DRAFT'
      );
    }
  }, [props?.data]);

  const sendEmailToPreferredDriver = async () => {
    try {
      await sendNewManualReservationEmailToPreferredDriver({
        variables: {
          reservation_id: Number(props.data.id),
        },
      });

      notify('Email to preferred driver sent', { type: 'info' });
      refresh();
    } catch (err) {
      console.log(err);

      notify('Could not send email to preferred driver', { type: 'error' });
      notify(JSON.stringify(err), { type: 'error' });

      throw err;
    }
  };

  return (
    <TopToolbar>
      {props?.data?.status === 'DRAFT' && (
        <>
          {isSendingEmailToPreferredDriver ? (
            'Sending email...'
          ) : (
            <Button
              disabled={!canSendEmail}
              label="Send email to preferred driver"
              color="primary"
              onClick={sendEmailToPreferredDriver}
            />
          )}
        </>
      )}
    </TopToolbar>
  );
};
