import { useState } from 'react';
import {
  formatDateToZonedDatetime,
  fromTimestamptz,
  getClientTimezone,
  getDateFromTimestamptz,
  getDurationInHours,
  getTimeFromTimestamptz,
  now,
  toNiceDate,
} from '@ugo/utils';

/**
 * @description A collection of date utilities
 */
export const useDates = () => {
  const [dateState] = useState({
    getClientTimezone,
    fromTimestamptz,
    getTimeFromTimestamptz,
    getDateFromTimestamptz,
    toNiceDate,
    now,
    getDurationInHours,
    formatDateToZonedDatetime,
  });

  return dateState;
};
