import React, { useEffect, useState } from 'react';
import {
  useGetFirebaseUserDetailsQuery,
  useResendVerificationEmailMutation,
} from '@ugo/graphql-operations';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@material-ui/core';
import { useNotify } from 'react-admin';

const FirebaseUserDetails = ({ account }: any) => {
  const [firebaseUser, setFirebaseUser] = useState<any>(null);
  const notify = useNotify();

  const [resendVerificationEmailMutation] =
    useResendVerificationEmailMutation();

  const { loading: firebaseUserLoading, refetch: fetchFirebaseUser } =
    useGetFirebaseUserDetailsQuery({
      skip: !account?.id,
      variables: {
        user_id: account?.id,
      },
      onCompleted: (data) => {
        setFirebaseUser(data.getFirebaseUserDetails?.firebase_user);
      },
    });

  useEffect(() => {
    fetchFirebaseUser({ user_id: account.id });
  }, [account]);

  const resendVerificationEmail = async () => {
    try {
      await resendVerificationEmailMutation({
        variables: {
          account_id: account?.id as string,
          app_origin: process.env.REACT_APP_APP_URL as string,
        },
      });
      notify(`Verification email sent to ${account?.email}`, 'success');
    } catch (e) {
      notify('Error resending verification URL', 'error');
    }
  };

  return (
    <Box>
      <Accordion>
        <AccordionSummary>
          <Box>
            <Box>Firebase user details</Box>
            <Box>
              <small>Email verification controls</small>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box mb={4}>
              {firebaseUserLoading ? (
                'Loading...'
              ) : (
                <pre>{JSON.stringify(firebaseUser, null, 2)}</pre>
              )}
            </Box>
            {firebaseUser && (
              <Box>
                <Box mb={2}>
                  {firebaseUser.emailVerified
                    ? 'Email successfully verified by the user'
                    : 'Email NOT verified. You can resend the email by pressing the button below.'}
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={firebaseUser.emailVerified}
                    onClick={resendVerificationEmail}
                  >
                    Resend verification email
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FirebaseUserDetails;
